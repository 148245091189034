<template>
  <section class="content">
    <!-- 正文信息 -->
    <div class="box-body">
      <el-form :inline="true" :model="paperTitleForm" label-width="150px">
        <el-row>
          <el-form-item label="课程名称(代码)" class="pull-left">
            <el-select
              v-model="paperTitleForm.courseNo"
              class="dialog_input_width"
              filterable
              clearable
              remote
              placeholder="请输入课程名称(代码)"
              :remote-method="queryCoursesByKeyword"
              @change="searchGenPaper"
              @focus="(e) => queryCoursesByKeyword(e.target.value)"
            >
              <el-option
                v-for="item in courseInfoSelect"
                :key="item.courseNo"
                :label="item.courseInfo"
                :value="item.courseNo"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-row>
        <el-row v-if="paperTitelDisable">
          <el-form-item label="试卷名称" class="pull-left">
            <el-select
              v-model="paperTitleForm.paperId"
              class="dialog_input_width"
              filterable
              placeholder="请选择"
              sytle="width:220px;"
              @change="searchPaperDetail"
            >
              <el-option
                v-for="item in paperInfoSelect"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <span style="padding-left: 5px">
              <el-button type="primary" @click="insertPaper"
                ><i class="el-icon-plus"></i> 新增试卷</el-button
              >
            </span>
          </el-form-item>
        </el-row>
        <el-row v-if="paperDetailDisable">
          <el-form-item label="试卷大题" class="pull-left">
            <el-select
              v-model="paperTitleForm.paperDetailId"
              class="dialog_input_width"
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in paperDetailInfoSelect"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="题型" class="pull-left">
            <el-select
              v-model="paperTitleForm.value"
              class="dialog_input_width"
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in quesTypes"
                :key="item.id"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label=" " class="pull-left">
            <el-button
              type="primary"
              :disabled="nextDisabled"
              @click="submitForm"
              >下一步
            </el-button>
          </el-form-item>
        </el-row>
      </el-form>

      <!-- 添加试卷弹出框 -->
      <el-dialog title="新增试卷" :visible.sync="paperDialog" width="500px">
        <el-form
          ref="paperForm"
          :model="paperForm"
          :rules="rules"
          label-position="right"
          label-width="120px"
          inline-message
        >
          <el-row>
            <el-form-item label="试卷名称" label-width="120px" prop="name">
              <el-input
                v-model="paperForm.name"
                class="dialog_input_width"
                placeholder="请输入试卷名称"
              ></el-input>
            </el-form-item>
          </el-row>
          <el-row class="margin_top_10 margin_left_120">
            <el-button type="primary" @click="submitPaperForm('paperForm')"
              >保 存</el-button
            >
            <el-button @click="resetPaperForm('paperForm')"
              ><i class="el-icon-refresh"></i> 重 置</el-button
            >
          </el-row>
        </el-form>
      </el-dialog>
    </div>
  </section>
</template>
<script>
import { CORE_API, QUESTION_API } from "@/constants/constants";
import { mapState } from "vuex";
export default {
  data() {
    return {
      paperTitleForm: {
        courseNo: "",
        courseName: "",
        paperId: "",
        paperDetailId: "",
        value: "",
      },
      paperForm: {
        name: "",
      },
      paperDialog: false,
      courseList: [],
      tableData: [],
      detailsData: [],
      quesTypes: [
        { value: "SINGLE_ANSWER_QUESTION", label: "单选" },
        { value: "MULTIPLE_ANSWER_QUESTION", label: "多选" },
        { value: "BOOL_ANSWER_QUESTION", label: "判断" },
        { value: "FILL_BLANK_QUESTION", label: "填空" },
        { value: "TEXT_ANSWER_QUESTION", label: "问答" },
      ],
      rules: {
        name: [{ required: true, message: "请输入试卷名称", trigger: "blur" }],
      },
    };
  },
  computed: {
    paperTitelDisable() {
      if (this.paperTitleForm.courseNo) {
        return true;
      } else {
        return false;
      }
    },
    paperDetailDisable() {
      console.log(
        "this.paperTitleForm.courseNo:",
        this.paperTitleForm.courseNo
      );
      console.log("this.paperTitleForm.paperId:", this.paperTitleForm.paperId);
      console.log("this.detailsData.length:", this.detailsData.length);
      if (
        this.paperTitleForm.courseNo &&
        this.paperTitleForm.paperId &&
        this.detailsData.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    },
    nextDisabled() {
      if (
        this.paperTitleForm.courseNo &&
        this.paperTitleForm.paperId &&
        this.paperTitleForm.paperDetailId &&
        this.paperTitleForm.value
      ) {
        return false;
      } else {
        return true;
      }
    },
    //获取课程的集合
    courseInfoSelect() {
      var courseList = [];
      for (let course of this.courseList) {
        var courseInfo = course.name + "(" + course.code + ")";
        var courseNo = course.code;
        courseList.push({ courseNo: courseNo, courseInfo: courseInfo });
      }
      return courseList;
    },
    //获取试卷的集合
    paperInfoSelect() {
      var paperList = [];
      for (let paper of this.tableData) {
        var paperId = paper.id;
        var paperName = paper.name;
        paperList.push({ id: paperId, name: paperName });
      }
      return paperList;
    },
    //获取大题的集合
    paperDetailInfoSelect() {
      var paperDetaliList = [];
      for (let paperDetail of this.detailsData) {
        var paperDetailId = paperDetail.id;
        var paperDetailName = paperDetail.name;
        paperDetaliList.push({ id: paperDetailId, name: paperDetailName });
      }

      return paperDetaliList;
    },
    routeType() {
      return this.$route.params.type;
    },
    ...mapState({ user: (state) => state.user }),
  },
  methods: {
    //远端查询课程
    queryCoursesByKeyword(query) {
      query = query.trim();
      this.courseLoading = true;
      this.$http
        .get(CORE_API + "/course/query?name=" + query + "&enable=true")
        .then((response) => {
          this.courseList = response.data;
          this.courseLoading = false;
        });
    },
    //根据课程查询试卷集合
    searchGenPaper() {
      this.$http
        .get(
          QUESTION_API +
            "/importPaper/1/500/?courseNo=" +
            this.paperTitleForm.courseNo
        )
        .then((response) => {
          this.tableData = response.data.content;
        });
      this.paperTitleForm.paperId = "";
      this.paperTitleForm.paperDetailId = "";
    },
    //根据试卷查询大题
    searchPaperDetail() {
      this.$http
        .get(QUESTION_API + "/paperDetail/paper/" + this.paperTitleForm.paperId)
        .then((response) => {
          this.detailsData = response.data;
          console.log("response.data:", response.data);
          console.log("this.detailsData:", this.detailsData);
          for (var i = 0; i < this.detailsData.length; i++) {
            if (!this.detailsData[i].name) {
              this.detailsData[i].name = "默认大题";
            }
          }
        });
      this.paperTitleForm.paperDetailId = "";
    },
    //新增试卷
    insertPaper() {
      this.paperDialog = true;
    },
    //重置
    resetPaperForm(formData) {
      this.$refs[formData].resetFields();
    },
    //提交新增试卷
    submitPaperForm(formData) {
      this.$refs[formData].validate((valid) => {
        if (valid) {
          for (let course of this.courseList) {
            if (course.code == this.paperTitleForm.courseNo) {
              this.paperTitleForm.courseName = course.name;
            }
          }
          console.log("课程名称：" + this.paperTitleForm.courseName);
          this.$http
            .post(
              QUESTION_API +
                "/importPaper/saveBlankPaper/" +
                this.paperTitleForm.courseNo +
                "/" +
                this.paperForm.name
            )
            .then((response) => {
              var paperId = response.data.paper.id;
              this.$http
                .get(
                  QUESTION_API +
                    "/importPaper/1/500/?courseNo=" +
                    this.paperTitleForm.courseNo
                )
                .then((response) => {
                  this.tableData = response.data.content;
                  this.paperTitleForm.paperDetailId = "";
                  this.paperTitleForm.paperId = paperId;
                  this.searchPaperDetail();
                });
              this.paperDialog = false;
            })
            .catch((error) => {
              this.$notify({ type: "error", message: error.response.data.msg });
              this.paperDialog = false;
            });
        } else {
          return false;
        }
      });
    },
    //提交
    submitForm() {
      var path = "";
      switch (this.paperTitleForm.value) {
        case "SINGLE_ANSWER_QUESTION":
        case "MULTIPLE_ANSWER_QUESTION":
          path = "edit_select_question";
          break;
        case "TEXT_ANSWER_QUESTION":
        case "FILL_BLANK_QUESTION":
        case "BOOL_ANSWER_QUESTION":
          path = "edit_other_question";
          break;
      }
      for (let course of this.courseList) {
        if (course.code == this.paperTitleForm.courseNo) {
          this.paperTitleForm.courseName = course.name;
        }
      }
      console.log(path);
      this.$router.push({
        path:
          path +
          "/" +
          this.paperTitleForm.paperId +
          "/" +
          this.paperTitleForm.paperDetailId +
          "/" +
          this.paperTitleForm.value +
          "/" +
          this.paperTitleForm.courseNo +
          "/" +
          this.paperTitleForm.courseName,
      });
    },
  },
};
</script>
<style scoped src="../styles/Common.css"></style>
<style scoped>
.box_width_lg {
  width: 600px;
}
</style>
