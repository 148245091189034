import Vue from "vue";
import Vuex from "vuex";
import user from "../modules/portal/store/user";
import currentPaths from "../modules/portal/store/currentPaths";
import menuList from "../modules/portal/store/menuList";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user,
    currentPaths,
    menuList,
  },
});
