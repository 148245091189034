<template>
  <section class="content">
    <!-- 正文信息 -->
    <div class="box-body">
      <el-form
        :inline="true"
        :model="formSearch"
        label-position="right"
        label-width="70px"
      >
        <el-row>
          <el-col :span="6">
            <el-form-item label="考试类型">
              <el-select
                v-model="formSearch.examType"
                class="search_width"
                filterable
                clearable
                placeholder="请选择"
                size="small"
                @change="searchExams"
              >
                <el-option
                  v-for="item in EXAM_TYPES"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="考试名称">
              <el-select
                v-model="formSearch.examId"
                class="search_width"
                filterable
                :remote-method="getExams"
                remote
                clearable
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in examList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="导出类型">
              <el-select
                v-model="formSearch.exportType"
                class="search_width"
                filterable
                clearable
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in EXPORT_TYPES"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <div class="search_down">
              <el-button size="small" type="primary" @click="searchFrom"
                ><i class="el-icon-search"></i> 查询</el-button
              >
              <el-button size="small" @click="resetForm"
                ><i class="el-icon-refresh"></i> 重 置</el-button
              >
              <el-button size="small" type="primary" @click="add"
                ><i class="el-icon-plus"></i> 新增</el-button
              >
            </div>
          </el-col>
        </el-row>
      </el-form>
      <div
        style="width: 100%; border-bottom: 1px solid #ddd; margin: 10px 0"
      ></div>
      <!-- 页面列表 -->
      <el-table border :data="tableData">
        <el-table-column label="考试类型">
          <template slot-scope="scope">
            <span>{{ scope.row.examType | examTypesFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column label="考试名称">
          <template slot-scope="scope">
            <span>{{ scope.row.examName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="导出类型">
          <template slot-scope="scope">
            <span>{{ scope.row.exportType | exportTypesFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column label="题数">
          <template slot-scope="scope">
            <div
              v-for="(item, index) in scope.row.questionTypeNums"
              v-show="scope.row.exportType == 'NORMAL'"
              :key="index"
            >
              <span>{{ item.questionType | questionType }}</span>
              <span>{{ item.quantity }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="175">
          <template slot-scope="scope">
            <div class="operate_left">
              <el-button
                size="mini"
                type="primary"
                plain
                @click="copy(scope.row)"
                ><i class="el-icon-edit"></i>复用</el-button
              >
              <el-button size="mini" type="danger" @click="del(scope.row)"
                ><i class="el-icon-delete"></i> 删除</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="page pull-right">
        <el-pagination
          :current-page="currentPage"
          :page-size="pageSize"
          :page-sizes="[10, 20, 50, 100, 200, 300]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 复用窗口 -->
    <el-dialog
      title="试卷结构复用"
      :visible.sync="dialogCopyModel"
      width="500px"
    >
      <el-form
        :model="exportStructureCopy"
        label-position="right"
        label-width="120px"
      >
        <el-tabs> <el-tab-pane label="原结构信息"></el-tab-pane> </el-tabs>
        <el-form-item label="考试类型">
          <el-select
            v-model="exportStructureCopy.examTypeOld"
            placeholder="请选择"
            disabled
          >
            <el-option
              v-for="item in EXAM_TYPES"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考试名称">
          <el-select
            v-model="exportStructureCopy.examIdOld"
            placeholder="请选择"
            disabled
          >
            <el-option
              v-for="item in examList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="导出类型">
          <el-select
            v-model="exportStructureCopy.exportTypeOld"
            placeholder="请选择"
            disabled
          >
            <el-option
              v-for="item in EXPORT_TYPES"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-tabs> <el-tab-pane label="新结构信息"></el-tab-pane> </el-tabs>
        <el-form-item label="考试类型">
          <el-select
            v-model="exportStructureCopy.examType"
            placeholder="请选择"
            @change="searchExamId"
          >
            <el-option
              v-for="item in EXAM_TYPES"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <span
            v-if="check_value(exportStructureCopy.examType)"
            class="error_message error_other"
            >请选择考试类型</span
          >
        </el-form-item>
        <el-form-item label="考试名称">
          <el-select v-model="exportStructureCopy.examId" placeholder="请选择">
            <el-option
              v-for="item in examListNew"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <span
            v-if="check_value(exportStructureCopy.examId)"
            class="error_message error_other"
            >请选择考试名称</span
          >
        </el-form-item>
        <el-row class="margin_top_10 margin_left_120">
          <el-button type="primary" @click="saveCopy">保 存</el-button>
          <!-- <el-button @click="resetForm3"
            ><i class="el-icon-refresh"></i> 重 置</el-button
          > -->
          <el-button type="primary" @click="cancleCopy"
            ><i class="el-icon-arrow-left"></i> 返 回</el-button
          >
        </el-row>
      </el-form>
    </el-dialog>
    <!-- 新增窗口 -->
    <el-dialog title="新增导出结构" :visible.sync="dialogModel" width="500px">
      <el-form
        :model="exportStructure"
        label-position="right"
        label-width="78px"
        :inline="true"
      >
        <el-row>
          <div style="color: #f56c6c; margin-bottom: -30px">*</div>
          <el-form-item label="考试类型">
            <el-select
              v-model="exportStructure.examType"
              placeholder="请选择"
              @change="searchExportStructure('examType')"
            >
              <el-option
                v-for="item in EXAM_TYPES"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <span
              v-if="check_value(exportStructure.examType)"
              class="error_message error_other"
              >请选择考试类型</span
            >
          </el-form-item>
        </el-row>
        <el-row>
          <div style="color: #f56c6c; margin-bottom: -30px; margin-top: 8px">
            *
          </div>
          <el-form-item label="考试名称">
            <el-select
              v-model="exportStructure.examId"
              filterable
              placeholder="请选择"
              @change="searchExportStructure('examId')"
            >
              <el-option
                v-for="item in examListByExamType"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
            <span
              v-if="check_value(exportStructure.examId)"
              class="error_message error_other"
              >请选择考试名称</span
            >
          </el-form-item>
        </el-row>
        <el-row>
          <div style="color: #f56c6c; margin-bottom: -30px; margin-top: 8px">
            *
          </div>
          <el-form-item label="导出类型">
            <el-select
              v-model="exportStructure.exportType"
              placeholder="请选择"
              :disabled="button"
            >
              <el-option
                v-for="item in EXPORT_TYPES"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <span
              v-if="check_value(exportStructure.exportType)"
              class="error_message error_other"
              >请选择导出类型</span
            >
          </el-form-item>
        </el-row>
        <!-- 判断机考还是传统 -->
        <div v-if="exportStructure.exportType == 'NORMAL'">
          <div
            v-for="(item, index) in exportStructure.questionTypeNums"
            :key="index"
          >
            <el-row>
              <div
                style="
                  color: #f56c6c;
                  margin-bottom: -30px;
                  margin-left: 26px;
                  margin-top: 8px;
                "
              >
                *
              </div>
              <el-form-item
                :label="item.questionType | questionType"
                label-width="78px"
              >
                <el-input
                  v-model="item.quantity"
                  style="width: 220px"
                  :disabled="button"
                ></el-input>
                <span
                  v-if="check(item.quantity)"
                  class="error_message error_other"
                >
                  请输入正整数或0
                </span>
              </el-form-item>
            </el-row>
          </div>
        </div>
        <el-row class="margin_top_10 margin_left_120">
          <el-button type="primary" :disabled="button" @click="save"
            >保 存</el-button
          >
          <!-- <el-button @click="resetForm2"
            ><i class="el-icon-refresh"></i> 重 置</el-button
          > -->
          <el-button type="primary" @click="cancel"
            ><i class="el-icon-arrow-left"></i> 返 回</el-button
          >
        </el-row>
      </el-form>
    </el-dialog>
  </section>
</template>
<script>
import { QUESTION_API, EXAM_WORK_API } from "@/constants/constants";
import { EXAM_TYPES, EXPORT_TYPES } from "../constants/constants";
import { mapState } from "vuex";
import _ from "lodash";
export default {
  data() {
    return {
      isShow: false,
      loading: false,
      dialogModel: false,
      dialogCopyModel: false,
      formSearch: {
        examType: null,
        examId: null,
        exportType: null,
      },
      exportStructure: {
        examType: "",
        examId: "",
        examName: "",
        exportType: "",
        useable: "1", //默认启用
        questionTypeNums: [
          {
            questionType: "SINGLE_ANSWER_QUESTION",
            quantity: "",
          },
          {
            questionType: "MULTIPLE_ANSWER_QUESTION",
            quantity: "",
          },
          {
            questionType: "BOOL_ANSWER_QUESTION",
            quantity: "",
          },
        ],
      },
      exportStructureCopy: {
        examTypeOld: "",
        examIdOld: "",
        exportTypeOld: "",
        examType: "",
        examId: "",
        useable: "1",
        questionTypeNums: [
          {
            questionType: "SINGLE_ANSWER_QUESTION",
            quantity: "",
          },
          {
            questionType: "MULTIPLE_ANSWER_QUESTION",
            quantity: "",
          },
          {
            questionType: "BOOL_ANSWER_QUESTION",
            quantity: "",
          },
        ],
      },
      EXAM_TYPES: EXAM_TYPES,
      EXPORT_TYPES: EXPORT_TYPES,
      examList: [],
      examListByExamType: [],
      tableData: [],
      pageSize: 10,
      currentPage: 1,
      total: 10,
      button: false,
      examListNew: [],
      disMessage: false,
      reShow: false,
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
  },
  created() {
    this.getExams("");
    this.search();
  },
  methods: {
    resetForm() {
      this.formSearch = {
        examType: null,
        examId: null,
        exportType: null,
      };
    },
    searchFrom() {
      this.currentPage = 1;
      this.search();
    },
    search() {
      if (this.formSearch.examType === "") {
        this.formSearch.examType = null;
      }
      this.tableData = [];
      this.loading = true;
      var url =
        QUESTION_API +
        "/findPageByExportStructure/" +
        this.currentPage +
        "/" +
        this.pageSize;
      this.$http.post(url, this.formSearch).then((response) => {
        this.tableData = response.data.content;
        this.total = response.data.totalElements;
        this.loading = false;
      });
    },
    //新增考试结构
    add() {
      this.dialogModel = true;
      this.button = false;
      this.isShow = false;
      this.exportStructure = {
        examType: "",
        examId: "",
        examName: "",
        exportType: "",
        useable: "1", //默认启用
        questionTypeNums: [
          {
            questionType: "SINGLE_ANSWER_QUESTION",
            quantity: "",
          },
          {
            questionType: "MULTIPLE_ANSWER_QUESTION",
            quantity: "",
          },
          {
            questionType: "BOOL_ANSWER_QUESTION",
            quantity: "",
          },
        ],
      };
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.search();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.search();
    },
    closeQuesDialog() {
      this.dialogModel = false;
    },
    //表单验证
    check(value) {
      if (this.disMessage) {
        return false;
      }
      if (this.reShow) {
        return false;
      }
      var r = /^\+?[1-9][0-9]*$/;
      if ((r.test(value) || value === "0") && this.isShow) {
        return false;
      }
      return true;
    },
    check_value(value) {
      if (!value && this.isShow) {
        return true;
      }
      return false;
    },
    save() {
      this.reShow = false;
      this.isShow = true;
      if (this.check_value(this.exportStructure.examType)) {
        return false;
      }
      if (this.check_value(this.exportStructure.examId)) {
        return false;
      }
      if (this.check_value(this.exportStructure.exportType)) {
        return false;
      }
      if (this.exportStructure.exportType == "NORMAL") {
        for (var i = 0; i < this.exportStructure.questionTypeNums.length; i++) {
          if (this.check(this.exportStructure.questionTypeNums[i].quantity)) {
            return false;
          }
        }
      }
      let examId = this.exportStructure.examId;
      this.exportStructure.examName = _.filter(this.examList, function (item) {
        return item.id == examId;
      })[0].name;
      this.$http
        .post(QUESTION_API + "/saveExportStructure", this.exportStructure)
        .then(() => {
          this.$notify({
            title: "提示",
            message: "保存成功",
            type: "success",
          });
          this.closeQuesDialog();
          this.search();
        });
    },
    //重置2
    resetForm2() {
      this.exportStructure = {
        examType: "",
        examId: "",
        examName: "",
        exportType: "",
        useable: "1", //默认启用
        questionTypeNums: [
          {
            questionType: "SINGLE_ANSWER_QUESTION",
            quantity: "",
          },
          {
            questionType: "MULTIPLE_ANSWER_QUESTION",
            quantity: "",
          },
          {
            questionType: "BOOL_ANSWER_QUESTION",
            quantity: "",
          },
        ],
      };
      this.isShow = false;
      this.button = false;
    },
    //取消
    cancel() {
      this.isShow = false;
      this.button = false;
      this.dialogModel = false;
    },
    //取消复用
    cancleCopy() {
      this.isShow = false;
      this.dialogCopyModel = false;
    },
    //重置3
    resetForm3() {
      this.exportStructureCopy.examType = "";
      this.exportStructureCopy.examId = "";
      this.isShow = false;
    },
    //复用
    copy(row) {
      this.dialogCopyModel = true;
      this.exportStructureCopy.examTypeOld = row.examType;
      this.exportStructureCopy.examIdOld = parseInt(row.examId);
      this.exportStructureCopy.exportTypeOld = row.exportType;
      this.exportStructureCopy.questionTypeNums = row.questionTypeNums;
      this.exportStructureCopy.examType = "";
      this.exportStructureCopy.examId = "";
    },
    //新增的时候，先查询
    searchExportStructure(type) {
      this.disMessage = false;
      if (type == "examType") {
        this.$http
          .get(
            EXAM_WORK_API +
              "/exam/queryByNameLike?enable=true&name=&examTypes=" +
              this.exportStructure.examType
          )
          .then((response) => {
            this.examListByExamType = response.data;
            this.exportStructure.examId = "";
            this.resetExportStructure();
          });
      }
      if (this.exportStructure.examType && this.exportStructure.examId) {
        var url =
          QUESTION_API + "/findExportStructure/" + this.exportStructure.examId;
        this.$http
          .get(url)
          .then((response) => {
            console.log(response);
            if (!response.data.id) {
              //如果没有数据
              this.resetExportStructure();
            } else {
              this.button = true;
              this.exportStructure.exportType = response.data.exportType;
              this.exportStructure.useable = response.data.useable
                ? response.data.useable.toString()
                : "1";
              for (
                var i = 0;
                i < this.exportStructure.questionTypeNums.length;
                i++
              ) {
                this.exportStructure.questionTypeNums[i].quantity =
                  response.data.questionTypeNums[i].quantity;
              }
              this.disMessage = true;
              console.log("非空对象");
            }
          })
          .catch(() => {});
      }
    },
    resetExportStructure() {
      this.button = false;
      this.reShow = true;
      this.exportStructure.exportType = "";
      this.exportStructure.useable = "1";
      for (var i = 0; i < this.exportStructure.questionTypeNums.length; i++) {
        this.exportStructure.questionTypeNums[i].quantity = "";
      }
    },
    //点击复用，选择
    searchExamId() {
      if (this.exportStructureCopy.examType) {
        this.$http
          .get(
            EXAM_WORK_API +
              "/exam/queryByNameLike?enable=true&name=&examTypes=" +
              this.exportStructureCopy.examType
          )
          .then((response) => {
            let examListFilter = response.data;
            var url =
              QUESTION_API +
              "/findExportStructureByExamType/" +
              this.exportStructureCopy.examType;
            this.$http.get(url).then((response) => {
              let examListOld = response.data;
              //两个数组相减
              this.examListNew = _.filter(examListFilter, function (exam) {
                return examListOld.indexOf(exam.id.toString()) == -1;
              });
            });
          });
      }
    },
    //保存复用
    saveCopy() {
      this.isShow = true;
      if (this.check_value(this.exportStructureCopy.examType) === true) {
        return false;
      }
      if (this.check_value(this.exportStructureCopy.examId) === true) {
        return false;
      }
      this.exportStructure.examType = this.exportStructureCopy.examType;
      this.exportStructure.examId = this.exportStructureCopy.examId;
      this.exportStructure.exportType = this.exportStructureCopy.exportTypeOld;
      this.exportStructure.useable = this.exportStructureCopy.useable;
      this.exportStructure.questionTypeNums =
        this.exportStructureCopy.questionTypeNums;
      let examId = this.exportStructure.examId;
      this.exportStructure.examName = _.filter(
        this.examListNew,
        function (item) {
          return item.id == examId;
        }
      )[0].name;
      this.$http
        .post(QUESTION_API + "/saveExportStructure", this.exportStructure)
        .then(() => {
          this.$notify({
            title: "提示",
            message: "保存成功",
            type: "success",
          });
          this.dialogCopyModel = false;
          this.search();
        });
    },
    //删除
    del(row) {
      this.$confirm("是否删除该条导出结构", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.loading = true;
        var url = QUESTION_API + "/deleteExportStructure/" + row.id;
        this.$http.delete(url).then(
          () => {
            this.$notify({
              type: "success",
              message: "删除成功！",
            });
            this.loading = false;
            this.search();
          },
          () => {
            this.$notify({
              type: "error",
              message: "删除失败！",
            });
            this.loading = false;
          }
        );
      });
      this.loading = false;
    },
    //查询考试
    getExams(query) {
      query = query.trim();
      var examType = this.formSearch.examType;
      if (this.formSearch.examType == null) {
        examType = "";
      }
      this.$http
        .get(
          EXAM_WORK_API +
            "/exam/queryByNameLike?enable=true&name=" +
            query +
            "&examTypes=" +
            examType
        )
        .then((response) => {
          this.examList = response.data;
        });
    },
    searchExams() {
      this.formSearch.examId = null;
      this.getExams("");
    },
  },
};
</script>
<style scoped src="../styles/Common.css"></style>
