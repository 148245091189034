<template>
  <el-select
    v-model="selected"
    class="size-select"
    placeholder="请选择"
    :style="styles"
    remote
    :remote-method="search"
    clearable
    :disabled="disabled"
    :multiple="multiple"
    @change="select"
  >
    <el-option
      v-for="item in optionList"
      :key="item.roleId"
      :label="item.roleName"
      :value="item.roleId"
    >
      <span>{{ item.roleName }}</span>
    </el-option>
  </el-select>
</template>

<script>
import { CORE_API } from "@/constants/constants";
export default {
  name: "RoleSelect",
  props: {
    value: { type: [String, Number, Array], default: "" },
    schoolId: { type: [String, Number], default: 0 },
    styles: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    enableSuper: { type: Boolean, default: false },
  },
  data() {
    return {
      optionList: [],
      selected: "",
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.selected = val;
      },
    },
    schoolId() {
      this.search();
    },
  },
  async created() {
    this.search();
  },
  methods: {
    async search() {
      const url =
        CORE_API +
        "/rolePrivilege/getRoles?includeSuperAdmin=" +
        true +
        "&rootOrgId=" +
        this.schoolId;

      const res = await this.$httpWithMsg.post(url);
      const isSuperAdminOrOrgAdmin = this.$store.state.user.roleList.some(
        (role) => role.roleCode == "SUPER_ADMIN" || role.roleCode == "ORG_ADMIN"
      );
      // console.log(isSuperAdminOrOrgAdmin);

      this.optionList = res?.data;
      if (!this.enableSuper) {
        this.optionList = this.optionList.filter(
          (item) => item.roleCode != "SUPER_ADMIN"
        );
      }
      if (!isSuperAdminOrOrgAdmin) {
        this.optionList = this.optionList.filter(
          (item) => item.roleCode != "ORG_ADMIN"
        );
      }
    },
    select() {
      this.$emit("input", this.selected);
      this.$emit("change", this.selected, this.optionList);
    },
  },
};
</script>

<style></style>
