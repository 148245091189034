<template>
  <el-container>
    <el-main class="el-main-padding">
      <commonFormVue
        :form="form"
        :get-exam-condition="getExamCondition"
      ></commonFormVue>
      <el-col :span="6">
        <el-button
          size="small"
          type="primary"
          icon="el-icon-search"
          @click="search('clickSelectBtn')"
          >查询</el-button
        >
        <el-button size="small" icon="el-icon-refresh" @click="resetForm"
          >重置</el-button
        >
      </el-col>
      <el-row class="margin-top-10">
        <el-col :span="24">
          <el-table
            ref="multipleTable"
            v-loading="tableLoading"
            element-loading-text="数据加载中"
            :data="tableData"
            border
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              sortable
              label="姓名"
              prop="studentName"
            ></el-table-column>
            <el-table-column
              sortable
              label="身份证号"
              prop="identityNumber"
            ></el-table-column>
            <el-table-column
              sortable
              label="学号"
              prop="studentCode"
            ></el-table-column>
            <el-table-column
              sortable
              label="学习中心"
              prop="orgName"
            ></el-table-column>
            <el-table-column
              sortable
              label="课程"
              prop="courseName"
            ></el-table-column>
            <el-table-column
              sortable
              label="课程层次"
              prop="courseLevel"
            ></el-table-column>
            <el-table-column label="操作" width="120">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="primary"
                  icon="el-icon-refresh"
                  @click="openReexamineDialog(scope.row.examStudentId)"
                  >设置重考</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="block pull-right">
            <el-pagination
              :current-page.sync="form.pageNo"
              :page-sizes="[10, 20, 50, 100, 200, 300]"
              :page-size="form.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            ></el-pagination>
          </div>
        </el-col>
      </el-row>
      <el-dialog
        title="设置重考"
        :visible.sync="dialogFormVisible"
        @closed="reexamineDialogClosed"
      >
        <el-form ref="reexamineForm" :model="reexamineForm">
          <el-form-item
            prop="reexamineType"
            label="重考理由"
            :rules="[
              { required: true, message: '请选择重考理由', trigger: 'change' },
            ]"
          >
            <el-select
              v-model="reexamineForm.reexamineType"
              placeholder="请选择"
            >
              <el-option
                v-for="item in reexamineTypeList"
                :key="item.name"
                :label="item.desc"
                :value="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="详情描述" style="margin-top: 15px">
            <el-input
              v-model="reexamineForm.reexamineDetail"
              type="textarea"
              :autosize="{ minRows: 6, maxRows: 10 }"
              placeholder="请输入内容"
              maxlength="200"
              show-word-limit
            ></el-input>
          </el-form-item>
          <div class="dialog-footer margin-top-10 text-center">
            <el-button type="primary" @click="doSetReexamine">确 定</el-button>
            <el-button @click="dialogFormVisible = false">取 消</el-button>
          </div>
        </el-form>
      </el-dialog>
    </el-main>
  </el-container>
</template>
<script>
import { mapState } from "vuex";
import commonFormVue from "../component/commonForm.vue";
export default {
  components: { commonFormVue },
  data() {
    return {
      dialogFormVisible: false,
      total: 0,
      tableLoading: false,
      form: {
        examRecordDataId: null,
        hasStranger: null,
        courseId: null,
        courseLevel: null,
        examId: null,
        examStageId: null,
        faceSuccessPercentLower: null,
        faceSuccessPercentUpper: null,
        livenessSuccessPercentLower: null,
        livenessSuccessPercentUpper: null,
        identityNumber: null,
        orgId: null,
        studentCode: null,
        studentName: null,
        isWarn: null,
        pageNo: 1,
        pageSize: 10,
        ORG_FIND_ALL: false, //查询所有机构
      },
      reexamineForm: {
        examStudentId: "",
        reexamineType: "",
        reexamineDetail: "",
      },
      getExamCondition: {
        params: {
          name: "",
          examTypes: "ONLINE#ONLINE_HOMEWORK",
        },
        filterCondition: "",
      },
      tableData: [],
      reexamineTypeList: [
        { desc: "断电", name: "POWER_FAILURE" },
        { desc: "机器故障", name: "MACHINE_STOPPAGE" },
        { desc: "其他", name: "OTHER" },
      ],
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
  },
  created() {},
  methods: {
    resetForm() {
      this.form = {
        examRecordDataId: null,
        hasStranger: null,
        courseId: null,
        courseLevel: null,
        examId: null,
        examStageId: null,
        faceSuccessPercentLower: null,
        faceSuccessPercentUpper: null,
        livenessSuccessPercentLower: null,
        livenessSuccessPercentUpper: null,
        identityNumber: null,
        orgId: this.form.ORG_FIND_ALL ? null : this.form.orgId,
        studentCode: null,
        studentName: null,
        isWarn: null,
        pageNo: 1,
        pageSize: 10,
        ORG_FIND_ALL: this.form.ORG_FIND_ALL,
      };
    },
    search(type) {
      if (!this.form.examId) {
        this.$notify({
          title: "警告",
          message: "请选择考试",
          type: "warning",
          duration: 1000,
        });
        return false;
      }
      if (type && type == "clickSelectBtn") {
        this.form.pageNo = 1;
      }
      this.tableLoading = true;
      this.$http
        .post("/api/ecs_oe_admin/exam/student/reexamine/list", this.form)
        .then((response) => {
          if (response.data) {
            this.tableData = response.data.content;
            this.total = response.data.totalElements;
          } else {
            this.tableData = [];
          }
          this.tableLoading = false;
        });
    },
    selectable(row) {
      return row.isWarn;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /**
     * pagesize改变时触发
     */
    handleSizeChange(val) {
      this.form.pageSize = val;
      this.search();
    },
    /**
     * 当前页改变时触发
     */
    handleCurrentChange() {
      this.search();
    },
    openReexamineDialog(examStudentId) {
      this.dialogFormVisible = true;
      this.reexamineForm = {
        examStudentId: examStudentId,
        reexamineType: "",
        reexamineDetail: "",
      };
    },
    doSetReexamine() {
      this.$refs["reexamineForm"].validate((valid) => {
        if (valid) {
          var param = new URLSearchParams({
            examStudentId: this.reexamineForm.examStudentId,
            reexamineType: this.reexamineForm.reexamineType,
            reexamineDetail: this.reexamineForm.reexamineDetail,
          });
          this.$http
            .post("/api/ecs_oe_admin/exam/student/reexamine", param)
            .then(() => {
              this.$notify({
                title: "成功",
                message: "操作成功",
                type: "success",
              });
              this.dialogFormVisible = false;
              this.search();
            });
        } else {
          return false;
        }
      });
    },
    reexamineDialogClosed() {
      this.$refs["reexamineForm"].resetFields();
    },
  },
};
</script>
<style scoped src="../style/common.css"></style>
