<template>
  <section class="content">
    <div class="box box-info">
      <div
        v-loading.body="fileLoading"
        v-loading.fullscreen="fileLoading"
        class="box-body"
        element-loading-text="请稍后..."
      >
        <!-- 表单 -->
        <el-form inline :model="formSearch">
          <el-form-item v-if="isSuperAdmin" label="学校">
            <el-select
              v-model="formSearch.rootOrgId"
              placeholder="请选择"
              style="width: 180px"
              filterable
            >
              <el-option
                v-for="item in rootSchoolSelect"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="名称">
            <el-input
              v-model="formSearch.name"
              placeholder="请输入名称"
              style="width: 180px"
            />
          </el-form-item>
          <el-form-item>
            <el-button
              size="small"
              type="primary"
              icon="el-icon-search"
              @click="handleSearchBtn"
            >
              查询
            </el-button>
          </el-form-item>
        </el-form>

        <div class="block-seperator"></div>

        <span>当前目录：{{ curDir }}</span>
        <span style="float: right">
          <el-button
            size="small"
            type="primary"
            :disabled="parentDirBtn()"
            @click="parentDir"
          >
            上一级
          </el-button>
          <el-button
            size="small"
            type="primary"
            icon="el-icon-plus"
            @click="addDir"
          >
            新增目录
          </el-button>
          <el-button
            size="small"
            type="primary"
            icon="el-icon-plus"
            @click="addFile"
          >
            新增文件
          </el-button>
        </span>
        <div style="width: 100%; margin-bottom: 10px"></div>

        <!-- 页面列表 -->
        <el-table :data="tableData" border resizable stripe style="width: 100%">
          <el-table-column width="50" label="ID">
            <span slot-scope="scope">{{ scope.row.id }}</span>
          </el-table-column>
          <el-table-column width="250" label="名称">
            <span slot-scope="scope">
              <span
                style="width: 30px; height: 20px; display: block; float: left"
              >
                <img
                  v-if="scope.row.isFile == 0"
                  src="../assets/img/dir.png"
                  style="height: 20px; width: 20px"
                />
              </span>
              <span
                ><a
                  v-if="scope.row.isFile == 0"
                  href="javascript:void(0)"
                  @click="toDir(scope.row)"
                  >{{ scope.row.name }}</a
                ><a
                  v-if="scope.row.isFile == 1"
                  target="_blank"
                  :href="scope.row.fileUrl"
                  >{{ scope.row.name }}</a
                ></span
              ></span
            >
          </el-table-column>
          <el-table-column label="路径">
            <span slot-scope="scope">{{ scope.row.filePath }}</span>
          </el-table-column>
          <el-table-column width="180" label="创建时间">
            <span slot-scope="scope">{{ scope.row.creationTime }}</span>
          </el-table-column>
          <el-table-column :context="_self" label="操作" width="150">
            <div slot-scope="scope">
              <el-button
                size="mini"
                type="danger"
                plain
                @click="deleteRes(scope.row)"
              >
                <i class="el-icon-delete"></i> 删除
              </el-button>
            </div>
          </el-table-column>
        </el-table>
        <div class="page pull-right">
          <el-pagination
            v-if="paginationShow"
            :current-page="currentPage"
            :page-size="pageSize"
            :page-sizes="[10, 20, 50, 100, 200, 300]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          />
        </div>
      </div>
      <el-dialog
        title="新增目录"
        width="500px"
        :visible.sync="dirModel"
        :close-on-click-modal="false"
        @close="closeDirModel"
      >
        <el-form
          ref="dirForm"
          :key="dirModelKey"
          :inline="true"
          :model="dirForm"
          :rules="dirRules"
          label-width="90px"
        >
          <el-row>
            <el-form-item label="目录名称" prop="dirName">
              <el-input
                v-model="dirForm.dirName"
                class="pull-length"
                maxlength="50"
                placeholder="可输入字母,数字,-,_"
              />
            </el-form-item>
          </el-row>
          <el-row class="pull-center">
            <el-button type="primary" :loading="dirForm.loading" @click="subDir"
              >确定</el-button
            >
            <el-button @click="closeDirModel">取消</el-button>
          </el-row>
        </el-form>
      </el-dialog>
      <el-dialog
        title="新增文件"
        width="550px"
        :visible.sync="fileModel"
        :close-on-click-modal="false"
        @close="closeFileModel"
      >
        <el-form
          ref="fileForm"
          :key="fileModelKey"
          :inline="true"
          :model="fileForm"
          :rules="fileRules"
          label-width="90px"
        >
          <el-row>
            <el-form-item label="文件" prop="dataFile">
              <el-input
                v-model="fileForm.fileName"
                class="pull-length"
                :readonly="true"
                placeholder="文件最大限制10M"
              />
            </el-form-item>
            <el-form-item>
              <el-upload :before-upload="handleUploadFile" action="/upload">
                <el-button
                  type="primary"
                  size="small"
                  icon="ios-cloud-upload-outline"
                  >请选择文件
                </el-button>
              </el-upload>
            </el-form-item>
          </el-row>
          <el-row class="pull-center">
            <el-button
              type="primary"
              :loading="fileForm.loading"
              @click="subFile"
              >确定</el-button
            >
            <el-button @click="closeFileModel">取消</el-button>
          </el-row>
        </el-form>
      </el-dialog>
    </div>
  </section>
</template>
<script>
import { CORE_API } from "@/constants/constants.js";
import { mapState } from "vuex";

export default {
  name: "ResourceList",
  data() {
    var validateFile = function (rule, value, callback) {
      if (value) {
        var upFileName = value.name;
        var reg = /^[a-zA-Z\\-_0-9\\.]{1,50}$/;
        if (!upFileName.match(reg)) {
          return callback(
            new Error("文件名长度最大为50且只能包含字母,数字,'-','_','.'")
          );
        } else if (value.size > 1024 * 1024 * 10) {
          return callback(new Error("文件大小限制为10M"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      fileLoading: false,
      dirModel: false,
      dirModelKey: Math.random(),
      fileModel: false,
      fileModelKey: Math.random(),
      curDir: "",
      parentIds: [],
      paginationShow: false,
      rootOrgList: [],
      formSearch: {
        parentId: -1,
        rootOrgId: null,
        code: "",
        name: "",
      },
      dirForm: {
        dirName: "",
        loading: false,
      },
      fileForm: {
        fileName: null,
        dataFile: null,
        loading: false,
      },
      loading: true,
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 10,

      dirRules: {
        dirName: [
          { required: true, message: "请输入目录名称", trigger: "blur" },
          {
            pattern: /^[a-zA-Z\\-_0-9]{1,50}$/,
            message: "目录名称必须为1-50位字母,数字,'-','_'组合",
            trigger: "blur",
          },
        ],
      },
      fileRules: {
        dataFile: [
          {
            required: true,
            type: "object",
            message: "请选择文件",
            trigger: "change",
          },
          {
            validator: validateFile,
            type: "object",
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
    isSuperAdmin() {
      return this.user.roleList.some((role) => role.roleCode == "SUPER_ADMIN");
    },
    rootSchoolSelect() {
      let rootSchools = [];
      for (let i = 0; i < this.rootOrgList.length; i++) {
        let info = {
          name: this.rootOrgList[i].name + "（" + this.rootOrgList[i].id + "）",
          id: this.rootOrgList[i].id,
        };
        rootSchools.push(info);
      }
      return rootSchools;
    },
  },
  watch: {
    "formSearch.rootOrgId": {
      immediate: false,
      handler(val) {
        if (val == null) {
          this.curDir = "";
        } else {
          this.curDir = "/";
          this.formSearch.parentId = -1;
          this.handleSearchBtn();
        }
      },
    },
  },
  //初始化查询
  created() {
    this.init();
  },
  methods: {
    handleUploadFile(file) {
      this.fileForm.dataFile = file;
      this.fileForm.fileName = file.name;
      return false;
    },
    async subFile() {
      const res = await this.$refs.fileForm.validate();

      if (res === false) {
        return;
      }
      this.fileForm.loading = true;
      var params =
        "?parentId=" +
        this.formSearch.parentId +
        "&rootOrgId=" +
        this.formSearch.rootOrgId;
      var url = CORE_API + "/resource/addFile" + params;
      let formData = new FormData();
      formData.append("dataFile", this.fileForm.dataFile);
      this.$httpWithMsg
        .post(url, formData)
        .then(() => {
          this.$notify({
            type: "success",
            message: "上传成功！",
          });
          this.closeFileModel();
          this.searchForm();
        })
        .finally(() => (this.fileForm.loading = false));
    },
    closeFileModel() {
      this.fileModel = false;
      this.$refs.fileForm.resetFields();
      this.fileModelKey = Math.random();
      this.fileForm.fileName = null;
      this.fileForm.dataFile = null;
    },
    closeDirModel() {
      this.dirModel = false;
      this.dirForm.dirName = null;
      this.dirModelKey = Math.random();
    },
    async subDir() {
      let res = await this.$refs.dirForm.validate();
      if (!res) {
        return;
      }
      this.dirForm.loading = true;
      var url = CORE_API + "/resource/addDir";
      this.$httpWithMsg
        .post(url, {
          parentId: this.formSearch.parentId,
          rootOrgId: this.formSearch.rootOrgId,
          name: this.dirForm.dirName,
        })
        .then(() => {
          this.$notify({
            type: "success",
            message: "新增成功！",
          });
          this.closeDirModel();
          this.searchForm();
        })
        .finally(() => (this.dirForm.loading = false));
    },
    lastCurDir() {
      this.curDir = this.curDir.substring(0, this.curDir.length - 1);
      var index = this.curDir.lastIndexOf("/");
      this.curDir = this.curDir.substring(0, index + 1);
    },
    toDir(row) {
      this.curDir = this.curDir + row.name + "/";
      this.parentIds.push(this.formSearch.parentId);
      this.formSearch.parentId = row.id;
      this.currentPage = 1;
      this.searchForm();
    },
    parentDirBtn() {
      if (this.curDir == "/" || this.curDir == "") {
        return true;
      } else {
        return false;
      }
    },
    deleteRes(row) {
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        var url = CORE_API + "/resource/" + row.id;
        this.$httpWithMsg.delete(url).then(() => {
          this.$notify({
            type: "success",
            message: "删除成功！",
          });
          this.searchForm();
        });
      });
    },
    parentDir() {
      this.formSearch.parentId = this.parentIds.pop();
      this.lastCurDir();
      this.currentPage = 1;
      this.searchForm();
    },
    addFile() {
      if (this.formSearch.rootOrgId == null) {
        this.$notify({
          type: "warning",
          message: "请选择学校",
        });
        return;
      }
      this.fileModel = true;
    },
    addDir() {
      if (this.formSearch.rootOrgId == null) {
        this.$notify({
          type: "warning",
          message: "请选择学校",
        });
        return;
      }
      this.dirModel = true;
    },
    handleSearchBtn() {
      this.currentPage = 1;
      this.searchForm();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.searchForm();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.searchForm();
    },
    //查询
    searchForm() {
      if (this.formSearch.rootOrgId == null) {
        this.$notify({
          type: "warning",
          message: "请选择学校",
        });
        return;
      }
      this.loading = true;
      this.fileLoading = true;
      var param = new URLSearchParams(this.formSearch);
      var url =
        CORE_API +
        "/resource/page/" +
        (this.currentPage - 1) +
        "/" +
        this.pageSize +
        "?" +
        param;
      this.$httpWithMsg
        .get(url)
        .then((response) => {
          this.tableData = response.data.list;
          this.total = response.data.total;
          this.loading = false;

          this.$nextTick(function () {
            this.paginationShow = true;
          });
        })
        .finally(() => (this.fileLoading = false));
    },
    init() {
      if (this.isSuperAdmin) {
        this.$httpWithMsg
          .get(CORE_API + "/org/getRootOrgList")
          .then((response) => {
            this.rootOrgList = response.data;
          });
      } else {
        this.formSearch.rootOrgId = this.user.rootOrgId;
        this.searchForm();
      }
    },
  },
};
</script>

<style scoped>
.page {
  margin-top: 10px;
}

.el-upload {
  width: 80px;
}

.pull-length {
  width: 300px;
}
.pull-center {
  margin-top: 20px;
}
</style>
