export default {
  data() {
    return {};
  },
  beforeCreate() {
    var groupCode = "NETEXAM_WORK_MENUS";
    this.$http
      .post(
        "/api/ecs_core/rolePrivilege/getUserPrivileges?groupCode=" +
          groupCode +
          "&full=" +
          false
      )
      .then((response) => {
        var allpermissions = response.data;
        var pagePermissions = [];
        for (var i = 0; i < allpermissions.length; i++) {
          if (allpermissions[i].ext1 == "PAGE") {
            pagePermissions.push(allpermissions[i].code);
          }
        }
        if (pagePermissions.length > 0) {
          for (var currentPagePrivilege in this.currentPagePrivileges) {
            if (pagePermissions.indexOf(currentPagePrivilege) > -1) {
              this.currentPagePrivileges[currentPagePrivilege] = true;
            }
          }
        }
        this.getPermissionStatus = true; //获取权限完成
      });
  },
};
