<template>
  <section class="content">
    <!-- 正文信息 -->
    <div class="box-body">
      <el-form
        :inline="true"
        :model="formSearch"
        label-position="right"
        label-width="70px"
      >
        <el-row>
          <el-col :span="6">
            <el-form-item label="课程名称">
              <el-select
                v-model="formSearch.courseNo"
                class="search_width"
                filterable
                :remote-method="getCourses"
                remote
                clearable
                placeholder="请选择"
                size="small"
                @change="initCourseProperty"
                @focus="(e) => getCourses(e.target.value)"
              >
                <el-option
                  v-for="item in courseInfoSelect"
                  :key="item.courseNo"
                  :label="item.courseInfo"
                  :value="item.courseNo"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="层次">
              <el-select
                v-model="formSearch.courseLevel"
                class="search_width"
                filterable
                clearable
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in levelList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="题型">
              <el-select
                v-model="formSearch.questionType"
                class="search_width"
                filterable
                clearable
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in questionTypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="属性名">
              <el-select
                v-model="formSearch.coursePropertyName"
                class="search_width"
                filterable
                clearable
                :disabled="updatePorperty"
                placeholder="请选择"
                size="small"
                @change="searchFirst"
              >
                <el-option
                  v-for="item in coursePropertyList"
                  :key="item.name"
                  :label="item.name"
                  :value="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- created by wwh -->
        <el-row>
          <el-col :span="6">
            <el-form-item label="一级属性">
              <el-select
                v-model="formSearch.firstPropertyId"
                class="search_width"
                filterable
                clearable
                :disabled="updateFirst"
                placeholder="请选择"
                size="small"
                @change="searchSecond"
              >
                <el-option
                  v-for="item in firstPropertyList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="二级属性">
              <el-select
                v-model="formSearch.secondPropertyId"
                class="search_width"
                filterable
                clearable
                :disabled="updateSecond"
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in secondPropertyList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="公开度">
              <el-select
                v-model="formSearch.publicity"
                class="search_width"
                filterable
                clearable
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in publicityList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <div class="search_down">
              <el-button size="small" type="primary" @click="searchFrom"
                ><i class="el-icon-search"></i> 查询</el-button
              >
              <el-button size="small" @click="resetForm"
                ><i class="el-icon-refresh"></i> 重 置</el-button
              >
            </div>
          </el-col>
        </el-row>
      </el-form>
      <div
        style="width: 100%; border-bottom: 1px solid #ddd; margin: 10px 0"
      ></div>
      <!-- 页面列表 -->
      <el-table v-loading="loading" :data="tableData" border>
        <el-table-column label="课程" width="180">
          <template slot-scope="scope">
            <span>{{ scope.row.course.name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="层次" width="100">
          <template slot-scope="scope">
            <span>{{ getLevel(scope.row.course.level) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="题型" width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.questionType | questionType }}</span>
          </template>
        </el-table-column>
        <el-table-column label="题干">
          <template slot-scope="scope">
            <span
              class="row_quesBody"
              @click="prevViewQues(scope.row)"
              v-html="scope.row.quesBody"
            >
            </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="175">
          <template slot-scope="scope">
            <div class="operate_left">
              <el-button
                v-if="scope.row.questionType !== 'NESTED_ANSWER_QUESTION'"
                size="mini"
                type="primary"
                plain
                @click="updateRow(scope.row)"
              >
                <i class="el-icon-edit"></i>编辑
              </el-button>
              <el-button
                size="mini"
                type="danger"
                @click="deleteRow(scope.row)"
              >
                <i class="el-icon-delete"></i> 删除
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页栏 -->
      <div class="page pull-right">
        <el-pagination
          :current-page="currentPage"
          :page-size="pageSize"
          :page-sizes="[10, 20, 50, 100, 200, 300]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        >
        </el-pagination>
      </div>
    </div>

    <div class="text-left">
      <el-dialog
        title="试题预览"
        :visible.sync="quesDialog"
        @close="closeQuesDialog"
      >
        <el-form :model="quesModel" label-position="right" label-width="80px">
          <el-row :gutter="10">
            <el-col :xs="10" :sm="10" :md="10" :lg="10">
              <el-form-item label="题型">
                <el-select
                  v-model="quesModel.questionType"
                  :disabled="true"
                  placeholder="题型"
                >
                  <el-option
                    v-for="item in questionTypes"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :xs="30" :sm="30" :md="30" :lg="30">
              <el-form-item label="题干">
                <span class="ques-body" v-html="quesModel.quesBody"></span>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 非套题 -->
          <div v-if="quesModel.questionType !== 'NESTED_ANSWER_QUESTION'">
            <el-row
              v-for="(quesOption, index) in quesModel.quesOptions"
              :key="index"
              :gutter="10"
            >
              <el-col :xs="30" :sm="30" :md="30" :lg="30">
                <el-form-item :label="index | optionOrderWordFilter">
                  <span class="ques-body" v-html="quesOption.optionBody"></span>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <!-- 套题 -->
          <div v-if="quesModel.questionType === 'NESTED_ANSWER_QUESTION'">
            <el-row
              v-for="subQuestionModel in quesModel.subQuestions"
              :key="subQuestionModel"
              :gutter="10"
            >
              <el-col :xs="30" :sm="30" :md="30" :lg="30">
                <el-row>
                  <el-form-item label="题目">
                    <span v-html="subQuestionModel.quesBody"></span>
                  </el-form-item>
                </el-row>
                <el-row
                  v-for="(
                    subQuesOption, subIndex
                  ) in subQuestionModel.quesOptions"
                  :key="subIndex"
                  :gutter="10"
                >
                  <el-col :xs="30" :sm="30" :md="30" :lg="30">
                    <el-form-item :label="subIndex | optionOrderWordFilter">
                      <span v-html="subQuesOption.optionBody"></span>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-form-item label="答案">
                    <span v-html="subQuestionModel.quesAnswer"></span>
                  </el-form-item>
                </el-row>
              </el-col>
            </el-row>
          </div>
          <!-- 非套题答案 -->
          <div v-if="quesModel.questionType !== 'NESTED_ANSWER_QUESTION'">
            <el-form-item label="答案">
              <span v-html="answer"></span>
            </el-form-item>
          </div>
        </el-form>
      </el-dialog>
    </div>
    <el-dialog title="提示" :visible.sync="deleteDialogVisible">
      <span>{{ deleteInfo }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="deleteDialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </section>
</template>
<script>
import { CORE_API, QUESTION_API } from "@/constants/constants";
import { QUESTION_TYPES, LEVEL_TYPE } from "../constants/constants";
export default {
  data() {
    return {
      formSearch: {
        questionType: "",
        courseNo: "",
        courseLevel: "",
        courseName: "",
        publicity: "",
        coursePropertyName: "",
        firstPropertyId: "",
        secondPropertyId: "",
      },
      courseList: [], //课程list
      levelList: LEVEL_TYPE, //层次list
      questionTypeList: [], //题型
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 10,
      quesModel: {},
      questionTypes: QUESTION_TYPES,
      quesDialog: false,
      loading: false,
      deleteDialogVisible: false,
      deleteInfo: "",
      singleRightAnswer: "", //接收单选答案
      multipleRightAnswer: [], //接收多选答案
      publicityList: [
        { label: "公开", value: true },
        { label: "非公开", value: false },
      ],
      coursePropertyList: [], //课程属性集合
      firstPropertyList: [], //一级属性集合
      secondPropertyList: [], //二级属性集合
      isClear: 0,
    };
  },
  computed: {
    courseInfoSelect() {
      var courseList = [];
      for (let course of this.courseList) {
        var courseInfo = course.name + "(" + course.code + ")";
        var courseNo = course.code;
        courseList.push({ courseNo: courseNo, courseInfo: courseInfo });
      }
      return courseList;
    },
    answer() {
      if (this.quesModel.questionType == "SINGLE_ANSWER_QUESTION") {
        return this.singleRightAnswer;
      } else if (this.quesModel.questionType == "MULTIPLE_ANSWER_QUESTION") {
        var obj = this.multipleRightAnswer;
        return obj.sort().toString();
      } else {
        return this.quesModel.quesAnswer;
      }
    },
    updateFirst() {
      if (this.formSearch.coursePropertyName) {
        return false;
      }
      return true;
    },
    updateSecond() {
      if (this.formSearch.firstPropertyId) {
        return false;
      }
      return true;
    },
    updatePorperty() {
      if (this.formSearch.courseNo) {
        return false;
      }
      return true;
    },
  },
  watch: {
    $route: "initVue",
  },
  //钩子函数
  created() {
    this.initVue();
  },
  methods: {
    resetForm() {
      this.formSearch = {
        questionType: "",
        courseNo: "",
        courseLevel: "",
        courseName: "",
        publicity: "",
        coursePropertyName: "",
        firstPropertyId: "",
        secondPropertyId: "",
      };
    },
    getLevel(level) {
      if (level == "ZSB") {
        return "专升本";
      } else if (level == "GQZ") {
        return "高起专";
      } else if (level == "GQB") {
        return "高起本";
      } else if (level == "ALL") {
        return "不限";
      } else {
        return "";
      }
    },
    //查询列表
    searchFrom() {
      this.currentPage = 1;
      this.searchQues();
    },
    searchQues() {
      if (!this.formSearch.courseNo) {
        this.$notify({
          message: "请选择课程",
          type: "warning",
        });
        return;
      }
      this.tableData = [];
      var url =
        QUESTION_API + "/question/" + this.currentPage + "/" + this.pageSize;
      this.loading = true;
      this.$http.get(url, { params: this.formSearch }).then((response) => {
        this.tableData = response.data.content;
        this.total = response.data.totalElements;
        this.loading = false;
      });
    },
    //分页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.searchQues();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.searchQues();
    },
    getCourseName(courseNo) {
      for (let course of this.courseList) {
        if (course.code == courseNo) {
          this.formSearch.courseName = course.name;
        }
      }
    },
    //修改
    updateRow(row) {
      this.getCourseName(this.formSearch.courseNo);
      //选择题：单选、多选
      if (
        row.questionType === "SINGLE_ANSWER_QUESTION" ||
        row.questionType === "MULTIPLE_ANSWER_QUESTION"
      ) {
        this.$router.push({
          path: "/questions/edit_select_question/" + row.id,
        });
      }
      //判断、填空、问答
      if (
        row.questionType === "BOOL_ANSWER_QUESTION" ||
        row.questionType === "FILL_BLANK_QUESTION" ||
        row.questionType === "TEXT_ANSWER_QUESTION"
      ) {
        this.$router.push({
          path: "/questions/edit_other_question/" + row.id,
        });
      }
      sessionStorage.setItem("question", JSON.stringify(this.formSearch));
      sessionStorage.setItem("question_currentPage", this.currentPage);
    },
    //删除
    deleteRow(row) {
      this.$confirm("确认删除试题吗?", "提示", {
        type: "warning",
      }).then(() => {
        this.loading = true;
        this.$httpWithMsg
          .delete(QUESTION_API + "/paper/deleteQuestion/" + row.id)
          .then(() => {
            this.$notify({
              message: "删除成功",
              type: "success",
            });
            this.searchQues();
          })
          .finally(() => (this.loading = false));
      });
    },
    //打开弹窗
    openQuesDialog() {
      this.quesDialog = true;
    },
    //关闭弹窗
    closeQuesDialog() {
      this.quesDialog = false;
      this.quesModel = {};
    },
    //预览
    prevViewQues(row) {
      this.quesModel = row;
      this.disposeSelectAnswer();
      this.openQuesDialog();
    },
    //查询所有课程
    getCourses(query) {
      this.$http
        .get(CORE_API + "/course/query?name=" + query + "&enable=true")
        .then((response) => {
          this.courseList = response.data;
          if (this.formSearch.courseNo) {
            this.$http
              .get(
                QUESTION_API +
                  "/courseProperty/enable/" +
                  this.formSearch.courseNo
              )
              .then((response) => {
                this.coursePropertyList = response.data;
                //查询一级属性
                this.getFirst();
              });
          } else {
            this.coursePropertyList = [];
          }
        });
    },
    //重置查询表单
    resetSearchForm() {
      this.formSearch = {
        questionType: "",
        courseNo: "",
      };
    },
    /*处理选择题答案显示，处理套题下选择题答案显示*/
    disposeSelectAnswer() {
      this.singleRightAnswer = "";
      this.multipleRightAnswer = [];
      //处理选择题答案显示
      if (
        this.quesModel.questionType == "SINGLE_ANSWER_QUESTION" ||
        this.quesModel.questionType == "MULTIPLE_ANSWER_QUESTION"
      ) {
        for (var i = 0; i < this.quesModel.quesOptions.length; i++) {
          var option = this.quesModel.quesOptions[i];
          var orderNum = String.fromCharCode(65 + i);
          if (
            this.quesModel.questionType == "SINGLE_ANSWER_QUESTION" &&
            option.isCorrect == 1
          ) {
            this.singleRightAnswer = orderNum;
          }
          if (
            this.quesModel.questionType == "MULTIPLE_ANSWER_QUESTION" &&
            option.isCorrect == 1
          ) {
            this.multipleRightAnswer.push(orderNum);
          }
        }
      }
      //处理套题下选择题答案显示
      if (this.quesModel.questionType == "NESTED_ANSWER_QUESTION") {
        var subQuestions = this.quesModel.subQuestions;
        for (var ii = 0; ii < subQuestions.length; ii++) {
          var subQuestion = subQuestions[ii];
          if (subQuestion.questionType == "SINGLE_ANSWER_QUESTION") {
            for (var j = 0; j < subQuestion.quesOptions.length; j++) {
              var option_j = subQuestion.quesOptions[j];
              var orderNum_j = String.fromCharCode(65 + j);
              if (option_j.isCorrect == 1) {
                subQuestion["quesAnswer"] = orderNum_j;
              }
            }
          }
          if (subQuestion.questionType == "MULTIPLE_ANSWER_QUESTION") {
            var subQuestionMultipleRightAnswer = [];
            for (var k = 0; k < subQuestion.quesOptions.length; k++) {
              var option2 = subQuestion.quesOptions[k];
              var orderNum2 = String.fromCharCode(65 + k);
              if (option2.isCorrect == 1) {
                subQuestionMultipleRightAnswer.push(orderNum2);
              }
            }
            subQuestion["quesAnswer"] = subQuestionMultipleRightAnswer
              .sort()
              .toString();
          }
        }
      }
    },
    //查询所有课程属性名
    initCourseProperty() {
      this.formSearch.coursePropertyName = "";
      this.formSearch.firstPropertyId = "";
      this.formSearch.secondPropertyId = "";
      if (this.formSearch.courseNo) {
        this.$http
          .get(
            QUESTION_API + "/courseProperty/enable/" + this.formSearch.courseNo
          )
          .then((response) => {
            this.coursePropertyList = response.data;
          });
      } else {
        this.coursePropertyList = [];
      }
    },
    //查询一级属性
    searchFirst() {
      if (this.formSearch.coursePropertyName) {
        this.formSearch.firstPropertyId = "";
        this.formSearch.secondPropertyId = "";
        for (let courseProperty of this.coursePropertyList) {
          if (courseProperty.name == this.formSearch.coursePropertyName) {
            this.$http
              .get(QUESTION_API + "/property/first/" + courseProperty.id)
              .then((response) => {
                this.firstPropertyList = response.data;
              });
          }
        }
      }
    },
    //查询二级属性
    searchSecond() {
      if (this.formSearch.firstPropertyId) {
        this.formSearch.secondPropertyId = "";
        this.$http
          .get(
            QUESTION_API + "/property/second/" + this.formSearch.firstPropertyId
          )
          .then((response) => {
            this.secondPropertyList = response.data;
          });
      }
    },
    getFirst() {
      if (this.formSearch.coursePropertyName) {
        for (let courseProperty of this.coursePropertyList) {
          if (courseProperty.name == this.formSearch.coursePropertyName) {
            this.$http
              .get(QUESTION_API + "/property/first/" + courseProperty.id)
              .then((response) => {
                this.firstPropertyList = response.data;
                //查询二级
                this.getSecond();
              });
          }
        }
      }
    },
    getSecond() {
      if (this.formSearch.firstPropertyId) {
        this.$http
          .get(
            QUESTION_API + "/property/second/" + this.formSearch.firstPropertyId
          )
          .then((response) => {
            this.secondPropertyList = response.data;
          });
      }
    },
    initVue() {
      this.isClear = this.$route.params.isClear;
      if (this.isClear == 0 || !this.isClear) {
        sessionStorage.removeItem("question");
        sessionStorage.removeItem("question_currentPage");
        this.formSearch = {
          questionType: "",
          courseNo: "",
          courseLevel: "",
          courseName: "",
          publicity: "",
          coursePropertyName: "",
          firstPropertyId: "",
          secondPropertyId: "",
        };
        this.currentPage = 1;
      } else {
        this.formSearch = JSON.parse(sessionStorage.getItem("question"));
        this.currentPage = parseInt(
          sessionStorage.getItem("question_currentPage")
        );
      }
      if (this.formSearch && this.formSearch.courseName) {
        this.getCourses(this.formSearch.courseName);
      } else {
        this.formSearch = {
          questionType: "",
          courseNo: "",
          courseLevel: "",
          courseName: "",
          publicity: "",
          coursePropertyName: "",
          firstPropertyId: "",
          secondPropertyId: "",
        };
      }
      if (this.formSearch.courseNo) {
        this.searchQues();
      }
    },
  },
};
</script>
<style scoped>
.select_width {
  width: 150px;
}
.row_quesBody >>> p {
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
<style scoped src="../styles/Common.css"></style>
