<template>
  <section class="content">
    <div class="box box-info">
      <div
        v-loading.body="loading"
        v-loading.fullscreen="loading"
        class="box-body"
        element-loading-text="请稍后..."
      >
        <!-- 表单 -->
        <el-form inline :model="searchForm">
          <el-form-item v-if="isSuperAdmin" label="学校">
            <el-select
              v-model="searchForm.rootOrgId"
              clearable
              filterable
              placeholder="请选择"
              class="search_width"
            >
              <el-option
                v-for="item in rootOrgList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="操作">
            <el-select
              v-model="searchForm.operate"
              clearable
              filterable
              placeholder="请选择"
              class="input_width"
            >
              <el-option
                v-for="item in operateList"
                :key="item"
                :label="item"
                :value="item"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="登录名">
            <el-input
              v-model="searchForm.loginName"
              placeholder="请输入登录名"
              style="width: 180px"
            />
          </el-form-item>

          <el-form-item label="角色">
            <el-select
              v-model="searchForm.roleId"
              clearable
              placeholder="请选择"
              class="input_width"
            >
              <el-option
                v-for="item in roleList4Search"
                :key="item.roleId"
                :label="item.roleName"
                :value="item.roleId"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="操作时间">
            <el-date-picker
              v-model="timeRange"
              class="input"
              type="datetimerange"
              start-placeholder="开始日期"
              range-separator="至"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd HH:mm:ss"
              :clearable="false"
              @change="changeTimeRange"
            ></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="handleSearchBtn"
            >
              查询
            </el-button>
          </el-form-item>
        </el-form>

        <div class="block-seperator"></div>
        <!-- 页面列表 -->
        <el-table :data="tableData" border resizable stripe style="width: 100%">
          <el-table-column width="120" prop="loginName" label="登录名">
          </el-table-column>
          <el-table-column width="120" prop="name" label="姓名">
          </el-table-column>
          <el-table-column width="120" prop="roles" label="用户角色">
          </el-table-column>
          <el-table-column width="120" prop="operateIp" label="IP地址">
          </el-table-column>
          <el-table-column width="200" prop="operate" label="操作">
          </el-table-column>
          <el-table-column prop="operateInfo" label="操作详情">
          </el-table-column>
          <el-table-column width="160" prop="operateTime" label="操作时间">
          </el-table-column>
        </el-table>
        <div class="page pull-right">
          <el-pagination
            v-if="paginationShow"
            :current-page="currentPage"
            :page-size="pageSize"
            :page-sizes="[10, 20, 50, 100, 200, 300]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { CORE_API } from "@/constants/constants.js";
import { mapState } from "vuex";

export default {
  name: "Project",
  data() {
    return {
      rootOrgList: [],
      operateList: [],
      examList: [],
      loading: false,
      paginationShow: false,
      roleList4Search: [],
      timeRange: [],
      searchForm: {
        rootOrgId: null,
        startTime: null,
        endTime: null,
        loginName: "",
        operate: "",
        roleId: "",
      },
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 10,
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
    isSuperAdmin() {
      return this.user.roleList.some((role) => role.roleCode == "SUPER_ADMIN");
    },
  },
  //初始化查询
  created() {
    this.init();
  },
  methods: {
    changeTimeRange(e) {
      if (e && e.length > 0) {
        this.searchForm.startTime = e[0];
        this.searchForm.endTime = e[1];
      } else {
        this.searchForm.startTime = "";
        this.searchForm.endTime = "";
      }
    },
    handleSearchBtn() {
      this.currentPage = 1;
      this.search();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.search();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.search();
    },
    //查询
    search() {
      if (this.searchForm.rootOrgId == null) {
        this.$notify({
          type: "warning",
          message: "请选择学校",
        });
        return;
      }
      this.loading = true;
      var url =
        CORE_API +
        "/adminOperate/page/" +
        this.currentPage +
        "/" +
        this.pageSize;
      this.$httpWithMsg
        .get(url, { params: this.searchForm })
        .then((response) => {
          this.tableData = response.data.list;
          this.total = response.data.total;
          this.loading = false;

          this.$nextTick(function () {
            this.paginationShow = true;
          });
        })
        .finally(() => (this.loading = false));
    },
    init() {
      var url =
        CORE_API +
        "/rolePrivilege/getRoles?includeSuperAdmin=true&rootOrgId=" +
        this.user.rootOrgId;

      Promise.all([this.$httpWithMsg.post(url)]).then(([resp]) => {
        this.roleList4Search = resp.data;
      });

      this.$httpWithMsg.get(CORE_API + "/adminOperate/types").then((resp) => {
        this.operateList = resp.data;
      });
      if (this.isSuperAdmin) {
        this.$httpWithMsg
          .get(CORE_API + "/org/getRootOrgList")
          .then((response) => {
            this.rootOrgList = response.data;
          });
      } else {
        this.searchForm.rootOrgId = this.user.rootOrgId;
        this.search();
      }
    },
  },
};
</script>

<style scoped>
.page {
  margin-top: 10px;
}
.pull-length {
  width: 300px;
}
.pull-center {
  margin-top: 20px;
}
.editForm .el-form-item {
  margin-bottom: 12px;
}
</style>
