<template>
  <section class="content">
    <div v-show="isClear == 1">
      <LinkTitlesCustom :current-paths="['基础信息', '课程属性预设']" />
    </div>
    <!-- 正文信息 -->
    <div class="box-body">
      <el-form
        :inline="true"
        :model="formSearch"
        label-position="right"
        label-width="70px"
      >
        <el-row>
          <el-col :span="6">
            <el-form-item label="属性名称">
              <el-input
                v-model="formSearch.name"
                class="search_width"
                placeholder="请输入属性名称"
                size="small"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="课程名称">
              <el-select
                v-model="formSearch.courseId"
                class="search_width"
                filterable
                :remote-method="getCourses"
                remote
                clearable
                placeholder="请选择"
                size="small"
                @focus="(e) => getCourses(e.target.value)"
              >
                <el-option
                  v-for="item in courseInfoSelect"
                  :key="item.courseId"
                  :label="item.courseInfo"
                  :value="item.courseId"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <div class="search_down">
              <el-button size="small" type="primary" @click="searchFrom"
                ><i class="el-icon-search"></i> 查询</el-button
              >
              <el-button size="small" type="primary" @click="addCourseProperty"
                ><i class="el-icon-plus"></i> 新增</el-button
              >
            </div>
          </el-col>
        </el-row>
        <div
          style="width: 100%; border-bottom: 1px solid #ddd; margin: 10px 0"
        ></div>
        <el-row>
          <el-form-item class="pull-left">
            <span>批量操作：</span>
            <el-button
              size="small"
              type="success"
              :disabled="noBatchSelected"
              @click="openCoursePropertys"
              ><i class="el-icon-check"></i> 启用</el-button
            >
            <el-button
              size="small"
              type="danger"
              :disabled="noBatchSelected"
              @click="closeCoursePropertys"
              ><i class="el-icon-close"></i> 禁用</el-button
            >
            <el-button
              size="small"
              type="primary"
              icon="el-icon-upload2"
              @click="impCourseProperty"
            >
              导入
            </el-button>
            <el-button size="small" type="primary" @click="exportCourseProperty"
              ><i class="el-icon-download"></i>导出</el-button
            >
          </el-form-item>
        </el-row>
      </el-form>
      <div style="width: 100%; margin-bottom: 10px"></div>
      <!-- 页面列表 -->
      <el-table
        v-loading="loading"
        :data="tableData"
        element-loading-text="拼命加载中"
        border
        style="width: 100%"
        @selection-change="selectionChange"
      >
        <el-table-column type="selection" width="40"></el-table-column>
        <el-table-column label="课程名称">
          <template slot-scope="scope">
            <span>{{ scope.row.courseName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="属性名称">
          <template slot-scope="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            <span v-if="scope.row.enable">
              <el-tooltip
                class="item"
                effect="dark"
                content="启用"
                placement="left"
              >
                <i class="el-icon-success" style="color: green"></i>
              </el-tooltip>
            </span>
            <span v-else>
              <el-tooltip
                class="item"
                effect="dark"
                content="禁用"
                placement="left"
              >
                <i class="el-icon-error" style="color: red"></i>
              </el-tooltip>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="260">
          <template slot-scope="scope">
            <div class="operate_left">
              <el-button
                size="mini"
                type="primary"
                plain
                @click="editCourseProperty(scope.row)"
                ><i class="el-icon-zoom-in"></i> 详情
              </el-button>
              <el-button
                v-if="!scope.row.enable"
                size="mini"
                type="primary"
                plain
                @click="openCourseProperty(scope.row)"
              >
                <i class="el-icon-check" aria-hidden="true"></i>启用
              </el-button>
              <el-button
                v-if="scope.row.enable"
                size="mini"
                type="danger"
                @click="closeCourseProperty(scope.row)"
              >
                <i class="el-icon-close" aria-hidden="true"></i>禁用
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="page pull-right">
        <el-pagination
          :current-page.sync="currentPage"
          :page-size="pageSize"
          :page-sizes="[10, 20, 50, 100, 200, 300]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="新增课程属性名称"
      :visible.sync="coursePropertyDialog"
      width="500px"
    >
      <el-form
        ref="coursePropertyForm"
        :model="coursePropertyForm"
        :rules="rules"
        label-position="right"
        label-width="90px"
        inline-message
      >
        <el-row>
          <el-form-item label="属性名称" label-width="120px" prop="name">
            <el-input
              v-model="coursePropertyForm.name"
              class="dialog_input_width"
            ></el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="课程名称" label-width="120px" prop="courseId">
            <el-select
              v-model="coursePropertyForm.courseId"
              class="dialog_input_width"
              filterable
              :remote-method="getCourses"
              remote
              clearable
              placeholder="请选择课程"
              @focus="(e) => getCourses(e.target.value)"
            >
              <el-option
                v-for="item in courseInfoSelect"
                :key="item.courseId"
                :label="item.courseInfo"
                :value="item.courseId"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-row>
        <el-row class="margin_top_10 margin_left_120">
          <el-button type="primary" @click="submit('coursePropertyForm')"
            >保 存</el-button
          >
          <el-button @click="resetForm('coursePropertyForm')"
            ><i class="el-icon-refresh"></i> 重 置</el-button
          >
          <el-button type="primary" @click="back('coursePropertyForm')"
            ><i class="el-icon-arrow-left"></i> 返 回</el-button
          >
        </el-row>
      </el-form>
    </el-dialog>
    <!-- 导入弹窗 -->
    <el-dialog title="导入窗口" width="520px" :visible.sync="impDialog">
      <el-form>
        <el-row>
          <el-form-item style="margin-left: 20px">
            <el-upload
              ref="upload"
              class="form_left"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              :action="uploadAction"
              :headers="uploadHeaders"
              :data="uploadData"
              :before-upload="beforeUpload"
              :on-progress="uploadProgress"
              :on-success="uploadSuccess"
              :on-error="uploadError"
              :file-list="fileList"
              :auto-upload="false"
              :multiple="false"
            >
              <el-button
                slot="trigger"
                size="small"
                type="primary"
                icon="el-icon-search"
              >
                选择文件
              </el-button>
              &nbsp;
              <el-button
                size="small"
                type="primary"
                icon="el-icon-check"
                @click="submitUpload"
              >
                确认上传
              </el-button>
              <el-button
                size="small"
                type="primary"
                icon="el-icon-refresh"
                @click="removeFile"
              >
                清空文件
              </el-button>
              <el-button
                size="small"
                type="primary"
                icon="el-icon-download"
                @click="exportFile"
              >
                下载模板
              </el-button>
              <div slot="tip" class="el-upload__tip">只能上传xlsx文件</div>
            </el-upload>
          </el-form-item>
        </el-row>
      </el-form>
    </el-dialog>

    <!-- 导入错误信息列表 -->
    <el-dialog title="错误提示" :visible.sync="errDialog">
      <div
        v-for="errMessage in errMessages"
        :key="errMessage.lineNum"
        class="text-danger"
      >
        第{{ errMessage.lineNum }}行：{{ errMessage.msg }}
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="errDialog = false">确定</el-button>
      </span>
    </el-dialog>
  </section>
</template>

<script>
import { CORE_API, QUESTION_API } from "@/constants/constants";
import LinkTitlesCustom from "@/components/LinkTitlesCustom.vue";
import { mapState } from "vuex";
export default {
  components: { LinkTitlesCustom },
  data() {
    return {
      formSearch: {
        name: "",
        courseId: "",
      },

      impDialog: false,
      uploadAction: QUESTION_API + "/courseProperty/import",
      uploadHeaders: {},
      uploadData: {},
      errMessages: [],
      errDialog: false,
      fileLoading: false,
      fileList: [],

      courseList: [],
      loading: false,
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 10,
      coursePropertyForm: {
        name: "",
        courseId: "",
        courseCode: "",
      },
      coursePropertyDialog: false,
      selectedList: [],
      rules: {
        name: [{ required: true, message: "请输入属性名称", trigger: "blur" }],
        courseId: [
          { required: true, message: "请选择课程名称", trigger: "change" },
        ],
      },
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    selectedIds() {
      var selectedIdsStr = "";
      for (let id of this.selectedList) {
        if (!selectedIdsStr) {
          selectedIdsStr += id;
        } else {
          selectedIdsStr += "," + id;
        }
      }
      return selectedIdsStr;
    },
    courseInfoSelect() {
      var courseList = [];
      for (var i = 0; i < this.courseList.length; i++) {
        var courseInfo = {
          courseInfo:
            this.courseList[i].name + "(" + this.courseList[i].code + ")",
          courseId: this.courseList[i].id,
        };
        courseList.push(courseInfo);
      }
      return courseList;
    },
    noBatchSelected() {
      return this.selectedList.length === 0;
    },
  },
  watch: {
    $route: "initValue",
  },
  created() {
    this.initValue();
    this.uploadHeaders = {
      key: this.user.key,
      token: this.user.token,
    };
  },

  methods: {
    exportCourseProperty() {
      var key = this.user.key;
      var token = this.user.token;
      window.open(
        QUESTION_API +
          "/courseProperty/export?$key=" +
          key +
          "&$token=" +
          token +
          "&name=" +
          this.formSearch.name +
          "&courseId=" +
          this.formSearch.courseId
      );
    },
    //导入
    impCourseProperty() {
      this.impDialog = true;
      this.initUpload();
    },
    initUpload() {
      this.fileList = [];
    },
    beforeUpload(file) {
      console.log(file);
    },
    uploadProgress() {
      console.log("uploadProgress");
    },
    uploadSuccess(response) {
      if (!response.hasError) {
        this.$notify({
          message: "上传成功",
          type: "success",
        });
        this.fileLoading = false;
        this.impDialog = false;
        this.searchCourProperty();
      } else {
        this.fileLoading = false;
        this.impDialog = false;
        this.errMessages = response.failRecords;
        this.errDialog = true;
      }
    },
    uploadError(response) {
      var json = JSON.parse(response.message);
      if (response.status == 500) {
        this.$notify({
          message: json.desc,
          type: "error",
        });
      }
      this.fileLoading = false;
    },
    //确定上传
    submitUpload() {
      if (!this.checkUpload()) {
        return false;
      }
      this.$refs.upload.submit();
      this.fileLoading = true;
    },
    checkUpload() {
      var fileList = this.$refs.upload.uploadFiles;
      if (fileList.length == 0) {
        this.$notify({
          message: "上传文件不能为空",
          type: "error",
        });
        return false;
      }
      if (fileList.length > 1) {
        this.$notify({
          message: "每次只能上传一个文件",
          type: "error",
        });
        return false;
      }
      for (let file of fileList) {
        if (!file.name.endsWith(".xlsx")) {
          this.$notify({
            message: "上传文件必须为xlsx格式",
            type: "error",
          });
          this.initUpload();
          return false;
        }
      }
      return true;
    },
    //清空文件
    removeFile() {
      // this.fileList = [];
      this.$refs.upload.clearFiles();
    },
    //下载模板
    exportFile() {
      window.location.href =
        QUESTION_API +
        "/courseProperty/importTemplate?$key=" +
        this.user.key +
        "&$token=" +
        this.user.token;
    },
    //查询所有课程属性
    searchFrom() {
      this.currentPage = 1;
      this.searchCourProperty();
    },
    searchCourProperty() {
      var pageNo = Number(this.currentPage);
      this.loading = true;
      var url =
        QUESTION_API +
        "/courseProperty/all/" +
        this.currentPage +
        "/" +
        this.pageSize;
      this.$http.get(url, { params: this.formSearch }).then((response) => {
        this.tableData = response.data.content;
        this.total = response.data.totalElements;
        this.currentPage = pageNo;
        this.loading = false;
      });
    },
    //新增
    addCourseProperty() {
      this.coursePropertyDialog = true;
      this.coursePropertyForm.name = "";
      this.coursePropertyForm.courseId = "";
    },
    //重置
    resetForm(formData) {
      this.coursePropertyForm.name = "";
      this.coursePropertyForm.courseId = "";
      this.$refs[formData].clearValidate();
    },
    //返回
    back(formData) {
      this.resetForm(formData);
      this.coursePropertyDialog = false;
    },
    //修改
    editCourseProperty(row) {
      sessionStorage.setItem("course_property_name", this.formSearch.name);
      sessionStorage.setItem(
        "course_property_courseId",
        this.formSearch.courseId
      );
      sessionStorage.setItem("course_property_currentPage", this.currentPage);
      sessionStorage.setItem("courseProperty", JSON.stringify(row));
      this.$router.push({
        path: "/questions/property_info/" + row.id,
      });
    },
    //开启
    openCourseProperty(row) {
      this.$confirm("确认开启?", "提示", {
        type: "success",
      }).then(() => {
        this.loading = true;
        this.$http
          .put(QUESTION_API + "/courseProperty/open/" + row.id)
          .then(() => {
            this.$notify({
              message: "开启成功",
              type: "success",
            });
            this.searchCourProperty();
          })
          .catch(() => {
            this.$notify({
              type: "error",
              message: "开启失败",
            });
          });
      });
    },
    //批量启用
    openCoursePropertys() {
      this.$confirm("是否确认开启？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      }).then(() => {
        this.loading = true;
        var url = QUESTION_API + "/courseProperty/opens/" + this.selectedIds;
        this.$http
          .put(url)
          .then(() => {
            this.$notify({
              type: "success",
              message: "开启成功！",
            });
            this.searchCourProperty();
            this.selectedList = [];
          })
          .catch(() => {
            this.$notify({
              type: "error",
              message: "开启失败！",
            });
            this.selectedList = [];
          });
      });
      this.loading = false;
    },
    //关闭
    closeCourseProperty(row) {
      this.$confirm("确认禁用?", "提示", {
        type: "warning",
      }).then(() => {
        this.loading = true;
        this.$http
          .put(QUESTION_API + "/courseProperty/close/" + row.id)
          .then(() => {
            this.$notify({
              message: "禁用成功",
              type: "success",
            });
            this.searchCourProperty();
          })
          .catch(() => {
            this.$notify({
              type: "error",
              message: "禁用失败",
            });
          });
      });
    },
    //批量关闭
    closeCoursePropertys() {
      this.$confirm("是否确认禁用？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      }).then(() => {
        this.loading = true;
        var url = QUESTION_API + "/courseProperty/closes/" + this.selectedIds;
        this.$http
          .put(url)
          .then(() => {
            this.$notify({
              type: "success",
              message: "禁用成功！",
            });
            this.searchCourProperty();
            this.selectedList = [];
          })
          .catch(() => {
            this.$notify({
              type: "error",
              message: "禁用失败！",
            });
            this.selectedList = [];
          });
      });
      this.loading = false;
    },
    //分页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.searchCourProperty();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.searchCourProperty();
    },
    //确定
    submit(formData) {
      this.$refs[formData].validate((valid) => {
        if (valid) {
          for (let course of this.courseList) {
            if (course.id == this.coursePropertyForm.courseId) {
              this.coursePropertyForm.courseCode = course.code;
            }
          }
          this.$http
            .post(
              QUESTION_API + "/courseProperty/save",
              this.coursePropertyForm
            )
            .then(() => {
              this.$notify({
                message: "新增成功",
                type: "success",
              });
              this.coursePropertyDialog = false;
              this.searchCourProperty();
            })
            .catch((error) => {
              this.$notify({
                type: "error",
                message: error.response.data.desc,
              });
            });
        } else {
          return false;
        }
      });
    },
    //查询所有课程
    getCourses(query) {
      query = query.trim();
      this.courseLoading = true;
      this.$http
        .get(CORE_API + "/course/query?name=" + query + "&enable=true")
        .then((response) => {
          this.courseList = response.data;
          this.courseLoading = false;
        });
    },
    removeItem() {
      sessionStorage.removeItem("course_property_name");
      sessionStorage.removeItem("course_property_courseId");
      sessionStorage.removeItem("course_property_currentPage");
    },
    //页面回填值
    initValue() {
      this.isClear = this.$route.params.isClear;
      if (this.isClear == 0 || !this.isClear) {
        this.removeItem();
        this.formSearch = {
          name: "",
          courseId: "",
        };
      } else {
        this.formSearch.name =
          sessionStorage.getItem("course_property_name") == "null"
            ? ""
            : sessionStorage.getItem("course_property_name");
        this.formSearch.courseId =
          sessionStorage.getItem("course_property_courseId") == ""
            ? ""
            : parseInt(sessionStorage.getItem("course_property_courseId"));
        this.currentPage =
          sessionStorage.getItem("course_property_currentPage") == null
            ? 1
            : parseInt(sessionStorage.getItem("course_property_currentPage"));
      }
      this.getOneCourse(this.formSearch.courseId);
      this.handleCurrentChange(this.currentPage);
    },
    //查询单个课程
    getOneCourse(courseId) {
      if (courseId) {
        this.$http.get(CORE_API + "/course/" + courseId).then((response) => {
          this.courseList.push(response.data);
        });
      } else {
        this.courseList = [];
      }
    },
    //全选
    selectionChange(val) {
      this.selectedList = [];
      var selectedList = this.selectedList;
      val.forEach((element) => {
        selectedList.push(element.id);
      });
      this.selectedList = selectedList;
    },
  },
};
</script>
<style scoped src="../styles/Common.css"></style>
