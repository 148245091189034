<template>
  <div>
    <LinkTitlesCustom :current-paths="['鉴权管理', '权限组列表', '权限配置']" />

    <section class="content">
      <div class="box box-info">
        <div class="box-body">
          <!-- 权限树 -->
          <div style="width: 80%">
            <el-tree
              ref="tree"
              :data="treeData"
              :props="defaultProps"
              node-key="id"
              highlight-current
              :default-expanded-keys="[-1]"
              :expand-on-click-node="false"
              :render-content="renderContent"
              style="padding: 10px"
            />
          </div>

          <!-- 新增权限 -->
          <el-dialog
            title="新增权限"
            width="450px"
            :close-on-click-modal="false"
            :visible.sync="addingDialog.show"
          >
            <el-form
              ref="addingForm"
              :model="addingDialog.privilege"
              inline
              inline-message
              label-width="100px"
              :rules="rules"
            >
              <el-form-item label="父权限名称" prop="parentName">
                <el-input
                  v-model="addingDialog.parentName"
                  disabled
                  class="input_width"
                />
              </el-form-item>
              <el-form-item label="权限名称" prop="name">
                <el-input
                  v-model="addingDialog.privilege.name"
                  class="input_width"
                />
              </el-form-item>
              <el-form-item label="权限编码" prop="code">
                <el-input
                  v-model="addingDialog.privilege.code"
                  class="input_width"
                />
              </el-form-item>
              <el-form-item label="描述" prop="description">
                <el-input
                  v-model="addingDialog.privilege.description"
                  class="input_width"
                />
              </el-form-item>
              <el-form-item label="权重" prop="weight">
                <el-input
                  v-model="addingDialog.privilege.weight"
                  class="input_width"
                />
              </el-form-item>
              <el-form-item label="属性1">
                <el-input
                  v-model="addingDialog.privilege.ext1"
                  class="input_width"
                />
              </el-form-item>
              <el-form-item label="属性2">
                <el-input
                  v-model="addingDialog.privilege.ext2"
                  class="input_width"
                />
              </el-form-item>
              <el-form-item label="属性3">
                <el-input
                  v-model="addingDialog.privilege.ext3"
                  class="input_width"
                />
              </el-form-item>
              <el-form-item label="属性4">
                <el-input
                  v-model="addingDialog.privilege.ext4"
                  class="input_width"
                />
              </el-form-item>
              <el-form-item label="属性5">
                <el-input
                  v-model="addingDialog.privilege.ext5"
                  class="input_width"
                />
              </el-form-item>
              <el-form-item class="d-flex justify-content-center">
                <el-button type="primary" @click="addPrivilege">确定</el-button>
                <el-button @click="addingDialog.show = false">取消</el-button>
              </el-form-item>
            </el-form>
          </el-dialog>

          <!-- 修改权限 -->
          <el-dialog
            title="修改权限"
            width="450px"
            :visible.sync="updateDialog.show"
            :close-on-click-modal="false"
          >
            <el-form
              ref="updateForm"
              :model="updateDialog.privilege"
              inline
              inline-message
              label-width="100px"
              :rules="rules"
            >
              <el-form-item label="权限名称" prop="name">
                <el-input
                  v-model="updateDialog.privilege.name"
                  class="input_width"
                />
              </el-form-item>
              <el-form-item label="权限编码" prop="code">
                <el-input
                  v-model="updateDialog.privilege.code"
                  disabled
                  class="input_width"
                />
              </el-form-item>
              <el-form-item label="描述" prop="description">
                <el-input
                  v-model="updateDialog.privilege.description"
                  class="input_width"
                />
              </el-form-item>
              <el-form-item label="权重" prop="weight">
                <el-input
                  v-model="updateDialog.privilege.weight"
                  class="input_width"
                />
              </el-form-item>
              <el-form-item label="属性1">
                <el-input
                  v-model="updateDialog.privilege.ext1"
                  class="input_width"
                />
              </el-form-item>
              <el-form-item label="属性2">
                <el-input
                  v-model="updateDialog.privilege.ext2"
                  class="input_width"
                />
              </el-form-item>
              <el-form-item label="属性3">
                <el-input
                  v-model="updateDialog.privilege.ext3"
                  class="input_width"
                />
              </el-form-item>
              <el-form-item label="属性4">
                <el-input
                  v-model="updateDialog.privilege.ext4"
                  class="input_width"
                />
              </el-form-item>
              <el-form-item label="属性5">
                <el-input
                  v-model="updateDialog.privilege.ext5"
                  class="input_width"
                />
              </el-form-item>
              <el-form-item class="d-flex justify-content-center">
                <el-button type="primary" @click="updatePrivilege"
                  >确定</el-button
                >
                <el-button @click="updateDialog.show = false">取消</el-button>
              </el-form-item>
            </el-form>
          </el-dialog>

          <!-- 查看权限 -->
          <el-dialog
            title="查看权限"
            :visible.sync="showDialog.show"
            :close-on-click-modal="false"
          >
            <el-form :model="showDialog.privilege" label-width="100px">
              <el-form-item label="权限名称">
                <el-col>
                  <el-input v-model="showDialog.privilege.name" disabled />
                </el-col>
              </el-form-item>
              <el-form-item label="权限编码">
                <el-col>
                  <el-input v-model="showDialog.privilege.code" disabled />
                </el-col>
              </el-form-item>
              <el-form-item label="描述">
                <el-col>
                  <el-input
                    v-model="showDialog.privilege.description"
                    disabled
                  />
                </el-col>
              </el-form-item>
              <el-form-item label="权重">
                <el-col>
                  <el-input v-model="showDialog.privilege.weight" disabled />
                </el-col>
              </el-form-item>
              <el-form-item label="属性1">
                <el-col>
                  <el-input v-model="showDialog.privilege.ext1" disabled />
                </el-col>
              </el-form-item>
              <el-form-item label="属性2">
                <el-col>
                  <el-input v-model="showDialog.privilege.ext2" disabled />
                </el-col>
              </el-form-item>
              <el-form-item label="属性3">
                <el-col>
                  <el-input v-model="showDialog.privilege.ext3" disabled />
                </el-col>
              </el-form-item>
              <el-form-item label="属性4">
                <el-col>
                  <el-input v-model="showDialog.privilege.ext4" disabled />
                </el-col>
              </el-form-item>
              <el-form-item label="属性5">
                <el-col>
                  <el-input v-model="showDialog.privilege.ext5" disabled />
                </el-col>
              </el-form-item>
              <el-form-item class="d-flex justify-content-center">
                <el-button @click="showDialog.show = false">关闭</el-button>
              </el-form-item>
            </el-form>
          </el-dialog>
        </div>
      </div>
    </section>
  </div>
</template>

<script type="text/jsx">
import { CORE_API } from "@/constants/constants.js";
import LinkTitlesCustom from '../../../components/LinkTitlesCustom'

let checkWeight = (rule, value, callback) => {
    if (('0' != value) && (!value)) {
        return new Error('请输入权重');
    } else if (('0' != value) && (!value.match(/^[1-9][0-9]*$/))) {
        callback(new Error('请输入整数'));
    } else if (value < 0) {
        callback(new Error('不能小于0'));
    } else if (value > 10000) {
        callback(new Error('不能大于10000'));
    } else {
        callback();
    }
};
let checkCode = (rule, value, callback) => {
    if ((0 != value) && (!value)) {
        callback(new Error('请输入权限编码'));
    } else if (!value.match(/^[0-9a-zA-Z_]*$/)) {
        callback(new Error("只能由数字、字母和\"_\"组成"));
    } else {
        callback();
    }
};


export default {
  name: "PrivilegeTree",
    components: {
      LinkTitlesCustom
    },
    data() {
        return {
            privilegeGroupId: null,
            rootTreeNode: {
                id: null,
                label: null
            },
            treeData: [],
            defaultProps: {
                children: 'children',
                label: 'label'
            },
            store: null,
            data: null,
            addingDialog: {
                show: false,
                parentName: null,
                privilege: {
                    groupId: null,
                    parentId: null,
                    name: null,
                    code: null,
                    description: null,
                    weight: null,
                    ext1: null,
                    ext2: null,
                    ext3: null,
                    ext4: null,
                    ext5: null
                }
            },
            updateDialog: {
                show: false,
                parentName: null,
                privilege: {
                    id: null,
                    groupId: null,
                    parentId: null,
                    name: null,
                    code: null,
                    description: null,
                    weight: null,
                    ext1: null,
                    ext2: null,
                    ext3: null,
                    ext4: null,
                    ext5: null
                }
            },
            showDialog: {
                show: false,
                parentName: null,
                privilege: {
                    id: null,
                    groupId: null,
                    parentId: null,
                    name: null,
                    code: null,
                    description: null,
                    weight: null,
                    ext1: null,
                    ext2: null,
                    ext3: null,
                    ext4: null,
                    ext5: null
                }
            },
            rules: {
                name: [{
                    required: true,
                    message: '请输入权限名称',
                    trigger: 'blur'
                }],
                code: [{
                    required: true,
                    message: '请输入权限编码',
                    trigger: 'blur'
                }, {
                    validator: checkCode, trigger: 'blur'
                }],
                weight: [{
                    required: true,
                    message: '请输入权重',
                    trigger: 'blur'
                }, {
                    validator: checkWeight, trigger: 'blur'
                }]
            }
        }
    },
    created() {
        this.privilegeGroupId = this.$route.params.privilegeGroupId;
        this.initTree();
    },

    methods: {
        openAddingDialog(store, data) {
            console.log(data);
            this.store = store;
            this.data = data;
            this.addingDialog.parentName = data.label;
            this.addingDialog.privilege.groupId = this.privilegeGroupId;
            if (1 == data.$treeNodeId) {
                this.addingDialog.privilege.parentId = null;
            } else {
                this.addingDialog.privilege.parentId = data.id;
            }
            this.addingDialog.privilege.name = null;
            this.addingDialog.privilege.code = null;
            this.addingDialog.privilege.description = null;
            this.addingDialog.privilege.weight = "0";
            this.addingDialog.privilege.ext1 = null;
            this.addingDialog.privilege.ext2 = null;
            this.addingDialog.privilege.ext3 = null;
            this.addingDialog.privilege.ext4 = null;
            this.addingDialog.privilege.ext5 = null;
            this.addingDialog.show = true;
        },

        addPrivilege() {
            this.$refs.addingForm.validate((valid) => {
                if (!valid) {
                    return;
                }
                var url = CORE_API + '/rolePrivilege/addPrivilege';
                this.$httpWithMsg.post(url, this.addingDialog.privilege).then((response) => {
                    this.$notify({
                        message: '添加成功',
                        type: 'success'
                    });
                    var addedPrivilege = response.data;
                    this.store.append({
                        id: addedPrivilege.id,
                        label: this.addingDialog.privilege.name,
                        children: []
                    }, this.data);
                })
                this.addingDialog.show = false;
            });
        },
        updatePrivilege() {
            this.$refs.updateForm.validate((valid) => {
                if (!valid) {
                    return;
                }
                var url = CORE_API + '/rolePrivilege/updatePrivilege';
                this.$httpWithMsg.put(url, this.updateDialog.privilege).then(() => {
                    this.$notify({
                        message: '修改成功',
                        type: 'success'
                    });
                    this.data.label = this.updateDialog.privilege.name;
                })
                this.updateDialog.show = false;
            });
        },
        openUpdateDialog(store, data) {
            this.store = store;
            this.data = data;
            this.getPrivilege(data.id, "for-update");

        },
        openShowDialog(store, data) {
            console.log(data);
            this.store = store;
            this.data = data;
            this.getPrivilege(data.id, "for-show");
        },
        getPrivilege(id, type) {
            var url = CORE_API + '/rolePrivilege/getPrivilege/' + id;
            this.$httpWithMsg.get(url).then((response) => {
                var resp = response.data;

                this.updateDialog.privilege.id = resp.id;
                this.updateDialog.privilege.groupId = resp.groupId;
                this.updateDialog.privilege.parentId = resp.parentId;
                this.updateDialog.privilege.name = resp.name;
                this.updateDialog.privilege.code = resp.code;
                this.updateDialog.privilege.description = resp.description;
                this.updateDialog.privilege.weight = resp.weight + "";
                this.updateDialog.privilege.ext1 = resp.ext1;
                this.updateDialog.privilege.ext2 = resp.ext2;
                this.updateDialog.privilege.ext3 = resp.ext3;
                this.updateDialog.privilege.ext4 = resp.ext4;
                this.updateDialog.privilege.ext5 = resp.ext5;

                this.showDialog.privilege.id = resp.id;
                this.showDialog.privilege.groupId = resp.groupId;
                this.showDialog.privilege.parentId = resp.parentId;
                this.showDialog.privilege.name = resp.name;
                this.showDialog.privilege.code = resp.code;
                this.showDialog.privilege.description = resp.description;
                this.showDialog.privilege.weight = resp.weight + "";
                this.showDialog.privilege.ext1 = resp.ext1;
                this.showDialog.privilege.ext2 = resp.ext2;
                this.showDialog.privilege.ext3 = resp.ext3;
                this.showDialog.privilege.ext4 = resp.ext4;
                this.showDialog.privilege.ext5 = resp.ext5;

                if ("for-update" == type) {
                    this.updateDialog.show = true;
                }
                else if ("for-show" == type) {
                    this.showDialog.show = true;
                }
            });
        },

        remove(store, data) {
            this.$confirm('此操作将永久删除权限 "' + data.label + '", 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                var url = CORE_API + '/rolePrivilege/deletePrivilege/' + data.id;
                return this.$httpWithMsg.delete(url).then(() => {
                    this.$notify({
                        message: '删除成功',
                        type: 'success'
                    });
                    store.remove(data);
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },

        renderContent(h, {node, data, store}) {
            if (-1 == data.id) {
                return (
                  <span>
                    <span>
                        <span>{node.label}</span>
                    </span>
                    <span style="float: right; margin-left: 20px; margin-right: 65px">
                        <el-button type="primary" size="mini" on-click={() => this.openAddingDialog(store, data)}>追加</el-button>
                    </span>
                  </span>
                )
            }
            return (
                <span>
                    <span>
                        <span>{node.label}</span>
                    </span>
                    <span style="float: right; margin-left: 20px; margin-right: 20px">
                          <el-button size="mini" type="text" on-click={() => this.openShowDialog(store, data)}>查看</el-button>
                          <el-button size="mini" type="text" on-click={() => this.openUpdateDialog(store, data)}>修改</el-button>
                          <el-button size="mini" type="text" on-click={() => this.openAddingDialog(store, data)}>追加</el-button>
                          <el-button size="mini" type="text" on-click={() => this.remove(store, data)}>删除</el-button>
                    </span>
                </span>
            );
        },

        async initTree() {
            var url = CORE_API + '/rolePrivilege/getPrivilegeTree/' + this.privilegeGroupId+"?includeDisabledCodes=true";
            const response = await this.$httpWithMsg.get(url);
            this.rootTreeNode.id = response.data.id;
            this.rootTreeNode.label = response.data.label;
            this.treeData = [];
            this.treeData.push(response.data);
        }
    }
};
</script>

<style scoped>
.input_width {
  width: 200px;
}
</style>
