<template>
  <section class="content">
    <div class="box box-info">
      <div class="box-body">
        <el-form
          ref="formSearch"
          :model="formSearch"
          :inline="true"
          label-width="70px"
        >
          <el-form-item label="考试">
            <el-select
              v-model="formSearch.examId"
              class="input"
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in examList4Search"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="学习中心">
            <el-select
              v-model="formSearch.orgId"
              class="input"
              :remote-method="getOrgList4Search"
              :loading="getOrgList4SearchLoading"
              remote
              filterable
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in orgList4Search"
                :key="item.id"
                :label="item.name + ' - ' + item.code"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="课程">
            <el-select
              v-model="formSearch.courseId"
              class="input"
              filterable
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in courseList4Search"
                :key="item.id"
                :label="item.name + ' - ' + item.code"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="学生姓名">
            <el-input v-model="formSearch.studentName" class="input" />
          </el-form-item>
          <el-form-item label="学号">
            <el-input v-model="formSearch.studentCode" class="input" />
          </el-form-item>
          <el-form-item label="证件号">
            <el-input v-model="formSearch.identityNumber" class="input" />
          </el-form-item>
          <el-form-item>
            <el-button
              size="small"
              type="primary"
              icon="el-icon-search"
              @click="resetPageAndSearchForm"
              >查询</el-button
            >
            <el-button
              size="small"
              icon="el-icon-refresh"
              @click="resetEcsFormSearch"
            >
              重置
            </el-button>
          </el-form-item>
        </el-form>
        <div class="block-seperator"></div>

        <span>操作：</span>
        <el-button
          size="small"
          type="primary"
          icon="el-icon-download"
          @click="exportHandler"
          >导出</el-button
        >
        <el-table
          :data="tableData"
          border
          style="width: 100%; text-align: center; margin-top: 10px"
        >
          <el-table-column
            prop="studentName"
            width="120"
            label="姓名"
          ></el-table-column>
          <el-table-column prop="studentCode" label="学号"></el-table-column>
          <el-table-column
            prop="identityNumber"
            label="证件号"
          ></el-table-column>
          <el-table-column prop="orgName" label="学习中心"></el-table-column>
          <el-table-column prop="courseName" label="课程"></el-table-column>
          <el-table-column
            prop="creationTime"
            width="140"
            label="操作时间"
          ></el-table-column>
          <el-table-column
            prop="operateUserName"
            width="140"
            label="操作人"
          ></el-table-column>
        </el-table>
        <div class="page pull-right">
          <el-pagination
            :current-page="currentPage"
            :page-size="pageSize"
            :page-sizes="[10, 20, 50, 100, 200, 300]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          ></el-pagination>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { CORE_API, EXAM_WORK_API } from "@/constants/constants.js";
import { mapState } from "vuex";
export default {
  name: "ReexamineRecord",
  data() {
    return {
      formSearch: {
        examId: "",
        orgId: "",
        courseId: "",
        studentName: "",
        studentCode: "",
        identityNumber: "",
      },
      getOrgList4SearchLoading: false,
      orgList4Search: [],
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 10,
      examList4Search: [],
      courseList4Search: [],
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
  },
  created() {
    this.getOrgList4Search("");
    this.queryExams4Search("");
    this.getCourses4Search("");
  },
  methods: {
    getCourses4Search(query) {
      this.courseLoading4Search = true;
      this.$httpWithMsg
        .get(CORE_API + "/course/query?name=" + query)
        .then((response) => {
          this.courseList4Search = response.data;
        });
    },
    getOrgList4Search(orgName) {
      this.getOrgList4SearchLoading = true;
      let url =
        CORE_API +
        "/org/query?rootOrgId=" +
        this.user.rootOrgId +
        "&name=" +
        orgName;
      this.$httpWithMsg
        .get(url)
        .then((response) => {
          this.getOrgList4SearchLoading = false;
          this.orgList4Search = response.data;
        })
        .catch((response) => {
          console.log(response);
          this.getOrgList4SearchLoading = false;
        });
    },
    queryExams4Search() {
      this.$httpWithMsg
        .get(EXAM_WORK_API + "/exam/queryByNameLike?enable=true&name=" + name)
        .then((response) => {
          this.examList4Search = response.data;
        });
    },
    resetPageAndSearchForm() {
      this.currentPage = 1;
      this.searchForm();
    },
    resetEcsFormSearch() {
      this.formSearch = {
        examId: "",
        orgId: "",
        courseId: "",
        studentName: "",
        studentCode: "",
        identityNumber: "",
      };
    },
    searchForm() {
      if (!this.formSearch.examId) {
        this.$notify({
          type: "warning",
          message: "请选择考试",
        });
        return;
      }
      let url = "/api/ecs_oe_admin/reexamine/log/list";
      this.$httpWithMsg
        .post(url, null, {
          params: {
            ...this.formSearch,
            pageNo: this.currentPage,
            pageSize: this.pageSize,
          },
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        })
        .then((response) => {
          this.tableData = response.data.content || [];
          this.total = response.data.totalElements;
        });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.searchForm();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.searchForm();
    },
    editItem(item) {
      this.curRow = item;
      this.showEditDialog = true;
    },
    exportHandler() {
      if (!this.formSearch.examId) {
        this.$notify({
          type: "warning",
          message: "请选择考试",
        });
        return;
      }

      this.$confirm("确定执行导出?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        var param = new URLSearchParams(this.formSearch);
        window.open(
          "/api/ecs_oe_admin/reexamine/log/list/export" +
            "?$key=" +
            this.user.key +
            "&$token=" +
            this.user.token +
            "&" +
            param
        );
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.box-body {
  .input {
    width: 200px !important;
  }
}
</style>
