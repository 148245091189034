<template>
  <div>
    <LinkTitlesCustom :current-paths="['用户管理', '数据权限']" />
    <section class="content">
      <div v-loading.body="loading" class="box box-info">
        <!-- 正文信息 -->
        <div class="box-body">
          <el-form
            ref="form"
            :inline="true"
            :model="form"
            inline-message
            label-position="right"
          >
            <div style="margin-bottom: 10px">
              <!-- <el-button type="primary" size="small">保 存</el-button> -->
              <el-button
                type="primary"
                size="small"
                icon="el-icon-arrow-left"
                @click="back"
                >返 回</el-button
              >
            </div>
            <el-tabs v-model="activeName" type="border-card">
              <el-tab-pane label="考试权限" name="tab1">
                <el-row class="mb-2">
                  <el-switch
                    v-model="form.defaultStatusExam"
                    active-text="全部考试"
                    @change="(enabled) => saveDefaultStatus('Exam', enabled)"
                  ></el-switch>
                  <div v-if="!form.defaultStatusExam" class="float-right">
                    <el-button
                      size="small"
                      type="danger"
                      icon="el-icon-delete"
                      :disabled="noBatchSelectedExam"
                      @click="deleteBatch('Exam')"
                      >删除</el-button
                    >
                    <el-button
                      size="small"
                      type="primary"
                      icon="el-icon-plus"
                      @click="addExam"
                      >添加</el-button
                    >
                  </div>
                </el-row>
                <el-row v-if="!form.defaultStatusExam">
                  <div>
                    <el-table
                      ref="tableExam"
                      :data="tableDataExam"
                      border
                      resizable
                      stripe
                      style="width: 100%"
                      @selection-change="selectChange"
                    >
                      <el-table-column type="selection" width="40" />
                      <el-table-column width="85" label="考试ID">
                        <span slot-scope="scope">{{ scope.row.examId }}</span>
                      </el-table-column>
                      <el-table-column width="200" label="考试代码">
                        <span slot-scope="scope">{{ scope.row.examCode }}</span>
                      </el-table-column>
                      <el-table-column label="考试名称">
                        <span slot-scope="scope">{{ scope.row.examName }}</span>
                      </el-table-column>
                      <el-table-column width="100" label="考试类型">
                        <span slot-scope="scope">{{
                          scope.row.examType | examTypeFilter
                        }}</span>
                      </el-table-column>
                    </el-table>
                    <div class="page pull-right">
                      <el-pagination
                        v-if="paginationShow"
                        :current-page="currentPageExam"
                        :page-size="pageSizeExam"
                        :page-sizes="[10, 20, 50, 100, 200, 300]"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="totalExam"
                        @current-change="handleCurrentChangeExam"
                        @size-change="handleSizeChangeExam"
                      />
                    </div>
                  </div>
                </el-row>
              </el-tab-pane>
              <el-tab-pane label="课程权限" name="tab2">
                <el-row class="mb-2">
                  <el-switch
                    v-model="form.defaultStatusCourse"
                    active-text="全部课程"
                    @change="(enabled) => saveDefaultStatus('Course', enabled)"
                  ></el-switch>
                  <div v-if="!form.defaultStatusCourse" class="float-right">
                    <el-button
                      size="small"
                      type="danger"
                      icon="el-icon-delete"
                      :disabled="noBatchSelectedCourse"
                      @click="deleteBatch('Course')"
                      >删除</el-button
                    >
                    <el-button
                      size="small"
                      type="primary"
                      icon="el-icon-plus"
                      @click="addCourse"
                      >添加</el-button
                    >
                  </div>
                </el-row>
                <el-row v-if="!form.defaultStatusCourse">
                  <div>
                    <el-table
                      ref="tableCourse"
                      :data="tableDataCourse"
                      border
                      resizable
                      stripe
                      style="width: 100%"
                      @selection-change="selectChange"
                    >
                      <el-table-column type="selection" width="40" />
                      <el-table-column width="85" label="课程ID">
                        <span slot-scope="scope">{{ scope.row.courseId }}</span>
                      </el-table-column>
                      <el-table-column width="200" label="课程代码">
                        <span slot-scope="scope">{{
                          scope.row.courseCode
                        }}</span>
                      </el-table-column>
                      <el-table-column label="课程名称">
                        <span slot-scope="scope">{{
                          scope.row.courseName
                        }}</span>
                      </el-table-column>
                      <el-table-column width="100" label="层次">
                        <span slot-scope="scope">{{
                          scope.row.courseLevel
                        }}</span
                        >>
                      </el-table-column>
                    </el-table>
                    <div class="page pull-right">
                      <el-pagination
                        v-if="paginationShow"
                        :current-page="currentPageCourse"
                        :page-size="pageSizeCourse"
                        :page-sizes="[10, 20, 50, 100, 200, 300]"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="totalCourse"
                        @current-change="handleCurrentChangeCourse"
                        @size-change="handleSizeChangeCourse"
                      />
                    </div>
                  </div>
                </el-row>
              </el-tab-pane>
              <el-tab-pane label="学习中心权限" name="tab3">
                <el-row class="mb-2">
                  <el-switch
                    v-model="form.defaultStatusOrg"
                    active-text="全部学习中心"
                    @change="(enabled) => saveDefaultStatus('Org', enabled)"
                  ></el-switch>
                  <div v-if="!form.defaultStatusOrg" class="float-right">
                    <el-button
                      size="small"
                      type="danger"
                      icon="el-icon-delete"
                      :disabled="noBatchSelectedOrg"
                      @click="deleteBatch('Org')"
                      >删除</el-button
                    >
                    <el-button
                      size="small"
                      type="primary"
                      icon="el-icon-plus"
                      @click="addOrg"
                      >添加</el-button
                    >
                  </div>
                </el-row>
                <el-row v-if="!form.defaultStatusOrg">
                  <div>
                    <el-table
                      ref="tableOrg"
                      :data="tableDataOrg"
                      border
                      resizable
                      stripe
                      style="width: 100%"
                      @selection-change="selectChange"
                    >
                      <el-table-column type="selection" width="40" />
                      <el-table-column width="85" label="中心ID">
                        <span slot-scope="scope">{{ scope.row.orgId }}</span>
                      </el-table-column>
                      <el-table-column width="200" label="中心代码">
                        <span slot-scope="scope">{{ scope.row.orgCode }}</span>
                      </el-table-column>
                      <el-table-column label="中心名称">
                        <span slot-scope="scope">{{ scope.row.orgName }}</span>
                      </el-table-column>
                      <el-table-column width="100" label="负责人">
                        <span slot-scope="scope">{{ scope.row.contacts }}</span
                        >>
                      </el-table-column>
                    </el-table>
                    <div class="page pull-right">
                      <el-pagination
                        v-if="paginationShow"
                        :current-page="currentPageOrg"
                        :page-size="pageSizeOrg"
                        :page-sizes="[10, 20, 50, 100, 200, 300]"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="totalOrg"
                        @current-change="handleCurrentChangeOrg"
                        @size-change="handleSizeChangeOrg"
                      />
                    </div>
                  </div>
                </el-row>
              </el-tab-pane>
            </el-tabs>
          </el-form>
        </div>
      </div>
    </section>
    <DataPrevillegeAddExamDialog
      ref="addExamDialog"
      :user-id="userId"
      :root-org-id="$route.query.rootOrgId"
      @reload="init"
    />
    <DataPrevillegeAddCourseDialog
      ref="addCourseDialog"
      :user-id="userId"
      :root-org-id="$route.query.rootOrgId"
      @reload="init"
    />
    <DataPrevillegeAddOrgDialog
      ref="addOrgDialog"
      :user-id="userId"
      :root-org-id="$route.query.rootOrgId"
      @reload="init"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { CORE_API } from "@/constants/constants.js";
import LinkTitlesCustom from "@/components/LinkTitlesCustom.vue";
import { object2QueryString } from "@/utils/utils";
import DataPrevillegeAddExamDialog from "./data_previllege_add_exam";
import DataPrevillegeAddCourseDialog from "./data_previllege_add_course";
import DataPrevillegeAddOrgDialog from "./data_previllege_add_org";

export default {
  name: "DataPrevillege",
  components: {
    LinkTitlesCustom,
    DataPrevillegeAddExamDialog,
    DataPrevillegeAddCourseDialog,
    DataPrevillegeAddOrgDialog,
  },
  data() {
    return {
      rolePrivileges: {
        user_data_rule_setting: false,
      },
      loading: false,
      style: {
        label_width_tab1: "160px",
        label_width_tab2: "160px",
        label_width_tab3: "160px",
      },
      activeName: "tab1",
      toActiveName: null,
      form: {
        defaultStatusExam: false,
        defaultStatusCourse: false,
        defaultStatusOrg: false,
      },
      userId: "",
      paginationShow: false,
      selectedChanged: false,
      tableDataExam: [],
      noBatchSelectedExam: true,
      currentPageExam: 1,
      pageSizeExam: 10,
      totalExam: 10,
      tableDataCourse: [],
      noBatchSelectedCourse: true,
      currentPageCourse: 1,
      pageSizeCourse: 10,
      totalCourse: 10,
      tableDataOrg: [],
      noBatchSelectedOrg: true,
      currentPageOrg: 1,
      pageSizeOrg: 10,
      totalOrg: 10,
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
  },
  async created() {
    await this.initPrivileges();
    if (!this.rolePrivileges.user_data_rule_setting) {
      this.$alert("没有权限访问！", "提示", {
        confirmButtonText: "确定",
        callback: () => {
          this.back();
        },
      });
      return;
    }

    this.userId = this.$route.params.userId;
    await this.init();
    this.$nextTick(function () {
      this.paginationShow = true;
    });
  },
  methods: {
    async init() {
      const reqs = [];
      const defaultPrevilleges = ["EXAM", "COURSE", "ORG"];
      for (const defP of defaultPrevilleges) {
        let url =
          CORE_API +
          "/user/data/rule/default/status?" +
          `userId=${this.userId}&type=${defP}`;
        reqs.push(this.$httpWithMsg.post(url));
      }

      const preVillegeList = ["Exam", "Course", "Org"];

      for (const defP of preVillegeList) {
        let url = CORE_API + "/user/data/rule/list/for/" + defP.toLowerCase();
        const pageNo = this.$data["currentPage" + defP];
        const pageSize = this.$data["pageSize" + defP];
        reqs.push(
          this.$httpWithMsg.post(url, {
            userId: this.userId,
            pageNo,
            pageSize,
          })
        );
      }

      try {
        this.loading = true;

        const promiseAll = await Promise.all(reqs);
        // console.log(promiseAll);
        this.form.defaultStatusExam = promiseAll[0].data;
        this.form.defaultStatusCourse = promiseAll[1].data;
        this.form.defaultStatusOrg = promiseAll[2].data;
        this.tableDataExam = promiseAll[3].data.content;
        this.tableDataCourse = promiseAll[4].data.content;
        this.tableDataOrg = promiseAll[5].data.content;
        this.totalExam = promiseAll[3].data.totalElements;
        this.totalCourse = promiseAll[4].data.totalElements;
        this.totalOrg = promiseAll[5].data.totalElements;
      } finally {
        this.loading = false;
      }
    },
    noBatchSelected(type) {
      if (!this.$refs[type + "Table"]) return false;
      console.log(this.$refs[type + "Table"].selection.length);
      return this.$refs[type + "Table"].selection.length;
    },
    selectChange() {
      this.noBatchSelectedExam =
        this.$refs.tableExam && !this.$refs.tableExam.selection.length;
      this.noBatchSelectedCourse =
        this.$refs.tableCourse && !this.$refs.tableCourse.selection.length;
      this.noBatchSelectedOrg =
        this.$refs.tableOrg && !this.$refs.tableOrg.selection.length;
    },
    handleSizeChangeExam(val) {
      this.pageSizeExam = val;
      this.currentPageExam = 1;
      this.init();
    },
    handleCurrentChangeExam(val) {
      this.currentPageExam = val;
      this.init();
    },
    handleSizeChangeCourse(val) {
      this.pageSizeCourse = val;
      this.currentPageCourse = 1;
      this.init();
    },
    handleCurrentChangeCourse(val) {
      this.currentPageCourse = val;
      this.init();
    },
    handleSizeChangeOrg(val) {
      this.pageSizeOrg = val;
      this.currentPageOrg = 1;
      this.init();
    },
    handleCurrentChangeOrg(val) {
      this.currentPageOrg = val;
      this.init();
    },
    back() {
      this.$router.back();
    },
    async saveDefaultStatus(type, enabled) {
      try {
        await this.$httpWithMsg.post(
          CORE_API +
            "/user/data/rule/default/status/update?" +
            object2QueryString({
              enabled,
              type: type.toUpperCase(),
              userId: this.userId,
            })
        );
        this.$notify({
          type: "success",
          message: "更新成功",
        });
      } catch (error) {
        this.form["defaultStatus" + type] = !enabled;
      }
    },
    addExam() {
      this.$refs.addExamDialog.openDialog();
    },
    async deleteBatch(type) {
      const refIds = this.$refs["table" + type].selection.map((v) => v.refId);

      try {
        await this.$httpWithMsg.post(CORE_API + "/user/data/rule/delete", {
          refIds,
          type: type.toUpperCase(),
          userId: this.userId,
        });

        this.$notify({
          type: "success",
          message: "删除成功",
        });
        if (
          this["tableData" + type].length == refIds.length &&
          this["currentPage" + type] * this["pageSize" + type] + 1 >
            this["total" + type]
        ) {
          this["currentPage" + type] = 1;
        }
        await this.init();
      } catch (error) {
        console.log(error);
      }
    },
    addCourse() {
      this.$refs.addCourseDialog.openDialog();
    },
    addOrg() {
      this.$refs.addOrgDialog.openDialog();
    },
    async initPrivileges() {
      let params = new URLSearchParams();
      params.append(
        "privilegeCodes",
        Object.keys(this.rolePrivileges).toString()
      );
      var url = CORE_API + "/rolePrivilege/checkPrivileges?" + params;
      await this.$httpWithMsg.post(url).then((response) => {
        this.rolePrivileges = response.data;
      });
    },
  },
};
</script>
<style scoped>
.content {
  width: 800px;
}

.input {
  width: 440px;
}
.input >>> .el-input__inner {
  -webkit-appearance: button;
}
</style>
