<template>
  <section class="content">
    <div class="box box-info">
      <div
        v-loading.body="loading"
        v-loading.fullscreen="loading"
        class="box-body"
        element-loading-text="请稍后..."
      >
        <div class="row-div">
          <el-form inline>
            <el-form-item label="展示项选择">
              <el-checkbox v-model="examCourseMainListShow.e"
                >调卷平均难度</el-checkbox
              >
              <el-checkbox v-model="examCourseMainListShow.f"
                >标准差</el-checkbox
              >
              <el-checkbox v-model="examCourseMainListShow.g"
                >差异系数</el-checkbox
              >
              <el-checkbox v-model="examCourseMainListShow.a">实考</el-checkbox>
              <el-checkbox v-model="examCourseMainListShow.b">缺考</el-checkbox>
              <el-checkbox v-model="examCourseMainListShow.c">及格</el-checkbox>
              <el-checkbox v-model="examCourseMainListShow.d"
                >分数段</el-checkbox
              >
            </el-form-item>
            <el-form-item>
              <el-button
                size="small"
                type="primary"
                icon="el-icon-download"
                @click="exportExamCourse"
                >导出</el-button
              ></el-form-item
            >
          </el-form>
        </div>
        <el-table
          :data="examCourseData.examCourseMainList"
          border
          resizable
          stripe
          style="width: 100%"
        >
          <el-table-column prop="examName" label="考试名称"> </el-table-column>
          <el-table-column prop="courseName" width="100" label="课程名称">
          </el-table-column>
          <el-table-column
            width="100"
            :sortable="true"
            prop="totalScore"
            label="满分"
          >
          </el-table-column>
          <el-table-column
            :sortable="true"
            width="100"
            prop="maxScore"
            label="最高分"
          >
          </el-table-column>
          <el-table-column
            width="100"
            :sortable="true"
            prop="minScore"
            label="最低分"
          >
          </el-table-column>
          <el-table-column
            width="100"
            :sortable="true"
            prop="avgScore"
            label="平均分"
          >
          </el-table-column>
          <el-table-column
            width="100"
            :sortable="true"
            prop="std"
            label="标准差"
          >
          </el-table-column>
          <el-table-column
            :sortable="true"
            width="100"
            prop="avgDifficultyDegree"
            label="平均调卷难度"
          >
          </el-table-column>
          <el-table-column
            :sortable="true"
            width="100"
            prop="cdi"
            label="差异系数"
          >
          </el-table-column>
          <el-table-column
            :sortable="true"
            width="100"
            prop="signCount"
            label="报名人数"
          >
          </el-table-column>
          <el-table-column
            :sortable="true"
            width="100"
            prop="participantCount"
            label="考试人数"
          >
          </el-table-column>
          <el-table-column
            :sortable="true"
            width="100"
            prop="participantRatio"
            label="考试人数/报名人数比例（%）"
          >
          </el-table-column>
          <el-table-column
            :sortable="true"
            width="100"
            prop="missCount"
            label="缺考人数"
          >
          </el-table-column>
          <el-table-column
            :sortable="true"
            width="100"
            prop="missRatio"
            label="缺考率（%）"
          >
          </el-table-column>
          <el-table-column
            :sortable="true"
            width="100"
            prop="fullCount"
            label="满分人数"
          >
          </el-table-column>
          <el-table-column
            :sortable="true"
            width="100"
            prop="zeroCount"
            label="零分人数"
          >
          </el-table-column>
          <el-table-column
            :sortable="true"
            width="100"
            prop="passCount"
            label="及格人数"
          >
          </el-table-column>
          <el-table-column
            :sortable="true"
            width="100"
            prop="passSignRatio"
            label="及格报名人数占比（%）"
          >
          </el-table-column>
          <el-table-column
            :sortable="true"
            width="100"
            prop="passParticipantRatio"
            label="及格实考人数占比（%）"
          >
          </el-table-column>
          <el-table-column label="分段统计">
            <el-table-column
              v-for="(item, index) in examCourseData.examCourseMainListCol"
              :key="index"
              :sortable="true"
              width="100"
              :prop="item.key"
              :label="item.label"
            >
            </el-table-column>
          </el-table-column>
        </el-table>
        <!-- 调卷平均难度 -->
        <div v-if="examCourseMainListShow.e" class="row-div">
          <div>
            <div>调卷平均难度前十</div>
            <el-table
              :data="examCourseData.examCourseMainTopTen.difficultyDegreeDesc"
              border
              resizable
              stripe
              style="width: 100%"
            >
              <el-table-column prop="examName" label="考试名称">
              </el-table-column>
              <el-table-column prop="courseName" width="100" label="课程名称">
              </el-table-column>
              <el-table-column
                width="100"
                prop="avgDifficultyDegree"
                label="调卷平均难度"
              >
              </el-table-column>
            </el-table>
          </div>
          <div>
            <v-charts
              :options="examCourseData.difficultyDegreeDescBar"
            ></v-charts>
          </div>
          <div>
            <div>调卷平均难度后十</div>
            <el-table
              :data="examCourseData.examCourseMainTopTen.difficultyDegree"
              border
              resizable
              stripe
              style="width: 100%"
            >
              <el-table-column prop="examName" label="考试名称">
              </el-table-column>
              <el-table-column prop="courseName" width="100" label="课程名称">
              </el-table-column>
              <el-table-column
                width="100"
                prop="avgDifficultyDegree"
                label="调卷平均难度"
              >
              </el-table-column>
            </el-table>
          </div>
        </div>
        <!-- 标准差 -->
        <div v-if="examCourseMainListShow.f" class="row-div">
          <div>
            <div>标准差前十</div>
            <el-table
              :data="examCourseData.examCourseMainTopTen.stdDesc"
              border
              resizable
              stripe
              style="width: 100%"
            >
              <el-table-column prop="examName" label="考试名称">
              </el-table-column>
              <el-table-column prop="courseName" width="100" label="课程名称">
              </el-table-column>
              <el-table-column width="100" prop="std" label="标准差">
              </el-table-column>
            </el-table>
          </div>
          <div><v-charts :options="examCourseData.stdDescBar"></v-charts></div>
          <div>
            <div>标准差后十</div>
            <el-table
              :data="examCourseData.examCourseMainTopTen.std"
              border
              resizable
              stripe
              style="width: 100%"
            >
              <el-table-column prop="examName" label="考试名称">
              </el-table-column>
              <el-table-column prop="courseName" width="100" label="课程名称">
              </el-table-column>
              <el-table-column width="100" prop="std" label="标准差">
              </el-table-column>
            </el-table>
          </div>
        </div>
        <!-- 差异系数 -->
        <div v-if="examCourseMainListShow.g" class="row-div">
          <div>
            <div>差异系数前十</div>
            <el-table
              :data="examCourseData.examCourseMainTopTen.cdiDesc"
              border
              resizable
              stripe
              style="width: 100%"
            >
              <el-table-column prop="examName" label="考试名称">
              </el-table-column>
              <el-table-column prop="courseName" width="100" label="课程名称">
              </el-table-column>
              <el-table-column width="100" prop="cdi" label="差异系数">
              </el-table-column>
            </el-table>
          </div>
          <div><v-charts :options="examCourseData.cdiDescBar"></v-charts></div>
          <div>
            <div>差异系数后十</div>
            <el-table
              :data="examCourseData.examCourseMainTopTen.cdi"
              border
              resizable
              stripe
              style="width: 100%"
            >
              <el-table-column prop="examName" label="考试名称">
              </el-table-column>
              <el-table-column prop="courseName" width="100" label="课程名称">
              </el-table-column>
              <el-table-column width="100" prop="cdi" label="差异系数">
              </el-table-column>
            </el-table>
          </div>
        </div>
        <!-- 实考人数 -->
        <div v-if="examCourseMainListShow.a" class="row-div">
          <div>
            <div>实考人数前十</div>
            <el-table
              :data="examCourseData.examCourseMainTopTen.participantDesc"
              border
              resizable
              stripe
              style="width: 100%"
            >
              <el-table-column prop="examName" label="考试名称">
              </el-table-column>
              <el-table-column prop="courseName" width="100" label="课程名称">
              </el-table-column>
              <el-table-column
                width="100"
                prop="participantCount"
                label="实考人数"
              >
              </el-table-column>
            </el-table>
          </div>
          <div>
            <v-charts :options="examCourseData.participantDescBar"></v-charts>
          </div>
          <div>
            <div>实考人数后十</div>
            <el-table
              :data="examCourseData.examCourseMainTopTen.participant"
              border
              resizable
              stripe
              style="width: 100%"
            >
              <el-table-column prop="examName" label="考试名称">
              </el-table-column>
              <el-table-column prop="courseName" width="100" label="课程名称">
              </el-table-column>
              <el-table-column
                width="100"
                prop="participantCount"
                label="实考人数"
              >
              </el-table-column>
            </el-table>
          </div>
        </div>
        <!-- 实考比例 -->
        <div v-if="examCourseMainListShow.a" class="row-div">
          <div>
            <div>实考比例前十</div>
            <el-table
              :data="examCourseData.examCourseMainTopTen.participantRatioDesc"
              border
              resizable
              stripe
              style="width: 100%"
            >
              <el-table-column prop="examName" label="考试名称">
              </el-table-column>
              <el-table-column prop="courseName" width="100" label="课程名称">
              </el-table-column>
              <el-table-column
                width="100"
                prop="participantRatio"
                label="实考比例（%）"
              >
              </el-table-column>
            </el-table>
          </div>
          <div>
            <v-charts
              :options="examCourseData.participantRatioDescBar"
            ></v-charts>
          </div>
          <div>
            <div>实考比例后十</div>
            <el-table
              :data="examCourseData.examCourseMainTopTen.participantRatio"
              border
              resizable
              stripe
              style="width: 100%"
            >
              <el-table-column prop="examName" label="考试名称">
              </el-table-column>
              <el-table-column prop="courseName" width="100" label="课程名称">
              </el-table-column>
              <el-table-column
                width="100"
                prop="participantRatio"
                label="实考比例（%）"
              >
              </el-table-column>
            </el-table>
          </div>
        </div>
        <!-- 缺考人数 -->
        <div v-if="examCourseMainListShow.b" class="row-div">
          <div>
            <div>缺考人数前十</div>
            <el-table
              :data="examCourseData.examCourseMainTopTen.missDesc"
              border
              resizable
              stripe
              style="width: 100%"
            >
              <el-table-column prop="examName" label="考试名称">
              </el-table-column>
              <el-table-column prop="courseName" width="100" label="课程名称">
              </el-table-column>
              <el-table-column width="100" prop="missCount" label="缺考人数">
              </el-table-column>
            </el-table>
          </div>
          <div><v-charts :options="examCourseData.missDescBar"></v-charts></div>
          <div>
            <div>缺考人数后十</div>
            <el-table
              :data="examCourseData.examCourseMainTopTen.miss"
              border
              resizable
              stripe
              style="width: 100%"
            >
              <el-table-column prop="examName" label="考试名称">
              </el-table-column>
              <el-table-column prop="courseName" width="100" label="课程名称">
              </el-table-column>
              <el-table-column width="100" prop="missCount" label="缺考人数">
              </el-table-column>
            </el-table>
          </div>
        </div>
        <!-- 缺考比例 -->
        <div v-if="examCourseMainListShow.b" class="row-div">
          <div>
            <div>缺考比例前十</div>
            <el-table
              :data="examCourseData.examCourseMainTopTen.missRatioDesc"
              border
              resizable
              stripe
              style="width: 100%"
            >
              <el-table-column prop="examName" label="考试名称">
              </el-table-column>
              <el-table-column prop="courseName" width="100" label="课程名称">
              </el-table-column>
              <el-table-column
                width="100"
                prop="missRatio"
                label="缺考比例（%）"
              >
              </el-table-column>
            </el-table>
          </div>
          <div>
            <v-charts :options="examCourseData.missRatioDescBar"></v-charts>
          </div>
          <div>
            <div>缺考比例后十</div>
            <el-table
              :data="examCourseData.examCourseMainTopTen.missRatio"
              border
              resizable
              stripe
              style="width: 100%"
            >
              <el-table-column prop="examName" label="考试名称">
              </el-table-column>
              <el-table-column prop="courseName" width="100" label="课程名称">
              </el-table-column>
              <el-table-column
                width="100"
                prop="missRatio"
                label="缺考比例（%）"
              >
              </el-table-column>
            </el-table>
          </div>
        </div>
        <!-- 及格人数 -->
        <div v-if="examCourseMainListShow.c" class="row-div">
          <div>
            <div>及格人数前十</div>
            <el-table
              :data="examCourseData.examCourseMainTopTen.passDesc"
              border
              resizable
              stripe
              style="width: 100%"
            >
              <el-table-column prop="examName" label="考试名称">
              </el-table-column>
              <el-table-column prop="courseName" width="100" label="课程名称">
              </el-table-column>
              <el-table-column width="100" prop="passCount" label="及格人数">
              </el-table-column>
            </el-table>
          </div>
          <div><v-charts :options="examCourseData.passDescBar"></v-charts></div>
          <div>
            <div>及格人数后十</div>
            <el-table
              :data="examCourseData.examCourseMainTopTen.pass"
              border
              resizable
              stripe
              style="width: 100%"
            >
              <el-table-column prop="examName" label="考试名称">
              </el-table-column>
              <el-table-column prop="courseName" width="100" label="课程名称">
              </el-table-column>
              <el-table-column width="100" prop="passCount" label="及格人数">
              </el-table-column>
            </el-table>
          </div>
        </div>
        <!-- 及格实考比例 -->
        <div v-if="examCourseMainListShow.c" class="row-div">
          <div>
            <div>及格实考比例前十</div>
            <el-table
              :data="
                examCourseData.examCourseMainTopTen.passParticipantRatioDesc
              "
              border
              resizable
              stripe
              style="width: 100%"
            >
              <el-table-column prop="examName" label="考试名称">
              </el-table-column>
              <el-table-column prop="courseName" width="100" label="课程名称">
              </el-table-column>
              <el-table-column
                width="100"
                prop="passParticipantRatio"
                label="及格实考比例（%）"
              >
              </el-table-column>
            </el-table>
          </div>
          <div>
            <v-charts
              :options="examCourseData.passParticipantRatioDescBar"
            ></v-charts>
          </div>
          <div>
            <div>及格实考比例后十</div>
            <el-table
              :data="examCourseData.examCourseMainTopTen.passParticipantRatio"
              border
              resizable
              stripe
              style="width: 100%"
            >
              <el-table-column prop="examName" label="考试名称">
              </el-table-column>
              <el-table-column prop="courseName" width="100" label="课程名称">
              </el-table-column>
              <el-table-column
                width="100"
                prop="passParticipantRatio"
                label="及格实考比例（%）"
              >
              </el-table-column>
            </el-table>
          </div>
        </div>
        <span
          v-if="
            examCourseData.examCourseMainTopTen.partition != null &&
            examCourseMainListShow.d
          "
        >
          <span v-for="(item, index) in formSearch.partitionCount" :key="index">
            <!-- 分段人数 -->
            <div class="row-div">
              <div>
                <div>分段{{ index + 1 }}人数前十</div>
                <el-table
                  :data="
                    examCourseData.examCourseMainTopTen.partition[index]
                      .countDesc
                  "
                  border
                  resizable
                  stripe
                  style="width: 100%"
                >
                  <el-table-column prop="examName" label="考试名称">
                  </el-table-column>
                  <el-table-column
                    prop="courseName"
                    width="100"
                    label="课程名称"
                  >
                  </el-table-column>
                  <el-table-column
                    width="100"
                    prop="count"
                    :label="'分段' + (index + 1) + '人数'"
                  >
                  </el-table-column>
                </el-table>
              </div>
              <div>
                <v-charts
                  :options="examCourseData.partitionCountDescBar[index]"
                ></v-charts>
              </div>
              <div>
                <div>分段{{ index + 1 }}人数后十</div>
                <el-table
                  :data="
                    examCourseData.examCourseMainTopTen.partition[index]
                      .countAsc
                  "
                  border
                  resizable
                  stripe
                  style="width: 100%"
                >
                  <el-table-column prop="examName" label="考试名称">
                  </el-table-column>
                  <el-table-column
                    prop="courseName"
                    width="100"
                    label="课程名称"
                  >
                  </el-table-column>
                  <el-table-column
                    width="100"
                    prop="count"
                    :label="'分段' + (index + 1) + '人数'"
                  >
                  </el-table-column>
                </el-table>
              </div>
            </div>
            <!-- 分段实考比例 -->
            <div class="row-div">
              <div>
                <div>分段{{ index + 1 }}实考比例前十</div>
                <el-table
                  :data="
                    examCourseData.examCourseMainTopTen.partition[index]
                      .participantRatioDesc
                  "
                  border
                  resizable
                  stripe
                  style="width: 100%"
                >
                  <el-table-column prop="examName" label="考试名称">
                  </el-table-column>
                  <el-table-column
                    prop="courseName"
                    width="100"
                    label="课程名称"
                  >
                  </el-table-column>
                  <el-table-column
                    width="100"
                    prop="participantRatio"
                    :label="'分段' + (index + 1) + '实考比例（%）'"
                  >
                  </el-table-column>
                </el-table>
              </div>
              <div>
                <v-charts
                  :options="
                    examCourseData.partitionParticipantRatioDescBar[index]
                  "
                ></v-charts>
              </div>
              <div>
                <div>分段{{ index + 1 }}实考比例后十</div>
                <el-table
                  :data="
                    examCourseData.examCourseMainTopTen.partition[index]
                      .participantRatioAsc
                  "
                  border
                  resizable
                  stripe
                  style="width: 100%"
                >
                  <el-table-column prop="examName" label="考试名称">
                  </el-table-column>
                  <el-table-column
                    prop="courseName"
                    width="100"
                    label="课程名称"
                  >
                  </el-table-column>
                  <el-table-column
                    width="100"
                    prop="participantRatio"
                    :label="'分段' + (index + 1) + '实考比例（%）'"
                  >
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </span>
        </span>
      </div>
    </div>
  </section>
</template>
<script>
import { REPORTS_API } from "@/constants/constants.js";
import { mapState } from "vuex";
import ECharts from "vue-echarts/components/ECharts";
import "echarts/lib/chart/bar";
export default {
  name: "ReportView",
  components: {
    "v-charts": ECharts,
  },
  data() {
    return {
      loading: false,
      formSearch: {
        name: "",
        analyseTypeName: "",
        projectId: "",
        partitionCount: 0,
      },
      //考试课程
      examCourseMainListShow: {
        a: true,
        b: true,
        c: true,
        d: true,
        e: true,
        f: true,
        g: true,
      },
      examCourseData: {
        examCourseMainList: [],
        examCourseMainListCol: [],
        examCourseMainTopTen: {},
        participantDescBar: this.getBar("实考人数前十", "实考人数"),
        participantRatioDescBar: this.getBar("实考比例前十", "实考比例"),
        missDescBar: this.getBar("缺考人数前十", "缺考人数"),
        missRatioDescBar: this.getBar("缺考比例前十", "缺考比例"),
        passDescBar: this.getBar("及格人数前十", "及格人数"),
        passParticipantRatioDescBar: this.getBar(
          "及格实考比例前十",
          "及格实考比例"
        ),
        partitionCountDescBar: [],
        partitionParticipantRatioDescBar: [],
        difficultyDegreeDescBar: this.getBar(
          "调卷平均难度前十",
          "调卷平均难度"
        ),
        stdDescBar: this.getBar("标准差前十", "标准差"),
        cdiDescBar: this.getBar("差异系数前十", "差异系数"),
      },
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
    isSuperAdmin() {
      return this.user.roleList.some((role) => role.roleCode == "SUPER_ADMIN");
    },
  },
  //初始化查询
  created() {
    this.formSearch.projectId = this.$route.params.projectId;
    this.init();
  },
  methods: {
    exportExamCourse() {
      window.open(
        REPORTS_API +
          "/examCourseDataReport/export?$key=" +
          this.user.key +
          "&$token=" +
          this.user.token +
          "&projectId=" +
          this.formSearch.projectId
      );
    },
    setBarData(bar, seriesName, data, fieldName, xfieldName) {
      bar.series = [
        {
          name: seriesName,
          type: "bar",
          barWidth: "20",
          data: this.getYdata(data, fieldName),
        },
      ];
      bar.xAxis = [
        {
          type: "category",
          data: this.getXdata(data, xfieldName),
          axisTick: {
            alignWithLabel: true,
          },
          axisLabel: {
            interval: 0,
            rotate: 20,
          },
        },
      ];
    },
    getBar(text, seriesName) {
      return {
        color: ["#3398DB"],
        title: {
          text: text,
          left: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c}",
        },
        xAxis: [
          {
            type: "category",
            axisTick: {
              alignWithLabel: true,
            },
            axisLabel: {
              interval: 0,
              rotate: 30,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: seriesName,
            type: "bar",
          },
        ],
      };
    },
    getXdata(data, xfieldName) {
      let xdata = [];
      data.forEach((e) => {
        xdata.push(e.examName + "-" + e[xfieldName]);
      });
      return xdata;
    },
    getYdata(data, col) {
      let ydata = [];
      data.forEach((e) => {
        ydata.push(e[col]);
      });
      return ydata;
    },
    setViewCols(partitionData, cols) {
      for (var i = 0; i < this.formSearch.partitionCount; i++) {
        let coldata1 = {};
        coldata1.label = "分段" + (i + 1) + "人数";
        coldata1.key = "partitionCount" + i;
        cols.push(coldata1);
        let coldata2 = {};
        coldata2.label = "分段" + (i + 1) + "人数实考占比（%）";
        coldata2.key = "participantRatio" + i;
        cols.push(coldata2);
      }
      partitionData.forEach((e) => {
        for (var i = 0; i < e.partitionData.length; i++) {
          let key1 = "partitionCount" + i;
          e[key1] = e.partitionData[i].count;
          let key2 = "participantRatio" + i;
          e[key2] = e.partitionData[i].participantRatio;
        }
      });
    },
    //查询
    getExamCourseMainList() {
      var url =
        REPORTS_API +
        "/examCourseDataReport/getExamCourseMainList?projectId=" +
        this.formSearch.projectId;
      this.$httpWithMsg.get(url).then((response) => {
        this.examCourseData.examCourseMainList = response.data;
        this.setViewCols(
          this.examCourseData.examCourseMainList,
          this.examCourseData.examCourseMainListCol
        );
      });
    },
    getExamCourseMainTopTen() {
      var url =
        REPORTS_API +
        "/examCourseDataReport/getExamCourseMainTop10?projectId=" +
        this.formSearch.projectId;
      this.$httpWithMsg.get(url).then((response) => {
        this.examCourseData.examCourseMainTopTen = response.data;

        this.setBarData(
          this.examCourseData.difficultyDegreeDescBar,
          "调卷平均难度",
          this.examCourseData.examCourseMainTopTen.difficultyDegreeDesc,
          "avgDifficultyDegree",
          "courseName"
        );
        this.setBarData(
          this.examCourseData.stdDescBar,
          "标准差",
          this.examCourseData.examCourseMainTopTen.stdDesc,
          "std",
          "courseName"
        );
        this.setBarData(
          this.examCourseData.cdiDescBar,
          "差异系数",
          this.examCourseData.examCourseMainTopTen.cdiDesc,
          "cdi",
          "courseName"
        );
        this.setBarData(
          this.examCourseData.participantDescBar,
          "实考人数",
          this.examCourseData.examCourseMainTopTen.participantDesc,
          "participantCount",
          "courseName"
        );
        this.setBarData(
          this.examCourseData.participantRatioDescBar,
          "实考比例",
          this.examCourseData.examCourseMainTopTen.participantRatioDesc,
          "participantRatio",
          "courseName"
        );
        this.setBarData(
          this.examCourseData.missDescBar,
          "缺考人数",
          this.examCourseData.examCourseMainTopTen.missDesc,
          "missCount",
          "courseName"
        );
        this.setBarData(
          this.examCourseData.missRatioDescBar,
          "缺考比例",
          this.examCourseData.examCourseMainTopTen.missRatioDesc,
          "missRatio",
          "courseName"
        );
        this.setBarData(
          this.examCourseData.passDescBar,
          "及格人数",
          this.examCourseData.examCourseMainTopTen.passDesc,
          "passCount",
          "courseName"
        );
        this.setBarData(
          this.examCourseData.passParticipantRatioDescBar,
          "及格实考比例",
          this.examCourseData.examCourseMainTopTen.passParticipantRatioDesc,
          "passParticipantRatio",
          "courseName"
        );
        for (let i = 0; i < this.formSearch.partitionCount; i++) {
          this.setBarData(
            this.examCourseData.partitionCountDescBar[i],
            "分段" + (i + 1) + "人数",
            this.examCourseData.examCourseMainTopTen.partition[i].countDesc,
            "count",
            "courseName"
          );
          this.setBarData(
            this.examCourseData.partitionParticipantRatioDescBar[i],
            "分段" + (i + 1) + "实考比例",
            this.examCourseData.examCourseMainTopTen.partition[i]
              .participantRatioDesc,
            "participantRatio",
            "courseName"
          );
        }
      });
    },
    async init() {
      var url = REPORTS_API + "/project/" + this.formSearch.projectId;
      await this.$httpWithMsg.get(url).then((response) => {
        this.formSearch.name = response.data.name;
        this.formSearch.analyseTypeName = response.data.analyseTypeName;
        this.formSearch.partitionCount = response.data.partitionCount;
        for (let i = 0; i < this.formSearch.partitionCount; i++) {
          this.examCourseData.partitionCountDescBar[i] = this.getBar(
            "分段" + (i + 1) + "人数前十",
            "分段" + (i + 1) + "人数"
          );
          this.examCourseData.partitionParticipantRatioDescBar[i] = this.getBar(
            "分段" + (i + 1) + "实考比例前十",
            "分段" + (i + 1) + "实考比例"
          );
        }
      });
      this.getExamCourseMainList();
      this.getExamCourseMainTopTen();
    },
  },
};
</script>

<style scoped>
.row-div {
  display: flex;
  overflow-x: auto;
  border: 1px solid #ddd;
  padding: 5px;
  margin-top: 10px;
}
</style>
