<template>
  <section class="content">
    <div class="box box-info">
      <!-- 正文信息 -->
      <div class="box-body">
        <!-- 搜索表单 -->
        <el-form :inline="true" :model="formSearch" label-width="70px">
          <el-form-item label="考试">
            <el-select
              v-model="formSearch.examId"
              class="input"
              :remote-method="queryExams4Search"
              remote
              :loading="queryExams4SearchLoading"
              filterable
              placeholder="请选择"
              @change="handleExamChange4Search"
            >
              <el-option
                v-for="item in examList4Search"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="场次">
            <el-select
              v-model="formSearch.examStageId"
              clearable
              :disabled="examStageDisabled4Search"
              class="input"
              :remote-method="queryExamStages4Search"
              remote
              :loading="queryExamStages4SearchLoading"
              :filterable="true"
              placeholder="请选择"
            >
              <el-option
                v-for="item in examStageList4Search"
                :key="item.id"
                :label="`${item.stageOrder} (${item.startTime}至${item.endTime})`"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="姓名">
            <el-input
              v-model="formSearch.studentName"
              class="input"
              placeholder="请输入姓名"
            ></el-input>
          </el-form-item>
          <el-form-item label="学号">
            <el-input
              v-model="formSearch.studentCode"
              class="input"
              placeholder="请输入学号"
            ></el-input>
          </el-form-item>
          <el-form-item label="专业">
            <el-input
              v-model="formSearch.specialtyName"
              class="input"
              placeholder="请输入专业"
            ></el-input>
          </el-form-item>
          <el-form-item label="课程">
            <el-select
              v-model="formSearch.courseId"
              class="input"
              :remote-method="getCourses4Search"
              :loading="courseLoading4Search"
              remote
              filterable
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in courseList4Search"
                :key="item.id"
                :label="item.name + ' - ' + item.code"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="学习中心">
            <el-select
              v-model="formSearch.orgId"
              class="input"
              :remote-method="getOrgList4Search"
              :loading="getOrgList4SearchLoading"
              remote
              filterable
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in orgList4Search"
                :key="item.id"
                :label="item.name + ' - ' + item.code"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="采集人">
            <el-input
              v-model="formSearch.infoCollector"
              class="input"
              placeholder="请输入采集人"
            ></el-input>
          </el-form-item>
          <el-form-item label="身份证">
            <el-input
              v-model="formSearch.identityNumber"
              class="input"
              placeholder="请输入身份证"
            ></el-input>
          </el-form-item>
          <el-form-item label="考点">
            <el-input
              v-model="formSearch.examSite"
              class="input"
              placeholder="请输入考点"
            ></el-input>
          </el-form-item>

          <el-form-item label="状态">
            <el-select v-model="formSearch.enable" class="input">
              <el-option label="未选择" value="UNDEFINED"></el-option>
              <el-option label="已启用" value="TRUE"></el-option>
              <el-option label="已禁用" value="FALSE"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item class="d-block">
            <el-button
              v-if="rolePrivileges.search_examStudent"
              size="small"
              type="primary"
              icon="el-icon-search"
              @click="resetPageAndSearchForm"
              >查询</el-button
            >
            <el-button
              size="small"
              icon="el-icon-refresh"
              @click="resetSearchForm"
              >重置</el-button
            >
            <el-button
              v-if="rolePrivileges.add_examStudent"
              size="small"
              type="primary"
              icon="el-icon-plus"
              @click="openAddingDialog"
              >新增</el-button
            >
            <el-button
              v-if="rolePrivileges.copy_examStudent"
              size="small"
              type="primary"
              icon="el-icon-document"
              @click="copy"
              >复制</el-button
            >
            <el-button
              v-if="rolePrivileges.del_examStudent"
              size="small"
              type="danger"
              icon="el-icon-delete"
              @click="deleteStuByExam"
              >按考试删除</el-button
            >
          </el-form-item>
        </el-form>

        <div class="block-seperator"></div>
        <div>
          <span>批量操作：</span>

          <el-button
            v-if="rolePrivileges.change_exam_student_availability"
            size="small"
            type="success"
            icon="el-icon-check"
            :disabled="noBatchSelected"
            @click="enableExamStudent('')"
            >启用</el-button
          >
          <el-button
            v-if="rolePrivileges.change_exam_student_availability"
            size="small"
            type="danger"
            icon="el-icon-close"
            :disabled="noBatchSelected"
            @click="disableExamStudent('')"
            >禁用</el-button
          >

          <el-button
            v-if="rolePrivileges.del_examStudent"
            size="small"
            type="danger"
            icon="el-icon-delete"
            :disabled="noBatchSelected"
            @click="deleteStuBatch"
            >删除</el-button
          >
        </div>

        <div style="width: 100%; margin-bottom: 10px"></div>

        <!-- 新增弹出窗口 -->
        <el-dialog
          title="考生信息页面"
          :visible.sync="addingStudentDialog"
          width="650px"
          @close="closeAddingStudentDialog"
        >
          <el-form
            ref="addingStudentForm"
            :model="examStudentForm"
            :rules="rules"
            :inline="true"
            label-width="85px"
            class="editForm"
          >
            <el-form-item label="考试" prop="examId">
              <el-select
                v-model="examStudentForm.examId"
                class="input"
                :remote-method="queryExams4InsertOrUpdate"
                :loading="queryExams4InsertOrUpdateLoading"
                remote
                filterable
                clearable
                placeholder="请选择"
                @change="handleExamChange4Insert"
              >
                <el-option
                  v-for="item in examList4InsertOrUpdate"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  :disabled="!item.enable"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="场次">
              <el-select
                v-model="examStudentForm.examStageId"
                class="input"
                :disabled="examStageDisabled4Insert"
                :remote-method="queryExamStages4Insert"
                remote
                clearable
                :loading="queryExamStages4InsertLoading"
                :filterable="true"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in examStageList4Insert"
                  :key="item.id"
                  :label="`${item.stageOrder} (${item.startTime}至${item.endTime})`"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="姓名" prop="studentName">
              <el-input
                v-model="examStudentForm.studentName"
                class="input"
                auto-complete="off"
                maxlength="20"
              ></el-input>
            </el-form-item>
            <el-form-item label="学号" prop="studentCode">
              <el-input
                v-model="examStudentForm.studentCode"
                class="input"
                auto-complete="off"
                maxlength="20"
              ></el-input>
            </el-form-item>
            <el-form-item label="身份证号" prop="identityNumber">
              <el-input
                v-model="examStudentForm.identityNumber"
                class="input"
                auto-complete="off"
                maxlength="20"
              ></el-input>
            </el-form-item>
            <el-form-item label="学习中心" prop="orgId">
              <el-select
                v-model="examStudentForm.orgId"
                class="input"
                :remote-method="getOrgList4InsertOrUpdate"
                :loading="getOrgList4InsertOrUpdateLoading"
                remote
                filterable
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in orgList4InsertOrUpdate"
                  :key="item.id"
                  :label="item.name + ' - ' + item.code"
                  :value="item.id"
                  :disabled="!item.enable"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="课程" prop="courseId">
              <el-select
                v-model="examStudentForm.courseId"
                :remote-method="getCourses4InsertOrUpdate"
                :loading="courseLoading4InsertOrUpdate"
                remote
                filterable
                clearable
                class="input"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in courseList4InsertOrUpdate"
                  :key="item.id"
                  :label="item.name + ' - ' + item.code"
                  :value="item.id"
                  :disabled="!item.enable"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="试卷类型" prop="paperType">
              <el-select
                v-model="examStudentForm.paperType"
                class="input"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in paperTypeList"
                  :key="item"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="专业">
              <el-input
                v-model="examStudentForm.specialtyName"
                class="input"
                auto-complete="off"
                maxlength="20"
              ></el-input>
            </el-form-item>
            <el-form-item label="考点">
              <el-input
                v-model="examStudentForm.examSite"
                class="input"
                auto-complete="off"
                maxlength="20"
              ></el-input>
            </el-form-item>
            <el-form-item label="年级">
              <el-input
                v-model="examStudentForm.grade"
                class="input"
                auto-complete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="采集人">
              <el-input
                v-model="examStudentForm.infoCollector"
                class="input"
                maxlength="20"
              ></el-input>
            </el-form-item>
            <el-form-item label="电话">
              <el-input
                v-model="examStudentForm.phone"
                class="input"
                auto-complete="off"
                maxlength="20"
              ></el-input>
            </el-form-item>
            <el-form-item label="试卷袋编码">
              <el-input
                v-model="examStudentForm.ext1"
                class="input"
                maxlength="20"
              ></el-input>
            </el-form-item>
          </el-form>
          <div style="text-align: center">
            <el-button type="primary" @click="addStudent">确 定</el-button>
            <el-button @click="closeAddingStudentDialog">取 消</el-button>
          </div>
        </el-dialog>

        <!-- 修改弹出窗口 -->
        <el-dialog
          width="650px"
          title="考生信息页面"
          :visible.sync="updateStudentDialog"
          @close="closeUpdateStudentDialog"
        >
          <el-form
            ref="updateStudentForm"
            :model="examStudentForm"
            size="large"
            :rules="rules"
            :inline="true"
            label-width="85px"
            class="editForm"
          >
            <el-form-item label="考试" prop="examId">
              <el-select
                v-model="examStudentForm.examId"
                class="input"
                :remote-method="queryExams4InsertOrUpdate"
                :loading="queryExams4InsertOrUpdateLoading"
                remote
                disabled
                filterable
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in examList4InsertOrUpdate"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  :disabled="!item.enable"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="场次">
              <el-select
                v-model="examStudentForm.examStageId"
                class="input"
                :disabled="examStageDisabled4Update"
                :remote-method="queryExamStages4Update"
                remote
                clearable
                :loading="queryExamStages4UpdateLoading"
                :filterable="true"
                placeholder="请选择"
                @focus="(e) => queryExamStages4Update(e.target.value)"
              >
                <el-option
                  v-for="item in examStageList4Update"
                  :key="item.id"
                  :label="`${item.stageOrder} (${item.startTime}至${item.endTime})`"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="姓名" prop="studentName">
              <el-input
                v-model="examStudentForm.studentName"
                class="input"
                auto-complete="off"
                maxlength="20"
              ></el-input>
            </el-form-item>
            <el-form-item label="学号" prop="studentCode">
              <el-input
                v-model="examStudentForm.studentCode"
                class="input"
                maxlength="20"
                readonly
              ></el-input>
            </el-form-item>
            <el-form-item label="身份证号" prop="identityNumber">
              <el-input
                v-model="examStudentForm.identityNumber"
                class="input"
                maxlength="20"
                readonly
              ></el-input>
            </el-form-item>
            <el-form-item label="学习中心" prop="orgId">
              <el-select
                v-model="examStudentForm.orgId"
                class="input"
                :remote-method="getOrgList4InsertOrUpdate"
                :loading="getOrgList4InsertOrUpdateLoading"
                remote
                filterable
                clearable
                placeholder="请选择"
                @clear="clearOrg4UpdateExamStudent"
              >
                <el-option
                  v-for="item in orgList4InsertOrUpdate"
                  :key="item.id"
                  :label="item.name + ' - ' + item.code"
                  :value="item.id"
                  :disabled="!item.enable"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="课程" prop="courseId">
              <el-select
                v-model="examStudentForm.courseId"
                :remote-method="getCourses4InsertOrUpdate"
                :loading="courseLoading4InsertOrUpdate"
                remote
                filterable
                clearable
                disabled
                class="input"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in courseList4InsertOrUpdate"
                  :key="item.id"
                  :label="item.name + ' - ' + item.code"
                  :value="item.id"
                  :disabled="!item.enable"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="试卷类型" prop="paperType">
              <el-select
                v-model="examStudentForm.paperType"
                class="input"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in paperTypeList"
                  :key="item"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="专业">
              <el-input
                v-model="examStudentForm.specialtyName"
                class="input"
                auto-complete="off"
                maxlength="20"
              ></el-input>
            </el-form-item>
            <el-form-item label="考点">
              <el-input
                v-model="examStudentForm.examSite"
                class="input"
                maxlength="20"
                auto-complete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="年级">
              <el-input
                v-model="examStudentForm.grade"
                class="input"
                maxlength="20"
                auto-complete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="采集人">
              <el-input
                v-model="examStudentForm.infoCollector"
                class="input"
                maxlength="20"
              ></el-input>
            </el-form-item>
            <el-form-item label="电话">
              <el-input
                v-model="examStudentForm.phone"
                class="input"
                maxlength="20"
                auto-complete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="试卷袋编码">
              <el-input
                v-model="examStudentForm.ext1"
                class="input"
                maxlength="20"
              ></el-input>
            </el-form-item>
          </el-form>
          <div style="text-align: center">
            <el-button type="primary" @click="updateStudent">确 定</el-button>
            <el-button @click="closeUpdateStudentDialog">取 消</el-button>
          </div>
        </el-dialog>

        <!-- 复制弹窗 -->
        <el-dialog
          title="考生信息复制"
          width="500px"
          :visible.sync="studentCopyDialog"
        >
          <el-form
            ref="studentCopyForm"
            :model="studentCopyForm"
            :rules="rules"
            class="editForm"
            :inline="true"
            label-width="120px"
          >
            <el-form-item label="源考试" prop="sourceExamId">
              <el-select
                v-model="studentCopyForm.sourceExamId"
                class="input"
                :remote-method="queryExams4CopyFrom"
                remote
                :loading="queryExams4CopyFromLoading"
                filterable
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in examList4CopyFrom"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="目标考试" prop="targetExamId">
              <el-select
                v-model="studentCopyForm.targetExamId"
                class="input"
                :remote-method="queryExams4CopyTo"
                remote
                :loading="queryExams4CopyToLoading"
                filterable
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in examList4CopyTo"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  :disabled="!item.enable"
                ></el-option>
              </el-select>
            </el-form-item>
            <div style="text-align: center">
              <el-button type="primary" @click="copyStudent">确 定</el-button>
              <el-button @click="studentCopyDialog = false">取 消</el-button>
            </div>
          </el-form>
        </el-dialog>

        <!-- 页面列表 -->
        <el-table
          v-loading="loading"
          element-loading-text="拼命加载中"
          :data="tableData"
          border
          style="width: 100%; text-align: center"
          @selection-change="selectChange"
        >
          <el-table-column
            type="selection"
            width="40"
            fixed="left"
          ></el-table-column>
          <el-table-column
            prop="id"
            label="ID"
            sortable
            fixed="left"
          ></el-table-column>
          <el-table-column label="考生" fixed="left">
            <template slot-scope="scope">
              <el-popover trigger="hover" placement="left">
                <div style="font-size: 18px; font-family: 新宋体">
                  <tr>
                    <td style="color: green">姓名</td>
                    <td style="color: purple; padding-left: 20px">
                      {{ scope.row.studentName }}
                    </td>
                  </tr>
                  <tr>
                    <td style="color: green">身份证号</td>
                    <td style="color: purple; padding-left: 20px">
                      {{ scope.row.identityNumber }}
                    </td>
                  </tr>
                  <tr>
                    <td style="color: green">学号</td>
                    <td style="color: purple; padding-left: 20px">
                      {{ scope.row.studentCode }}
                    </td>
                  </tr>
                  <tr>
                    <td style="color: green">学习中心名称</td>
                    <td style="color: purple; padding-left: 20px">
                      {{ scope.row.orgName }}
                    </td>
                  </tr>
                  <tr>
                    <td style="color: green">学习中心编码</td>
                    <td style="color: purple; padding-left: 20px">
                      {{ scope.row.orgCode }}
                    </td>
                  </tr>
                  <tr>
                    <td style="color: green">课程名称</td>
                    <td style="color: purple; padding-left: 20px">
                      {{ scope.row.courseName }}
                    </td>
                  </tr>
                  <tr>
                    <td style="color: green">课程编码</td>
                    <td style="color: purple; padding-left: 20px">
                      {{ scope.row.courseCode }}
                    </td>
                  </tr>
                  <tr>
                    <td style="color: green">年级</td>
                    <td style="color: purple; padding-left: 20px">
                      {{ scope.row.grade }}
                    </td>
                  </tr>
                  <tr>
                    <td style="color: green">电话</td>
                    <td style="color: purple; padding-left: 20px">
                      {{ scope.row.phone }}
                    </td>
                  </tr>
                  <tr>
                    <td style="color: green">专业</td>
                    <td style="color: purple; padding-left: 20px">
                      {{ scope.row.specialtyName }}
                    </td>
                  </tr>
                  <tr>
                    <td style="color: green">采集人</td>
                    <td style="color: purple; padding-left: 20px">
                      {{ scope.row.infoCollector }}
                    </td>
                  </tr>
                  <tr>
                    <td style="color: green">考点</td>
                    <td style="color: purple; padding-left: 20px">
                      {{ scope.row.examSite }}
                    </td>
                  </tr>
                  <tr>
                    <td style="color: green">试卷袋编码</td>
                    <td style="color: purple; padding-left: 20px">
                      {{ scope.row.ext1 }}
                    </td>
                  </tr>
                </div>

                <div slot="reference" class="name-wrapper">
                  <span>{{ scope.row.studentName }}</span>
                </div>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column
            prop="studentCode"
            label="学号"
            sortable
          ></el-table-column>
          <el-table-column
            prop="ext2"
            label="身份证号"
            width="120"
            sortable
          ></el-table-column>
          <el-table-column
            prop="orgName"
            label="学习中心"
            width="120"
            sortable
          ></el-table-column>
          <el-table-column
            prop="examName"
            label="考试名称"
            width="120"
            sortable
          ></el-table-column>
          <el-table-column
            v-if="!examStageDisabled4Search"
            label="场次"
            width="120"
            sortable
          >
            <template slot-scope="scope">
              <el-popover trigger="hover" placement="left">
                <div style="font-size: 18px; font-family: 新宋体">
                  <tr>
                    <td style="color: green">场次序号</td>
                    <td style="color: purple; padding-left: 20px">
                      {{ scope.row.examStageOrder }}
                    </td>
                  </tr>
                  <tr>
                    <td style="color: green">开始时间</td>
                    <td style="color: purple; padding-left: 20px">
                      {{ scope.row.startTime }}
                    </td>
                  </tr>
                  <tr>
                    <td style="color: green">结束时间</td>
                    <td style="color: purple; padding-left: 20px">
                      {{ scope.row.endTime }}
                    </td>
                  </tr>
                </div>

                <div slot="reference" class="name-wrapper">
                  <span>{{ scope.row.examStageOrder }}</span>
                </div>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column
            prop="courseName"
            label="课程名称"
            width="120"
            sortable
          ></el-table-column>
          <el-table-column
            prop="updateTime"
            width="155"
            label="更新时间"
            sortable
          ></el-table-column>
          <el-table-column width="50" label="状态">
            <span slot-scope="scope">
              <span v-if="scope.row.enable">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="启用"
                  placement="left"
                >
                  <i class="el-icon-success" style="color: green"></i>
                </el-tooltip>
              </span>
              <span v-else>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="禁用"
                  placement="left"
                >
                  <i class="el-icon-error" style="color: red"></i>
                </el-tooltip>
              </span>
            </span>
          </el-table-column>
          <el-table-column width="300" label="操作" fixed="right">
            <template slot-scope="scope">
              <div v-if="scope.row.locked">锁定中，请稍后操作</div>
              <div v-else>
                <el-button
                  size="mini"
                  type="primary"
                  plain
                  :disabled="!scope.row.photoPath"
                  icon="el-icon-picture"
                  @click="showPhoto(scope.row)"
                  >查看照片</el-button
                >
                <el-button
                  v-if="rolePrivileges.update_examStudent"
                  size="mini"
                  type="primary"
                  plain
                  icon="el-icon-edit"
                  @click="openUpdateDialog(scope.row)"
                  >编辑</el-button
                >

                <el-dropdown
                  v-if="
                    rolePrivileges.del_examStudent ||
                    rolePrivileges.change_exam_student_availability
                  "
                  style="margin-left: 10px"
                >
                  <el-button type="primary" plain size="mini">
                    更多
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>
                      <el-button
                        v-if="rolePrivileges.del_examStudent"
                        size="mini"
                        type="danger"
                        icon="el-icon-delete"
                        @click="deleteExamStudent(scope.row)"
                        >删除</el-button
                      >
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <el-button
                        v-if="
                          null != scope.row.enable &&
                          !scope.row.enable &&
                          rolePrivileges.change_exam_student_availability
                        "
                        size="mini"
                        type="primary"
                        plain
                        icon="el-icon-check"
                        @click="enableExamStudent(scope.row)"
                        >启用</el-button
                      >
                      <el-button
                        v-else-if="
                          rolePrivileges.change_exam_student_availability
                        "
                        size="mini"
                        type="danger"
                        icon="el-icon-close"
                        @click="disableExamStudent(scope.row)"
                        >禁用</el-button
                      >
                    </el-dropdown-item>

                    <el-dropdown-item>
                      <el-button
                        v-if="scope.row.focusFace"
                        size="mini"
                        type="danger"
                        icon="el-icon-setting"
                        @click="skipFaceSetting(scope.row)"
                        >跳过人脸</el-button
                      >
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="page pull-right">
          <el-pagination
            :current-page="currentPage"
            :page-size="pageSize"
            :page-sizes="[10, 20, 50, 100, 200, 300]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          ></el-pagination>
        </div>

        <el-dialog v-model="errDialog" title="错误提示">
          <div
            v-for="errMessage in errMessages"
            :key="errMessage.lineNum"
            class="text-danger"
          >
            第{{ errMessage.lineNum }}行：{{ errMessage.msg }}
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="errDialog = false">确定</el-button>
          </span>
        </el-dialog>

        <el-dialog
          title="照片"
          :visible.sync="photoDialog"
          width="300px"
          @close="closePhotoDialog"
        >
          <img :src="photo.url" height="100%" width="100%" />
        </el-dialog>
      </div>
    </div>
  </section>
</template>

<script>
import {
  TASK_API,
  CORE_API,
  EXAM_WORK_API,
  PAPER_TYPE,
} from "@/constants/constants.js";
import { mapState } from "vuex";

let _this = null;

let validateIdentityNumber = (rule, value, callback) => {
  if (
    !_this.examStudentForm.identityNumber ||
    _this.examStudentForm.identityNumber === ""
  ) {
    return callback(new Error("请输入身份证"));
  }
  if (
    _this.examStudentForm.identityNumber &&
    _this.examStudentForm.identityNumber.length < 6
  ) {
    callback(new Error("身份证至少6位"));
  } else {
    callback();
  }
};

export default {
  data() {
    return {
      rolePrivileges: {
        search_examStudent: false,
        add_examStudent: false,
        import_examStudent: false,
        copy_examStudent: false,
        del_examStudent: false,
        update_examStudent: false,
        change_exam_student_availability: false,
      },
      pureLC: false,
      lc_id: null,
      lc_code: null,
      lc_name: null,
      loading: false,
      courseLoading4Search: false,
      courseLoading4InsertOrUpdate: false,
      photoDialog: false,
      photo: { url: "" },
      specialtyList: [],
      button: {},
      errDialog: false,
      errMessages: [],
      uploadAction: EXAM_WORK_API + "/exam_student/import",
      uploadData: { examId: "" },
      fileLoading: false,
      fileList: [],
      uploadHeaders: {},
      orgList4Search: [],
      getOrgList4SearchLoading: false,
      examInfo4Search: null,
      formSearch: {
        rootOrgId: "",
        examId: "",
        examStageId: "",
        studentCode: "",
        studentName: "",
        orgId: "",
        specialtyName: "",
        courseId: "",
        infoCollector: "",
        identityNumber: "",
        examSite: "",
        enable: "UNDEFINED",
      },
      paperTypeList: PAPER_TYPE,
      courseList4Search: [],
      courseList4InsertOrUpdate: [],

      examList4Search: [],
      examList4InsertOrUpdate: [],
      examList4Import: [],
      examList4CopyFrom: [],
      examList4CopyTo: [],

      examStageList4Search: [],
      examStageList4Insert: [],
      examStageList4Update: [],

      queryExams4SearchLoading: false,
      queryExams4InsertOrUpdateLoading: false,
      queryExams4ImportLoading: false,
      queryExams4CopyFromLoading: false,
      queryExams4CopyToLoading: false,

      queryExamStages4SearchLoading: false,
      queryExamStages4InsertLoading: false,
      queryExamStages4UpdateLoading: false,

      selectedExamStuIds: [],
      addingStudentDialog: false,
      updateStudentDialog: false,
      studentPhoDialog: false,
      studentImportDialog: false,
      studentCopyDialog: false,

      tableData: [],

      currentPage: 1,
      pageSize: 10,
      total: 10,

      formLabelWidth: "120px",

      orgList4InsertOrUpdate: [],
      getOrgList4InsertOrUpdateLoading: false,
      examStudentForm: {
        id: null,
        examId: "",
        examStageId: "",
        examStageOrder: "",
        courseId: null,
        studentId: null,
        studentName: "",
        studentCode: "",
        identityNumber: "",
        specialtyName: "",
        orgId: "",
        examSite: "",
        grade: "",
        phone: "",
        infoCollector: "",
        paperType: "X",
        ext1: null,
        ext2: null,
        ext3: null,
        ext4: null,
        ext5: null,
      },

      studentImportForm: {
        examId: "",
      },
      studentCopyForm: {
        sourceExamId: "",
        targetExamId: "",
      },
      result: false,

      rules: {
        examId: [
          {
            type: "number",
            required: true,
            message: "请选择考试",
            trigger: "change",
          },
        ],
        studentName: [
          { required: true, message: "请输入姓名", trigger: "blur" },
        ],
        identityNumber: [
          {
            required: true,
            validator: validateIdentityNumber,
            trigger: "blur",
          },
        ],
        courseId: [
          {
            type: "number",
            required: true,
            message: "请选择课程",
            trigger: "change",
          },
        ],
        paperType: [
          { required: false, message: "请输入试卷类型", trigger: "blur" },
        ],
        orgId: [
          {
            type: "number",
            required: true,
            message: "请选择学习中心",
            trigger: "change",
          },
        ],
        sourceExamId: [
          {
            type: "number",
            required: true,
            message: "请选择源考试",
            trigger: "change",
          },
        ],
        targetExamId: [
          {
            type: "number",
            required: true,
            message: "请选择目标考试",
            trigger: "change",
          },
        ],
      },
      examStageDisabled4Search: true,
      examStageDisabled4Insert: true,
      examStageDisabled4Update: true,
    };
  },

  computed: {
    ...mapState({ user: (state) => state.user }),
    examStuIds() {
      var examStuIds = "";
      for (let examStuId of this.selectedExamStuIds) {
        if (!examStuIds) {
          examStuIds += examStuId;
        } else {
          examStuIds += "," + examStuId;
        }
      }
      return examStuIds;
    },
    noBatchSelected() {
      return this.selectedExamStuIds.length === 0;
    },
  },
  created() {
    _this = this;
    this.initPrivileges();
    this.init();
    this.uploadHeaders = {
      key: this.user.key,
      token: this.user.token,
    };
  },

  methods: {
    clearOrg4UpdateExamStudent() {
      this.getOrgList4InsertOrUpdate("");
    },
    queryExams4Search(name) {
      this.queryExams(name, "search");
    },
    queryExams4InsertOrUpdate(name) {
      this.queryExams(name, "insertOrUpdate");
    },
    queryExams4Import(name) {
      this.queryExams(name, "import");
    },
    queryExams4CopyFrom(name) {
      this.queryExams(name, "copyFrom");
    },
    queryExams4CopyTo(name) {
      this.queryExams(name, "copyTo");
    },
    queryExams(name, where) {
      console.log("queryExams; name: " + name);
      if ("search" == where) {
        this.queryExams4SearchLoading = true;
      } else if ("insertOrUpdate" == where) {
        this.queryExams4InsertOrUpdateLoading = true;
      } else if ("import" == where) {
        this.queryExams4ImportLoading = true;
      } else if ("copyFrom" == where) {
        this.queryExams4CopyFromLoading = true;
      } else if ("copyTo" == where) {
        this.queryExams4CopyToLoading = true;
      }
      this.$httpWithMsg
        .get(EXAM_WORK_API + "/exam/queryByNameLike?enable=true&name=" + name)
        .then((response) => {
          if ("search" == where) {
            this.queryExams4SearchLoading = false;
            this.examList4Search = response.data;
          } else if ("insertOrUpdate" == where) {
            this.queryExams4InsertOrUpdateLoading = false;
            this.examList4InsertOrUpdate = response.data;
          } else if ("import" == where) {
            this.queryExams4ImportLoading = false;
            this.examList4Import = response.data;
          } else if ("copyFrom" == where) {
            this.queryExams4CopyFromLoading = false;
            this.examList4CopyFrom = response.data;
          } else if ("copyTo" == where) {
            this.queryExams4CopyToLoading = false;
            this.examList4CopyTo = response.data;
          }
        })
        .catch((response) => {
          console.log(response);
          if ("search" == where) {
            this.queryExams4SearchLoading = false;
          } else if ("insertOrUpdate" == where) {
            this.queryExams4InsertOrUpdateLoading = false;
          } else if ("import" == where) {
            this.queryExams4ImportLoading = false;
          } else if ("copyFrom" == where) {
            this.queryExams4CopyFromLoading = false;
          } else if ("copyTo" == where) {
            this.queryExams4CopyToLoading = false;
          }
        });
    },

    queryExamStages4Search(name) {
      this.queryExamStages(this.formSearch.examId, name, "search");
    },
    queryExamStages4Insert(name) {
      this.queryExamStages(this.examStudentForm.examId, name, "insert");
    },
    queryExamStages4Update(name) {
      this.queryExamStages(this.examStudentForm.examId, name, "update");
    },
    queryExamStages(examId, name, where) {
      console.log("queryExams; name: " + name);
      let enable = true;
      //更新考生时，可以查看禁用的场次
      if (where == "update") {
        enable = "";
      }
      let url =
        EXAM_WORK_API +
        "/examStage/queryByNameLike?examId=" +
        examId +
        "&enable=" +
        enable +
        "&name=" +
        name;

      this.$httpWithMsg
        .get(url)
        .then((response) => {
          if ("search" == where) {
            this.queryExamStages4SearchLoading = false;
            this.examStageList4Search = response.data;
          } else if ("insert" == where) {
            this.queryExamStages4InsertLoading = false;
            this.examStageList4Insert = response.data;
          } else if ("update" == where) {
            this.queryExamStages4UpdateLoading = false;
            this.examStageList4Update = response.data;
          }
        })
        .catch((response) => {
          console.log(response);
          if ("search" == where) {
            this.queryExamStages4SearchLoading = false;
          } else if ("insert" == where) {
            this.queryExamStages4InsertLoading = false;
          } else if ("update" == where) {
            this.queryExamStages4UpdateLoading = false;
          }
        });
    },
    getOrgList4Search(orgName) {
      this.getOrgList(orgName, "search");
    },
    getOrgList4InsertOrUpdate(orgName) {
      this.getOrgList(orgName, "insertOrUpdate");
    },
    getOrgList(orgName, where) {
      if ("search" == where) {
        this.getOrgList4SearchLoading = true;
      } else if ("insertOrUpdate" == where) {
        this.getOrgList4InsertOrUpdateLoading = true;
      }

      let url = CORE_API + "/org/query?name=" + orgName;
      this.$httpWithMsg
        .get(url)
        .then((response) => {
          if ("search" == where) {
            this.getOrgList4SearchLoading = false;
            this.orgList4Search = response.data;
          } else if ("insertOrUpdate" == where) {
            this.getOrgList4InsertOrUpdateLoading = false;
            this.orgList4InsertOrUpdate = response.data;
          }
        })
        .catch((response) => {
          console.log(response);
          if ("search" == where) {
            this.getOrgList4SearchLoading = false;
          } else if ("insertOrUpdate" == where) {
            this.getOrgList4InsertOrUpdateLoading = false;
          }
        });
    },
    closePhotoDialog() {
      this.photo.url = "/img/blank.png";
      this.photoDialog = false;
    },
    showPhoto(row) {
      if (row.photoPath) {
        this.photo.url = row.photoPath;
        this.photoDialog = true;
      } else {
        this.$notify({
          showClose: true,
          message: "未上传照片",
          type: "error",
        });
      }
    },
    getExcelTitle(row) {
      return "第" + row + "行";
    },
    getCourseName(code) {
      for (let course of this.courseList) {
        if (code == course.code) {
          return course.name;
        }
      }
    },
    specialtySelect(code) {
      console.log(code);
      for (let specialty of this.specialtyList) {
        if (code == specialty.code) {
          return specialty.name;
        }
      }
    },
    selectChange(row) {
      this.selectedExamStuIds = [];
      row.forEach((element, index) => {
        console.log(index);
        this.selectedExamStuIds.push(element.id);
      });
      // console.log(this.selectedExamStuIds);
    },
    resetSearchForm() {
      this.formSearch.rootOrgId = "";
      this.formSearch.examId = "";
      this.formSearch.studentCode = "";
      this.formSearch.studentName = "";
      this.formSearch.specialtyName = "";
      this.formSearch.courseId = "";
      this.formSearch.infoCollector = "";
      this.formSearch.identityNumber = "";
      this.formSearch.examSite = "";
      this.formSearch.enable = "UNDEFINED";
      if (!this.pureLC) {
        this.formSearch.orgId = "";
      }
    },
    resetPageAndSearchForm() {
      this.currentPage = 1;
      this.searchForm();
    },
    //查询方法
    searchForm() {
      if (!this.formSearch.examId) {
        this.$notify({
          type: "warning",
          message: "请选择考试",
        });
        return;
      }
      var param = new URLSearchParams(this.formSearch);
      this.loading = true;
      var url =
        EXAM_WORK_API +
        "/exam_student/examStudentPage/" +
        (this.currentPage - 1) +
        "/" +
        this.pageSize +
        "?" +
        param;
      this.$httpWithMsg.get(url).then((response) => {
        this.tableData = response.data.list;
        this.total = response.data.total;
        this.loading = false;
      });
    },
    //查询所有课程
    getSpecialtys() {
      var orgId = this.user.orgId;
      this.$httpWithMsg
        .get(CORE_API + "/specialty/all?orgId=" + orgId)
        .then((response) => {
          console.log(response);
          this.specialtyList = response.data;
        });
    },
    getCourses4Search(query) {
      this.courseLoading4Search = true;
      this.$httpWithMsg
        .get(CORE_API + "/course/query?name=" + query)
        .then((response) => {
          this.courseList4Search = response.data;
          this.courseLoading4Search = false;
        });
    },
    getCourses4InsertOrUpdate(query) {
      this.courseLoading4InsertOrUpdate = true;
      this.$httpWithMsg
        .get(CORE_API + "/course/query?name=" + query)
        .then((response) => {
          this.courseList4InsertOrUpdate = response.data;
          this.courseLoading4InsertOrUpdate = false;
        });
    },
    //新增信息
    addStudent() {
      var url = EXAM_WORK_API + "/exam_student";
      this.$refs.addingStudentForm.validate((valid) => {
        if (valid) {
          this.$httpWithMsg.put(url, this.examStudentForm).then((response) => {
            console.log(response);
            this.$notify({
              type: "success",
              message: "保存成功",
            });
            this.addingStudentDialog = false;
            if (this.formSearch.examId) {
              this.searchForm();
            }
          });
        } else {
          return false;
        }
      });
    },
    //更新
    updateStudent() {
      var url = EXAM_WORK_API + "/exam_student";
      this.$refs.updateStudentForm.validate((valid) => {
        if (valid) {
          this.$httpWithMsg.put(url, this.examStudentForm).then((response) => {
            console.log(response);
            this.$notify({
              type: "success",
              message: "更新成功",
            });
            this.updateStudentDialog = false;
            this.searchForm();
          });
        } else {
          return false;
        }
      });
    },
    //删除方法
    deleteExamStudent(row) {
      this.$confirm("是否删除考生?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      }).then(() => {
        var url = EXAM_WORK_API + "/exam_student/" + row.id;
        this.$httpWithMsg.delete(url).then((response) => {
          console.log(response);
          this.$notify({
            type: "success",
            message: "删除成功!",
          });
          this.searchForm();
        });
      });
    },
    //批量删除方法
    deleteStuBatch() {
      if (this.examStuIds.length == 0) {
        this.$notify({
          type: "warning",
          message: "请选择要删除的考生",
        });
        return;
      }
      this.$confirm("是否删除考生?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      }).then(() => {
        var url = EXAM_WORK_API + "/exam_student/" + this.examStuIds;
        this.$httpWithMsg.delete(url).then((response) => {
          console.log(response);
          this.$notify({
            type: "success",
            message: "删除成功!",
          });
          this.searchForm();
        });
      });
    },
    //按考试删除方法
    deleteStuByExam() {
      if (!this.formSearch.examId) {
        this.$notify({
          type: "warning",
          message: "请选择要删除的考试",
        });
        return;
      }

      this.$msgbox({
        title: "提示",
        message: "是否删除该考试所有考生?",
        showCancelButton: false,
        confirmButtonText: "确定",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "执行中...";

            let inst = instance;
            let delExamStudentDone = done;
            let url =
              EXAM_WORK_API + "/exam_student/exam/" + this.formSearch.examId;
            this.$httpWithMsg
              .delete(url)
              .then((response) => {
                console.log(response);
                this.$notify({
                  type: "success",
                  message: "删除成功!",
                });
                inst.confirmButtonLoading = false;
                delExamStudentDone();
                this.searchForm();
              })
              .catch((response) => {
                console.log(response);
                inst.confirmButtonLoading = false;
                inst.confirmButtonText = "确定";
              });
          } else {
            done();
          }
        },
      }).then((action) => {
        console.log(action);
      });
    },

    handleCurrentChange(val) {
      this.currentPage = val;
      this.searchForm();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.searchForm();
    },
    //新增
    openAddingDialog() {
      if (this.$refs.addingStudentForm) {
        this.$refs.addingStudentForm.resetFields();
      }
      this.examStudentForm.examId = null;
      this.examStudentForm.studentName = null;
      this.examStudentForm.studentCode = null;
      this.examStudentForm.identityNumber = null;
      this.examStudentForm.courseId = null;
      this.examStudentForm.examSite = null;
      this.examStudentForm.grade = null;
      this.examStudentForm.paperType = "X";
      this.examStudentForm.specialtyName = null;
      this.examStudentForm.phone = null;
      this.examStudentForm.infoCollector = null;
      this.examStudentForm.ext1 = null;
      this.examStudentForm.examStageId = null;
      this.examStudentForm.examStageOrder = null;

      this.getOrgList4InsertOrUpdate("");
      this.examStudentForm.orgId = null;
      // if (this.pureLC) {
      //   this.examStudentForm.orgId = this.lc_id;
      // } else {
      //   this.examStudentForm.orgId = null;
      // }

      this.examList4InsertOrUpdate = [];
      this.courseList4InsertOrUpdate = [];
      this.examStageDisabled4Insert = true;

      this.queryExams4InsertOrUpdate("");
      this.getCourses4InsertOrUpdate("");
      this.addingStudentDialog = true;
    },
    //修改
    openUpdateDialog(row) {
      if (this.$refs.updateStudentForm) {
        this.$refs.updateStudentForm.resetFields();
      }
      this.orgList4InsertOrUpdate = [
        { id: row.orgId, name: row.orgName, code: row.orgCode },
      ];
      this.courseList4InsertOrUpdate = [
        { id: row.courseId, name: row.courseName, code: row.courseCode },
      ];
      this.examList4InsertOrUpdate = [{ id: row.examId, name: row.examName }];

      this.examStudentForm.id = row.id;
      this.examStudentForm.examId = row.examId;
      this.examStudentForm.courseId = row.courseId;
      this.examStudentForm.studentId = row.studentId;
      this.examStudentForm.studentName = row.studentName;
      this.examStudentForm.studentCode = row.studentCode;
      this.examStudentForm.identityNumber = row.identityNumber;
      this.examStudentForm.specialtyName = row.specialtyName;
      this.examStudentForm.orgId = row.orgId;
      this.examStudentForm.examSite = row.examSite;
      this.examStudentForm.grade = row.grade;
      this.examStudentForm.phone = row.phone;
      this.examStudentForm.infoCollector = row.infoCollector;
      this.examStudentForm.paperType = row.paperType;
      this.examStudentForm.ext1 = row.ext1;
      this.examStudentForm.ext2 = row.ext2;
      this.examStudentForm.ext3 = row.ext3;
      this.examStudentForm.ext4 = row.ext4;
      this.examStudentForm.ext5 = row.ext5;
      this.examStudentForm.examStageId = row.examStageId;
      this.examStudentForm.examStageOrder = row.examStageOrder;

      this.queryExamStages4Update(this.examStudentForm.examStageOrder);
      this.updateStudentDialog = true;
    },
    //禁用
    disableExamStudent(row) {
      let stuIds = this.checkIds(row);
      if (!stuIds) return;

      this.$confirm("是否禁用所选考生？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        var url = EXAM_WORK_API + "/exam_student/disable/" + stuIds;
        this.$httpWithMsg.put(url).then((response) => {
          console.log(response);
          this.$notify({
            type: "success",
            message: "禁用成功",
          });
          this.searchForm();
        });
      });
    },
    //启用
    enableExamStudent(row) {
      let stuIds = this.checkIds(row);
      if (!stuIds) return;

      this.$confirm("是否启用所选考生？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        var url = EXAM_WORK_API + "/exam_student/enable/" + stuIds;
        this.$httpWithMsg.put(url).then((response) => {
          console.log(response);
          this.$notify({
            type: "success",
            message: "启用成功",
          });
          this.searchForm();
        });
      });
    },
    skipFaceSetting(row) {
      this.$confirm("是否跳过人脸？", "提示", {
        distinguishCancelAndClose: true,
        confirmButtonText: "跳过",
        cancelButtonText: "不跳过",
        type: "warning",
      })
        .then(() => {
          let url =
            EXAM_WORK_API +
            "/exam/skip/face/setting?enabled=true&examId=" +
            row.examId +
            "&examStudentIds=" +
            row.id;

          this.$httpWithMsg.post(url).then(() => {
            this.$notify({
              type: "success",
              message: "操作成功！",
            });
          });
        })
        .catch((action) => {
          console.log("action:" + action);
          if (action === "close") {
            return;
          }

          let url =
            EXAM_WORK_API +
            "/exam/skip/face/setting?enabled=false&examId=" +
            row.examId +
            "&examStudentIds=" +
            row.id;

          this.$httpWithMsg.post(url).then(() => {
            this.$notify({
              type: "success",
              message: "取消成功！",
            });
          });
        });
    },
    checkIds(row) {
      if (row) {
        return row.id;
      } else {
        if (this.examStuIds.length == 0) {
          this.$notify({
            type: "warning",
            message: "请选择考生",
          });
          return "";
        } else {
          return this.examStuIds;
        }
      }
    },
    closeAddingStudentDialog() {
      this.addingStudentDialog = false;
    },
    closeUpdateStudentDialog() {
      this.updateStudentDialog = false;
    },
    //导入
    imp() {
      this.studentImportDialog = true;
      this.initUpload();
    },
    //复制
    copy() {
      if (this.$refs.studentCopyForm) {
        this.$refs.studentCopyForm.resetFields();
      }
      this.studentCopyDialog = true;
      this.studentCopyForm.sourceExamId = null;
      this.studentCopyForm.targetExamId = null;
      this.examList4CopyFrom = [];
      this.examList4CopyTo = [];
      this.queryExams4CopyFrom("");
      this.queryExams4CopyTo("");
    },
    //复制学生
    copyStudent() {
      this.$refs.studentCopyForm.validate((valid) => {
        if (valid) {
          if (
            this.studentCopyForm.sourceExamId ==
            this.studentCopyForm.targetExamId
          ) {
            this.$notify({
              type: "error",
              message: "源考试与目标考试不能相同",
            });
            return;
          }
          let url =
            TASK_API +
            "/copyExamStudent/addCopyTask?" +
            new URLSearchParams({
              examId1: this.studentCopyForm.sourceExamId,
              examId2: this.studentCopyForm.targetExamId,
            });

          this.fileLoading = true;
          this.$httpWithMsg.post(url, {}).then((response) => {
            console.log(response);
            this.$notify({
              type: "success",
              message: "复制中,请稍后查看",
            });
            this.studentCopyDialog = false;
            this.fileLoading = false;
            this.searchForm();
          });
        } else {
          return false;
        }
      });
    },
    beforeUpload(file) {
      console.log(file);
    },
    uploadProgress(event, file, fileList) {
      console.log("uploadProgress");
      console.log(event);
      console.log(file);
      console.log(fileList);
    },
    uploadSuccess(response, file, fileList) {
      console.log("uploadSuccess");
      console.log(response);
      console.log(file);
      console.log(fileList);
      if (response.failNum > 0) {
        this.errMessages = response.failRecords;
        this.errDialog = true;
      } else {
        this.$notify({
          message: "上传成功",
          type: "success",
        });
      }

      this.fileLoading = false;
      this.studentImportDialog = false;
      this.searchForm();
    },
    uploadError(response, file, fileList) {
      console.log("uploadSuccess");
      console.log(response);
      console.log(file);
      console.log(fileList);

      var json = JSON.parse(response.message);
      if (response.status == 500) {
        this.$notify({
          message: json.desc,
          type: "error",
        });
      }
      this.fileLoading = false;
    },
    initUpload() {
      if (this.$refs.studentImportForm) {
        this.$refs.studentImportForm.resetFields();
      }
      this.fileList = [];
      this.studentImportForm.examId = "";
      this.studentImportForm.examList4Import = [];
    },
    checkUpload() {
      if (!this.studentImportForm.examId) {
        this.$notify({
          message: "请选择考试",
          type: "error",
        });
        return false;
      } else {
        this.uploadData.examId = this.studentImportForm.examId;
      }
      var fileList = this.$refs.upload.uploadFiles;
      if (fileList.length == 0) {
        this.$notify({
          message: "上传文件不能为空",
          type: "error",
        });
        return false;
      }
      if (fileList.length > 1) {
        this.$notify({
          message: "每次只能上传一个文件",
          type: "error",
        });
        return false;
      }
      for (let file of fileList) {
        if (!file.name.endsWith(".xlsx")) {
          this.$notify({
            message: "上传文件必须为xlsx格式",
            type: "error",
          });
          this.initUpload();
          return false;
        }
      }
      return true;
    },
    //确定上传
    submitUpload() {
      if (!this.checkUpload()) {
        return false;
      }
      this.$refs.upload.submit();
      this.fileLoading = true;
    },
    //清空文件
    removeFile() {
      this.$refs.upload.clearFiles();
    },
    //下载模板
    exportFile() {
      window.location.href = "/api/ecs_exam_work/exam_student/download";
    },
    initPrivileges() {
      let params = new URLSearchParams();
      params.append(
        "privilegeCodes",
        Object.keys(this.rolePrivileges).toString()
      );
      var url = CORE_API + "/rolePrivilege/checkPrivileges?" + params;
      this.$httpWithMsg.post(url).then((response) => {
        this.rolePrivileges = response.data;
      });
    },
    async init() {
      for (let role of this.user.roleList) {
        if (role.roleCode == "LC_USER") {
          this.pureLC = true;
          continue;
        }
      }

      if (1 < this.user.roleList.length) {
        this.pureLC = false;
      }

      this.formSearch.rootOrgId = this.user.rootOrgId;
      this.getCourses4Search("");
      this.getOrgList4Search("");
      this.queryExams4Search("");

      if (this.pureLC) {
        var url = CORE_API + "/user/" + this.user.userId;
        await this.$httpWithMsg.get(url).then((response) => {
          var userInfo = response.data;
          this.lc_id = userInfo.orgId;
          // this.lc_name = userInfo.orgName;
          // this.lc_code = userInfo.orgCode;
          // this.orgList4Search = [
          //   { id: this.lc_id, name: this.lc_name, code: this.lc_code },
          // ];
          // this.orgList4InsertOrUpdate = [
          //   { id: this.lc_id, name: this.lc_name, code: this.lc_code },
          // ];
          // this.formSearch.orgId = this.lc_id;
          // this.examStudentForm.orgId = this.lc_id;
        });

        // this.searchForm();
      }
    },
    handleExamChange4Search(value) {
      this.formSearch.examStageId = "";
      if (this.examList4Search.length > 0) {
        let examArr = this.examList4Search.filter((p) => p.id == value);
        if (examArr && examArr.length > 0) {
          let exam = examArr[0];
          if (
            exam.specialSettingsEnabled &&
            exam.specialSettingsType == "STAGE_BASED"
          ) {
            this.examStageDisabled4Search = false;
            this.examStageDisabled4Update = false; //编辑同查询一致，考试不可修改
            this.queryExamStages4Search("");
            this.queryExamStages(value, "", "insert");
            this.queryExamStages(value, "", "update");
          } else {
            this.examStageList4Search = [];
            this.examStageDisabled4Search = true;
            this.examStageDisabled4Update = true;
          }
        }
      }
    },
    handleExamChange4Insert(value) {
      this.examStudentForm.examStageId = "";
      if (this.examList4InsertOrUpdate.length > 0) {
        let examArr = this.examList4InsertOrUpdate.filter((p) => p.id == value);
        if (examArr && examArr.length > 0) {
          let exam = examArr[0];
          if (
            exam.specialSettingsEnabled &&
            exam.specialSettingsType == "STAGE_BASED"
          ) {
            this.examStageDisabled4Insert = false;
            this.queryExamStages4Insert("");
          } else {
            this.examStageDisabled4Insert = true;
          }
        }
      }
    },
  },
};
</script>
<style scoped>
.input {
  width: 200px;
}
.editForm .el-form-item {
  margin-bottom: 12px;
}
</style>
