<template>
  <!-- 系统通知 -->
  <el-container>
    <el-main class="el-main-padding">
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="200px"
        class="demo-ruleForm"
        :inline-message="true"
      >
        <el-form-item label="有效期" prop="examDatetimeRange">
          <el-date-picker
            v-model="examDatetimeRange"
            class="input"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            :clearable="false"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="标题" prop="title">
          <el-input
            v-model="ruleForm.title"
            type="text"
            placeholder="请输入标题"
            maxlength="20"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="通知内容" prop="content">
          <el-input
            v-model="ruleForm.content"
            type="textarea"
            placeholder="请输入内容"
            maxlength="200"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="enable">
          <el-radio-group v-model="ruleForm.enable" class="pull_right_sm">
            <el-radio :label="true">启用</el-radio>
            <el-radio :label="false">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item style="margin-top: 10px">
          <el-button type="primary" @click="submitForm('ruleForm')"
            >保 存</el-button
          >
        </el-form-item>
      </el-form>
    </el-main>
  </el-container>
</template>
<script>
import { mapState } from "vuex";
import moment from "moment";
import { CORE_API } from "@/constants/constants.js";
let _this = null;
export default {
  name: "SysNotice",
  data() {
    let validateExamDatetimeRange = (rule, value, callback) => {
      let examDatetimeRange = _this.examDatetimeRange;
      if (!examDatetimeRange) {
        callback(new Error("请输入有效期"));
      } else {
        callback();
      }
    };
    return {
      examDatetimeRange: [],
      ruleForm: {
        id: null,
        rootOrgId: -1,
        content: "",
        startTime: null,
        endTime: null,
        enable: true,
        title: "",
      },
      rules: {
        title: [{ required: true, trigger: "blur" }],
        content: [{ required: true, trigger: "blur" }],
        enable: [{ required: true, trigger: "blur" }],
        examDatetimeRange: [
          {
            required: true,
            validator: validateExamDatetimeRange,
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
  },
  created() {
    _this = this;
    this.initForm();
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ruleForm.startTime = this.examDatetimeRange[0];
          this.ruleForm.endTime = this.examDatetimeRange[1];
          this.$httpWithMsg
            .put(CORE_API + "/sys/notice/saveSysNotice", this.ruleForm)
            .then(
              () => {
                this.$notify({
                  message: "保存成功",
                  type: "success",
                });
              },
              () => {}
            );
        } else {
          return false;
        }
      });
    },

    initForm() {
      var url = CORE_API + "/sys/notice/getSysNotice/?rootOrgId=-1";
      this.$httpWithMsg.get(url).then((response) => {
        if (response && response.data) {
          let data = response.data;
          (this.ruleForm.rootOrgId = data.rootOrgId),
            (this.ruleForm.content = data.content);
          this.ruleForm.id = data.id;
          this.ruleForm.startTime = data.startTime;
          this.ruleForm.endTime = data.endTime;
          this.ruleForm.title = data.title;
          this.ruleForm.enable = data.enable;

          this.examDatetimeRange = [
            this.ruleForm.startTime,
            this.ruleForm.endTime,
          ];
        } else {
          let now = moment().format("YYYY-MM-DD HH:mm:ss");
          this.examDatetimeRange = [now, now];
        }
      });
    },
  },
};
</script>
<style scoped>
.input-width {
  width: 638px;
}
</style>
