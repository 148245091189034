<template>
  <el-container>
    <el-main class="el-main-padding">
      <el-row>
        <el-col :span="24">
          <el-form
            :inline="true"
            :model="markWorkSearchForm"
            label-position="right"
            label-width="70px"
          >
            <el-form-item label="评卷名称" class="pull-left">
              <el-select
                v-model="markWorkSearchForm.markId"
                clearable
                filterable
                :remote-method="getMarkWorks"
                remote
                class="input"
                placeholder="请选择"
                @change="searchMarkWork"
                @clear="cleanMarkWork"
              >
                <el-option
                  v-for="item in markWorkSelect"
                  :key="item.markId"
                  :label="item.markName"
                  :value="item.markId"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="学习中心" class="pull-left">
              <el-select
                v-model="markWorkSearchForm.orgId"
                clearable
                filterable
                :remote-method="getOrg"
                remote
                class="input"
                placeholder="请选择"
                @change="searchMarkWork"
                @clear="cleanMarkWork"
              >
                <el-option
                  v-for="item in orgList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-row :gutter="2">
        <el-col :span="12" class="chart-border">
          <div class="pie"><v-charts :options="pie"></v-charts></div>
        </el-col>
        <el-col :span="12" class="chart-border">
          <div class="pie"><v-charts :options="pie1"></v-charts></div>
        </el-col>
      </el-row>
      <el-row class="row-div">
        <el-col :span="24">
          <el-form
            :inline="true"
            :model="formSearch"
            label-position="right"
            label-width="40px"
          >
            <el-form-item label="课程" class="pull-left">
              <el-select
                v-model="formSearch.courseCode"
                clearable
                filterable
                class="input"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in courseAllListSelect"
                  :key="item.code"
                  :label="item.courseInfo"
                  :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button
                size="small"
                type="primary"
                icon="el-icon-search"
                @click="searchBtnFn"
                >查询</el-button
              >
            </el-form-item>
            <el-form-item>
              <el-button
                size="small"
                type="primary"
                icon="el-icon-download"
                @click="exportBtnFn"
                >导出</el-button
              >
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-table
            v-loading="loading"
            stripe
            element-loading-text="拼命加载中"
            :data="tableData"
            border
            style="width: 100%"
          >
            <el-table-column
              label="课程名称"
              width="200"
              prop="name"
            ></el-table-column>
            <el-table-column
              label="课程代码"
              min-width="100"
              prop="code"
            ></el-table-column>
            <el-table-column
              label="任务总数"
              min-width="100"
              prop="totalCount"
              sortable
            ></el-table-column>
            <el-table-column
              label="完成数"
              min-width="100"
              prop="markedCount"
              sortable
            ></el-table-column>
            <el-table-column
              label="待完成"
              min-width="100"
              prop="leftCount"
              sortable
            ></el-table-column>
            <el-table-column label="进度" min-width="100">
              <template slot-scope="scope">
                <div>
                  <span>{{ scope.row.progress }}%</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column :context="_self" label="操作" width="160">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="mini"
                  plain
                  @click="courseDetail(scope.row)"
                  >详情</el-button
                >
                <el-button
                  v-if="scope.row.leftCount > 0"
                  size="mini"
                  type="primary"
                  plain
                  @click="releasePaper(scope.row)"
                  >释放</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="page pull-right">
            <el-pagination
              v-if="paginationShow"
              :current-page="currentPage"
              :page-size="pageSize"
              :page-sizes="[10, 20, 50, 100, 200, 300]"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              @current-change="handleSettingCurrentChange"
              @size-change="handleSizeChange"
            ></el-pagination>
          </div>
        </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>

<script>
import {
  MARKING_API,
  MARKING_LOGIC_API,
  CORE_API,
} from "@/constants/constants";
import { mapState } from "vuex";
import ECharts from "vue-echarts/components/ECharts";
import "echarts/lib/chart/pie";
export default {
  components: {
    "v-charts": ECharts,
  },
  data() {
    return {
      paginationShow: false,
      formSearch: {
        courseCode: "",
      },
      markWorkSearchForm: {
        markId: "",
        orgId: "",
      },
      tableData: [],
      oldData: [],
      currentPage: 1,
      pageSize: 10,
      total: 10,
      loading: false,
      workId: "",
      markWorkName: "",
      courseList: [],
      markWorkList: [],
      orgList: [],
      quertTemp: {},
      pie: {
        title: {
          text: "试卷进度情况",
          left: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        legend: {
          orient: "vertical",
          left: "left",
          data: ["待完成", "完成数"],
        },
        series: [
          {
            name: "试卷总数：0",
            type: "pie",
            radius: "55%",
            center: ["50%", "60%"],
            data: [
              {
                value: 0,
                name: "待完成",
                itemStyle: {
                  normal: {
                    color: "#FE8463",
                  },
                },
              },
              {
                value: 0,
                name: "完成数",
                itemStyle: {
                  normal: {
                    color: "#7CB5EC",
                  },
                },
              },
            ],
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      },
      pie1: {
        title: {
          text: "课程进度情况",
          left: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        legend: {
          orient: "vertical",
          left: "left",
          data: ["待完成", "完成数"],
        },
        series: [
          {
            name: "课程总数：0",
            type: "pie",
            radius: "55%",
            center: ["50%", "60%"],
            data: [
              {
                value: 0,
                name: "待完成",
                itemStyle: {
                  normal: {
                    color: "#FE8463",
                  },
                },
              },
              {
                value: 0,
                name: "完成数",
                itemStyle: {
                  normal: {
                    color: "#7CB5EC",
                  },
                },
              },
            ],
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      },
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
    courseAllListSelect() {
      let courseSelect = [];
      for (let course of this.courseList) {
        let courseInfo = course.name + "(" + course.code + ")";
        courseSelect.push({ code: course.code, courseInfo: courseInfo });
      }
      return courseSelect;
    },
    markWorkSelect() {
      let markWorkNames = [];
      for (let markWork of this.markWorkList) {
        markWorkNames.push({
          markId: markWork.id,
          markName: markWork.name,
        });
      }
      return markWorkNames;
    },
  },
  created() {
    this.getMarkWorks();
    this.getOrg();
    this.backFill();
  },
  methods: {
    releasePaper(row) {
      this.$confirm("确认释放试卷吗?", "提示", {
        type: "warning",
      }).then(() => {
        this.loading = true;
        this.$http
          .post(
            MARKING_API +
              "/markWorks/release?workId=" +
              row.workId +
              "&courseCode=" +
              row.code
          )
          .then(
            () => {
              this.$notify({
                message: "操作成功",
                type: "success",
              });
              this.searchBtnFn();
            },
            (response) => {
              this.$notify({
                message: response.response.data.desc,
                type: "error",
              });
              this.loading = false;
            }
          );
      });
    },
    cleanMarkWork() {
      //代表清空所有数据列表信息
      this.pie.series = [
        {
          name: "试卷总数：0",
          type: "pie",
          radius: "55%",
          center: ["50%", "60%"],
          data: [
            {
              value: 0,
              name: "待完成",
              itemStyle: {
                normal: {
                  color: "#FE8463",
                },
              },
            },
            {
              value: 0,
              name: "完成数",
              itemStyle: {
                normal: {
                  color: "#7CB5EC",
                },
              },
            },
          ],
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ];

      this.pie1.series = [
        {
          name: "课程总数：0",
          type: "pie",
          radius: "55%",
          center: ["50%", "60%"],
          data: [
            {
              value: 0,
              name: "待完成",
              itemStyle: {
                normal: {
                  color: "#FE8463",
                },
              },
            },
            {
              value: 0,
              name: "完成数",
              itemStyle: {
                normal: {
                  color: "#7CB5EC",
                },
              },
            },
          ],
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ];
      this.courseList = [];
      this.tableData = [];
      this.total = 0;
    },
    getMarkWorks(name = "") {
      this.$http
        .get(MARKING_LOGIC_API + "/markWorks?status=1" + `&name=${name}`)
        .then((response) => {
          this.markWorkList = response.data?.content || [];
          if (this.$route.query && this.$route.query.markId) {
            this.markWorkSearchForm.markId = parseInt(this.$route.query.markId);
          }
        });
    },
    getMarkWorksByName(name) {
      this.getMarkWorks(name);
    },
    getOrg(name = "") {
      this.$http
        .get(
          CORE_API +
            "/org/subOrgPage/0/20?name=" +
            name +
            "&parentId=" +
            this.user.rootOrgId
        )
        .then((response) => {
          this.orgList = response.data?.list || [];
        });
    },
    getCourses() {
      this.$http
        .get(
          MARKING_API + "/markResults/queryExamCourseList?workId=" + this.workId
        )
        .then((response) => {
          this.courseList = response.data;
          if (this.$route.query && this.$route.query.courseCode) {
            this.formSearch.courseCode = this.$route.query.courseCode;
          }
        });
    },
    handleSettingCurrentChange(val) {
      this.currentPage = val;
      this.searchSetting();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.searchSetting();
    },
    searchBtnFn() {
      this.currentPage = 1;
      this.searchSetting();
    },
    exportBtnFn() {
      if (!this.markWorkSearchForm.markId) {
        this.$notify({
          title: "警告",
          message: "请选择评卷名称",
          type: "warning",
        });
        return false;
      }
      window.open(
        MARKING_API +
          "/markCourses/all/export?workId=" +
          this.workId +
          "&courseCode=" +
          this.formSearch.courseCode +
          "&$key=" +
          this.user.key +
          "&$token=" +
          this.user.token +
          "&orgId=" +
          this.markWorkSearchForm.orgId
      );
    },
    searchSetting() {
      if (!this.markWorkSearchForm.markId) {
        this.$notify({
          title: "警告",
          message: "请选择评卷名称",
          type: "warning",
        });
        return false;
      }
      this.quertTemp["markId"] = this.markWorkSearchForm.markId;
      this.quertTemp["courseCode"] = this.formSearch.courseCode;
      this.quertTemp["markWorkName"] = this.markWorkName;
      this.quertTemp["currentPage"] = this.currentPage;
      this.quertTemp["pageSize"] = this.pageSize;
      this.loading = true;
      this.$http
        .get(
          MARKING_API +
            "/markCourses/all/" +
            (this.currentPage - 1) +
            "/" +
            this.pageSize +
            "?workId=" +
            this.workId +
            "&orgId=" +
            this.markWorkSearchForm.orgId,
          { params: this.formSearch }
        )
        .then((response) => {
          this.tableData = response.data.content;
          this.total = response.data.totalElements;
          this.loading = false;
          this.$router.push({
            path:
              "/marking/mark_work_overview?" +
              new URLSearchParams(JSON.parse(JSON.stringify(this.quertTemp))),
          });
          this.$nextTick(function () {
            this.paginationShow = true;
          });
        });
    },
    courseDetail(row) {
      var url =
        "/marking/course_detail/" +
        this.workId +
        "/" +
        encodeURIComponent(this.markWorkName) +
        "/" +
        row.code +
        "/" +
        row.name;
      this.$router.push({
        path: url,
      });
    },
    doPie() {
      this.loading = true;
      this.$http
        .get(
          MARKING_API +
            "/markWorks/" +
            this.workId +
            "?orgId=" +
            this.markWorkSearchForm.orgId
        )
        .then((response) => {
          this.pie.series = [
            {
              name: "试卷总数：" + response.data.totalStudentPaper,
              type: "pie",
              radius: "55%",
              center: ["50%", "60%"],
              data: [
                {
                  value:
                    response.data.totalStudentPaper -
                    response.data.markedStudentPaper,
                  name: "待完成",
                  itemStyle: {
                    normal: {
                      color: "#FE8463",
                    },
                  },
                  selected: true,
                },
                {
                  value: response.data.markedStudentPaper,
                  name: "完成数",
                  itemStyle: {
                    normal: {
                      color: "#7CB5EC",
                    },
                  },
                },
              ],
              itemStyle: {
                normal: {
                  label: {
                    show: true,
                    formatter: "{b}:{d}%",
                  },
                  labelLine: {
                    show: true,
                  },
                },
                emphasis: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                  label: {
                    show: true,
                    formatter: "{b}:{d}%",
                  },
                },
              },
            },
          ];
          this.pie1.series = [
            {
              name: "课程总数：" + response.data.totalCourse,
              type: "pie",
              radius: "55%",
              center: ["50%", "60%"],
              data: [
                {
                  value: response.data.totalCourse - response.data.markedCourse,
                  name: "待完成",
                  itemStyle: {
                    normal: {
                      color: "#FE8463",
                    },
                  },
                  selected: true,
                },
                {
                  value: response.data.markedCourse,
                  name: "完成数",
                  itemStyle: {
                    normal: {
                      color: "#7CB5EC",
                    },
                  },
                },
              ],
              itemStyle: {
                normal: {
                  label: {
                    show: true,
                    formatter: "{b}:{d}%",
                  },
                  labelLine: {
                    show: true,
                  },
                },
                emphasis: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                  label: {
                    show: true,
                    formatter: "{b}:{d}%",
                  },
                },
              },
            },
          ];
          this.loading = false;
        });
    },
    searchMarkWork(val) {
      if (this.markWorkSearchForm.markId) {
        let obj = {};
        for (let item of this.markWorkList) {
          if (item.id === val) {
            obj = item;
            break;
          }
        }
        this.markWorkName = obj.name;
        this.workId = this.markWorkSearchForm.markId;
        this.doPie();
        this.searchSetting();
        this.getCourses();
      }
    },
    backFill() {
      var formData = this.$route.query;
      if (formData && formData.markId) {
        this.workId = formData.markId;
        this.markWorkSearchForm.markId = parseInt(formData.markId);
        this.formSearch.courseCode = formData.courseCode;
        this.currentPage = parseInt(formData.currentPage);
        this.markWorkName = formData.markWorkName;
        this.doPie();
        this.getCourses();
        this.searchSetting();
      }
    },
  },
};
</script>
<style lang="css" scoped>
li {
  list-style-type: none;
}

.searchFrame {
  margin-right: 10px;
  margin-bottom: 10px;
}

.page {
  margin-top: 10px;
}
.f_button {
  display: block;
  width: 57px;
  height: 20px;
  border: 1px solid #ccc;
  background: #fff;
  font-size: small;
}
.pie {
  height: 400px;
  margin-top: 10px;
  margin-left: 10px;
}
.echarts-div {
  margin-left: 10px;
  width: auto;
}
rts-div {
  margin-left: 10px;
  width: auto;
}
.chart-border {
  border: 1px solid #ddd;
}
.el-main-padding {
  padding-top: 0px;
  padding-left: 5px;
}
.row-div {
  margin-top: 10px;
}
</style>
