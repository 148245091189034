<template>
  <section class="content">
    <LinkTitlesCustom :current-paths="['题型创建', detailName]" />
    <!-- 正文信息 -->
    <div class="box-body">
      <el-form
        ref="paperUnitForm"
        :inline="true"
        :model="paperUnitForm"
        :rules="rules"
        label-position="right"
        label-width="78px"
      >
        <el-row>
          <el-col :span="6">
            <el-form-item label="题型结构" prop="questionType">
              <el-select
                v-model="paperUnitForm.questionType"
                class="search_width"
                placeholder="请选择"
                size="small"
                @change="getQuesNameList"
              >
                <el-option
                  v-for="item in quesTypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="每题分值" prop="score">
              <el-input-number
                v-model="paperUnitForm.score"
                size="small"
                :precision="1"
                :min="0.1"
                @change="muli"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="题目数量" prop="count">
              <el-input
                v-model.number="paperUnitForm.count"
                class="search_width_80px"
                :disabled="true"
              >
              </el-input>
              <el-button
                icon="el-icon-plus"
                @click="propertyDialog = true"
              ></el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="题型总分" prop="totalScore">
              <el-input
                v-model="paperUnitForm.totalScore"
                class="search_width"
                :disabled="true"
                size="small"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="来源大题" prop="quesNames">
              <el-select
                v-model="paperUnitForm.quesNames"
                class="search_width"
                multiple
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in quesNameList"
                  :key="item.code"
                  :label="
                    (item.name || '').length > 40
                      ? item.name.slice(0, 40) + '...'
                      : item.name
                  "
                  :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <div class="search_down">
              <el-button
                size="small"
                type="primary"
                @click="submitForm('paperUnitForm')"
                >保 存</el-button
              >
              <el-button size="small" @click="resetForm('paperUnitForm')"
                ><i class="el-icon-refresh"></i> 重 置</el-button
              >
              <el-button
                size="small"
                type="primary"
                icon="el-icon-arrow-left"
                @click="back"
                >返 回</el-button
              >
            </div>
          </el-col>
        </el-row>
      </el-form>
      <div
        style="width: 100%; border-bottom: 1px solid #ddd; margin: 10px 0"
      ></div>
      <!-- 页面列表 -->
      <el-table
        :data="unitStructs"
        border
        style="width: 100%; text-align: center"
      >
        <el-table-column label="大题名称" width="100">
          <template slot-scope="scope">
            <span v-if="false">{{ scope.detailName }}</span>
            <span>{{ detailName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="题型结构" width="80">
          <template slot-scope="scope">
            <span>{{ getQuesTypeName(scope.row.questionType) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="题目总数" width="80">
          <template slot-scope="scope">
            <span>{{ scope.row.count }}</span>
          </template>
        </el-table-column>
        <el-table-column label="公开(简单)" width="88">
          <template slot-scope="scope">
            <span>{{ scope.row.publicSimple }}</span>
          </template>
        </el-table-column>
        <el-table-column label="公开(中等)" width="88">
          <template slot-scope="scope">
            <span>{{ scope.row.publicMedium }}</span>
          </template>
        </el-table-column>
        <el-table-column label="公开(困难)" width="88">
          <template slot-scope="scope">
            <span>{{ scope.row.publicDifficulty }}</span>
          </template>
        </el-table-column>
        <el-table-column label="公开(不限难度)" width="110">
          <template slot-scope="scope">
            <span>{{ scope.row.publicAnyDifficulty || 0 }}</span>
          </template>
        </el-table-column>
        <el-table-column label="非公开(简单)" width="102">
          <template slot-scope="scope">
            <span>{{ scope.row.noPublicSimple }}</span>
          </template>
        </el-table-column>
        <el-table-column label="非公开(中等)" width="102">
          <template slot-scope="scope">
            <span>{{ scope.row.noPublicMedium }}</span>
          </template>
        </el-table-column>
        <el-table-column label="非公开(困难)" width="102">
          <template slot-scope="scope">
            <span>{{ scope.row.noPublicDifficulty }}</span>
          </template>
        </el-table-column>
        <el-table-column label="非公开(不限难度)" width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.noPublicAnyDifficulty || 0 }}</span>
          </template>
        </el-table-column>
        <el-table-column label="每题分值" width="80">
          <template slot-scope="scope">
            <span>{{ scope.row.score }}</span>
          </template>
        </el-table-column>
        <el-table-column label="题型总分" width="80">
          <template slot-scope="scope">
            <span>{{ scope.row.totalScore }}</span>
          </template>
        </el-table-column>
        <el-table-column label="来源大题" width="150">
          <template slot-scope="scope">
            <span>{{ getQuesNames(scope.row.quesNames) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="175">
          <template slot-scope="scope">
            <div class="operate_left">
              <el-button
                size="mini"
                type="primary"
                @click="openPaperDetailUnitStructDialog(scope.row.id)"
                ><i class="el-icon-edit"></i>编辑</el-button
              >
              <el-button
                size="mini"
                type="danger"
                @click="deleteUnit(scope.row.id)"
                ><i class="el-icon-delete"></i> 删除</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 小题信息 -->
      <el-dialog
        width="65%"
        title="小题信息"
        :visible.sync="paperDetailUnitStructDialog"
      >
        <div>
          <el-form
            ref="paperUnitForm2"
            :inline="true"
            :model="paperUnitForm2"
            :rules="rules"
          >
            <el-row>
              <el-col :span="12">
                <el-form-item
                  label="题型结构"
                  prop="questionType"
                  label-width="78px"
                >
                  <el-select
                    v-model="paperUnitForm2.questionType"
                    class="form_width"
                    placeholder="请选择"
                    @change="getQuesNameList"
                  >
                    <el-option
                      v-for="item in quesTypes"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="每题分值" prop="score" label-width="78px">
                  <el-input-number
                    v-model="paperUnitForm2.score"
                    size="small"
                    :precision="1"
                    :min="0"
                    @change="muli2"
                  ></el-input-number>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item
                  label="题型总分"
                  prop="totalScore"
                  label-width="78px"
                >
                  <el-input
                    v-model="paperUnitForm2.totalScore"
                    class="form_width"
                    :disabled="true"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="来源大题"
                  prop="quesNames"
                  label-width="78px"
                >
                  <el-select
                    v-model="paperUnitForm2.quesNames"
                    class="form_width"
                    multiple
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in quesNameList"
                      :key="item.code"
                      :label="
                        (item.name || '').length > 40
                          ? item.name.slice(0, 40) + '...'
                          : item.name
                      "
                      :value="item.code"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="5">
                <div class="grid-content bg-purple">
                  <div class="form_font_size">公开（简单）</div>
                </div>
              </el-col>
              <el-col :span="5">
                <div class="grid-content bg-purple">
                  <div class="form_font_size">公开（中等）</div>
                </div>
              </el-col>
              <el-col :span="5">
                <div class="grid-content bg-purple">
                  <div class="form_font_size">公开（困难）</div>
                </div>
              </el-col>
              <el-col :span="5">
                <div class="grid-content bg-purple">
                  <div class="form_font_size">公开（不限难度）</div>
                </div>
              </el-col>
              <el-col :span="4">
                <div class="grid-content bg-purple">
                  <div class="form_font_size">公开总数</div>
                </div>
              </el-col>
            </el-row>
            <!-- 第二列 -->
            <el-row :gutter="20">
              <el-col :span="5">
                <div class="grid-content bg-purple">
                  <el-input
                    v-model="paperUnitForm2.publicSimple"
                    @change="
                      unitCount(
                        paperUnitForm2.publicSimple,
                        'publicSimple',
                        paperUnitForm2
                      )
                    "
                  ></el-input>
                </div>
              </el-col>
              <el-col :span="5">
                <div class="grid-content bg-purple">
                  <el-input
                    v-model="paperUnitForm2.publicMedium"
                    @change="
                      unitCount(
                        paperUnitForm2.publicMedium,
                        'publicMedium',
                        paperUnitForm2
                      )
                    "
                  ></el-input>
                </div>
              </el-col>
              <el-col :span="5">
                <div class="grid-content bg-purple">
                  <el-input
                    v-model="paperUnitForm2.publicDifficulty"
                    @change="
                      unitCount(
                        paperUnitForm2.publicDifficulty,
                        'publicDifficulty',
                        paperUnitForm2
                      )
                    "
                  ></el-input>
                </div>
              </el-col>
              <el-col :span="5">
                <div class="grid-content bg-purple">
                  <el-input
                    v-model="paperUnitForm2.publicAnyDifficulty"
                    @change="
                      unitCount(
                        paperUnitForm2.publicAnyDifficulty,
                        'publicAnyDifficulty',
                        paperUnitForm2
                      )
                    "
                  ></el-input>
                </div>
              </el-col>
              <el-col :span="4">
                <div class="grid-content bg-purple">
                  <el-input
                    v-model="paperUnitForm2.publicSum"
                    :disabled="true"
                  ></el-input>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="5">
                <div class="grid-content bg-purple">
                  <div class="form_font_size">非公开（简单）</div>
                </div>
              </el-col>
              <el-col :span="5">
                <div class="grid-content bg-purple">
                  <div class="form_font_size">非公开（中等）</div>
                </div>
              </el-col>
              <el-col :span="5">
                <div class="grid-content bg-purple">
                  <div class="form_font_size">非公开（困难）</div>
                </div>
              </el-col>
              <el-col :span="5">
                <div class="grid-content bg-purple">
                  <div class="form_font_size">非公开（不限难度）</div>
                </div>
              </el-col>
              <el-col :span="4">
                <div class="grid-content bg-purple">
                  <div class="form_font_size">非公开总数</div>
                </div>
              </el-col>
            </el-row>
            <!-- 第四列 -->
            <el-row :gutter="20">
              <el-col :span="5">
                <div class="grid-content bg-purple">
                  <el-input
                    v-model="paperUnitForm2.noPublicSimple"
                    @change="
                      unitCount(
                        paperUnitForm2.noPublicSimple,
                        'noPublicSimple',
                        paperUnitForm2
                      )
                    "
                  ></el-input>
                </div>
              </el-col>
              <el-col :span="5">
                <div class="grid-content bg-purple">
                  <el-input
                    v-model="paperUnitForm2.noPublicMedium"
                    @change="
                      unitCount(
                        paperUnitForm2.noPublicMedium,
                        'noPublicMedium',
                        paperUnitForm2
                      )
                    "
                  ></el-input>
                </div>
              </el-col>
              <el-col :span="5">
                <div class="grid-content bg-purple">
                  <el-input
                    v-model="paperUnitForm2.noPublicDifficulty"
                    @change="
                      unitCount(
                        paperUnitForm2.noPublicDifficulty,
                        'noPublicDifficulty',
                        paperUnitForm2
                      )
                    "
                  ></el-input>
                </div>
              </el-col>
              <el-col :span="5">
                <div class="grid-content bg-purple">
                  <el-input
                    v-model="paperUnitForm2.noPublicAnyDifficulty"
                    @change="
                      unitCount(
                        paperUnitForm2.noPublicAnyDifficulty,
                        'noPublicAnyDifficulty',
                        paperUnitForm2
                      )
                    "
                  ></el-input>
                </div>
              </el-col>
              <el-col :span="4">
                <div class="grid-content bg-purple">
                  <el-input
                    v-model="paperUnitForm2.noPublicSum"
                    :disabled="true"
                  ></el-input>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="5">
                <div class="grid-content bg-purple">
                  <div class="form_font_size">简单总数</div>
                </div>
              </el-col>
              <el-col :span="5">
                <div class="grid-content bg-purple">
                  <div class="form_font_size">中等总数</div>
                </div>
              </el-col>
              <el-col :span="5">
                <div class="grid-content bg-purple">
                  <div class="form_font_size">困难总数</div>
                </div>
              </el-col>
              <el-col :span="5">
                <div class="grid-content bg-purple">
                  <div class="form_font_size">不限难度总数</div>
                </div>
              </el-col>
              <el-col :span="4">
                <div class="grid-content bg-purple">
                  <div class="form_font_size">总数</div>
                </div>
              </el-col>
            </el-row>
            <!-- 第六列 -->
            <el-row :gutter="20">
              <el-col :span="5">
                <div class="grid-content bg-purple">
                  <el-input
                    v-model="paperUnitForm2.simpleSum"
                    :disabled="true"
                  ></el-input>
                </div>
              </el-col>
              <el-col :span="5">
                <div class="grid-content bg-purple">
                  <el-input
                    v-model="paperUnitForm2.mediumSum"
                    :disabled="true"
                  ></el-input>
                </div>
              </el-col>
              <el-col :span="5">
                <div class="grid-content bg-purple">
                  <el-input
                    v-model="paperUnitForm2.difficultySum"
                    :disabled="true"
                  ></el-input>
                </div>
              </el-col>
              <el-col :span="5">
                <div class="grid-content bg-purple">
                  <el-input
                    v-model="paperUnitForm2.anyDifficultySum"
                    :disabled="true"
                  ></el-input>
                </div>
              </el-col>
              <el-col :span="4">
                <div class="grid-content bg-purple">
                  <el-input
                    v-model="paperUnitForm2.count"
                    :disabled="true"
                  ></el-input>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <div style="margin-left: 45%">
                <el-button
                  type="primary"
                  @click="editSubmitForm('paperUnitForm2')"
                  >保 存</el-button
                >
              </div>
            </el-row>
          </el-form>
        </div>
      </el-dialog>
      <!-- 设置题目数量弹框 -->
      <el-dialog width="65%" title="题目数量" :visible.sync="propertyDialog">
        <el-row :gutter="20">
          <el-col :span="5">
            <div class="grid-content bg-purple">
              <div class="form_font_size">公开（简单）</div>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="grid-content bg-purple">
              <div class="form_font_size">公开（中等）</div>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="grid-content bg-purple">
              <div class="form_font_size">公开（困难）</div>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="grid-content bg-purple">
              <div class="form_font_size">公开（不限难度）</div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <div class="form_font_size">公开总数</div>
            </div>
          </el-col>
        </el-row>
        <!-- 第二列 -->
        <el-row :gutter="20">
          <el-col :span="5">
            <div class="grid-content bg-purple">
              <el-input
                v-model="paperUnitForm.publicSimple"
                @change="
                  unitCount(
                    paperUnitForm.publicSimple,
                    'publicSimple',
                    paperUnitForm
                  )
                "
              ></el-input>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="grid-content bg-purple">
              <el-input
                v-model="paperUnitForm.publicMedium"
                @change="
                  unitCount(
                    paperUnitForm.publicMedium,
                    'publicMedium',
                    paperUnitForm
                  )
                "
              ></el-input>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="grid-content bg-purple">
              <el-input
                v-model="paperUnitForm.publicDifficulty"
                @change="
                  unitCount(
                    paperUnitForm.publicDifficulty,
                    'publicDifficulty',
                    paperUnitForm
                  )
                "
              ></el-input>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="grid-content bg-purple">
              <el-input
                v-model="paperUnitForm.publicAnyDifficulty"
                @change="
                  unitCount(
                    paperUnitForm.publicAnyDifficulty,
                    'publicAnyDifficulty',
                    paperUnitForm
                  )
                "
              ></el-input>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <el-input
                v-model="paperUnitForm.publicSum"
                :disabled="true"
              ></el-input>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="5">
            <div class="grid-content bg-purple">
              <div class="form_font_size">非公开（简单）</div>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="grid-content bg-purple">
              <div class="form_font_size">非公开（中等）</div>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="grid-content bg-purple">
              <div class="form_font_size">非公开（困难）</div>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="grid-content bg-purple">
              <div class="form_font_size">非公开（不限难度）</div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <div class="form_font_size">非公开总数</div>
            </div>
          </el-col>
        </el-row>
        <!-- 第四列 -->
        <el-row :gutter="20">
          <el-col :span="5">
            <div class="grid-content bg-purple">
              <el-input
                v-model="paperUnitForm.noPublicSimple"
                @change="
                  unitCount(
                    paperUnitForm.noPublicSimple,
                    'noPublicSimple',
                    paperUnitForm
                  )
                "
              ></el-input>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="grid-content bg-purple">
              <el-input
                v-model="paperUnitForm.noPublicMedium"
                @change="
                  unitCount(
                    paperUnitForm.noPublicMedium,
                    'noPublicMedium',
                    paperUnitForm
                  )
                "
              ></el-input>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="grid-content bg-purple">
              <el-input
                v-model="paperUnitForm.noPublicDifficulty"
                @change="
                  unitCount(
                    paperUnitForm.noPublicDifficulty,
                    'noPublicDifficulty',
                    paperUnitForm
                  )
                "
              ></el-input>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="grid-content bg-purple">
              <el-input
                v-model="paperUnitForm.noPublicAnyDifficulty"
                @change="
                  unitCount(
                    paperUnitForm.noPublicAnyDifficulty,
                    'noPublicAnyDifficulty',
                    paperUnitForm
                  )
                "
              ></el-input>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <el-input
                v-model="paperUnitForm.noPublicSum"
                :disabled="true"
              ></el-input>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="5">
            <div class="grid-content bg-purple">
              <div class="form_font_size">简单总数</div>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="grid-content bg-purple">
              <div class="form_font_size">中等总数</div>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="grid-content bg-purple">
              <div class="form_font_size">困难总数</div>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="grid-content bg-purple">
              <div class="form_font_size">不限难度总数</div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <div class="form_font_size">总数</div>
            </div>
          </el-col>
        </el-row>
        <!-- 第六列 -->
        <el-row :gutter="20">
          <el-col :span="5">
            <div class="grid-content bg-purple">
              <el-input
                v-model="paperUnitForm.simpleSum"
                :disabled="true"
              ></el-input>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="grid-content bg-purple">
              <el-input
                v-model="paperUnitForm.mediumSum"
                :disabled="true"
              ></el-input>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="grid-content bg-purple">
              <el-input
                v-model="paperUnitForm.difficultySum"
                :disabled="true"
              ></el-input>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="grid-content bg-purple">
              <el-input
                v-model="paperUnitForm.anyDifficultySum"
                :disabled="true"
              ></el-input>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <el-input
                v-model="paperUnitForm.count"
                :disabled="true"
              ></el-input>
            </div>
          </el-col>
        </el-row>
        <div style="margin-top: 20px; margin-left: 42%">
          <el-button type="primary" @click="propertyDialog = false"
            >保 存</el-button
          >
          <el-button type="primary" @click="propertyDialog = false"
            >返 回</el-button
          >
        </div>
      </el-dialog>
    </div>
  </section>
</template>

<script>
import { QUESTION_API } from "@/constants/constants";
import { QUESTION_TYPES } from "../constants/constants";
import _ from "lodash";
import LinkTitlesCustom from "@/components/LinkTitlesCustom.vue";
export default {
  components: { LinkTitlesCustom },
  data() {
    var checkIntegerNumber = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("数值不能为空"));
      }
      setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error("请输入整数"));
        } else {
          if (value < 0) {
            callback(new Error("必须大于0"));
          } else {
            callback();
          }
        }
      }, 100);
    };
    var checkDoubleNumber = (rule, value, callback) => {
      //var reg = /^[-\+]?\d+(\.\d+)?$/;
      var reg = /^\d+(?=\.{0,1}\d+$|$)/;
      if (!value) {
        return callback(new Error("数值不能为空"));
      }
      setTimeout(() => {
        if (!reg.test(value)) {
          callback(new Error("请输入数值"));
        } else {
          if (value < 0) {
            callback(new Error("必须大于等于0"));
          } else {
            callback();
          }
        }
      }, 100);
    };
    var validateQuesNames = (rule, value, callback) => {
      if (Array.isArray(value) && value.length == 0) {
        callback(new Error("请选择来源大题"));
      } else {
        callback();
      }
    };
    return {
      quesTypes: QUESTION_TYPES,
      courseNo: "",
      quesNameList: [],
      paperUnitForm: {
        id: "",
        questionType: "",
        count: 0,
        score: "",
        totalScore: "",
        quesNames: [],
        publicSimple: 0,
        publicMedium: 0,
        publicDifficulty: 0,
        noPublicSimple: 0,
        noPublicMedium: 0,
        noPublicDifficulty: 0,
        publicSum: 0,
        noPublicSum: 0,
        simpleSum: 0,
        mediumSum: 0,
        difficultySum: 0,
        publicAnyDifficulty: 0,
        noPublicAnyDifficulty: 0,
        anyDifficultySum: 0,
      },
      paperUnitForm2: {
        id: "",
        questionType: "",
        count: "",
        score: "",
        totalScore: "",
        quesNames: [],
        publicSimple: 0,
        publicMedium: 0,
        publicDifficulty: 0,
        noPublicSimple: 0,
        noPublicMedium: 0,
        noPublicDifficulty: 0,
        publicSum: 0,
        noPublicSum: 0,
        simpleSum: 0,
        mediumSum: 0,
        difficultySum: 0,
        publicAnyDifficulty: 0,
        noPublicAnyDifficulty: 0,
        anyDifficultySum: 0,
      },
      unitId: "",
      detailName: "",
      detailId: "",
      paperStructId: "",
      paperStruct: {},
      unitStructs: [],
      paperDetailUnitStructDialog: false,
      propertyDialog: false,
      rules: {
        questionType: [
          { required: true, message: "题型不能为空", trigger: "change" },
        ],
        count: [
          { validator: checkIntegerNumber, required: true, trigger: "blur" },
        ],
        score: [
          { required: true, validator: checkDoubleNumber, trigger: "blur" },
        ],
        quesNames: [{ validator: validateQuesNames, trigger: "change" }],
      },
    };
  },
  computed: {
    totalScore() {
      var sum = 0.0;
      for (let unitStruct of this.unitStructs) {
        sum = this.accAdd(unitStruct.totalScore, sum);
      }
      return sum;
    },
    detailCount() {
      var count = 0.0;
      for (let unitStruct of this.unitStructs) {
        count += unitStruct.count;
      }
      return count;
    },
    quesNames() {
      var quesNames = [];
      for (let quesName of this.quesNameList) {
        quesNames.push(quesName.code);
      }
      return quesNames;
    },
  },
  //初始化查询
  created() {
    this.paperStructId = this.$route.params.id;
    this.detailName = this.$route.params.name;
    this.detailId = this.$route.params.detailId;
    this.courseNo = this.$route.params.courseNo;
    this.searchForm();
  },
  methods: {
    accAdd(num1, num2) {
      let sq1, sq2, m;
      try {
        sq1 = num1.toString().split(".")[1].length;
      } catch (e) {
        sq1 = 0;
      }
      try {
        sq2 = num2.toString().split(".")[1].length;
      } catch (e) {
        sq2 = 0;
      }
      m = Math.pow(10, Math.max(sq1, sq2));
      return (num1 * m + num2 * m) / m;
    },
    //查询来源大题名称
    getQuesNameList(quesType) {
      if (quesType && quesType.length > 0) {
        var quesTypeObj = {};
        var url = QUESTION_API + "/paperStruct/quesNames";
        if (this.courseNo !== "all") {
          quesTypeObj = {
            quesType: quesType,
            courseNo: this.courseNo,
          };
        } else {
          quesTypeObj = {
            quesType: quesType,
          };
        }
        this.$http.get(url, { params: quesTypeObj }).then((response) => {
          console.log(response);
          this.quesNameList = response.data;
          if (this.quesNameList != null) {
            for (let quesName of this.quesNameList) {
              if (quesName.name.length > 50) {
                quesName.name = quesName.name.substring(0, 50) + "...";
              }
            }
          }
          this.initQuesNames();
        });
      }
    },
    initQuesNames() {
      var arr1 = _.difference(this.paperUnitForm.quesNames, this.quesNames);
      if (arr1.length > 0) {
        this.paperUnitForm.quesNames = [];
      }
      var arr2 = _.difference(this.paperUnitForm2.quesNames, this.quesNames);
      if (arr2.length > 0) {
        this.paperUnitForm2.quesNames = [];
      }
    },
    getQuesNames(quesNameList) {
      var quesNameStr = "";
      if (!Array.isArray(quesNameList)) {
        return quesNameStr;
      }
      for (let quesName of quesNameList) {
        if (quesNameStr === "") {
          quesNameStr += quesName;
        } else {
          quesNameStr += "," + quesName;
        }
      }
      return quesNameStr;
    },
    //查询列表集合
    searchForm() {
      this.loading = true;
      var paperStructStorge = sessionStorage.getItem("paperStruct");
      if (typeof paperStructStorge === "string") {
        var paperStruct = JSON.parse(paperStructStorge);
        this.paperStruct = paperStruct;
        this.unitStructs = this.getUnits();
        console.log("this.unitStructs:", this.unitStructs);
      }
    },
    //保存
    submitForm(formData) {
      this.loading = true;
      this.$refs[formData].validate((valid) => {
        if (valid) {
          var unitStruct = Object.assign({}, this.paperUnitForm);
          var ids = [];
          for (let unitStruct of this.unitStructs) {
            ids.push(unitStruct.id);
          }
          ids.sort();
          var maxId;
          if (ids.length == 0) {
            maxId = 1;
          } else {
            maxId = ids[ids.length - 1];
          }
          unitStruct.id = maxId + 1;
          this.unitStructs.push(unitStruct);
          this.resetForm(formData);
          this.paperUnitForm.publicSimple = 0;
          this.paperUnitForm.publicMedium = 0;
          this.paperUnitForm.publicDifficulty = 0;
          this.paperUnitForm.noPublicSimple = 0;
          this.paperUnitForm.noPublicMedium = 0;
          this.paperUnitForm.noPublicDifficulty = 0;
          this.paperUnitForm.publicSum = 0;
          this.paperUnitForm.noPublicSum = 0;
          this.paperUnitForm.simpleSum = 0;
          this.paperUnitForm.mediumSum = 0;
          this.paperUnitForm.difficultySum = 0;
          this.paperUnitForm.publicAnyDifficulty = 0;
          this.paperUnitForm.noPublicAnyDifficulty = 0;
          this.paperUnitForm.anyDifficultySum = 0;
          this.setUnits();
          sessionStorage.setItem(
            "paperStruct",
            JSON.stringify(this.paperStruct)
          );
        } else {
          return false;
        }
      });
    },

    editSubmitForm(formData) {
      this.$refs[formData].validate((valid) => {
        if (valid) {
          for (let unitStruct of this.unitStructs) {
            if (unitStruct.id == this.paperUnitForm2.id) {
              unitStruct.questionType = this.paperUnitForm2.questionType;
              unitStruct.count = this.paperUnitForm2.count;
              unitStruct.score = this.paperUnitForm2.score;
              unitStruct.totalScore = this.paperUnitForm2.totalScore;
              unitStruct.quesNames = this.paperUnitForm2.quesNames;
              unitStruct.publicSimple = this.paperUnitForm2.publicSimple;
              unitStruct.publicMedium = this.paperUnitForm2.publicMedium;
              unitStruct.publicDifficulty =
                this.paperUnitForm2.publicDifficulty;
              unitStruct.noPublicSimple = this.paperUnitForm2.noPublicSimple;
              unitStruct.noPublicMedium = this.paperUnitForm2.noPublicMedium;
              unitStruct.noPublicDifficulty =
                this.paperUnitForm2.noPublicDifficulty;
              unitStruct.publicSum = this.paperUnitForm2.publicSum;
              unitStruct.noPublicSum = this.paperUnitForm2.noPublicSum;
              unitStruct.simpleSum = this.paperUnitForm2.simpleSum;
              unitStruct.mediumSum = this.paperUnitForm2.mediumSum;
              unitStruct.difficultySum = this.paperUnitForm2.difficultySum;
              unitStruct.publicAnyDifficulty =
                this.paperUnitForm2.publicAnyDifficulty || 0;
              unitStruct.noPublicAnyDifficulty =
                this.paperUnitForm2.noPublicAnyDifficulty || 0;
              unitStruct.anyDifficultySum =
                this.paperUnitForm2.anyDifficultySum || 0;
              this.setUnits();
              this.paperDetailUnitStructDialog = false;
              sessionStorage.setItem(
                "paperStruct",
                JSON.stringify(this.paperStruct)
              );
            }
          }
        }
      });
    },
    //重置
    resetForm(formData) {
      this.$refs[formData].resetFields();
      //this.$refs[formData].clearValidate();
      // this.paperUnitForm = {
      //   id: "",
      //   questionType: "",
      //   count: 0,
      //   score: "",
      //   totalScore: "",
      //   quesNames: [],
      //   publicSimple: 0,
      //   publicMedium: 0,
      //   publicDifficulty: 0,
      //   noPublicSimple: 0,
      //   noPublicMedium: 0,
      //   noPublicDifficulty: 0,
      //   publicSum: 0,
      //   noPublicSum: 0,
      //   simpleSum: 0,
      //   mediumSum: 0,
      //   difficultySum: 0
      // };
    },
    //返回
    back() {
      this.$router.push({
        path: "/questions/insert_paper_structure/" + this.paperStructId,
      });
    },
    muli() {
      this.paperUnitForm.totalScore =
        (this.paperUnitForm.score * 1000 * this.paperUnitForm.count) / 1000;
    },
    muli2() {
      this.paperUnitForm2.totalScore =
        (this.paperUnitForm2.score * 1000 * this.paperUnitForm2.count) / 1000;
    },
    getUnits() {
      var unitStructs = [];
      for (let paperDetailStruct of this.paperStruct.paperDetailStructs) {
        if (parseInt(paperDetailStruct.id) == this.detailId) {
          if (paperDetailStruct.unitStructs) {
            return paperDetailStruct.unitStructs;
          }
        }
      }
      return unitStructs;
    },
    setUnits() {
      for (let paperDetailStruct of this.paperStruct.paperDetailStructs) {
        if (parseInt(paperDetailStruct.id) == this.detailId) {
          paperDetailStruct.unitStructs = this.unitStructs;
          paperDetailStruct.totalScore = this.totalScore;
          paperDetailStruct.detailCount = this.detailCount;
        }
      }
    },
    getQuesTypeName(value) {
      for (let quesType of this.quesTypes) {
        if (value == quesType.value) {
          return quesType.label;
        }
      }
    },

    openPaperDetailUnitStructDialog(id) {
      this.unitId = id;
      for (let unitStruct of this.unitStructs) {
        if (unitStruct.id == id) {
          this.paperUnitForm2.id = id;
          this.paperUnitForm2.questionType = unitStruct.questionType;
          this.paperUnitForm2.count = unitStruct.count;
          this.paperUnitForm2.score = unitStruct.score;
          this.paperUnitForm2.totalScore = unitStruct.totalScore;
          this.paperUnitForm2.quesNames = unitStruct.quesNames;
          this.paperUnitForm2.publicSimple = unitStruct.publicSimple;
          this.paperUnitForm2.publicMedium = unitStruct.publicMedium;
          this.paperUnitForm2.publicDifficulty = unitStruct.publicDifficulty;
          this.paperUnitForm2.noPublicSimple = unitStruct.noPublicSimple;
          this.paperUnitForm2.noPublicMedium = unitStruct.noPublicMedium;
          this.paperUnitForm2.noPublicDifficulty =
            unitStruct.noPublicDifficulty;
          this.paperUnitForm2.publicAnyDifficulty =
            unitStruct.publicAnyDifficulty || 0;
          this.paperUnitForm2.noPublicAnyDifficulty =
            unitStruct.noPublicAnyDifficulty || 0;
          this.paperUnitForm2.anyDifficultySum =
            unitStruct.anyDifficultySum || 0;
          //实时计算，不存数据库
          this.paperUnitForm2.publicSum =
            this.paperUnitForm2.publicSimple +
              this.paperUnitForm2.publicMedium +
              this.paperUnitForm2.publicDifficulty +
              this.paperUnitForm2.publicAnyDifficulty || 0;
          this.paperUnitForm2.noPublicSum =
            this.paperUnitForm2.noPublicSimple +
              this.paperUnitForm2.noPublicMedium +
              this.paperUnitForm2.noPublicDifficulty +
              this.paperUnitForm2.noPublicAnyDifficulty || 0;
          this.paperUnitForm2.simpleSum =
            this.paperUnitForm2.publicSimple +
            this.paperUnitForm2.noPublicSimple;
          this.paperUnitForm2.mediumSum =
            this.paperUnitForm2.publicMedium +
            this.paperUnitForm2.noPublicMedium;
          this.paperUnitForm2.difficultySum =
            this.paperUnitForm2.publicDifficulty +
            this.paperUnitForm2.noPublicDifficulty;
          this.paperUnitForm2.anyDifficultySum =
            (this.paperUnitForm2.publicAnyDifficulty || 0) +
            (this.paperUnitForm2.noPublicAnyDifficulty || 0);
        }
      }
      this.getQuesNameList(this.paperUnitForm2.questionType);
      this.paperDetailUnitStructDialog = true;
    },
    deleteUnit(id) {
      var unitStructs = this.unitStructs;
      for (let i = unitStructs.length - 1; i >= 0; i--) {
        if (unitStructs[i].id == id) {
          unitStructs.splice(i, 1);
        }
      }
      this.unitStructs = unitStructs;
      this.setUnits();
      sessionStorage.setItem("paperStruct", JSON.stringify(this.paperStruct));
      console.log(sessionStorage.getItem("paperStruct"));
    },
    //判断是否为正整数
    isPositiveInteger(s) {
      //是否为正整数
      var re = /^[0-9]+$/;
      return re.test(s);
    },
    //计算数量
    unitCount(data, rowType, paperUnitForm) {
      //计算前校验正整数
      if (!this.isPositiveInteger(data)) {
        if (rowType === "publicSimple") {
          setTimeout(function () {
            paperUnitForm.publicSimple = 0;
          }, 1);
        } else if (rowType === "publicMedium") {
          setTimeout(function () {
            paperUnitForm.publicMedium = 0;
          }, 1);
        } else if (rowType === "publicDifficulty") {
          setTimeout(function () {
            paperUnitForm.publicDifficulty = 0;
          }, 1);
        } else if (rowType === "publicAnyDifficulty") {
          setTimeout(function () {
            paperUnitForm.publicAnyDifficulty = 0;
          }, 1);
        } else if (rowType === "noPublicSimple") {
          setTimeout(function () {
            paperUnitForm.noPublicSimple = 0;
          }, 1);
        } else if (rowType === "noPublicMedium") {
          setTimeout(function () {
            paperUnitForm.noPublicMedium = 0;
          }, 1);
        } else if (rowType === "noPublicDifficulty") {
          setTimeout(function () {
            paperUnitForm.noPublicDifficulty = 0;
          }, 1);
        } else if (rowType === "noPublicAnyDifficulty") {
          setTimeout(function () {
            paperUnitForm.noPublicAnyDifficulty = 0;
          }, 1);
        }
      }
      setTimeout(function () {
        paperUnitForm.publicSimple = parseInt(paperUnitForm.publicSimple);
        paperUnitForm.publicMedium = parseInt(paperUnitForm.publicMedium);
        paperUnitForm.publicDifficulty = parseInt(
          paperUnitForm.publicDifficulty
        );
        paperUnitForm.publicAnyDifficulty = parseInt(
          paperUnitForm.publicAnyDifficulty || 0
        );
        paperUnitForm.noPublicSimple = parseInt(paperUnitForm.noPublicSimple);
        paperUnitForm.noPublicMedium = parseInt(paperUnitForm.noPublicMedium);
        paperUnitForm.noPublicDifficulty = parseInt(
          paperUnitForm.noPublicDifficulty
        );
        paperUnitForm.noPublicAnyDifficulty = parseInt(
          paperUnitForm.noPublicAnyDifficulty || 0
        );
        //公开总数
        paperUnitForm.publicSum =
          parseInt(paperUnitForm.publicSimple) +
          parseInt(paperUnitForm.publicMedium) +
          parseInt(paperUnitForm.publicDifficulty) +
          parseInt(paperUnitForm.publicAnyDifficulty || 0);
        //非公开总数
        paperUnitForm.noPublicSum =
          parseInt(paperUnitForm.noPublicSimple) +
          parseInt(paperUnitForm.noPublicMedium) +
          parseInt(paperUnitForm.noPublicDifficulty) +
          parseInt(paperUnitForm.noPublicAnyDifficulty || 0);
        //简单总数
        paperUnitForm.simpleSum =
          parseInt(paperUnitForm.publicSimple) +
          parseInt(paperUnitForm.noPublicSimple);
        //中等总数
        paperUnitForm.mediumSum =
          parseInt(paperUnitForm.publicMedium) +
          parseInt(paperUnitForm.noPublicMedium);
        //困难总数
        paperUnitForm.difficultySum =
          parseInt(paperUnitForm.publicDifficulty) +
          parseInt(paperUnitForm.noPublicDifficulty);
        //不限难度总数
        paperUnitForm.anyDifficultySum =
          parseInt(paperUnitForm.publicAnyDifficulty || 0) +
          parseInt(paperUnitForm.noPublicAnyDifficulty || 0);
        //总数
        paperUnitForm.count =
          paperUnitForm.publicSum + paperUnitForm.noPublicSum;
        //分数
        paperUnitForm.totalScore =
          (paperUnitForm.score * 1000 * paperUnitForm.count) / 1000;
      }, 5);
      if (this.clearCheck(paperUnitForm.count)) {
        this.$refs["paperUnitForm"].clearValidate("count");
      }
    },
    clearCheck(value) {
      var reg = /^\d+(?=\.{0,1}\d+$|$)/;
      if (!value) {
        return true;
      }
      if (!reg.test(value)) {
        return true;
      } else {
        if (value < 0) {
          return true;
        } else {
          return false;
        }
      }
    },
  },
};
</script>
<style scoped src="../styles/Common.css"></style>
<style scoped>
.el-row {
  margin-bottom: 20px;
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #dcdfe6;
}
.bg-purple {
  background: #dcdfe6;
}
.bg-purple-light {
  background: #f9fafc;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
.el-form--inline .el-form-item__label {
  float: none;
  display: inline-block;
  width: 78px;
}
</style>
