import Home from "../../portal/views/home/Home.vue";
import Tips from "../../portal/views/tips/Tips.vue";
import Project from "../view/Project.vue";
import ProjectStatistic from "../view/ProjectStatistic.vue";
import CourseStatistic from "../view/CourseStatistic.vue";
import ProjectTemplate from "../view/ProjectTemplate.vue";
import ExamStructure from "../view/ExamStructure.vue";

export default [
  {
    path: "/print" /* 印刷管理 */,
    meta: { auth: false },
    component: Home,
    children: [
      {
        path: "tips" /* 模块说明 */,
        component: Tips,
      },
      {
        path: "/print/project/list" /* 项目列表 */,
        meta: { privilegeCodes: "print_project_list" },
        component: Project,
      },
      {
        path: "/print/project/statistic" /* 项目统计 */,
        meta: { privilegeCodes: "print_project_statistic" },
        component: ProjectStatistic,
      },
      {
        path: "/print/course/statistic" /* 课程统计 */,
        meta: { privilegeCodes: "print_course_statistic" },
        component: CourseStatistic,
      },
      {
        path: "/print/project/template" /* 模板页面 */,
        meta: { privilegeCodes: "print_project_template" },
        component: ProjectTemplate,
      },
      {
        path: "/print/exam/structure" /* 考试结构 */,
        meta: { privilegeCodes: "print_exam_structure" },
        component: ExamStructure,
      },
    ],
  },
];
