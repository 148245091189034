<template>
  <section class="content">
    <div v-show="isClear == 1">
      <LinkTitlesCustom :current-paths="['题库管理 ', '考试试卷管理']" />
    </div>
    <div class="box-body">
      <el-form
        :inline="true"
        :model="formSearch"
        label-position="right"
        label-width="70px"
      >
        <el-row>
          <el-col :span="6">
            <el-form-item label="课程名称">
              <el-select
                v-model="formSearch.courseNo"
                class="search_width"
                filterable
                :remote-method="getCourses"
                remote
                clearable
                placeholder="全部"
                size="small"
                @focus="(e) => getCourses(e.target.value)"
              >
                <el-option
                  v-for="item in courseInfoSelect"
                  :key="item.courseNo"
                  :label="item.courseInfo"
                  :value="item.courseNo"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="试卷名称">
              <el-input
                v-model="formSearch.name"
                class="search_width"
                placeholder="试卷名称"
                size="small"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="层次">
              <el-select
                v-model="formSearch.level"
                class="search_width"
                clearable
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in levelList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="录入人">
              <el-input
                v-model="formSearch.creator"
                class="search_width"
                placeholder="录入人"
                size="small"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="修改人">
              <el-input
                v-model="formSearch.lastModifyName"
                class="search_width"
                placeholder="修改人"
                size="small"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="调用状态">
              <el-select
                v-model="formSearch.inUse"
                class="search_width"
                clearable
                placeholder="请选择"
                size="small"
              >
                <el-option value="1" label="已调用"> </el-option>
                <el-option value="0" label="未调用"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="审核状态">
              <el-select
                v-model="formSearch.auditStatus"
                class="search_width"
                clearable
                placeholder="请选择"
                size="small"
              >
                <el-option value="true" label="已审核"> </el-option>
                <el-option value="false" label="未审核"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <div class="search_down">
              <el-button size="small" type="primary" @click="searchFrom"
                ><i class="el-icon-search"></i> 查 询</el-button
              >
              <el-button size="small" @click="resetForm"
                ><i class="el-icon-refresh"></i> 重 置</el-button
              >
              <el-button size="small" type="primary" @click="genPaperDetail"
                ><i class="el-icon-plus"></i> 组卷</el-button
              >
            </div>
          </el-col>
        </el-row>
        <!-- <el-row>
          <el-col :span="12">
            <el-button size="small" type="primary" @click="toAddPaperSelect(0)"
              ><i class="el-icon-plus"></i>随机抽卷模板</el-button
            >
          </el-col>
        </el-row> -->
        <div
          style="width: 100%; border-bottom: 1px solid #ddd; margin: 10px 0"
        ></div>
        <el-row>
          <el-form-item>
            <span>批量操作：</span>
            <el-button
              size="small"
              type="danger"
              :disabled="noBatchSelected"
              @click="batchDeleteGenPaper"
            >
              <i class="el-icon-delete"></i>删除成卷
            </el-button>
            <el-button
              size="small"
              type="danger"
              :disabled="noBatchSelected"
              @click="recoveryPapers"
            >
              <i class="el-icon-delete"></i>回收
            </el-button>

            <el-button
              size="small"
              type="primary"
              :disabled="noBatchSelected"
              @click="openBatchExportPaperDialog"
              ><i class="el-icon-download"></i>下载成卷</el-button
            >
            <el-button
              size="small"
              type="primary"
              :disabled="noBatchSelected"
              @click="openPrints"
              ><i class="el-icon-share"></i>发送成卷</el-button
            >
            <el-button
              size="small"
              type="primary"
              :disabled="noBatchSelected"
              @click="openQuesPro"
              ><i class="el-icon-edit"></i>试卷属性</el-button
            >
          </el-form-item>
        </el-row>
      </el-form>
      <div style="width: 100%; margin-bottom: 10px"></div>
      <el-table
        v-loading="loading"
        element-loading-text="拼命加载中"
        :data="tableData"
        border
        style="width: 100%"
        @selection-change="selectChange"
      >
        <el-table-column type="selection" width="40"></el-table-column>
        <el-table-column label="课程名称" width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.course.name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="课程代码" width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.course.code }}</span>
          </template>
        </el-table-column>
        <el-table-column label="试卷名称" width="180">
          <template slot-scope="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="试卷总分"
          width="103"
          sortable
          prop="totalScore"
        >
        </el-table-column>
        <el-table-column
          label="试卷难度"
          width="103"
          sortable
          prop="difficultyDegree"
        >
        </el-table-column>
        <el-table-column
          label="大题数量"
          width="103"
          sortable
          prop="paperDetailCount"
        >
        </el-table-column>
        <el-table-column label="调用状态" width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.inUse == 1 ? "已调用" : "未调用" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="审核状态" width="100">
          <template slot-scope="scope">
            <span>{{
              scope.row.auditStatus == false ? "未审核" : "已审核"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="录入员" width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.creator }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="创建时间"
          width="153"
          sortable
          prop="createTime"
        >
        </el-table-column>
        <el-table-column label="修改人" width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.lastModifyName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="修改时间"
          width="153"
          sortable
          prop="updateDate"
        >
        </el-table-column>
        <el-table-column label="操作" width="286" fixed="right">
          <template slot-scope="scope">
            <div class="operate_left">
              <el-button
                size="mini"
                type="primary"
                plain
                @click="editGenPaper(scope.row)"
                ><i class="el-icon-edit"></i>编辑</el-button
              >
              <el-button
                size="mini"
                type="primary"
                plain
                @click="audit(scope.row)"
                ><i class="el-icon-share"></i> 试卷审核</el-button
              >
              <el-dropdown style="margin-left: 10px">
                <el-button type="primary" size="mini" plain>
                  更多 <i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <el-button
                      size="small"
                      type="danger"
                      @click="recoveryPaper(scope.row)"
                    >
                      <i class="el-icon-delete"></i>回收
                    </el-button>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-button
                      size="mini"
                      type="primary"
                      plain
                      @click="openExportDialog(scope.row)"
                      ><i class="el-icon-download"></i> 下载</el-button
                    >
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-button
                      size="mini"
                      type="primary"
                      plain
                      @click="exportOriginalPaper(scope.row)"
                      ><i class="el-icon-download"></i> 导出原卷</el-button
                    >
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-button
                      size="mini"
                      type="primary"
                      plain
                      @click="previewPDF2(scope.row)"
                      ><i class="el-icon-zoom-in"></i> 预览</el-button
                    >
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-button
                      size="mini"
                      type="primary"
                      plain
                      @click="openPrint(scope.row)"
                      ><i class="el-icon-share"></i> 发送</el-button
                    >
                  </el-dropdown-item>
                  <!-- <el-dropdown-item>
                    <el-button
                      size="mini"
                      type="danger"
                      @click="deleteGenPaper(scope.row)"
                      ><i class="el-icon-delete"></i> 删除</el-button
                    >
                  </el-dropdown-item> -->
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="page pull-right">
        <el-pagination
          :current-page="currentPage"
          :page-size="pageSize"
          :page-sizes="[10, 20, 50, 100, 200, 300]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog title="导出" :visible.sync="exportDialog">
      <el-form :model="exportModel" label-position="right" label-width="80px">
        <el-row v-if="isShow">
          <el-col :md="12">
            <el-form-item label="课程名称">
              {{ exportModel.courseName }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="isShow">
          <el-col :md="12">
            <el-form-item label="课程代码">
              {{ exportModel.courseCode }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :md="24">
            <el-form-item label="导出内容">
              <el-checkbox-group v-model="exportModel.exportContentList">
                <el-checkbox label="PAPER">试卷</el-checkbox>
                <el-checkbox label="ANSWER">答案</el-checkbox>
                <!-- <el-checkbox label="COMPUTERTEST_PACKAGE"
                  >机考数据包</el-checkbox
                > -->
                <el-checkbox label="THEMIS_PACKAGE">数据包</el-checkbox>
                <!-- <el-checkbox
                  v-if="isShowPrintExamPackage"
                  label="PRINT_EXAM_PACKAGE"
                  >分布式印刷数据包
                </el-checkbox> -->
                <el-input
                  v-if="isShowPrintExamPackagePassword"
                  v-model="printExamPackagePassword"
                  placeholder="加密密码 (可选)"
                  type="password"
                  style="width: 150px"
                ></el-input>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="showSeqMode()">
          <el-form-item label="小题序号">
            <el-radio-group v-model="exportModel.seqMode" class="input">
              <el-radio label="MODE1">单题型连续</el-radio>
              <el-radio label="MODE2">客观题整体连续</el-radio>
              <el-radio label="MODE3">按大题独立</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-row>
        <el-row>
          <el-col :md="24" class="text-center">
            <el-button type="primary" @click="exportPaperInfo"
              >开始导出</el-button
            >
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>

    <el-dialog title="传送印刷平台" :visible.sync="dialogModel" width="500px">
      <el-form
        ref="printFrom"
        :model="printFrom"
        :rules="rules"
        label-position="right"
        label-width="120px"
        inline-message
      >
        <el-row>
          <el-form-item label="学校名称">
            <el-input
              v-model="Org.name"
              class="dialog_input_width"
              :disabled="true"
            ></el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="考试名称" prop="examId">
            <el-select
              v-model="printFrom.examId"
              class="dialog_input_width"
              filterable
              clearable
              :remote-method="getExams"
              remote
              placeholder="请选择"
            >
              <el-option
                v-for="item in examList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-row>
        <el-row class="margin_top_10 margin_left_120">
          <el-button type="primary" @click="sendPrint('printFrom')"
            >保 存</el-button
          >
          <el-button @click="resetForm2('printFrom')"
            ><i class="el-icon-refresh"></i> 重 置</el-button
          >
          <el-button type="primary" @click="cancel('printFrom')"
            ><i class="el-icon-arrow-left"></i> 返 回</el-button
          >
        </el-row>
      </el-form>
    </el-dialog>
    <el-dialog
      title="属性修改"
      :visible.sync="quesPropertyDialog"
      width="500px"
    >
      <el-form
        :loading="quesLoading"
        label-position="right"
        :inline="true"
        label-width="120px"
      >
        <el-row>
          <el-form-item>
            <el-checkbox v-model="difficultyDegreeDis"></el-checkbox>
          </el-form-item>
          <el-form-item label="难  度">
            <el-select
              v-model="difficultyDegree"
              class="dialog_input_width"
              :disabled="!difficultyDegreeDis"
            >
              <el-option
                v-for="item in difficultyDegreeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item>
            <el-checkbox v-model="publicityDis"></el-checkbox>
          </el-form-item>
          <el-form-item label="公开度">
            <el-select
              v-model="publicity"
              class="dialog_input_width"
              :disabled="!publicityDis"
            >
              <el-option
                v-for="item in publicityList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-row>
        <el-row class="margin_top_10 margin_left_120">
          <el-button
            v-loading.fullscreen.lock="quesLoading"
            type="primary"
            @click="updateQuesPropertyByPaper()"
            >保 存</el-button
          >
          <el-button @click="resetQuesProForm"
            ><i class="el-icon-refresh"></i> 重 置</el-button
          >
          <el-button type="primary" @click="closePropertyDialog"
            ><i class="el-icon-arrow-left"></i> 返 回</el-button
          >
        </el-row>
      </el-form>
    </el-dialog>
  </section>
</template>

<script>
import { CORE_API, QUESTION_API, EXAM_WORK_API } from "@/constants/constants";
import { LEVEL_TYPE, PUBLICITY_LIST } from "../constants/constants";
import { mapState } from "vuex";
import LinkTitlesCustom from "@/components/LinkTitlesCustom.vue";
export default {
  components: { LinkTitlesCustom },
  data() {
    return {
      publicityDis: false,
      difficultyDegreeDis: false,
      quesLoading: false,
      quesPropertyDialog: false,
      difficultyDegree: 0.1,
      publicity: true,
      difficultyDegreeList: [
        { label: 0.1, value: 0.1 },
        { label: 0.2, value: 0.2 },
        { label: 0.3, value: 0.3 },
        { label: 0.4, value: 0.4 },
        { label: 0.5, value: 0.5 },
        { label: 0.6, value: 0.6 },
        { label: 0.7, value: 0.7 },
        { label: 0.8, value: 0.8 },
        { label: 0.9, value: 0.9 },
        { label: 1.0, value: 1.0 },
      ],
      publicityList: PUBLICITY_LIST,
      isClear: 0,
      courseLoading: false,
      formSearch: {
        auditStatus: "",
        courseNo: "",
        courseName: "",
        creator: "",
        lastModifyName: "",
        level: "",
        name: "",
        inUse: "",
      },
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      courseList: [],
      levelList: LEVEL_TYPE,
      dialogVisible: false,
      selectedPaperIds: [],
      fileList: [],
      uploadAction: "",
      formUpload: {
        paperName: "",
      },
      uploadData: {},
      fileLoading: false,
      exportDialog: false,
      exportModel: {
        id: "",
        courseCode: "",
        courseName: "",
        exportContentList: [],
        seqMode: "MODE1",
      },
      isShow: true,
      examList: [],
      Org: {},
      dialogModel: false,
      rowIds: [],
      isShowPrintExamPackage: false,
      isShowPrintExamPackagePassword: false,
      printExamPackagePassword: "",
      printFrom: {
        examId: "",
      },
      rules: {
        examId: [{ required: true, message: "请输入名称", trigger: "change" }],
      },
    };
  },
  computed: {
    paperIds() {
      var paperIds = "";
      for (let paperId of this.selectedPaperIds) {
        if (!paperIds) {
          paperIds += paperId;
        } else {
          paperIds += "," + paperId;
        }
      }
      return paperIds;
    },
    courseInfoSelect() {
      var courseList = [];
      for (let course of this.courseList) {
        var courseInfo = course.name + "(" + course.code + ")";
        var courseNo = course.code;
        var courseName = course.name;
        courseList.push({
          courseNo: courseNo,
          courseInfo: courseInfo,
          courseName: courseName,
        });
      }
      return courseList;
    },
    noBatchSelected() {
      return this.selectedPaperIds.length === 0;
    },
    ...mapState({ user: (state) => state.user }),
  },
  watch: {
    $route: "initVue",
  },
  created() {
    this.initVue();
    this.searchExamList();
    this.searchOrgName();
  },
  methods: {
    toAddPaperSelect(id) {
      var courseNo = this.formSearch.courseNo;
      if (!courseNo) {
        this.$notify({
          title: "警告",
          message: "请选择课程",
          type: "warning",
        });
        return false;
      }
      let course = this.getCourseObj(courseNo);
      let courseName = course.name;
      this.$router.push({
        path: "/questions/add_paper_select" + (id ? `/${id}` : ""),
        query: { courseNo, courseName, courseId: course.id },
      });
    },
    audit(row) {
      this.$confirm(
        "请通过试卷预览或编辑功能，认真检查试卷结构和题目，确认试卷无问题，则可以审核确认。",
        "试卷内容是否确认通过",
        {
          type: "info",
        }
      ).then(() => {
        this.loading = true;
        this.$http.get(QUESTION_API + "/paper/audit/" + row.id).then(
          () => {
            this.selectedPaperIds = [];
            this.searchGenPaper();
            this.$notify({
              message: "操作成功",
              type: "success",
            });
            this.loading = false;
          },
          (response) => {
            this.$notify({
              message: response.response.data.desc,
              type: "error",
            });
            this.loading = false;
          }
        );
      });
    },
    exportOriginalPaper(row) {
      var key = this.user.key;
      var token = this.user.token;
      //window.location.href = QUESTION_API+"/originalPaper/export/"+row.id+"/"+ this.user.displayName;
      window.open(
        QUESTION_API +
          "/originalPaper/exportPlus/" +
          row.id +
          "/" +
          this.user.displayName +
          "?$key=" +
          key +
          "&$token=" +
          token
      );
    },
    showSeqMode() {
      for (let i = 0; i < this.exportModel.exportContentList.length; i++) {
        let element = this.exportModel.exportContentList[i];
        if (element == "PAPER" || element == "ANSWER") {
          // this.showSeqMode = true;
          return true;
        }
      }
      // this.showSeqMode = false;
      return false;
    },
    updateQuesPropertyByPaper() {
      if (!this.difficultyDegreeDis && !this.publicityDis) {
        this.$notify({
          title: "警告",
          message: "请勾选难度或公开度",
          type: "warning",
        });
        return;
      }
      var params = "?ids=" + this.selectedPaperIds;
      if (this.difficultyDegreeDis) {
        params = params + "&difficultyDegree=" + this.difficultyDegree;
      }
      if (this.publicityDis) {
        params = params + "&publicity=" + this.publicity;
      }
      this.quesLoading = true;
      this.$httpWithMsg
        .put(QUESTION_API + "/question/updatePro/paperIds" + params)
        .then(() => {
          this.$notify({
            message: "更新成功",
            type: "success",
          });
          this.searchGenPaper();
          this.quesLoading = false;
          this.closePropertyDialog();
        })
        .catch(() => {
          this.quesLoading = false;
        });
    },
    openQuesPro() {
      if (!this.selectedPaperIds || this.selectedPaperIds.length < 1) {
        this.$notify({
          title: "警告",
          message: "请选择试卷",
          type: "warning",
        });
        return;
      }
      this.difficultyDegree = 0.1;
      this.publicity = true;
      this.quesPropertyDialog = true;
    },
    closePropertyDialog() {
      this.quesPropertyDialog = false;
    },
    resetQuesProForm() {
      this.difficultyDegree = 0.1;
      this.publicity = true;
    },
    resetForm() {
      this.formSearch = {
        courseNo: "",
        courseName: "",
        level: "",
        name: "",
      };
    },
    //查询
    searchFrom() {
      this.currentPage = 1;
      this.searchGenPaper();
    },
    searchGenPaper() {
      var pageNo = this.currentPage;
      this.currentPage = 1;
      this.loading = true;
      var url = QUESTION_API + "/genPaper/" + pageNo + "/" + this.pageSize;
      this.$http.get(url, { params: this.formSearch }).then((response) => {
        this.tableData = response.data.content;
        this.total = response.data.totalElements;
        this.currentPage = Number(pageNo);
      });
      this.loading = false;
    },
    genPaperDetail() {
      var courseNo = this.formSearch.courseNo;
      if (!courseNo) {
        this.$notify({
          title: "警告",
          message: "请选择课程",
          type: "warning",
        });
      } else {
        var course = this.getCourseObj(courseNo);
        this.formSearch.courseName = course.name;
        sessionStorage.setItem("gen_paper", JSON.stringify(this.formSearch));
        sessionStorage.setItem("gen_paper_currentPage", this.currentPage);
        this.$router.push({
          path: "/questions/gen_paper_detail/" + courseNo,
        });
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.searchGenPaper();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.searchGenPaper();
    },
    getCourseObj(courseNo) {
      for (let course of this.courseList) {
        if (course.code == courseNo) {
          return course;
        }
      }
      return "";
    },
    exportGenPaper(row) {
      window.open(
        QUESTION_API + "/paper/export/" + row.id + "/" + this.user.rootOrgName
      );
    },
    editGenPaper(row) {
      var course = this.getCourseObj(this.formSearch.courseNo);
      if (course) {
        this.formSearch.courseName = course.name;
      }
      sessionStorage.setItem("gen_paper", JSON.stringify(this.formSearch));
      sessionStorage.setItem("gen_paper_currentPage", this.currentPage);
      sessionStorage.setItem("question_back", "false");
      this.$router.push({
        path: "/edit_paper/" + row.id + "/gen_paper",
      });
    },
    deleteGenPaper(row) {
      this.$confirm("确认删除试卷吗?", "提示", {
        type: "warning",
      }).then(() => {
        this.loading = true;
        let param = new URLSearchParams({
          paperIds: row.id,
        });
        this.$http.post(QUESTION_API + "/paper", param).then(
          () => {
            this.$notify({
              message: "删除成功",
              type: "success",
            });
            this.searchGenPaper();
          },
          (response) => {
            this.$notify({
              message: response.response.data.desc,
              type: "error",
            });
            this.loading = false;
          }
        );
      });
    },
    recoveryPaper(row) {
      this.$confirm("确认回收试卷吗?", "提示", {
        type: "warning",
      }).then(() => {
        this.loading = true;
        this.$http.put(QUESTION_API + "/paper_storage/recovery/" + row.id).then(
          () => {
            this.$notify({
              message: "操作成功",
              type: "success",
            });
            this.searchGenPaper();
          },
          (response) => {
            this.$notify({
              message: response.response.data.desc,
              type: "error",
            });
            this.loading = false;
          }
        );
      });
    },
    recoveryPapers() {
      var paperIds = this.paperIds;
      if (this.selectedPaperIds.length != 0) {
        this.$confirm("确认回收试卷吗?", "提示", {
          type: "warning",
        }).then(() => {
          this.loading = true;
          this.$http
            .put(QUESTION_API + "/paper_storage/recovery/" + paperIds)
            .then(
              () => {
                this.$notify({
                  message: "操作成功",
                  type: "success",
                });
                this.selectedPaperIds = [];
                this.searchGenPaper();
              },
              (response) => {
                this.$notify({
                  message: response.response.data.desc,
                  type: "error",
                });
                this.loading = false;
              }
            );
        });
      } else {
        this.$notify({
          message: "请勾选回收的试卷",
          type: "warning",
        });
      }
    },
    selectChange(row) {
      this.selectedPaperIds = [];
      row.forEach((element) => {
        this.selectedPaperIds.push(element.id);
      });
    },
    batchDeleteGenPaper() {
      if (this.selectedPaperIds.length != 0) {
        this.$confirm("确认删除试卷吗?", "提示", {
          type: "warning",
        }).then(() => {
          this.loading = true;
          let param = new URLSearchParams({
            paperIds: this.paperIds,
          });
          this.$http.post(QUESTION_API + "/paper", param).then(
            () => {
              this.$notify({
                message: "删除成功",
                type: "success",
              });
              this.searchGenPaper();
              this.selectedPaperIds = [];
            },
            (response) => {
              this.$notify({
                message: response.response.data.desc,
                type: "error",
              });
              this.loading = false;
            }
          );
        });
      } else {
        this.$notify({
          message: "请勾选删除的数据",
          type: "warning",
        });
      }
    },
    //查询所有课程
    getCourses(query) {
      if (query) {
        query = query.trim();
      }
      this.courseLoading = true;
      this.$http
        .get(CORE_API + "/course/query?name=" + query + "&enable=true")
        .then((response) => {
          this.courseList = response.data;
          this.courseLoading = false;
        });
    },
    //打开导出弹框
    openExportDialog(row) {
      this.isShow = true;
      this.isShow = true;
      this.exportDialog = true;
      this.exportModel.id = row.id;
      this.exportModel.courseCode = row.course.code;
      this.exportModel.courseName = row.course.name;
      this.exportModel.exportContentList = [];
      /* 分布式印刷数据包设置 */
      this.isShowPrintExamPackage = true;
      this.isShowPrintExamPackagePassword = false;
      this.printExamPackagePassword = "";
    },
    //打开批量弹出框
    openBatchExportPaperDialog() {
      if (this.selectedPaperIds.length != 0) {
        this.exportDialog = true;
        this.isShow = false;
        this.isShow = false;
        this.exportModel.exportContentList = [];
      } else {
        this.$notify({
          message: "请勾选导出的数据",
          type: "warning",
        });
      }
      /* 分布式印刷数据包设置 */
      this.isShowPrintExamPackage = false;
      this.isShowPrintExamPackagePassword = false;
      this.printExamPackagePassword = "";
    },
    //导出试卷，答案，机考数据包
    exportPaperInfo() {
      if (this.exportModel.exportContentList.length == 0) {
        this.$notify({
          message: "请选择导出内容",
          type: "error",
        });
        return false;
      }
      if (this.printExamPackagePassword.length > 0) {
        var reg = /^[0-9A-Za-z]{6,12}$/;
        if (!reg.test(this.printExamPackagePassword)) {
          this.$notify({
            message: "加密密码请输入6至12位的数字或字母！",
            type: "error",
          });
          return false;
        }
      }
      var key = this.user.key;
      var token = this.user.token;
      if (this.isShow) {
        window.location.href =
          QUESTION_API +
          "/paper/export/" +
          this.exportModel.id +
          "/" +
          this.exportModel.exportContentList +
          "/onLine" +
          "?psw=" +
          this.printExamPackagePassword +
          "&$key=" +
          key +
          "&$token=" +
          token +
          "&seqMode=" +
          this.exportModel.seqMode;
        this.exportDialog = false;
      } else {
        var paperIds = this.paperIds;
        window.location.href =
          QUESTION_API +
          "/paper/batch_export/" +
          paperIds +
          "/" +
          this.exportModel.exportContentList +
          "/onLine" +
          "?$key=" +
          key +
          "&$token=" +
          token +
          "&seqMode=" +
          this.exportModel.seqMode;
        this.exportDialog = false;
      }
    },
    removeItem() {
      sessionStorage.removeItem("gen_paper");
      sessionStorage.removeItem("gen_paper_currentPage");
    },
    previewPDF2(row) {
      window.open(QUESTION_API + "/paper/pdf/" + row.id);
    },
    openPrint(row) {
      this.dialogModel = true;
      this.rowIds.push(row.id);
    },
    openPrints() {
      this.dialogModel = true;
      this.rowIds = this.selectedPaperIds;
    },
    cancel(formData) {
      this.resetForm2(formData);
      this.dialogModel = false;
    },
    resetForm2(formData) {
      this.printFrom.examId = "";
      this.$refs[formData].clearValidate();
    },
    //传至印刷模块
    sendPrint(formData) {
      this.$refs[formData].validate((valid) => {
        if (valid) {
          var orgId = "";
          for (let exam of this.examList) {
            if (exam.id == this.printFrom.examId) {
              orgId = exam.rootOrgId;
            }
          }
          if (orgId != "" || orgId == "0") {
            for (let printPid of this.rowIds) {
              this.$http.get(
                QUESTION_API +
                  "/sendPrint/" +
                  printPid +
                  "/" +
                  this.printFrom.examId +
                  "/" +
                  orgId
              );
            }
            this.$notify({
              message: "已推送",
              type: "success",
            });
            this.resetForm2(formData);
            this.dialogModel = false;
            this.rowIds = [];
          }
        } else {
          return false;
        }
      });
    },
    //查询考试
    getExams(query) {
      if (query) {
        query = query.trim();
      }
      this.$http
        .get(
          EXAM_WORK_API + "/exam/queryByNameLike?name=" + query + "&enable=true"
        )
        .then((response) => {
          this.examList = response.data;
        });
    },
    searchExamList() {
      //查询所有考试
      this.$http
        .get(EXAM_WORK_API + "/exam/queryByNameLike?name=&enable=true")
        .then((response) => {
          var list = response.data;
          for (var i = 0; i < list.length; i++) {
            if (list[i].examType == "TRADITION") {
              this.examList.push(list[i]);
            }
          }
        });
    },
    //根据orgId查询学校名称
    searchOrgName() {
      this.$http
        .get(CORE_API + "/org/rootOrg/" + this.user.rootOrgId)
        .then((response) => {
          this.Org = response.data;
        });
    },
    initVue() {
      this.isClear = this.$route.params.isClear;
      if (this.isClear == 0 || !this.isClear) {
        this.removeItem();
        this.formSearch = {
          courseNo: "",
          courseName: "",
          level: "",
          name: "",
        };
        this.currentPage = 1;
      } else {
        this.formSearch = JSON.parse(sessionStorage.getItem("gen_paper"));
        this.currentPage =
          sessionStorage.getItem("gen_paper_currentPage") == null
            ? 1
            : parseInt(sessionStorage.getItem("gen_paper_currentPage"));
      }
      this.getCourses(this.formSearch.courseName);
      this.searchGenPaper();
    },
  },
};
</script>
<style scoped src="../styles/Common.css"></style>
