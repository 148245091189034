<!-- 编辑填空，问答，判断题 -->
<template>
  <div id="editOtherApp">
    <section v-loading="fullscreenLoading" class="content">
      <h3 class="box-title">
        <span v-if="!quesModel.id"
          ><LinkTitlesCustom :current-paths="['试题管理', '试题新增']"
        /></span>
        <span v-if="quesModel.id"
          ><LinkTitlesCustom :current-paths="['试题管理', '试题修改']"
        /></span>
      </h3>
      <div class="box-body">
        <el-form
          ref="quesModel"
          :model="quesModel"
          :rules="rules"
          label-position="right"
          label-width="80px"
        >
          <el-row :gutter="10">
            <el-col :xs="10" :sm="10" :md="10" :lg="10">
              <el-form-item label="题型">
                <el-select
                  v-model="quesModel.questionType"
                  :disabled="true"
                  placeholder="请输入题型"
                >
                  <el-option
                    v-for="item in questionTypes"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- created by weiwenhai -->
          <el-form-item label="难度">
            <el-select
              v-model="quesModel.difficultyDegree"
              placeholder="请输入难度"
            >
              <el-option
                v-for="item in difficultyDegreeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="公开度">
            <el-select v-model="quesModel.publicity" placeholder="请输入公开度">
              <el-option
                v-for="item in publicityList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            v-if="quesModel.questionType == 'TEXT_ANSWER_QUESTION'"
            label="作答类型"
          >
            <el-select v-model="quesModel.answerType">
              <el-option
                v-for="item in answerTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="属性列表">
            <el-tooltip
              v-for="(content, index) in quesModel.quesProperties"
              :key="index"
              placement="top"
            >
              <div slot="content">
                <span v-if="content.firstProperty != null"
                  >一级属性：{{ content.firstProperty.name }}</span
                ><br />
                <span v-if="content.secondProperty != null"
                  >二级属性：{{ content.secondProperty.name }}</span
                >
              </div>
              <el-tag
                :key="content.id"
                style="margin-right: 5px"
                closable
                type="primary"
                @close="handleClose(content)"
              >
                {{ content.coursePropertyName }}
              </el-tag>
            </el-tooltip>
          </el-form-item>
          <el-row :gutter="20">
            <el-col :xs="6" :sm="6" :md="6" :lg="6">
              <el-form-item label="属性名">
                <el-select
                  v-model="coursePropertyName"
                  placeholder="属性名"
                  class="property_with"
                  @change="searchFirst"
                >
                  <el-option
                    v-for="item in coursePropertyList"
                    :key="item.name"
                    :label="item.name"
                    :value="item.name"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="6" :sm="6" :md="6" :lg="6">
              <el-form-item label="一级">
                <el-select
                  v-model="firstPropertyId"
                  placeholder="一级"
                  class="property_with"
                  @change="searchSecond"
                >
                  <el-option
                    v-for="item in firstPropertyList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="6" :sm="6" :md="6" :lg="6">
              <el-form-item label="二级">
                <el-select
                  v-model="secondPropertyId"
                  placeholder="二级"
                  class="property_with"
                >
                  <el-option
                    v-for="item in secondPropertyList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="3" :sm="3" :md="3" :lg="3">
              <el-form-item>
                <el-button
                  type="primary"
                  style="margin-left: -30px"
                  @click="insertProperty"
                  ><i class="el-icon-plus"></i> 新增属性</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
          <!-- end -->
          <el-row :gutter="10">
            <el-col :xs="30" :sm="30" :md="30" :lg="30">
              <el-form-item label="题干" prop="quesBody">
                <ckeditor v-model="quesModel.quesBody"></ckeditor>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row
            v-for="quesOption in quesModel.quesOptions"
            :key="quesOption"
            :gutter="10"
          >
            <el-col :xs="30" :sm="30" :md="30" :lg="30">
              <el-form-item :label="quesOption.number">
                <ckeditor v-model="quesOption.quesBody"></ckeditor>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- 非套题 -->
          <div
            v-if="
              quesModel.questionType !== 'NESTED_ANSWER_QUESTION' &&
              quesModel.questionType !== 'BOOL_ANSWER_QUESTION'
            "
          >
            <el-form-item label="答案" prop="quesAnswer">
              <ckeditor v-model="quesModel.quesAnswer"></ckeditor>
            </el-form-item>
          </div>
          <div v-if="quesModel.questionType == 'BOOL_ANSWER_QUESTION'">
            <el-row>
              <el-col>
                <el-form-item label="答案" prop="quesAnswer">
                  <el-select
                    v-model="quesModel.quesAnswer"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="op in options"
                      :key="op"
                      :label="op"
                      :value="op"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <div class="paper-top">
            <el-form-item>
              <el-button type="primary" @click="saveQues('quesModel')"
                >保存</el-button
              >
              <el-button
                type="primary"
                icon="caret-left"
                @click="backToQuesList()"
                >返回列表</el-button
              >
            </el-form-item>
          </div>
        </el-form>
      </div>
    </section>
  </div>
</template>
<script>
import { QUESTION_API } from "@/constants/constants";
import { isEmptyStr, QUESTION_TYPES } from "../constants/constants";
import ckeditor from "../component/ckeditor.vue";
import LinkTitlesCustom from "@/components/LinkTitlesCustom.vue";

export default {
  name: "EditOtherApp",
  components: { ckeditor, LinkTitlesCustom },
  data() {
    return {
      fullscreenLoading: false,
      questionTypes: QUESTION_TYPES,
      courseName: "",
      courseNo: "",
      paperDetailId: "",
      paperId: "",
      quesModel: {
        courseName: "",
        courseNo: "",
        quesAnswer: "",
        quesBody: "",
        questionType: "",
        difficultyDegree: "",
        publicity: true,
        answerType: "",
        quesProperties: [],
        score: 0,
      },
      options: ["正确", "错误"],
      difficultyDegreeList: [
        { label: 0.1, value: 0.1 },
        { label: 0.2, value: 0.2 },
        { label: 0.3, value: 0.3 },
        { label: 0.4, value: 0.4 },
        { label: 0.5, value: 0.5 },
        { label: 0.6, value: 0.6 },
        { label: 0.7, value: 0.7 },
        { label: 0.8, value: 0.8 },
        { label: 0.9, value: 0.9 },
        { label: 1.0, value: 1.0 },
      ],
      publicityList: [
        { label: "公开", value: true },
        { label: "非公开", value: false },
      ],
      answerTypes: [
        { label: "文本", value: "DIVERSIFIED_TEXT" },
        { label: "音频", value: "SINGLE_AUDIO" },
      ],
      coursePropertyList: [],
      coursePropertyName: "", //课程属性名
      firstPropertyList: [], //一级属性集合
      firstPropertyId: "", //一级属性id
      secondPropertyList: [], //二级属性集合
      secondPropertyId: "", //二级属性id
      //验证
      rules: {
        // quesBody: [
        //     {required: true, message: '请输入题干', trigger: 'blur'}
        // ],
        quesAnswer: [
          { required: true, message: "请输入答案", trigger: "blur" },
        ],
      },
    };
  },
  computed: {},
  created() {
    this.paperId = this.$route.params.paperId;
    this.paperDetailId = this.$route.params.paperDetailId;
    this.courseName = this.$route.params.courseName;
    this.courseNo = this.$route.params.courseNo;
    let questionType = this.$route.params.questionType;
    if (questionType) {
      this.quesModel.questionType = questionType;
    }
    this.questionId = this.$route.params.id;
    if (this.questionId) {
      this.quesModel["id"] = this.questionId;
      this.getQues(this.questionId);
    }
    if (this.courseNo) {
      this.$http
        .get(QUESTION_API + "/courseProperty/enable/" + this.courseNo)
        .then((response) => {
          this.coursePropertyList = response.data;
        });
    }

    if (isEmptyStr(this.quesModel.answerType)) {
      this.quesModel.answerType = "DIVERSIFIED_TEXT";
    }
  },
  mounted() {},
  methods: {
    saveQues(formName) {
      if (!this.quesModel.difficultyDegree) {
        this.$notify({
          message: "请选择试题难度",
          type: "error",
        });
        return false;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.questionId) {
            this.fullscreenLoading = true;
            //修改逻辑
            this.$httpWithMsg
              .put(QUESTION_API + "/question", this.quesModel)
              .then(() => {
                this.$notify({
                  message: "保存成功",
                  type: "success",
                });
              })
              .finally(() => (this.fullscreenLoading = false));
          } else {
            //新增逻辑
            this.quesModel.courseNo = this.courseNo;
            this.quesModel.courseName = this.courseName;
            this.fullscreenLoading = true;
            this.$http
              .post(
                QUESTION_API +
                  "/paper/addQuestion/" +
                  this.paperId +
                  "/" +
                  this.paperDetailId,
                this.quesModel
              )
              .then(() => {
                this.fullscreenLoading = false;
                this.$notify({
                  type: "success",
                  message: `保存成功`,
                });
                this.$router.push({ path: "/questions/question_list/0" });
              })
              .catch(() => {
                this.fullscreenLoading = false;
              });
          }
        } else {
          return false;
        }
      });
    },
    getQues(id) {
      this.$http.get(QUESTION_API + "/question/" + id).then((response) => {
        this.quesModel = response.data;

        if (isEmptyStr(this.quesModel.answerType)) {
          this.quesModel.answerType = "DIVERSIFIED_TEXT";
        }

        this.initCourseProperty();
      });
    },
    backToQuesList() {
      this.$router.push({
        path: "/questions/question_list/1",
      });
    },
    //查询所有课程属性名
    initCourseProperty() {
      var code = this.quesModel.course.code;
      this.$http
        .get(QUESTION_API + "/courseProperty/code/" + code)
        .then((response) => {
          this.coursePropertyList = response.data;
        });
    },
    //查询一级属性
    searchFirst() {
      this.firstPropertyId = "";
      this.secondPropertyId = "";
      this.secondPropertyList = [];
      for (let courseProperty of this.coursePropertyList) {
        if (courseProperty.name == this.coursePropertyName) {
          this.$http
            .get(QUESTION_API + "/property/first/" + courseProperty.id)
            .then((response) => {
              this.firstPropertyList = response.data;
            });
        }
      }
    },
    //查询二级属性
    searchSecond() {
      this.secondPropertyId = "";
      this.$http
        .get(QUESTION_API + "/property/second/" + this.firstPropertyId)
        .then((response) => {
          this.secondPropertyList = response.data;
        });
    },
    //新增属性
    insertProperty() {
      if (!this.checkInsertPro()) {
        return false;
      }
      var quesProperty = {
        id: "",
        coursePropertyName: "",
        firstProperty: {},
        secondProperty: {},
      };
      if (
        this.quesModel.quesProperties === undefined ||
        this.quesModel.quesProperties === null ||
        this.quesModel.quesProperties.length == 0
      ) {
        this.quesModel.quesProperties = [];
      }
      quesProperty.id =
        this.coursePropertyName +
        "-" +
        this.firstPropertyId +
        "-" +
        this.secondPropertyId;
      for (let quesPro of this.quesModel.quesProperties) {
        if (quesPro.id == quesProperty.id) {
          this.$notify({
            message: "该属性已存在，请重新选择",
            type: "error",
          });
          return false;
        }
      }
      quesProperty.coursePropertyName = this.coursePropertyName;
      //取到一级属性对象
      for (let property of this.firstPropertyList) {
        if (property.id == this.firstPropertyId) {
          quesProperty.firstProperty = property;
        }
      }
      //判断是否有二级属性
      if (
        this.secondPropertyList != undefined &&
        this.secondPropertyList.length > 0
      ) {
        if (!this.secondPropertyId) {
          this.$notify({
            message: "请选择二级属性",
            type: "error",
          });
          return false;
        }
      }
      //取到二级属性对象
      for (let property of this.secondPropertyList) {
        if (property.id == this.secondPropertyId) {
          quesProperty.secondProperty = property;
        }
      }
      this.quesModel.quesProperties.push(quesProperty);
      this.quesModel = Object.assign({}, this.quesModel);
      //清空下拉框
      this.coursePropertyName = "";
      this.firstPropertyId = "";
      this.secondPropertyId = "";
      this.firstPropertyList = [];
      this.secondPropertyList = [];
    },
    //删除属性
    handleClose(tag) {
      this.quesModel.quesProperties.splice(
        this.quesModel.quesProperties.indexOf(tag),
        1
      );
      this.quesModel = Object.assign({}, this.quesModel);
    },
    //新增属性验证
    checkInsertPro() {
      if (!this.coursePropertyName) {
        this.$notify({
          message: "请选择属性",
          type: "error",
        });
        return false;
      }
      if (!this.firstPropertyId) {
        this.$notify({
          message: "请选择一级属性",
          type: "error",
        });
        return false;
      }
      return true;
    },
  },
};
</script>
<style scoped src="../styles/Common.css"></style>
