<template>
  <section class="content">
    <div class="box-body">
      <!-- 表单 -->
      <el-form
        :inline="true"
        :model="formSearch"
        label-width="70px"
        label-position="right"
      >
        <el-row>
          <el-col :span="6">
            <el-form-item v-if="isSuperAdmin" label="学校">
              <el-select
                v-model="formSearch.rootOrgId"
                clearable
                filterable
                placeholder="请选择"
                class="search_width"
                size="small"
              >
                <el-option
                  v-for="item in rootOrgList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="模板名称">
              <el-input
                v-model="formSearch.fileName"
                placeholder="请输入模板名称"
                class="search_width"
                size="small"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="模板类型" prop="type" class="form-item">
              <el-select
                v-model="formSearch.type"
                class="search_width"
                :clearable="true"
                size="small"
              >
                <el-option label="试卷导出" value="PAPER_EXPORT"> </el-option>
                <el-option label="答案导出" value="ANWSER_EXPORT"> </el-option>
                <el-option label="离线试卷" value="OUTLINE_PAPER_EXPORT">
                </el-option>
                <el-option label="试卷预览" value="PAPER_VIEW"> </el-option>
                <el-option label="答案预览" value="ANWSER_VIEW"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <div class="search_down">
              <el-button
                size="small"
                type="primary"
                icon="el-icon-search"
                @click="handleSearchBtn"
              >
                查询
              </el-button>
              <el-button
                size="small"
                type="primary"
                icon="el-icon-plus"
                @click="addFile"
              >
                新增
              </el-button>
              <el-button
                size="small"
                type="primary"
                icon="el-icon-edit"
                @click="operConfig"
              >
                配置
              </el-button>
            </div>
          </el-col>
        </el-row>
      </el-form>

      <div class="block-seperator"></div>
      <!-- 页面列表 -->
      <el-table :data="tableData" border resizable stripe style="width: 100%">
        <el-table-column prop="fileName" label="模板名称"> </el-table-column>
        <el-table-column prop="suffix" width="100" label="文件类型">
        </el-table-column>
        <el-table-column width="150" prop="typeName" label="模板类型">
        </el-table-column>
        <el-table-column width="180" prop="creationTime" label="上传时间">
        </el-table-column>
        <el-table-column width="150" prop="createUser" label="上传人">
        </el-table-column>
        <el-table-column label="状态" width="70">
          <template slot-scope="scope">
            <span v-if="scope.row.enable">
              <el-tooltip
                class="item"
                effect="dark"
                content="启用"
                placement="left"
              >
                <i class="el-icon-success" style="color: green"></i>
              </el-tooltip>
            </span>
            <span v-else>
              <el-tooltip
                class="item"
                effect="dark"
                content="禁用"
                placement="left"
              >
                <i class="el-icon-error" style="color: red"></i>
              </el-tooltip>
            </span>
          </template>
        </el-table-column>
        <el-table-column :context="_self" label="操作" width="280">
          <div slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              plain
              icon="el-icon-download"
              @click="downFile(scope.row)"
            >
              下载
            </el-button>
            <el-button
              v-if="!scope.row.enable"
              size="mini"
              type="primary"
              plain
              @click="enable(scope.row)"
            >
              <i class="el-icon-check" aria-hidden="true"></i>启用
            </el-button>
            <el-button
              v-if="scope.row.enable"
              size="mini"
              type="danger"
              @click="disenable(scope.row)"
            >
              <i class="el-icon-close" aria-hidden="true"></i>禁用
            </el-button>
            <el-button
              size="mini"
              type="danger"
              plain
              icon="el-icon-remove-outline"
              @click="deleteFile(scope.row)"
            >
              删除
            </el-button>
          </div>
        </el-table-column>
      </el-table>
      <div class="page pull-right">
        <el-pagination
          v-if="paginationShow"
          :current-page="currentPage"
          :page-size="pageSize"
          :page-sizes="[10, 20, 50, 100, 200, 300]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        />
      </div>
    </div>
    <el-dialog
      title="新增模板"
      width="550px"
      :visible.sync="fileModel"
      :close-on-click-modal="false"
      @close="closeFileModel"
    >
      <el-form
        ref="fileForm"
        :key="fileModelKey"
        :inline="true"
        :model="fileForm"
        :rules="fileRules"
        label-width="90px"
      >
        <el-row class="form-row">
          <el-form-item label="模板名称" prop="templateName">
            <el-input
              v-model="fileForm.templateName"
              class="pull-length"
              placeholder="请输入模板名称"
            ></el-input>
          </el-form-item>
        </el-row>
        <el-row class="form-row">
          <el-form-item label="模板类型" prop="type" class="form-item">
            <el-select v-model="fileForm.type" :clearable="true" class="input">
              <el-option label="试卷导出" value="PAPER_EXPORT"> </el-option>
              <el-option label="答案导出" value="ANWSER_EXPORT"> </el-option>
              <el-option label="离线试卷" value="OUTLINE_PAPER_EXPORT">
              </el-option>
              <el-option label="试卷预览" value="PAPER_VIEW"> </el-option>
              <el-option label="答案预览" value="ANWSER_VIEW"> </el-option>
            </el-select>
          </el-form-item>
        </el-row>
        <el-row class="form-row">
          <el-form-item label="文件" prop="dataFile">
            <el-input
              v-model="fileForm.fileName"
              class="pull-length"
              :readonly="true"
              placeholder="文件最大限制10M"
            />
          </el-form-item>
          <el-form-item>
            <el-upload
              :before-upload="handleUploadFile"
              accept=".ftl,.zip"
              action="/upload"
            >
              <el-button
                type="primary"
                size="small"
                icon="ios-cloud-upload-outline"
                >请选择文件
              </el-button>
            </el-upload>
          </el-form-item>
        </el-row>
        <el-row class="pull-center">
          <el-button type="primary" :loading="fileForm.loading" @click="subFile"
            >确定</el-button
          >
          <el-button @click="closeFileModel">取消</el-button>
        </el-row>
      </el-form>
    </el-dialog>
    <el-dialog
      title="配置信息"
      width="550px"
      :visible.sync="configModel"
      :close-on-click-modal="false"
      @close="closeConfigModel"
    >
      <el-form
        ref="configForm"
        :key="configModelKey"
        :inline="true"
        :model="configForm"
        :rules="configRules"
        label-width="90px"
      >
        <el-row v-if="isSuperAdmin" class="form-row">
          <el-form-item label="学校名称">
            <el-input
              v-model="configForm.orgName"
              disabled="true"
              class="pull-length"
            ></el-input>
          </el-form-item>
        </el-row>
        <el-row class="form-row">
          <el-form-item label="配置名称" prop="serviceName">
            <el-input
              v-model="configForm.serviceName"
              class="pull-length"
              placeholder="请输入serviceName"
            ></el-input>
          </el-form-item>
        </el-row>
        <el-row class="pull-center">
          <el-button
            type="primary"
            :loading="configForm.loading"
            @click="subConfig"
            >确定</el-button
          >
          <el-button @click="closeConfigModel">取消</el-button>
        </el-row>
      </el-form>
    </el-dialog>
  </section>
</template>
<script>
import { CORE_API, QUESTION_API } from "@/constants/constants.js";
import { mapState } from "vuex";

export default {
  name: "ExportTemplate",
  data() {
    var reg = /\.zip|\.ftl$/;
    var validateFile = function (rule, value, callback) {
      if (value) {
        var upFileName = value.name;
        if (value.size > 1024 * 1024 * 10) {
          return callback(new Error("文件大小限制为10M"));
        } else if (!upFileName.match(reg)) {
          return callback(new Error("文件类型只能是ftl或zip"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      rootOrgList: [],
      loading: false,
      fileModel: false,
      fileModelKey: Math.random(),
      configModel: false,
      configModelKey: Math.random(),
      paginationShow: false,
      formSearch: {
        rootOrgId: null,
        fileName: "",
        type: "",
      },
      fileForm: {
        templateName: null,
        type: null,
        fileName: null,
        dataFile: null,
        loading: false,
      },
      configForm: {
        serviceName: null,
        orgName: null,
        loading: false,
      },
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 10,
      fileRules: {
        templateName: [
          {
            required: true,
            type: "string",
            message: "请输入模板名称",
            trigger: "change",
          },
          {
            pattern: /^[^\\/\\?%#&=\\+]*$/,
            message: "名称不能包含特殊字符 / ? % # & = +",
            trigger: "change",
          },
        ],
        type: [
          {
            required: true,
            type: "string",
            message: "请选择模板类型",
            trigger: "change",
          },
        ],
        dataFile: [
          {
            required: true,
            type: "object",
            message: "请选择文件",
            trigger: "change",
          },
          {
            validator: validateFile,
            type: "object",
            trigger: "change",
          },
        ],
      },
      configRules: {
        serviceName: [
          {
            required: true,
            type: "string",
            message: "请输入配置名称",
            trigger: "change",
          },
          {
            pattern: /^[^\\/\\?%#&=\\+]*$/,
            message: "名称不能包含特殊字符 / ? % # & = +",
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
    isSuperAdmin() {
      return this.user.roleList.some((role) => role.roleCode == "SUPER_ADMIN");
    },
  },
  watch: {
    "formSearch.rootOrgId": {
      immediate: false,
      handler(val) {
        if (val == null) {
          this.curDir = "";
        } else {
          this.curDir = "/";
          this.formSearch.parentId = -1;
          this.handleSearchBtn();
        }
      },
    },
  },
  //初始化查询
  created() {
    this.init();
  },
  methods: {
    downFile(row) {
      window.location.href = row.fullFilePath;
    },
    enable(row) {
      var url =
        QUESTION_API +
        "/exportTemplate/enable/" +
        this.formSearch.rootOrgId +
        "/" +
        row.id;
      this.$httpWithMsg.put(url).then(() => {
        this.$notify({
          type: "success",
          message: "启用成功！",
        });
        this.searchForm();
      });
    },
    disenable(row) {
      var url =
        QUESTION_API +
        "/exportTemplate/disenable/" +
        this.formSearch.rootOrgId +
        "/" +
        row.id;
      this.$httpWithMsg.put(url).then(() => {
        this.$notify({
          type: "success",
          message: "禁用成功！",
        });
        this.searchForm();
      });
    },
    deleteFile(row) {
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        var url =
          QUESTION_API +
          "/exportTemplate/" +
          this.formSearch.rootOrgId +
          "/" +
          row.id;
        this.$httpWithMsg.delete(url).then(() => {
          this.$notify({
            type: "success",
            message: "删除成功！",
          });
          this.searchForm();
        });
      });
    },
    handleUploadFile(file) {
      this.fileForm.dataFile = file;
      this.fileForm.fileName = file.name;
      return false;
    },
    addFile() {
      if (this.formSearch.rootOrgId == null) {
        this.$notify({
          type: "warning",
          message: "请选择学校",
        });
        return;
      }
      this.fileModel = true;
    },
    operConfig() {
      if (this.formSearch.rootOrgId == null) {
        this.$notify({
          type: "warning",
          message: "请选择学校",
        });
        return;
      }
      this.loading = true;
      var url =
        QUESTION_API + "/exportTemplate/config/" + this.formSearch.rootOrgId;
      this.$httpWithMsg
        .get(url)
        .then((response) => {
          this.configForm.serviceName = response.data.exportServiceName;
          if (this.isSuperAdmin) {
            for (const org of this.rootOrgList) {
              if (this.formSearch.rootOrgId == org.id) {
                this.configForm.orgName = org.name;
                break;
              }
            }
          }
          this.configModel = true;
        })
        .finally(() => (this.loading = false));
    },
    async subFile() {
      const res = await this.$refs.fileForm.validate();

      if (res === false) {
        return;
      }
      this.fileForm.loading = true;
      var params =
        "?templateName=" +
        this.fileForm.templateName +
        "&type=" +
        this.fileForm.type;
      var url =
        QUESTION_API +
        "/exportTemplate/add/" +
        this.formSearch.rootOrgId +
        params;
      let formData = new FormData();
      formData.append("dataFile", this.fileForm.dataFile);
      this.$httpWithMsg
        .post(url, formData)
        .then(() => {
          this.$notify({
            type: "success",
            message: "上传成功！",
          });
          this.closeFileModel();
          this.searchForm();
        })
        .finally(() => (this.fileForm.loading = false));
    },
    async subConfig() {
      const res = await this.$refs.configForm.validate();

      if (res === false) {
        return;
      }
      this.configForm.loading = true;
      var params = "?serviceName=" + this.configForm.serviceName;
      var url =
        QUESTION_API +
        "/exportTemplate/config/" +
        this.formSearch.rootOrgId +
        params;
      this.$httpWithMsg
        .put(url)
        .then(() => {
          this.$notify({
            type: "success",
            message: "配置成功！",
          });
          this.closeConfigModel();
        })
        .finally(() => (this.configForm.loading = false));
    },
    closeFileModel() {
      this.fileModel = false;
      this.$refs.fileForm.resetFields();
      this.fileModelKey = Math.random();
      this.fileForm.fileName = null;
      this.fileForm.type = null;
      this.fileForm.dataFile = null;
      this.fileForm.templateName = null;
    },
    closeConfigModel() {
      this.configModel = false;
      this.$refs.configForm.resetFields();
      this.configModelKey = Math.random();
      this.configForm.orgName = null;
      this.configForm.serviceName = null;
    },
    handleSearchBtn() {
      this.currentPage = 1;
      this.searchForm();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.searchForm();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.searchForm();
    },
    //查询
    searchForm() {
      if (this.formSearch.rootOrgId == null) {
        this.$notify({
          type: "warning",
          message: "请选择学校",
        });
        return;
      }
      this.loading = true;
      var url =
        QUESTION_API +
        "/exportTemplate/page/" +
        this.currentPage +
        "/" +
        this.pageSize;
      this.$httpWithMsg
        .get(url, { params: this.formSearch })
        .then((response) => {
          this.tableData = response.data.content;
          this.total = response.data.totalElements;
          this.loading = false;

          this.$nextTick(function () {
            this.paginationShow = true;
          });
        })
        .finally(() => (this.loading = false));
    },
    init() {
      if (this.isSuperAdmin) {
        this.$httpWithMsg
          .get(CORE_API + "/org/getRootOrgList")
          .then((response) => {
            this.rootOrgList = response.data;
          });
      } else {
        this.formSearch.rootOrgId = this.user.rootOrgId;
        this.searchForm();
      }
    },
  },
};
</script>

<style scoped src="../styles/Common.css"></style>
<style scoped>
.page {
  margin-top: 10px;
}
.pull-length {
  width: 300px;
}
.details-length {
  width: 400px;
}
.pull-center {
  margin-top: 20px;
}
.editForm .el-form-item {
  margin-bottom: 12px;
}
.form-row {
  margin-top: 20px;
}
</style>
