<template>
  <section class="content">
    <div class="box box-info">
      <div class="box-body">
        <el-table :data="privilegeGroupList" border resizable stripe>
          <el-table-column prop="id" label="ID" width="100" />
          <el-table-column prop="code" label="权限组编码" />
          <el-table-column prop="name" label="权限组名称" />
          <el-table-column width="300" label="操作">
            <div slot-scope="scope">
              <el-button
                size="small"
                type="primary"
                @click="editPrivilege(scope.row)"
              >
                权限配置
              </el-button>
            </div>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import { CORE_API } from "@/constants/constants.js";

export default {
  name: "PrivilegeGroupList",
  data() {
    return {
      privilegeGroupList: [],
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
  },
  created() {
    this.queryAppList();
  },
  methods: {
    async queryAppList() {
      var url = CORE_API + "/rolePrivilege/getPrivilegeGroupList";
      const response = await this.$httpWithMsg.get(url);
      this.privilegeGroupList = response.data;
    },
    editPrivilege(row) {
      this.$router.push({ path: "/basic/privilege_tree/" + row.id });
    },
  },
};
</script>

<style scoped></style>
