<template>
  <section class="content">
    <div class="box box-info">
      <div
        v-loading.body="loading"
        v-loading.fullscreen="loading"
        class="box-body"
        element-loading-text="请稍后..."
      >
        <!-- 表单 -->
        <el-form inline :model="formSearch">
          <el-form-item label="学校">
            <el-select
              v-model="formSearch.rootOrgId"
              placeholder="请选择"
              style="width: 180px"
            >
              <el-option
                v-for="item in rootOrgList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="考试名称">
            <el-input
              v-model="formSearch.examName"
              placeholder="考试名称"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              size="small"
              type="primary"
              icon="el-icon-search"
              @click="handleSearchBtn"
            >
              查询
            </el-button>
            <el-button
              size="small"
              type="primary"
              icon="el-icon-arrow-left"
              @click="back"
            >
              返回
            </el-button>
          </el-form-item>
        </el-form>

        <div class="block-seperator"></div>
        <!-- 页面列表 -->
        <el-table :data="tableData" border resizable stripe style="width: 100%">
          <el-table-column prop="examName" label="考试名称"> </el-table-column>
          <el-table-column width="100" label="考试类型">
            <template slot-scope="scope">
              <div>
                <span>{{ getExamType(scope.row.examType) }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            width="180"
            prop="examStartDate"
            label="考试开始时间"
          >
          </el-table-column>
          <el-table-column width="180" prop="examEndDate" label="考试结束时间">
          </el-table-column>
          <el-table-column width="100" prop="onlineCount" label="在考人数">
          </el-table-column>
          <el-table-column width="100" prop="planCount" label="计划人数">
          </el-table-column>
          <el-table-column width="100" prop="completeCount" label="完成人数">
          </el-table-column>
          <el-table-column width="100" prop="completeRatio" label="完成率(%)">
          </el-table-column>
        </el-table>
        <div class="page pull-right">
          <el-pagination
            v-if="paginationShow"
            :current-page="currentPage"
            :page-size="pageSize"
            :page-sizes="[10, 20, 50, 100, 200, 300]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { REPORTS_API, CORE_API, EXAM_TYPE } from "@/constants/constants.js";
import { mapState } from "vuex";

export default {
  name: "Project",
  data() {
    return {
      loading: false,
      rootOrgList: [],
      paginationShow: false,
      formSearch: {
        rootOrgId: null,
        examName: null,
      },
      examTypeList: EXAM_TYPE,
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 10,
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
    isSuperAdmin() {
      return this.user.roleList.some((role) => role.roleCode == "SUPER_ADMIN");
    },
  },
  //初始化查询
  created() {
    this.init();
  },
  methods: {
    getExamType(examType) {
      for (let tempExamType of this.examTypeList) {
        if (tempExamType.value == examType) {
          return tempExamType.label;
        }
      }
    },
    back() {
      this.$router.push({
        path: "/reports/exam-student-online",
      });
    },
    handleSearchBtn() {
      this.currentPage = 1;
      this.searchForm();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.searchForm();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.searchForm();
    },
    //查询
    searchForm() {
      if (this.formSearch.rootOrgId == null) {
        this.$notify({
          type: "warning",
          message: "请选择学校",
        });
        return;
      }
      this.loading = true;
      var url =
        REPORTS_API +
        "/examData/page/" +
        this.currentPage +
        "/" +
        this.pageSize;
      this.$httpWithMsg
        .get(url, { params: this.formSearch })
        .then((response) => {
          this.tableData = response.data.list;
          this.total = response.data.total;
          this.loading = false;

          this.$nextTick(function () {
            this.paginationShow = true;
          });
        })
        .finally(() => (this.loading = false));
    },
    init() {
      this.$httpWithMsg
        .get(CORE_API + "/org/getRootOrgList")
        .then((response) => {
          this.rootOrgList = response.data;
        });
    },
  },
};
</script>

<style scoped>
.page {
  margin-top: 10px;
}
.pull-length {
  width: 300px;
}
.pull-center {
  margin-top: 20px;
}
.editForm .el-form-item {
  margin-bottom: 12px;
}
</style>
