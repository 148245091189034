<template>
  <svg
    v-if="show"
    class="icon reduplicate"
    :style="{ fill: fillColor }"
    width="50px"
    height="26px"
    viewBox="0 0 1984 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    @click="markCheck"
  >
    <path
      d="M0 1024 0 0l1984 0 0 1024L0 1024zM57.6 966.4l1868.8 0L1926.4 57.6 57.6 57.6 57.6 966.4z"
    />
    <path
      d="M329.6 796.8l0-32 275.2 0 0-54.4-233.6 0 0-35.2 233.6 0 0-57.6-211.2 0 0-208 211.2 0 0-54.4-272 0 0-32 272 0L604.8 268.8l-230.4 0L374.4 233.6l320 0c6.4 0 12.8 0 19.2 0 60.8 0 124.8-6.4 188.8-22.4l0 38.4c-51.2 12.8-108.8 19.2-169.6 19.2-6.4 0-16 0-22.4 0l-41.6 0 0 51.2 278.4 0 0 32L672 352l0 54.4 214.4 0L886.4 544c0 25.6-3.2 44.8-16 57.6-9.6 12.8-28.8 16-48 16-3.2 0-6.4 0-9.6 0l-137.6 0 0 57.6 236.8 0 0 35.2L672 710.4l0 54.4 278.4 0 0 32L329.6 796.8zM672 585.6l115.2 0c0 0 3.2 0 6.4 0 12.8 0 19.2-3.2 25.6-9.6 6.4-6.4 9.6-16 6.4-28.8l0-19.2L672 528 672 585.6zM454.4 585.6l153.6 0 0-57.6-153.6 0L454.4 585.6zM672 499.2l153.6 0 0-57.6L672 441.6 672 499.2zM454.4 499.2l153.6 0 0-57.6-153.6 0L454.4 499.2z"
    />
    <path
      d="M1049.6 790.4c92.8-16 166.4-28.8 227.2-41.6l9.6-3.2L1280 739.2c-41.6-19.2-83.2-44.8-124.8-76.8l0 0-3.2 0c-28.8 12.8-57.6 22.4-92.8 28.8l0-32c76.8-28.8 134.4-67.2 166.4-115.2l3.2-3.2-105.6 0 0-201.6L1120 339.2c-28.8 12.8-54.4 22.4-76.8 28.8l0-35.2c67.2-32 112-76.8 131.2-134.4l67.2 0c-6.4 16-9.6 28.8-16 38.4l-3.2 3.2 425.6 0 0 38.4L1193.6 278.4l0 0c-12.8 19.2-32 32-54.4 44.8l-9.6 6.4 464 0 0 134.4c0 25.6-6.4 44.8-16 57.6-12.8 12.8-32 19.2-57.6 19.2-3.2 0-3.2 0-6.4 0l-217.6 0 0 3.2c-3.2 9.6-9.6 19.2-22.4 32l-12.8 12.8 323.2 0 0 35.2c-54.4 51.2-108.8 92.8-166.4 118.4l-6.4 3.2 6.4 3.2c32 9.6 89.6 19.2 169.6 28.8 25.6 6.4 44.8 9.6 60.8 9.6l0 32c-131.2-16-230.4-32-300.8-57.6l0 0 0 0c-67.2 22.4-166.4 41.6-300.8 60.8L1046.4 790.4zM1225.6 652.8c48 28.8 89.6 51.2 124.8 67.2l0 0 0 0c54.4-22.4 102.4-54.4 144-92.8l6.4-6.4-278.4 0 0 0c-9.6 9.6-22.4 19.2-35.2 22.4l0 6.4L1225.6 649.6zM1184 505.6l310.4 0c0 0 3.2 0 3.2 0 0 0 0 0 0 0 9.6 0 19.2-3.2 22.4-9.6 6.4-6.4 9.6-16 6.4-28.8l0-16L1184 451.2 1184 505.6zM1184 422.4l345.6 0 0-54.4L1184 368 1184 422.4z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    id: {
      type: String,
      default: "",
    },
    show: {
      type: Boolean,
      default: false,
    },
    fillColor: {
      type: String,
      default: "blue",
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    markCheck() {
      this.$emit("reduplicate_mark_check", this.id, this.checked);
    },
  },
};
</script>

<style lang="css">
.icon.reduplicate {
  position: absolute;
  left: -30px;
  top: 20px;
  transform: rotate(-45deg);
}
</style>
