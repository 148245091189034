<template>
  <el-container>
    <el-header style="padding: 0">
      <el-menu class="el-menu" mode="horizontal">
        <el-menu-item index="1" style="width: 200px">
          <router-link
            to="/home/overview"
            style="
              display: inline-block;
              font-size: 20px;
              line-height: 48px;
              width: 100%;
              text-decoration-line: none;
            "
            title="回到主页"
          >
            <img
              src="./icon.png"
              style="
                margin-bottom: 2px;
                line-height: 48px;
                display: inline-block;
              "
            />
            云平台主页
          </router-link>
        </el-menu-item>
        <el-menu-item
          index="4"
          style="float: right"
          title="退出系统"
          @click="logout"
        >
          <v-icon name="sign-out-alt" />
          <span style="cursor: pointer"> 退出 </span>
        </el-menu-item>
        <el-menu-item index="3" style="float: right" title="个人信息管理">
          <v-icon name="user" />
          <span style="cursor: pointer" @click="openUserDialog">
            {{ user.displayName }}
          </span>
        </el-menu-item>
        <el-menu-item
          index="2"
          style="float: right"
          title="未读通知"
          @click="openMessageHome"
        >
          <span class="el-icon-message" />
          <span style="cursor: pointer">{{ unreadMessageCount }}</span>
        </el-menu-item>
        <el-menu-item
          index="1"
          class="navbar-group-item"
          style="float: right"
          title="机构名称"
        >
          <v-icon name="users" /> {{ user.rootOrgName }}
        </el-menu-item>
      </el-menu>
    </el-header>

    <el-container>
      <HomeSide v-if="ifShowHomeSide" :key="sideKey" />
      <el-container class="main-body">
        <LinkTitles v-if="ifShowHomeSide" :key="Math.random()" />
        <router-view class="main-content"></router-view>
        <el-footer class="footer">&copy; 启明泰和 2019</el-footer>
      </el-container>
    </el-container>
    <SiteMessagePopup
      @changeUnreadMessageCount="changeUnreadMessageCount"
    ></SiteMessagePopup>
    <!-- 添加用户信息弹出框 -->
    <el-dialog
      title="个人信息"
      width="500px"
      :visible.sync="userDialog"
      @close="() => $refs.passForm.clearValidate()"
    >
      <el-tabs value="first">
        <el-tab-pane label="用户权限" name="first">
          <el-form :inline="true" label-position="right" label-width="90px">
            <el-row :gutter="10">
              <el-col>
                <el-tag
                  v-for="role in user.roleList"
                  :key="role.roleId"
                  type="primary"
                  style="margin-left: 10px; margin-top: 10px"
                >
                  {{ role.roleName }}
                </el-tag>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="修改密码" name="second">
          <el-form
            ref="passForm"
            :inline="true"
            :model="passForm"
            :rules="passRules"
            label-position="right"
            label-width="80px"
          >
            <el-row>
              <el-form-item label="密码" prop="pass">
                <el-input
                  v-model="passForm.pass"
                  type="password"
                  style="width: 300px"
                  auto-complete="off"
                  placeholder="请输入密码"
                />
              </el-form-item>
            </el-row>
            <el-row style="margin-top: 20px">
              <el-form-item label="确认密码" prop="checkPass">
                <el-input
                  v-model="passForm.checkPass"
                  type="password"
                  style="width: 300px"
                  auto-complete="off"
                  placeholder="请输入确认密码"
                />
              </el-form-item>
            </el-row>
            <el-row style="margin-top: 20px; margin-left: 100px">
              <el-button type="primary" @click="submitForm">保 存</el-button>
              <el-button @click="userDialog = false">取 消</el-button>
            </el-row>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>

    <el-dialog
      title="修改密码"
      width="500px"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      :visible.sync="passWeakDialog"
      @close="() => $refs.passWeakForm.clearValidate()"
    >
      <el-form
        ref="passWeakForm"
        :inline="true"
        :model="passWeakForm"
        :rules="passWeakRules"
        label-position="right"
        label-width="80px"
      >
        <el-row>
          <el-form-item label="密码" prop="pass">
            <el-input
              v-model="passWeakForm.pass"
              type="password"
              style="width: 300px"
              auto-complete="off"
              placeholder="输入新密码"
            />
          </el-form-item>
        </el-row>
        <el-row style="margin-top: 20px">
          <el-form-item label="确认密码" prop="checkPass">
            <el-input
              v-model="passWeakForm.checkPass"
              type="password"
              style="width: 300px"
              auto-complete="off"
              placeholder="再次输入新密码"
            />
          </el-form-item>
        </el-row>
        <el-row style="margin-top: 20px">
          <el-form-item
            ><span class="passWeakInfo"
              >*为保护您的账户安全，请重新录入新密码，以防泄露
            </span></el-form-item
          >
        </el-row>
        <el-row style="margin-top: 20px; margin-left: 80px">
          <el-button type="primary" @click="submitPassWeakForm"
            >确 认</el-button
          >
          <el-button @click="logout">取 消</el-button>
        </el-row>
      </el-form>
    </el-dialog>
  </el-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { USER_SIGNOUT, USER_SIGNIN } from "../../store/user";
import { CORE_API } from "@/constants/constants";
import HomeSide from "./HomeSide.vue";
import LinkTitles from "./LinkTitles.vue";
import SiteMessagePopup from "./SiteMessagePopup.vue";
export default {
  name: "Home",
  components: { HomeSide, LinkTitles, SiteMessagePopup },
  data() {
    let pswReg =
      /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_]+$)(?![a-z0-9]+$)(?![a-z\W_]+$)(?![0-9\W_]+$)[a-zA-Z0-9\W_]{6,10}$/;

    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else if (value && !value.match(pswReg)) {
        callback(
          new Error(
            "至少包含大写字母、小写字母、数字、特殊符号中的三种，且长度限制在6-10位！"
          )
        );
      } else {
        if (this.passForm.checkPass !== "") {
          this.$refs.passForm.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入确认密码"));
      } else if (value !== this.passForm.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };

    var validatePassWeakPass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else if (value && !value.match(pswReg)) {
        callback(
          new Error(
            "至少包含大写字母、小写字母、数字、特殊符号中的三种，且长度限制在6-10位！"
          )
        );
      } else {
        if (this.passWeakForm.checkPass !== "") {
          this.$refs.passWeakForm.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePassWeakPass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入确认密码"));
      } else if (value !== this.passWeakForm.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      unreadMessageCount: 0,
      userDialog: false,
      passWeakDialog: false,
      passForm: { pass: "", checkPass: "" },
      passWeakForm: { pass: "", checkPass: "" },
      passRules: {
        pass: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }],
      },
      passWeakRules: {
        pass: [{ validator: validatePassWeakPass, trigger: "blur" }],
        checkPass: [{ validator: validatePassWeakPass2, trigger: "blur" }],
      },
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
    ifShowHomeSide() {
      return this.$route.fullPath.startsWith("/home") === false;
    },
    sideKey() {
      const module = this.$route.fullPath.split("/")[1];
      return module;
    },
  },
  created() {
    if (this.user.passwordWeak) {
      this.passWeakDialog = true;
    }
  },
  methods: {
    ...mapActions([USER_SIGNOUT, USER_SIGNIN]),
    openMessageHome() {
      this.$router.push({ path: "/home/site-message" });
    },
    changeUnreadMessageCount(count) {
      this.unreadMessageCount = count;
    },
    openUserDialog() {
      this.passForm = { pass: "", checkPass: "" };
      this.userDialog = true;
    },
    //保存密码
    submitForm() {
      this.$refs.passForm.validate((valid) => {
        if (valid) {
          var password = encodeURIComponent(this.passForm.pass);
          var url = CORE_API + "/user/password?password=" + password;
          this.$httpWithMsg.put(url).then(() => {
            this.$notify({
              type: "success",
              message: "修改密码成功！",
            });
            this.resetForm();
            this.userDialog = false;
          });
        } else {
          console.log("error submit!");
          return false;
        }
      });
    },
    submitPassWeakForm() {
      this.$refs.passWeakForm.validate((valid) => {
        if (valid) {
          var password = encodeURIComponent(this.passWeakForm.pass);
          var url = CORE_API + "/user/password?password=" + password;
          this.$httpWithMsg.put(url).then(() => {
            this.$notify({
              type: "success",
              message: "修改密码成功！",
            });
            this.user.passwordWeak = false;
            this.USER_SIGNIN(this.user);
            this.$refs.passWeakForm.resetFields();
            this.passWeakDialog = false;
          });
        } else {
          console.log("error submit!");
          return false;
        }
      });
    },
    //重置
    resetForm() {
      this.$refs.passForm.resetFields();
    },
    logout() {
      const orgId = this.user.rootOrgId;
      const getRootOrgId = () => {
        if (location.hostname.includes("qmth.com.cn")) {
          return "";
        } else {
          return "?orgId=" + orgId;
        }
      };

      this.$http
        .post(CORE_API + "/auth/logout")
        .then(() => {
          this.USER_SIGNOUT();
          window.name = "";
          this.$router.replace({
            path: "/login" + getRootOrgId(),
          });
        })
        .catch((response) => {
          if (response.status == 500) {
            this.$notify({
              showClose: true,
              message: response.data.desc,
              type: "error",
            });
          }
          this.USER_SIGNOUT();
          window.name = "";
          this.$router.replace({
            path: "/login" + getRootOrgId(),
          });
        });
    },
  },
};
</script>

<style scoped>
.el-menu,
.el-footer {
  background-color: #3c8dbd;
  color: #ffffff;
  text-align: center;
  line-height: 60px;
}
.el-footer {
  color: #878e93;
  background-color: #ecf0f5;
  line-height: 40px;
  height: 40px !important;
}

.el-menu.el-menu--horizontal {
  border-bottom: none;
}

.el-menu >>> .el-menu-item {
  color: white !important;
}

.el-menu >>> .el-menu-item:hover,
.el-menu >>> .el-menu-item:focus {
  color: white !important;
  background-color: rgba(40, 121, 169) !important;
}

.el-menu >>> .is-active.el-menu-item:focus {
  color: white !important;
}

.el-menu >>> .navbar-group-item.is-active.el-menu-item:focus {
  color: none !important;
}

.el-menu >>> .navbar-group-item.el-menu-item:hover,
.el-menu >>> .navbar-group-item.el-menu-item:focus {
  color: white !important;
  background-color: transparent !important;
  cursor: unset;
  border-bottom: none;
}

body > .el-container {
  margin-bottom: 40px;
}

.main-body {
  min-height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
  margin-left: 20px;
}

.main-content {
  min-height: calc(100vh - 60px - 60px - 40px);
  margin-top: 20px;
  margin-right: 20px;
}

.footer {
  justify-self: flex-end;
  margin-left: -20px;
}
.passWeakInfo {
  color: blue !important;
}
.passWeakErr {
  color: red !important;
}
</style>
