<template>
  <div>
    <LinkTitlesCustom
      :current-paths="['考试管理', '考试信息', '场次特殊设置']"
    />
    <section class="content">
      <div class="box box-info">
        <div
          v-loading.body="loading"
          v-loading.fullscreen="loading"
          class="box-body"
          element-loading-text="请稍后..."
        >
          <!-- 表单 -->
          <el-form inline :model="formSearch">
            <el-row>
              <el-col :span="7">
                <el-form-item label="考试名称">
                  <el-input v-model="examName" :disabled="true"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="考试类型">
                  <el-input v-model="examTypeName" :disabled="true"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item label="时间范围">
                  <el-date-picker
                    v-model="startExamDatetimeRange"
                    class="input"
                    type="datetimerange"
                    start-placeholder="开始时间"
                    range-separator="至"
                    end-placeholder="结束时间"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    :clearable="false"
                    size="small"
                    @change="changeStartExamDatetimeRange"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="7">
                <el-form-item label=" 场次号">
                  <el-input
                    v-model="formSearch.stageOrder"
                    placeholder="场次号"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="状态">
                  <el-radio-group v-model="formSearch.enable">
                    <el-radio label>全部</el-radio>
                    <el-radio :label="true">启用</el-radio>
                    <el-radio :label="false">禁用</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item>
                  <el-button
                    size="small"
                    type="primary"
                    icon="el-icon-search"
                    @click="handleSearchBtn"
                    >查询</el-button
                  >
                  <el-button
                    size="small"
                    type="primary"
                    icon="el-icon-plus"
                    @click="handleAddStage"
                    >场次新增</el-button
                  >
                  <el-button
                    size="small"
                    type="primary"
                    icon="el-icon-setting"
                    @click="handleCommonSetting"
                    >收卷设置</el-button
                  >

                  <el-button
                    size="small"
                    type="primary"
                    icon="el-icon-download"
                    @click="handleExport"
                    >导出</el-button
                  >
                  <el-button
                    size="small"
                    type="primary"
                    icon="el-icon-arrow-left"
                    @click="back"
                    >返回</el-button
                  >
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>

          <div class="block-seperator"></div>
          <div>
            <span>批量操作：</span>

            <el-button
              size="small"
              type="success"
              icon="el-icon-check"
              :disabled="noBatchSelected"
              @click="batchModifyStageEnable(true)"
              >启用</el-button
            >
            <el-button
              size="small"
              type="danger"
              icon="el-icon-close"
              :disabled="noBatchSelected"
              @click="batchModifyStageEnable(false)"
              >禁用</el-button
            >
            <el-button
              size="small"
              type="danger"
              icon="el-icon-refresh"
              @click="clearStage"
              >清空所有场次</el-button
            >
          </div>
          <div style="width: 100%; margin-bottom: 10px"></div>

          <!-- 页面列表 -->
          <el-table
            :data="tableData"
            border
            resizable
            stripe
            style="width: 100%"
            @selection-change="selectChange"
          >
            <el-table-column
              type="selection"
              width="40"
              fixed="left"
            ></el-table-column>
            <el-table-column
              prop="stageOrder"
              width="100"
              label="场次号"
            ></el-table-column>
            <el-table-column
              width="180"
              prop="startTime"
              label="开始时间"
            ></el-table-column>
            <el-table-column
              width="180"
              prop="endTime"
              label="结束时间"
            ></el-table-column>
            <el-table-column
              width="180"
              prop="creationTime"
              label="创建时间"
            ></el-table-column>
            <el-table-column
              width="180"
              prop="updateTime"
              label="修改时间"
            ></el-table-column>
            <el-table-column width="50" label="状态">
              <span slot-scope="scope">
                <span v-if="scope.row.enable">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="启用"
                    placement="left"
                  >
                    <i class="el-icon-success" style="color: green"></i>
                  </el-tooltip>
                </span>
                <span v-else>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="禁用"
                    placement="left"
                  >
                    <i class="el-icon-error" style="color: red"></i>
                  </el-tooltip>
                </span>
              </span>
            </el-table-column>
            <el-table-column label="操作">
              <div slot-scope="scope">
                <el-button
                  v-if="!scope.row.enable"
                  size="mini"
                  type="primary"
                  plain
                  icon="el-icon-check"
                  @click="modifyStageEnable(scope.row)"
                  >启用</el-button
                >
                <el-button
                  v-if="scope.row.enable"
                  size="mini"
                  type="danger"
                  icon="el-icon-close"
                  @click="modifyStageEnable(scope.row)"
                  >禁用</el-button
                >&nbsp;
                <el-dropdown>
                  <el-button type="primary" plain size="mini">
                    更多
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>
                      <el-button
                        size="mini"
                        type="primary"
                        plain
                        icon="el-icon-edit"
                        @click="editStage(scope.row)"
                        >编辑</el-button
                      >
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </el-table-column>
          </el-table>
          <div class="page pull-right">
            <el-pagination
              v-if="paginationShow"
              :current-page="currentPage"
              :page-size="pageSize"
              :page-sizes="[10, 20, 50, 100, 200, 300]"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              @current-change="handleCurrentChange"
              @size-change="handleSizeChange"
            />
          </div>
        </div>

        <!-- 添加场次弹出框 -->
        <el-dialog
          title="场次新增"
          width="670px"
          :visible.sync="addStageVisible"
        >
          <el-form
            ref="addStageForm"
            :inline="true"
            :inline-message="false"
            :model="addStageForm"
            label-position="right"
            label-width="120px"
          >
            <el-row>
              <el-form-item label="创建方式">
                <el-radio-group
                  v-model="addStageForm.createType"
                  class="pull_right_sm"
                >
                  <el-radio label="simple">简单创建</el-radio>
                  <el-radio label="loop">循环创建</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-row>
            <!-- 简单创建 -->
            <template v-if="addStageForm.createType == 'simple'">
              <el-row>
                <el-col :span="14">
                  <el-form-item label="增加数量">
                    <el-input-number
                      v-model="addStageForm.num"
                      :controls="false"
                      :min="1"
                      :max="20"
                      label="数量"
                    ></el-input-number>
                  </el-form-item>
                </el-col>
                <el-col :span="10" style="line-height: 48px; height: 40px">
                  <i
                    class="el-icon-circle-plus"
                    style="color: #00a4ff; font-size: 24px"
                    @click="addTimeRange"
                  ></i>
                </el-col>
              </el-row>

              <el-row
                v-for="(item, index) in addStageForm.addTimeRangeArr"
                :key="'tr' + index"
              >
                <el-col :span="22">
                  <el-form-item :label="index + 1 + '.'">
                    <el-date-picker
                      v-model="item.timeRange"
                      class="input"
                      style="width: 100%"
                      type="datetimerange"
                      start-placeholder="开始时间"
                      range-separator="至"
                      end-placeholder="结束时间"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      :clearable="false"
                      size="small"
                      @change="changeStartExamDatetimeRange2Add"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="2" style="line-height: 48px; height: 40px">
                  <i
                    class="el-icon-remove"
                    style="color: #00a4ff; font-size: 24px"
                    @click="removeTimeRange(index)"
                  ></i>
                </el-col>
              </el-row>
            </template>
            <!-- 循环创建 -->
            <template v-if="addStageForm.createType != 'simple'">
              <el-row>
                <el-form-item label="选择日期范围">
                  <el-date-picker
                    v-model="addStageForm.timeRange0"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd"
                    :picker-options="repeatDatePickOptions()"
                  ></el-date-picker>
                </el-form-item>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="每日场次数量">
                    <el-input-number
                      v-model="addStageForm.num2"
                      style="width: 170px !important"
                      :controls="false"
                      :min="1"
                      :max="20"
                      label="每日场次数量"
                    ></el-input-number>
                  </el-form-item>
                </el-col>
                <el-col :span="10" style="line-height: 48px; height: 40px">
                  <i
                    class="el-icon-circle-plus"
                    style="color: #00a4ff; font-size: 24px"
                    @click="addTimeRange"
                  ></i>
                </el-col>
              </el-row>

              <el-row
                v-for="(item, index) in addStageForm.addTimeRangeArr2"
                :key="'tr2' + index"
              >
                <el-col :span="20">
                  <el-form-item :label="index + 1 + '.'">
                    <el-time-picker
                      :key="'picker0' + index"
                      v-model="item.timeRange[0]"
                      placeholder="选择开始时间"
                      style="width: 170px !important"
                      value-format="HH:mm:ss"
                      format="HH:mm:ss"
                      :picker-options="{
                        selectableRange: '00:00:00 - 23:59:59',
                      }"
                    ></el-time-picker>
                    <span class="mx-3">至</span>
                    <el-time-picker
                      :key="'picker1' + index"
                      v-model="item.timeRange[1]"
                      placeholder="选择结束时间"
                      style="width: 170px !important"
                      value-format="HH:mm:ss"
                      format="HH:mm:ss"
                      :picker-options="{
                        selectableRange: '00:00:00 - 23:59:59',
                      }"
                    ></el-time-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="2" style="line-height: 48px; height: 40px">
                  <i
                    class="el-icon-remove"
                    style="color: #00a4ff; font-size: 24px"
                    @click="removeTimeRange(index)"
                  ></i>
                </el-col>
              </el-row>
            </template>
            <el-row class="d-flex justify-content-center dialog_btn_row">
              <el-button type="primary" @click="saveAddStage">保 存</el-button>
              <el-button @click="addStageVisible = false">取 消</el-button>
            </el-row>
          </el-form>
        </el-dialog>

        <!-- 修改场次弹出框 -->
        <el-dialog
          title="编辑场次"
          width="700px"
          :visible.sync="editStageVisible"
        >
          <el-form
            ref="editStageForm"
            :inline="true"
            :inline-message="false"
            :model="editStageForm"
            :rules="editStageRules"
            label-position="right"
            label-width="120px"
          >
            <el-row>
              <el-form-item label="ID">
                <el-input
                  v-model="editStageForm.id"
                  class="input_width_lg"
                  :disabled="true"
                />
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="场次号">
                <el-input
                  v-model="editStageForm.stageOrder"
                  class="input_width_lg"
                  :disabled="true"
                />
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="开考时间" prop="startExamDatetimeRange2Edit">
                <el-date-picker
                  v-model="startExamDatetimeRange2Edit"
                  class="input"
                  type="datetimerange"
                  start-placeholder="开始时间"
                  range-separator="至"
                  end-placeholder="结束时间"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :clearable="false"
                  size="small"
                  @change="changeStartExamDatetimeRange2Edit"
                ></el-date-picker>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="特殊设置">
                <el-radio-group
                  v-model="editStageForm.specialSetting"
                  class="pull_right_sm"
                >
                  <el-radio :label="false">通用设置</el-radio>
                  <el-radio :label="true">特殊设置</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-row>
            <template v-if="editStageForm.specialSetting">
              <el-row>
                <el-form-item label="收卷设置">
                  <el-radio-group
                    v-model="editStageForm.submitType"
                    class="pull_right_sm"
                  >
                    <el-radio label="NORMAL">正常交卷</el-radio>
                    <el-radio label="TIMING_END">定点交卷</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-row>
              <el-row v-if="editStageForm.submitType == 'TIMING_END'">
                <el-form-item label="统一交卷时间" prop="submitDuration">
                  <el-input
                    v-model="editStageForm.submitDuration"
                    class="input_width_lg"
                    maxlength="10"
                    @keyup.native="handleSubmitDuration4editStage"
                  >
                    <template slot="prepend">开考后</template>
                    <template slot="append">分钟</template>
                  </el-input>
                </el-form-item>
              </el-row>
            </template>
            <el-row class="d-flex justify-content-center dialog_btn_row">
              <el-button type="primary" @click="saveEditStage">保 存</el-button>
              <el-button @click="editStageVisible = false">取 消</el-button>
            </el-row>
          </el-form>
        </el-dialog>

        <!-- 通用设置弹出框 -->
        <el-dialog
          title="收卷设置"
          width="650px"
          :visible.sync="editStageCommonSettingVisible"
        >
          <el-form
            ref="editStageCommonSettingForm"
            :inline="true"
            :inline-message="false"
            :model="editStageCommonSettingForm"
            :rules="commonSettingRules"
            label-position="right"
            label-width="120px"
          >
            <el-row>
              <el-form-item label="考试ID">
                <el-input
                  v-model="editStageCommonSettingForm.examId"
                  class="input_width_lg"
                  :disabled="true"
                />
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="考试名称">
                <el-input
                  v-model="editStageCommonSettingForm.examName"
                  class="input_width_lg"
                  :disabled="true"
                />
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="收卷设置" prop="submitType">
                <el-radio-group
                  v-model="editStageCommonSettingForm.submitType"
                  class="pull_right_sm"
                >
                  <el-radio label="NORMAL">正常交卷</el-radio>
                  <el-radio label="TIMING_END">定点交卷</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-row>
            <el-row
              v-if="editStageCommonSettingForm.submitType == 'TIMING_END'"
            >
              <el-form-item label="统一交卷时间" prop="submitDuration">
                <el-input
                  v-model="editStageCommonSettingForm.submitDuration"
                  class="input_width_lg"
                  maxlength="10"
                  @keyup.native="handleSubmitDuration"
                >
                  <template slot="prepend">开考后</template>
                  <template slot="append">分钟</template>
                </el-input>
              </el-form-item>
            </el-row>
            <el-row class="d-flex justify-content-center dialog_btn_row">
              <el-button type="primary" @click="saveEditStageCommonSetting"
                >保 存</el-button
              >
              <el-button @click="editStageCommonSettingVisible = false"
                >取 消</el-button
              >
            </el-row>
          </el-form>
        </el-dialog>
      </div>
    </section>
  </div>
</template>
<script>
import { EXAM_WORK_API } from "@/constants/constants.js";
import { mapState } from "vuex";
import LinkTitlesCustom from "@/components/LinkTitlesCustom.vue";
import moment from "moment";

let _this = null;
export default {
  name: "StageSpecialSettings",
  components: {
    LinkTitlesCustom,
  },
  data() {
    let validateExamDatetimeRange = (rule, value, callback) => {
      let examDatetimeRange = _this.startExamDatetimeRange2Edit;
      if (!examDatetimeRange) {
        callback(new Error("考试时间不允许为空"));
      } else {
        callback();
      }
    };

    return {
      loading: false,
      startExamDatetimeRange: [],
      startExamDatetimeRange2Edit: [],
      paginationShow: false,
      addStageVisible: false,
      editStageVisible: false,
      editStageCommonSettingVisible: false,
      selectedExamStageIds: [],
      exam: {},
      examName: "",
      examTypeName: "",
      formSearch: {
        examId: null,
        startTime: null,
        endTime: null,
        stageOrder: "",
        enable: true,
      },
      addStageForm: {
        timeRange0: [],
        createType: "simple",
        num: 1,
        addTimeRangeArr: [{ timeRange: [] }],
        num2: 1,
        addTimeRangeArr2: [
          {
            timeRange: ["", ""],
          },
        ],
      },
      editStageForm: {
        id: null,
        stageOrder: null,
        startTime: null,
        endTime: null,
        specialSetting: false,
        submitType: "NORMAL",
        submitDuration: 0,
      },
      editStageCommonSettingForm: {
        id: null,
        examId: null,
        examName: "",
        submitType: "NORMAL",
        submitDuration: 0,
      },
      addStageRules: {
        startTime: [
          {
            required: true,
            message: "开始时间不允许为空",
            trigger: "blur",
          },
        ],
        endTime: [
          {
            required: true,
            message: "结束时间不允许为空",
            trigger: "blur",
          },
        ],
      },
      editStageRules: {
        startExamDatetimeRange2Edit: [
          {
            required: true,
            validator: validateExamDatetimeRange,
            trigger: "blur",
          },
        ],
        submitDuration: [
          {
            required: true,
            trigger: "blur",
            message: "定点交卷时长不允许为空",
          },
        ],
      },
      commonSettingRules: {
        submitType: [
          {
            required: true,
            trigger: "change",
            message: "请选择交卷类型",
          },
        ],
        submitDuration: [
          {
            required: true,
            trigger: "blur",
            message: "定点交卷时长不允许为空",
          },
        ],
      },
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 10,
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
    isSuperAdmin() {
      return this.user.roleList.some((role) => role.roleCode == "SUPER_ADMIN");
    },
    noBatchSelected() {
      return this.selectedExamStageIds.length === 0;
    },
  },
  //初始化查询
  async created() {
    this.formSearch.examId = this.$route.params.id;
    await this.getExamDetail(this.formSearch.examId);
    this.examName = this.$route.params.examName;
    this.examTypeName = this.$route.params.examTypeName;
    this.searchForm();
    _this = this;
  },
  methods: {
    repeatDatePickOptions() {
      return {
        disabledDate: (time) => {
          return !moment(time).isBetween(
            moment(this.exam.beginTime),
            moment(this.exam.endTime)
          );
        },
      };
    },
    handleSubmitDuration() {
      this.editStageCommonSettingForm.submitDuration =
        this.editStageCommonSettingForm.submitDuration.replace(/[^\d]/g, "");
    },
    handleSubmitDuration4editStage() {
      this.editStageForm.submitDuration =
        this.editStageForm.submitDuration.replace(/[^\d]/g, "");
    },
    handleAddStage() {
      this.resetAddStageForm();
      this.addStageVisible = true;
    },
    resetAddStageForm() {
      this.addStageForm = {
        timeRange0: [],
        createType: "simple",
        num: 1,
        addTimeRangeArr: [{ timeRange: [] }],
        num2: 1,
        addTimeRangeArr2: [
          {
            timeRange: ["", ""],
          },
        ],
      };
    },
    handleCommonSetting() {
      this.editStageCommonSettingForm.examId = this.formSearch.examId;
      this.editStageCommonSettingForm.examName = this.examName;
      this.editStageCommonSettingForm.submitType = "NORMAL";
      this.editStageCommonSettingForm.submitDuration = 0;
      var url = EXAM_WORK_API + "/examStage/getExamStageSetting";
      this.$httpWithMsg
        .get(url, { params: this.formSearch })
        .then((response) => {
          if (response.data) {
            this.editStageCommonSettingForm.id = response.data.id;
            this.editStageCommonSettingForm.submitType =
              response.data.submitType;
            this.editStageCommonSettingForm.submitDuration =
              response.data.submitDuration;
          }
        })
        .finally(() => (this.loading = false));
      this.editStageCommonSettingVisible = true;
    },
    handleExport() {
      window.open(
        EXAM_WORK_API +
          "/examStage/exportExamStage/" +
          this.formSearch.examId +
          "?$key=" +
          this.user.key +
          "&$token=" +
          this.user.token
      );
    },
    clearStage() {
      this.$confirm(
        "清空场次后，已导入考生中绑定的场次信息将会清空，如需重新绑定场次，则需将考生数据再次导入！",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "error",
        }
      ).then(() => {
        var url =
          EXAM_WORK_API +
          "/examStage/clearStages?examId=" +
          this.formSearch.examId;
        this.$httpWithMsg.delete(url, {}).then(() => {
          this.$notify({
            type: "success",
            message: "操作成功！",
          });
          this.searchForm();
        });
      });
    },
    selectChange(row) {
      this.selectedExamStageIds = [];
      row.forEach((element, index) => {
        console.log(index);
        this.selectedExamStageIds.push(element.id);
      });
    },
    //添加时间段
    addTimeRange() {
      let ct = this.addStageForm.createType;
      let num;
      let addTimeRangeArr;
      if (ct == "simple") {
        num = this.addStageForm.num;
        addTimeRangeArr = this.addStageForm.addTimeRangeArr;
      } else {
        num = this.addStageForm.num2;
        addTimeRangeArr = this.addStageForm.addTimeRangeArr2;
      }

      if (num) {
        for (let i = 0; i < num; i++) {
          //不得超过20条
          if (addTimeRangeArr.length >= 20) {
            this.$alert("场次数量一次最多不得超过20条");
            break;
          }

          if (ct == "simple") {
            addTimeRangeArr.push({
              timeRange: [],
            });
          } else {
            addTimeRangeArr.push({
              timeRange: ["", ""],
            });
          }
        }
      }
    },
    //删除时间段
    removeTimeRange(index) {
      let ct = this.addStageForm.createType;
      let addTimeRangeArr =
        ct == "simple"
          ? this.addStageForm.addTimeRangeArr
          : this.addStageForm.addTimeRangeArr2;
      addTimeRangeArr.splice(index, 1);
    },

    handleDailyNumChange(value) {
      if (value) {
        this.addStageForm.addTimeRangeArr = [];
        for (let i = 0; i < value; i++) {
          this.addStageForm.addTimeRangeArr.push({ timeRange: [] });
        }
      }
    },
    changeStartExamDatetimeRange(e) {
      if (e && e.length > 0) {
        this.formSearch.startTime = e[0];
        this.formSearch.endTime = e[1];
      } else {
        this.formSearch.startTime = "";
        this.formSearch.endTime = "";
      }
    },
    changeStartExamDatetimeRange2Edit(e) {
      if (e && e.length > 0) {
        this.editStageForm.startTime = e[0];
        this.editStageForm.endTime = e[1];
      } else {
        this.editStageForm.startTime = "";
        this.editStageForm.endTime = "";
      }
    },
    changeStartExamDatetimeRange2Add(e) {
      console.log(e);
    },
    modifyStageEnable(row) {
      let confirmMsg;
      let enable;

      if (row.enable == true) {
        confirmMsg = "是否禁用该场次？";
        enable = false;
      } else {
        confirmMsg = "是否启用该场次？";
        enable = true;
      }
      this.$confirm(confirmMsg, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      }).then(() => {
        let url = EXAM_WORK_API + "/examStage/modifyStageEnable";
        let param = new URLSearchParams({
          examStageIds: [row.id],
          enable: enable,
        });
        this.$httpWithMsg.put(url, param).then(() => {
          this.$notify({
            type: "success",
            message: "操作成功！",
          });
          this.searchForm();
        });
      });
    },
    //批量启用/禁用场次
    batchModifyStageEnable(enable) {
      let confirmMsg;
      if (enable == true) {
        confirmMsg = "是否启用所选场次？";
      } else {
        confirmMsg = "是否禁用所选场次？";
      }
      this.$confirm(confirmMsg, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      }).then(() => {
        let url = EXAM_WORK_API + "/examStage/modifyStageEnable";
        let param = new URLSearchParams({
          examStageIds: this.selectedExamStageIds,
          enable: enable,
        });
        this.$httpWithMsg.put(url, param).then(() => {
          this.$notify({
            type: "success",
            message: "操作成功！",
          });
          this.searchForm();
        });
      });
    },
    editStage(row) {
      if (this.$refs["editStageForm"]) {
        this.$refs["editStageForm"].resetFields();
      }

      this.editStageForm.id = row.id;
      this.startExamDatetimeRange2Edit = [row.startTime, row.endTime];
      this.editStageForm.startTime = row.startTime;
      this.editStageForm.endTime = row.endTime;
      this.editStageForm.stageOrder = row.stageOrder;
      this.editStageForm.specialSetting = row.specialSetting;
      this.editStageForm.submitType = row.submitType;
      this.editStageForm.submitDuration =
        row.submitDuration == null ? 0 : row.submitDuration;
      this.editStageVisible = true;
    },
    saveAddStage() {
      let examStages = [];
      if (this.addStageForm.createType == "simple") {
        //按数量创建
        for (let i = 0; i < this.addStageForm.addTimeRangeArr.length; i++) {
          if (this.addStageForm.addTimeRangeArr[i].timeRange == null) {
            this.$notify({
              showClose: true,
              message: "日期范围不允许为空",
              type: "error",
            });
            return;
          }
          let startTime = this.addStageForm.addTimeRangeArr[i].timeRange[0];
          let endTime = this.addStageForm.addTimeRangeArr[i].timeRange[1];
          examStages.push({
            examId: this.formSearch.examId,
            stageOrder: i + 1,
            startTime: startTime,
            endTime: endTime,
          });
        }
      } else {
        if (this.addStageForm.timeRange0.length == 0) {
          this.$notify({
            showClose: true,
            message: "日期范围不允许为空",
            type: "error",
          });
          return;
        }

        if (
          this.addStageForm.addTimeRangeArr2.some(
            (p) => p.timeRange == null || p.timeRange.some((t) => t == "")
          )
        ) {
          this.$notify({
            showClose: true,
            message: "时间段不允许为空",
            type: "error",
          });
          return;
        }

        //按天创建
        let st0 = this.addStageForm.timeRange0[0];
        let et0 = this.addStageForm.timeRange0[1];
        let days = this.getDays(st0, et0) + 1;
        let order = 0;
        for (var j = 0; j < days; j++) {
          //日期
          let dt = this.addDays(st0, j);
          for (let tr of this.addStageForm.addTimeRangeArr2) {
            let st = dt + " " + tr.timeRange[0];
            let et = dt + " " + tr.timeRange[1];
            order++;
            examStages.push({
              examId: this.formSearch.examId,
              stageOrder: order,
              startTime: st,
              endTime: et,
            });
          }
        }
      }
      let url = EXAM_WORK_API + "/examStage/batchAddStage";

      this.$http
        .post(url, examStages)
        .then(() => {
          this.$notify({
            title: "提示",
            message: "保存成功",
            type: "success",
            duration: 2000,
          });
          this.addStageVisible = false;
          this.searchForm();
        })
        .catch((e) => {
          let response = e.response;
          if (response.status == 500) {
            this.$notify({
              showClose: true,
              message: response.data.desc,
              type: "error",
            });
          }
        });
    },
    saveEditStage() {
      var url = EXAM_WORK_API + "/examStage/modifyStage";
      this.$refs.editStageForm.validate((valid) => {
        if (valid) {
          let param = new URLSearchParams(this.editStageForm);
          this.$httpWithMsg.put(url, param).then(() => {
            this.$notify({
              type: "success",
              message: "保存成功",
            });
            this.editStageVisible = false;
            this.searchForm();
          });
        } else {
          return false;
        }
      });
    },
    saveEditStageCommonSetting() {
      var url = EXAM_WORK_API + "/examStage/saveStageSetting";
      this.$refs.editStageCommonSettingForm.validate((valid) => {
        if (valid) {
          this.$httpWithMsg
            .post(url, this.editStageCommonSettingForm)
            .then(() => {
              this.$notify({
                type: "success",
                message: "保存成功",
              });
              this.searchForm();
              this.editStageCommonSettingVisible = false;
            });
        } else {
          return false;
        }
      });
    },

    back() {
      this.$router.push({ path: "/examwork/examInfo" });
    },
    handleSearchBtn() {
      this.currentPage = 1;
      this.searchForm();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.searchForm();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.searchForm();
    },
    //查询
    searchForm() {
      this.loading = true;
      let temParams = Object.assign({}, this.formSearch);

      var url =
        EXAM_WORK_API +
        "/examStage/queryPage/" +
        (this.currentPage - 1) +
        "/" +
        this.pageSize;
      this.$httpWithMsg
        .get(url, { params: temParams })
        .then((response) => {
          this.tableData = response.data.list;
          this.total = response.data.total;
          this.loading = false;

          this.$nextTick(function () {
            this.paginationShow = true;
          });
        })
        .finally(() => (this.loading = false));
    },
    //获取两个日期相差天数
    getDays(strDateStart, strDateEnd) {
      var strSeparator = "-"; //日期分隔符
      var oDate1;
      var oDate2;
      var iDays;
      oDate1 = strDateStart.split(strSeparator);
      oDate2 = strDateEnd.split(strSeparator);
      var strDateS = new Date(oDate1[0], oDate1[1] - 1, oDate1[2]);
      var strDateE = new Date(oDate2[0], oDate2[1] - 1, oDate2[2]);
      iDays = parseInt(Math.abs(strDateS - strDateE) / 1000 / 60 / 60 / 24); //把相差的毫秒数转换为天数
      return iDays;
    },
    //日期添加指定天数
    addDays(date, days) {
      let dt = new Date(date);
      dt.setDate(dt.getDate() + days);
      return dt.getFullYear() + "-" + (dt.getMonth() + 1) + "-" + dt.getDate();
    },
    async getExamDetail(examId) {
      let url = EXAM_WORK_API + "/exam/" + examId;
      return this.$httpWithMsg.get(url).then((response) => {
        this.exam = response.data;
      });
    },
  },
};
</script>

<style scoped>
.page {
  margin-top: 10px;
}
.pull-length {
  width: 300px;
}
.pull-center {
  margin-top: 20px;
}
.editForm .el-form-item {
  margin-bottom: 12px;
}

.input_width_lg {
  width: 300px;
}
.dialog_btn_row {
  margin-top: 12px;
}
</style>
