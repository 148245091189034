<template>
  <section class="content">
    <LinkTitlesCustom :current-paths="['考试管理', '考试信息', 'ip访问限制']" />
    <div class="box box-info">
      <!-- 正文信息 -->
      <div class="box-body">
        <el-form
          ref="formSearch"
          :model="formSearch"
          :inline="true"
          label-width="70px"
        >
          <el-form-item label="考试名称">
            <el-input
              v-model="formSearch.examName"
              class="input"
              :disabled="true"
            ></el-input>
          </el-form-item>
          <el-form-item label="考试类型">
            <el-input
              v-model="formSearch.examType"
              class="input"
              :disabled="true"
            ></el-input>
          </el-form-item>
          <el-form-item label="IP">
            <el-input
              v-model="formSearch.ip"
              placeholder="请输入IP"
              class="input"
            ></el-input>
          </el-form-item>
          <el-form-item label="限制类型">
            <el-select v-model="formSearch.limitType" class="input">
              <el-option label="未选择" value=""></el-option>
              <el-option label="允许访问" value="HAS_ACCESS"></el-option>
              <el-option label="禁止访问" value="NO_ACCESS"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item class="d-block">
            <el-button
              size="small"
              type="primary"
              icon="el-icon-search"
              @click="resetPageAndSearchForm"
              >查询</el-button
            >
            <el-button
              size="small"
              icon="el-icon-refresh"
              @click="resetSearchForm"
              >重置</el-button
            >
            <el-button
              size="small"
              type="primary"
              icon="el-icon-arrow-left"
              @click="back"
              >返回</el-button
            >
          </el-form-item>
        </el-form>

        <div class="block-seperator"></div>
        <el-button
          size="small"
          type="primary"
          icon="el-icon-plus"
          @click="openAddingDialog"
          >新增</el-button
        >
        <el-button
          size="small"
          type="primary"
          icon="el-icon-edit"
          @click="editIpLimitProperty"
          >整体控制设置</el-button
        >
        <el-button
          size="small"
          type="primary"
          icon="el-icon-edit"
          :disabled="noBatchSelected"
          @click="editIpLimitType"
          >批量限制类型设置</el-button
        >
        <el-button
          size="small"
          type="primary"
          icon="el-icon-upload2"
          @click="batchImport"
          >批量导入</el-button
        >
        <el-button
          size="small"
          type="primary"
          icon="el-icon-download"
          @click="batchExport"
          >批量导出</el-button
        >
        <el-button
          size="small"
          type="danger"
          icon="el-icon-delete"
          :disabled="noBatchSelected"
          @click="batchDelete"
          >批量删除</el-button
        >
        <el-button
          size="small"
          type="danger"
          icon="el-icon-delete"
          @click="allDelete"
          >全部删除</el-button
        >
        <div style="width: 100%; margin-bottom: 10px"></div>

        <!-- 页面列表 -->
        <el-table
          :data="tableData"
          border
          style="width: 100%; text-align: center"
          @selection-change="selectChange"
        >
          <el-table-column type="selection" width="50"></el-table-column>
          <el-table-column
            prop="id"
            width="80"
            label="ID"
            sortable
          ></el-table-column>
          <el-table-column label="IP地址">
            <span slot-scope="scope" v-html="scope.row.ip"></span>
          </el-table-column>
          <el-table-column
            prop="limitType"
            label="限制类型"
            width="130"
            sortable
          ></el-table-column>
          <el-table-column prop="remark" label="备注"></el-table-column>
          <el-table-column
            width="170"
            prop="creationTime"
            label="创建时间"
          ></el-table-column>
          <el-table-column
            width="170"
            prop="updateTime"
            label="修改时间"
          ></el-table-column>
          <el-table-column label="操作" width="320">
            <div slot-scope="scope">
              <el-button
                size="mini"
                type="primary"
                icon="el-icon-edit"
                @click="editIpLimit(scope.row)"
                >修改</el-button
              >
              <el-button
                size="mini"
                type="danger"
                icon="el-icon-delete"
                @click="deleteIpLimit(scope.row)"
                >删除</el-button
              >
              <el-button
                size="mini"
                type="primary"
                icon="el-icon-edit"
                @click="editIpLimitType(scope.row)"
                >限制类型设置</el-button
              >
            </div>
          </el-table-column>
        </el-table>
        <div class="page pull-right">
          <el-pagination
            :current-page="currentPage"
            :page-size="pageSize"
            :page-sizes="[10, 20, 50, 100, 200, 300]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          ></el-pagination>
        </div>

        <!-- 新增弹出窗口 -->
        <el-dialog
          title="IP限制信息设置"
          :visible.sync="addingIpLimitDialog"
          width="350px"
          @close="closeAddIpLimitDialog"
        >
          <el-form
            ref="addIpLimitForm"
            :rules="rules"
            :model="examIpLimitForm"
            label-width="85px"
            class="editForm"
          >
            <el-form-item label="IP" prop="ip">
              <el-input
                v-model="examIpLimitForm.ip"
                class="input"
                auto-complete="off"
                :disabled="!showIpColumn"
                maxlength="20"
              ></el-input>
            </el-form-item>
            <el-form-item label="限制类型" prop="limitType">
              <el-select
                v-model="examIpLimitForm.limitType"
                class="input"
                auto-complete="off"
                maxlength="20"
              >
                <el-option
                  v-for="item in limitTypeList"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="备注" prop="remark">
              <el-input
                v-model="examIpLimitForm.remark"
                class="input"
                auto-complete="off"
                maxlength="30"
              ></el-input>
            </el-form-item>
          </el-form>
          <div style="text-align: center">
            <el-button type="primary" @click="addIpLimit">确 定</el-button>
            <el-button @click="closeAddIpLimitDialog">取 消</el-button>
          </div>
        </el-dialog>

        <!-- 切换类型弹出窗口 -->
        <el-dialog
          title="限制类型设置"
          :visible.sync="editIpLimitTypeDialog"
          width="350px"
          @close="closeEditIpLimitTypeDialog"
        >
          <el-form
            ref="addIpLimitTypeForm"
            :rules="rules"
            :model="examIpLimitTypeForm"
            :inline="true"
            label-width="85px"
            style="text-align: center"
            class="editForm"
          >
            <el-form-item label="限制类型" prop="limitType">
              <el-select
                v-model="examIpLimitTypeForm.limitType"
                class="input"
                auto-complete="off"
                maxlength="20"
              >
                <el-option
                  v-for="item in limitTypeList"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div style="text-align: center">
            <el-button type="primary" @click="addIpLimitType">确 定</el-button>
            <el-button @click="closeEditIpLimitTypeDialog">取 消</el-button>
          </div>
        </el-dialog>

        <!-- 控制修改弹出窗口 -->
        <el-dialog
          title="整体控制设置"
          :visible.sync="editIpLimitPropertyDialog"
          width="350px"
          @close="closeEditIpLimitPropertyDialog"
        >
          <el-form
            ref="addIpLimitPropertyForm"
            :rules="rules"
            :model="examIpLimitPropertyForm"
            :inline="true"
            label-width="85px"
            class="editForm"
          >
            <el-form-item label="整体控制" prop="totalLimit">
              <el-select
                v-model="examIpLimitPropertyForm.totalLimit"
                class="input"
                auto-complete="off"
                maxlength="20"
              >
                <el-option
                  v-for="item in totalList"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-show="false" label="学习中心" prop="centerLimit">
              <el-select
                v-model="examIpLimitPropertyForm.centerLimit"
                class="input"
                auto-complete="off"
                maxlength="20"
              >
                <el-option
                  v-for="item in centerList"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div style="text-align: center">
            <el-button type="primary" @click="addIpLimitProperty"
              >确 定</el-button
            >
            <el-button @click="closeEditIpLimitPropertyDialog">取 消</el-button>
          </div>
        </el-dialog>

        <!-- 导入弹窗 -->
        <el-dialog
          title="IP限制信息导入"
          width="500px"
          :visible.sync="ipLimitImportDialog"
        >
          <el-form ref="ipLimitImportForm" :model="ipLimitImportForm">
            <el-row>
              <el-form-item style="margin-left: 30px">
                <el-upload
                  ref="upload"
                  class="form_left"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  :action="uploadAction"
                  :headers="uploadHeaders"
                  :data="uploadData"
                  :on-success="uploadSuccess"
                  :on-error="uploadError"
                  :file-list="fileList"
                  :auto-upload="false"
                  :multiple="false"
                >
                  <el-button
                    slot="trigger"
                    size="small"
                    type="primary"
                    icon="el-icon-search"
                    >选择文件</el-button
                  >
                  <el-button
                    size="small"
                    style="margin-left: 10px"
                    type="primary"
                    icon="el-icon-check"
                    @click="submitUpload"
                    >确认上传
                  </el-button>
                  <el-button
                    size="small"
                    style="margin-left: 10px"
                    type="primary"
                    icon="el-icon-refresh"
                    @click="removeFile"
                    >清空文件
                  </el-button>
                  <el-button
                    size="small"
                    type="primary"
                    icon="el-icon-download"
                    @click="exportFile"
                    >下载模板
                  </el-button>
                  <div slot="tip" class="el-upload__tip">只能上传xlsx文件</div>
                </el-upload>
              </el-form-item>
            </el-row>
          </el-form>
        </el-dialog>
        <!-- 导入错误信息列表 -->
        <el-dialog title="错误提示" :visible.sync="errDialog">
          <div
            v-for="errMessage in errMessages"
            :key="errMessage.lineNum"
            class="text-danger"
          >
            第{{ errMessage.lineNum }}行：{{ errMessage.msg }}
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="errDialog = false">确定</el-button>
          </span>
        </el-dialog>
      </div>
    </div>
  </section>
</template>

<script>
import { EXAM_WORK_API } from "@/constants/constants.js";
import { mapState } from "vuex";

export default {
  data() {
    return {
      limitTypeList: [
        {
          id: "HAS_ACCESS",
          label: "允许访问",
        },
        {
          id: "NO_ACCESS",
          label: "禁止访问",
        },
      ],
      centerList: [
        {
          id: false,
          label: "关闭",
        },
        {
          id: true,
          label: "开启",
        },
      ],
      totalList: [
        {
          id: false,
          label: "全部黑名单模式",
        },
        {
          id: true,
          label: "全部白名单模式",
        },
      ],
      showIpColumn: true,
      button: {},
      ipLimitImportDialog: false,
      editIpLimitPropertyDialog: false,
      examIpLimitForm: {
        id: "",
        ip: "",
        examId: "",
        limitType: "",
        remark: "",
      },
      examIpLimitTypeForm: {
        id: "",
        examId: null,
        limitType: "",
      },
      examIpLimitPropertyForm: {
        totalLimit: "",
        centerLimit: "",
      },
      formSearch: {
        examId: "",
        examName: "",
        examType: "",
        ip: "",
        limitType: "",
      },
      selectedIpIds: [],

      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 10,

      rules: {
        ip: [
          { required: true, message: "Ip不能为空！", trigger: "change" },
          // {
          //   pattern:
          //     /^(?:(?:1[0-9][0-9]\.)|(?:2[0-4][0-9]\.)|(?:25[0-5]\.)|(?:[1-9][0-9]\.)|(?:[0-9/*]\.)){3}(?:(?:1[0-9][0-9])|(?:2[0-4][0-9])|(?:25[0-5])|(?:[1-9][0-9])|(?:[0-9/*]))$/,
          //   message: "请输入正确的ip格式",
          // },
        ],
        limitType: [
          { required: true, message: "限制类型不能为空！", trigger: "change" },
        ],
      },
      addingIpLimitDialog: false,
      editIpLimitTypeDialog: false,
      ipLimitImportForm: {
        examId: "",
      },
      uploadAction: EXAM_WORK_API + "/exam/ipLimited/import",
      uploadData: {},
      fileLoading: false,
      errDialog: false,
      fileList: [],
      uploadHeaders: {},
      errMessages: [],
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
    stuIds() {
      var ipIds = "";
      for (let ipId of this.selectedIpIds) {
        if (!ipIds) {
          ipIds += ipId;
        } else {
          ipIds += "," + ipId;
        }
      }
      return ipIds;
    },
    noBatchSelected() {
      return this.selectedIpIds.length === 0;
    },
    isSuperAdmin() {
      return this.user.roleList.some((role) => role.roleCode == "SUPER_ADMIN");
    },
  },
  created() {
    this.init();
    this.uploadHeaders = {
      key: this.user.key,
      token: this.user.token,
    };
    this.formSearch.examId = this.$route.params.id;
    this.formSearch.examType = this.$route.params.examTypeName;
    this.formSearch.examName = this.$route.params.examName;
  },
  methods: {
    //新增
    openAddingDialog() {
      if (this.$refs["examIpLimitForm"]) {
        this.$refs["examIpLimitForm"].resetFields();
      }
      this.examIpLimitForm.id = null;
      this.examIpLimitForm.ip = null;
      this.examIpLimitForm.limitType = null;
      this.examIpLimitForm.remark = null;
      this.addingIpLimitDialog = true;
      this.showIpColumn = true;
    },
    closeAddIpLimitDialog() {
      this.addingIpLimitDialog = false;
    },
    closeEditIpLimitTypeDialog() {
      this.editIpLimitTypeDialog = false;
    },
    closeEditIpLimitPropertyDialog() {
      this.editIpLimitPropertyDialog = false;
    },
    //新增信息
    addIpLimit() {
      var url = EXAM_WORK_API + "/exam/ipLimited";
      this.examIpLimitForm.examId = this.formSearch.examId;
      this.$refs.addIpLimitForm.validate((valid) => {
        if (valid) {
          this.$httpWithMsg.post(url, this.examIpLimitForm).then(() => {
            this.$notify({
              type: "success",
              message: "保存成功",
            });
            this.addingIpLimitDialog = false;
            this.searchForm();
          });
        } else {
          return false;
        }
      });
    },
    addIpLimitType() {
      var ids = this.selectedIpIds;
      var id = this.examIpLimitTypeForm.id;
      var url = EXAM_WORK_API + "/exam/ipLimited";
      if (!id) {
        url = url + "?ids=" + ids;
      }
      this.examIpLimitTypeForm.examId = this.formSearch.examId;
      this.$refs.addIpLimitTypeForm.validate((valid) => {
        if (valid) {
          this.$httpWithMsg.post(url, this.examIpLimitTypeForm).then(() => {
            this.$notify({
              type: "success",
              message: "保存成功",
            });
            this.editIpLimitTypeDialog = false;
            this.searchForm();
          });
        } else {
          return false;
        }
      });
    },
    addIpLimitProperty() {
      var url =
        EXAM_WORK_API + "/exam/ipLimited/property/" + this.formSearch.examId;
      this.$refs.addIpLimitPropertyForm.validate((valid) => {
        if (valid) {
          this.$httpWithMsg.post(url, this.examIpLimitPropertyForm).then(() => {
            this.$notify({
              type: "success",
              message: "保存成功",
            });
            this.editIpLimitPropertyDialog = false;
            this.searchForm();
          });
        } else {
          return false;
        }
      });
    },
    editIpLimit(row) {
      if (this.$refs.examIpLimitForm) {
        this.$refs.examIpLimitForm.resetFields();
      }
      this.examIpLimitForm.id = row.id;
      this.examIpLimitForm.ip = row.ip;
      this.examIpLimitForm.remark = row.remark;
      this.examIpLimitForm.limitType = this.getLimitType(row.limitType);
      this.addingIpLimitDialog = true;
      this.showIpColumn = true;
    },
    getLimitType(type) {
      if (type === "允许访问") {
        return "HAS_ACCESS";
      } else if (type === "禁止访问") {
        return "NO_ACCESS";
      }
      return null;
    },
    batchDelete() {
      this.$confirm("确定删除所选数据吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      }).then(() => {
        let url = EXAM_WORK_API + "/exam/ipLimited/" + this.selectedIpIds;
        this.deleteIp(url);
      });
    },
    allDelete() {
      this.$confirm("确定删除该考试下的所有Ip限制吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      }).then(() => {
        let url =
          EXAM_WORK_API + "/exam/ipLimited/all/" + this.formSearch.examId;
        this.deleteIp(url);
      });
    },
    deleteIpLimit(row) {
      this.$confirm("确定删除当前数据吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      }).then(() => {
        let url = EXAM_WORK_API + "/exam/ipLimited/" + row.id;
        this.deleteIp(url);
      });
    },
    editIpLimitType(row) {
      if (this.$refs.addIpLimitTypeForm) {
        this.$refs.addIpLimitTypeForm.resetFields();
      }
      if (row) {
        this.examIpLimitTypeForm.id = row.id;
        this.examIpLimitTypeForm.limitType = this.getLimitType(row.limitType);
      } else {
        this.examIpLimitTypeForm.id = null;
        this.examIpLimitTypeForm.limitType = null;
      }
      this.editIpLimitTypeDialog = true;
    },
    editIpLimitProperty() {
      if (this.$refs.addIpLimitPropertyForm) {
        this.$refs.addIpLimitPropertyForm.resetFields();
      }
      var url =
        EXAM_WORK_API + "/exam/ipLimited/property/" + this.formSearch.examId;
      this.$httpWithMsg.get(url).then((response) => {
        this.examIpLimitPropertyForm.totalLimit = response.data.totalLimit;
        this.examIpLimitPropertyForm.centerLimit = response.data.centerLimit;
      });
      this.editIpLimitPropertyDialog = true;
    },
    deleteIp(url) {
      this.$httpWithMsg.delete(url).then(() => {
        this.$notify({
          type: "success",
          message: "操作成功！",
        });
        this.searchForm();
      });
    },
    selectChange(row) {
      this.selectedIpIds = [];
      row.forEach((element) => {
        this.selectedIpIds.push(element.id);
      });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.searchForm();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.searchForm();
    },
    resetSearchForm() {
      this.formSearch.ip = "";
      this.formSearch.limitType = "";
    },
    resetPageAndSearchForm() {
      this.currentPage = 1;
      this.searchForm();
    },
    //查询方法
    searchForm() {
      this.formSearch.examId = this.$route.params.id;
      var param = new URLSearchParams(this.formSearch);
      var url =
        EXAM_WORK_API +
        "/exam/ipLimited/page/" +
        (this.currentPage - 1) +
        "/" +
        this.pageSize +
        "?" +
        param;
      this.$httpWithMsg.get(url).then((response) => {
        this.tableData = response.data.list;
        this.total = response.data.total;
      });
    },
    batchExport() {
      var param =
        new URLSearchParams(this.formSearch) +
        "&$key=" +
        this.user.key +
        "&$token=" +
        this.user.token;
      var url = EXAM_WORK_API + "/exam/ipLimited/export?" + param;
      window.open(url);
    },
    initUpload() {
      if (this.$refs.ipLimitImportForm) {
        this.$refs.ipLimitImportForm.resetFields();
      }
      this.fileList = [];
      this.uploadAction =
        EXAM_WORK_API + "/exam/ipLimited/import/" + this.formSearch.examId;
    },
    batchImport() {
      this.ipLimitImportDialog = true;
      this.initUpload();
    },
    uploadSuccess(response) {
      if (!response.hasError) {
        this.$notify({
          message: "上传成功",
          type: "success",
        });
        this.fileLoading = false;
        this.ipLimitImportDialog = false;
        this.searchForm();
      } else {
        this.fileLoading = false;
        this.ipLimitImportDialog = false;
        this.errMessages = response.failRecords;
        this.errDialog = true;
      }
    },
    uploadError(response) {
      console.log("uploadError");
      var json = JSON.parse(response.message);
      if (response.status == 500) {
        this.$notify({
          message: json.desc,
          type: "error",
        });
      }
      this.fileLoading = false;
    },
    //确定上传
    submitUpload() {
      if (!this.checkUpload()) {
        return false;
      }
      this.$refs.upload.submit();
      this.fileLoading = true;
    },
    checkUpload() {
      var fileList = this.$refs.upload.uploadFiles;
      if (fileList.length == 0) {
        this.$notify({
          message: "上传文件不能为空",
          type: "error",
        });
        return false;
      }
      if (fileList.length > 1) {
        this.$notify({
          message: "每次只能上传一个文件",
          type: "error",
        });
        return false;
      }
      for (let file of fileList) {
        if (!file.name.endsWith(".xlsx")) {
          this.$notify({
            message: "上传文件必须为xlsx格式",
            type: "error",
          });
          this.initUpload();
          return false;
        }
      }
      return true;
    },
    //清空文件
    removeFile() {
      this.$refs.upload.clearFiles();
    },
    //下载模板
    exportFile() {
      window.location.href =
        "/api/ecs_exam_work/exam/ipLimited/downloadTemplate?$key=" +
        this.user.key +
        "&$token=" +
        this.user.token;
    },
    back() {
      this.$router.push({ path: "/examwork/examInfo" });
    },
    async init() {
      this.searchForm();
    },
  },
};
</script>
<style lang="scss" scoped>
.input {
  width: 180px;
}
.el-form-item {
  margin-bottom: 15px !important;
  :deep(.el-form-item__label) {
    margin-bottom: 2px !important;
  }
}
</style>
