<template>
  <el-container>
    <el-main class="el-main-padding">
      <commonFormVue :form="form" :get-exam-condition="getExamCondition">
        <el-col :span="6">
          <el-form-item label="是否缺考">
            <el-select
              v-model="form.finished"
              class="form_search_width"
              size="small"
              clearable
              placeholder="全部"
            >
              <el-option value="false" label="是"></el-option>
              <el-option value="true" label="否"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </commonFormVue>
      <el-row>
        <el-col :span="24">
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="search('clickSelectBtn')"
            >查询</el-button
          >
          <el-button size="small" icon="el-icon-refresh" @click="resetForm"
            >重置</el-button
          >
        </el-col>
      </el-row>

      <el-row>
        <el-col v-show="currentPagePrivileges.score_detail_export" :span="24">
          <div class="block-seperator"></div>
          <span>操作：</span>
          <el-button
            v-show="!exportLoading"
            type="primary"
            size="small"
            icon="el-icon-download"
            @click="exportData"
            >导出</el-button
          >
          <el-button
            v-show="exportLoading"
            size="small"
            icon="el-icon-download"
            :loading="true"
            >导出数据中...</el-button
          >
        </el-col>
      </el-row>

      <el-row class="margin-top-10">
        <el-col :span="24">
          <el-table
            ref="multipleTable"
            v-loading="tableLoading"
            element-loading-text="数据加载中"
            :data="tableData"
            border
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              label="姓名"
              prop="studentName"
              min-width="100"
            ></el-table-column>
            <el-table-column
              label="身份证号"
              prop="identityNumber"
              min-width="100"
            ></el-table-column>
            <el-table-column
              label="学号"
              prop="studentCode"
              min-width="100"
            ></el-table-column>
            <el-table-column
              label="学习中心"
              prop="orgName"
              min-width="100"
            ></el-table-column>
            <el-table-column
              label="课程"
              prop="courseNameAndCode"
              min-width="160"
            ></el-table-column>
            <el-table-column
              label="课程层次"
              prop="courseLevel"
              width="90"
            ></el-table-column>
            <el-table-column label="场次" width="120">
              <template slot-scope="scope">
                <el-popover trigger="hover" placement="left">
                  <div style="font-size: 18px; font-family: 新宋体">
                    <tr>
                      <td style="color: green">场次序号</td>
                      <td style="color: purple; padding-left: 20px">
                        {{ scope.row.examStageOrder }}
                      </td>
                    </tr>
                    <tr>
                      <td style="color: green">开始时间</td>
                      <td style="color: purple; padding-left: 20px">
                        {{ scope.row.stageStartTime }}
                      </td>
                    </tr>
                    <tr>
                      <td style="color: green">结束时间</td>
                      <td style="color: purple; padding-left: 20px">
                        {{ scope.row.stageEndTime }}
                      </td>
                    </tr>
                  </div>

                  <div slot="reference" class="name-wrapper">
                    <span>{{ scope.row.examStageOrder }}</span>
                  </div>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column
              label="最终成绩"
              prop="finalExamScore"
              width="90"
            ></el-table-column>
            <el-table-column
              label="客观分"
              prop="objectiveScore"
              width="90"
            ></el-table-column>
            <el-table-column
              label="主观分"
              prop="subjectiveScore"
              width="90"
            ></el-table-column>
            <el-table-column
              label="是否缺考"
              prop="isAbsent"
              width="90"
            ></el-table-column>
            <el-table-column
              sortable
              :sort-method="sortBySubmitCount"
              label="正常交卷次数"
              prop="submitCount"
              width="130"
            ></el-table-column>
            <el-table-column
              label="剩余考试次数"
              prop="leftExamTimes"
              width="110"
            ></el-table-column>
            <el-table-column
              sortable
              :sort-method="sortByDisciplineCount"
              label="违纪次数"
              prop="disciplineCount"
              width="110"
            ></el-table-column>
            <el-table-column
              label="成绩统计时间"
              prop="startTime"
              width="160"
            ></el-table-column>
            <el-table-column
              label="年级"
              prop="grade"
              width="90"
            ></el-table-column>
            <el-table-column
              label="采集人"
              prop="infoCollector"
              width="100"
            ></el-table-column>
            <el-table-column
              label="专业"
              prop="specialtyName"
              width="100"
            ></el-table-column>
          </el-table>
          <div class="block pull-right">
            <el-pagination
              :current-page.sync="form.pageNo"
              :page-sizes="[10, 20, 50, 100, 200, 300]"
              :page-size="form.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            ></el-pagination>
          </div>
        </el-col>
      </el-row>
      <el-dialog title="导出方式选择" :visible.sync="dialogExportTypeVisible">
        <el-row>
          <el-col :span="24">
            <el-radio v-model="exportType" label="ALL">全部导出</el-radio>
            <el-radio v-model="exportType" label="BATCH">分批导出</el-radio>
          </el-col>
        </el-row>
        <el-row v-show="exportType == 'BATCH'" class="margin-top-10">
          <el-col :span="4">导出区间</el-col>
          <el-col :span="9">
            <el-input
              v-model="form.startLimit"
              size="small"
              placeholder="下限"
            ></el-input>
          </el-col>
          <el-col :span="2" style="text-align: center; color: #c0c4cc"
            >——</el-col
          >
          <el-col :span="9">
            <el-input
              v-model="form.endLimit"
              size="small"
              placeholder="上限"
            ></el-input>
          </el-col>
        </el-row>
        <el-row class="margin-top-20">
          <el-col :span="24">
            <el-button type="primary" plain @click="exportData">确定</el-button>
            <el-button plain @click="dialogExportTypeVisible = false"
              >取消</el-button
            >
          </el-col>
        </el-row>
      </el-dialog>
    </el-main>
  </el-container>
</template>
<script>
import { mapState } from "vuex";
import commonFormVue from "../component/commonForm.vue";
import pagePrivilege from "../mixin/pagePrivilege.js";
export default {
  components: { commonFormVue },
  mixins: [pagePrivilege],
  data() {
    return {
      total: 0,
      tableLoading: false,
      dialogExportTypeVisible: false,
      exportLoading: false,
      form: {
        rootOrgId: null,
        examRecordDataId: null,
        hasStranger: null,
        courseId: null,
        courseLevel: null,
        examId: null,
        faceSuccessPercentLower: null,
        faceSuccessPercentUpper: null,
        livenessSuccessPercentLower: null,
        livenessSuccessPercentUpper: null,
        identityNumber: null,
        orgId: null,
        studentCode: null,
        studentName: null,
        isWarn: null,
        pageNo: 1,
        pageSize: 10,
        finished: "",
        startLimit: "",
        endLimit: "",
        ORG_FIND_ALL: false, //查询所有机构
        examStageId: null,
        creator: null,
      },
      getExamCondition: {
        params: {
          name: "",
          examTypes: "ONLINE#OFFLINE#ONLINE_HOMEWORK",
        },
        filterCondition: "",
      },
      tableData: [],
      exportUrl: "/api/ecs_oe_admin/exam/score/statistic/list/export/async",
      exportFileName: "成绩统计",
      currentPagePrivileges: {
        score_detail_export: false, //导出
      },
      exportType: "ALL",
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
  },
  created() {
    this.form.creator = this.user.userId;
  },
  methods: {
    resetForm() {
      this.form = {
        examRecordDataId: null,
        hasStranger: null,
        courseId: null,
        courseLevel: null,
        examId: null,
        faceSuccessPercentLower: null,
        faceSuccessPercentUpper: null,
        livenessSuccessPercentLower: null,
        livenessSuccessPercentUpper: null,
        identityNumber: null,
        orgId: this.form.ORG_FIND_ALL ? null : this.form.orgId,
        studentCode: null,
        studentName: null,
        isWarn: null,
        pageNo: 1,
        pageSize: 10,
        finished: "",
        startLimit: "",
        endLimit: "",
        ORG_FIND_ALL: this.form.ORG_FIND_ALL,
        examStageId: null,
      };
    },
    search(type) {
      if (!this.form.examId) {
        this.$notify({
          title: "警告",
          message: "请选择考试",
          type: "warning",
          duration: 1000,
        });
        return false;
      }
      if (type && type == "clickSelectBtn") {
        this.form.pageNo = 1;
      }
      this.tableLoading = true;
      this.$http
        .post("/api/ecs_oe_admin/exam/score/statistic/list", this.form)
        .then((response) => {
          if (response.data) {
            this.tableData = response.data.content;
            this.total = response.data.totalElements;
          } else {
            this.tableData = [];
          }
          this.tableLoading = false;
        });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /**
     * pagesize改变时触发
     */
    handleSizeChange(val) {
      this.form.pageSize = val;
      this.search();
    },
    /**
     * 当前页改变时触发
     */
    handleCurrentChange() {
      this.search();
    },
    openExportDialog() {
      if (!this.form.examId) {
        this.$notify({
          title: "警告",
          message: "请选择考试",
          type: "warning",
          duration: 1000,
        });
        return false;
      }
      this.dialogExportTypeVisible = true;
      this.exportType = "ALL";
      this.form.startLimit = "";
      this.form.endLimit = "";
    },
    exportData() {
      this.$http
        .post("/api/ecs_oe_admin/exam/record/detail/check", this.form)
        .then((response) => {
          if (response.data > 0) {
            this.$confirm("成绩统计存在待审数据，是否继续导出?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }).then(() => {
              this.dialogExportTypeVisible = false;
              // this._exportData();
              this.asyncExportData();
            });
          } else {
            this.$confirm("确定执行导出?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }).then(() => {
              this.dialogExportTypeVisible = false;
              // this._exportData();
              this.asyncExportData();
            });
          }
        });
    },
    asyncExportData() {
      this.form.rootOrgId = this.user.rootOrgId;
      this.form.creator = this.user.userId;
      this.$http
        .get(
          this.exportUrl +
            "?$key=" +
            this.user.key +
            "&$token=" +
            this.user.token,
          {
            params: {
              query: this.form,
            },
          }
        )
        .then(() => {
          this.$notify({
            type: "success",
            message: "正在后台导出中，请稍后到“导出任务列表”中下载！",
          });
        })
        .catch((error) => {
          this.$notify({
            type: "error",
            message: error.response.data.desc,
          });
        });
    },
    _exportData() {
      this.exportLoading = true;
      this.form.creator = this.user.userId;
      this.$http
        .get(this.exportUrl, {
          params: {
            query: this.form,
          },
          responseType: "arraybuffer",
          timeout: 20 * 60 * 1000, //限时20分钟
        })
        .then((response) => {
          if (response.data) {
            var blob = new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            var url = URL.createObjectURL(blob);
            var a = document.createElement("a");
            a.href = url;
            a.download = this.exportFileName + ".xlsx";
            a.target = "_blank";
            a.click();
            URL.revokeObjectURL(url);
          }
          this.exportLoading = false;
        });
    },
    sortByDisciplineCount(obj1, obj2) {
      let p1 = Number(obj1.disciplineCount);
      let p2 = Number(obj2.disciplineCount);
      if (isNaN(p1)) {
        p1 = 0;
      }
      if (isNaN(p2)) {
        p2 = 0;
      }
      return p1 - p2;
    },
    sortBySubmitCount(obj1, obj2) {
      let p1 = Number(obj1.submitCount);
      let p2 = Number(obj2.submitCount);
      if (isNaN(p1)) {
        p1 = 0;
      }
      if (isNaN(p2)) {
        p2 = 0;
      }
      return p1 - p2;
    },
  },
};
</script>
<style scoped src="../style/common.css"></style>
