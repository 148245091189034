<template>
  <div>
    <LinkTitlesCustom :current-paths="['评卷设置', '设置列表', '快速设置']" />
    <section class="content">
      <div class="box box-info">
        <div class="box-header with-border">
          <div class="box-tools pull-right"></div>
        </div>
        <div class="box-body">
          <div class="scroll">
            <el-form
              :inline="true"
              :model="markerSearch"
              label-position="right"
              label-width="40px"
              @submit.native.prevent
            >
              <el-row v-if="selectedMarker.length != 0" :gutter="10">
                <el-col :xs="30" :sm="30" :md="30" :lg="30">
                  <el-form-item label="已选">
                    <el-tag
                      v-for="tag in selectedMarker"
                      :key="tag.userId"
                      :closable="tagClosable"
                      :close-transition="false"
                      :type="tagType"
                      @close="tagClose(tag)"
                      >{{ tag.name }}</el-tag
                    >
                  </el-form-item>
                </el-col>
              </el-row>
              <el-form-item label="姓名" class="pull-left">
                <el-input
                  v-model="markerSearch.name"
                  placeholder="姓名"
                  @change="searchMarker"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button
                  size="small"
                  type="primary"
                  icon="el-icon-search"
                  @click="searchMarker"
                  >查询</el-button
                >
                <el-button
                  size="small"
                  type="primary"
                  icon="el-icon-check"
                  @click="markerSelect"
                  >确定</el-button
                >
                <el-button
                  size="small"
                  type="primary"
                  icon="el-icon-arrow-left"
                  @click="back"
                  >返回</el-button
                >
              </el-form-item>
            </el-form>
            <div class="block-seperator"></div>
            <div class="table-native">
              <el-table
                v-loading="loading"
                stripe
                element-loading-text="拼命加载中"
                :data="markerData"
                border
                @selection-change="selectChange"
              >
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column label="姓名" prop="name"></el-table-column>
                <el-table-column
                  label="登录名"
                  prop="loginName"
                ></el-table-column>
              </el-table>
              <div class="page pull-right">
                <el-pagination
                  background
                  :current-page="curMarker"
                  :page-size="pageSize"
                  :page-sizes="[10, 20, 50, 100, 200, 300]"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="totalMarker"
                  @current-change="handleMarkerCurrentChange"
                  @size-change="handleSizeChange"
                ></el-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { MARKING_API, MARKING_LOGIC_API } from "@/constants/constants";
import { mapState } from "vuex";
import LinkTitlesCustom from "@/components/LinkTitlesCustom.vue";
export default {
  components: {
    LinkTitlesCustom,
  },
  data() {
    return {
      markerSearch: {
        name: "",
      },
      markerData: [],
      oldData: [],
      selectedMarker: [],
      oldSelectedMarker: [],
      curMarker: 1,
      totalMarker: 10,
      pageSize: 10,
      loading: true,
      workId: "",
      examName: "",
      courseCode: "",
      markTasks: {
        workId: "",
        userId: [],
        paperId: "",
      },
      paperId: "",
      tagType: "primary",
      tagClosable: true,
      marked: false,
      progress: 0,
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
    selectedIds() {
      var selectedIds = [];
      for (let marker of this.selectedMarker) {
        selectedIds.push(marker.userId);
      }
      return selectedIds;
    },
  },
  created() {
    this.workId = this.$route.params.workId;
    this.examName = this.$route.params.name;
    this.courseCode = this.$route.params.courseCode;
    this.initMarker();
    this.searchSelectedMarker();
    //校验评卷工作是否开始，如果开始则不能删除评卷员
    this.checkMarked();
  },
  methods: {
    selectChange(val) {
      var selectedMarker = this.selectedMarker;
      val.forEach((element) => {
        selectedMarker.push(element);
        this.removeMarkerData(element.userId);
      });
      this.selectedMarker = selectedMarker;
    },
    markerSelect() {
      if (this.selectedIds.length == 0) {
        this.$message.error("请选择评卷员！");
      } else {
        console.log(this.selectedIds);
        this.markTasks = {
          workId: this.workId,
          userIds: this.selectedIds,
          courseCode: this.courseCode,
        };
        this.loading = true;
        this.$http.post(MARKING_LOGIC_API + "/markTasks", this.markTasks).then(
          () => {
            this.$notify({
              message: "快速设置成功",
              type: "success",
            });
            this.loading = false;
            this.initMarker();
            this.searchSelectedMarker();
          },
          () => {
            this.$notify({
              message: "快速设置失败",
              type: "error",
            });
            this.loading = false;
            this.initMarker();
            this.searchSelectedMarker();
          }
        );
      }
    },
    filterMarker() {
      var tempData = this.markerData.filter((element) => {
        if (this.markerSearch.name) {
          return element.name.includes(this.markerSearch.name);
        } else {
          return true;
        }
      });
      this.markerData = tempData;
      this.totalMarker = tempData.length;
    },
    handleMarkerCurrentChange(val) {
      this.curMarker = val;
      this.searchMarker();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.searchMarker();
    },
    pagingMarker() {
      var start = (this.curMarker - 1) * this.pageSize;
      var end =
        this.curMarker * this.pageSize < this.totalMarker
          ? this.curMarker * this.pageSize
          : this.totalMarker;
      var tempData = [];
      console.log(`当前页: ${this.curMarker},开始:${start},结束:${end}`);
      for (let i = start; i < end; i++) {
        tempData.push(this.markerData[i]);
      }
      console.log(tempData);
      this.markerData = tempData;
    },
    searchMarker() {
      this.loading = true;
      this.markerData = this.oldData;
      this.filterMarker();
      this.pagingMarker();
      this.loading = false;
    },
    initMarker() {
      this.loading = true;
      this.$http
        .get(
          MARKING_LOGIC_API +
            "/markUsers?workId=" +
            this.workId +
            "&courseCode=" +
            this.courseCode
        )
        .then((response) => {
          this.markerData = response.data;
          this.oldData = this.markerData.slice(0);
          this.totalMarker = response.data.length;
          this.searchMarker();
          this.loading = false;
        });
    },
    checkMarked() {
      var self = this;
      self.loading = true;
      self.$http
        .get(MARKING_API + "/markWorks/" + self.workId)
        .then((response) => {
          self.loading = false;
          if (response.markedStudentPaper > 0) {
            self.marked = true;
            self.progress = response.progress;
          }
        })
        .catch((error) => {
          this.$notify({
            message: error.message,
            type: "error",
          });
        });
    },
    searchSelectedMarker() {
      this.loading = true;
      this.$http
        .get(
          MARKING_LOGIC_API +
            "/markUsers/assign?workId=" +
            this.workId +
            "&courseCode=" +
            this.courseCode
        )
        .then((response) => {
          this.selectedMarker = response.data;
          this.oldSelectedMarker = this.selectedMarker.slice(0);
          this.loading = false;
        });
    },
    back() {
      this.$router.push({
        path:
          "/marking/mark_setting_main/" +
          this.$route.params.workId +
          "/" +
          encodeURIComponent(this.$route.params.name),
      });
    },
    isOldSelectedMarker(marker) {
      var flag = false;
      for (let tempMarker of this.oldSelectedMarker) {
        if (tempMarker.userId == marker.userId) {
          flag = true;
          break;
        }
      }
      return flag;
    },
    tagClose(tag) {
      this.checkMarked();
      if (this.marked && this.isOldSelectedMarker(tag)) {
        this.$notify({
          message: "评卷工作已开始，不能删除该评卷员！",
          type: "error",
        });
        return;
      }
      for (let [index, marker] of this.selectedMarker.entries()) {
        if (tag.userId == marker.userId) {
          this.selectedMarker.splice(index, 1);
          this.markerData.push(tag);
          this.oldData.push(tag);
          break;
        }
      }
    },
    removeMarkerData(userId) {
      for (let [index, marker] of this.markerData.entries()) {
        if (userId == marker.userId) {
          this.markerData.splice(index, 1);
          break;
        }
      }
      for (let [index, marker] of this.oldData.entries()) {
        if (userId == marker.userId) {
          this.oldData.splice(index, 1);
          break;
        }
      }
    },
  },
};
</script>
<style lang="css" scoped>
li {
  list-style-type: none;
}

.searchFrame {
  margin-right: 10px;
  margin-bottom: 10px;
}

.page {
  margin-top: 10px;
}

.el-tag {
  margin-right: 10px;
}
.table-native {
  width: 700px;
}
</style>
