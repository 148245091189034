<template>
  <el-form>
    <el-form-item>
      <el-button
        size="small"
        type="primary"
        icon="el-icon-arrow-left"
        @click="back"
      >
        返回
      </el-button>
    </el-form-item>
    <el-form-item>
      <div class="site-message-container">
        <h3
          style="
            font-size: 24px;
            font-weight: 500;
            color: rgba(34, 34, 34, 1);
            line-height: 33px;
          "
        >
          {{ message.title }}
        </h3>
        <h5
          style="
            font-size: 13px;
            font-weight: 400;
            color: rgba(153, 153, 153, 1);
            line-height: 18px;
            margin-top: 16px;
            margin-bottom: 40px;
          "
        >
          发布时间: {{ message.publishTime }}
        </h5>
        <div class="site-message-content" v-html="message.content"></div>
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
import { EXAM_WORK_API } from "@/constants/constants.js";
export default {
  name: "SiteMessageDetail",
  data() {
    return {
      message: {},
    };
  },
  watch: {
    $route: function () {
      this.init();
    },
  },
  async mounted() {
    await this.prepareData();
  },
  async beforeUpdate() {
    await this.prepareData();
  },
  created() {
    this.init();
  },
  methods: {
    back() {
      this.$router.push({ path: "/home/site-message" });
    },
    async prepareData() {
      if (!this.message.hasRead) {
        var url =
          EXAM_WORK_API +
          "/notice/updateNoticeReadStatus?noticeId=" +
          this.$route.params.id;
        this.$httpWithMsg.post(url).then(() => {
          this.$eventHub.$emit("UnreadNoticeChange");
        });
      }
    },
    init() {
      var url = EXAM_WORK_API + "/notice/" + this.$route.params.id;
      this.$httpWithMsg.get(url).then((response) => {
        if (response.data.publishStatus === "RECALLED") {
          response.data.title = "发送者已撤回消息：" + response.data.title;
          response.data.content = "该消息已被发送者撤回。";
        }
        this.message = response.data;
      });
    },
  },
};
</script>

<style scoped>
.btn {
  float: right;
}
.back-block {
  line-height: 16px;
  display: flex;
  align-items: center;
}

.site-message-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #eeeeee;
  border-radius: 6px;
  background-color: #ffffff;
  text-align: center;
  min-height: 400px;
}
.site-message-content {
  overflow: auto;
}
</style>
