<template>
  <section class="content" style="margin-top: 20px">
    <div class="box box-info">
      <!-- 正文信息 -->
      <div class="box-body">
        <el-form
          :model="formSearch"
          :inline="true"
          label-position="right"
          class="el-form el-form--inline"
          style="
            border-bottom: 1px solid rgb(221, 221, 221);
            padding-bottom: 10px;
            margin-bottom: 10px;
          "
        >
          <el-form-item label="学校">
            <el-select
              v-model="formSearch.orgId"
              :disabled="showGoBack"
              placeholder="请选择"
              @change="loadExamList(formSearch.orgId)"
            >
              <el-option
                v-for="item in orgList"
                :key="item.orgId"
                :label="item.orgName"
                :value="item.orgId"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="考试">
            <el-select
              v-model="formSearch.examId"
              :disabled="showGoBack"
              placeholder="请选择"
              @change="searchRecords"
            >
              <el-option
                v-for="item in examList"
                :key="item.examId"
                :label="item.examName"
                :value="item.examId"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button
              v-show="showSearch"
              size="small"
              type="primary"
              icon="el-icon-search"
              @click="searchRecords"
              >查询
            </el-button>

            <el-button
              v-show="!checkEmptyNumber(formSearch.examId) && hasPermit"
              size="small"
              type="primary"
              icon="el-icon-plus"
              :disabled="!hasPermit"
              @click="openOtherSettingDialog"
              >新增其它事项
            </el-button>

            <el-button
              v-show="!checkEmptyNumber(formSearch.examId)"
              size="small"
              type="primary"
              icon="el-icon-refresh"
              @click="refreshStatistic"
              >统计刷新
            </el-button>

            <el-button
              v-show="showGoBack"
              size="small"
              type="primary"
              icon="el-icon-arrow-left"
              @click="goBack"
              >返回
            </el-button>
          </el-form-item>
        </el-form>

        <!-- 数据列表 -->
        <el-table
          v-loading="loading"
          :data="tableData"
          element-loading-text="数据加载中"
          style="width: 100%"
          border
          stripe
        >
          <el-table-column width="60" label="类型" prop="type" />
          <el-table-column width="80" label="人科次" prop="totalStudent" />
          <el-table-column width="100" label="课程数量" prop="totalCourse" />
          <el-table-column width="100" label="试卷数量" prop="totalPaper" />
          <el-table-column width="100" label="印刷数量A3" prop="A3" />
          <el-table-column width="100" label="印刷数量A4" prop="A4" />
          <el-table-column width="100" label="总数(A3)" prop="sumA3" />
          <el-table-column width="80" label="试卷袋数" prop="totalPkg" />
          <el-table-column label="事项说明" prop="remark" />
          <el-table-column width="120" label="印刷供应商" prop="supplierName" />
          <el-table-column width="120" label="项目经理" prop="pmName" />

          <el-table-column
            width="180"
            label="操作"
            :context="_self"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                v-if="scope.row.btnType == 'BACKUP'"
                size="mini"
                type="primary"
                icon="el-icon-menu"
                :disabled="!hasPermit"
                plain
                @click="openBackupSettingDialog"
                >备份设置
              </el-button>

              <el-button
                v-if="scope.row.btnType == 'OTHER'"
                size="mini"
                type="primary"
                icon="el-icon-edit"
                :disabled="!hasPermit"
                plain
                @click="openOtherSettingDialog(scope.row)"
                >修改
              </el-button>

              <el-button
                v-if="scope.row.btnType == 'OTHER'"
                size="mini"
                type="danger"
                icon="el-icon-delete"
                :disabled="!hasPermit"
                @click="removeOtherSetting(scope.row)"
                >删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!-- 备份设置弹窗 -->
      <el-dialog
        title="备份设置"
        width="450px"
        :visible.sync="backupSettingDialog"
        @close="closeBackupSettingDialog"
      >
        <el-form
          ref="backupSettingForm"
          :model="backupSettingForm"
          :rules="rules"
          label-position="right"
          label-width="90px"
          inline-message
        >
          <el-tabs v-model="eachPkgTab">
            <el-tab-pane name="first">
              <span slot="label"
                >每袋冗余设置
                <el-switch v-model="backupSettingForm.needEachPkg"></el-switch
              ></span>
              <el-form-item label="比例" prop="eachPkgPercent">
                <el-input
                  v-model="backupSettingForm.eachPkgPercent"
                  :disabled="!backupSettingForm.needEachPkg"
                  class="w200"
                  ><template slot="append">%</template></el-input
                >
              </el-form-item>
              <el-form-item label="最大" prop="eachPkgMax">
                <el-input
                  v-model="backupSettingForm.eachPkgMax"
                  :disabled="!backupSettingForm.needEachPkg"
                  class="w200"
                  ><template slot="append">%</template></el-input
                >
              </el-form-item>
              <el-form-item label="最小" prop="eachPkgMin">
                <el-input
                  v-model="backupSettingForm.eachPkgMin"
                  :disabled="!backupSettingForm.needEachPkg"
                  class="w200"
                  ><template slot="append">%</template></el-input
                >
              </el-form-item>
            </el-tab-pane>
          </el-tabs>

          <el-tabs v-model="alonePkgTab">
            <el-tab-pane name="first">
              <span slot="label"
                >单独备份袋设置
                <el-switch v-model="backupSettingForm.needAlonePkg"></el-switch
              ></span>
              <el-form-item label="归集参数" prop="groupType">
                <el-select
                  v-model="backupSettingForm.groupType"
                  :disabled="!backupSettingForm.needAlonePkg"
                  placeholder="请选择"
                  class="w200"
                >
                  <el-option
                    v-for="item in groupTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="比例" prop="alonePkgPercent">
                <el-input
                  v-model="backupSettingForm.alonePkgPercent"
                  :disabled="!backupSettingForm.needAlonePkg"
                  class="w200"
                  ><template slot="append">%</template>
                </el-input>
              </el-form-item>
              <el-form-item label="最大" prop="alonePkgMax">
                <el-input
                  v-model="backupSettingForm.alonePkgMax"
                  :disabled="!backupSettingForm.needAlonePkg"
                  class="w200"
                  ><template slot="append">%</template></el-input
                >
              </el-form-item>
              <el-form-item label="最小" prop="alonePkgMin">
                <el-input
                  v-model="backupSettingForm.alonePkgMin"
                  :disabled="!backupSettingForm.needAlonePkg"
                  class="w200"
                  ><template slot="append">%</template></el-input
                >
              </el-form-item>
            </el-tab-pane>
          </el-tabs>
        </el-form>
        <div style="text-align: center">
          <el-button type="primary" @click="editBackupSetting"
            >确 定
          </el-button>
          <el-button @click="closeBackupSettingDialog">取 消</el-button>
        </div>
      </el-dialog>

      <!-- 其它事项弹窗 -->
      <el-dialog
        title="其它事项"
        width="500px"
        :visible.sync="otherSettingDialog"
        @close="closeOtherSettingDialog"
      >
        <el-form
          ref="otherSettingForm"
          :model="otherSettingForm"
          :rules="rules"
          label-position="right"
          label-width="90px"
          inline-message
        >
          <el-form-item label="事项内容" prop="remark">
            <el-input
              v-model="otherSettingForm.remark"
              type="textarea"
              :rows="8"
              class="w300"
            ></el-input>
          </el-form-item>
        </el-form>
        <div style="text-align: center; margin-top: 10px">
          <el-button type="primary" @click="editOtherSetting">确 定 </el-button>
          <el-button @click="closeOtherSettingDialog">取 消</el-button>
        </div>
      </el-dialog>
    </div>
  </section>
</template>

<script>
import { PRINT_API } from "@/constants/constants";
import { groupTypeList } from "../constants/constants.js";
import { mapState } from "vuex";
import { checkEmptyStr, checkEmptyNumber } from "../utils/common.js";

export default {
  data() {
    let validateEachPkgPercent = (rule, value, callback) => {
      if (this.backupSettingForm.needEachPkg) {
        if (checkEmptyNumber(this.backupSettingForm.eachPkgPercent)) {
          callback(new Error("请输入正确的数值！"));
          return;
        }
        if (this.backupSettingForm.eachPkgPercent > 100) {
          callback(new Error("请输入100以内的数值！"));
          return;
        }
      }
      callback();
    };

    let validateEachPkgMax = (rule, value, callback) => {
      if (this.backupSettingForm.needEachPkg) {
        if (checkEmptyNumber(this.backupSettingForm.eachPkgMax)) {
          callback(new Error("请输入正确的数值！"));
          return;
        }
        if (this.backupSettingForm.eachPkgMax > 100) {
          callback(new Error("请输入100以内的数值！"));
          return;
        }
      }
      callback();
    };

    let validateEachPkgMin = (rule, value, callback) => {
      if (this.backupSettingForm.needEachPkg) {
        if (checkEmptyNumber(this.backupSettingForm.eachPkgMin)) {
          callback(new Error("请输入正确的数值！"));
          return;
        }
        if (this.backupSettingForm.eachPkgMin > 100) {
          callback(new Error("请输入100以内的数值！"));
          return;
        }
      }
      callback();
    };

    let validateAlonePkgPercent = (rule, value, callback) => {
      if (this.backupSettingForm.needAlonePkg) {
        if (checkEmptyNumber(this.backupSettingForm.alonePkgPercent)) {
          callback(new Error("请输入正确的数值！"));
          return;
        }
        if (this.backupSettingForm.alonePkgPercent > 100) {
          callback(new Error("请输入100以内的数值！"));
          return;
        }
      }
      callback();
    };

    let validateAlonePkgMax = (rule, value, callback) => {
      if (this.backupSettingForm.needAlonePkg) {
        if (checkEmptyNumber(this.backupSettingForm.alonePkgMax)) {
          callback(new Error("请输入正确的数值！"));
          return;
        }
        if (this.backupSettingForm.alonePkgMax > 100) {
          callback(new Error("请输入100以内的数值！"));
          return;
        }
      }
      callback();
    };

    let validateAlonePkgMin = (rule, value, callback) => {
      if (this.backupSettingForm.needAlonePkg) {
        if (checkEmptyNumber(this.backupSettingForm.alonePkgMin)) {
          callback(new Error("请输入正确的数值！"));
          return;
        }
        if (this.backupSettingForm.alonePkgMin > 100) {
          callback(new Error("请输入100以内的数值！"));
          return;
        }
      }
      callback();
    };

    let validateGroupType = (rule, value, callback) => {
      if (this.backupSettingForm.needAlonePkg) {
        if (checkEmptyStr(this.backupSettingForm.groupType)) {
          callback(new Error("请选择归集参数！"));
          return;
        }
      }
      callback();
    };

    return {
      formSearch: {
        orgId: "",
        examId: "",
      },
      curUserRole: {},
      groupTypeList: groupTypeList,
      loaded: false,
      hasPermit: false,
      loading: false,
      showSearch: true,
      showGoBack: false,
      tableData: [],
      projectId: "",
      eachPkgTab: "first",
      alonePkgTab: "first",
      orgList: [],
      examList: [],
      backupSettingDialog: false,
      backupSettingForm: {
        projectId: "",
        needAlonePkg: true,
        needEachPkg: true,
        eachPkgPercent: "",
        eachPkgMax: "",
        eachPkgMin: "",
        alonePkgPercent: "",
        alonePkgMax: "",
        alonePkgMin: "",
        groupType: null,
      },
      otherSettingDialog: false,
      otherSettingForm: {
        id: "",
        projectId: "",
        remark: "",
      },
      rules: {
        eachPkgPercent: [
          {
            type: "number",
            required: true,
            validator: validateEachPkgPercent,
            trigger: "change",
          },
        ],
        eachPkgMax: [
          {
            type: "number",
            required: true,
            validator: validateEachPkgMax,
            trigger: "change",
          },
        ],
        eachPkgMin: [
          {
            type: "number",
            required: true,
            validator: validateEachPkgMin,
            trigger: "change",
          },
        ],
        alonePkgPercent: [
          {
            type: "number",
            required: true,
            validator: validateAlonePkgPercent,
            trigger: "change",
          },
        ],
        alonePkgMax: [
          {
            type: "number",
            required: true,
            validator: validateAlonePkgMax,
            trigger: "change",
          },
        ],
        alonePkgMin: [
          {
            type: "number",
            required: true,
            validator: validateAlonePkgMin,
            trigger: "change",
          },
        ],
        groupType: [
          { required: true, validator: validateGroupType, trigger: "change" },
        ],
        remark: [
          { required: true, message: "请输入事项内容！", trigger: "change" },
        ],
      },
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
  },
  created() {
    this.loadUserRole(this.user);

    if (this.curUserRole.isSuperLeader || this.curUserRole.isPM) {
      this.hasPermit = true;
    } else {
      this.hasPermit = false;
    }

    /* 加载默认数据 */
    let orgId = this.$route.query.orgId;
    let examId = this.$route.query.examId;
    //console.log(orgId + " - " + examId);

    if (!checkEmptyNumber(orgId) && !checkEmptyNumber(examId)) {
      this.loaded = true;
      this.loadOrgList();
      this.loadExamList(orgId);
      this.showSearch = false;
      this.showGoBack = true;
      this.formSearch.orgId = parseInt(orgId);
      this.formSearch.examId = parseInt(examId);
      this.searchRecords();
    } else {
      this.loaded = false;
      this.loadOrgList();
    }
  },
  methods: {
    searchRecords() {
      /* 查询记录列表 */
      let orgId = this.formSearch.orgId;
      if (checkEmptyNumber(orgId)) {
        this.$notify({
          title: "提示",
          message: "请选择学校！",
          type: "warning",
        });
        return;
      }

      let examId = this.formSearch.examId;
      if (checkEmptyNumber(examId)) {
        this.$notify({
          title: "提示",
          message: "请选择考试！",
          type: "warning",
        });
        return;
      }

      this.loading = true;
      let url =
        PRINT_API + "/printing/project/statistic/" + orgId + "/" + examId;

      this.$http.post(url, this.formSearch).then(
        (response) => {
          this.tableData = [];
          this.projectId = response.data.projectId;
          this.renderTableColumns(response.data);
          this.loadOtherSetting(this.projectId);
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          console.log(error.response);
          this.$notify({
            title: "错误",
            type: "error",
            message: error.response.data.desc,
          });
        }
      );
    },
    renderTableColumns(data) {
      let normalColumn = {
        type: "常规",
        A3: data.normalA3,
        A4: data.normalA4,
        sumA3: data.summary,
        totalStudent: data.totalStudent,
        totalCourse: data.totalCourse,
        totalPaper: data.totalPaper,
        totalPkg: data.totalPkg,
        supplierName: data.supplierName,
        pmName: data.pmName,
      };
      this.tableData.push(normalColumn);

      let backupColumn = {
        type: "备份",
        A3: data.backupA3,
        A4: data.backupA4,
        sumA3: data.backupSummary,
        btnType: "BACKUP",
      };
      this.tableData.push(backupColumn);

      let totalColumn = {
        type: "合计",
        A3: data.totalA3,
        A4: data.totalA4,
        sumA3: data.totalSummary,
      };
      this.tableData.push(totalColumn);
    },
    selectDefault() {
      if (this.loaded) {
        return;
      }
      if (this.orgList.length > 0) {
        let firstOrgId = this.orgList[0].orgId;
        this.formSearch.orgId = firstOrgId;
        this.loadExamList(firstOrgId);
      }
    },
    loadOrgList() {
      /* 查询学校列表 */
      let url = PRINT_API + "/printing/project/org/list";
      this.$http.post(url).then(
        (response) => {
          this.orgList = response.data;
          this.selectDefault();
        },
        (error) => {
          console.log(error.response);
          // ignore
        }
      );
    },
    loadExamList(orgId) {
      /* 查询考试列表 */
      this.formSearch.examId = "";
      this.examList = [];
      this.tableData = [];
      this.projectId = "";
      if (checkEmptyNumber(orgId)) {
        return;
      }

      let url = PRINT_API + "/printing/project/exam/list?orgId=" + orgId;
      this.$http.post(url).then((response) => {
        this.examList = response.data;
        if (this.loaded) {
          return;
        }
        if (this.examList.length > 0) {
          this.formSearch.examId = this.examList[0].examId;
          this.searchRecords();
        }
      });
    },
    refreshStatistic() {
      let checkUrl = PRINT_API + "/printing/project/statistic/refresh/check";
      this.$http.get(checkUrl).then((response) => {
        if (response.data.data) {
          this.$notify({
            title: "提示",
            message: "尚有项目统计任务正在刷新中，请稍后再刷新！",
            type: "warning",
          });
        } else {
          /* 刷新当前统计信息 */
          let orgId = this.formSearch.orgId;
          if (checkEmptyNumber(orgId)) {
            this.$notify({
              title: "提示",
              message: "请选择学校！",
              type: "warning",
            });
            return;
          }

          let examId = this.formSearch.examId;
          if (checkEmptyNumber(examId)) {
            this.$notify({
              title: "提示",
              message: "请选择考试！",
              type: "warning",
            });
            return;
          }

          this.$confirm("刷新当前统计信息？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              let url =
                PRINT_API +
                "/printing/project/statistic/refresh/" +
                orgId +
                "/" +
                examId;
              this.$http.post(url).then(
                () => {
                  this.$notify({
                    title: "提示",
                    message: "刷新成功！",
                    type: "success",
                  });
                  this.searchRecords();
                },
                (error) => {
                  console.log(error.response);
                  this.$notify({
                    title: "错误",
                    type: "error",
                    message: error.response.data.desc,
                  });
                }
              );
            })
            .catch(() => {
              // ignore
            });
        }
      });
    },
    editBackupSetting() {
      /* 保存备份设置 */
      this.$refs.backupSettingForm.validate((valid) => {
        if (!valid) {
          return false;
        }

        let curLoading = this.$loading({
          lock: true,
          text: "数据保存中！",
        });

        let url = PRINT_API + "/project/backup/setting/save";
        this.$http.post(url, this.backupSettingForm).then(
          () => {
            curLoading.close();
            this.$notify({
              title: "提示",
              type: "success",
              message: "保存备份设置成功！",
            });
            this.backupSettingDialog = false;
          },
          (error) => {
            console.log(error.response);
            curLoading.close();
            this.$notify({
              title: "错误",
              type: "error",
              message: error.response.data.desc,
            });
          }
        );
      });
    },
    openBackupSettingDialog() {
      /* 打开备份设置弹窗 */
      let url = PRINT_API + "/project/backup/setting/" + this.projectId;
      this.$http.post(url).then(
        (response) => {
          this.backupSettingForm = response.data;
        },
        () => {
          this.backupSettingForm = {
            projectId: this.projectId,
            needAlonePkg: true,
            needEachPkg: true,
            eachPkgPercent: "",
            eachPkgMax: "",
            eachPkgMin: "",
            alonePkgPercent: "",
            alonePkgMax: "",
            alonePkgMin: "",
            groupType: null,
          };
        }
      );
      this.backupSettingDialog = true;
    },
    closeBackupSettingDialog() {
      /* 关闭备份设置弹窗 */
      this.backupSettingDialog = false;
    },
    loadOtherSetting(projectId) {
      /* 查询其它事项列表 */
      let url =
        PRINT_API + "/project/other/setting/list?projectId=" + projectId;

      this.$http.post(url).then(
        (response) => {
          let list = response.data;
          if (!list || list.length < 1) {
            return;
          }
          for (let obj of list) {
            obj.type = "其它";
            obj.btnType = "OTHER";
            this.tableData.push(obj);
          }
        },
        (error) => {
          console.log(error.response);
          // ignore
        }
      );
    },
    editOtherSetting() {
      /* 保存其它事项 */
      this.$refs.otherSettingForm.validate((valid) => {
        if (!valid) {
          return false;
        }

        let curLoading = this.$loading({
          lock: true,
          text: "数据保存中！",
        });

        let url = PRINT_API + "/project/other/setting/save";
        this.$http.post(url, this.otherSettingForm).then(
          () => {
            curLoading.close();
            this.$notify({
              title: "提示",
              type: "success",
              message: "保存其它事项成功！",
            });
            this.otherSettingDialog = false;
            this.searchRecords();
          },
          (error) => {
            console.log(error.response);
            curLoading.close();
            this.$notify({
              title: "错误",
              type: "error",
              message: error.response.data.desc,
            });
          }
        );
      });
    },
    removeOtherSetting(row) {
      /* 删除某个其它事项 */
      this.$confirm("确定删除当前事项吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let url = PRINT_API + "/project/other/setting/delete/" + row.id;
          this.$http.post(url).then(
            () => {
              this.$notify({
                title: "提示",
                type: "success",
                message: "删除当前事项成功！",
              });
              this.searchRecords();
            },
            (error) => {
              console.log(error.response);
              this.$notify({
                title: "错误",
                type: "error",
                message: error.response.data.desc,
              });
            }
          );
        })
        .catch(() => {
          /*ignore*/
        });
    },
    openOtherSettingDialog(row) {
      /* 打开其它事项弹窗 */
      if (checkEmptyNumber(this.projectId)) {
        this.$notify({
          title: "提示",
          message: "请选择学校和考试！",
          type: "warning",
        });
        return;
      }
      this.otherSettingDialog = true;
      this.otherSettingForm.projectId = this.projectId;
      if (row.id) {
        this.otherSettingForm.id = row.id;
      } else {
        this.otherSettingForm.id = "";
      }
      if (row.remark) {
        this.otherSettingForm.remark = row.remark;
      } else {
        this.otherSettingForm.remark = "";
      }
    },
    closeOtherSettingDialog() {
      /* 关闭其它事项弹窗 */
      this.otherSettingDialog = false;
    },
    goBack() {
      /* 返回上级界面 */
      this.$router.push({ path: "/print/project/list" });
    },
    checkEmptyNumber: checkEmptyNumber,
  },
};
</script>

<style scoped>
.page {
  margin-top: 10px;
}
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
.w200 {
  width: 200px;
}
.w300 {
  width: 300px;
}
</style>
