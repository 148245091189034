<template>
  <section style="margin-top: 0px">
    <div>
      <div style="width: 643px; padding: 10px 0px">
        <el-alert title="算法代号" type="success" show-icon :closable="false">
          <el-breadcrumb separator="|" style="font-size: 9px">
            <el-breadcrumb-item>AES 简写 A</el-breadcrumb-item>
            <el-breadcrumb-item>Base64 简写 B</el-breadcrumb-item>
            <el-breadcrumb-item>RC4 简写 C</el-breadcrumb-item>
            <el-breadcrumb-item>DES 简写 D</el-breadcrumb-item>
            <el-breadcrumb-item>TripleDES 简写 E</el-breadcrumb-item>
          </el-breadcrumb>
        </el-alert>
      </div>

      <el-transfer
        v-model="enableCryptoConfigs"
        :data="allCryptoConfigs"
        :titles="['禁用组合列表', '启用组合列表']"
        :button-texts="['禁用', '启用']"
        :format="{
          noChecked: '${total}',
          hasChecked: '${checked}/${total}',
        }"
        @change="handleChange"
      >
        <el-button
          slot="right-footer"
          class="transfer-footer"
          type="primary"
          size="small"
          :disabled="!canEdit"
          round
          plain
          @click="updateCryptoConfigs()"
          >保存</el-button
        >
      </el-transfer>

      <div style="width: 643px; margin: 50px 0px 20px 0px">
        <el-alert
          title="配置被禁用旧版本API的学校ID列表"
          type="warning"
          show-icon
          :closable="false"
        >
          注：多个值请逗号分隔！
          <el-button
            class="el-alert__closebtn"
            type="warning"
            size="small"
            round
            plain
            @click="updateDisabledRootOrgIds()"
          >
            保存</el-button
          >
        </el-alert>

        <el-input
          v-model="disabledRootOrgIds"
          style="margin-top: 5px"
          type="textarea"
          placeholder="示例：111,222,333"
          maxlength="500"
          rows="3"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import { CORE_API } from "@/constants/constants.js";

export default {
  data() {
    return {
      loading: false,
      canEdit: false,
      allCryptoConfigs: [],
      enableCryptoConfigs: [],
      disabledRootOrgIds: null,
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
    isSuperAdmin() {
      return this.user.roleList.some((role) => role.roleCode === "SUPER_ADMIN");
    },
  },
  created() {
    if (this.isSuperAdmin) {
      this.loadCryptoConfigs();
      this.loadDisabledRootOrgIds();
    }
  },
  methods: {
    handleChange() {
      this.canEdit = true;
    },
    loadCryptoConfigs() {
      this.loading = true;
      let url = CORE_API + "/crypto/config/list";

      this.$http.post(url).then(
        (response) => {
          let data = response.data;

          for (let n = 0; n < data.length; n++) {
            let obj = data[n];
            this.allCryptoConfigs.push({
              key: obj.combination,
              label: obj.combination,
              disabled: false,
            });

            if (obj.enable) {
              this.enableCryptoConfigs.push(obj.combination);
            }
          }

          this.loading = false;
        },
        () => {
          this.$notify({
            type: "error",
            message: "获取组合配置失败！",
          });
          this.loading = false;
        }
      );
    },
    updateCryptoConfigs() {
      let params = [];
      for (let n = 0; n < this.allCryptoConfigs.length; n++) {
        let obj = this.allCryptoConfigs[n];

        let enable = false;
        if (this.enableCryptoConfigs.indexOf(obj.key) > -1) {
          enable = true;
        }

        params.push({ combination: obj.key, enable: enable });
      }

      if (params.length === 0) {
        return;
      }

      this.$confirm("确认保存？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.canEdit = false;
        let url = CORE_API + "/crypto/config/update";

        this.$http.post(url, params).then(
          () => {
            this.$notify({
              type: "success",
              message: "保存成功！",
            });
          },
          () => {
            this.$notify({
              type: "error",
              message: "保存失败！",
            });
          }
        );
      });
    },
    loadDisabledRootOrgIds() {
      let url = "/api/ecs_oe_student/temp/config/list";
      this.$http.post(url).then(
        (response) => {
          let data = response.data;
          this.disabledRootOrgIds = data.join(",");
        },
        () => {
          this.$notify({
            type: "error",
            message: "获取被禁用旧版本API的学校ID列表失败！",
          });
        }
      );
    },
    updateDisabledRootOrgIds() {
      let params = [];
      if (this.disabledRootOrgIds) {
        let values = this.disabledRootOrgIds.split(",");
        // console.log("values", values);
        let reg = /^[0-9]+$/;
        for (let n = 0; n < values.length; n++) {
          if (reg.test(values[n])) {
            params.push(values[n]);
          }
        }
      }

      this.$confirm("确认保存？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let url = "/api/ecs_oe_student/temp/config/update";
        this.$http.post(url, params).then(
          () => {
            this.$notify({
              type: "success",
              message: "保存成功！",
            });
            this.disabledRootOrgIds = params.join(",");
          },
          () => {
            this.$notify({
              type: "error",
              message: "保存失败！",
            });
          }
        );
      });
    },
  },
};
</script>

<style scoped>
.transfer-footer {
  margin: 4px 13px 0px 0px;
  float: right;
}
</style>

<style>
.el-transfer-panel {
  height: 390px;
}
.el-transfer-panel__list {
  min-height: 310px;
  height: 310px;
}
</style>
