<template>
  <transition
    name="fade"
    mode="out-in"
    appear
    :duration="{ enter: 500, leave: 300 }"
  >
    <div v-if="unreadMessage" :key="unreadMessage.id" class="popup">
      <h5
        style="
          padding: 5px;
          background: #3c8dbd;
          border-radius: 6px 6px 0px 0px;
          font-size: 14px;
          font-weight: 500;
          color: rgba(255, 255, 255, 1);
          line-height: 20px;
        "
      >
        {{ unreadMessage.title }}
      </h5>

      <p
        style="
          text-overflow: ellipsis;
          text-indent: 2em;
          text-align: left;
          height: 100px;
          overflow: hidden;
          margin: 20px;
        "
      >
        {{ unreadMessageContent }}
      </p>
      <div style="text-align: left; margin-left: 20px; margin-bottom: 10px">
        <span
          style="
            display: inline-block;
            margin-left: 20px;
            cursor: pointer;
            color: #2d8cf0;
          "
          @click="toDetail"
          >详情</span
        >
        <span
          style="
            display: inline-block;
            margin-left: 20px;
            cursor: pointer;
            color: #777;
          "
          @click="ignoreMessage(null)"
          >忽略</span
        >
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "SiteMessagePopup",
  data() {
    let ignoreMessageIds = window.sessionStorage.getItem("ignoreMessageIds");
    if (ignoreMessageIds) {
      ignoreMessageIds = JSON.parse(ignoreMessageIds);
    } else {
      ignoreMessageIds = [];
    }
    return {
      siteMessages: [],
      ignoreMessageIds,
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
    unreadMessage() {
      const unreadMessages = this.siteMessages
        .filter((v) => v.hasRead === false)
        .filter(
          (v) =>
            this.ignoreMessageIds.includes(v.id + "-" + this.user.id) === false
        )
        .reverse();
      const unreads = this.siteMessages.filter((v) => v.hasRead === false);
      this.$emit("changeUnreadMessageCount", unreads.length);
      if (unreadMessages.length > 0) {
        return unreadMessages[0];
      } else {
        return null;
      }
    },
    unreadMessageContent() {
      const div = document.createElement("div");
      div.innerHTML = this.unreadMessage.content;
      const text = div.textContent || div.innerText || "";
      return text.slice(0, 90) + (text.length > 90 ? "..." : "");
    },
  },
  created() {
    this.$eventHub.$on("UnreadNoticeChange", () => this.onUnreadNoticeChange());
    this.getUnreadNoticeList();
  },
  beforeDestroy() {
    this.$eventHub.$off("UnreadNoticeChange");
  },
  methods: {
    toDetail() {
      let unid = this.unreadMessage.id;
      this.ignoreMessage(unid);
      this.$router.push({
        path: "/home/site-message/" + unid,
      });
    },
    ignoreMessage(id) {
      let unid = this.unreadMessage.id;
      if (id) {
        unid = id;
      }
      this.ignoreMessageIds.push(unid + "-" + this.user.id);
      window.sessionStorage.setItem(
        "ignoreMessageIds",
        JSON.stringify(this.ignoreMessageIds)
      );
    },
    async getUnreadNoticeList() {
      try {
        this.$httpWithoutBar
          .get("/api/ecs_exam_work/notice/getUserNoticeList?hasRead=false")
          .then((response) => {
            for (let notice of response.data) {
              if (notice.hasRecalled === true) {
                notice.title = "发送者已撤回消息：" + notice.title;
                notice.content = "该消息已被发送者撤回。";
              }
            }
            this.siteMessages = response.data;
            setTimeout(() => {
              this.getUnreadNoticeList();
            }, 60000);
          });
      } catch (error) {
        console.log("tag", error);
      }
    },
    async onUnreadNoticeChange() {
      this.$httpWithoutBar
        .get("/api/ecs_exam_work/notice/getUserNoticeList?hasRead=false")
        .then((response) => {
          for (let notice of response.data) {
            if (notice.hasRecalled === true) {
              notice.title = "发送者已撤回消息：" + notice.title;
              notice.content = "该消息已被发送者撤回。";
            }
          }
          this.siteMessages = response.data;
        });
    },
  },
};
</script>

<style scoped>
.popup {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 320px;
  height: 200px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  border: 1px solid rgba(221, 221, 221, 1);
  z-index: 99;
}
</style>
