<template>
  <section class="content" style="margin-top: 20px">
    <div class="box box-info">
      <!-- 正文信息 -->
      <div class="box-body">
        <el-form
          :model="formSearch"
          :inline="true"
          label-position="right"
          class="el-form el-form--inline"
          style="
            border-bottom: 1px solid rgb(221, 221, 221);
            padding-bottom: 10px;
            margin-bottom: 10px;
          "
        >
          <el-form-item label="学校">
            <el-select
              v-model="formSearch.orgId"
              placeholder="请选择"
              @change="loadExamList(formSearch.orgId)"
            >
              <el-option
                v-for="item in orgList"
                :key="item.orgId"
                :label="item.orgName"
                :value="item.orgId"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="考试">
            <el-select
              v-model="formSearch.examId"
              placeholder="请选择"
              @change="searchRecords"
            >
              <el-option
                v-for="item in examList"
                :key="item.examId"
                :label="item.examName"
                :value="item.examId"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button
              size="small"
              type="primary"
              icon="el-icon-search"
              @click="searchRecords"
              >查询
            </el-button>
          </el-form-item>
        </el-form>

        <!-- 数据列表 -->
        <el-table
          v-loading="loading"
          :data="tableData"
          element-loading-text="数据加载中"
          style="width: 100%"
          border
          stripe
        >
          <el-table-column label="序号" type="index" width="50px">
          </el-table-column>
          <el-table-column label="类别" prop="typeName" />
          <el-table-column label="上传">
            <template slot-scope="scope">
              <el-upload
                :action="uploadAction"
                :headers="uploadHeaders"
                :file-list="uploadFileList"
                :disabled="!hasPermit"
                :before-upload="(file) => beforeUpload(file, scope.row)"
                :on-success="
                  (response, file, fileList) =>
                    uploadSuccess(response, file, fileList, scope.row)
                "
                :on-error="uploadError"
                :show-file-list="false"
                :auto-upload="true"
                :multiple="false"
              >
                <el-button
                  size="mini"
                  type="primary"
                  icon="el-icon-upload"
                  :disabled="!hasPermit"
                  plain
                  >上传</el-button
                >
                <span
                  v-show="
                    scope.row.templateType == 1 || scope.row.templateType == 2
                  "
                  class="el-upload__tip"
                  style="margin-left: 10px"
                  >请上传xls/xlsx文件，且不超过50M</span
                >
                <span
                  v-show="scope.row.templateType == 8"
                  class="el-upload__tip"
                  style="margin-left: 10px"
                  >请上传jpg/png文件，且不超过50M</span
                >
                <span
                  v-show="
                    scope.row.templateType != 1 &&
                    scope.row.templateType != 2 &&
                    scope.row.templateType != 8
                  "
                  class="el-upload__tip"
                  style="margin-left: 10px"
                  >请上传pdf文件，且不超过50M</span
                >
              </el-upload>
            </template>
          </el-table-column>
          <el-table-column label="下载" width="100px">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="primary"
                icon="el-icon-download"
                :disabled="checkEmptyStr(scope.row.fileName)"
                plain
                @click="download(scope.row)"
                >下载
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </section>
</template>

<script>
import { PRINT_API } from "@/constants/constants";
import {} from "../constants/constants.js";
import { mapState } from "vuex";
import { checkEmptyStr, checkEmptyNumber } from "../utils/common.js";

export default {
  data() {
    return {
      formSearch: {
        orgId: "",
        examId: "",
      },
      curUserRole: {},
      hasPermit: false,
      loading: false,
      tableData: [],
      orgList: [],
      examList: [],
      templateForm: {
        orgId: "",
        examId: "",
        templateType: "",
        fileUrl: "",
      },
      uploadFileList: [],
      uploadAction: PRINT_API + "/common/upload",
      uploadHeaders: {
        key: "",
        token: "",
      },
      rules: {},
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
  },
  created() {
    this.loadOrgList();

    this.loadUserRole(this.user);

    if (this.curUserRole.isSuperLeader || this.curUserRole.isPM) {
      this.hasPermit = true;
    } else {
      this.hasPermit = false;
    }

    this.uploadHeaders = {
      key: this.user.key,
      token: this.user.token,
    };
  },
  methods: {
    searchRecords() {
      /* 查询记录列表 */
      let orgId = this.formSearch.orgId;
      if (checkEmptyNumber(orgId)) {
        this.$notify({
          title: "提示",
          message: "请选择学校！",
          type: "warning",
        });
        return;
      }

      let examId = this.formSearch.examId;
      if (checkEmptyNumber(examId)) {
        this.$notify({
          title: "提示",
          message: "请选择考试！",
          type: "warning",
        });
        return;
      }

      this.loading = true;
      let url = PRINT_API + "/project/template/" + orgId + "/" + examId;
      this.$http.post(url, this.formSearch).then(
        (response) => {
          this.tableData = response.data;
          this.loading = false;
        },
        (error) => {
          console.log(error);
          this.loading = false;
        }
      );
    },
    selectDefault() {
      if (this.orgList.length > 0) {
        let firstOrgId = this.orgList[0].orgId;
        this.formSearch.orgId = firstOrgId;
        this.loadExamList(firstOrgId);
      }
    },
    loadOrgList() {
      /* 查询学校列表 */
      let url = PRINT_API + "/printing/project/org/list";
      this.$http.post(url).then(
        (response) => {
          this.orgList = response.data;
          this.selectDefault();
        },
        (error) => {
          console.log(error.response);
          // ignore
        }
      );
    },
    loadExamList(orgId) {
      /* 查询考试列表 */
      this.formSearch.examId = "";
      this.examList = [];
      this.tableData = [];

      if (checkEmptyNumber(orgId)) {
        return;
      }
      let url = PRINT_API + "/printing/project/exam/list?orgId=" + orgId;
      this.$http.post(url).then((response) => {
        this.examList = response.data;
        if (this.examList.length > 0) {
          this.formSearch.examId = this.examList[0].examId;
          this.searchRecords();
        }
      });
    },
    beforeUpload(file, row) {
      const isLimit = file.size / 1024 / 1024 < 50;
      if (!isLimit) {
        this.$notify({
          title: "提示",
          message: "上传文件大小不能超过50MB！",
          type: "warning",
        });
        return false;
      }

      let index = file.name.lastIndexOf(".");
      let ext = file.name.substring(index).toLowerCase();
      console.log("templateType:" + row.templateType + " ext:" + ext);

      if (row.templateType == 1 || row.templateType == 2) {
        /* 考生数据表(1)、考场数据表(2) */
        if (!new RegExp("(.xls|.xlsx)$").test(ext)) {
          this.$notify({
            title: "提示",
            message: "请选择后缀为.xls或.xlsx的文件！",
            type: "warning",
          });
          return false;
        }
      } else if (row.templateType == 8) {
        /* 试卷袋样式(8) */
        if (!new RegExp("(.jpg|.png)$").test(ext)) {
          this.$notify({
            title: "提示",
            message: "请选择后缀为.jpg或.png的文件！",
            type: "warning",
          });
          return false;
        }
      } else {
        /* 卷袋贴模板(3)、签到表模板(4)、常规题卡模板(5)、特殊题卡模板(6)、备份卷贴模板(7) */
        if (!new RegExp("(.pdf)$").test(ext)) {
          this.$notify({
            title: "提示",
            message: "请选择后缀为.pdf的文件！",
            type: "warning",
          });
          return false;
        }
      }
      return true;
    },
    uploadSuccess(response, file, fileList, row) {
      /* 上传模板 */
      if (response.code == "PRT-000200") {
        this.templateForm.orgId = this.formSearch.orgId;
        this.templateForm.examId = this.formSearch.examId;
        this.templateForm.templateType = row.templateType;
        this.templateForm.fileUrl = response.data;
        this.$http
          .post(PRINT_API + "/project/template/save", this.templateForm)
          .then(
            () => {
              this.$notify({
                title: "提示",
                message: "上传模板成功！",
                type: "success",
              });
              this.searchRecords();
            },
            (error) => {
              console.log(error.response);
              this.$notify({
                title: "错误",
                type: "error",
                message: error.response.data.desc,
              });
            }
          );
      }
    },
    uploadError(response) {
      console.log(response);
      this.$notify({
        title: "错误",
        message: "上传文件失败！",
        type: "error",
      });
    },
    preview(row) {
      /* 预览模板 */
      let url = row.fileUrl;
      if (!url) {
        this.$notify({
          title: "提示",
          message: "当前模板不存在！",
          type: "warning",
        });
        return;
      }
      window.open(url);
    },
    download(row) {
      /* 下载模板 */
      let filePath = row.fileName;
      if (!filePath) {
        this.$notify({
          title: "提示",
          message: "当前模板不存在！",
          type: "warning",
        });
        return;
      }

      this.$confirm("确定下载？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let url = PRINT_API + "/common/download?filePath=" + filePath;
          window.location.href = url;
        })
        .catch(() => {
          /*ignore*/
        });
    },
    checkEmptyStr: checkEmptyStr,
  },
};
</script>

<style scoped>
.page {
  margin-top: 10px;
}
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
.w220 {
  width: 220px;
}
</style>
