<template>
  <section class="content">
    <div class="box box-info">
      <div
        v-loading.body="loading"
        v-loading.fullscreen="loading"
        class="box-body"
        element-loading-text="请稍后..."
      >
        <!-- 表单 -->
        <el-form inline :model="formSearch">
          <el-form-item label="项目名称">
            <el-input
              v-model="formSearch.name"
              :readonly="true"
              style="width: 180px"
            />
          </el-form-item>
          <el-form-item label="分析类型">
            <el-input
              v-model="formSearch.analyseTypeName"
              :readonly="true"
              style="width: 180px"
            />
          </el-form-item>
          <el-form-item>
            <el-button
              size="small"
              type="primary"
              icon="el-icon-arrow-left"
              @click="back"
            >
              返回
            </el-button>
          </el-form-item>
        </el-form>

        <div class="block-seperator"></div>
        <el-tabs type="border-card">
          <el-tab-pane label="学习中心分析">
            <exam-org-report></exam-org-report>
          </el-tab-pane>
          <el-tab-pane label="考试课程分析">
            <exam-course-report></exam-course-report>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </section>
</template>
<script>
import ExamOrgReport from "../views/ExamOrgReport.vue";
import ExamCourseReport from "../views/ExamCourseReport.vue";
import { REPORTS_API } from "@/constants/constants.js";
import { mapState } from "vuex";
export default {
  name: "ReportView",
  components: {
    "exam-org-report": ExamOrgReport,
    "exam-course-report": ExamCourseReport,
  },
  data() {
    return {
      loading: false,
      formSearch: {
        name: "",
        analyseTypeName: "",
        projectId: "",
        partitionCount: 0,
      },
      // 考试学习中心
      examOrgMainListShow: {
        a: true,
        b: true,
        c: true,
        d: true,
      },
      examOrgData: {
        examOrgMainList: [],
        examOrgMainListCol: [],
        examOrgMainTopTen: {},
        participantDescBar: this.getBar("实考人数前十", "实考人数"),
        participantRatioDescBar: this.getBar("实考比例前十", "实考比例"),
        missDescBar: this.getBar("缺考人数前十", "缺考人数"),
        missRatioDescBar: this.getBar("缺考比例前十", "缺考比例"),
        passDescBar: this.getBar("及格人数前十", "及格人数"),
        passParticipantRatioDescBar: this.getBar(
          "及格实考比例前十",
          "及格实考比例"
        ),
        partitionCountDescBar: [],
        partitionParticipantRatioDescBar: [],
      },
      //考试课程
      examCourseMainListShow: {
        a: true,
        b: true,
        c: true,
        d: true,
        e: true,
        f: true,
        g: true,
      },
      examCourseData: {
        examCourseMainList: [],
        examCourseMainListCol: [],
        examCourseMainTopTen: {},
        participantDescBar: this.getBar("实考人数前十", "实考人数"),
        participantRatioDescBar: this.getBar("实考比例前十", "实考比例"),
        missDescBar: this.getBar("缺考人数前十", "缺考人数"),
        missRatioDescBar: this.getBar("缺考比例前十", "缺考比例"),
        passDescBar: this.getBar("及格人数前十", "及格人数"),
        passParticipantRatioDescBar: this.getBar(
          "及格实考比例前十",
          "及格实考比例"
        ),
        partitionCountDescBar: [],
        partitionParticipantRatioDescBar: [],
        difficultyDegreeDescBar: this.getBar(
          "调卷平均难度前十",
          "调卷平均难度"
        ),
        stdDescBar: this.getBar("标准差前十", "标准差"),
        cdiDescBar: this.getBar("差异系数前十", "差异系数"),
      },
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
    isSuperAdmin() {
      return this.user.roleList.some((role) => role.roleCode == "SUPER_ADMIN");
    },
  },
  //初始化查询
  created() {
    this.formSearch.projectId = this.$route.params.projectId;
    this.init();
  },
  methods: {
    exportExamCourse() {
      window.open(
        REPORTS_API +
          "/examCourseDataReport/export?$key=" +
          this.user.key +
          "&$token=" +
          this.user.token +
          "&projectId=" +
          this.formSearch.projectId
      );
    },
    exportExamOrg() {
      window.open(
        REPORTS_API +
          "/examOrgReport/export?$key=" +
          this.user.key +
          "&$token=" +
          this.user.token +
          "&projectId=" +
          this.formSearch.projectId
      );
    },
    setBarData(bar, seriesName, data, fieldName, xfieldName) {
      bar.series = [
        {
          name: seriesName,
          type: "bar",
          barWidth: "20",
          data: this.getYdata(data, fieldName),
        },
      ];
      bar.xAxis = [
        {
          type: "category",
          data: this.getXdata(data, xfieldName),
          axisTick: {
            alignWithLabel: true,
          },
          axisLabel: {
            interval: 0,
            rotate: 20,
          },
        },
      ];
    },
    getBar(text, seriesName) {
      return {
        color: ["#3398DB"],
        title: {
          text: text,
          left: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c}",
        },
        xAxis: [
          {
            type: "category",
            axisTick: {
              alignWithLabel: true,
            },
            axisLabel: {
              interval: 0,
              rotate: 30,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: seriesName,
            type: "bar",
          },
        ],
      };
    },
    getXdata(data, xfieldName) {
      let xdata = [];
      data.forEach((e) => {
        xdata.push(e.examName + "-" + e[xfieldName]);
      });
      return xdata;
    },
    getYdata(data, col) {
      let ydata = [];
      data.forEach((e) => {
        ydata.push(e[col]);
      });
      return ydata;
    },
    setViewCols(partitionData, cols) {
      for (var i = 0; i < this.formSearch.partitionCount; i++) {
        let coldata1 = {};
        coldata1.label = "分段" + (i + 1) + "人数";
        coldata1.key = "partitionCount" + i;
        cols.push(coldata1);
        let coldata2 = {};
        coldata2.label = "分段" + (i + 1) + "人数实考占比（%）";
        coldata2.key = "participantRatio" + i;
        cols.push(coldata2);
      }
      partitionData.forEach((e) => {
        for (var i = 0; i < e.partitionData.length; i++) {
          let key1 = "partitionCount" + i;
          e[key1] = e.partitionData[i].count;
          let key2 = "participantRatio" + i;
          e[key2] = e.partitionData[i].participantRatio;
        }
      });
    },
    back() {
      this.$router.push({
        path: "/reports/overview",
      });
    },
    //查询
    getExamOrgMainList() {
      var url =
        REPORTS_API +
        "/examOrgReport/getExamOrgMainList?projectId=" +
        this.formSearch.projectId;
      this.$httpWithMsg.get(url).then((response) => {
        this.examOrgData.examOrgMainList = response.data;
        this.setViewCols(
          this.examOrgData.examOrgMainList,
          this.examOrgData.examOrgMainListCol
        );
      });
    },
    getExamCourseMainList() {
      var url =
        REPORTS_API +
        "/examCourseDataReport/getExamCourseMainList?projectId=" +
        this.formSearch.projectId;
      this.$httpWithMsg.get(url).then((response) => {
        this.examCourseData.examCourseMainList = response.data;
        this.setViewCols(
          this.examCourseData.examCourseMainList,
          this.examCourseData.examCourseMainListCol
        );
      });
    },
    getExamOrgMainTopTen() {
      var url =
        REPORTS_API +
        "/examOrgReport/getExamOrgMainTop10?projectId=" +
        this.formSearch.projectId;
      this.$httpWithMsg.get(url).then((response) => {
        this.examOrgData.examOrgMainTopTen = response.data;
        this.setBarData(
          this.examOrgData.participantDescBar,
          "实考人数",
          this.examOrgData.examOrgMainTopTen.participantDesc,
          "participantCount",
          "orgName"
        );
        this.setBarData(
          this.examOrgData.participantRatioDescBar,
          "实考比例",
          this.examOrgData.examOrgMainTopTen.participantRatioDesc,
          "participantRatio",
          "orgName"
        );
        this.setBarData(
          this.examOrgData.missDescBar,
          "缺考人数",
          this.examOrgData.examOrgMainTopTen.missDesc,
          "missCount",
          "orgName"
        );
        this.setBarData(
          this.examOrgData.missRatioDescBar,
          "缺考比例",
          this.examOrgData.examOrgMainTopTen.missRatioDesc,
          "missRatio",
          "orgName"
        );
        this.setBarData(
          this.examOrgData.passDescBar,
          "及格人数",
          this.examOrgData.examOrgMainTopTen.passDesc,
          "passCount",
          "orgName"
        );
        this.setBarData(
          this.examOrgData.passParticipantRatioDescBar,
          "及格实考比例",
          this.examOrgData.examOrgMainTopTen.passParticipantRatioDesc,
          "passParticipantRatio",
          "orgName"
        );
        for (let i = 0; i < this.formSearch.partitionCount; i++) {
          this.setBarData(
            this.examOrgData.partitionCountDescBar[i],
            "分段" + (i + 1) + "人数",
            this.examOrgData.examOrgMainTopTen.partition[i].countDesc,
            "count",
            "orgName"
          );
          this.setBarData(
            this.examOrgData.partitionParticipantRatioDescBar[i],
            "分段" + (i + 1) + "实考比例",
            this.examOrgData.examOrgMainTopTen.partition[i]
              .participantRatioDesc,
            "participantRatio",
            "orgName"
          );
        }
      });
    },
    getExamCourseMainTopTen() {
      var url =
        REPORTS_API +
        "/examCourseDataReport/getExamCourseMainTop10?projectId=" +
        this.formSearch.projectId;
      this.$httpWithMsg.get(url).then((response) => {
        this.examCourseData.examCourseMainTopTen = response.data;

        this.setBarData(
          this.examCourseData.difficultyDegreeDescBar,
          "调卷平均难度",
          this.examCourseData.examCourseMainTopTen.difficultyDegreeDesc,
          "avgDifficultyDegree",
          "courseName"
        );
        this.setBarData(
          this.examCourseData.stdDescBar,
          "标准差",
          this.examCourseData.examCourseMainTopTen.stdDesc,
          "std",
          "courseName"
        );
        this.setBarData(
          this.examCourseData.cdiDescBar,
          "差异系数",
          this.examCourseData.examCourseMainTopTen.cdiDesc,
          "cdi",
          "courseName"
        );
        this.setBarData(
          this.examCourseData.participantDescBar,
          "实考人数",
          this.examCourseData.examCourseMainTopTen.participantDesc,
          "participantCount",
          "courseName"
        );
        this.setBarData(
          this.examCourseData.participantRatioDescBar,
          "实考比例",
          this.examCourseData.examCourseMainTopTen.participantRatioDesc,
          "participantRatio",
          "courseName"
        );
        this.setBarData(
          this.examCourseData.missDescBar,
          "缺考人数",
          this.examCourseData.examCourseMainTopTen.missDesc,
          "missCount",
          "courseName"
        );
        this.setBarData(
          this.examCourseData.missRatioDescBar,
          "缺考比例",
          this.examCourseData.examCourseMainTopTen.missRatioDesc,
          "missRatio",
          "courseName"
        );
        this.setBarData(
          this.examCourseData.passDescBar,
          "及格人数",
          this.examCourseData.examCourseMainTopTen.passDesc,
          "passCount",
          "courseName"
        );
        this.setBarData(
          this.examCourseData.passParticipantRatioDescBar,
          "及格实考比例",
          this.examCourseData.examCourseMainTopTen.passParticipantRatioDesc,
          "passParticipantRatio",
          "courseName"
        );
        for (let i = 0; i < this.formSearch.partitionCount; i++) {
          this.setBarData(
            this.examCourseData.partitionCountDescBar[i],
            "分段" + (i + 1) + "人数",
            this.examCourseData.examCourseMainTopTen.partition[i].countDesc,
            "count",
            "courseName"
          );
          this.setBarData(
            this.examCourseData.partitionParticipantRatioDescBar[i],
            "分段" + (i + 1) + "实考比例",
            this.examCourseData.examCourseMainTopTen.partition[i]
              .participantRatioDesc,
            "participantRatio",
            "courseName"
          );
        }
      });
    },
    async init() {
      var url = REPORTS_API + "/project/" + this.formSearch.projectId;
      await this.$httpWithMsg.get(url).then((response) => {
        this.formSearch.name = response.data.name;
        this.formSearch.analyseTypeName = response.data.analyseTypeName;
        this.formSearch.partitionCount = response.data.partitionCount;
        for (let i = 0; i < this.formSearch.partitionCount; i++) {
          this.examOrgData.partitionCountDescBar[i] = this.getBar(
            "分段" + (i + 1) + "人数前十",
            "分段" + (i + 1) + "人数"
          );
          this.examOrgData.partitionParticipantRatioDescBar[i] = this.getBar(
            "分段" + (i + 1) + "实考比例前十",
            "分段" + (i + 1) + "实考比例"
          );
          this.examCourseData.partitionCountDescBar[i] = this.getBar(
            "分段" + (i + 1) + "人数前十",
            "分段" + (i + 1) + "人数"
          );
          this.examCourseData.partitionParticipantRatioDescBar[i] = this.getBar(
            "分段" + (i + 1) + "实考比例前十",
            "分段" + (i + 1) + "实考比例"
          );
        }
      });
      this.getExamOrgMainList();
      this.getExamOrgMainTopTen();
      this.getExamCourseMainList();
      this.getExamCourseMainTopTen();
    },
  },
};
</script>

<style scoped>
.row-div {
  display: flex;
  overflow-x: auto;
  border: 1px solid #ddd;
  padding: 5px;
  margin-top: 10px;
}
</style>
