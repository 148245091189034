<template>
  <section class="content">
    <div v-show="isClear == 1">
      <LinkTitlesCustom :current-paths="['考试管理', '调卷规则']" />
    </div>
    <!-- 正文信息 -->
    <div class="box-body">
      <el-form
        :inline="true"
        :model="formSearch"
        label-position="right"
        label-width="70px"
      >
        <el-row>
          <el-col :span="6">
            <el-form-item label="考试名称">
              <el-select
                v-model="formSearch.examId"
                class="search_width"
                filterable
                :remote-method="getExams"
                remote
                clearable
                placeholder="请选择"
                size="small"
                @change="getExamCourses"
              >
                <el-option
                  v-for="item in examList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="课程名称">
              <el-select
                v-model="formSearch.courseNo"
                class="search_width"
                filterable
                :remote-method="getCourses"
                remote
                clearable
                placeholder="请选择"
                size="small"
                @focus="(e) => getCourses(e.target.value)"
              >
                <el-option
                  v-for="item in courseInfoSelect"
                  :key="item.courseNo"
                  :label="item.courseInfo"
                  :value="item.courseNo"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <div class="search_down">
              <el-button size="small" type="primary" @click="searchFrom"
                ><i class="el-icon-search"></i> 查询</el-button
              >
              <el-button size="small" type="primary" @click="markRules"
                ><i class="el-icon-plus"></i> 新增</el-button
              >
            </div>
          </el-col>
        </el-row>
        <div
          style="width: 100%; border-bottom: 1px solid #ddd; margin: 10px 0"
        ></div>
        <el-row>
          <el-form-item>
            <span>批量操作：</span>
            <el-button size="small" type="primary" @click="openBatchExpDialog"
              ><i class="el-icon-download"></i> 下载成卷
            </el-button>
          </el-form-item>
        </el-row>
      </el-form>
      <div style="width: 100%; margin-bottom: 10px"></div>
      <!-- 页面列表 -->
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column label="考试名称" width="180">
          <template slot-scope="scope">
            <span>{{ scope.row.examName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="考试类型">
          <template slot-scope="scope">
            <div class="el_table_inner_left">
              <span v-show="scope.row.examType == 'ONLINE'">在线考试</span>
              <span v-show="scope.row.examType == 'TRADITION'">传统考试</span>
              <span v-show="scope.row.examType == 'PRACTICE'">练习考试</span>
              <span v-show="scope.row.examType == 'OFFLINE'">离线考试</span>
              <span v-show="scope.row.examType == 'PRINT_EXAM'"
                >分布式印刷考试</span
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column label="课程名称">
          <template slot-scope="scope">
            <span>{{ scope.row.courseName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="课程代码" width="140">
          <template slot-scope="scope">
            <span>{{ scope.row.courseCode }}</span>
          </template>
        </el-table-column>
        <el-table-column label="调卷类型">
          <template slot-scope="scope">
            <div class="el_table_inner_left">
              <span v-show="scope.row.callType == 'WHOLE_SET'">成套调用</span>
              <span v-show="scope.row.callType == 'RESTRUCT'">重组调用</span>
              <span v-show="scope.row.callType == 'RANDOM_PAPER'"
                >随机抽题</span
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column label="调卷规则">
          <template slot-scope="scope">
            <span v-show="scope.row.id">
              <el-tag type="success">已制定</el-tag>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <div class="operate_left">
              <el-button
                v-if="scope.row.id"
                size="mini"
                type="primary"
                plain
                @click="updateRules(scope.row)"
                ><i class="el-icon-edit"></i>编辑
              </el-button>
              <el-button
                v-if="scope.row.ifFinish == 1"
                size="mini"
                type="primary"
                plain
                @click="openSingleExportPaperDialog(scope.row)"
                ><i class="el-icon-download"></i> 下载
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="page pull-right">
        <el-pagination
          :current-page="currentPage"
          :page-size="pageSize"
          :page-sizes="[10, 20, 50, 100, 200, 300]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog title="导出" :visible.sync="exportDialog">
      <el-form :model="exportModel" label-position="right" label-width="80px">
        <el-row>
          <el-col :md="24">
            <el-form-item label="考试名称">
              <el-select
                v-model="exportModel.examId"
                filterable
                clearable
                :disabled="
                  exportDialog.exportWay == 'SINGLE' &&
                  exportModel.courseCode != ''
                "
                @change="checkPaperStructure"
              >
                <el-option
                  v-for="item in examList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-show="exportModel.courseId">
          <el-col :md="24">
            <el-form-item label="课程名称">
              <el-input v-model="exportModel.courseName" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :md="24">
            <el-form-item
              v-if="exportModel.examType != 'PRINT_EXAM'"
              label="导出内容"
            >
              <el-checkbox-group
                v-if="exportModel.exportType == 'NORMAL'"
                v-model="exportModel.exportContentList"
              >
                <el-checkbox label="PAPER" :disabled="paperDisabled"
                  >试卷</el-checkbox
                >
                <el-checkbox label="ANSWER" :disabled="answerDisabled"
                  >答案</el-checkbox
                >
                <el-checkbox
                  label="PAPER_STRUCTURE_OBJECTIVE,PAPER_STRUCTURE_SUBJECTIVE"
                  :disabled="structureDisabled"
                  >试卷结构
                </el-checkbox>
              </el-checkbox-group>
              <el-checkbox-group
                v-if="exportModel.exportType == 'ONLINE'"
                v-model="exportModel.exportContentList"
              >
                <el-checkbox
                  label="COMPUTERTEST_PACKAGE"
                  :disabled="onlineDisabled"
                  >机考数据包
                </el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item
              v-if="exportModel.examType == 'PRINT_EXAM'"
              label="导出内容"
            >
              <el-checkbox-group v-model="exportModel.exportContentList">
                <el-checkbox label="PAPER" :disabled="paperDisabled"
                  >试卷</el-checkbox
                >
                <el-checkbox label="ANSWER" :disabled="answerDisabled"
                  >答案</el-checkbox
                >
                <el-checkbox
                  v-if="isShowPrintExamPackage"
                  label="PRINT_EXAM_PACKAGE"
                  >分布式印刷数据包
                </el-checkbox>
                <el-input
                  v-if="isShowPrintExamPackagePassword"
                  v-model="printExamPackagePassword"
                  placeholder="加密密码 (可选)"
                  type="password"
                  style="width: 150px"
                ></el-input>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :md="24" class="text-center">
            <el-button type="primary" @click="exportPaperInfo"
              >开始导出</el-button
            >
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </section>
</template>

<script>
import { CORE_API, EXAM_WORK_API, QUESTION_API } from "@/constants/constants";
import { LEVEL_TYPE } from "../constants/constants";
import { mapState } from "vuex";
import _ from "lodash";
import LinkTitlesCustom from "@/components/LinkTitlesCustom.vue";
export default {
  components: { LinkTitlesCustom },
  data() {
    return {
      isClear: 0,
      formSearch: {
        examId: "",
        courseNo: "",
        courseName: "",
      },
      tableData: [],
      currentPage: 1,
      levelList: LEVEL_TYPE,
      pageSize: 10,
      total: 0,
      examList: [], //考试list
      courseList: [], //课程list
      loading: false,
      paperModels: "",
      exportDialog: false,
      exportModel: {
        examType: "",
        examId: "",
        courseId: "",
        courseName: "",
        exportWay: "",
        exportType: "NORMAL",
        exportContentList: [],
      },
      paperDisabled: true,
      answerDisabled: true,
      structureDisabled: true,
      onlineDisabled: true,
      courseAllList: [],
      isShowPrintExamPackage: false,
      isShowPrintExamPackagePassword: false,
      printExamPackagePassword: "",
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
    courseInfoSelect() {
      var courseList = [];
      for (let course of this.courseList) {
        var courseInfo = course.name + "(" + course.code + ")";
        courseList.push({
          courseNo: course.code,
          courseName: course.name,
          courseInfo: courseInfo,
        });
      }
      return courseList;
    },
  },
  watch: {
    $route: "initVue",
  },
  created() {
    this.initVue();
  },
  methods: {
    //初始化
    initialize(examId) {
      this.examList = [];
      //查询所有考试
      this.$http.get(EXAM_WORK_API + "/exam/" + examId).then((response) => {
        this.examList.push(response.data);
        this.formSearch.examId = parseInt(examId);
        this.searchRulePaper();
      });
    },
    //查询列表
    searchFrom() {
      this.currentPage = 1;
      this.searchRulePaper();
    },
    searchRulePaper() {
      if (!this.formSearch.examId) {
        this.$notify({
          message: "请选择考试",
          type: "warning",
        });
        return false;
      }
      this.loading = true;
      let currentPage = this.currentPage;
      this.currentPage = 1;
      this.$http
        .get(
          QUESTION_API +
            "/findPageExtractConfig/" +
            currentPage +
            "/" +
            this.pageSize +
            "?examId=" +
            this.formSearch.examId +
            "&courseNo=" +
            this.formSearch.courseNo
        )
        .then((response) => {
          this.tableData = response.data.content;
          this.total = response.data.totalElements;
          this.currentPage = Number(currentPage);
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.$notify({
            message: error.response.data.desc,
            type: "error",
          });
          this.tableData = [];
          this.total = 0;
        });
    },
    //分页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.searchRulePaper();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.searchRulePaper();
    },
    //制定调卷规则
    markRules() {
      sessionStorage.setItem("extract_paper_examId", this.formSearch.examId);
      this.$router.push({
        name: "extract_paper_info_add",
      });
    },
    getCourseName(courseNo) {
      for (let course of this.courseList) {
        if (course.code == courseNo) {
          this.formSearch.courseName = course.name;
        }
      }
    },
    //编辑调卷规则
    updateRules(row) {
      this.getCourseName(this.formSearch.courseNo);
      sessionStorage.setItem("extract_paper_examId", row.examId);
      sessionStorage.setItem(
        "extract_paper_courseNo",
        this.formSearch.courseNo
      );
      sessionStorage.setItem(
        "extract_paper_courseName",
        this.formSearch.courseName
      );
      sessionStorage.setItem("extract_paper_currentPage", this.currentPage);
      this.$router.push({
        name: "extract_paper_info_edit",
        params: { extractConfigId: row.id },
        query: { courseId: row.course?.id || "" },
      });
    },
    openSingleExportPaperDialog(row) {
      this.exportModel = {
        examType: row.examType,
        examId: row.examId,
        examName: row.examName,
        courseId: row.courseCode,
        courseName: row.courseName,
        exportType: "NORMAL",
        exportWay: "SINGLE",
        exportContentList: [],
      };
      this.checkPaperStructure();
      this.exportDialog = true;
      /* 分布式印刷数据包设置 */
      //this.exportModel.examType = 'PRINT_EXAM';//todo
      this.isShowPrintExamPackage = true;
      this.isShowPrintExamPackagePassword = false;
      this.printExamPackagePassword = "";
    },
    openBatchExpDialog() {
      this.exportModel = {
        examType: "",
        examId: "",
        examName: "",
        courseId: "",
        courseName: "",
        exportType: "NORMAL",
        exportWay: "BATCH",
        exportContentList: [],
      };
      this.resetDisabled();
      this.exportDialog = true;
      /* 分布式印刷数据包设置 */
      this.isShowPrintExamPackage = false;
      this.isShowPrintExamPackagePassword = false;
      this.printExamPackagePassword = "";
    },
    checkPaperStructure() {
      if (!this.exportModel.examId) {
        return false;
      }
      this.exportModel.exportContentList = [];
      let examFile = {
        examId: this.exportModel.examId,
        courseId: this.exportModel.courseId ? this.exportModel.courseId : null,
      };
      this.resetDisabled();
      this.$http
        .post(QUESTION_API + "/findExamFile", examFile)
        .then((response) => {
          let examFiles = response.data;
          if (examFiles != null && examFiles.length > 0) {
            this.paperDisabled = this.isDisabled(examFiles, "PAPER");
            this.answerDisabled = this.isDisabled(examFiles, "ANSWER");
            this.onlineDisabled = this.isDisabled(
              examFiles,
              "COMPUTERTEST_PACKAGE"
            );

            this.$http
              .get(
                QUESTION_API + "/findExportStructure/" + this.exportModel.examId
              )
              .then((response) => {
                this.exportModel.exportType = response.data
                  ? response.data.exportType
                  : "NORMAL";
                this.structureDisabled = !response.data;
                if (this.exportModel.exportWay == "SINGLE") {
                  this.structureDisabled = this.isDisabled(
                    examFiles,
                    "PAPER_STRUCTURE_OBJECTIVE"
                  );
                }
              });
          } else {
            this.resetDisabled();
          }
        });
    },
    isDisabled(examFiles, fileType) {
      return (
        _.filter(examFiles, function (examFile) {
          return examFile.examFileType == fileType;
        }).length == 0
      );
    },
    resetDisabled() {
      this.paperDisabled = true;
      this.answerDisabled = true;
      this.structureDisabled = true;
      this.onlineDisabled = true;
    },
    exportPaperInfo() {
      let examId = this.exportModel.examId;
      let courseId = this.exportModel.courseId;
      let exportWay = this.exportModel.exportWay;
      if (!examId) {
        this.$notify({
          message: "请选择考试",
          type: "error",
        });
        return false;
      }
      if (this.exportModel.exportContentList.length == 0) {
        this.$notify({
          message: "请选择导出内容",
          type: "error",
        });
        return false;
      }
      if (this.printExamPackagePassword.length > 0) {
        var reg = /^[0-9A-Za-z]{6,12}$/;
        if (!reg.test(this.printExamPackagePassword)) {
          this.$notify({
            message: "加密密码请输入6至12位的数字或字母！",
            type: "error",
          });
          return false;
        }
      }
      let exportContentList = this.exportModel.exportContentList.toString();
      var key = this.user.key;
      var token = this.user.token;
      this.$http
        .get(
          QUESTION_API +
            "/exportBatchExamPaperInfoCheck/" +
            exportWay +
            "/" +
            examId +
            "?$key=" +
            key +
            "&$token=" +
            token
        )
        .then(
          (response) => {
            console.log(response);
            if (response.data) {
              this.$notify({
                message: response.data.desc,
                type: "error",
              });
            } else {
              var key = this.user.key;
              var token = this.user.token;
              if (exportWay == "SINGLE") {
                window.location.href =
                  QUESTION_API +
                  "/exportSingleExamPaperInfo/" +
                  this.user.rootOrgId +
                  "/" +
                  exportWay +
                  "/" +
                  examId +
                  "/" +
                  courseId +
                  "/" +
                  exportContentList +
                  "/" +
                  this.user.displayName +
                  "?psw=" +
                  this.printExamPackagePassword +
                  "&$key=" +
                  key +
                  "&$token=" +
                  token;
              } else if (exportWay == "BATCH") {
                window.location.href =
                  QUESTION_API +
                  "/exportBatchExamPaperInfo/" +
                  this.user.rootOrgId +
                  "/" +
                  exportWay +
                  "/" +
                  examId +
                  "/" +
                  exportContentList +
                  "/" +
                  this.user.displayName +
                  "?$key=" +
                  key +
                  "&$token=" +
                  token;
              }
            }
          },
          (error) => {
            this.$notify({
              message: error.response.data.desc,
              type: "error",
            });
          }
        );
    },
    removeItem() {
      sessionStorage.removeItem("extract_paper_currentPage");
      sessionStorage.removeItem("extract_paper_examId");
      sessionStorage.removeItem("extract_paper_courseNo");
    },
    //查询所有课程
    getCourses(query) {
      query = query.trim();
      this.courseLoading = true;
      this.$http
        .get(CORE_API + "/course/query?name=" + query + "&enable=true")
        .then((response) => {
          this.courseList = response.data;
          console.log("this.courseList:", this.courseList);
          this.courseLoading = false;
        });
    },
    //查询考试
    getExams(query) {
      query = query.trim();
      this.$http
        .get(
          EXAM_WORK_API + "/exam/queryByNameLike?name=" + query + "&enable=true"
        )
        .then((response) => {
          this.examList = response.data;
        });
    },
    //清空课程列表
    getExamCourses() {
      this.formSearch.courseNo = "";
    },
    initVue() {
      this.isClear = this.$route.params.isClear;
      if (this.isClear == 0 || !this.isClear) {
        this.removeItem();
        //查询所有考试
        this.getExams("");
        this.formSearch = {
          examId: "",
          courseNo: "",
          courseName: "",
        };
        this.currentPage = 1;
      } else if (this.isClear == 2) {
        this.formSearch.examId =
          sessionStorage.getItem("extract_config_add_examId") == ""
            ? ""
            : parseInt(sessionStorage.getItem("extract_config_add_examId"));
        if (this.formSearch.examId) {
          this.initialize(this.formSearch.examId);
        }
        this.currentPage = 1;
      } else {
        this.formSearch.courseNo =
          sessionStorage.getItem("extract_paper_courseNo") == "null"
            ? ""
            : sessionStorage.getItem("extract_paper_courseNo");
        this.formSearch.courseName =
          sessionStorage.getItem("extract_paper_courseName") == "null"
            ? ""
            : sessionStorage.getItem("extract_paper_courseName");
        this.formSearch.examId =
          sessionStorage.getItem("extract_paper_examId") == ""
            ? ""
            : parseInt(sessionStorage.getItem("extract_paper_examId"));
        this.currentPage =
          sessionStorage.getItem("extract_paper_currentPage") == null
            ? 1
            : parseInt(sessionStorage.getItem("extract_paper_currentPage"));
        if (this.formSearch.examId) {
          this.initialize(this.formSearch.examId);
        }
        if (this.formSearch.courseName) {
          this.getCourses(this.formSearch.courseName);
        }
      }
    },
  },
};
</script>
<style scoped src="../styles/Common.css"></style>
