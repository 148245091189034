<template>
  <div class="ckeditor" style="margin-bottom: 10px">
    <textarea :id="id" :value="value"></textarea>
  </div>
</template>

<script>
let inc = 0;
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: () => `editor-${++inc}`,
    },
    height: {
      type: String,
      default: "200px",
    },
    width: {
      type: String,
      default: "980px",
    },
    toolbar: {
      type: [String, Array],
      default: null,
    },
    language: {
      type: String,
      default: "zh-cn",
    },
    extraplugins: {
      type: String,
      default: "",
    },
    extrabuttons: {
      type: String,
      default: "",
    },
    readonly: {
      type: Boolean,
    },
  },
  computed: {
    instance() {
      return window.CKEDITOR.instances[this.id];
    },
  },
  beforeUpdate() {
    if (this.value !== this.instance.getData()) {
      this.instance.setData(this.value);
    }
  },
  mounted() {
    var removePluginStr = "bidi,image";
    var removeButtonStr =
      "Table,Styles,Format,ShowBlocks,Iframe,PageBreak,Smiley,Flash,Language,JustifyBlock,JustifyRight,JustifyCenter,JustifyLeft,CreateDiv,CopyFormatting,ImageButton,Button,HiddenField,Select,Textarea,TextField,Radio,Checkbox,Form,BGColor,SelectAll,Replace,Find,Templates,Print,Preview,NewPage,Save,Underline,Subscript,Superscript,HorizontalRule,Unlink,Link,Scayt,Cut,Copy,Paste,PasteText,PasteFromWord,Maximize,Italic,Bold,NumberedList,BulletedList,Indent,Outdent,Blockquote,About,RemoveFormat,Strike";
    if (!this.extrabuttons || this.extrabuttons.indexOf("Font") == -1) {
      removeButtonStr = removeButtonStr + ",Font";
    }
    if (!this.extrabuttons || this.extrabuttons.indexOf("FontSize") == -1) {
      removeButtonStr = removeButtonStr + ",FontSize";
    }
    if (!this.extrabuttons || this.extrabuttons.indexOf("TextColor") == -1) {
      removeButtonStr = removeButtonStr + ",TextColor";
    }

    let config = {
      toolbar: this.toolbar,
      language: this.language,
      height: this.height,
      width: this.width,
      extraPlugins: this.extraplugins + ",image2,base64image,pastebase64",
      removeButtons: removeButtonStr,
      removePlugins: removePluginStr,
    };
    window.CKEDITOR.replace(this.id, config);
    this.instance.on("instanceReady", (ev) => {
      ev.editor.setReadOnly(this.readonly);
    });
    this.instance.on("change", () => {
      let html = this.instance.getData();
      if (html !== this.value) {
        this.$emit("input", html);
      }
    });
  },
  beforeDestroy() {
    if (this.instance) {
      this.instance.focusManager.blur(true);
      this.instance.destroy();
    }
  },
};
</script>
<style>
.ckeditor::after {
  content: "";
  display: table;
  clear: both;
}
.cke_button__image {
  display: none !important;
}
</style>
