<template>
  <section class="content" style="margin-top: 20px">
    <div class="box box-info">
      <!-- 正文信息 -->
      <div class="box-body">
        <el-form
          :model="formSearch"
          :inline="true"
          label-position="right"
          label-width="90px"
          class="el-form el-form--inline"
          style="
            border-bottom: 1px solid rgb(221, 221, 221);
            padding-bottom: 10px;
            margin-bottom: 10px;
          "
        >
          <el-form-item label="学校">
            <el-select
              v-model="formSearch.orgId"
              placeholder="请选择"
              @change="loadExamList(formSearch.orgId)"
            >
              <el-option
                v-for="item in orgList"
                :key="item.orgId"
                :label="item.orgName"
                :value="item.orgId"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="考试">
            <el-select
              v-model="formSearch.examId"
              placeholder="请选择"
              @change="searchRecords(1)"
            >
              <el-option
                v-for="item in examList"
                :key="item.examId"
                :label="item.examName"
                :value="item.examId"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="项目经理">
            <el-select
              v-model="formSearch.pmId"
              placeholder="请选择"
              filterable
              clearable
              remote
              :disabled="curUserRole.isPM"
              :remote-method="loadPmList"
            >
              <el-option label="请选择" value=""></el-option>
              <el-option
                v-for="item in pmList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="印刷供应商">
            <el-select
              v-model="formSearch.supplierId"
              placeholder="请选择"
              filterable
              clearable
              remote
              :disabled="curUserRole.isSupplier"
              :remote-method="loadSupplierList"
            >
              <el-option label="请选择" value=""></el-option>
              <el-option
                v-for="item in supplierList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item style="padding-left: 40px">
            <el-button
              v-show="false"
              size="small"
              icon="el-icon-refresh"
              @click="resetSearchForm"
            >
              重置
            </el-button>

            <el-button
              size="small"
              type="primary"
              icon="el-icon-search"
              @click="searchRecords(1)"
              >查询
            </el-button>
          </el-form-item>
        </el-form>

        <!-- 数据列表 -->
        <el-table
          v-loading="loading"
          :data="tableData"
          element-loading-text="数据加载中"
          style="width: 100%"
          border
          stripe
        >
          <el-table-column
            width="220"
            label="学校名称"
            prop="orgName"
            sortable
          />
          <el-table-column label="考试名称" prop="examName" sortable />
          <el-table-column
            width="200"
            label="印刷供应商"
            prop="supplierName"
            sortable
          />
          <el-table-column
            width="150"
            label="项目经理"
            prop="pmName"
            sortable
          />

          <el-table-column width="120" label="项目统计">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="primary"
                icon="el-icon-view"
                plain
                @click="gotoProjectStatistic(scope.row)"
                >查看
              </el-button>
            </template>
          </el-table-column>

          <el-table-column width="120" label="操作" :context="_self">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="primary"
                icon="el-icon-menu"
                :disabled="!hasPermit"
                plain
                @click="openProjectSettingDialog(scope.row)"
                >项目设置
              </el-button>
            </template>
          </el-table-column>
        </el-table>

        <!-- 分页 -->
        <div v-show="false" class="page pull-right">
          <el-pagination
            :current-page="formSearch.pageNo"
            :page-size="formSearch.pageSize"
            :page-sizes="[10, 20, 50, 100, 200, 300]"
            :total="totalElements"
            layout="total, sizes, prev, pager, next, jumper"
            @current-change="handlePagerNo"
            @size-change="handlePagerSize"
          ></el-pagination>
        </div>
      </div>

      <!-- 项目设置弹窗 -->
      <el-dialog
        title="项目设置"
        width="700px"
        :visible.sync="projectSettingDialog"
        @close="closeProjectSettingDialog"
      >
        <el-form
          ref="settingForm"
          :model="settingForm"
          :rules="rules"
          label-position="right"
          label-width="110px"
          inline-message
        >
          <el-form-item label="学校" prop="orgId">
            <el-select
              v-model="settingForm.orgId"
              placeholder="请选择"
              :disabled="true"
              class="w440"
            >
              <el-option
                v-for="item in orgList"
                :key="item.orgId"
                :label="item.orgName"
                :value="item.orgId"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="考试" prop="examId">
            <el-select
              v-model="settingForm.examId"
              placeholder="请选择"
              :disabled="true"
              class="w440"
            >
              <el-option
                v-for="item in examList"
                :key="item.examId"
                :label="item.examName"
                :value="item.examId"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="项目经理" prop="pmId">
            <el-select
              v-model="settingForm.pmId"
              placeholder="请选择"
              clearable
              class="w440"
              @change="selectPmName"
            >
              <el-option
                v-for="item in pmList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="供应商" prop="supplierId">
            <el-select
              v-model="settingForm.supplierId"
              placeholder="请选择"
              clearable
              class="w440"
              @change="selectSupplierName"
            >
              <el-option
                v-for="item in supplierList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="准备时间" prop="prepareTimeRange">
            <el-date-picker
              v-model="prepareTimeRange"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              :default-time="['00:00:00', '23:59:59']"
            >
              >
            </el-date-picker>
          </el-form-item>

          <el-form-item label="印刷时间" prop="printTimeRange">
            <el-date-picker
              v-model="printTimeRange"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              :default-time="['00:00:00', '23:59:59']"
            >
              >
            </el-date-picker>
          </el-form-item>

          <el-form-item label="邮寄时间" prop="mailTimeRange">
            <el-date-picker
              v-model="mailTimeRange"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              :default-time="['00:00:00', '23:59:59']"
            >
              >
            </el-date-picker>
          </el-form-item>
        </el-form>

        <div style="text-align: center">
          <el-button type="primary" @click="editProjectSetting"
            >确 定
          </el-button>
          <el-button @click="closeProjectSettingDialog">取 消</el-button>
        </div>
      </el-dialog>
    </div>
  </section>
</template>

<script>
import { CORE_API, PRINT_API } from "@/constants/constants";
import {} from "../constants/constants.js";
import { mapState } from "vuex";
import {
  checkEmptyStr,
  checkEmptyNumber,
  checkArray,
  formatGMT,
} from "../utils/common.js";

export default {
  data() {
    let validatePrepareTimeRange = (rule, value, callback) => {
      if (
        !checkArray(this.prepareTimeRange) ||
        this.prepareTimeRange.length == 0
      ) {
        callback(new Error("请选择准备时间！"));
      } else {
        callback();
      }
    };

    let validatePrintTimeRange = (rule, value, callback) => {
      if (!checkArray(this.printTimeRange) || this.printTimeRange.length == 0) {
        callback(new Error("请选择印刷时间！"));
      } else {
        callback();
      }
    };

    let validateMailTimeRange = (rule, value, callback) => {
      if (!checkArray(this.mailTimeRange) || this.mailTimeRange.length == 0) {
        callback(new Error("请选择邮寄时间！"));
      } else {
        callback();
      }
    };

    return {
      formSearch: {
        orgId: "",
        examId: "",
        pmId: "",
        supplierId: "",
        pageNo: 1,
        pageSize: 50,
      },
      curUserRole: {},
      hasPermit: false,
      totalElements: 0,
      loading: false,
      tableData: [],
      orgList: [],
      examList: [],
      pmList: [],
      supplierList: [],
      projectSettingDialog: false,
      settingForm: {
        id: "",
        orgId: "",
        examId: "",
        pmId: "",
        pmName: "",
        supplierId: "",
        supplierName: "",
        prepareStartTime: "",
        prepareEndTime: "",
        printStartTime: "",
        printEndTime: "",
        mailStartTime: "",
        mailEndTime: "",
      },
      prepareTimeRange: [],
      printTimeRange: [],
      mailTimeRange: [],
      rules: {
        orgId: [
          {
            type: "number",
            required: true,
            message: "请选择学校！",
            trigger: "change",
          },
        ],
        examId: [
          {
            type: "number",
            required: true,
            message: "请选择考试！",
            trigger: "change",
          },
        ],
        supplierId: [
          {
            required: true,
            message: "请选择供应商！",
            trigger: "change",
          },
        ],
        pmId: [
          {
            required: true,
            message: "请选择项目经理！",
            trigger: "change",
          },
        ],
        prepareTimeRange: [
          {
            required: true,
            validator: validatePrepareTimeRange,
            trigger: "change",
          },
        ],
        printTimeRange: [
          {
            required: true,
            validator: validatePrintTimeRange,
            trigger: "change",
          },
        ],
        mailTimeRange: [
          {
            required: true,
            validator: validateMailTimeRange,
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
  },
  created() {
    this.loadOrgList();

    this.loadUserRole(this.user);

    if (this.curUserRole.isSuperLeader) {
      this.hasPermit = true;
      this.loadPmList();
      this.loadSupplierList();
    } else if (this.curUserRole.isPM) {
      this.hasPermit = false;
      this.loadPmInfo(this.user);
      this.loadSupplierList();
      this.formSearch.pmId = this.user.userId;
    } else if (this.curUserRole.isSupplier) {
      this.hasPermit = false;
      this.loadPmList();
      this.loadSupplierInfo(this.user);
      this.formSearch.supplierId = this.user.userId;
    } else {
      this.hasPermit = false;
    }
  },
  methods: {
    handlePagerNo(pageNo) {
      /* 处理分页 */
      this.searchRecords(pageNo);
    },
    handlePagerSize(pageSize) {
      /* 处理每页条数 */
      this.formSearch.pageSize = pageSize;
      this.searchRecords(1);
    },
    resetSearchForm() {
      if (this.curUserRole.isPM) {
        this.formSearch.supplierId = "";
      } else if (this.curUserRole.isSupplier) {
        this.formSearch.pmId = "";
      } else {
        this.formSearch.pmId = "";
        this.formSearch.supplierId = "";
      }
      //this.searchRecords(1);
    },
    searchRecords(pageNo) {
      this.formSearch.pageNo = pageNo;

      /* 查询记录列表 */
      let orgId = this.formSearch.orgId;
      if (checkEmptyNumber(orgId)) {
        this.$notify({
          title: "提示",
          message: "请选择学校！",
          type: "warning",
        });
        return;
      }

      let examId = this.formSearch.examId;
      if (checkEmptyNumber(examId)) {
        this.$notify({
          title: "提示",
          message: "请选择考试！",
          type: "warning",
        });
        return;
      }

      this.loading = true;
      let url = PRINT_API + "/printing/project/list";
      this.$http.post(url, this.formSearch).then(
        (response) => {
          this.tableData = response.data.content;
          this.totalElements = response.data.totalElements;
          this.loading = false;
        },
        (error) => {
          console.log(error);
          this.loading = false;
        }
      );
    },
    selectDefault() {
      if (this.orgList.length > 0) {
        let firstOrgId = this.orgList[0].orgId;
        this.formSearch.orgId = firstOrgId;
        this.loadExamList(firstOrgId);
      }
    },
    loadOrgList() {
      /* 查询学校列表 */
      let url = PRINT_API + "/printing/project/org/list";
      this.$http.post(url).then(
        (response) => {
          this.orgList = response.data;
          this.selectDefault();
        },
        (error) => {
          console.log(error.response);
          // ignore
        }
      );
    },
    loadExamList(orgId) {
      /* 查询考试列表 */
      this.formSearch.examId = "";
      this.examList = [];
      this.tableData = [];
      if (checkEmptyNumber(orgId)) {
        return;
      }

      let url = PRINT_API + "/printing/project/exam/list?orgId=" + orgId;
      this.$http.post(url).then((response) => {
        this.examList = response.data;
        if (this.examList.length > 0) {
          this.formSearch.examId = this.examList[0].examId;
          this.searchRecords(1);
        }
      });
    },
    loadPmList(query) {
      /* 查询项目经理列表 */
      this.pmList = [];
      let url =
        CORE_API +
        "/user/query?enable=true&rootOrgCode=qmth&roleCode=PRINT_PROJECT_LEADER";

      if (!checkEmptyStr(query)) {
        url += "&name=" + query;
      }

      this.$http.get(url).then((response) => {
        this.pmList = response.data;
      });
    },
    loadSupplierList(query) {
      /* 查询印刷供应商列表 */
      this.supplierList = [];
      let url =
        CORE_API +
        "/user/query?enable=true&rootOrgCode=qmth-print&roleCode=PRINT_SUPPLIER";

      if (!checkEmptyStr(query)) {
        url += "&name=" + query;
      }

      this.$http.get(url).then((response) => {
        this.supplierList = response.data;
      });
    },
    loadPmInfo(loginUser) {
      this.pmList = [];
      this.pmList.push({ id: loginUser.userId, name: loginUser.displayName });
    },
    loadSupplierInfo(loginUser) {
      this.supplierList = [];
      this.supplierList.push({
        id: loginUser.userId,
        name: loginUser.displayName,
      });
    },
    selectPmName(key) {
      /* 处理项目经理名称 */
      if (checkEmptyNumber(key)) {
        return;
      }
      let obj = {};
      obj = this.pmList.find(function (item) {
        return item.id === key;
      });
      this.settingForm.pmName = obj.name;
    },
    selectSupplierName(key) {
      /* 处理供应商名称 */
      if (checkEmptyNumber(key)) {
        return;
      }
      let obj = {};
      obj = this.supplierList.find(function (item) {
        return item.id === key;
      });
      this.settingForm.supplierName = obj.name;
    },
    gotoProjectStatistic(row) {
      /* 查看项目统计 */
      let url =
        "/print/project/statistic?orgId=" + row.orgId + "&examId=" + row.examId;
      this.$router.push({ path: url });
    },
    editProjectSetting() {
      /* 保存项目设置 */
      this.$refs.settingForm.validate((valid) => {
        if (!valid) {
          return false;
        }
        this.selectPmName(this.settingForm.pmId);
        this.selectSupplierName(this.settingForm.supplierId);

        this.settingForm.prepareStartTime = formatGMT(this.prepareTimeRange[0]);
        this.settingForm.prepareEndTime = formatGMT(this.prepareTimeRange[1]);
        this.settingForm.printStartTime = formatGMT(this.printTimeRange[0]);
        this.settingForm.printEndTime = formatGMT(this.printTimeRange[1]);
        this.settingForm.mailStartTime = formatGMT(this.mailTimeRange[0]);
        this.settingForm.mailEndTime = formatGMT(this.mailTimeRange[1]);

        let curLoading = this.$loading({
          lock: true,
          text: "数据保存中！",
        });

        let url = PRINT_API + "/printing/project/update";
        this.$http.post(url, this.settingForm).then(
          () => {
            curLoading.close();
            this.$notify({
              title: "提示",
              type: "success",
              message: "项目设置成功！",
            });
            this.projectSettingDialog = false;
            this.searchRecords(1);
          },
          (error) => {
            console.log(error.response);
            curLoading.close();
            this.$notify({
              title: "错误",
              type: "error",
              message: error.response.data.desc,
            });
          }
        );
      });
    },
    openProjectSettingDialog(row) {
      /* 打开项目设置弹窗 */
      this.projectSettingDialog = true;
      this.settingForm.id = row.id;
      this.settingForm.orgId = row.orgId;
      this.settingForm.examId = row.examId;
      this.settingForm.pmId = row.pmId;
      this.settingForm.pmName = row.pmName;
      this.settingForm.supplierId = row.supplierId;
      this.settingForm.supplierName = row.supplierName;

      this.prepareTimeRange = this.settingDateRange(
        row.prepareStartTime,
        row.prepareEndTime
      );
      this.printTimeRange = this.settingDateRange(
        row.printStartTime,
        row.printEndTime
      );
      this.mailTimeRange = this.settingDateRange(
        row.mailStartTime,
        row.mailEndTime
      );
    },
    closeProjectSettingDialog() {
      /* 关闭项目设置弹窗 */
      this.projectSettingDialog = false;
    },
    settingDateRange(startDate, endDate) {
      if (!checkEmptyStr(startDate) && !checkEmptyStr(endDate)) {
        return [startDate, endDate];
      }
      return [];
    },
  },
};
</script>

<style scoped>
.page {
  margin-top: 10px;
}
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
.w220 {
  width: 220px;
}
.w440 {
  width: 440px;
}
</style>
