<template>
  <section class="content">
    <div
      v-loading.body="fileLoading"
      v-loading.fullscreen="loading"
      class="box-body"
      element-loading-text="请稍后..."
    >
      <el-form ref="primaryForm" :inline="true" :model="formSearch">
        <el-row>
          <el-form-item label="专业名称">
            <el-input
              v-model="formSearch.name"
              class="input_width_lg"
              placeholder="请输入专业名称"
            />
          </el-form-item>
          <el-form-item label="专业代码">
            <el-input
              v-model="formSearch.code"
              class="input_width_lg"
              placeholder="请输入专业代码"
            />
          </el-form-item>
          <el-form-item label="课程">
            <el-select
              v-model="formSearch.courseId"
              class="input"
              :remote-method="getCourses"
              :loading="courseLoading"
              remote
              filterable
              clearable
              placeholder="请选择"
              @focus="(e) => getCourses(e.target.value)"
            >
              <el-option
                v-for="item in courseList4SearchWrapper"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              size="small"
              type="primary"
              icon="el-icon-search"
              @click="handleSearchBtn"
            >
              查询
            </el-button>
            <el-button
              size="small"
              icon="el-icon-refresh"
              @click="resetPrimaryForm"
            >
              重置
            </el-button>
            <el-button
              size="small"
              type="primary"
              icon="el-icon-plus"
              @click="insert"
            >
              新增
            </el-button>
          </el-form-item>
        </el-row>
      </el-form>

      <div class="block-seperator"></div>

      <span>操作：</span>
      <el-button
        size="small"
        type="danger"
        :disabled="noBatchSelected"
        icon="el-icon-delete"
        @click="deleteIds"
      >
        删除
      </el-button>
      <el-button
        size="small"
        type="primary"
        icon="el-icon-upload2"
        @click="impSpecialty"
      >
        导入
      </el-button>
      <el-button
        size="small"
        type="primary"
        icon="el-icon-download"
        @click="exportSpeciatly"
      >
        导出
      </el-button>

      <div style="width: 100%; margin-bottom: 10px"></div>

      <!-- 新增或修改弹出框 -->
      <el-dialog
        title="专业信息"
        width="450px"
        :visible.sync="speciallyDialog"
        @close="dialogBeforeClose"
      >
        <el-form
          ref="speciallyForm"
          :inline="true"
          inline-message
          :model="speciallyForm"
          :rules="rules"
          label-position="right"
          label-width="90px"
        >
          <el-form-item label="专业代码" prop="code">
            <el-input
              v-model="speciallyForm.code"
              :disabled="null != speciallyForm.id"
              class="pull_length"
              auto-complete="off"
              placeholder="专业代码"
              maxlength="100"
            />
          </el-form-item>
          <el-form-item label="专业名称" prop="name">
            <el-input
              v-model="speciallyForm.name"
              class="pull_length"
              auto-complete="off"
              placeholder="专业名称"
              maxlength="100"
            />
          </el-form-item>
          <el-row class="pull_center">
            <el-button type="primary" @click="submitForm('speciallyForm')">
              保 存
            </el-button>
            <el-button @click="close">取 消</el-button>
          </el-row>
        </el-form>
      </el-dialog>

      <!-- 关联课程弹出框 -->
      <el-dialog
        :title="courseDialogTitle"
        width="740px"
        :visible.sync="courseDialog"
      >
        <!-- 表单 -->
        <el-form :inline="true" :model="courseSearchForm">
          <el-row>
            <el-form-item label="课程名称" class="pull-left">
              <el-input
                v-model="courseSearchForm.name"
                class="input_width_lg"
                placeholder="请输入课程名称"
              />
            </el-form-item>
            <el-form-item label="课程代码" class="pull-left">
              <el-input
                v-model="courseSearchForm.code"
                class="input_width_lg"
                placeholder="请输入课程代码"
              />
            </el-form-item>
            <el-form-item class="pull-right">
              <el-button
                size="small"
                type="primary"
                icon="el-icon-search"
                @click="searchCoursePage"
              >
                查询
              </el-button>
              <el-button
                size="small"
                type="primary"
                icon="el-icon-plus"
                @click="addRelation"
              >
                新增
              </el-button>
            </el-form-item>
          </el-row>
        </el-form>

        <!-- 课程列表 -->
        <el-table :data="courseTableData" border resizable stripe>
          <el-table-column prop="id" label="ID" width="60" />
          <el-table-column prop="name" label="课程名称" width="220" />
          <el-table-column prop="code" label="课程代码" />
          <el-table-column
            sortable
            prop="updateTime"
            label="更新时间"
            width="170"
          />
          <el-table-column label="操作" width="120">
            <span slot-scope="scope">
              <el-button
                size="mini"
                type="primary"
                icon="el-icon-edit"
                @click="deleteRelation(scope.row)"
              >
                取消关联
              </el-button>
            </span>
          </el-table-column>
        </el-table>
        <div class="pull-right">
          <el-pagination
            :current-page="currentCoursePage"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
            :total="courseTotal"
            @current-change="handleCourseCurrentChange"
          />
        </div>
      </el-dialog>

      <!-- 添加关联 -->
      <el-dialog
        title="添加关联课程"
        width="400px"
        :visible.sync="addRelationDialog"
        @close="() => $refs.addRelationForm.clearValidate()"
      >
        <el-form
          ref="addRelationForm"
          :inline="true"
          inline-message
          :model="addRelationForm"
          :rules="addRelationRules"
        >
          <el-row>
            <el-form-item label="课程" class="pull-left" prop="courseId">
              <el-select
                v-model="addRelationForm.courseId"
                class="input"
                style="width: 200px"
                :remote-method="getCourses4AddRelation"
                :loading="courseLoading4AddRelation"
                remote
                filterable
                clearable
                placeholder="请选择"
                @focus="(e) => getCourses4AddRelation(e.target.value)"
              >
                <el-option
                  v-for="item in courseList4AddRelationWrapper"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-row>
          <el-row class="d-flex justify-content-center">
            <el-button type="primary" @click="submitAddRelationForm">
              保 存
            </el-button>
            <el-button @click="addRelationDialog = false">取 消</el-button>
          </el-row>
        </el-form>
      </el-dialog>

      <!-- 页面列表 -->
      <el-table
        :data="tableData"
        border
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="40"> </el-table-column>
        <el-table-column prop="id" label="ID" width="60"> </el-table-column>
        <el-table-column prop="code" label="专业代码"> </el-table-column>
        <el-table-column prop="name" label="专业名称"> </el-table-column>
        <el-table-column
          sortable
          prop="updateTime"
          label="更新时间"
          width="170"
        >
        </el-table-column>
        <el-table-column label="操作" width="300">
          <template slot-scope="scope">
            <div>
              <el-button
                size="mini"
                type="primary"
                plain
                icon="el-icon-share"
                @click="relation(scope.row)"
              >
                关联课程
              </el-button>
              <el-button
                size="mini"
                type="primary"
                icon="el-icon-edit"
                plain
                @click="edit(scope.row)"
              >
                编辑
              </el-button>
              <el-button
                size="mini"
                type="danger"
                icon="el-icon-delete"
                @click="deleteId(scope.row)"
              >
                删除
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="page pull-right">
        <el-pagination
          :current-page="currentPage"
          :page-size="pageSize"
          :page-sizes="[10, 20, 50, 100, 200, 300]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        />
      </div>

      <!-- 导入弹窗 -->
      <el-dialog title="导入窗口" width="520px" :visible.sync="impDialog">
        <el-form>
          <el-row>
            <el-form-item style="margin-left: 20px">
              <el-upload
                ref="upload"
                class="form_left"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                :action="uploadAction"
                :headers="uploadHeaders"
                :data="uploadData"
                :before-upload="beforeUpload"
                :on-progress="uploadProgress"
                :on-success="uploadSuccess"
                :on-error="uploadError"
                :file-list="fileList"
                :auto-upload="false"
                :multiple="false"
              >
                <el-button
                  slot="trigger"
                  size="small"
                  type="primary"
                  icon="el-icon-search"
                >
                  选择文件
                </el-button>
                &nbsp;
                <el-button
                  size="small"
                  type="primary"
                  icon="el-icon-check"
                  @click="submitUpload"
                >
                  确认上传
                </el-button>
                <el-button
                  size="small"
                  type="primary"
                  icon="el-icon-refresh"
                  @click="removeFile"
                >
                  清空文件
                </el-button>
                <el-button
                  size="small"
                  type="primary"
                  icon="el-icon-download"
                  @click="exportFile"
                >
                  下载模板
                </el-button>
                <div slot="tip" class="el-upload__tip">只能上传xlsx文件</div>
              </el-upload>
            </el-form-item>
          </el-row>
        </el-form>
      </el-dialog>

      <!-- 导入错误信息列表 -->
      <el-dialog title="错误提示" :visible.sync="errDialog">
        <div
          v-for="errMessage in errMessages"
          :key="errMessage.lineNum"
          class="text-danger"
        >
          第{{ errMessage.lineNum }}行：{{ errMessage.msg }}
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="errDialog = false">确定</el-button>
        </span>
      </el-dialog>
    </div>
  </section>
</template>

<script>
import { CORE_API } from "@/constants/constants.js";
import { mapState } from "vuex";

export default {
  name: "Specially",
  data() {
    return {
      courseLoading: false,
      formSearch: {
        name: "",
        code: "",
        courseId: "",
      },
      speciallyForm: {
        id: null,
        name: "",
        code: "",
      },
      speciatlyId: "",
      speciallyDialog: false,
      courseDialog: false,
      loading: false,
      multipleSelection: [],
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 10,
      courseAllList: [],
      courseList: [],
      courseList4Search: [],
      courseAll: [],

      impDialog: false,
      uploadAction: CORE_API + "/specialty/import",
      uploadHeaders: {},
      uploadData: {},
      errMessages: [],
      errDialog: false,
      fileLoading: false,
      fileList: [],
      rules: {
        name: [{ required: true, message: "请输入专业名称", trigger: "blur" }],
        code: [{ required: true, message: "请输入专业代码", trigger: "blur" }],
      },
      courseDialogTitle: null,
      courseSearchForm: {
        specialtyId: null,
        code: "",
        name: "",
      },
      courseTableData: [],
      currentCoursePage: 1,
      coursePageSize: 10,
      courseTotal: 10,

      addRelationDialog: false,
      addRelationForm: {
        specialtyId: null,
        courseId: null,
      },
      courseLoading4AddRelation: false,
      courseList4AddRelation: [],
      addRelationRules: {
        courseId: [
          {
            required: true,
            type: "number",
            message: "请选择课程",
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    noBatchSelected() {
      return this.multipleSelection.length === 0;
    },
    courseAllListSelect() {
      let courseList = [];
      for (let course of this.courseAllList) {
        let courseInfo = course.name + "(" + course.code + ")";
        courseList.push({ id: course.id, courseInfo: courseInfo });
      }
      return courseList;
    },
    courseList4SearchWrapper() {
      var courseList = [];
      for (let course of this.courseList4Search) {
        var name = course.name + " - " + course.code;
        var id = course.id;
        courseList.push({ id: id, name: name, enable: course.enable });
      }
      return courseList;
    },
    courseList4AddRelationWrapper() {
      var courseList = [];
      for (let course of this.courseList4AddRelation) {
        var name = course.name + " - " + course.code;
        var id = course.id;
        courseList.push({ id: id, name: name, enable: course.enable });
      }
      return courseList;
    },
  },
  //初始化查询
  created() {
    this.searchForm();
    this.uploadHeaders = {
      key: this.user.key,
      token: this.user.token,
    };
  },
  methods: {
    dialogBeforeClose() {
      this.$refs.speciallyForm.clearValidate();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.searchForm();
    },
    resetPrimaryForm() {
      this.formSearch = {
        name: "",
        code: "",
        enable: "",
        level: "",
        specialtyId: "",
      };
      this.$refs.primaryForm.resetFields();
    },
    deleteRelation(row) {
      var courseId = row.id;
      var specialtyId = this.courseSearchForm.specialtyId;
      var param = new URLSearchParams({
        courseId: courseId,
        specialtyId: specialtyId,
      });
      var url = CORE_API + "/courseSpeciatlyRelation/delete?" + param;
      this.$httpWithMsg.delete(url).then(() => {
        this.$notify({
          type: "success",
          message: "取消关联成功！",
        });
        return this.searchCoursePage();
      });
    },
    submitAddRelationForm() {
      this.$refs.addRelationForm.validate((valid) => {
        if (valid) {
          var param = new URLSearchParams(this.addRelationForm);
          var url = CORE_API + "/courseSpeciatlyRelation/add?" + param;
          this.$httpWithMsg.post(url, this.speciallyForm).then(
            () => {
              this.$notify({
                type: "success",
                message: "添加成功！",
              });
              this.searchCoursePage();
              this.addRelationDialog = false;
            },
            () => (this.addRelationDialog = false)
          );
        } else {
          console.log("error submit!");
          return false;
        }
      });
    },
    addRelation() {
      this.addRelationForm.courseId = null;
      this.addRelationForm.specialtyId = this.courseSearchForm.specialtyId;
      this.addRelationDialog = true;
      // this.$refs.addRelationForm.resetFields();
    },
    searchCoursePage() {
      var param = new URLSearchParams(this.courseSearchForm);
      var url =
        CORE_API +
        "/course/coursePage/" +
        (this.currentCoursePage - 1) +
        "/" +
        this.pageSize +
        "?" +
        param;
      this.$httpWithMsg
        .get(url)
        .then((response) => {
          console.log(response);
          this.courseTableData = response.data.content;
          this.courseTotal = response.data.totalElements;
        })
        .then(() => {
          if (this.courseTableData.length === 0 && this.courseTotal > 0) {
            this.currentCoursePage = this.currentCoursePage - 1;
            this.searchCoursePage();
          }
        });
    },
    getCourses(query) {
      this.courseLoading = true;
      this.$httpWithMsg
        .get(CORE_API + "/course/query?name=" + query)
        .then((response) => {
          this.courseList4Search = response.data;
          this.courseLoading = false;
        });
    },
    getCourses4AddRelation(query) {
      this.courseLoading4AddRelation = true;
      this.$httpWithMsg
        .get(CORE_API + "/course/query?name=" + query)
        .then((response) => {
          this.courseList4AddRelation = response.data;
          this.courseLoading4AddRelation = false;
        });
    },
    handleSearchBtn() {
      this.currentPage = 1;
      this.searchForm();
    },
    //查询
    searchForm() {
      let searchLock = true;
      setTimeout(() => {
        if (searchLock) {
          this.loading = true;
        }
      }, 500);
      var param = new URLSearchParams(this.formSearch);
      var url =
        CORE_API +
        "/specialty/specialtyPage/" +
        (this.currentPage - 1) +
        "/" +
        this.pageSize +
        "?" +
        param;
      this.$httpWithMsg
        .get(url)
        .then((response) => {
          console.log("response :", response);
          this.tableData = response.data.content;
          this.total = response.data.totalElements;
        })
        .finally(() => {
          searchLock = false;
          this.loading = false;
        });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.searchForm();
    },
    handleCourseCurrentChange(val) {
      this.currentCoursePage = val;
      this.searchCoursePage();
    },
    //全选
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //删除方法
    deleteSchool() {
      this.$confirm("是否删除学校信息?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      }).then(() => {
        this.loading = true;
        var vm = this;
        var url = CORE_API;
        vm.$httpWithMsg.delete(url).then(() => {
          this.loading = false;
          this.$notify({
            type: "success",
            message: "删除成功!",
          });
          //this.searchForm()
        });
      });
    },
    //保存
    submitForm(formData) {
      this.$refs[formData].validate((valid) => {
        if (valid) {
          var url = CORE_API + "/specialty";
          //修改
          if (null != this.speciallyForm.id) {
            this.$httpWithMsg.put(url, this.speciallyForm).then(
              () => {
                this.$notify({
                  type: "success",
                  message: "修改成功！",
                });
                this.searchForm();
                this.speciallyDialog = false;
              },
              () => {
                this.searchForm();
                this.speciallyDialog = false;
              }
            );
          }
          //新增
          else {
            this.$httpWithMsg.post(url, this.speciallyForm).then(
              () => {
                this.$notify({
                  type: "success",
                  message: "新增成功！",
                });
                this.speciallyDialog = false;
                this.searchForm();
              },
              () => {
                this.speciallyDialog = false;
                return this.searchForm();
              }
            );
          }
        } else {
          console.log("error submit!");
          return false;
        }
      });
    },

    //重置
    resetForm(formData) {
      this.$refs[formData].resetFields();
    },
    close() {
      this.speciallyDialog = false;
    },
    //新增
    insert() {
      this.speciallyForm.id = null;
      this.speciallyForm.name = "";
      this.speciallyForm.code = "";

      this.speciallyDialog = true;
    },
    //修改
    edit(row) {
      this.speciallyForm = Object.assign({}, row);

      this.speciallyDialog = true;
    },
    //删除
    deleteId(row) {
      this.$confirm("是否删除该条课程信息？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      }).then(() => {
        var url = CORE_API + "/specialty/" + row.id;
        this.$httpWithMsg.delete(url).then(() => {
          this.$notify({
            type: "success",
            message: "删除成功！",
          });
          this.searchForm();
        });
      });
    },
    //删除多条信息
    deleteIds() {
      if (this.multipleSelection.length === 0) {
        this.$notify({
          type: "warning",
          message: "请选择要删除的专业",
        });
      } else {
        this.$confirm("是否删除这些课程信息？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "error",
        }).then(() => {
          var ids = [];
          for (var i = 0; i < this.multipleSelection.length; i++) {
            ids.push(this.multipleSelection[i].id);
          }
          var url = CORE_API + "/specialty/" + ids;
          this.$httpWithMsg.delete(url).then(() => {
            this.$notify({
              type: "success",
              message: "删除成功！",
            });
            this.searchForm();
          });
        });
      }
    },
    //关联课程
    relation(row) {
      this.courseDialogTitle =
        "关联课程列表 【专业名称：" +
        row.name +
        "】【专业代码：" +
        row.code +
        "】";
      this.courseSearchForm.specialtyId = row.id;
      this.courseSearchForm.code = "";
      this.courseSearchForm.name = "";

      this.searchCoursePage();
      this.courseDialog = true;
    },
    closeCourse() {
      this.courseDialog = false;
    },
    saveCourse() {
      //debugger;
      var url = CORE_API + "/CourseSpeciatly/addCourse/" + this.speciatlyId;
      this.$httpWithMsg.post(url, this.courseList).then(
        () => {
          this.$notify({
            type: "success",
            message: "课程关联成功！",
          });
          this.courseDialog = false;
          this.searchForm();
        },
        () => {
          this.$notify({
            type: "error",
            message: "课程关联失败！",
          });
          this.courseDialog = false;
          this.searchForm();
        }
      );
    },
    exportSpeciatly() {
      let param = new URLSearchParams(this.formSearch);
      window.open(
        CORE_API +
          "/specialty/export?$key=" +
          this.user.key +
          "&$token=" +
          this.user.token +
          "&" +
          param
      );
    },
    //导入
    impSpecialty() {
      this.impDialog = true;
      this.initUpload();
    },
    initUpload() {
      this.fileList = [];
    },
    beforeUpload(file) {
      console.log(file);
    },
    uploadProgress() {
      console.log("uploadProgress");
    },
    uploadSuccess(response) {
      if (!response.hasError) {
        this.$notify({
          message: "上传成功",
          type: "success",
        });
        this.fileLoading = false;
        this.impDialog = false;
        this.searchForm();
      } else {
        this.fileLoading = false;
        this.impDialog = false;
        this.errMessages = response.failRecords;
        this.errDialog = true;
      }
    },
    uploadError(response) {
      var json = JSON.parse(response.message);
      if (response.status == 500) {
        this.$notify({
          message: json.desc,
          type: "error",
        });
      }
      this.fileLoading = false;
    },
    //确定上传
    submitUpload() {
      if (!this.checkUpload()) {
        return false;
      }
      this.$refs.upload.submit();
      this.fileLoading = true;
    },
    checkUpload() {
      var fileList = this.$refs.upload.uploadFiles;
      if (fileList.length == 0) {
        this.$notify({
          message: "上传文件不能为空",
          type: "error",
        });
        return false;
      }
      if (fileList.length > 1) {
        this.$notify({
          message: "每次只能上传一个文件",
          type: "error",
        });
        return false;
      }
      for (let file of fileList) {
        if (!file.name.endsWith(".xlsx")) {
          this.$notify({
            message: "上传文件必须为xlsx格式",
            type: "error",
          });
          this.initUpload();
          return false;
        }
      }
      return true;
    },
    //清空文件
    removeFile() {
      // this.fileList = [];
      this.$refs.upload.clearFiles();
    },
    //下载模板
    exportFile() {
      window.location.href =
        CORE_API +
        "/specialty/importTemplate?$key=" +
        this.user.key +
        "&$token=" +
        this.user.token;
    },
  },
};
</script>

<style scoped>
.page {
  margin-top: 10px;
}

.input_width_lg {
  width: 180px;
}
.pull_center {
  margin-left: 120px;
}
.pull_length {
  width: 200px;
}
</style>
