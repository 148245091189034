<template>
  <section class="content">
    <LinkTitlesCustom
      :current-paths="['考试管理', '考试信息', '微信小程序作答设置']"
    />

    <div class="box box-info">
      <div class="box-body">
        <el-form
          ref="searchForm"
          :model="searchForm"
          :inline="true"
          label-width="70px"
        >
          <el-form-item label="课程代码">
            <el-input
              v-model="searchForm.courseCode"
              class="input"
              clearable
            ></el-input>
          </el-form-item>

          <el-form-item label="课程名称">
            <el-input
              v-model="searchForm.courseName"
              class="input"
              clearable
            ></el-input>
          </el-form-item>

          <el-form-item label="状态">
            <el-select
              v-model="searchForm.weixinAnswerEnabled"
              class="input"
              clearable
            >
              <el-option label="全部" value=""></el-option>
              <el-option label="启用" value="true"></el-option>
              <el-option label="禁用" value="false"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              size="small"
              type="primary"
              icon="el-icon-search"
              @click="doSearch(1)"
              >查询</el-button
            >
            <el-button
              size="small"
              type="primary"
              icon="el-icon-arrow-left"
              @click="back"
              >返回</el-button
            >
          </el-form-item>
        </el-form>

        <div class="block-seperator"></div>

        <el-button
          size="small"
          type="danger"
          icon="el-icon-edit"
          :disabled="noBatchSelected"
          @click="batchEnabled(true)"
          >批量启用</el-button
        >
        <el-button
          size="small"
          type="danger"
          icon="el-icon-edit"
          :disabled="noBatchSelected"
          @click="batchEnabled(false)"
          >批量禁用</el-button
        >
        <el-button
          size="small"
          type="primary"
          icon="el-icon-upload2"
          @click="settingDialogOpen"
          >导入设置</el-button
        >
        <el-button
          size="small"
          type="primary"
          icon="el-icon-download"
          @click="exportSetting"
          >导出设置</el-button
        >

        <div style="width: 100%; margin-bottom: 10px"></div>

        <el-table
          :data="tableData"
          style="width: 100%; text-align: center"
          border
          @selection-change="selectChange"
        >
          <el-table-column type="selection" width="50"></el-table-column>
          <el-table-column prop="courseCode" label="课程代码"></el-table-column>
          <el-table-column prop="courseName" label="课程名称"></el-table-column>
          <el-table-column label="状态">
            <template slot-scope="scope">
              <span>{{ scope.row.weixinAnswerEnabled ? "启用" : "禁用" }}</span>
            </template>
          </el-table-column>
        </el-table>

        <div class="page pull-right">
          <el-pagination
            :current-page="searchForm.pageNo"
            :page-size="searchForm.pageSize"
            :page-sizes="[10, 20, 50, 100, 200, 300]"
            :total="totalElements"
            layout="total, sizes, prev, pager, next, jumper"
            @current-change="handlePagerNo"
            @size-change="handlePagerSize"
          ></el-pagination>
        </div>
      </div>

      <el-dialog
        title="导入配置"
        width="520px"
        :visible.sync="settingDialog"
        @close="settingDialogClose"
      >
        <el-form>
          <el-row>
            <el-form-item style="margin-left: 20px">
              <el-upload
                ref="upload"
                class="form_left"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                action="/api/ecs_exam_work/exam/course/import/weixinAnswerEnabledSetting"
                :file-list="fileList"
                :data="uploadData"
                :headers="uploadHeaders"
                :on-success="uploadSuccess"
                :on-error="uploadError"
                :auto-upload="false"
                :multiple="false"
              >
                <el-button
                  slot="trigger"
                  size="small"
                  type="primary"
                  icon="el-icon-search"
                >
                  选择文件
                </el-button>
                &nbsp;
                <el-button
                  size="small"
                  type="primary"
                  icon="el-icon-check"
                  @click="doUpload"
                >
                  确认上传
                </el-button>
                <el-button
                  size="small"
                  type="primary"
                  icon="el-icon-refresh"
                  @click="clearFiles"
                >
                  清空文件
                </el-button>
                <el-button
                  size="small"
                  type="primary"
                  icon="el-icon-download"
                  @click="downloadTemplate"
                >
                  下载模板
                </el-button>
                <div slot="tip" class="el-upload__tip">
                  只能上传xlsx格式的文件！
                </div>
              </el-upload>
            </el-form-item>
          </el-row>
        </el-form>
      </el-dialog>
    </div>
  </section>
</template>

<script>
import { EXAM_WORK_API } from "@/constants/constants.js";
import { mapState } from "vuex";

export default {
  data() {
    return {
      searchForm: {
        examId: null,
        courseCode: "",
        courseName: "",
        weixinAnswerEnabled: null,
        pageNo: 1,
        pageSize: 10,
      },
      loading: false,
      tableData: [],
      totalElements: 0,
      selectedCourseIds: [],
      settingDialog: false,
      fileList: [],
      uploadHeaders: {},
      uploadData: {
        examId: null,
      },
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
    noBatchSelected() {
      return this.selectedCourseIds.length === 0;
    },
  },
  created() {
    this.searchForm.examId = this.$route.params.id;
    this.doSearch(1);
  },
  methods: {
    back() {
      this.$router.push({ path: "/examwork/examInfo" });
    },
    doSearch(pageNo) {
      this.searchForm.pageNo = pageNo;

      let url = EXAM_WORK_API + "/exam/course/list";
      this.$httpWithMsg.post(url, this.searchForm).then((response) => {
        this.tableData = response.data.content;
        this.totalElements = response.data.totalElements;
      });
    },
    settingDialogOpen() {
      this.settingDialog = true;
      this.fileList = [];
      this.uploadData.examId = this.searchForm.examId;
      this.uploadHeaders = {
        key: this.user.key,
        token: this.user.token,
      };
    },
    settingDialogClose() {
      this.settingDialog = false;
    },
    doUpload() {
      if (!this.checkUpload()) {
        return false;
      }

      this.$refs.upload.submit();
    },
    checkUpload() {
      let fileList = this.$refs.upload.uploadFiles;
      if (fileList.length == 0) {
        this.$notify({
          message: "上传文件不能为空！",
          type: "error",
        });
        return false;
      }

      if (fileList.length > 1) {
        this.$notify({
          message: "每次只能上传一个文件！",
          type: "error",
        });
        return false;
      }

      for (let file of fileList) {
        if (!file.name.endsWith(".xlsx")) {
          this.$notify({
            message: "只能上传xlsx格式的文件！",
            type: "error",
          });

          this.clearFiles();
          return false;
        }
      }

      return true;
    },
    uploadSuccess(response) {
      console.log(response);
      this.$notify({
        message: "上传成功！",
        type: "success",
      });

      this.settingDialogClose();
      this.doSearch(1);
    },
    uploadError(response) {
      // console.log(response);
      let json = JSON.parse(response.message);
      if (response.status != 200) {
        this.$notify({
          message: "上传失败！" + json.desc,
          type: "error",
        });
      }
    },
    clearFiles() {
      this.fileList = [];
      this.$refs.upload.clearFiles();
    },
    downloadTemplate() {
      window.location.href =
        EXAM_WORK_API + "/exam/course/download/weixinAnswerEnabledTemplate";
    },
    exportSetting() {
      this.$confirm("确认导出设置？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let url =
          EXAM_WORK_API +
          "/exam/course/export/weixinAnswerEnabledSetting?examId=" +
          this.searchForm.examId;

        if (this.searchForm.weixinAnswerEnabled) {
          url += "&weixinAnswerEnabled=" + this.searchForm.weixinAnswerEnabled;
        }

        window.open(url);
      });
    },
    batchEnabled(enabled) {
      this.$confirm(enabled ? "确认启用？" : "确认禁用？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let courseIds = this.selectedCourseIds.join(",");

        let url =
          EXAM_WORK_API +
          "/exam/course/weixinAnswerEnabled?examId=" +
          this.searchForm.examId +
          "&enabled=" +
          enabled +
          "&courseIds=" +
          courseIds;
        this.$http.post(url).then(() => {
          this.$notify({
            type: "success",
            message: enabled ? "批量启用成功!" : "批量禁用成功！",
          });
          this.doSearch(1);
        });
      });
    },
    handlePagerNo(pageNo) {
      this.doSearch(pageNo);
    },
    handlePagerSize(pageSize) {
      this.searchForm.pageSize = pageSize;
      this.doSearch(1);
    },
    selectChange(row) {
      this.selectedCourseIds = [];
      row.forEach((element) => {
        this.selectedCourseIds.push(element.courseId);
      });
    },
  },
};
</script>
<style scoped>
.input {
  width: 180px;
}
</style>
