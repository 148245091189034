import Vue from "vue";
//标记卷类型
export const TAGS = [
  { label: "雷同卷", value: "SAME" },
  { label: "空白卷", value: "BLANK" },
  { label: "答非所问", value: "IRRELEVANT" },
  { label: "作弊违纪", value: "CHEAT" },
  { label: "科目错误", value: "SUBJECT_ERROR" },
  { label: "非手写", value: "QUESTIONABLE" },
];
//标记卷过滤器
Vue.filter("tagFilter", function (val) {
  for (let tag of TAGS) {
    if (tag.value === val) {
      return tag.label;
    }
  }
});
//考试类型
export const EXAMTYPES = [
  { label: "在线考试", value: "ONLINE" },
  { label: "离线考试", value: "OFFLINE" },
  { label: "传统考试", value: "TRADITION" },
  { label: "练习", value: "PRACTICE" },
  { label: "在线作业", value: "ONLINE_HOMEWORK" },
];
//考试类型过滤器
Vue.filter("examTypeFilter", function (val) {
  for (let examType of EXAMTYPES) {
    if (examType.value === val) {
      return examType.label;
    }
  }
});
