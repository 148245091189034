<template>
  <div>
    <LinkTitlesCustom
      :current-paths="['考试管理', '考试信息', '学习中心设置']"
    />
    <section class="content">
      <div class="box-body">
        <el-form
          :model="formSearch"
          :inline="true"
          label-position="right"
          label-width="80px"
        >
          <el-form-item label="学习中心">
            <el-select
              v-model="formSearch.orgId"
              class="select"
              :remote-method="getOrgList4Search"
              :loading="loadingOrg4Search"
              remote
              filterable
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in orgList4Search"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              size="small"
              type="primary"
              icon="el-icon-search"
              @click="resetPageAndSearch"
              >查询
            </el-button>
            <el-button
              size="small"
              type="primary"
              icon="el-icon-plus"
              @click="showAddOrgSetting"
              >新增
            </el-button>
            <el-button
              size="small"
              type="primary"
              icon="el-icon-download"
              @click="exportOrgSettings"
            >
              导出
            </el-button>
            <el-button
              size="small"
              type="primary"
              icon="el-icon-upload2"
              @click="importOrgSettings"
            >
              导入
            </el-button>
            <el-button size="small" type="primary" @click="back"
              >返 回
            </el-button>
          </el-form-item>
        </el-form>

        <div class="block-seperator"></div>
        <span>批量操作：</span>

        <el-button
          size="small"
          type="success"
          icon="el-icon-check"
          :disabled="noBatchSelected"
          @click="batchNotLimit"
          >开考
        </el-button>
        <el-button
          size="small"
          type="danger"
          icon="el-icon-close"
          :disabled="noBatchSelected"
          @click="batchLimit"
          >禁考
        </el-button>
        <el-button
          size="small"
          type="danger"
          icon="el-icon-delete"
          :disabled="noBatchSelected"
          @click="batchDelete"
          >删除
        </el-button>
        <el-button
          size="small"
          type="danger"
          icon="el-icon-delete"
          :disabled="total == 0"
          @click="deleteAll"
          >删除所有
        </el-button>

        <div style="width: 100%; margin-bottom: 10px"></div>

        <!-- 页面列表 -->
        <el-table
          v-loading="loading4FormSearch"
          :data="tableData"
          border
          style="width: 100%; text-align: center"
          @selection-change="selectChange"
        >
          <el-table-column type="selection" width="40"></el-table-column>
          <el-table-column prop="id" width="80" label="ID"></el-table-column>
          <el-table-column
            prop="orgCode"
            label="学习中心代码"
            sortable
          ></el-table-column>
          <el-table-column
            prop="orgName"
            label="学习中心名称"
            sortable
          ></el-table-column>
          <el-table-column
            prop="beginTime"
            width="155"
            label="开始时间"
            sortable
          >
          </el-table-column>
          <el-table-column prop="endTime" width="155" label="结束时间" sortable>
          </el-table-column>
          <el-table-column width="80" label="开考状态">
            <span slot-scope="scope">
              <span v-if="!scope.row.examLimit">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="开考"
                  placement="left"
                >
                  <i class="el-icon-success" style="color: green"></i>
                </el-tooltip>
              </span>
              <span v-else>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="禁考"
                  placement="left"
                >
                  <i class="el-icon-error" style="color: red"></i>
                </el-tooltip>
              </span>
            </span>
          </el-table-column>
          <el-table-column prop="updateTime" width="155" label="更新时间">
          </el-table-column>
          <el-table-column label="操作" width="200">
            <template slot-scope="scope">
              <div>
                <el-button
                  v-if="scope.row.examLimit"
                  size="mini"
                  type="primary"
                  plain
                  icon="el-icon-edit"
                  @click="updateExamLimit(scope.row)"
                >
                  开考
                </el-button>
                <el-button
                  v-else
                  size="mini"
                  type="danger"
                  icon="el-icon-edit"
                  @click="updateExamLimit(scope.row)"
                >
                  禁考
                </el-button>
                <el-button
                  size="mini"
                  type="primary"
                  plain
                  icon="el-icon-edit"
                  @click="showUpdateOrgSetting(scope.row)"
                >
                  编辑
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="page pull-right">
          <el-pagination
            :current-page="currentPage"
            :page-size="pageSize"
            :page-sizes="[10, 20, 50, 100, 200, 300]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          >
          </el-pagination>
        </div>

        <!-- 新增 -->
        <el-dialog
          title="新增学习中心考试设置"
          size="tiny"
          :visible.sync="addOrgSettingDialog"
        >
          <el-form
            ref="addOrgSettingForm"
            :model="orgSetting"
            :rules="orgSettingRules"
            label-position="right"
            :inline="true"
            inline-message
          >
            <el-row>
              <el-form-item label="学习中心" prop="orgId" label-width="120px">
                <el-select
                  v-model="orgSetting.orgId"
                  class="select"
                  :remote-method="getOrgList4InsertOrUpdate"
                  :loading="loadingOrg4InsertOrUpdate"
                  remote
                  filterable
                  clearable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in orgList4InsertOrUpdate"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item
                label="考试时间"
                prop="orgSettingDatetimeRange"
                label-width="120px"
              >
                <el-date-picker
                  v-model="orgSettingDatetimeRange"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="开考状态" label-width="120px">
                <el-radio-group v-model="orgSetting.examLimit" class="input">
                  <el-radio label="true">禁考</el-radio>
                  <el-radio label="false">开考</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-row>
            <el-row>
              <div style="text-align: center">
                <el-button type="primary" @click="addOrgSetting"
                  >确 定
                </el-button>
                <el-button @click="addOrgSettingDialog = false"
                  >取 消
                </el-button>
              </div>
            </el-row>
          </el-form>
        </el-dialog>

        <!-- 修改 -->
        <el-dialog
          title="修改学习中心考试设置"
          size="tiny"
          :visible.sync="updateOrgSettingDialog"
        >
          <el-form
            ref="updateOrgSettingForm"
            :model="orgSetting"
            :rules="orgSettingRules"
            label-position="right"
            :inline="true"
            inline-message
          >
            <el-row>
              <el-form-item label="学习中心" prop="orgId" label-width="120px">
                <el-select
                  v-model="orgSetting.orgId"
                  class="select"
                  :remote-method="getOrgList4InsertOrUpdate"
                  :loading="loadingOrg4InsertOrUpdate"
                  remote
                  filterable
                  clearable
                  :disabled="true"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in orgList4InsertOrUpdate"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item
                label="考试时间"
                prop="orgSettingDatetimeRange"
                label-width="120px"
              >
                <el-date-picker
                  v-model="orgSettingDatetimeRange"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="开考状态" label-width="120px">
                <el-radio-group v-model="orgSetting.examLimit" class="input">
                  <el-radio label="true">禁考</el-radio>
                  <el-radio label="false">开考</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-row>
            <el-row>
              <div style="margin-left: 30%">
                <el-button type="primary" @click="updateOrgSetting"
                  >确 定
                </el-button>
                <el-button @click="updateOrgSettingDialog = false"
                  >取 消
                </el-button>
              </div>
            </el-row>
          </el-form>
        </el-dialog>

        <!-- 导入弹窗 -->
        <el-dialog
          title="学习中心设置导入"
          size="tiny"
          width="500px"
          :visible.sync="orgSettingsImportDialog"
        >
          <el-form ref="orgSettingsImportForm" :model="orgSettingsImportForm">
            <el-row>
              <el-form-item style="margin-left: 30px">
                <el-upload
                  ref="upload"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  :action="uploadAction"
                  :headers="uploadHeaders"
                  :data="uploadData"
                  :on-success="uploadSuccess"
                  :on-error="uploadError"
                  :file-list="fileList"
                  :auto-upload="false"
                  :multiple="false"
                >
                  <el-button
                    slot="trigger"
                    size="small"
                    type="primary"
                    icon="el-icon-search"
                    >选择文件
                  </el-button>
                  <el-button
                    size="small"
                    style="margin-left: 10px"
                    type="primary"
                    icon="el-icon-check"
                    @click="submitUpload"
                    >确认上传
                  </el-button>
                  <el-button
                    size="small"
                    style="margin-left: 10px"
                    type="primary"
                    icon="el-icon-refresh"
                    @click="removeFile"
                    >清空文件
                  </el-button>
                  <div slot="tip" class="el-upload__tip">只能上传xlsx文件</div>
                </el-upload>
              </el-form-item>
            </el-row>
          </el-form>
        </el-dialog>

        <!-- 导入错误信息列表 -->
        <el-dialog title="错误提示" :visible.sync="errDialog">
          <div
            v-for="errMessage in errMessages"
            :key="errMessage.lineNum"
            class="text-danger"
          >
            第{{ errMessage.lineNum }}行：{{ errMessage.msg }}
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="errDialog = false">确定</el-button>
          </span>
        </el-dialog>
        <!--END-->
      </div>
    </section>
  </div>
</template>

<script>
import { CORE_API, EXAM_WORK_API } from "@/constants/constants.js";
import LinkTitlesCustom from "@/components/LinkTitlesCustom.vue";
import { mapState } from "vuex";

export default {
  components: { LinkTitlesCustom },
  data() {
    return {
      examId: null,
      loading4FormSearch: false,
      formSearch: {
        examId: null,
        orgId: "",
      },
      loadingOrg4Search: false,
      orgList4Search: [],

      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      selectedOrgSettings: [],

      orgSetting: {
        examId: null,
        orgId: null,
        beginTime: null,
        endTime: null,
        examLimit: "false",
        properties: {},
      },
      orgSettingDatetimeRange: [],
      addOrgSettingDialog: false,
      updateOrgSettingDialog: false,
      orgList4InsertOrUpdate: [],
      loadingOrg4InsertOrUpdate: false,
      orgSettingRules: {
        orgId: [
          { required: true, message: "请选择学习中心", trigger: "blur,change" },
        ],
      },

      orgSettingsImportDialog: false,
      orgSettingsImportForm: {},
      uploadAction: null,
      uploadHeaders: {},
      uploadData: {},
      fileList: [],
      errDialog: false,
      errMessages: [],
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
    noBatchSelected() {
      return this.selectedOrgSettings.length === 0;
    },
  },
  created() {
    this.examId = this.$route.params.id;
    this.formSearch.examId = this.examId;
    this.init();
    this.uploadHeaders = {
      key: this.user.key,
      token: this.user.token,
    };
    this.uploadAction =
      EXAM_WORK_API + "/exam/importExamOrgSettings/" + this.examId;
  },
  methods: {
    batchNotLimit() {
      this.$confirm("是否开考？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let url =
          EXAM_WORK_API +
          "/exam/setOrgExamNotLimited/" +
          this.selectedOrgSettings;
        this.$httpWithMsg.put(url).then((response) => {
          console.log(response);
          this.$notify({
            type: "success",
            message: "开考成功",
          });
          this.search();
        });
      });
    },
    batchLimit() {
      this.$confirm("是否禁考？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let url =
          EXAM_WORK_API + "/exam/setOrgExamLimited/" + this.selectedOrgSettings;
        this.$httpWithMsg.put(url).then((response) => {
          console.log(response);
          this.$notify({
            type: "success",
            message: "禁考成功",
          });
          this.search();
        });
      });
    },
    batchDelete() {
      this.$confirm("删除所选学习中心特殊设置？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let url =
          EXAM_WORK_API +
          "/exam/deleteExamOrgSettings/" +
          this.selectedOrgSettings;
        this.$httpWithMsg.put(url).then((response) => {
          console.log(response);
          this.$notify({
            type: "success",
            message: "删除成功",
          });
          this.search();
        });
      });
    },
    deleteAll() {
      this.$confirm("删除所有学习中心特殊设置？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let url =
          EXAM_WORK_API + "/exam/deleteAllExamOrgSettings/" + this.examId;
        this.$httpWithMsg.put(url).then((response) => {
          console.log(response);
          this.$notify({
            type: "success",
            message: "删除成功",
          });
          this.search();
        });
      });
    },
    selectChange(row) {
      this.selectedOrgSettings = [];
      row.forEach((element, index) => {
        console.log(index);
        this.selectedOrgSettings.push(element.id);
      });
      console.log(this.selectedOrgSettings);
    },
    getOrgList4Search(name) {
      this.loadingOrg4Search = true;
      var url = CORE_API + "/org/query?" + new URLSearchParams({ name: name });
      this.$httpWithMsg
        .get(url)
        .then((response) => {
          this.orgList4Search = response.data;
          this.loadingOrg4Search = false;
        })
        .catch((response) => {
          console.log(response);
          this.loadingOrg4Search = false;
        });
    },
    getOrgList4InsertOrUpdate(name) {
      this.loadingOrg4InsertOrUpdate = true;
      var url = CORE_API + "/org/query?" + new URLSearchParams({ name: name });
      this.$httpWithMsg
        .get(url)
        .then((response) => {
          this.orgList4InsertOrUpdate = response.data;
          this.loadingOrg4InsertOrUpdate = false;
        })
        .catch((response) => {
          console.log(response);
          this.loadingOrg4InsertOrUpdate = false;
        });
    },
    updateExamLimit(row) {
      row.examLimit = !row.examLimit;
      let url = EXAM_WORK_API + "/exam/examOrgSettings";
      this.$httpWithMsg.post(url, row).then((response) => {
        row.updateTime = response.data.updateTime;
      });
    },
    updateOrgSetting() {
      if (!this.orgSettingDatetimeRange) {
        this.orgSetting.beginTime = null;
        this.orgSetting.endTime = null;
      } else {
        this.orgSetting.beginTime = this.orgSettingDatetimeRange[0];
        this.orgSetting.endTime = this.orgSettingDatetimeRange[1];
      }

      this.$refs.updateOrgSettingForm.validate((valid) => {
        if (valid) {
          let url = EXAM_WORK_API + "/exam/examOrgSettings";
          this.$httpWithMsg.post(url, this.orgSetting).then((response) => {
            console.log(response);
            this.$notify({
              type: "success",
              message: "保存成功",
            });
            this.search();
            this.updateOrgSettingDialog = false;
          });
        } else {
          return false;
        }
      });
    },
    addOrgSetting() {
      if (!this.orgSettingDatetimeRange) {
        this.orgSetting.beginTime = null;
        this.orgSetting.endTime = null;
      } else {
        this.orgSetting.beginTime = this.orgSettingDatetimeRange[0];
        this.orgSetting.endTime = this.orgSettingDatetimeRange[1];
      }
      this.$refs.addOrgSettingForm.validate((valid) => {
        if (valid) {
          let url = EXAM_WORK_API + "/exam/examOrgSettings";
          this.$httpWithMsg.put(url, this.orgSetting).then((response) => {
            console.log(response);
            this.$notify({
              type: "success",
              message: "保存成功",
            });
            this.search();
            this.addOrgSettingDialog = false;
          });
        } else {
          return false;
        }
      });
    },
    back() {
      this.$router.push({ path: "/examwork/examInfo" });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.search();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.search();
    },
    showUpdateOrgSetting(row) {
      this.orgSetting.examId = row.examId;
      this.orgSetting.beginTime = row.beginTime;
      this.orgList4InsertOrUpdate = [{ id: row.orgId, name: row.orgName }];
      this.orgSetting.orgId = row.orgId;
      this.orgSetting.endTime = row.endTime;
      this.orgSetting.examLimit = row.examLimit ? "true" : "false";
      if (row.beginTime && row.endTime) {
        this.orgSettingDatetimeRange = [row.beginTime, row.endTime];
      } else {
        this.orgSettingDatetimeRange = [];
      }
      this.updateOrgSettingDialog = true;
    },
    showAddOrgSetting() {
      this.orgSetting.examId = this.examId;
      this.orgSetting.beginTime = null;
      this.orgList4InsertOrUpdate = [];
      this.orgSetting.orgId = null;
      this.orgSetting.endTime = null;
      this.orgSettingDatetimeRange = [];
      this.addOrgSettingDialog = true;
    },
    exportOrgSettings() {
      window.open(
        EXAM_WORK_API +
          "/exam/exportExamOrgSettings/" +
          this.examId +
          "?$key=" +
          this.user.key +
          "&$token=" +
          this.user.token
      );
    },
    importOrgSettings() {
      this.removeFile();
      this.orgSettingsImportDialog = true;
    },

    uploadSuccess(response, file, fileList) {
      console.log("uploadSuccess");
      console.log(response);
      console.log(file);
      console.log(fileList);
      this.$notify({
        message: "上传成功",
        type: "success",
      });
      this.orgSettingsImportDialog = false;
      this.resetPageAndSearch();
    },
    uploadError(response, file, fileList) {
      console.log("uploadError");
      console.log(response);
      console.log(file);
      console.log(fileList);
      var json = JSON.parse(response.message);
      if (response.status == 500) {
        this.$notify({
          message: json.desc,
          type: "error",
        });
      }
    },
    //清空文件
    removeFile() {
      this.fileList = [];
      if (this.$refs.upload) {
        this.$refs.upload.clearFiles();
      }
    },
    //确定上传
    submitUpload() {
      if (!this.checkUpload()) {
        return false;
      }
      this.$refs.upload.submit();
      this.fileLoading = true;
    },
    checkUpload() {
      var fileList = this.$refs.upload.uploadFiles;
      if (fileList.length == 0) {
        this.$notify({
          message: "上传文件不能为空",
          type: "error",
        });
        return false;
      }
      if (fileList.length > 1) {
        this.$notify({
          message: "每次只能上传一个文件",
          type: "error",
        });
        return false;
      }
      for (let file of fileList) {
        if (!file.name.endsWith(".xlsx")) {
          this.$notify({
            message: "上传文件必须为xlsx格式",
            type: "error",
          });
          return false;
        }
      }
      return true;
    },
    resetPageAndSearch() {
      this.currentPage = 1;
      this.search();
    },
    search() {
      let param = new URLSearchParams(this.formSearch);
      let url =
        EXAM_WORK_API +
        "/exam/getExamOrgSettingsList/" +
        (this.currentPage - 1) +
        "/" +
        this.pageSize +
        "?" +
        param;
      this.loading4FormSearch = true;
      this.$httpWithMsg
        .get(url)
        .then((response) => {
          console.log(response);
          this.tableData = response.data.list;
          this.total = response.data.total;
          this.loading4FormSearch = false;
        })
        .catch((response) => {
          console.log(response);
          this.loading4FormSearch = false;
        });
    },
    init() {
      this.search();
    },
  },
};
</script>
<style scoped></style>
