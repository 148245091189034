<template>
  <section class="content">
    <div class="box box-info">
      <div
        v-loading.body="loading"
        v-loading.fullscreen="loading"
        class="box-body"
        element-loading-text="请稍后..."
      >
        <!-- 表单 -->
        <el-form inline>
          <el-form-item>
            <el-button
              size="small"
              type="primary"
              icon="el-icon-arrow-left"
              @click="back"
            >
              返回
            </el-button>
            <el-button
              size="small"
              type="primary"
              icon="el-icon-refresh"
              @click="searchForm"
            >
              刷新
            </el-button>
            <el-button
              size="small"
              type="primary"
              :disabled="noBatchSelected"
              @click="setRead()"
            >
              标记为已读
            </el-button>
          </el-form-item>
        </el-form>

        <div class="block-seperator"></div>
        <!-- 页面列表 -->
        <el-table
          :data="tableData"
          border
          resizable
          stripe
          style="width: 100%"
          @selection-change="selectChange"
        >
          <el-table-column type="selection" width="50"></el-table-column>
          <el-table-column width="100" label="ID">
            <span slot-scope="scope">{{ scope.row.id }}</span>
          </el-table-column>
          <el-table-column label="标题"
            ><span slot-scope="scope"
              ><router-link
                :to="'/home/site-message/' + scope.row.id"
                style="display: flex"
              >
                <img
                  :class="
                    scope.row.hasRead
                      ? 'mhome-message-read'
                      : 'mhome-message-unread'
                  "
                />
                <span v-if="scope.row.hasRecalled" class="mhome-message-title"
                  >发送者已撤回消息：</span
                >
                <span class="mhome-message-title">{{ scope.row.title }}</span>
              </router-link>
            </span></el-table-column
          >
          <el-table-column width="180" prop="publishTime" label="发送时间">
          </el-table-column>
        </el-table>
        <div class="page pull-right">
          <el-pagination v-if="paginationShow" layout="total" :total="total" />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { EXAM_WORK_API } from "@/constants/constants.js";
import { mapState } from "vuex";

export default {
  name: "Project",
  data() {
    return {
      loading: false,
      selectedIds: [],

      paginationShow: false,
      tableData: [],
      total: 10,
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
    ids() {
      var ids = "";
      for (let id of this.selectedIds) {
        if (!ids) {
          ids += id;
        } else {
          ids += "," + id;
        }
      }
      return ids;
    },
    noBatchSelected() {
      return this.selectedIds.length === 0;
    },
  },
  //初始化查询
  created() {
    this.searchForm();
  },
  methods: {
    back() {
      this.$router.push({ path: "/home/overview" });
    },
    setRead() {
      var ids = this.checkIds();
      if (!ids) return;
      var url =
        EXAM_WORK_API + "/notice/updateNoticeReadStatus?noticeId=" + ids;
      this.$httpWithMsg.post(url).then(() => {
        this.$notify({
          type: "success",
          message: "操作成功",
        });
        this.searchForm();
      });
    },
    checkIds() {
      if (this.ids.length == 0) {
        this.$notify({
          type: "warning",
          message: "请选择通知",
        });
        return "";
      } else {
        return this.ids;
      }
    },
    selectChange(row) {
      this.selectedIds = [];
      row.forEach((element) => {
        this.selectedIds.push(element.id);
      });
    },
    handleSearchBtn() {
      this.currentPage = 0;
      this.searchForm();
    },
    //查询
    searchForm() {
      this.loading = true;
      var url = EXAM_WORK_API + "/notice/getUserNoticeList";
      this.$httpWithMsg
        .get(url)
        .then((response) => {
          this.$eventHub.$emit("UnreadNoticeChange");
          this.tableData = response.data;
          this.total = response.data.length;
          this.loading = false;

          this.$nextTick(function () {
            this.paginationShow = true;
          });
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style scoped>
.page {
  margin-top: 10px;
}

.pull-length {
  width: 300px;
}

.pull-center {
  margin-top: 20px;
}

.editForm .el-form-item {
  margin-bottom: 12px;
}

.mhome-message-read {
  width: 16px;
  height: 14px;
  background-image: url(./svgs/sms-read.svg);
}

.mhome-message-unread {
  width: 16px;
  height: 14px;
  background-image: url(./svgs/sms-unread.svg);
}

.mhome-message-title {
  line-height: 14px;
  margin-left: 8px;
}
</style>
