<template>
  <div id="student_paper" class="markmain pull-left">
    <el-tabs :active-name="activeName">
      <el-tab-pane label="考生答卷" name="first">
        <div v-if="examType != 'OFFLINE'">
          <div v-if="studentPaper.id != ''" id="main-source">
            <div
              v-if="!markSign"
              class="paper"
              v-html="studentPaper.studentSubjectiveHtml"
            ></div>
            <div v-show="markSign" class="paper">
              <div class="fixed marktitle signTitle">
                <el-button-group class="itemTitle">
                  <el-button type="info" size="small">
                    <span class>当前题</span>
                  </el-button>
                  <el-button type="info" size="small">
                    <span class>{{ itemTitle }}</span>
                  </el-button>
                </el-button-group>
                <!--
                  <el-button type="primary" size="small" @click="saveMarkSign">保存轨迹</el-button>
                -->
                <el-button type="danger" size="small" @click="delMarkSign"
                  >清除轨迹</el-button
                >
                <el-button type="danger" size="small" @click="delAllMarkSign"
                  >清除全部轨迹</el-button
                >
              </div>
              <div>
                <canvas id="canvas" width="800px" height="800px"></canvas>
              </div>
            </div>
          </div>
        </div>
        <div v-if="examType == 'OFFLINE'">
          <div v-if="studentPaper.id != ''">
            <div style="float: left" v-html="getPdfUrl()"></div>
            <div
              v-if="
                studentPaper.studentSubjectiveHtml &&
                studentPaper.studentSubjectiveHtml != '' &&
                studentPaper.studentSubjectiveHtml.indexOf('.pdf') > -1
              "
              style="float: right; margin-right: 20px"
              class="no-print"
            >
              <el-button size="small" type="success" @click="pdfDown()"
                >下载</el-button
              >
            </div>
          </div>
        </div>
      </el-tab-pane>
      <template v-if="examType == 'OFFLINE'">
        <el-tab-pane label="标答" name="second">
          <div v-if="examType == 'OFFLINE'" id="answer-source">
            <div style="float: right; margin-right: 20px" class="no-print">
              <el-button size="small" type="success" @click="printAnswer()"
                >打印</el-button
              >
            </div>
            <div id="answer-content" class="paper" v-html="answerHtml"></div>
          </div>
        </el-tab-pane>
      </template>
    </el-tabs>
  </div>
</template>
<script>
import { Drawing } from "../canvas/mark_sign";
import { EVENTHUB } from "../constants/constants";
import { MARKING_API } from "@/constants/constants";
import printJS from "print-js";
import "viewerjs/dist/viewer.css";
import Viewer from "viewerjs";
import { mapState } from "vuex";
export default {
  props: {
    paperMarkSign: {
      type: Array,
      default: () => [],
    },
    studentPaper: {
      type: Object,
      default: () => {},
    },
    markSign: {
      type: Boolean,
      default: false,
    },
    signOption: {
      type: Object,
      default: () => {},
    },
    signScores: {
      type: Array,
      default: () => [],
    },
    signItem: {
      type: Object,
      default: () => {},
    },
    examType: {
      type: String,
      default: "",
    },
    answerHtml: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      activeName: "first",
      drawing: {},
      tmpSignScores: this.signScores,
      tmpMarkSign: this.markSign,
      picModelKey: Math.random(),
      picModel: false,
      picForm: {
        imgUrl: "",
        rotate: 0,
      },
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
    itemTitle() {
      if (!this.markSign) return "无";
      var title = "无";
      if (this.signItem.id) {
        title =
          Number.parseInt(this.signItem.mainNumber) +
          1 +
          "(" +
          this.signItem.orders +
          ")";
      }
      return title;
    },
    itemKey() {
      if (!this.markSign) return "";
      return this.studentPaper.id + "-" + this.signItem.id;
    },
    paperKey() {
      if (!this.markSign) return "";
      return this.studentPaper.id;
    },
    paperSigns() {
      if (!this.markSign) return [];
      var paperSigns = [];
      if (this.drawing.scoreLoc.length > 0) {
        for (let scoreLoc of this.drawing.scoreLoc) {
          paperSigns.push({
            loc: scoreLoc.loc,
            score: scoreLoc.score,
            itemId: scoreLoc.itemId,
          });
        }
      }
      return paperSigns;
    },
  },
  watch: {
    signItem(val) {
      if (this.markSign) {
        console.log("signItem", val);
        this.changeMarkSign();
      }
    },
    studentPaper() {
      this.$nextTick(() => {
        this.$emit("showAnswerChange");
      });
      if (this.markSign) {
        this.intMarkDraw();
      }
    },
    tmpSignScores(val) {
      this.$emit("changeSignScores", val);
    },
    signScores(val) {
      this.tmpSignScores = val;
    },
    markSign() {
      this.intMarkDraw();
    },
    paperSigns(val) {
      this.$emit("changePaperSign", val);
    },
  },
  mounted() {
    //this.createMarkDraw();
    window.viewPicture = this.viewPicture;
  },
  created() {
    EVENTHUB.$on("positionDiv", function (id) {
      let o = document.getElementById("student_paper");
      o.scrollTop = 0;
      var height = 0;
      let outObject = document.getElementById("anchor-" + id);
      if (outObject) {
        if (outObject.currentStyle) {
          height = parseInt(outObject.currentStyle["marginTop"]);
        } else {
          height = parseInt(
            document.defaultView.getComputedStyle(outObject, null)["marginTop"]
          );
        }
        var y = document.getElementById("anchor-" + id).offsetTop - height / 2;
        o.scrollTop = y - 5;
      }
    });
  },
  methods: {
    viewPicture(imagesClass, index) {
      const viewer = new Viewer(document.querySelector(imagesClass), {
        container: "#student_paper",
        className: "viewPictureStyle",
        backdrop: "static",
        zIndex: 99999,
        title: false,
        toolbar: {
          zoomIn: 1,
          zoomOut: 1,
          oneToOne: 1,
          reset: 1,
          prev: 1,
          play: {
            show: 0,
            size: "large",
          },
          next: 1,
          rotateLeft: 1,
          rotateRight: 1,
          flipHorizontal: 1,
          flipVertical: 1,
        },
        ready() {
          viewer.view(index);
        },
        hidden() {
          viewer.destroy();
        },
      });
      viewer.show();
    },
    getPdfUrl() {
      let url = this.studentPaper.studentSubjectiveHtml;
      let content = "";

      if (url && url.length > 1) {
        if (url.indexOf(".pdf") > -1) {
          content =
            "<embed src='" + url + "'  width='800px' height='800px'></embed>";
        } else if (url.indexOf(".zip") > -1) {
          content = "<a href='" + url + "'>下载</a>";
        } else if (/(.*)\.(jpg|gif|jpeg|png)$/.test(url)) {
          content = this.parseImgs(url);
        }
      }

      return content;
    },
    parseImgs(urls) {
      let uuid = this.generateUUID();
      let group1 = [],
        group2 = [];

      let elements = urls.split(",");
      for (let n = 0; n < elements.length; n++) {
        let url = elements[n];

        group1.push(
          "<a onclick=\"window.viewPicture('#pic-" +
            uuid +
            "','" +
            n +
            '\')" target="_blank"><img alt="加载中..." class="photo-answer altbox" src="' +
            url +
            '?x-oss-process=image/resize,m_lfit,h_200,w_200"/></a>'
        );

        group2.push('<img src="' + url + '"/>');
      }

      let html =
        '<div class="photo-answers-block">' +
        group1.join("") +
        '</div><div id="pic-' +
        uuid +
        '" style="display:none">' +
        group2.join("") +
        "</div>";
      return html;
    },
    generateUUID() {
      let date = new Date().getTime();
      return "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        let r = (date + Math.random() * 16) % 16 | 0;
        date = Math.floor(date / 16);
        return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
      });
    },
    createMarkDraw() {
      console.log("create");
      var drawing = {};
      // eslint-disable-next-line vue/no-mutating-props
      this.signOption.data = this.studentPaper.studentSubjectiveHtml;
      drawing = new Drawing("canvas", this.signOption);
      this.drawing = drawing;
    },
    intMarkDraw() {
      console.log("init");
      if (this.markSign) {
        this.clearScoreLoc();
        this.clearScores();
        this.setMarkDrawHtml();
        this.setSignLocation();
      }
    },
    setMarkDrawHtml() {
      this.drawing.setHtml(this.studentPaper.studentSubjectiveHtml);
    },
    saveMarkSign() {
      if (this.drawing.canvas && this.signItem.id) {
        localStorage.removeItem(this.paperKey);
        localStorage.setItem(this.paperKey, JSON.stringify(this.paperSigns));
        this.$notify({
          message: "轨迹保存成功",
          type: "success",
        });
      } else {
        this.$notify({
          message: "请选择给分步骤",
          type: "error",
        });
      }
    },
    setSignLocation() {
      let signStorage = localStorage.getItem(this.paperKey);
      console.log("signStorage", signStorage);
      if (typeof signStorage == "string") {
        let paperSigns = JSON.parse(signStorage);
        if (
          this.drawing.scoreLoc.length == 0 ||
          paperSigns.length > this.drawing.scoreLoc.length
        ) {
          this.drawing.setLocation(paperSigns, "localStorage");
        } else {
          this.drawing.setLocation(this.drawing.scoreLoc, "cache");
        }
      } else {
        this.drawing.setLocation(this.drawing.scoreLoc, "cache");
      }
      this.setSignItemId();
    },
    setSignItemId() {
      var signItem = Object.assign({}, this.signItem);
      this.drawing.setItemId(signItem.id);
    },
    setSignScores() {
      let signStorage = localStorage.getItem(this.paperKey);
      if (typeof signStorage == "string") {
        let paperSigns = JSON.parse(signStorage);
        if (paperSigns.length > 0) {
          for (let [index, paperSign] of paperSigns.entries()) {
            console.log("score index:", index);
            if (paperSign.itemId == this.signItem.id) {
              this.tmpSignScores.push(paperSign.score);
            }
          }
        }
      }
      console.log("signScores", this.signScores);
    },
    delMarkSign() {
      if (this.drawing.canvas) {
        this.removeItemSign();
        this.clearScores();
        this.drawing.ResetDrawAll();
        this.setSignLocation();
        this.$notify({
          message: "轨迹清除成功",
          type: "success",
        });
      }
    },
    removeItemSign() {
      let signStorage = localStorage.getItem(this.paperKey);
      if (typeof signStorage == "string") {
        let paperSigns = JSON.parse(signStorage);
        this.removeLS(paperSigns);
        this.removeCache();
      } else {
        this.removeCache();
      }
    },
    removeLS(paperSigns) {
      for (let i = paperSigns.length - 1; i >= 0; i--) {
        if (paperSigns[i].itemId == this.signItem.id) {
          paperSigns.splice(i, 1);
        }
      }
      if (paperSigns.length > 0) {
        localStorage.removeItem(this.paperKey);
        localStorage.setItem(this.paperKey, JSON.stringify(paperSigns));
      } else {
        localStorage.removeItem(this.paperKey);
        this.clearScoreLoc();
      }
    },
    removeCache() {
      for (let i = this.drawing.scoreLoc.length - 1; i >= 0; i--) {
        if (this.drawing.scoreLoc[i].itemId == this.signItem.id) {
          this.drawing.scoreLoc.splice(i, 1);
        }
      }
    },
    delAllMarkSign() {
      if (this.drawing.canvas) {
        localStorage.removeItem(this.paperKey);
        this.clearScores();
        this.clearScoreLoc();
        this.drawing.ResetDrawAll();
        this.$notify({
          message: "轨迹全部清除成功",
          type: "success",
        });
      }
    },
    changeMarkSign() {
      if (this.signItem) {
        this.drawing.ResetDrawAll();
        console.log("paperSign", localStorage.getItem(this.paperKey));
        this.setSignLocation();
        this.setSignScores();
      }
    },
    //清除分数
    clearScores() {
      this.tmpSignScores.splice(0, this.tmpSignScores.length);
      this.$emit("clearScores");
    },
    //清除分数坐标
    clearScoreLoc() {
      this.drawing.clearScoreLoc();
    },
    //打印
    printAnswer() {
      printJS({ printable: "answer-content", type: "html" });
    },
    pdfDown() {
      var fileurl = this.studentPaper.studentSubjectiveHtml;
      if (fileurl != "undefined" && fileurl != "") {
        let url =
          MARKING_API +
          "/fileDownLoad?url=" +
          fileurl +
          "&$key=" +
          this.user.key +
          "&$token=" +
          this.user.token;
        window.location.href = url;
      } else {
        this.$notify({
          message: "离线url为空",
          type: "warning",
        });
      }
    },
  },
};
</script>
<style scoped>
.markmain {
  width: 74%;
  overflow: auto;
  max-height: calc(100vh - 65px);
}
.markmain::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 8px; /*高宽分别对应横竖滚动条的尺寸*/
}
.markmain::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 5px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}
.markmain::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}
.marktitle {
  margin-right: 20px;
  margin-bottom: 20px;
}
.signTitle {
  margin-left: 35%;
}
.itemTitle {
  margin-right: 10px;
}
.titlefont {
  font-size: 15px;
}
li {
  list-style-type: none;
}
.markbutton {
  width: 40px;
  height: 40px;
}
img {
  width: 100%;
  height: 100%;
}
.paper {
  width: 90%;
  min-height: 600px;
}
.fixed {
  position: fixed;
}
.paper >>> .question-item {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 10px;
  padding: 0 10px;
}
.paper >>> .answer {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 10px;
  padding: 0 10px;
  background: rgb(250, 250, 250);
  overflow-wrap: break-word;
}
.paper >>> .right-answer {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 10px;
  padding: 0 10px;
  background: rgb(250, 250, 250);
  color: rgb(85, 191, 255);
  overflow-wrap: break-word;
}
.paper >>> .right-font {
  font-weight: bold;
  color: rgb(85, 191, 255);
}
.paper >>> .student-font {
  font-weight: bold;
  color: black;
}
.paper >>> .max-number {
  font-size: 20px;
  color: #444444;
}
.paper >>> img.photo-answer {
  width: 100px;
  padding: 10px;
  height: 100px;
}
.paper >>> .photo-answers-block {
  width: 350px !important;
}
.paper >>> .photo-answers-block a {
  cursor: pointer;
}
.img-div {
  text-align: center;
}
</style>
<style>
.viewPictureStyle {
  width: 74%;
}
.altbox {
  color: rgb(85, 191, 255);
}
</style>
