import Home from "../../portal/views/home/Home.vue";
import absent from "../views/absent.vue";
import awaitingAudit from "../views/awaitingAudit.vue";
import alreadyAudited from "../views/alreadyAudited.vue";
import reexamine from "../views/reexamine.vue";
import illegalityNameList from "../views/illegalityNameList.vue";
import illegalityType from "../views/illegalityType.vue";
import scoreDetail from "../views/scoreDetail.vue";
import examDetail from "../views/examDetail.vue";
import examScheduling from "../views/examScheduling.vue";
import examPaperDetail from "../views/examPaperDetail.vue";
import examSummary from "../views/examSummary.vue";
import exportTaskList from "../views/export_task_list.vue";
import Tips from "../../portal/views/tips/Tips.vue";
import ScoreStatistic from "../views/scoreStatistic.vue";
import ReexamineRecord from "../views/ReexamineRecord.vue";

export default [
  {
    path: "/oe/examPaperDetail/:courseId/:examRecordDataId",
    name: "examPaperDetail",
    component: examPaperDetail, //考卷详情
  },
  {
    path: "/oe", //首页
    meta: { auth: false },
    component: Home,
    children: [
      {
        path: "tips", //模块说明
        component: Tips,
      },
      {
        path: "reexamine-record",
        name: "ReexamineRecord",
        component: ReexamineRecord, //设置重考记录
      },
      {
        path: "absent",
        name: "absent",
        component: absent, //缺考登记
      },
      {
        path: "awaitingAudit",
        name: "awaitingAudit",
        component: awaitingAudit, //监考待审
      },
      {
        path: "alreadyAudited",
        name: "alreadyAudited",
        component: alreadyAudited, //监考已审
      },
      {
        path: "reexamine",
        name: "reexamine",
        component: reexamine, //重考列表
      },
      {
        path: "illegalityNameList",
        name: "illegalityNameList",
        component: illegalityNameList, //违纪名单
      },
      {
        path: "illegalityType",
        name: "illegalityType",
        component: illegalityType, //违纪类型管理
      },
      {
        path: "scoreDetail",
        name: "scoreDetail",
        component: scoreDetail, //成绩统计
      },
      {
        path: "examDetail",
        name: "examDetail",
        component: examDetail, //考试详情
      },
      {
        path: "examScheduling",
        name: "examScheduling",
        component: examScheduling, //考试进度详情
      },
      {
        path: "examSummary",
        name: "examSummary",
        component: examSummary, //考试概览
      },
      {
        path: "exportTaskList",
        name: "exportTaskList",
        component: exportTaskList, //导出任务列表
      },
      {
        path: "scoreStatistic",
        name: "scoreStatistic",
        component: ScoreStatistic,
      },
    ],
  },
];
