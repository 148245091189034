<template>
  <section class="content">
    <div
      v-loading.fullscreen="loading"
      class="box-body"
      element-loading-text="请稍后..."
    >
      <el-form ref="primaryForm" :inline="true" :model="formSearch">
        <el-row>
          <el-form-item label="名称">
            <el-input
              v-model="formSearch.propKey"
              class="input_width_lg"
              placeholder="请输入参数名称"
            />
          </el-form-item>
          <el-form-item label="描述">
            <el-input
              v-model="formSearch.description"
              class="input_width_lg"
              placeholder="请输入参数描述"
            />
          </el-form-item>
          <!-- <el-form-item label="类型">
            <el-select
              class="input"
              :remote-method="getBasicDataTypes"
              @focus="e => getBasicDataTypes(e.target.value)"
              :loading="basicDataTypeLoading"
              remote
              filterable
              clearable
              v-model="formSearch.basicDataTypeCode"
              placeholder="请选择参数类型"
            >
              <el-option
                v-for="item in basicDataTypeList4Search"
                :label="item.name"
                :value="item.code"
                :key="item.code"
              />
            </el-select>
          </el-form-item>-->
          <el-form-item label="参数值">
            <el-input
              v-model="formSearch.propValue"
              class="input_width_lg"
              placeholder="请输入参数值"
            />
          </el-form-item>

          <el-form-item>
            <el-button
              size="small"
              type="primary"
              icon="el-icon-search"
              @click="handleSearchBtn"
              >查询</el-button
            >
            <el-button
              size="small"
              icon="el-icon-refresh"
              @click="resetPrimaryForm"
              >重置</el-button
            >
            <el-button
              size="small"
              type="primary"
              icon="el-icon-plus"
              @click="insert"
              >新增</el-button
            >
          </el-form-item>
        </el-row>
      </el-form>

      <div class="block-seperator"></div>

      <span>操作：</span>
      <el-button
        size="small"
        type="danger"
        :disabled="noBatchSelected"
        icon="el-icon-delete"
        @click="deleteIds"
        >删除</el-button
      >

      <div style="width: 100%; margin-bottom: 10px"></div>

      <!-- 新增或修改弹出框 -->
      <el-dialog
        title="参数信息"
        width="700px"
        :visible.sync="sysPropDialog"
        :close-on-click-modal="false"
        @close="dialogBeforeClose"
      >
        <el-form
          ref="sysPropForm"
          :inline="true"
          inline-message
          :model="sysPropForm"
          :rules="rules"
          label-position="right"
          label-width="90px"
        >
          <el-form-item label="名称" prop="propKey">
            <el-input
              v-model="sysPropForm.propKey"
              :disabled="'modify' == operateType"
              class="pull_length"
              auto-complete="off"
              placeholder="请输入参数名称"
              maxlength="100"
            />
          </el-form-item>
          <el-form-item label="类型" prop="propValueType">
            <el-select
              v-model="sysPropForm.propValueType"
              :disabled="'modify' == operateType"
              class="pull_length"
              :remote-method="getBasicDataTypes"
              :loading="basicDataTypeLoading"
              remote
              filterable
              clearable
              placeholder="请选择参数类型"
              @focus="(e) => getBasicDataTypes(e.target.value)"
            >
              <el-option
                v-for="item in basicDataTypeList4Search"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="参数值" prop="propValue">
            <el-input
              v-model="sysPropForm.propValue"
              class="pull_length text_area"
              type="textarea"
              auto-complete="off"
              placeholder="请输入参数值"
              maxlength="1000"
            />
          </el-form-item>
          <el-form-item label="描述" prop="description">
            <el-input
              v-model="sysPropForm.description"
              type="textarea"
              class="pull_length text_area"
              auto-complete="off"
              placeholder="请输入参数描述"
              maxlength="1000"
            />
          </el-form-item>
          <el-row class="pull_center">
            <el-button type="primary" @click="submitForm('sysPropForm')"
              >保 存</el-button
            >
            <el-button @click="close">取 消</el-button>
          </el-row>
        </el-form>
      </el-dialog>

      <!-- 页面列表 -->
      <el-table
        :data="tableData"
        border
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="40"></el-table-column>
        <el-table-column prop="propKey" label="名称"></el-table-column>
        <el-table-column
          prop="propValueType"
          label="类型"
          width="100"
        ></el-table-column>
        <el-table-column prop="propValue" label="参数值"></el-table-column>
        <el-table-column prop="description" label="描述"></el-table-column>
        <el-table-column
          sortable
          prop="updateTime"
          label="更新时间"
          width="170"
        ></el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <div>
              <el-button
                size="mini"
                type="primary"
                icon="el-icon-edit"
                plain
                @click="edit(scope.row)"
                >编辑</el-button
              >
              <el-button
                size="mini"
                type="danger"
                icon="el-icon-delete"
                @click="deleteId(scope.row)"
                >删除</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="page pull-right">
        <el-pagination
          :current-page="currentPage"
          :page-size="pageSize"
          :page-sizes="[10, 20, 50, 100, 200, 300]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { CORE_API } from "@/constants/constants.js";
import { mapState } from "vuex";
import { BASIC_DATA_TYPE } from "@/constants/constants.js";

export default {
  name: "SysPropList",
  data() {
    return {
      courseLoading: false,
      formSearch: {
        propKey: "",
        propValueType: "",
        propValue: "",
        description: "",
      },
      sysPropForm: {
        propKey: "",
        propValueType: "",
        propValue: "",
        description: "",
      },
      sysPropDialog: false,
      loading: false,
      basicDataTypeLoading: false,
      operateType: "add",
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 10,
      basicDataTypeList4Search: [],
      multipleSelection: [],
      rules: {
        propKey: [
          { required: true, message: "请输入参数名称", trigger: "blur" },
        ],
        propValueType: [
          { required: true, message: "请选择参数类型", trigger: "change" },
        ],
        propValue: [
          { required: true, message: "请输入参数值", trigger: "blur" },
        ],
        description: [
          { required: true, message: "请输入参数描述", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    noBatchSelected() {
      return this.multipleSelection.length === 0;
    },
  },

  //初始化查询
  created() {
    this.searchForm();
  },
  methods: {
    dialogBeforeClose() {
      this.$refs.sysPropForm.clearValidate();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.searchForm();
    },
    resetPrimaryForm() {
      this.formSearch = {
        propKey: "",
        propValueType: "",
        propValue: "",
        description: "",
      };
      this.$refs.primaryForm.resetFields();
    },
    getBasicDataTypes() {
      this.basicDataTypeLoading = true;
      this.basicDataTypeList4Search = BASIC_DATA_TYPE;
      this.basicDataTypeLoading = false;
      // this.$httpWithMsg
      //   .get(CORE_API + "/systemProperty/getBasicDataTypes?name=" + query)
      //   .then(response => {
      //     this.basicDataTypeList4Search = response.data;
      //     this.basicDataTypeLoading = false;
      //   });
    },
    handleSearchBtn() {
      this.currentPage = 1;
      this.searchForm();
    },
    //查询
    searchForm() {
      let searchLock = true;
      setTimeout(() => {
        if (searchLock) {
          this.loading = true;
        }
      }, 500);
      var param = new URLSearchParams(this.formSearch);
      var url =
        CORE_API +
        "/systemProperty/page/" +
        (this.currentPage - 1) +
        "/" +
        this.pageSize +
        "?" +
        param;
      this.$httpWithMsg
        .get(url)
        .then((response) => {
          // console.log("response :", response);
          this.tableData = response.data.content;
          this.total = response.data.totalElements;
        })
        .finally(() => {
          searchLock = false;
          this.loading = false;
        });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.searchForm();
    },
    handleCourseCurrentChange(val) {
      this.currentCoursePage = val;
      this.searchCoursePage();
    },
    //全选
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //保存
    submitForm(formData) {
      this.$refs[formData].validate((valid) => {
        if (valid) {
          var url = CORE_API + "/systemProperty";
          //修改
          if ("modify" == this.operateType) {
            this.$httpWithMsg.put(url, this.sysPropForm).then(
              () => {
                this.$notify({
                  type: "success",
                  message: "修改成功！",
                });
                this.searchForm();
                this.sysPropDialog = false;
              },
              () => {
                this.searchForm();
                this.sysPropDialog = false;
              }
            );
          }
          //新增
          else {
            this.$httpWithMsg.post(url, this.sysPropForm).then(
              () => {
                this.$notify({
                  type: "success",
                  message: "新增成功！",
                });
                this.sysPropDialog = false;
                this.searchForm();
              },
              () => {
                this.sysPropDialog = false;
                return this.searchForm();
              }
            );
          }
        } else {
          console.log("error submit!");
          return false;
        }
      });
    },

    //重置
    resetForm(formData) {
      this.$refs[formData].resetFields();
    },
    close() {
      this.sysPropDialog = false;
    },
    //新增
    insert() {
      this.operateType = "add";
      this.sysPropForm.propKey = "";
      this.sysPropForm.propValueType = "";
      this.sysPropForm.propValue = "";
      this.sysPropForm.description = "";

      this.sysPropDialog = true;
    },
    //修改
    edit(row) {
      this.operateType = "modify";
      this.sysPropForm = Object.assign({}, row);

      this.sysPropDialog = true;
    },
    //删除
    deleteId(row) {
      this.$confirm("是否删除该条配置信息？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      }).then(() => {
        var url = CORE_API + "/systemProperty/" + row.propKey;
        this.$httpWithMsg.delete(url).then(() => {
          this.$notify({
            type: "success",
            message: "删除成功！",
          });
          this.searchForm();
        });
      });
    },
    //删除多条信息
    deleteIds() {
      if (this.multipleSelection.length === 0) {
        this.$notify({
          type: "warning",
          message: "请选择要删除的配置",
        });
      } else {
        this.$confirm("是否删除这些配置信息？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "error",
        }).then(() => {
          var ids = [];
          for (var i = 0; i < this.multipleSelection.length; i++) {
            ids.push(this.multipleSelection[i].propKey);
          }
          var url = CORE_API + "/systemProperty/" + ids;
          this.$httpWithMsg.delete(url).then(() => {
            this.$notify({
              type: "success",
              message: "删除成功！",
            });
            this.searchForm();
          });
        });
      }
    },
  },
};
</script>

<style scoped>
.page {
  margin-top: 10px;
}

.input_width_lg {
  width: 180px;
}
.pull_center {
  text-align: center;
}
.pull_length {
  width: 400px;
}
.text_area {
  margin-bottom: 10px;
}
</style>
