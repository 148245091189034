<template>
  <section class="content">
    <div class="box box-info">
      <div
        v-loading.body="fileLoading"
        v-loading.fullscreen="loading"
        element-loading-text="请稍后..."
      >
        <el-form
          ref="primaryForm"
          inline
          :model="formSearch"
          label-width="70px"
        >
          <el-form-item label="课程名称">
            <el-input
              v-model="formSearch.name"
              class="input_width_lg"
              placeholder="请输入课程名称"
            />
          </el-form-item>
          <el-form-item label="课程代码">
            <el-input
              v-model="formSearch.code"
              class="input_width_lg"
              placeholder="请输入课程代码"
            />
          </el-form-item>
          <el-form-item label="课程状态">
            <el-select
              v-model="formSearch.enable"
              class="input_width_lg"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="item in statusList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="层次">
            <el-select
              v-model="formSearch.level"
              class="input_width_lg"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="item in levelList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="专业" prop="specialtyId">
            <el-select
              v-model="formSearch.specialtyId"
              class="input_width_lg"
              remote
              :remote-method="getSpecialtyList4Search"
              :loading="specialtyLoading4Search"
              filterable
              clearable
              placeholder="请选择"
              @focus="(e) => getSpecialtyList4Search(e.target.value)"
            >
              <el-option
                v-for="item in specialtyList4SearchWrapper"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>

          <el-form-item class="d-block">
            <el-button
              size="small"
              type="primary"
              icon="el-icon-search"
              @click="handleSearchBtn"
              >查询</el-button
            >
            <el-button
              size="small"
              icon="el-icon-refresh"
              @click="resetPrimaryForm"
            >
              重置
            </el-button>
            <el-button
              size="small"
              type="primary"
              icon="el-icon-plus"
              @click="insertCourse"
              >新增</el-button
            >
          </el-form-item>
        </el-form>

        <div class="block-seperator"></div>

        <span>操作：</span>
        <el-button
          size="small"
          type="success"
          :disabled="noBatchSelected"
          icon="el-icon-check"
          @click="enableByIds"
        >
          启用
        </el-button>
        <el-button
          size="small"
          type="danger"
          :disabled="noBatchSelected"
          icon="el-icon-close"
          @click="disableByIds"
        >
          禁用
        </el-button>
        <el-button
          size="small"
          type="primary"
          icon="el-icon-upload2"
          @click="impCourse"
        >
          导入
        </el-button>
        <el-button
          size="small"
          type="primary"
          icon="el-icon-download"
          @click="exportCourse"
        >
          导出
        </el-button>

        <div style="width: 100%; margin-bottom: 10px"></div>

        <!-- 添加或新增课程弹出框 -->
        <el-dialog
          title="课程"
          width="420px"
          :visible.sync="courseDialog"
          @close="dialogBeforeClose"
        >
          <el-form
            ref="courseForm"
            :inline="true"
            inline-message
            :model="courseForm"
            :rules="rules"
            label-width="90px"
          >
            <el-row>
              <el-form-item label="课程代码" prop="code">
                <el-input
                  v-model="courseForm.code"
                  :disabled="null != courseForm.id"
                  class="pull_length"
                  auto-complete="off"
                  placeholder="请输入课程代码"
                />
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="课程名称" prop="name">
                <el-input
                  v-model="courseForm.name"
                  class="pull_length"
                  auto-complete="off"
                  placeholder="请输入课程名称"
                />
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="层次" prop="level">
                <el-select
                  v-model="courseForm.level"
                  class="pull_length"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in levelList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="课程状态" prop="enable">
                <el-radio-group v-model="courseForm.enable" class="pull_length">
                  <el-radio label="true">启用</el-radio>
                  <el-radio label="false">禁用</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-row>
            <el-row class="pull-center">
              <el-button type="primary" @click="submitForm">保 存</el-button>
              <el-button @click="courseDialog = false">取 消</el-button>
            </el-row>
          </el-form>
        </el-dialog>

        <!-- 导入弹窗 -->
        <el-dialog title="导入窗口" width="520px" :visible.sync="impDialog">
          <el-form>
            <el-row>
              <el-form-item style="margin-left: 20px">
                <el-upload
                  ref="upload"
                  class="form_left"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  :action="uploadAction"
                  :headers="uploadHeaders"
                  :data="uploadData"
                  :before-upload="beforeUpload"
                  :on-progress="uploadProgress"
                  :on-success="uploadSuccess"
                  :on-error="uploadError"
                  :file-list="fileList"
                  :auto-upload="false"
                  :multiple="false"
                >
                  <el-button
                    slot="trigger"
                    size="small"
                    type="primary"
                    icon="el-icon-search"
                  >
                    选择文件
                  </el-button>
                  &nbsp;
                  <el-button
                    size="small"
                    type="primary"
                    icon="el-icon-check"
                    @click="submitUpload"
                  >
                    确认上传
                  </el-button>
                  <el-button
                    size="small"
                    type="primary"
                    icon="el-icon-refresh"
                    @click="removeFile"
                  >
                    清空文件
                  </el-button>
                  <el-button
                    size="small"
                    type="primary"
                    icon="el-icon-download"
                    @click="exportFile"
                  >
                    下载模板
                  </el-button>
                  <div slot="tip" class="el-upload__tip">只能上传xlsx文件</div>
                </el-upload>
              </el-form-item>
            </el-row>
          </el-form>
        </el-dialog>

        <!-- 导入错误信息列表 -->
        <el-dialog title="错误提示" :visible.sync="errDialog">
          <div
            v-for="errMessage in errMessages"
            :key="errMessage.lineNum"
            class="text-danger"
          >
            第{{ errMessage.lineNum }}行：{{ errMessage.msg }}
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="errDialog = false">确定</el-button>
          </span>
        </el-dialog>

        <!-- 关联专业弹出框 -->
        <el-dialog
          :title="specialtyDialogTitle"
          :visible.sync="specialtyDialog"
          width="800px"
        >
          <!-- 表单 -->
          <el-form :inline="true" :model="specialtySearchForm">
            <el-form-item label="专业名称">
              <el-input
                v-model="specialtySearchForm.name"
                class="input_width_lg"
                placeholder="请输入专业名称"
              />
            </el-form-item>
            <el-form-item label="专业代码">
              <el-input
                v-model="specialtySearchForm.code"
                class="input_width_lg"
                placeholder="请输入专业代码"
              />
            </el-form-item>
            <el-form-item>
              <el-button
                size="small"
                type="primary"
                icon="el-icon-search"
                @click="searchSpecialtyPage"
              >
                查询
              </el-button>
              <el-button
                size="small"
                type="primary"
                icon="el-icon-plus"
                @click="addRelation"
              >
                新增
              </el-button>
            </el-form-item>
          </el-form>

          <!-- 专业列表 -->
          <el-table :data="specialtyTableData" border>
            <el-table-column prop="id" label="ID" width="100" />
            <el-table-column prop="name" label="专业名称" width="250" />
            <el-table-column prop="code" label="专业代码" />
            <el-table-column
              sortable
              prop="updateTime"
              label="更新时间"
              width="170"
            />
            <el-table-column label="操作">
              <div slot-scope="scope">
                <span>
                  <el-button
                    size="mini"
                    type="primary"
                    icon="el-icon-edit"
                    plain
                    @click="deleteRelation(scope.row)"
                  >
                    取消关联
                  </el-button>
                </span>
              </div>
            </el-table-column>
          </el-table>
          <div class="page pull-right">
            <el-pagination
              :current-page="currentSpecialtyPage"
              :page-size="10"
              :total="specialtyTotal"
              @current-change="handleSpecialtyCurrentChange"
            />
          </div>
          <div style="margin-bottom: 20px"></div>
        </el-dialog>

        <!-- 添加关联 -->
        <el-dialog
          title="添加关联专业"
          :visible.sync="addRelationDialog"
          width="400px"
          @close="() => $refs.addRelationForm.clearValidate()"
        >
          <el-form
            ref="addRelationForm"
            :inline="true"
            inline-message
            :model="addRelationForm"
            :rules="addRelationRules"
          >
            <el-row>
              <el-form-item label="专业" prop="specialtyId">
                <el-select
                  v-model="addRelationForm.specialtyId"
                  class="input"
                  style="width: 200px"
                  remote
                  :remote-method="getSpecialtyList4AddRelation"
                  :loading="specialtyLoading4AddRelation"
                  filterable
                  clearable
                  placeholder="请选择"
                  @focus="(e) => getSpecialtyList4AddRelation(e.target.value)"
                >
                  <el-option
                    v-for="item in specialtyList4AddRelationWrapper"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-row>
            <el-row class="d-flex justify-content-center">
              <el-button type="primary" @click="submitAddRelationForm">
                保 存
              </el-button>
              <el-button @click="addRelationDialog = false">取 消</el-button>
            </el-row>
          </el-form>
        </el-dialog>

        <!-- 页面列表 -->
        <!-- FIXME: element-ui style bug https://github.com/ElemeFE/element/issues/16167 -->
        <el-table
          :data="tableData"
          border
          resizable
          stripe
          style="width: 99.99% !important"
          @selection-change="selectChange"
        >
          <el-table-column type="selection" width="40" />
          <el-table-column prop="id" label="课程ID" width="80" />
          <el-table-column prop="name" label="课程名称" width="180" />
          <el-table-column prop="code" label="课程代码" />
          <el-table-column label="层次">
            <span slot-scope="scope">{{ getLevel(scope.row.level) }}</span>
          </el-table-column>
          <el-table-column width="50" label="状态">
            <span slot-scope="scope">
              <span v-if="scope.row.enable">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="启用"
                  placement="left"
                >
                  <i class="el-icon-success" style="color: green"></i>
                </el-tooltip>
              </span>
              <span v-else>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="禁用"
                  placement="left"
                >
                  <i class="el-icon-error" style="color: red"></i>
                </el-tooltip>
              </span>
            </span>
          </el-table-column>
          <el-table-column
            sortable
            prop="updateTime"
            label="更新时间"
            width="170"
          />
          <el-table-column label="操作" width="290">
            <div slot-scope="scope">
              <el-button
                size="mini"
                type="primary"
                plain
                icon="el-icon-share"
                @click="relation(scope.row)"
              >
                关联专业
              </el-button>
              <el-button
                size="mini"
                type="primary"
                plain
                @click="editCourse(scope.row)"
              >
                <i class="el-icon-edit"></i> 编辑
              </el-button>
              <el-button
                v-if="!scope.row.enable"
                size="mini"
                plain
                type="primary"
                icon="el-icon-check"
                @click="enableById(scope.row)"
              >
                启用
              </el-button>
              <el-button
                v-if="scope.row.enable"
                size="mini"
                type="danger"
                icon="el-icon-close"
                @click="disableById(scope.row)"
              >
                禁用
              </el-button>
            </div>
          </el-table-column>
        </el-table>
        <div class="page pull-right">
          <el-pagination
            :current-page="currentPage"
            :page-size="10"
            :page-sizes="[10, 20, 50, 100, 200, 300]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { CORE_API } from "@/constants/constants.js";
import { ENABLE_TYPE, LEVEL_TYPE } from "../constants/constants.js";
import { mapState } from "vuex";

export default {
  name: "Course",
  data() {
    return {
      specialtyLoading4Search: false,
      specialtyList4Search: [],
      formSearch: {
        name: "",
        code: "",
        enable: "",
        level: "",
        specialtyId: "",
      },
      courseForm: {
        id: null,
        name: "",
        code: "",
        level: "ALL",
        enable: "true",
      },
      statusList: ENABLE_TYPE,
      levelList: LEVEL_TYPE,
      selectedCourseIds: [],
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 10,
      specialtyAllList: [],
      specialtyList: [],
      selectedSpecialtyList: [],
      specialtys: [],
      specialtyAll: [],

      impDialog: false,
      uploadAction: CORE_API + "/course/import",
      uploadHeaders: {},
      uploadData: {},
      errMessages: [],
      errDialog: false,
      fileLoading: false,
      loading: false,
      fileList: [],

      courseDialog: false,
      relationDialog: false,

      rules: {
        name: [
          {
            required: true,
            message: "请输入课程名称",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "请输入课程代码",
            trigger: "blur",
          },
        ],
        level: [
          {
            required: true,
            message: "请选择层次",
            trigger: "change",
          },
        ],
        status: [
          {
            required: true,
            message: "请选择状态",
            trigger: "change",
          },
        ],
      },
      specialtyDialog: false,
      specialtyDialogTitle: null,
      specialtySearchForm: {
        courseId: null,
        code: "",
        name: "",
      },
      specialtyTableData: [],
      currentSpecialtyPage: 1,
      specialtyPageSize: 10,
      specialtyTotal: 10,

      addRelationDialog: false,
      addRelationForm: {
        specialtyId: null,
        courseId: null,
      },
      specialtyLoading4AddRelation: false,
      specialtyList4AddRelation: [],
      addRelationRules: {
        specialtyId: [
          {
            required: true,
            type: "number",
            message: "请选择专业",
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    noBatchSelected() {
      return this.selectedCourseIds.length === 0;
    },
    courseIds() {
      var courseIds = "";
      for (let courseId of this.selectedCourseIds) {
        if (!courseIds) {
          courseIds += courseId;
        } else {
          courseIds += "," + courseId;
        }
      }
      return courseIds;
    },
    specialtyAllListSelect() {
      let specialtyList = [];
      for (let specialty of this.specialtyAllList) {
        let specialtyInfo = specialty.name + "(" + specialty.code + ")";
        specialtyList.push({ id: specialty.id, specialtyInfo: specialtyInfo });
      }
      return specialtyList;
    },
    getSpecialtyAllSelect4Search() {
      let specialtyList4Search = [];
      for (let specialty of this.specialtys) {
        let specialtyInfo = specialty.name + "(" + specialty.code + ")";
        specialtyList4Search.push({
          id: specialty.id,
          specialtyInfo: specialtyInfo,
        });
      }
      return specialtyList4Search;
    },
    specialtyList4SearchWrapper() {
      var specialtyList = [];
      for (let course of this.specialtyList4Search) {
        var name = course.name + " - " + course.code;
        var id = course.id;
        specialtyList.push({ id: id, name: name });
      }
      return specialtyList;
    },
    specialtyList4AddRelationWrapper() {
      var specialtyList = [];
      for (let course of this.specialtyList4AddRelation) {
        var name = course.name + " - " + course.code;
        var id = course.id;
        specialtyList.push({ id: id, name: name });
      }
      return specialtyList;
    },
  },
  //初始化查询
  created() {
    this.searchForm();
    this.uploadHeaders = {
      key: this.user.key,
      token: this.user.token,
    };
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.searchForm();
    },
    dialogBeforeClose() {
      this.$refs.courseForm.clearValidate();
    },
    deleteRelation(row) {
      var specialtyId = row.id;
      var courseId = this.specialtySearchForm.courseId;
      var param = new URLSearchParams({
        courseId: courseId,
        specialtyId: specialtyId,
      });
      var url = CORE_API + "/courseSpeciatlyRelation/delete?" + param;
      this.$httpWithMsg.delete(url).then(() => {
        this.$notify({
          type: "success",
          message: "取消关联成功！",
        });
        this.searchSpecialtyPage();
      });
    },
    submitAddRelationForm() {
      this.$refs.addRelationForm.validate((valid) => {
        if (valid) {
          var param = new URLSearchParams(this.addRelationForm);
          var url = CORE_API + "/courseSpeciatlyRelation/add?" + param;
          this.$httpWithMsg.post(url, this.speciallyForm).then(() => {
            this.$notify({
              type: "success",
              message: "添加成功！",
            });
            this.searchSpecialtyPage();
            this.addRelationDialog = false;
          });
        } else {
          console.log("error submit!");
          return false;
        }
      });
    },
    getSpecialtyList4AddRelation(query) {
      this.specialtyLoading4AddRelation = true;
      this.$httpWithMsg
        .get(CORE_API + "/specialty/query?name=" + query)
        .then((response) => {
          this.specialtyList4AddRelation = response.data;
          this.specialtyLoading4AddRelation = false;
        });
    },
    addRelation() {
      this.addRelationForm.specialtyId = null;
      this.addRelationForm.courseId = this.specialtySearchForm.courseId;
      this.addRelationDialog = true;
      if (this.$refs.addRelationForm) {
        this.$refs.addRelationForm.resetFields();
      }
    },
    handleSpecialtyCurrentChange(val) {
      this.currentSpecialtyPage = val;
      this.searchSpecialtyPage();
    },
    searchSpecialtyPage() {
      var param = new URLSearchParams(this.specialtySearchForm);
      var url =
        CORE_API +
        "/specialty/specialtyPage/" +
        (this.currentSpecialtyPage - 1) +
        "/" +
        this.pageSize +
        "?" +
        param;
      this.$httpWithMsg
        .get(url)
        .then((response) => {
          this.specialtyTableData = response.data.content;
          this.specialtyTotal = response.data.totalElements;
        })
        .then(() => {
          if (this.specialtyTableData.length === 0 && this.specialtyTotal > 0) {
            this.currentSpecialtyPage = this.currentSpecialtyPage - 1;
            this.searchSpecialtyPage();
          }
        });
    },
    getTag(status) {
      if (status == true) {
        return "success";
      } else if (status == false) {
        return "danger";
      }
      return status;
    },
    getLevel(level) {
      if (level == "ZSB") {
        return "专升本";
      } else if (level == "GQZ") {
        return "高起专";
      } else if (level == "GQB") {
        return "高起本";
      } else {
        return "不限";
      }
    },
    getSpecialtyList4Search(query) {
      this.specialtyLoading4Search = true;
      this.$httpWithMsg
        .get(CORE_API + "/specialty/query?name=" + query)
        .then((response) => {
          this.specialtyList4Search = response.data;
          this.specialtyLoading4Search = false;
        });
    },
    handleSearchBtn() {
      this.currentPage = 1;
      this.searchForm();
    },
    searchForm() {
      let searchLock = true;
      setTimeout(() => {
        if (searchLock) {
          this.loading = true;
        }
      }, 500);
      var param = new URLSearchParams(this.formSearch);
      var url =
        CORE_API +
        "/course/coursePage/" +
        (this.currentPage - 1) +
        "/" +
        this.pageSize +
        "?" +
        param;
      this.$httpWithMsg
        .get(url)
        .then((response) => {
          this.tableData = response.data.content;
          this.total = response.data.totalElements;
        })
        .finally(() => {
          searchLock = false;
          this.loading = false;
        });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.searchForm();
    },
    selectChange(row) {
      this.selectedCourseIds = [];
      row.forEach((element) => {
        this.selectedCourseIds.push(element.id);
      });
      console.log(this.selectedCourseIds);
    },
    //新增
    insertCourse() {
      this.courseForm.id = null;
      this.courseForm.name = null;
      this.courseForm.code = null;
      this.courseForm.level = "ALL";
      this.courseForm.enable = "true";

      this.courseDialog = true;
    },
    //修改
    editCourse(row) {
      this.courseForm = Object.assign({}, row);
      this.courseForm.enable = row.enable ? "true" : "false";
      this.courseId = row.id;

      this.courseDialog = true;
    },
    exportCourse() {
      var param = new URLSearchParams(this.formSearch);
      window.open(
        CORE_API +
          "/course/export?$key=" +
          this.user.key +
          "&$token=" +
          this.user.token +
          "&" +
          param
      );
    },

    //关联课程
    relation(row) {
      this.specialtyDialogTitle =
        "关联专业列表 【课程名称：" +
        row.name +
        "】【       课程代码：" +
        row.code +
        "】";
      this.specialtySearchForm.courseId = row.id;
      this.specialtySearchForm.name = "";
      this.specialtySearchForm.code = "";

      this.searchSpecialtyPage();
      this.specialtyDialog = true;
    },
    closeCourse() {
      this.courseDialog = false;
    },
    saveRelation() {},
    //保存(新增/修改)
    submitForm() {
      var url = CORE_API + "/course";
      if (null != this.courseForm.id) {
        //修改
        this.$refs.courseForm.validate((valid) => {
          if (valid) {
            this.$httpWithMsg.put(url, this.courseForm).then(() => {
              this.$notify({
                type: "success",
                message: "修改成功！",
              });
              this.searchForm();
              this.resetForm();
              this.courseDialog = false;
            });
          } else {
            console.log("error submit!");
            return false;
          }
        });
      } else {
        this.$refs.courseForm.validate((valid) => {
          if (valid) {
            this.$httpWithMsg.post(url, this.courseForm).then(() => {
              this.$notify({
                type: "success",
                message: "添加成功",
              });
              this.searchForm();
              this.resetForm();
              this.courseDialog = false;
            });
          } else {
            console.log("error submit!");
            return false;
          }
        });
      }
    },
    //重置
    resetForm() {
      this.$refs.courseForm.resetFields();
    },
    resetPrimaryForm() {
      this.formSearch = {
        name: "",
        code: "",
        enable: "",
        level: "",
        specialtyId: "",
      };
      this.$refs.primaryForm.resetFields();
    },
    //删除单个数据
    deleteById(row) {
      this.$confirm("是否删除该课程？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        var url = CORE_API + "/course/" + row.id;
        this.$httpWithMsg.delete(url).then(() => {
          this.$notify({
            type: "success",
            message: "删除成功！",
          });
          this.searchForm();
        });
      });
    },
    //删除多条数据
    deleteByIds() {
      if (this.selectedCourseIds.length === 0) {
        this.$notify({
          type: "warning",
          message: "请选择要删除的课程",
        });
      } else {
        this.$confirm("是否删除这些课程？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "error",
        }).then(() => {
          var url = CORE_API + "/course/" + this.courseIds;
          this.$httpWithMsg.delete(url).then(() => {
            this.$notify({
              type: "success",
              message: "删除成功！",
            });
            this.searchForm();
          });
        });
      }
    },
    //启用
    enableByIds() {
      if (this.selectedCourseIds.length === 0) {
        this.$notify({
          type: "warning",
          message: "请选择要启用的课程",
        });
      } else {
        this.$confirm("是否启用这些课程？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          var url = CORE_API + "/course/enable/" + this.courseIds;
          this.$httpWithMsg.put(url, {}).then(() => {
            this.$notify({
              type: "success",
              message: "启用成功！",
            });
            this.searchForm();
          });
        });
      }
    },
    enableById(row) {
      this.$confirm("是否启用该课程？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        var url = CORE_API + "/course/enable/" + row.id;
        this.$httpWithMsg.put(url, {}).then(() => {
          this.$notify({
            type: "success",
            message: "启用成功！",
          });
          this.searchForm();
        });
      });
    },
    //禁用
    disableByIds() {
      if (this.selectedCourseIds.length === 0) {
        this.$notify({
          type: "warning",
          message: "请选择要禁用的课程",
        });
      } else {
        this.$confirm("是否禁用这些课程？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "error",
        }).then(() => {
          var url = CORE_API + "/course/disable/" + this.courseIds;
          this.$httpWithMsg.put(url, {}).then(() => {
            this.$notify({
              type: "success",
              message: "禁用成功！",
            });
            this.searchForm();
          });
        });
      }
    },
    //禁用
    disableById(row) {
      this.$confirm("是否禁用该课程？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      }).then(() => {
        var url = CORE_API + "/course/disable/" + row.id;
        return this.$httpWithMsg.put(url, {}).then(() => {
          this.$notify({
            type: "success",
            message: "禁用成功！",
          });
          return this.searchForm();
        });
      });
    },
    //导入
    impCourse() {
      this.impDialog = true;
      this.initUpload();
    },
    initUpload() {
      this.fileList = [];
    },
    beforeUpload(file) {
      console.log(file);
    },
    uploadProgress() {
      console.log("uploadProgress");
    },
    uploadSuccess(response) {
      if (!response.hasError) {
        this.$notify({
          message: "上传成功",
          type: "success",
        });
        this.fileLoading = false;
        this.impDialog = false;
        this.searchForm();
      } else {
        this.fileLoading = false;
        this.impDialog = false;
        this.errMessages = response.failRecords;
        this.errDialog = true;
      }
    },
    uploadError(response) {
      var json = JSON.parse(response.message);
      if (response.status == 500) {
        this.$notify({
          message: json.desc,
          type: "error",
        });
      }
      this.fileLoading = false;
    },
    //确定上传
    submitUpload() {
      if (!this.checkUpload()) {
        return false;
      }
      this.$refs.upload.submit();
      this.fileLoading = true;
    },
    checkUpload() {
      var fileList = this.$refs.upload.uploadFiles;
      if (fileList.length == 0) {
        this.$notify({
          message: "上传文件不能为空",
          type: "error",
        });
        return false;
      }
      if (fileList.length > 1) {
        this.$notify({
          message: "每次只能上传一个文件",
          type: "error",
        });
        return false;
      }
      for (let file of fileList) {
        if (!file.name.endsWith(".xlsx")) {
          this.$notify({
            message: "上传文件必须为xlsx格式",
            type: "error",
          });
          this.initUpload();
          return false;
        }
      }
      return true;
    },
    //清空文件
    removeFile() {
      // this.fileList = [];
      this.$refs.upload.clearFiles();
    },
    //下载模板
    exportFile() {
      window.location.href =
        CORE_API +
        "/course/importTemplate?$key=" +
        this.user.key +
        "&$token=" +
        this.user.token;
    },
  },
};
</script>

<style scoped>
.page {
  margin-top: 10px;
}

.input_width_lg {
  width: 180px;
}

.pull_length {
  width: 180px;
}

.pull-center {
  margin-left: 30%;
}
</style>
