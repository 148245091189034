export const UPDATE_CURRENT_PATHS = "UPDATE_CURRENT_PATHS";

export default {
  state: [],
  mutations: {
    [UPDATE_CURRENT_PATHS](state, paths) {
      // console.log(state, paths);
      // state = paths;
      // Object.assign(state, paths);
      state.length = 0;
      state.push(...paths);
    },
  },
};
