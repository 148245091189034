<template>
  <section class="content">
    <div class="box box-info">
      <div
        v-loading.body="loading"
        v-loading.fullscreen="loading"
        class="box-body"
        element-loading-text="请稍后..."
      >
        <!-- 表单 -->
        <el-form inline :model="formSearch">
          <el-form-item label="学生ID">
            <el-input
              v-model="formSearch.studentId"
              placeholder="学生ID"
            ></el-input>
          </el-form-item>
          <el-form-item label="身份证号">
            <el-input
              v-model="formSearch.identityNumber"
              placeholder="身份证号"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              size="small"
              type="primary"
              icon="el-icon-search"
              @click="handleSearchBtn"
              >查询</el-button
            >
            <el-button
              size="small"
              type="primary"
              icon="el-icon-arrow-left"
              @click="back"
              >返回</el-button
            >
          </el-form-item>
        </el-form>

        <div class="block-seperator"></div>
        <!-- 页面列表 -->
        <el-table :data="tableData" border resizable stripe style="width: 100%">
          <el-table-column
            prop="studentId"
            width="180"
            label="学生ID"
          ></el-table-column>
          <el-table-column
            prop="identityNumber"
            label="学生身份证"
          ></el-table-column>
          <el-table-column
            width="180"
            prop="beginTime"
            label="考试开始时间"
          ></el-table-column>
          <el-table-column
            width="180"
            prop="endTime"
            label="考试结束时间"
          ></el-table-column>
          <el-table-column width="120" label="是否禁止考试">
            <template slot-scope="scope">
              <div>
                <span>{{ getYesNo(scope.row.examLimit) }}</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="page pull-right">
          <el-pagination
            v-if="paginationShow"
            :current-page="currentPage"
            :page-size="pageSize"
            :page-sizes="[10, 20, 50, 100, 200, 300]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { EXAM_WORK_API } from "@/constants/constants.js";
import { mapState } from "vuex";

export default {
  name: "StudentSpecialSettings",
  data() {
    return {
      loading: false,
      paginationShow: false,
      yesNoList: [
        {
          value: true,
          label: "是",
        },
        {
          value: false,
          label: "否",
        },
      ],
      formSearch: {
        examId: null,
        studentId: null,
        identityNumber: null,
      },
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 10,
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
    isSuperAdmin() {
      return this.user.roleList.some((role) => role.roleCode == "SUPER_ADMIN");
    },
  },
  //初始化查询
  created() {
    this.formSearch.examId = this.$route.params.id;
    this.searchForm();
  },
  methods: {
    getYesNo(val) {
      for (let temv of this.yesNoList) {
        if (temv.value == val) {
          return temv.label;
        }
      }
    },
    back() {
      this.$router.push({ path: "/examwork/examInfo" });
    },
    handleSearchBtn() {
      this.currentPage = 1;
      this.searchForm();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.searchForm();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.searchForm();
    },
    //查询
    searchForm() {
      let regx = /^\d*$/;
      if (this.formSearch.studentId && !regx.test(this.formSearch.studentId)) {
        this.$notify({
          title: "警告",
          message: "学生ID只能输入数字",
          type: "warning",
        });
        return false;
      }
      this.loading = true;
      let temParams = Object.assign({}, this.formSearch);
      if (!temParams.identityNumber) {
        delete temParams.identityNumber;
      }
      var url =
        EXAM_WORK_API +
        "/exam/getStudentSpecialSettingsList/" +
        (this.currentPage - 1) +
        "/" +
        this.pageSize;
      this.$httpWithMsg
        .get(url, { params: temParams })
        .then((response) => {
          this.tableData = response.data.list;
          this.total = response.data.total;
          this.loading = false;

          this.$nextTick(function () {
            this.paginationShow = true;
          });
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style scoped>
.page {
  margin-top: 10px;
}
.pull-length {
  width: 300px;
}
.pull-center {
  margin-top: 20px;
}
.editForm .el-form-item {
  margin-bottom: 12px;
}
</style>
