import { render, staticRenderFns } from "./TpMain.vue?vue&type=template&id=44cc1642&scoped=true"
import script from "./TpMain.vue?vue&type=script&lang=js"
export * from "./TpMain.vue?vue&type=script&lang=js"
import style0 from "./TpMain.vue?vue&type=style&index=0&id=44cc1642&prod&scoped=true&lang=css"
import style1 from "./TpMain.vue?vue&type=style&index=1&id=44cc1642&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44cc1642",
  null
  
)

export default component.exports