<template>
  <el-container>
    <el-main class="el-main-padding">
      <commonFormVue
        :form="form"
        :get-exam-condition="getExamCondition"
      ></commonFormVue>
      <el-col :span="6">
        <el-button
          size="small"
          icon="el-icon-search"
          type="primary"
          @click="search('clickSelectBtn')"
          >查询</el-button
        >
        <el-button size="small" icon="el-icon-refresh" @click="resetForm"
          >重置</el-button
        >
      </el-col>
      <el-row>
        <el-col v-show="currentPagePrivileges.ABSENT_EXPORT">
          <div class="block-seperator"></div>
          <span>操作：</span>
          <commonExportVue
            :form="form"
            :export-url="exportUrl"
            :export-file-name="exportFileName"
          ></commonExportVue>
        </el-col>
      </el-row>
      <el-row class="margin-top-10">
        <el-col :span="24">
          <el-table
            v-loading="tableLoading"
            element-loading-text="数据加载中"
            :data="tableData"
            border
          >
            <el-table-column
              sortable
              label="姓名"
              prop="studentName"
            ></el-table-column>
            <el-table-column
              sortable
              label="身份证号"
              prop="identityNumber"
            ></el-table-column>
            <el-table-column
              sortable
              label="学号"
              prop="studentCode"
            ></el-table-column>
            <el-table-column
              sortable
              label="学习中心"
              prop="orgName"
            ></el-table-column>
            <el-table-column
              sortable
              label="课程"
              prop="courseName"
            ></el-table-column>
            <el-table-column
              sortable
              label="课程层次"
              prop="courseLevel"
            ></el-table-column>
            <el-table-column label="场次" width="120">
              <template slot-scope="scope">
                <el-popover trigger="hover" placement="left">
                  <div style="font-size: 18px; font-family: 新宋体">
                    <tr>
                      <td style="color: green">场次序号</td>
                      <td style="color: purple; padding-left: 20px">
                        {{ scope.row.examStageOrder }}
                      </td>
                    </tr>
                    <tr>
                      <td style="color: green">开始时间</td>
                      <td style="color: purple; padding-left: 20px">
                        {{ scope.row.startTime }}
                      </td>
                    </tr>
                    <tr>
                      <td style="color: green">结束时间</td>
                      <td style="color: purple; padding-left: 20px">
                        {{ scope.row.endTime }}
                      </td>
                    </tr>
                  </div>

                  <div slot="reference" class="name-wrapper">
                    <span>{{ scope.row.examStageOrder }}</span>
                  </div>
                </el-popover>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <div class="page pull-right">
        <el-pagination
          :current-page.sync="form.pageNo"
          :page-sizes="[10, 20, 50, 100, 200, 300]"
          :page-size="form.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </el-main>
  </el-container>
</template>
<script>
import { mapState } from "vuex";
import commonFormVue from "../component/commonForm.vue";
import commonExportVue from "../component/commonExport.vue";
import pagePrivilege from "../mixin/pagePrivilege.js";
export default {
  components: { commonFormVue, commonExportVue },
  mixins: [pagePrivilege],
  data() {
    return {
      total: 0,
      tableLoading: false,
      exportLoading: false,
      form: {
        examRecordDataId: null,
        courseId: null,
        courseLevel: null,
        examId: null,
        examStageId: null,
        identityNumber: null,
        orgId: null,
        studentCode: null,
        studentName: null,
        pageNo: 1,
        pageSize: 10,
        rootOrgId: "",
        auditStatus: null,
        ORG_FIND_ALL: false, //查询所有机构l
      },
      getExamCondition: {
        params: { name: "", examTypes: "ONLINE#OFFLINE#ONLINE_HOMEWORK" },
        filterCondition: "OVERDUE",
      },
      tableData: [],
      exportUrl: "/api/ecs_oe_admin/exam/student/unfinished/list/export",
      exportFileName: "缺考登记",
      currentPagePrivileges: { ABSENT_EXPORT: false },
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
  },
  created() {},
  methods: {
    resetForm() {
      this.form = {
        examRecordDataId: null,
        courseId: null,
        courseLevel: null,
        examId: null,
        examStageId: null,
        identityNumber: null,
        orgId: this.form.ORG_FIND_ALL ? null : this.form.orgId,
        studentCode: null,
        studentName: null,
        pageNo: 1,
        pageSize: 10,
        rootOrgId: "",
        auditStatus: null,
        ORG_FIND_ALL: this.form.ORG_FIND_ALL,
      };
    },
    search(type) {
      if (!this.form.examId) {
        this.$notify({
          title: "警告",
          message: "请选择考试",
          type: "warning",
          duration: 1000,
        });
        return false;
      }
      if (type && type == "clickSelectBtn") {
        this.form.pageNo = 1;
      }
      this.tableLoading = true;
      this.$http
        .post("/api/ecs_oe_admin/exam/student/unfinished/list", this.form)
        .then((response) => {
          if (response.data) {
            this.tableData = response.data.content;
            this.total = response.data.totalElements;
          } else {
            this.tableData = [];
          }
          this.tableLoading = false;
        });
    },
    /**
     * pagesize改变时触发
     */
    handleSizeChange(val) {
      this.form.pageSize = val;
      this.search();
    },
    /**
     * 当前页改变时触发
     */
    handleCurrentChange() {
      this.search();
    },
  },
};
</script>
<style scoped src="../style/common.css"></style>
