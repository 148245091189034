import "../modules/questions/filters/filters";
import "../modules/marking/filters/filters";
import Vue from "vue";
import { LEVEL_TYPE_SELECT } from "@/constants/constants";

// 课程层次过滤器
Vue.filter("levelTypeFilter", function (val) {
  for (let examType of LEVEL_TYPE_SELECT) {
    if (examType.code === val) {
      return examType.name;
    }
  }
});
