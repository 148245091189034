/* 是否为数组 */
export function checkArray(value) {
  if (!value) {
    return false;
  }
  if (typeof Array.isArray === "function") {
    /* 兼容IE */
    return Array.isArray(value);
  } else {
    return Object.prototype.toString.call(value) === "[object Array]";
  }
}

/* 是否为整数数字(并包括0) */
export function checkEmptyNumber(number) {
  //console.log("number:" + number);
  let reg = /^[0-9]+$/;
  if (!reg.test(number)) {
    return true;
  }
  return false;
}

/* 是否为空字符串(包含数字0时则返回true) */
export function checkEmptyStr(str) {
  //console.log("str:" + str);
  if (str == undefined || String(str).length < 1) {
    return true;
  }
  return false;
}

/* 将GMT日期字符串格式化为：yyyy-MM-dd HH:mm:ss */
export function formatGMT(dateStr) {
  //console.log("dateStr:" + dateStr);
  if (!dateStr) {
    return "";
  }
  let date = new Date(dateStr);
  let year = date.getFullYear();
  let month = fillZero(date.getMonth() + 1);
  let day = fillZero(date.getDate());
  let hour = fillZero(date.getHours());
  let minute = fillZero(date.getMinutes());
  let second = fillZero(date.getSeconds());
  return (
    year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second
  );
}

/* 个位数则填充零 */
function fillZero(num) {
  if (num < 10) {
    return "0" + num;
  }
  return num;
}
