<template>
  <section class="content">
    <div class="box box-info">
      <!-- 正文信息 -->
      <div class="box-body">
        <el-form
          :inline="true"
          :model="formSearch"
          label-position="right"
          label-width="70px"
        >
          <el-form-item label="考试名称">
            <el-input
              v-model="formSearch.name"
              auto-complete="off"
              class="input"
            ></el-input>
          </el-form-item>
          <el-form-item label="类型">
            <el-select
              v-model="formSearch.examType"
              placeholder="请选择"
              clearable
              class="input"
            >
              <el-option
                v-for="item in examTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="状态">
            <el-select
              v-model="formSearch.enable"
              clearable
              placeholder="请选择"
              class="input"
              style="width: 100px"
            >
              <el-option label="已启用" value="true"></el-option>
              <el-option label="已禁用" value="false"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="归档状态">
            <el-select
              v-model="formSearch.archived"
              clearable
              filterable
              class="input"
              placeholder="请选择"
            >
              <el-option label="已归档" value="true"></el-option>
              <el-option label="未归档" value="false"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="d-block">
            <el-button
              v-if="rolePrivileges.search_exam"
              size="small"
              type="primary"
              icon="el-icon-search"
              @click="resetPageAndSearchForm"
              >查询</el-button
            >
            <el-button
              v-if="rolePrivileges.add_exam"
              size="small"
              type="primary"
              icon="el-icon-plus"
              @click="addExamInfoDialog"
              >新增</el-button
            >
            <el-button
              size="small"
              type="primary"
              icon="el-icon-document"
              @click="toCopyExam()"
              >复制</el-button
            >
          </el-form-item>
        </el-form>

        <!-- 弹出窗口 -->
        <el-dialog
          title="请选择考试类型"
          width="750px"
          :visible.sync="examInfoDialog"
        >
          <div>
            <el-button @click="toTradition">传统考试</el-button>
            <el-button @click="toOnline">网络考试</el-button>
            <el-button @click="toPractice">练习考试</el-button>
            <el-button @click="toOffline">离线考试</el-button>
            <el-button @click="toOnlineHomework">在线作业</el-button>
            <el-button @click="toPrint">分布式印刷考试</el-button>
          </div>
          <div style="margin-top: 10px; text-align: center">
            <el-button type="primary" @click="examInfoDialog = false"
              >取 消</el-button
            >
          </div>
        </el-dialog>

        <!-- 页面列表 -->
        <el-table
          v-loading="loading"
          element-loading-text="拼命加载中"
          :data="tableData"
          border
          style="width: 100%; text-align: center"
          @selection-change="selectChange"
        >
          <el-table-column type="selection" width="40"></el-table-column>
          <el-table-column
            prop="id"
            width="60"
            label="ID"
            sortable
          ></el-table-column>
          <el-table-column
            prop="code"
            width="130"
            label="考试编码"
          ></el-table-column>
          <el-table-column
            prop="name"
            width="130"
            label="考试名称"
          ></el-table-column>
          <el-table-column width="130" label="考试类型" sortable>
            <template slot-scope="scope">
              <div>
                <span>{{ getExamType(scope.row.examType) }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="beginTime"
            width="155"
            label="开始时间"
            sortable
          ></el-table-column>
          <el-table-column
            prop="endTime"
            width="155"
            label="结束时间"
            sortable
          ></el-table-column>
          <el-table-column
            prop="updateTime"
            width="155"
            label="更新时间"
            sortable
          ></el-table-column>
          <el-table-column width="50" label="状态">
            <span slot-scope="scope">
              <span v-if="scope.row.enable">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="启用"
                  placement="left"
                >
                  <i class="el-icon-success" style="color: green"></i>
                </el-tooltip>
              </span>
              <span v-else>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="禁用"
                  placement="left"
                >
                  <i class="el-icon-error" style="color: red"></i>
                </el-tooltip>
              </span>
            </span>
          </el-table-column>
          <el-table-column label="是否归档" width="90">
            <template slot-scope="scope">
              <div>
                <span>{{ getArchivedText(scope.row.archived) }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="190" fixed="right">
            <template v-if="!scope.row.archived" slot-scope="scope">
              <div>
                <el-button
                  v-if="rolePrivileges.update_exam"
                  size="mini"
                  plain
                  type="primary"
                  icon="el-icon-edit"
                  @click="editExamInfoDialog(scope.row)"
                  >编辑</el-button
                >

                <el-dropdown
                  v-if="
                    rolePrivileges.change_exam_availability ||
                    rolePrivileges.update_exam
                  "
                  style="margin-left: 10px"
                >
                  <el-button type="primary" plain size="mini">
                    更多
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>
                      <el-button
                        v-if="
                          !scope.row.enable &&
                          rolePrivileges.change_exam_availability
                        "
                        size="mini"
                        plain
                        type="primary"
                        icon="el-icon-check"
                        @click="enableById(scope.row)"
                      >
                        <i class="fa fa-check" aria-hidden="true"></i>启用
                      </el-button>
                      <el-button
                        v-else-if="rolePrivileges.change_exam_availability"
                        size="mini"
                        type="danger"
                        icon="el-icon-close"
                        @click="disableById(scope.row)"
                      >
                        <i class="fa fa-close" aria-hidden="true"></i>禁用
                      </el-button>
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <el-button
                        :disabled="
                          !(
                            rolePrivileges.update_exam &&
                            (scope.row.examType == 'OFFLINE' ||
                              scope.row.examType == 'ONLINE' ||
                              scope.row.examType == 'ONLINE_HOMEWORK') &&
                            scope.row.specialSettingsEnabled &&
                            scope.row.specialSettingsType == 'ORG_BASED'
                          )
                        "
                        size="mini"
                        type="primary"
                        icon="el-icon-edit"
                        @click="editOrgSettings(scope.row)"
                        >学习中心设置</el-button
                      >
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <el-button
                        :disabled="
                          !(
                            rolePrivileges.update_exam &&
                            (scope.row.examType == 'ONLINE' ||
                              scope.row.examType == 'ONLINE_HOMEWORK') &&
                            scope.row.specialSettingsEnabled &&
                            scope.row.specialSettingsType == 'STAGE_BASED'
                          )
                        "
                        size="mini"
                        type="primary"
                        icon="el-icon-edit"
                        @click="editStageSettings(scope.row)"
                        >场次特殊设置</el-button
                      >
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <el-button
                        :disabled="
                          !(
                            scope.row.properties &&
                            scope.row.properties.WEIXIN_ANSWER_ENABLED ===
                              'true'
                          )
                        "
                        size="mini"
                        type="primary"
                        icon="el-icon-edit"
                        @click="weixinAnswerSetting(scope.row)"
                        >微信小程序作答设置</el-button
                      >
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <el-button
                        :disabled="!scope.row.ipLimitSettingsEnabled"
                        size="mini"
                        type="primary"
                        icon="el-icon-edit"
                        @click="editIpSettings(scope.row)"
                        >IP访问限制</el-button
                      >
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <el-button
                        size="mini"
                        type="primary"
                        icon="el-icon-edit"
                        :disabled="scope.row.examType !== 'ONLINE'"
                        @click="skipFaceSetting(scope.row)"
                        >人脸白名单</el-button
                      >
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="page pull-right">
          <el-pagination
            v-if="paginationShow"
            :current-page="currentPage"
            :page-size="pageSize"
            :page-sizes="[10, 20, 50, 100, 200, 300]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          ></el-pagination>
        </div>

        <!-- 考试复制弹窗 -->
        <el-dialog
          :title="examCopyTitle"
          width="500px"
          :visible.sync="copyExamDialog"
          @close="doBeforedialogClose"
        >
          <el-form
            ref="examCopyForm"
            :inline="true"
            inline-message
            :model="examCopyForm"
            :rules="examCopyFormRules"
            label-width="120px"
          >
            <el-row>
              <el-form-item label="考试编码" placeholder>
                <el-input
                  v-model="examCopyForm.destExamName"
                  class="input"
                  :disabled="true"
                  maxlength="20"
                ></el-input>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item
                label="考试名称"
                placeholder="请输入考试名称"
                prop="destExamName"
              >
                <el-input
                  v-model="examCopyForm.destExamName"
                  class="input"
                  maxlength="20"
                ></el-input>
              </el-form-item>
            </el-row>

            <div style="text-align: center">
              <el-button type="primary" @click="copyExam">确 定</el-button>
              <el-button @click="copyExamDialog = false">取 消</el-button>
            </div>
          </el-form>
        </el-dialog>
      </div>
    </div>
  </section>
</template>
<script>
import { CORE_API, EXAM_WORK_API, EXAM_TYPE } from "@/constants/constants.js";
import { mapState } from "vuex";

let _this = null;

let validateDestExamName = (rule, value, callback) => {
  let name = _this.examCopyForm.destExamName;
  if (null == name || name.trim() == "") {
    callback(new Error("请输入考试名称"));
  } else {
    callback();
  }
};

export default {
  data() {
    return {
      rolePrivileges: {
        search_exam: false,
        add_exam: false,
        del_exam: false,
        update_exam: false,
        change_exam_availability: false,
        exam_course_setting: false,
        exam_org_setting: false,
      },
      formSearch: {
        name: "",
        examType: "",
        enable: "true",
        archived: "",
      },
      loading: false,
      examTypeList: EXAM_TYPE,
      tableData: [],
      paginationShow: false,
      currentPage: 1,
      pageSize: 10,
      total: 10,
      formLabelWidth: "120px",
      examInfoDialog: false,
      examId: "",
      selectedExamIds: [],
      button: {},

      copyExamDialog: false,
      examCopyTitle: null,
      examCopyForm: {
        srcExamId: null,
        destExamCode: "",
        destExamName: "",
      },
      examCopyFormRules: {
        destExamName: [
          { required: true, validator: validateDestExamName, trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
    examIds() {
      var examIds = "";
      for (let examId of this.selectedExamIds) {
        if (!examIds) {
          examIds += examId;
        } else {
          examIds += "," + examId;
        }
      }
      return examIds;
    },
  },
  //初始化查询
  created() {
    _this = this;

    let sessionData = sessionStorage.getItem("E_EAXM_SEARCH_PARAMS");
    let pageSize = sessionStorage.getItem("E_EAXM_SEARCH_PAGE_SIZE");
    let currentPage = sessionStorage.getItem("E_EAXM_SEARCH_CUR_PAGE");
    sessionStorage.removeItem("E_EAXM_SEARCH_PARAMS");
    sessionStorage.removeItem("E_EAXM_SEARCH_PAGE_SIZE");
    sessionStorage.removeItem("E_EAXM_SEARCH_CUR_PAGE");
    if (sessionData) {
      this.formSearch = JSON.parse(sessionData);
    }
    if (pageSize) {
      this.pageSize = parseInt(pageSize);
    }
    if (currentPage) {
      this.currentPage = parseInt(currentPage);
    }

    this.initPrivileges();
    this.searchForm();
  },

  methods: {
    getArchivedText(archived) {
      if (archived) {
        return "已归档";
      } else {
        return "未归档";
      }
    },
    toCopyExam() {
      if (this.selectedExamIds.length == 0) {
        this.$notify({
          type: "warning",
          message: "请勾选一个考试",
        });
        return;
      }
      if (this.selectedExamIds.length > 1) {
        this.$notify({
          type: "warning",
          message: "只能勾选一个考试",
        });
        return;
      }
      this.showCopyExam(
        this.tableData.filter((v) => v.id === this.selectedExamIds[0])[0]
      );
    },
    showCopyExam(row) {
      this.examCopyTitle = "复制考试：" + row.name;
      this.examCopyForm.srcExamId = row.id;
      this.examCopyForm.destExamCode = null;
      this.examCopyForm.destExamName = null;
      this.copyExamDialog = true;
    },
    doBeforedialogClose() {
      this.$refs.examCopyForm.clearValidate();
    },
    copyExam() {
      this.examCopyForm.destExamCode = this.examCopyForm.destExamName;
      var url = EXAM_WORK_API + "/exam/copyExam";
      this.$refs.examCopyForm.validate((valid) => {
        if (valid) {
          this.$httpWithMsg.post(url, this.examCopyForm).then((response) => {
            console.log(response);
            this.$notify({
              type: "success",
              message: "复制成功",
            });
            this.copyExamDialog = false;
            this.searchForm();
          });
        } else {
          return false;
        }
      });
    },
    selectChange(row) {
      this.selectedExamIds = [];
      row.forEach((element, index) => {
        console.log(index);
        this.selectedExamIds.push(element.id);
      });
      console.log(this.selectedExamIds);
    },
    enableById(row) {
      this.$confirm("是否启用该考试？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let url = EXAM_WORK_API + "/exam/enable/" + row.id;
        this.$httpWithMsg.put(url, {}).then((response) => {
          console.log(response);
          this.$notify({
            type: "success",
            message: "开启成功！",
          });
          this.searchForm();
        });
      });
    },
    disableById(row) {
      this.$confirm("是否禁用该考试？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      }).then(() => {
        var url = EXAM_WORK_API + "/exam/disable/" + row.id;
        this.$httpWithMsg.put(url, {}).then((response) => {
          console.log(response);
          this.$notify({
            type: "success",
            message: "禁用成功！",
          });
          this.searchForm();
        });
      });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.searchForm();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.searchForm();
    },
    resetPageAndSearchForm() {
      this.currentPage = 1;
      this.searchForm();
    },
    //查询方法
    searchForm() {
      var param = new URLSearchParams(this.formSearch);
      var url =
        EXAM_WORK_API +
        "/exam/queryPage/" +
        (this.currentPage - 1) +
        "/" +
        this.pageSize +
        "?" +
        param +
        "&ignoreArchived=true";
      this.loading = true;
      this.$httpWithMsg.get(url).then((response) => {
        console.log(response);
        this.tableData = response.data.list;
        this.total = response.data.total;
        this.loading = false;
        this.paginationShow = true;
      });
    },
    addExamInfoDialog() {
      this.examInfoDialog = true;
      this.examId = "";
    },
    editExamInfoDialog(row) {
      this.setSearchParams();
      if (row.examType == "ONLINE") {
        this.$router.push({ path: "/examwork/onlineExam/" + row.id });
      } else if (row.examType == "ONLINE_HOMEWORK") {
        this.$router.push({ path: "/examwork/onlineHomework/" + row.id });
      } else if (row.examType == "TRADITION") {
        this.$router.push({ path: "/examwork/traditionExam/" + row.id });
      } else if (row.examType == "PRACTICE") {
        this.$router.push({ path: "/examwork/practiceExam/" + row.id });
      } else if (row.examType == "OFFLINE") {
        this.$router.push({ path: "/examwork/offlineExam/" + row.id });
      } else if (row.examType == "PRINT_EXAM") {
        this.$router.push({ path: "/examwork/printExam/" + row.id });
      }
    },
    editOrgSettings(row) {
      this.setSearchParams();
      if (row.examType == "OFFLINE") {
        this.$router.push({
          path: "/examwork/offlineExamOrgSettings/" + row.id,
        });
      } else if (row.examType == "ONLINE") {
        this.$router.push({
          path: "/examwork/onlineExamOrgSettings/" + row.id,
        });
      } else if (row.examType == "ONLINE_HOMEWORK") {
        this.$router.push({
          path: "/examwork/onlineHomeworkOrgSettings/" + row.id,
        });
      }
    },
    editStageSettings(row) {
      this.setSearchParams();
      this.$router.push({
        path:
          "/examwork/stageSpecialSettings/" +
          row.id +
          "/" +
          row.name +
          "/" +
          this.getExamType(row.examType),
      });
    },
    weixinAnswerSetting(row) {
      this.setSearchParams();
      this.$router.push({
        path: "/examwork/weixinAnswerSetting/" + row.id,
      });
    },
    skipFaceSetting(row) {
      this.setSearchParams();
      this.$router.push({
        path: "/examwork/skipFaceSetting/" + row.id + "/" + row.name,
      });
    },
    editIpSettings(row) {
      this.setSearchParams();
      this.$router.push({
        path:
          "/examwork/examIpLimit/" +
          row.id +
          "/" +
          row.name +
          "/" +
          this.getExamType(row.examType),
      });
    },
    showExamCourseSettingsDialog(row) {
      this.setSearchParams();
      this.$router.push({ path: "/examwork/examCourseSettings/" + row.id });
    },
    showExamOrgSettingsDialog(row) {
      this.setSearchParams();
      this.$router.push({ path: "/examwork/examOrgSettings/" + row.id });
    },
    toTradition() {
      this.setSearchParams();
      this.$router.push({ path: "/examwork/traditionExam/add" });
    },
    toOnline() {
      this.setSearchParams();
      this.$router.push({ path: "/examwork/onlineExam/add" });
    },
    toPractice() {
      this.setSearchParams();
      this.$router.push({ path: "/examwork/practiceExam/add" });
    },
    toOffline() {
      this.setSearchParams();
      this.$router.push({ path: "/examwork/offlineExam/add" });
    },
    toOnlineHomework() {
      this.setSearchParams();
      this.$router.push({ path: "/examwork/onlineHomework/add" });
    },
    toPrint() {
      this.setSearchParams();
      this.$router.push({ path: "/examwork/printExam/add" });
    },

    setSearchParams() {
      sessionStorage.setItem(
        "E_EAXM_SEARCH_PARAMS",
        JSON.stringify(this.formSearch)
      );
      sessionStorage.setItem("E_EAXM_SEARCH_PAGE_SIZE", this.pageSize);
      sessionStorage.setItem("E_EAXM_SEARCH_CUR_PAGE", this.currentPage);
    },
    getExamType(examType) {
      for (let tempExamType of this.examTypeList) {
        if (tempExamType.value == examType) {
          return tempExamType.label;
        }
      }
    },
    initPrivileges() {
      let params = new URLSearchParams();
      params.append(
        "privilegeCodes",
        Object.keys(this.rolePrivileges).toString()
      );
      let url = CORE_API + "/rolePrivilege/checkPrivileges?" + params;
      this.$httpWithMsg.post(url).then((response) => {
        this.rolePrivileges = response.data;
      });
    },
  },
};
</script>
<style scoped>
.input {
  width: 200px;
}
</style>
