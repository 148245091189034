import Vue from "vue";
import { QUESTION_API } from "@/constants/constants";
Vue.directive("questionAudio", {
  bind: function (el, binding, vnode) {
    addAudio(el, binding, vnode);
  },
});
function addAudio(el, binding, vnode) {
  // console.log(el, binding, vnode);
  var list = el.getElementsByTagName("a");
  for (let obj of list) {
    var questionAudioId = obj.getAttribute("id");
    if (questionAudioId) {
      console.log("come in");
      vnode.context.$httpWithoutAuth
        .get(QUESTION_API + "/questionAudio/" + questionAudioId)
        .then((response) => {
          if (response && response.data) {
            let audioFlag =
              '<audio controls><source src="' +
              response.data.fileUrl +
              '" type="audio/mpeg"></audio>';
            var b = document.createElement("span");
            b.innerHTML = audioFlag;
            obj.append(b);
          }
        });
    }
  }
}
