<template>
  <div v-loading="loading" class="paper">
    <div class="center paper-top">
      <small class="paper-title">
        <el-button-group>
          <el-button size="small">
            <span class="titlefont">课程代码:</span>
          </el-button>
          <el-button size="small">
            <span class="titlefont">{{ paper.course.code }}</span>
          </el-button>
        </el-button-group>
      </small>
      <small class="paper-title">
        <el-button-group>
          <el-button size="small">
            <span class="titlefont">课程名称:</span>
          </el-button>
          <el-button size="small">
            <span class="titlefont">{{ paper.course.name }}</span>
          </el-button>
        </el-button-group>
      </small>
      <small class="paper-title">
        <el-button-group>
          <el-button size="small">
            <span class="titlefont">试卷名称:</span>
          </el-button>
          <input
            v-model="paper.name"
            placeholder="试卷名称"
            class="paper-input"
          />
        </el-button-group>
      </small>
      <small class="paper-title">
        <el-button-group>
          <el-button v-if="isBack" size="small" class="titlefont" @click="back">
            <i class="el-icon-arrow-left"></i>返回
          </el-button>
        </el-button-group>
      </small>
    </div>
    <div class="center">
      <div>
        <br />
        <h3 class="text-center">{{ paper.course.name }}&nbsp;试卷</h3>
        <br />
        <h4 class="text-center">（课程代码&nbsp;{{ paper.course.code }}）</h4>
        <br />
        <div v-if="paper.examRemark">
          <h1 class="text-left">考试说明：</h1>
          <div v-html="paper.examRemark"></div>
        </div>
        <h1 class="text-left">本试卷满分{{ paper.totalScore }}分。</h1>
        <br />
      </div>
    </div>
    <div>
      <div
        v-for="(paperDetail, index) in paper.paperDetails"
        :key="index"
        class="mainQues"
      >
        <div class="mainQuesTitle">
          <span>{{ paperDetail.cnNum }}</span>
          <span>.</span>
          <span>{{ paperDetail.name }}</span>
          <span>
            (本大题共{{ paperDetail.unitCount }}小题，满分{{
              paperDetail.score
            }}分)
          </span>
        </div>
        <div
          v-for="(paperDetailUnit, index2) in paperDetail.paperDetailUnits"
          :key="index2"
        >
          <div>
            <div class="quesBody">
              <span class="ques-title">{{ paperDetailUnit.number }}.</span>
              <span
                v-question-audio
                class="ques-body"
                :hasAudio="paperDetailUnit.question.hasAudio"
                :questionId="paperDetailUnit.question.id"
                v-html="paperDetailUnit.question.quesBody"
              ></span>
              <span>（{{ paperDetailUnit.score }}分）</span>
            </div>
            <div
              v-for="(quesOption, index3) in paperDetailUnit.question
                .quesOptions"
              :key="index3"
              class="quesOption"
            >
              <span class="ques-title"
                >{{ index3 | optionOrderWordFilter }}.</span
              >
              <span
                v-question-audio
                class="ques-body"
                :hasAudio="paperDetailUnit.question.hasAudio"
                :questionId="paperDetailUnit.question.id"
                v-html="quesOption.optionBody"
              ></span>
            </div>
          </div>
          <br />
          <div
            v-for="(subQuestion, index4) in paperDetailUnit.question
              .subQuestions"
            :key="index4"
            class="subQues"
          >
            <div>
              <div class="quesBody">
                <span class="ques-title"
                  >{{ subQuestion.quesParams.number }}.</span
                >
                <span v-html="subQuestion.quesBody"></span>
                <span>（{{ paperDetailUnit.subScoreList[index] }}分）</span>
              </div>
              <div
                v-for="(subQuesOption, index5) in subQuestion.quesOptions"
                :key="index5"
                class="quesOption"
              >
                <span class="ques-title"
                  >{{ index5 | optionOrderWordFilter }}.</span
                >
                <span v-html="subQuesOption.optionBody"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { QUESTION_API } from "@/constants/constants";
import { mapState } from "vuex";
export default {
  data() {
    return {
      paperId: "",
      paperDetailId: "",
      editPaperDetailUnit: "",
      quesDialog: false,
      paperDatailDialog: false,
      parentView: "",
      paper: {
        course: {
          code: "",
          name: "",
        },
      },
      questionType: "",
      reduplicateGroup: [],
      singleRightAnswer: "", //接收单选答案
      multipleRightAnswer: [], //接收多选答案
      options: ["正确", "错误"],
      loading: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    isBack() {
      var params = window.location.href.split("?")[1];
      if (params && params.indexOf("isback") > -1) {
        return true;
      }
      return false;
    },
  },
  created() {
    this.paperId = this.$route.params.paperId;
    console.log("this.paperId:", this.paperId);
    this.initPaper();
  },
  methods: {
    //初始化试卷
    initPaper() {
      this.loading = true;
      this.$httpWithoutAuth
        .get(QUESTION_API + "/paper/" + this.paperId)
        .then((response) => {
          this.paper = response.data;
          this.loading = false;
        });
    },
    back() {
      console.log("返回");
      window.history.back();
    },
  },
};
</script>
<style scoped>
.red {
  color: red;
}
.center {
  margin: 0 auto 0 auto;
  text-align: center;
}
h1 {
  font-size: 16px;
  font-family: "微软雅黑", serif;
  font-weight: bold;
}

h3 {
  font-size: 33px;
  font-family: "微软雅黑", serif;
  font-weight: bold;
}
h4 {
  font-size: 21px;
  font-family: "微软雅黑", serif;
}
span > p {
  font-size: 16px;
  font-family: "微软雅黑", serif;
  text-align: left;
  display: block;
}
.ques-title {
  margin-right: 10px;
}
.mainQues {
  margin-left: 3.5%;
  text-align: left;
}
.mainQuesTitle {
  font-size: 16px;
  font-family: "微软雅黑", serif;
  font-weight: bold;
  margin-bottom: 20px;
}
.ques {
  font-size: 16px;
  font-family: "微软雅黑", serif;
  text-align: left;
  margin-bottom: 10px;
  padding-left: 10px;
  position: relative;
}
.subQues {
  position: relative;
  margin-bottom: 10px;
  padding-left: 10px;
}
.ques.repeated {
  /*border: 1px solid red;*/
}
.quesBody {
  display: flex;
}
.quesOption {
  margin-top: 10px;
  display: flex;
}
.text-left {
  margin-left: 3.5%;
  text-align: left;
}

.paper-title {
  margin-right: 10px;
  margin-bottom: 10px;
}
.paper-top {
  margin-top: 10px;
}
small {
  font-size: 15px;
  font-weight: bold;
  margin-left: 10px;
  margin-top: 20px;
}
.titlefont {
  font-size: 14px;
}
.paper {
  margin: 0 auto;
  background-color: white;
  padding-left: 10%;
  padding-right: 10%;
}
.paper-input {
  font-size: 14px;
  margin-left: 7px;
  margin-top: 5px;
  width: 150px;
  border-bottom: 1px solid black; /* 下划线效果 */
  border-top: 0;
  border-left: 0;
  border-right: 0;
  background-color: transparent; /* 背景色透明 */
  outline: none;
}
.btnDiv {
  height: 30px;
  text-align: right;
  position: relative;
  right: 2px;
  top: 1px;
  visibility: hidden;
}
textarea {
  width: 200px;
}
#app {
  background-color: white !important;
}
</style>
