<template>
  <div
    id="editPaperApp"
    v-loading="loading"
    class="paper"
    element-loading-text="拼命加载中。。。"
  >
    <div class="edit-paper-top">
      <div class="edit-paper-top-inline">
        <div class="paper-top-div">
          <span class="paper-top-title">课程代码:</span>
          <span class="paper-top-value">{{ paper.course.code }}</span>
        </div>
        <div class="paper-top-div">
          <span class="paper-top-title">课程名称:</span>
          <span class="paper-top-value">{{ paper.course.name }}</span>
        </div>
        <div class="paper-top-div">
          <span class="paper-top-title">试卷名称:</span>
          <span class="paper-top-value">{{ paper.name }}</span>
        </div>
        <div class="paper-top-div">
          <span class="paper-top-title">试卷难度:</span>
          <span class="paper-top-value">{{ paper.difficultyDegree }}</span>
        </div>
        <div>
          <el-button
            size="small"
            type="primary"
            plain
            @click="releasePaper(paper.id)"
            ><i class="el-icon-share"></i>释放</el-button
          >
          <el-button
            size="small"
            type="primary"
            style="margin-left: 10px"
            @click="back"
            ><i class="el-icon-arrow-left"></i> 返回</el-button
          >
        </div>
      </div>
    </div>
    <div class="paperName">
      <div>
        <br />
        <h3 class="text-center">{{ paper.course.name }}&nbsp;试卷</h3>
        <h5 class="text-center">
          <span v-show="paper.hasAudio">（含音频试卷）</span>
        </h5>
        <br />
        <h4 class="text-center">（课程代码&nbsp;{{ paper.course.code }}）</h4>
        <br />
      </div>
      <div class="text-left">
        <el-table :data="paper.paperDetails" border style="width: 100%">
          <el-table-column header-align="center" label="大题名称">
            <template slot-scope="scope">
              <span>{{ scope.row.name }}</span>
            </template>
          </el-table-column>
          <el-table-column header-align="center" label="大题总分">
            <template slot-scope="scope">
              <span>{{ scope.row.score }}</span>
            </template>
          </el-table-column>
          <el-table-column header-align="center" label="小题数量">
            <template slot-scope="scope">
              <span>{{ scope.row.unitCount }}</span>
            </template>
          </el-table-column>
          <el-table-column header-align="center" label="公开数量">
            <template slot-scope="scope">
              <span>{{ scope.row.pubCount }}</span>
            </template>
          </el-table-column>
          <el-table-column header-align="center" label="非公开数量">
            <template slot-scope="scope">
              <span>{{ scope.row.noPubCount }}</span>
            </template>
          </el-table-column>
        </el-table>

        <div class="mainQues" style="margin-top: 20px; margin-left: 0px">
          <div class="mainQuesTitle">
            <span>考试说明:</span>
            <div style="width: 550px; margin-left: 20px; margin-top: 20px">
              <span v-html="paper.examRemark"></span>
            </div>
          </div>
        </div>
        <!-- end by wwh -->
        <div>
          <h1>本试卷满分{{ paper.totalScore }}分。</h1>
        </div>
        <br />
      </div>
    </div>

    <div>
      <!-- 循环大题 -->
      <div
        v-for="(paperDetail, detailIndex) in paper.paperDetails"
        v-show="paperDetailShow(paperDetail)"
        :key="detailIndex"
        class="mainQues"
      >
        <div
          class="mainQuesTitle"
          @mouseover="quesMouseOver(paperDetail.id)"
          @mouseout="quesMouseOut(paperDetail.id)"
        >
          <span>{{ paperDetail.cnNum }}</span> <span>.</span>
          <span>{{ paperDetail.name }}</span>
          <span>
            ({{ !paperDetail.title ? "本大题" : paperDetail.title + "，" }}共{{
              paperDetail.unitCount
            }}小题，满分{{ paperDetail.score }}分)
          </span>

          <span :id="paperDetail.id" class="btnDiv">
            <el-button
              v-show="showButtons[detailIndex].up"
              size="small"
              icon="el-icon-arrow-up"
              @click.stop="hideContent(detailIndex)"
            ></el-button>

            <el-button
              v-show="!showButtons[detailIndex].up"
              size="small"
              icon="el-icon-arrow-down"
              @click.stop="showContent(detailIndex)"
            ></el-button>
          </span>
        </div>

        <!-- 循环小题 -->
        <div v-show="showQuestions[detailIndex].is_show">
          <div
            v-for="(paperDetailUnit, unitIndex) in paperDetail.paperDetailUnits"
            v-show="quesShow(paperDetailUnit.id)"
            :key="unitIndex"
            class="ques"
          >
            <reduplicate_mark
              :id="paperDetailUnit.id"
              :show="reduplicateMarkShow(paperDetailUnit.id)"
              :fill-color="reduplicateMarkColor(paperDetailUnit.id)"
              :checked="reduplicateMarkCheck(paperDetailUnit.id)"
              @reduplicate_mark_check="reduplicate_mark_check"
            >
            </reduplicate_mark>

            <div
              class="quesSelect"
              @mouseover="quesMouseOver(paperDetailUnit.id)"
              @mouseout="quesMouseOut(paperDetailUnit.id)"
            >
              <div :id="paperDetailUnit.id" class="btnDiv">
                <el-button
                  size="small"
                  @click="editQues(paperDetailUnit, paperDetailUnit.question)"
                  >查看
                </el-button>
              </div>

              <div class="quesBody">
                <span class="ques-title">{{ paperDetailUnit.number }}.</span>
                <span
                  v-question-audio
                  class="ques-body"
                  :hasAudio="paperDetailUnit.question.hasAudio"
                  :questionId="paperDetailUnit.question.id"
                  v-html="paperDetailUnit.question.quesBody"
                ></span>

                <span class="score-span">
                  （{{ paperDetailUnit.score }}分）
                </span>
              </div>

              <div
                v-for="(quesOption, optionIndex) in paperDetailUnit.question
                  .quesOptions"
                :key="optionIndex"
                class="quesOption"
              >
                <span class="ques-title"
                  >{{ optionIndex | optionOrderWordFilter }}.
                </span>

                <span
                  v-question-audio
                  class="ques-body"
                  :hasAudio="paperDetailUnit.question.hasAudio"
                  :questionId="paperDetailUnit.question.id"
                  v-html="quesOption.optionBody"
                ></span>
              </div>
            </div>
            <br />

            <div
              v-for="(subQuestion, subIndex) in paperDetailUnit.question
                .subQuestions"
              v-show="quesShow(subQuestion.id)"
              :key="subIndex"
              class="subQues"
            >
              <reduplicate_mark
                :show="reduplicateMarkShow(subQuestion.id)"
              ></reduplicate_mark>

              <div
                class="quesSelect"
                @mouseover="
                  quesMouseOver(getSubQuesEditId(paperDetailUnit, subQuestion))
                "
                @mouseout="
                  quesMouseOut(getSubQuesEditId(paperDetailUnit, subQuestion))
                "
              >
                <div
                  :id="getSubQuesEditId(paperDetailUnit, subQuestion)"
                  class="btnDiv"
                >
                  <el-button
                    size="small"
                    @click="editQues(paperDetailUnit, subQuestion)"
                    >查看
                  </el-button>
                </div>

                <div class="quesBody">
                  <span class="ques-title"
                    >{{ subQuestion.quesParams.number }}.
                  </span>

                  <span v-html="subQuestion.quesBody"></span>
                  <span
                    >（{{ paperDetailUnit.subScoreList[subIndex] }}分）</span
                  >
                </div>

                <div
                  v-for="(
                    subQuesOption, subOptIndex
                  ) in subQuestion.quesOptions"
                  :key="subOptIndex"
                  class="quesOption"
                >
                  <span class="ques-title"
                    >{{ subOptIndex | optionOrderWordFilter }}.
                  </span>
                  <span v-html="subQuesOption.optionBody"></span>
                </div>
                <br />
              </div>
            </div>
          </div>
          <br />
        </div>
      </div>
    </div>

    <div class="text-left">
      <el-dialog
        v-loading.body="dialogLoading"
        title="试题查看"
        :visible.sync="quesDialog"
        @close="closeQuesDialog"
      >
        <el-form :model="quesModel" label-position="right" label-width="80px">
          <el-row :gutter="10">
            <el-col :xs="10" :sm="10" :md="10" :lg="10">
              <el-form-item label="题型">
                <el-select
                  v-model="quesModel.questionType"
                  :disabled="true"
                  placeholder="请输入题型"
                >
                  <el-option
                    v-for="item in questionTypes"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="10" :sm="10" :md="10" :lg="10">
              <el-form-item label="分值">
                <el-input
                  v-model="quesModel.score"
                  :disabled="true"
                  placeholder="分值"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- create by  weiwenhai 添加难度，公开度，试题属性 -->
          <el-row :gutter="10">
            <el-col :xs="10" :sm="10" :md="10" :lg="10">
              <el-form-item label="难度">
                <el-select
                  v-model="quesModel.difficultyDegree"
                  placeholder="请输入难度"
                  :disabled="true"
                >
                  <el-option
                    v-for="item in difficultyDegreeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="10" :sm="10" :md="10" :lg="10">
              <el-form-item label="公开度">
                <el-select
                  v-model="quesModel.publicity"
                  placeholder="请输入公开度"
                  :disabled="true"
                >
                  <el-option
                    v-for="item in publicityList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col
              v-if="quesModel.questionType == 'TEXT_ANSWER_QUESTION'"
              :xs="10"
              :sm="10"
              :md="10"
              :lg="10"
            >
              <el-form-item label="作答类型">
                <el-select v-model="quesModel.answerType" :disabled="true">
                  <el-option
                    v-for="item in answerTypes"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="10">
            <el-col :xs="20" :sm="20" :md="20" :lg="20">
              <el-form-item label="属性列表">
                <el-tooltip
                  v-for="(content, propIndex) in quesModel.quesProperties"
                  :key="propIndex"
                  placement="top"
                >
                  <div slot="content">
                    <span v-if="content.firstProperty != null"
                      >一级属性：{{ content.firstProperty.name }}</span
                    ><br />
                    <span v-if="content.secondProperty != null"
                      >二级属性：{{ content.secondProperty.name }}</span
                    >
                  </div>
                  <span>
                    <el-tag
                      :key="content.id"
                      style="margin-right: 5px"
                      :closable="false"
                      type="primary"
                    >
                      {{ content.coursePropertyName }}
                    </el-tag>
                  </span>
                </el-tooltip>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- end by weiwenhai -->
          <div>
            <el-row>
              <el-col>
                <el-form-item label="题目">
                  <span v-html="quesModel.quesBody"></span>
                </el-form-item>
              </el-col>
            </el-row>

            <el-form-item
              v-for="(quesOption, optIndex) in quesModel.quesOptions"
              :key="optIndex"
              ><el-col :span="2">
                <span>{{ optIndex | optionOrderWordFilter }}</span>
              </el-col>
              <el-col :span="20">
                <span v-html="quesOption.optionBody"></span>
              </el-col>
            </el-form-item>

            <div>
              <el-form-item label="答案">
                <span v-html="quesModel.quesAnswer"></span>
              </el-form-item>
            </div>
            <div
              :class="{
                margin_left_40: true,
              }"
            >
              <el-button @click="closeQuesDialog">关闭</el-button>
            </div>
          </div>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { QUESTION_API } from "@/constants/constants";
import { isEmptyStr, QUESTION_TYPES } from "../constants/constants";
import { mapState } from "vuex";
import reduplicate_mark from "../component/reduplicate_mark.vue";
import randomColor from "randomcolor";

export default {
  name: "EditPaperApp",
  components: {
    reduplicate_mark,
  },
  data() {
    return {
      hValue: "100px",
      wValue: "500px",
      display: "block",
      uploadAction: "",
      fileList: [],
      answerFileList: [],
      paperId: "",
      paperDetailId: "",
      editPaperDetailUnit: "",
      quesDialog: false,
      paperDatailDialog: false,
      paperRemarkDialog: false,
      parentView: "",
      paper: {
        course: {
          code: "",
          name: "",
        },
        examRemark: "",
      },
      loading: false,
      dialogLoading: false,
      detailLoading: false,
      uploadAudioLoading: false,
      uploadAnswerLoading: false,
      questionTypes: QUESTION_TYPES,
      questionType: "",
      quesModel: { quesProperties: [] },
      editpaperDetail: {},
      reduplicateQuestions: [],
      reduplicateGroup: [],
      reduplicateQuesColor: [],
      singleRightAnswer: "", //接收单选答案
      multipleRightAnswer: [], //接收多选答案
      options: ["正确", "错误"],
      duplicateLoading: false,
      dialogRadioFile: false,
      dialogAnswerFile: false,
      isUpload: true,
      isUploadAnswer: true,
      message: "",
      answerMessage: "",
      checkResult: false,
      checkResultAnswer: false,
      fileNameList: [],
      defaultColor: [
        "Red",
        "Blue",
        "LimeGreen",
        "GoldenRod",
        "Black",
        "BlueViolet",
        "Chocolate",
        "DarkCyan",
        "HotPink",
        "Orange",
        "IndianRed",
        "Indigo",
        "Green",
        "Aqua",
        "CadetBlue",
        "SkyBlue",
        "SlateBlue",
        "SlateGray",
        "Tomato",
        "VioletRed",
      ],
      difficultyDegreeList: [
        { label: 0.1, value: 0.1 },
        { label: 0.2, value: 0.2 },
        { label: 0.3, value: 0.3 },
        { label: 0.4, value: 0.4 },
        { label: 0.5, value: 0.5 },
        { label: 0.6, value: 0.6 },
        { label: 0.7, value: 0.7 },
        { label: 0.8, value: 0.8 },
        { label: 0.9, value: 0.9 },
        { label: 1.0, value: 1.0 },
      ],
      publicityList: [
        { label: "公开", value: true },
        { label: "非公开", value: false },
      ],
      answerTypes: [
        { label: "文本", value: "DIVERSIFIED_TEXT" },
        { label: "音频", value: "SINGLE_AUDIO" },
      ],
      coursePropertyList: [],
      coursePropertyName: "", //课程属性名
      firstPropertyList: [], //一级属性集合
      firstPropertyId: "", //一级属性id
      secondPropertyList: [], //二级属性集合
      secondPropertyId: "", //二级属性id
      examRemark: "",
      showQuestions: [],
      showButtons: [],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    updatePorperty() {
      return false;
    },
    answer() {
      if (this.quesModel.questionType == "SINGLE_ANSWER_QUESTION") {
        return this.singleRightAnswer;
      } else if (this.quesModel.questionType == "MULTIPLE_ANSWER_QUESTION") {
        var obj = this.multipleRightAnswer;
        return obj.sort().toString();
      }
      return this.quesModel.quesAnswer;
    },
  },
  created() {
    // $("body").attr("style", "");
    document.getElementsByTagName("body")[0].style = "";
    this.paperId = this.$route.params.id;
    this.parentView = this.$route.params.parentView;
    this.initPaper();
    // this.getreduplicateQuestions();
    this.uploadAction = QUESTION_API + "/uploadRadio/" + this.paperId;
    this.uploadHeaders = {
      key: this.user.key,
      token: this.user.token,
    };
  },
  methods: {
    releasePaper(paperid) {
      this.$confirm("确认释放试卷吗?", "提示", {
        type: "warning",
      }).then(() => {
        this.loading = true;
        this.$http.put(QUESTION_API + "/paper_storage/release/" + paperid).then(
          () => {
            this.loading = false;
            this.$notify({
              message: "操作成功",
              type: "success",
            });
            this.back();
          },
          (response) => {
            this.loading = false;
            this.$notify({
              message: response.response.data.desc,
              type: "error",
            });
          }
        );
      });
    },

    //隐藏大题下的所有小题
    hideContent(index) {
      console.log("up");
      this.showQuestions[index].is_show = false;
      this.showButtons[index].up = false;
    },
    //展开大题下所有小题
    showContent(index) {
      console.log("down");
      this.showQuestions[index].is_show = true;
      this.showButtons[index].up = true;
    },
    quesMouseOver(index) {
      document.getElementById(index).style.visibility = "visible";
    },
    quesMouseOut(index) {
      document.getElementById(index).style.visibility = "hidden";
    },
    //初始化试卷
    initPaper() {
      const scrollPosition =
        document.documentElement.scrollTop || document.body.scrollTop;
      this.loading = true;
      this.paper = {
        course: {
          code: "",
          name: "",
        },
      };
      this.$http
        .get(QUESTION_API + "/paper/" + this.paperId)
        .then((response) => {
          this.paper = response.data;
          //查询所有课程属性名
          this.initCourseProperty(this.paper.course.code);
          //将所有小题分为公开和非公开
          if (this.paper.paperDetails && this.paper.paperDetails.length > 0) {
            for (let paperDetil of this.paper.paperDetails) {
              this.showQuestions.push({ is_show: true });
              this.showButtons.push({ up: true });
              paperDetil.pubCount = 0;
              paperDetil.noPubCount = 0;
              if (
                paperDetil.paperDetailUnits &&
                paperDetil.paperDetailUnits.length > 0
              ) {
                for (let paperDetilUt of paperDetil.paperDetailUnits) {
                  if (
                    paperDetilUt.question.questionType !=
                    "NESTED_ANSWER_QUESTION"
                  ) {
                    //非套题
                    if (paperDetilUt.question.publicity) {
                      paperDetil.pubCount = paperDetil.pubCount + 1;
                    } else {
                      paperDetil.noPubCount = paperDetil.noPubCount + 1;
                    }
                  } else {
                    //循环所有子题
                    for (let ques of paperDetilUt.question.subQuestions) {
                      if (ques.publicity) {
                        paperDetil.pubCount = paperDetil.pubCount + 1;
                      } else {
                        paperDetil.noPubCount = paperDetil.noPubCount + 1;
                      }
                    }
                  }
                }
              }
            }
          }
          setTimeout(() => {
            document.documentElement.scrollTop = document.body.scrollTop =
              scrollPosition;
            console.log(scrollPosition);
          }, 1000);
          this.loading = false;
        });
    },
    //查询所有课程属性名
    initCourseProperty(courseCode) {
      this.$http
        .get(QUESTION_API + "/courseProperty/enable/" + courseCode)
        .then((response) => {
          this.coursePropertyList = response.data;
        });
    },
    quesShow(id) {
      if (this.reduplicateGroup.length < 1) {
        return true;
      }
      for (var i = 0, imax = this.reduplicateGroup.length; i < imax; i++) {
        if (id == this.reduplicateGroup[i]) {
          return true;
        }
      }
      return false;
    },
    reduplicateMarkShow(id) {
      var found = false;
      for (var i = 0, imax = this.reduplicateQuestions.length; i < imax; i++) {
        for (
          var j = 0, jmax = this.reduplicateQuestions[i].length;
          j < jmax;
          j++
        ) {
          if (this.reduplicateQuestions[i][j] == id) {
            found = true;
            break;
          }
        }
        if (found) {
          break;
        }
      }
      return found;
    },
    reduplicateMarkColor(id) {
      for (var i = 0, imax = this.reduplicateQuestions.length; i < imax; i++) {
        for (
          var j = 0, jmax = this.reduplicateQuestions[i].length;
          j < jmax;
          j++
        ) {
          if (this.reduplicateQuestions[i][j] == id) {
            return this.reduplicateQuesColor[i];
          }
        }
      }
    },
    reduplicateMarkCheck(id) {
      for (var i = 0, imax = this.reduplicateGroup.length; i < imax; i++) {
        if (id == this.reduplicateGroup[i]) {
          return true;
        }
      }
      return false;
    },
    reduplicate_mark_check(id, checked) {
      console.log(checked);
      console.log(this.reduplicateQuestions);
      console.log(id);
      if (!checked) {
        for (
          var i = 0, imax = this.reduplicateQuestions.length;
          i < imax;
          i++
        ) {
          for (
            var j = 0, jmax = this.reduplicateQuestions[i].length;
            j < jmax;
            j++
          ) {
            if (this.reduplicateQuestions[i][j] == id) {
              this.reduplicateGroup = [];
              for (
                var k = 0, kmax = this.reduplicateQuestions[i].length;
                k < kmax;
                k++
              ) {
                this.reduplicateGroup.push(this.reduplicateQuestions[i][k]);
              }
              return;
            }
          }
        }
      } else {
        this.reduplicateGroup = [];
      }
    },
    //编辑题目
    editQues(paperDetailUnit, question) {
      console.log("question:", question);
      this.coursePropertyName = "";
      this.firstPropertyId = "";
      this.secondPropertyId = "";
      this.editPaperDetailUnit = paperDetailUnit;
      this.quesModel = JSON.parse(JSON.stringify(question)); //深拷贝
      this.quesModel.score = paperDetailUnit.score;
      //如果是套题下面的小题编辑 ( paperDetailUnit的类型是套题，question的类型不是套题)
      if (
        paperDetailUnit.questionType == "NESTED_ANSWER_QUESTION" &&
        question.questionType != "NESTED_ANSWER_QUESTION"
      ) {
        for (var i = 0; i < paperDetailUnit.question.subQuestions.length; i++) {
          if (
            paperDetailUnit.question.subQuestions[i].id == this.quesModel.id
          ) {
            this.quesModel.score = paperDetailUnit.subScoreList[i];
            break;
          }
        }
      }

      if (isEmptyStr(this.quesModel.answerType)) {
        this.quesModel.answerType = "DIVERSIFIED_TEXT";
      }

      if (this.quesModel.questionType == "FILL_BLANK_QUESTION") {
        this.quesModel.quesBody = this.quesModel.quesBody.replace(
          /______/g,
          "###"
        );
      }

      this.assignAnswers(); //给singleRightAnswer或multipleRightAnswer赋值
      this.openQuesDialog();
    },
    //给singleRightAnswer和multipleRightAnswer赋值
    assignAnswers() {
      if (this.quesModel.quesOptions && this.quesModel.quesOptions.length > 0) {
        this.singleRightAnswer = "";
        this.multipleRightAnswer = [];
        for (let i = 0; i < this.quesModel.quesOptions.length; i++) {
          let option = this.quesModel.quesOptions[i];
          if (
            this.quesModel.questionType == "SINGLE_ANSWER_QUESTION" &&
            option.isCorrect == 1
          ) {
            this.singleRightAnswer = String.fromCharCode(65 + i);
          }
          if (
            this.quesModel.questionType == "MULTIPLE_ANSWER_QUESTION" &&
            option.isCorrect == 1
          ) {
            this.multipleRightAnswer.push(String.fromCharCode(65 + i));
          }
        }
      }
    },
    //打开修改试题编辑框
    openQuesDialog() {
      this.quesDialog = true;
    },
    //关闭试题编辑框
    closeQuesDialog() {
      this.quesDialog = false;
      this.quesModel = {};
    },
    //新增属性
    //在正确的option上设置isCorrect=1
    setRightAnswer() {
      if (
        !this.quesModel.quesOptions ||
        this.quesModel.quesOptions.length == 0
      ) {
        return false;
      }
      for (var i = 0; i < this.quesModel.quesOptions.length; i++) {
        var option = this.quesModel.quesOptions[i];
        var answerOrderNum = String.fromCharCode(65 + i);
        if (this.quesModel.questionType == "SINGLE_ANSWER_QUESTION") {
          option["isCorrect"] =
            answerOrderNum == this.singleRightAnswer ? 1 : 0;
        }
        if (this.quesModel.questionType == "MULTIPLE_ANSWER_QUESTION") {
          option["isCorrect"] =
            this.multipleRightAnswer.indexOf(answerOrderNum) > -1 ? 1 : 0;
        }
      }
    },
    //获取重复试题
    getreduplicateQuestions() {
      this.duplicateLoading = true;
      this.$http
        .get(QUESTION_API + "/paper/" + this.paperId + "/reduplicate-questions")
        .then((response) => {
          this.reduplicateQuestions = response.data;
          this.duplicateLoading = false;
          this.initReduplicateQuesColor();
          // var ques = document.getElementsByClassName("ques")[0];
          // ques.style.display = "inline";
        });
    },
    initReduplicateQuesColor() {
      var colorCount = this.reduplicateQuestions.length;
      if (colorCount > 20) {
        this.reduplicateQuesColor = randomColor({
          luminosity: "bright",
          count: colorCount,
        });
      } else {
        this.reduplicateQuesColor = this.defaultColor;
      }
    },
    getSubQuesEditId(paperDetailUnit, subQuestion) {
      return paperDetailUnit.question.id + "_" + subQuestion.quesParams.number;
    },
    //返回
    back() {
      if (sessionStorage.getItem("question_back") == "true") {
        this.$router.push({
          path: "/questions/paper_storage/0",
        });
      } else {
        this.$router.push({
          path: "/questions/paper_storage/1",
        });
      }
    },
    paperDetailShow(paperDetail) {
      if (this.reduplicateGroup.length == 0) {
        return true;
      }
      let paperDetailUnits = paperDetail.paperDetailUnits;
      for (let i = 0, imax = paperDetailUnits.length; i < imax; i++) {
        for (var j = 0, jmax = this.reduplicateGroup.length; j < jmax; j++) {
          if (paperDetailUnits[i].id == this.reduplicateGroup[j]) {
            return true;
          }
        }
      }
      return false;
    },
  },
};
</script>
<style scoped src="../styles/EditPaper.css">
.property_with {
  width: 100px;
}
.ck-toolbar {
  z-index: 9999;
}
#app {
  background-color: white !important;
}
</style>
<style scoped src="../styles/Common.css"></style>
