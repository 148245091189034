<template>
  <!-- eslint-disable vue/no-mutating-props -->
  <el-form :model="form" label-width="85px">
    <el-col :span="6">
      <el-form-item label="考试">
        <el-select
          v-model="form.examId"
          class="form_search_width"
          filterable
          remote
          :remote-method="getExams"
          clearable
          placeholder="请选择考试"
          size="small"
          @change="changeExam"
          @clear="getExams"
        >
          <el-option
            v-for="item in examList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-col>
    <el-col :span="6">
      <el-form-item label="场次">
        <el-select
          v-model="form.examStageId"
          clearable
          :disabled="examStageDisabled4Search"
          class="form_search_width"
          :remote-method="queryExamStages4Search"
          remote
          :loading="queryExamStages4SearchLoading"
          :filterable="true"
          placeholder="请选择"
          size="small"
        >
          <el-option
            v-for="item in examStageList4Search"
            :key="item.id"
            :label="`${item.stageOrder} (${item.startTime}至${item.endTime})`"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-col>
    <el-col :span="6">
      <el-form-item label="学习中心">
        <el-select
          v-model="form.orgId"
          class="form_search_width"
          filterable
          remote
          :remote-method="getOrgs"
          clearable
          placeholder="全部"
          size="small"
          @change="getCourses"
          @clear="getOrgs"
        >
          <el-option
            v-for="item in orgList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
        <!-- <el-input
          v-if="!currentPagePrivileges.ORG_FIND_ALL"
          v-model="orgName"
          class="form_search_width"
          size="small"
          :disabled="true"
        ></el-input>
        <el-radio v-show="false" v-model="form.ORG_FIND_ALL"></el-radio> -->
      </el-form-item>
    </el-col>
    <el-col :span="6">
      <el-form-item label="课程">
        <el-select
          v-model="form.courseId"
          class="form_search_width"
          clearable
          filterable
          placeholder="全部"
          size="small"
        >
          <el-option
            v-for="item in courseList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-col>
    <el-col :span="6">
      <el-form-item label="学生姓名">
        <el-input
          v-model="form.studentName"
          size="small"
          placeholder="姓名"
          class="form_search_width"
        ></el-input>
      </el-form-item>
    </el-col>
    <el-col :span="6">
      <el-form-item label="课程层次">
        <el-select
          v-model="form.courseLevel"
          class="form_search_width"
          clearable
          placeholder="不限"
          size="small"
        >
          <el-option
            v-for="item in courseLevels"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-col>
    <el-col :span="6">
      <el-form-item label="身份证号">
        <el-input
          v-model="form.identityNumber"
          class="form_search_width"
          size="small"
          placeholder="身份证号"
        ></el-input>
      </el-form-item>
    </el-col>
    <el-col :span="6">
      <el-form-item label="学号">
        <el-input
          v-model="form.studentCode"
          size="small"
          placeholder="学号"
          class="form_search_width"
        ></el-input>
      </el-form-item>
    </el-col>
    <slot></slot>
  </el-form>
</template>
<script>
/* eslint-disable vue/no-mutating-props */

import { EXAM_WORK_API } from "@/constants/constants";
import { COURSE_LEVELS } from "../constants/constants";

import { mapState } from "vuex";
export default {
  props: {
    form: { type: Object, default: () => {} },
    getExamCondition: { type: Object, default: () => {} },
  },
  data() {
    return {
      examList: [],
      courseList: [],
      orgList: [],
      courseLevels: COURSE_LEVELS,
      currentPagePrivileges: {
        ORG_FIND_ALL: false, //查询所有学习中心
      },
      orgName: "",
      examStageDisabled4Search: true,
      queryExamStages4SearchLoading: false,
      examStageList4Search: [],
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
  },
  created() {
    this.getExams("");
    let params = new URLSearchParams();
    params.append(
      "privilegeCodes",
      Object.keys(this.currentPagePrivileges).toString()
    );
    // this.$http
    //   .post("/api/ecs_core/rolePrivilege/checkPrivileges?" + params)
    //   .then((response) => {
    //     this.currentPagePrivileges = response.data;
    //     this.form.ORG_FIND_ALL = this.currentPagePrivileges.ORG_FIND_ALL;
    //     if (!this.currentPagePrivileges.ORG_FIND_ALL) {
    //       var userId = this.user.userId;
    //       this.$http.get("/api/ecs_core/user/" + userId).then((response) => {
    //         this.form.orgId = response.data.orgId;
    //         this.orgName = response.data.orgName;
    //       });
    //     } else {
    this.getOrgs("");
    //     }
    //   });
  },
  methods: {
    getExams(examName) {
      if (!examName) {
        examName = "";
      }
      this.getExamCondition.params.name = examName;
      this.getExamCondition.params.enable = true;
      this.$http
        .get("/api/ecs_exam_work/exam/queryByNameLike", {
          params: this.getExamCondition.params,
        })
        .then((response) => {
          var examList = response.data;
          if (this.getExamCondition.filterCondition == "IS_FACE_ENABLE") {
            examList = examList.filter(function (e) {
              //查询开启人脸识别的
              return e.properties["IS_FACE_ENABLE"] == "true";
            });
          } else if (this.getExamCondition.filterCondition == "OVERDUE") {
            //缺考登记
            examList = examList.filter(function (e) {
              var now = new Date().getTime();
              var endTime = new Date(e.endTime).getTime();
              return now > endTime; //查询考试结束时间已过期的
            });
          }
          this.examList = examList;
          if (this.form.examId) {
            this.getCourses();
            this.changeExam(this.form.examId);
          }
        });
    },
    getOrgs(orgName) {
      if (!orgName) {
        orgName = "";
      }
      var rootOrgId = this.user.rootOrgId;
      this.$http
        .get("/api/ecs_core/org/query", {
          params: {
            name: orgName,
            rootOrgId: rootOrgId,
            enable: true,
          },
        })
        .then((response) => {
          this.orgList = response.data;
        });
    },
    changeExam(examId) {
      // if (this.form.clearStageOnChangeExam != false) {
      //   this.form.examStageId = "";
      // }

      this.examList.forEach((exam) => {
        if (exam.id == examId) {
          this.form.examType = exam.examType;
        }
      });
      this.getCourses();

      //场次联动
      if (this.examList.length > 0) {
        let examArr = this.examList.filter((p) => p.id == examId);
        if (examArr && examArr.length > 0) {
          let exam = examArr[0];
          if (
            exam.specialSettingsEnabled &&
            exam.specialSettingsType == "STAGE_BASED"
          ) {
            this.examStageDisabled4Search = false;
            this.queryExamStages4Search("");
          } else {
            this.examStageList4Search = [];
            this.examStageDisabled4Search = true;
            this.form.examStageId = "";
          }
        }
      }
    },
    getCourses() {
      //this.form.courseId = "";
      this.courseList = [];
      var examId = this.form.examId;
      var orgId = this.form.orgId;
      if (!examId) {
        return false;
      }
      this.$http
        .get("/api/ecs_oe_admin/exam/student/findCoursesByExamIdAndOrgId", {
          params: {
            examId: examId,
            orgId: orgId,
          },
        })
        .then((response) => {
          this.courseList = response.data;
        });
    },
    queryExamStages4Search(name) {
      this.queryExamStages(this.form.examId, name, "search");
    },
    queryExamStages(examId, name, where) {
      console.log("queryExams; name: " + name);

      this.$httpWithMsg
        .get(
          EXAM_WORK_API +
            "/examStage/queryByNameLike?examId=" +
            examId +
            "&enable=true&name=" +
            name
        )
        .then((response) => {
          if ("search" == where) {
            this.queryExamStages4SearchLoading = false;
            this.examStageList4Search = response.data;
            if (
              !(
                this.examStageList4Search.length > 0 &&
                this.examStageList4Search.some(
                  (p) => p.id == this.form.examStageId
                )
              )
            ) {
              this.form.examStageId = "";
            }
          } else if ("insertOrUpdate" == where) {
            this.queryExamStages4InsertOrUpdateLoading = false;
            this.examStageList4InsertOrUpdate = response.data;
          }
        })
        .catch((response) => {
          console.log(response);
          if ("search" == where) {
            this.queryExamStages4SearchLoading = false;
          } else if ("insertOrUpdate" == where) {
            this.queryExamStages4InsertOrUpdateLoading = false;
          }
        });
    },
  },
};
</script>
<style scoped src="../style/common.css"></style>
