export const COURSE_LEVELS = [
  { code: "GQZ", name: "高起专" },
  { code: "GQB", name: "高起本" },
  { code: "ZSB", name: "专升本" },
];
export const AUDIT_EXPLAIN_LIST = [
  { name: "CHEAT", desc: "作弊" },
  { name: "NOT_ONESELF", desc: "他人替考" },
  { name: "NOT_ONESELF_OF_PHOTO", desc: "相片替考" },
  { name: "ACTION_FAILURE", desc: "指定动作失败" },
  { name: "BATCH_PASS", desc: "批量审核通过" },
  { name: "BATCH_NOTPASS", desc: "批量审核不通过" },
  { name: "OTHER", desc: "其他" },
];
export const DISCIPLINE_TYPE_LIST = [
  { name: "CHEAT", desc: "作弊" },
  { name: "NOT_ONESELF", desc: "他人替考" },
  { name: "NOT_ONESELF_OF_PHOTO", desc: "相片替考" },
  { name: "ACTION_FAILURE", desc: "指定动作失败" },
  { name: "BATCH_NOTPASS", desc: "批量审核不通过" },
  { name: "OTHER", desc: "其他" },
];
