<template>
  <el-main
    v-loading="needLoading"
    style="overflow: unset; margin-left: 20px"
    class="el-main-padding"
  >
    <el-row>
      <el-col :span="5">
        <img :src="studentBasePhotoPath" alt width="180" />
        <img
          v-if="showFirstCapturePhoto"
          :src="syncCapturePhotoPath"
          alt
          class="syncPhto"
        />
      </el-col>

      <el-col :span="19">
        <el-row>
          <el-col :span="8" class="capture-title">
            <span>考试记录ID:{{ examRecordDataId }}</span>
          </el-col>
          <el-col
            v-if="showAuditButton && needAudit"
            :span="8"
            style="text-align: center"
          >
            <el-button
              size="small"
              type="success"
              icon="el-icon-check"
              :disabled="needDisable"
              round
              @click="auditExamRecordData(true)"
              >通过</el-button
            >
            <el-button
              size="small"
              type="danger"
              icon="el-icon-close"
              :disabled="needDisable"
              round
              @click="auditExamRecordData(false)"
              >不通过</el-button
            >
          </el-col>

          <el-col
            :span="showAuditButton && needAudit ? 8 : 16"
            style="text-align: right"
          >
            <el-button
              size="small"
              type="primary"
              icon="el-icon-arrow-left"
              :disabled="needDisable"
              round
              @click="changeExamRecordData(false)"
              >上一条</el-button
            >
            <el-button
              size="small"
              type="primary"
              :disabled="needDisable"
              round
              @click="changeExamRecordData(true)"
              >下一条 <i class="el-icon-arrow-right el-icon--right"></i
            ></el-button>
          </el-col>
        </el-row>

        <el-row class="margin-top-10">
          <el-col :span="24">
            <el-descriptions :column="3" size="small" border>
              <template v-for="(item, index) in examRecordData">
                <el-descriptions-item :key="index" label="姓名">
                  {{ item.studentName }}
                </el-descriptions-item>
                <el-descriptions-item :key="index" label="身份证号">
                  {{ item.identityNumber }}
                </el-descriptions-item>
                <el-descriptions-item :key="index" label="学号">
                  {{ item.studentCode }}
                </el-descriptions-item>
                <el-descriptions-item :key="index" label="课程代码">
                  {{ item.courseCode }}
                </el-descriptions-item>
                <el-descriptions-item :key="index" label="课程名称">
                  {{ item.courseName }}
                </el-descriptions-item>
                <el-descriptions-item :key="index" label="课程层次">
                  {{ parseCourseLevel(item.courseLevel) }}
                </el-descriptions-item>
                <el-descriptions-item :key="index" label="客观题总分">
                  {{ item.objectiveTotalScore }}
                </el-descriptions-item>
                <el-descriptions-item :key="index" label="学习中心">
                  {{ item.orgName }}
                </el-descriptions-item>
                <el-descriptions-item :key="index" label="年级">
                  {{ item.grade }}
                </el-descriptions-item>
                <el-descriptions-item :key="index" label="校验次数">
                  {{ item.faceTotalCount }}
                </el-descriptions-item>
                <el-descriptions-item :key="index" label="成功次数">
                  {{ item.faceSuccessCount }}
                </el-descriptions-item>
                <el-descriptions-item :key="index" label="陌生人次数">
                  {{ item.faceStrangerCount }}
                </el-descriptions-item>
                <el-descriptions-item :key="index" label="人脸比对(%)">
                  {{ item.faceSuccessPercent }}
                </el-descriptions-item>
                <el-descriptions-item :key="index" label="人脸真实性(%)">
                  {{ item.baiduFaceLivenessSuccessPercent }}
                </el-descriptions-item>
                <el-descriptions-item :key="index" label="切屏次数">
                  {{ item.switchScreenCount }}
                </el-descriptions-item>
                <el-descriptions-item :key="index" label="审核结果">
                  {{ item.status }}
                </el-descriptions-item>
                <el-descriptions-item :key="index" label="违纪类型">
                  {{ item.disciplineType }}
                </el-descriptions-item>
                <el-descriptions-item :key="index" label="违纪说明">
                  <span
                    v-html="parseDisciplineDetail(item.disciplineDetail)"
                  ></span>
                </el-descriptions-item>
                <el-descriptions-item :key="index" label="虚拟设备">
                  {{ item.virtualCameraNames }}
                </el-descriptions-item>
                <el-descriptions-item :key="index" label="其它异常">
                  <span v-html="parseExamWarn(examWarns)"></span>
                </el-descriptions-item>
              </template>
            </el-descriptions>
          </el-col>
        </el-row>

        <el-row class="margin-top-10 photorow">
          <el-col
            v-for="item in photoCaptures"
            :key="item.id"
            :span="6"
            class="photocol"
          >
            <div v-show="item.pass" class="photo-pass">通过</div>
            <div v-show="!item.pass" class="photo-nopass">不通过</div>
            <img class="photo" :src="item.fileUrl" alt width="200" />
            <div v-show="item.stranger" class="photo-stranger">陌生人</div>
            <div
              v-show="!item.isFacelivenessPass"
              class="photo-facelivenessPass"
            >
              <i class="el-icon-warning" title="真实性不通过"></i>
            </div>
          </el-col>
        </el-row>

        <el-row class="margin-top-20">
          <el-col :span="24">
            <el-table :data="examProcessRecords" border>
              <el-table-column
                label="考试过程"
                prop="processName"
                width="180"
              ></el-table-column>
              <el-table-column
                label="记录时间"
                prop="recordTime"
                width="180"
              ></el-table-column>
              <el-table-column
                label="客户端IP"
                prop="sourceIp"
              ></el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </el-main>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "ExamRecordDetail",
  props: {
    examRecordDataId: {
      type: Number,
      default: null,
    },
    showAuditButton: {
      type: Boolean,
      default: false,
    },
    needLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      examRecordData: [],
      photoCaptures: [],
      examProcessRecords: [],
      examWarns: [],
      studentBasePhotoPath: "/admin/img/no-photo.jpg",
      syncCapturePhotoPath: "",
      showFirstCapturePhoto: false,
      needAudit: false,
      needDisable: false,
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
  },
  watch: {
    examRecordDataId() {
      this.initDetail();
    },
    needLoading(val) {
      console.log("needLoading:" + val);
    },
  },
  created() {
    this.initDetail();
  },
  methods: {
    changeExamRecordData(isNext) {
      this.needDisable = true;
      this.$emit("changeExamRecordData", isNext);

      setTimeout(() => {
        this.needDisable = false;
      }, 1000);
    },
    auditExamRecordData(isPass) {
      this.needDisable = true;
      this.$emit("auditExamRecordData", isPass);

      setTimeout(() => {
        this.needDisable = false;
      }, 1000);
    },
    initDetail() {
      console.log("initDetail... examRecordDataId:" + this.examRecordDataId);
      this.examRecordData = [];
      this.photoCaptures = [];
      this.examProcessRecords = [];
      this.examWarns = [];
      this.studentBasePhotoPath = "/admin/img/no-photo.jpg";
      this.syncCapturePhotoPath = "";
      this.showFirstCapturePhoto = false;
      this.needAudit = false;

      if (this.examRecordDataId) {
        this.getExamRecordData();
        this.getPhotoCaptures();
        this.getExamProcessRecords();
        this.getExamWarnList();
      }
    },
    getExamRecordData() {
      let param = new URLSearchParams({
        examRecordDataId: this.examRecordDataId,
      });
      this.$http
        .post("/api/ecs_oe_admin/exam/capture/audit/detail", param)
        .then((response) => {
          if (response.data) {
            let result = response.data;
            this.examRecordData = new Array(result);
            if (result.syncCaptureFileUrl) {
              this.syncCapturePhotoPath = result.syncCaptureFileUrl;
              this.showFirstCapturePhoto = true;
            }
            if (this.showAuditButton) {
              this.needAudit = result.isWarn && !result.isAudit;
            }
            this.getStudentInfo(result.studentId);
          }
        });
    },
    getStudentInfo(studentId) {
      this.$http
        .get("/api/ecs_core/student/getStudentInfo?studentId=" + studentId)
        .then((response) => {
          if (response.data.photoPath) {
            this.studentBasePhotoPath = response.data.photoPath;
          }
        });
    },
    getPhotoCaptures() {
      let param = new URLSearchParams({
        examRecordDataId: this.examRecordDataId,
      });
      this.$http
        .post("/api/ecs_oe_admin/exam/capture/list", param)
        .then((response) => {
          this.photoCaptures = response.data;
        });
    },
    getExamProcessRecords() {
      let url =
        "/api/ecs_oe_admin/exam/capture/getExamProcessRecords?examRecordDataId=" +
        this.examRecordDataId;
      this.$http.get(url).then((response) => {
        if (response.data) {
          this.examProcessRecords = response.data;
        }
      });
    },
    getExamWarnList() {
      let param = new URLSearchParams({
        examRecordDataId: this.examRecordDataId,
      });
      let url = "/api/ecs_oe_admin/exam/warn/list";
      this.$http.post(url, param).then((response) => {
        if (response.data) {
          this.examWarns = response.data;
        }
      });
    },
    parseCourseLevel(level) {
      if (level === "ZSB") {
        return "专升本";
      } else if (level === "GQZ") {
        return "高起专";
      } else if (level === "GQB") {
        return "高起本";
      } else {
        return "不限";
      }
    },
    parseDisciplineDetail: function (details) {
      if (!details) {
        return "";
      }

      if (details.indexOf("&&") < 0) {
        return details;
      }

      let result = "";
      let values = details.split("&&");
      for (let i = 0; i < values.length; i++) {
        result += values[i];
        if (i < values.length - 1) {
          result += "<br/>";
        }
      }
      return result;
    },
    parseExamWarn: function (examWarns) {
      if (!examWarns) {
        return "";
      }

      let result = "";
      for (let i = 0; i < examWarns.length; i++) {
        result += examWarns[i].warnDetail;
        if (i < examWarns.length - 1) {
          result += "<br/>";
        }
      }
      return result;
    },
  },
};
</script>

<style>
.el-descriptions-item__label {
  width: 100px;
}
</style>
<style scoped>
#capture-detail-header {
  height: 15px !important;
}
.capture-title {
  font-size: 20px;
  font-weight: bold;
}
.photorow {
  border: 1px solid #ebeef5;
  text-align: center;
}
.photocol {
  position: relative;
  margin: 20px 10px 0 10px;
}
.photo {
  display: block;
  width: 208px;
  height: 159px;
  object-fit: contain;
}
.photo-pass {
  position: absolute;
  top: 2px;
  width: 208px;
  text-align: center;
  color: white;
  font-size: 12px;
  background-color: rgba(10, 10, 10, 0.4);
}
.photo-nopass {
  position: absolute;
  top: 2px;
  width: 208px;
  text-align: center;
  color: red;
  font-size: 12px;
  background-color: rgba(10, 10, 10, 0.4);
}
.photo-stranger {
  position: absolute;
  top: 140px;
  width: 208px;
  text-align: center;
  color: red;
  font-size: 12px;
  background-color: rgba(10, 10, 10, 0.4);
}
.photo-facelivenessPass {
  position: absolute;
  top: 160px;
  width: 208px;
  font-size: 14px;
  text-align: center;
  color: red;
}
.syncPhto {
  margin-top: 30px;
  display: block;
  width: 180px;
  height: 159px;
  object-fit: contain;
}
</style>
<style scoped src="../style/common.css"></style>
