<template>
  <section class="content">
    <LinkTitlesCustom :current-paths="['word文件上传']" />
    <!-- 正文信息 -->
    <div
      v-loading.body="fileLoading"
      class="box-body"
      element-loading-text="试题上传中,请稍后..."
    >
      <el-form
        :inline="true"
        :model="formSearch"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-form-item label="课程名称" label-width="120px" class="pull-left">
            <el-select
              v-model="formSearch.courseNo"
              class="form_width"
              filterable
              :remote-method="getCourses"
              remote
              clearable
              placeholder="请选择"
              @change="searchCourseName"
              @focus="(e) => getCourses(e.target.value)"
            >
              <el-option
                v-for="item in courseInfoSelect"
                :key="item.courseNo"
                :label="item.courseInfo"
                :value="item.courseNo"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="pull-right">
            <el-button type="primary" @click="back"
              ><i class="el-icon-arrow-left"></i> 返回</el-button
            >
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="试卷名称" label-width="120px" class="pull-left">
            <el-input
              v-model="formSearch.name"
              placeholder="试卷名称"
              class="form_width"
            ></el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="总分校验" label-width="120px" class="pull-left">
            <el-radio v-model="scoreCheck" label="1">开启</el-radio>
            <el-radio v-model="scoreCheck" label="0">关闭</el-radio>
          </el-form-item>
        </el-row>
        <el-row v-if="scoreCheck == 1">
          <el-form-item label="试卷总分" label-width="120px" class="pull-left">
            <el-input
              v-model="formSearch.totalScore"
              placeholder="试卷总分"
              class="form_width"
            ></el-input>
          </el-form-item>
        </el-row>
        <!--
              <el-row>
                  <el-form-item label="相同大题名称" label-width="120px" class="pull-left">
                      <el-radio  v-model="formSearch.sameName"  label="1">合并</el-radio>
                      <el-radio  v-model="formSearch.sameName"  label="0">不合并</el-radio>
                  </el-form-item>
              </el-row>
            -->
        <el-row>
          <el-form-item>
            <el-upload
              ref="upload"
              class="form_left"
              accept=".docx,.json"
              :action="uploadAction"
              :headers="uploadHeaders"
              :data="uploadData"
              :before-upload="beforeUpload"
              :on-progress="uploadProgress"
              :on-success="uploadSuccess"
              :on-error="uploadError"
              :file-list="fileList"
              :auto-upload="false"
              :multiple="false"
            >
              <el-button slot="trigger" type="primary"
                ><i class="el-icon-search"></i> 选择文件</el-button
              >
              <el-button
                style="margin-left: 10px"
                type="primary"
                @click="submitUpload"
                ><i class="el-icon-check"></i> 确认上传
              </el-button>
              <el-button
                style="margin-left: 10px"
                type="primary"
                @click="removeFile"
                ><i class="el-icon-refresh"></i> 清空文件
              </el-button>
              <div slot="tip" class="el-upload__tip">
                只能上传docx和json文件
              </div>
            </el-upload>
          </el-form-item>
        </el-row>
      </el-form>
      <el-dialog title="错误提示" :visible.sync="errDialog">
        <span style="font-size: large">{{ errMessage }} !</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="errDialog = false">确定</el-button>
        </span>
      </el-dialog>
    </div>
  </section>
</template>
<script>
import { CORE_API, QUESTION_API } from "@/constants/constants";
import { mapState } from "vuex";
import LinkTitlesCustom from "@/components/LinkTitlesCustom.vue";
export default {
  components: { LinkTitlesCustom },
  data() {
    return {
      scoreCheck: "0",
      formSearch: {
        courseNo: "",
        couresName: "",
        name: "",
        totalScore: "",
        courseLevel: "",
        sameName: "0",
      },
      courseList: [],
      uploadAction: "",
      errMessage: "",
      uploadData: {},
      fileLoading: false,
      button_tpye: false,
      errDialog: false,
      fileList: [],
      uploadHeaders: {},
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
    courseInfoSelect() {
      var courseList = [];
      for (let course of this.courseList) {
        var courseInfo = course.name + "(" + course.code + ")";
        var courseNo = course.code;
        courseList.push({ courseNo: courseNo, courseInfo: courseInfo });
      }
      return courseList;
    },
  },
  watch: {
    scoreCheck: function () {
      if (this.scoreCheck == 0) {
        this.formSearch.totalScore = "";
      }
    },
  },
  created() {
    this.uploadAction = QUESTION_API + "/importPaper";
    this.uploadData = { name: this.formSearch.name };
    this.uploadHeaders = {
      key: this.user.key,
      token: this.user.token,
    };
  },
  methods: {
    searchCourseName() {
      for (let course of this.courseList) {
        if (course.code == this.formSearch.courseNo) {
          this.formSearch.courseName = course.name;
          this.formSearch.courseLevel = course.level;
          console.log(this.formSearch.courseLevel);
        }
      }
    },
    beforeUpload(file) {
      console.log(file);
    },
    uploadProgress() {
      console.log("uploadProgress");
    },
    uploadSuccess(response) {
      this.$notify({
        message: "上传成功",
        type: "success",
      });
      this.fileLoading = false;
      sessionStorage.setItem("question_back", "true");
      this.$router.push({
        path: "/edit_paper/" + response.id + "/import_paper",
      });
    },
    uploadError(err) {
      var result = err.message.match(/\{.+}/);
      var errMessage = JSON.parse(result[0]).desc;
      this.errDialog = true;
      this.errMessage = errMessage;
      this.fileLoading = false;
    },
    initUpload() {
      this.fileList = [];
      this.formSearch.name = "";
      this.formSearch.courseNo = "";
    },
    checkUpload() {
      if (!this.formSearch.courseNo) {
        this.$notify({
          message: "课程名称不能为空",
          type: "error",
        });
        return false;
      } else {
        this.uploadData.courseNo = this.formSearch.courseNo;
        this.uploadData.courseName = this.formSearch.courseName;
        this.uploadData.level = this.formSearch.courseLevel;
        this.uploadData.sameName = this.formSearch.sameName;
      }
      if (!this.formSearch.name) {
        this.$notify({
          message: "试卷名称不能为空",
          type: "error",
        });
        return false;
      } else {
        this.uploadData.name = this.formSearch.name;
      }
      if (this.scoreCheck == 1 && !this.formSearch.totalScore) {
        this.$notify({
          message: "试卷总分不能为空",
          type: "error",
        });
        return false;
      } else {
        this.uploadData.totalScore = this.formSearch.totalScore;
      }
      var fileList = this.$refs.upload.uploadFiles;
      if (fileList.length == 0) {
        this.$notify({
          message: "上传文件不能为空",
          type: "error",
        });
        return false;
      }
      if (fileList.length > 1) {
        this.$notify({
          message: "每次只能上传一个word或json文件",
          type: "error",
        });
        return false;
      }
      for (let file of fileList) {
        if (!file.name.endsWith(".docx") && !file.name.endsWith(".json")) {
          this.$notify({
            message: "上传文件必须为docx或json格式",
            type: "error",
          });
          this.initUpload();
          return false;
        }
      }
      return true;
    },
    //确定上传
    submitUpload() {
      console.log("this form:", this.formSearch);
      if (!this.checkUpload()) {
        return false;
      }
      this.$refs.upload.submit();
      this.fileLoading = true;
    },
    //清空文件
    removeFile() {
      // this.fileList = [];
      this.$refs.upload.clearFiles();
    },
    //返回
    back() {
      this.$router.push({ path: "/questions/import_paper/0" });
    },
    //查询所有课程
    getCourses(query) {
      query = query.trim();
      this.courseLoading = true;
      this.$http
        .get(CORE_API + "/course/query?name=" + query + "&enable=true")
        .then((response) => {
          this.courseList = response.data;
          this.courseLoading = false;
        });
    },
  },
};
</script>
<style scoped>
.form_width {
  width: 200px;
}
.form_left {
  margin-left: 50px;
}
</style>
