<template>
  <el-dialog
    ref="dialog"
    title="添加中心"
    width="900px"
    :visible.sync="visible"
    @close="closeDialog"
  >
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      :inline="true"
      label-position="right"
      label-width="80px"
    >
      <el-row>
        <el-form-item label="中心名称">
          <el-input v-model="form.name" placeholder="请输入中心名称" />
        </el-form-item>
        <el-form-item label="中心代码">
          <el-input v-model="form.code" placeholder="请输入中心代码" />
        </el-form-item>
        <!-- <el-form-item label="负责人">
          <el-input placeholder="请输入中心名称" v-model="form.contact" />
        </el-form-item> -->
        <el-form-item>
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="search"
          >
            查询
          </el-button>
        </el-form-item>
      </el-row>

      <el-row>
        <div>
          <el-table
            ref="table"
            :data="tableData"
            border
            resizable
            stripe
            style="width: 100%"
          >
            <el-table-column type="selection" width="40" />
            <el-table-column width="85" label="中心ID">
              <span slot-scope="scope">{{ scope.row.id }}</span>
            </el-table-column>
            <el-table-column width="200" label="中心代码">
              <span slot-scope="scope">{{ scope.row.code }}</span>
            </el-table-column>
            <el-table-column label="中心名称">
              <span slot-scope="scope">{{ scope.row.name }}</span>
            </el-table-column>
            <el-table-column width="100" label="负责人">
              <span slot-scope="scope">{{ scope.row.contacts }}</span>
            </el-table-column>
          </el-table>
          <div class="page pull-right">
            <el-pagination
              v-if="paginationShow"
              :current-page="currentPage"
              :page-size="pageSize"
              :page-sizes="[10, 20, 50, 100, 200, 300]"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              @current-change="handleCurrentChange"
              @size-change="handleSizeChange"
            />
          </div>
        </div>
        <div style="margin-bottom: 20px"></div>
      </el-row>

      <el-row class="d-flex justify-content-center mt-2">
        <el-button type="primary" :loading="loading" @click="submitForm"
          >确 定</el-button
        >
        <el-button @click="closeDialog">取 消</el-button>
      </el-row>
    </el-form>
  </el-dialog>
</template>

<script>
import { CORE_API } from "@/constants/constants";
export default {
  name: "DataPrevillegeAddOrgDialog",
  props: {
    userId: { type: String, default: "" },
    rootOrgId: { type: String, default: "" },
  },
  data() {
    return {
      visible: false,
      form: {
        // parentId: this.$store.state.user.rootOrgId,
        name: "",
        code: "",
      },
      rules: {},
      loading: false,
      paginationShow: false,
      tableData: [],
      noBatchSelected: true,
      currentPage: 1,
      pageSize: 10,
      total: 10,
    };
  },
  methods: {
    async search() {
      var param = new URLSearchParams({
        ...this.form,
        parentId: this.rootOrgId,
      });
      var url =
        CORE_API +
        "/org/subOrgPage/" +
        (this.currentPage - 1) +
        "/" +
        this.pageSize +
        "?" +
        param;
      this.loading = true;
      return this.$httpWithMsg.get(url).then((response) => {
        this.tableData = response.data.list;
        this.total = response.data.total;
        this.loading = false;
        this.paginationShow = true;
      });
    },
    async openDialog() {
      this.visible = true;
      try {
        await this.search();
      } catch (error) {
        console.log(error);
        this.$notify({ type: "error", title: "获取中心列表失败" });
      }
    },
    closeDialog() {
      this.visible = false;
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.search();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.search();
    },
    async submitForm() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        console.log("校验失败", error);
        return;
      }

      const refIds = this.$refs.table.selection.map((v) => v.id);
      console.log(refIds);
      if (refIds.length === 0) {
        this.$notify({ type: "warning", message: "请先选择一行或多行" });
        return;
      }

      try {
        this.loading = true;
        // await saveActivity(data);
        await this.$httpWithMsg.post(CORE_API + "/user/data/rule/add", {
          refIds,
          type: "ORG",
          userId: this.userId,
        });
        this.$emit("reload");
        this.$notify({ title: "保存成功", type: "success" });
        this.closeDialog();
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style></style>
