<template>
  <div class="ckeditor">
    <textarea
      :id="id"
      :display="display"
      :value="value"
      class="el-textarea__inner"
    ></textarea>
  </div>
</template>

<script>
let inc = 0;
export default {
  props: {
    display: {
      type: String,
      default: "inline",
    },
    value: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: () => `editor-${++inc}`,
    },
    height: {
      type: String,
      default: "300px",
    },
    width: {
      type: String,
      default: "500px",
    },
    language: {
      type: String,
      default: "zh-cn",
    },
    extraplugins: {
      type: String,
      default: "",
    },
  },
  computed: {
    instance() {
      return window.CKEDITOR.instances[this.id];
    },
  },
  beforeUpdate() {
    if (this.value !== this.instance.getData()) {
      this.instance.setData(this.value);
    }
  },
  mounted() {
    let config = {
      language: this.language,
      height: this.height,
      width: this.width,
      extraPlugins: this.extraplugins + ",base64image,pastebase64,image2",
      toolbarGroups: [
        { name: "clipboard", groups: ["clipboard", "undo"] },
        {
          name: "editing",
          groups: ["find", "selection", "spellchecker", "editing"],
        },
        { name: "links", groups: ["links"] },
        { name: "insert", groups: ["insert"] },
        { name: "forms", groups: ["forms"] },
        { name: "tools", groups: ["tools"] },
        { name: "document", groups: ["mode", "document", "doctools"] },
        { name: "others", groups: ["others"] },
        "/",
        { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
        {
          name: "paragraph",
          groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"],
        },
        { name: "styles", groups: ["styles"] },
        { name: "colors", groups: ["colors"] },
        { name: "about", groups: ["about"] },
      ],
      removePlugins: "bidi,colorbutton,image",
      removeButtons:
        "Table,Font,FontSize,Styles,Format,ShowBlocks,Iframe,PageBreak,Smiley,Flash,Language,JustifyBlock,JustifyRight,JustifyCenter,JustifyLeft,CreateDiv,CopyFormatting,ImageButton,Button,HiddenField,Select,Textarea,TextField,Radio,Checkbox,Form,BGColor,SelectAll,Replace,Find,Templates,Print,Preview,NewPage,Save,Subscript,Superscript,HorizontalRule,Unlink,Link,Scayt,Cut,Copy,Paste,PasteText,PasteFromWord,Maximize,NumberedList,BulletedList,Indent,Outdent,Blockquote,About,RemoveFormat,Strike",
    };
    if (this.display !== "inline") {
      window.CKEDITOR.replace(this.id, config);
    } else {
      window.CKEDITOR.inline(this.id, config);
    }
    this.instance.on("change", () => {
      let html = this.instance.getData();
      if (html !== this.value) {
        this.$emit("input", html);
      }
    });
  },
  beforeDestroy() {
    if (this.instance) {
      this.instance.focusManager.blur(true);
      this.instance.destroy();
    }
  },
};
</script>
<style>
.ckeditor::after {
  content: "";
  display: table;
  clear: both;
}
.cke_button__image {
  display: none !important;
}
</style>
