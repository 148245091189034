<template>
  <el-container>
    <el-main class="el-main-padding">
      <commonFormVue :form="form" :get-exam-condition="getExamCondition">
        <el-row v-show="showAllCondition">
          <el-col :span="6">
            <el-form-item label="考试记录ID">
              <el-input
                v-model="form.examRecordDataId"
                class="form_search_width"
                size="small"
                placeholder="考试记录ID"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="审核结果">
              <el-select
                v-if="currentPagePrivileges.INVIGILATE_AUDIT_STATUS"
                v-model="form.status"
                class="form_search_width"
                size="small"
                clearable
                placeholder="全部"
              >
                <el-option value="PASS" label="通过"></el-option>
                <el-option value="UN_PASS" label="不通过"></el-option>
              </el-select>
              <el-input
                v-if="!currentPagePrivileges.INVIGILATE_AUDIT_STATUS"
                size="small"
                class="form_search_width"
                placeholder="不通过"
                :disabled="true"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="审核说明">
              <el-select
                v-model="form.disciplineType"
                filterable
                remote
                :remote-method="getDisciplineTypeList"
                clearable
                class="form_search_width"
                placeholder="全部"
                size="small"
                @clear="getDisciplineTypeList"
              >
                <el-option
                  v-for="item in disciplineTypeList"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="审核人">
              <el-input
                v-model="form.auditUserName"
                :disabled="form.auditType === '1'"
                class="form_search_width"
                size="small"
                placeholder="审核人"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="审核时间">
              <el-date-picker
                v-model="auditExamDatetimeRange"
                class="input"
                type="datetimerange"
                start-placeholder="开始日期"
                range-separator="至"
                end-placeholder="结束日期"
                value-format="yyyy/MM/dd HH:mm:ss"
                :clearable="false"
                size="small"
                @change="changeAuditExamDatetimeRange"
              ></el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item label="切屏次数">
              <el-input
                v-model="form.switchScreenCountStart"
                style="width: 80px"
                size="small"
              ></el-input>
              至
              <el-input
                v-model="form.switchScreenCountEnd"
                style="width: 80px"
                size="small"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item label="审核类型">
              <el-select
                v-model="form.auditType"
                class="form_search_width"
                size="small"
                clearable
                placeholder="全部"
              >
                <el-option value="1" label="系统审核"></el-option>
                <el-option value="0" label="人工审核"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="开考时间">
              <el-date-picker
                v-model="startExamDatetimeRange"
                class="input"
                type="datetimerange"
                start-placeholder="开始日期"
                range-separator="至"
                end-placeholder="结束日期"
                value-format="yyyy/MM/dd HH:mm:ss"
                :clearable="false"
                size="small"
                @change="changeStartExamDatetimeRange"
              ></el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="交卷时间">
              <el-date-picker
                v-model="endExamDatetimeRange"
                class="input"
                type="datetimerange"
                start-placeholder="开始日期"
                range-separator="至"
                end-placeholder="结束日期"
                value-format="yyyy/MM/dd HH:mm:ss"
                :clearable="false"
                size="small"
                @change="changeEndExamDatetimeRange"
              ></el-date-picker>
            </el-form-item>
          </el-col>

          <!--          <el-col :span="6">
            <el-form-item label="Ip">
              <el-input
                v-model="form.ip"
                class="form_search_width"
                size="small"
                placeholder="Ip"
              ></el-input>
            </el-form-item>
          </el-col>-->
        </el-row>
      </commonFormVue>
      <el-col :span="24">
        <el-button
          size="small"
          type="primary"
          icon="el-icon-search"
          @click="search('clickSelectBtn')"
          >查询</el-button
        >
        <el-button
          v-if="!showAllCondition"
          size="small"
          type="primary"
          icon="el-icon-more"
          @click="showMoreCondition"
          >高级查询</el-button
        >
        <el-button
          v-if="showAllCondition"
          size="small"
          type="primary"
          @click="showSimpleCondition"
          >简单查询</el-button
        >
        <el-button
          size="small"
          icon="el-icon-refresh"
          class="margin-bottom-10"
          @click="resetForm"
          >重置</el-button
        >
      </el-col>
      <el-row>
        <el-col>
          <div class="block-seperator"></div>
          <span>操作：</span>
          <el-button
            type="primary"
            size="small"
            icon="el-icon-download"
            @click="exportData"
            >导出</el-button
          >

          <el-dropdown
            v-show="currentPagePrivileges.ALREADY_AUDITED_REDO_AUDIT"
          >
            <el-button
              style="margin-left: 10px"
              icon="el-icon-arrow-down"
              type="primary"
              :disabled="noBatchSelected"
              size="small"
              >重审</el-button
            >

            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <el-button
                  size="mini"
                  type="success"
                  icon="el-icon-success"
                  @click="redoAuditBatch('pass')"
                  >通&nbsp;&nbsp;过</el-button
                >
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button
                  size="mini"
                  type="danger"
                  icon="el-icon-error"
                  @click="redoAuditBatch('nopass')"
                  >不通过</el-button
                >
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <el-button
            v-if="form.updateTimeDesc !== true"
            style="margin-left: 10px"
            icon="el-icon-sort"
            size="small"
            @click="sortByUpdateTimeDesc(true)"
            >排序</el-button
          >
          <el-button
            v-if="form.updateTimeDesc === true"
            style="margin-left: 10px"
            icon="el-icon-bottom"
            type="primary"
            size="small"
            @click="sortByUpdateTimeDesc(false)"
            >更新时间排序</el-button
          >
        </el-col>
      </el-row>
      <el-row class="margin-top-10">
        <el-col :span="24">
          <el-table
            ref="multipleTable"
            v-loading="tableLoading"
            element-loading-text="数据加载中"
            :data="tableData"
            border
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="40"></el-table-column>
            <el-table-column label="考试记录ID" width="95">
              <template slot-scope="scope">
                <el-button
                  v-show="currentPagePrivileges.SNAPSHOT_DETAILS"
                  type="text"
                  @click="gotoCaptureDetail(scope.row.examRecordDataId)"
                  >{{ scope.row.examRecordDataId }}</el-button
                >
                <span v-show="!currentPagePrivileges.SNAPSHOT_DETAILS">{{
                  scope.row.examRecordDataId
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              min-width="100"
              label="姓名"
              prop="studentName"
            ></el-table-column>
            <el-table-column
              label="身份证号"
              prop="identityNumber"
              min-width="100"
            ></el-table-column>
            <el-table-column
              label="学号"
              prop="studentCode"
              min-width="100"
            ></el-table-column>
            <el-table-column
              label="课程"
              prop="courseName"
              min-width="90"
            ></el-table-column>
            <el-table-column
              label="课程层次"
              prop="courseLevel"
              width="80"
            ></el-table-column>
            <el-table-column
              label="校验次数"
              prop="faceTotalCount"
              width="80"
            ></el-table-column>
            <el-table-column
              label="成功次数"
              prop="faceSuccessCount"
              width="80"
            ></el-table-column>
            <el-table-column
              label="陌生人次数"
              prop="faceStrangerCount"
              width="95"
            ></el-table-column>
            <el-table-column
              label="人脸比对(%)"
              prop="faceSuccessPercent"
              width="100"
            ></el-table-column>
            <el-table-column
              label="切屏次数"
              prop="switchScreenCount"
              width="80"
            ></el-table-column>
            <el-table-column
              label="考试次数"
              prop="examOrder"
              width="80"
            ></el-table-column>
            <el-table-column label="场次" min-width="90">
              <template slot-scope="scope">
                <el-popover trigger="hover" placement="left">
                  <div style="font-size: 18px; font-family: 新宋体">
                    <tr>
                      <td style="color: green">场次序号</td>
                      <td style="color: purple; padding-left: 20px">
                        {{ scope.row.examStageOrder }}
                      </td>
                    </tr>
                    <tr>
                      <td style="color: green">开始时间</td>
                      <td style="color: purple; padding-left: 20px">
                        {{ scope.row.startTime }}
                      </td>
                    </tr>
                    <tr>
                      <td style="color: green">结束时间</td>
                      <td style="color: purple; padding-left: 20px">
                        {{ scope.row.endTime }}
                      </td>
                    </tr>
                  </div>

                  <div slot="reference" class="name-wrapper">
                    <span>{{ scope.row.examStageOrder }}</span>
                  </div>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column
              label="开考时间"
              prop="paperStartTime"
              width="180"
            ></el-table-column>
            <el-table-column
              label="交卷时间"
              prop="paperSubmitTime"
              width="180"
            ></el-table-column>
            <el-table-column
              label="审核说明"
              prop="disciplineType"
              width="150"
            ></el-table-column>
            <el-table-column
              label="审核人"
              prop="auditUserName"
              width="120"
            ></el-table-column>
            <el-table-column
              label="审核时间"
              prop="paperAuditTime"
              width="180"
            ></el-table-column>
            <el-table-column label="IP" prop="ip" width="125">
              <template slot-scope="scope">
                {{ showShortIpsStr(scope.row.ip, ",") }}
              </template>
            </el-table-column>
            <el-table-column
              fixed="right"
              label="审核结果"
              prop="status"
              width="80"
            ></el-table-column>
          </el-table>
          <div class="block pull-right">
            <el-pagination
              :current-page.sync="form.pageNo"
              :page-sizes="[10, 20, 50, 100, 200, 300]"
              :page-size="form.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            ></el-pagination>
          </div>
        </el-col>
      </el-row>
      <el-dialog
        title="重审"
        width="400px"
        :visible.sync="dialogAuditFormVisible"
        @closed="auditDialogClosed"
      >
        <el-form ref="redoAuditForm" :model="auditForm">
          <el-form-item
            label="违纪类型"
            prop="illegallyTypeId"
            :rules="[
              { required: true, message: '请选择违纪类型', trigger: 'change' },
            ]"
          >
            <el-select
              v-model="auditForm.illegallyTypeId"
              filterable
              remote
              :remote-method="getDisciplineTypeList"
              clearable
              placeholder="请选择"
              size="small"
              @clear="getDisciplineTypeList"
            >
              <el-option
                v-for="item in disciplineTypeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="详情描述" style="margin-top: 15px">
            <el-input
              v-model="auditForm.disciplineDetail"
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 5 }"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <div class="dialog-footer margin-top-10 text-center">
            <el-button type="primary" @click="doRedoAuditNoPass"
              >确 定</el-button
            >
            <el-button @click="dialogAuditFormVisible = false">取 消</el-button>
          </div>
        </el-form>
      </el-dialog>

      <el-dialog
        title="考试记录详情"
        width="1250px"
        :visible.sync="examRecordDataDialog"
        @close="examRecordDataDialogClose"
      >
        <ExamRecordDetail
          :exam-record-data-id="curSelectedExamRecordDataId"
          :show-audit-button="curSelectedShowAuditButton"
          :need-loading="needLoading"
          @changeExamRecordData="changeExamRecordData"
          @auditExamRecordData="auditExamRecordData"
        ></ExamRecordDetail>
      </el-dialog>
    </el-main>
  </el-container>
</template>
<script>
import { mapState } from "vuex";
import commonFormVue from "../component/commonForm.vue";
import { AUDIT_EXPLAIN_LIST } from "../constants/constants";
import pagePrivilege from "../mixin/pagePrivilege.js";
import _ from "lodash";
import { showShortIpsStr } from "@/utils/utils.js";
export default {
  components: { commonFormVue },
  mixins: [pagePrivilege],
  data() {
    return {
      showShortIpsStr,
      curSelectedExamRecordDataId: null,
      curSelectedShowAuditButton: false,
      needLoading: false,
      examRecordDataDialog: false,
      toNext: false,
      curTotalPages: 0,
      auditExplainList: AUDIT_EXPLAIN_LIST,
      total: 0,
      tableLoading: false,
      showAllCondition: false,
      form: {
        switchScreenCountStart: null,
        switchScreenCountEnd: null,
        examRecordDataId: null,
        hasStranger: null,
        courseId: null,
        courseLevel: null,
        examId: null,
        examStageId: null,
        faceSuccessPercentLower: null,
        faceSuccessPercentUpper: null,
        identityNumber: null,
        orgId: null,
        studentCode: null,
        studentName: null,
        isWarn: null,
        pageNo: 1,
        pageSize: 10,
        disciplineType: "",
        auditUserName: "",
        auditType: "",
        status: "",
        ORG_FIND_ALL: false, //查询所有机构
        startTime: null, //开考时间起
        endTime: null, //开考时间止
        submitStartTime: null, //交卷时间起
        submitEndTime: null, //交卷时间止
        auditStartTime: null, //审核时间起
        auditEndTime: null, //审核时间止
        ip: null, //Ip
        updateTimeDesc: null,
      },
      exportUrl: "/api/ecs_oe_admin/exam/audit/export/async",
      getExamCondition: {
        params: {
          name: "",
          examTypes: "ONLINE#ONLINE_HOMEWORK",
          propertyKeys: "IS_FACE_ENABLE",
        },
        filterCondition: "IS_FACE_ENABLE",
      },
      tableData: [],
      currentPagePrivileges: {
        INVIGILATE_AUDIT_STATUS: false, //数据状态
        SNAPSHOT_DETAILS: false, //详情查看
        ALREADY_AUDITED_REDO_AUDIT: false, //重新审核
      },
      getPermissionStatus: false, //获取权限状态
      disciplineTypeList: [],
      startExamDatetimeRange: [],
      endExamDatetimeRange: [],
      auditExamDatetimeRange: [],
      isOnlineExam: false,
      dialogAuditFormVisible: false,
      selectedIds: [],
      auditForm: {
        examRecordDataIds: [],
        illegallyTypeId: null,
        disciplineDetail: "",
        isPass: null,
      },
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
    noBatchSelected() {
      if (!this.isOnlineExam) {
        return true;
      }
      if (this.selectedIds.length === 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    //监控权限是否获取完成
    getPermissionStatus: function (val) {
      if (val) {
        //需求调整20200924：经沟通暂时注释此需求
        // if (!this.currentPagePrivileges.INVIGILATE_AUDIT_STATUS) {
        //   this.form.status = "UN_PASS";
        // }
        // this.backFill();
      }
    },
  },
  created() {
    // this.backFill();
    this.getDisciplineTypeList("");
  },
  methods: {
    checkParam() {
      var reg = /(^0$)|(^[1-9][0-9]*$)/;
      if (this.form.switchScreenCountStart) {
        if (!reg.test(this.form.switchScreenCountStart)) {
          this.$notify({
            title: "警告",
            message: "切屏次数起始值错误",
            type: "warning",
            duration: 2000,
          });
          return false;
        }
      }
      if (this.form.switchScreenCountEnd) {
        if (!reg.test(this.form.switchScreenCountEnd)) {
          this.$notify({
            title: "警告",
            message: "切屏次数截止值错误",
            type: "warning",
            duration: 2000,
          });
          return false;
        }
      }
      return true;
    },
    doRedoAuditNoPass() {
      this.$refs["redoAuditForm"].validate((valid) => {
        if (valid) {
          var redoAuditInfo = {
            examRecordDataIds: this.auditForm.examRecordDataIds,
            isPass: false,
            illegallyTypeId: this.auditForm.illegallyTypeId,
            disciplineDetail: this.auditForm.disciplineDetail,
          };
          this.$http
            .post("/api/ecs_oe_admin/exam/audit/redoAudit", redoAuditInfo)
            .then(() => {
              this.$notify({
                title: "成功",
                message: "操作成功",
                type: "success",
              });
              this.doRedoAuditNoPassPostProcess();
              this.search();
            })
            .catch((res) => {
              var errorMsg = "操作失败";
              if (res.response && res.response.data) {
                errorMsg = res.response.data.desc;
              }
              this.doRedoAuditNoPassPostProcess();
              this.$notify({
                title: "提示",
                message: errorMsg,
                type: "error",
              });
            });
        } else {
          return false;
        }
      });
    },
    doRedoAuditNoPassPostProcess() {
      this.auditForm = {
        examRecordDataId: null,
        illegallyTypeId: null,
        disciplineDetail: "",
        isPass: null,
      };
      this.$refs["redoAuditForm"].resetFields();
      this.dialogAuditFormVisible = false;
    },
    auditDialogClosed() {
      this.$refs["redoAuditForm"].resetFields();
    },
    redoAuditBatch(isPass) {
      this.auditForm.examRecordDataIds = this.selectedIds;
      if (isPass != "pass") {
        this.dialogAuditFormVisible = true;
      } else {
        //审核通过
        var redoAuditInfo = {
          examRecordDataIds: this.auditForm.examRecordDataIds,
          isPass: true,
          illegallyTypeId: null,
          disciplineDetail: "",
        };
        this.$http
          .post("/api/ecs_oe_admin/exam/audit/redoAudit", redoAuditInfo)
          .then(() => {
            this.$notify({
              title: "成功",
              message: "操作成功",
              type: "success",
            });
            this.search();
          })
          .catch((res) => {
            var errorMsg = "操作失败";
            if (res.response && res.response.data) {
              errorMsg = res.response.data.desc;
            }
            this.$notify({
              title: "提示",
              message: errorMsg,
              type: "error",
            });
          });
      }
    },
    resetForm() {
      this.form = {
        examRecordDataId: null,
        hasStranger: null,
        courseId: null,
        courseLevel: null,
        examId: null,
        examStageId: null,
        faceSuccessPercentLower: null,
        faceSuccessPercentUpper: null,
        identityNumber: null,
        orgId: this.form.ORG_FIND_ALL ? null : this.form.orgId,
        studentCode: null,
        studentName: null,
        isWarn: null,
        pageNo: 1,
        pageSize: 10,
        disciplineType: "",
        auditUserName: "",
        status: "",
        ORG_FIND_ALL: this.form.ORG_FIND_ALL,
        startTime: null, //开考时间起
        endTime: null, //开考时间止
        submitStartTime: null, //交卷时间起
        submitEndTime: null, //交卷时间止
        auditStartTime: null, //审核时间起
        auditEndTime: null, //审核时间止
        updateTimeDesc: null,
      };
      this.startExamDatetimeRange = [];
      this.endExamDatetimeRange = [];
      this.auditExamDatetimeRange = [];
    },
    showMoreCondition() {
      this.showAllCondition = true;
    },
    sortByUpdateTimeDesc(value) {
      this.form.updateTimeDesc = value && value === true;
    },
    showSimpleCondition() {
      this.$notify({
        title: "提示",
        message: "高级查询条件值已重置",
        type: "info",
        duration: 2000,
      });
      this.resetForm();
      this.showAllCondition = false;
    },
    async search(type) {
      if (!this.form.examId) {
        this.$notify({
          title: "警告",
          message: "请选择考试",
          type: "warning",
          duration: 2000,
        });
        return false;
      }
      if (!this.checkParam()) {
        return false;
      }
      if (type && type == "clickSelectBtn") {
        this.form.pageNo = 1;
      }
      this.tableLoading = true;
      var params = JSON.parse(JSON.stringify(this.form));

      // if (!this.currentPagePrivileges.INVIGILATE_AUDIT_STATUS) {
      //   params.status = "UN_PASS";
      // }

      await this.$http
        .post("/api/ecs_oe_admin/exam/audit/list", params)
        .then((response) => {
          if (response.data) {
            this.tableData = response.data.content;
            this.total = response.data.totalElements;
            this.form.pageNo = response.data.number + 1;
            this.curTotalPages = response.data.totalPages;
          } else {
            this.tableData = [];
          }
          this.tableLoading = false;
          // this.$router.push({
          //   path: "/oe/alreadyAudited?" + new URLSearchParams(params),
          // });
        });
    },
    selectable(row) {
      return row.isWarn;
    },
    handleSelectionChange(row) {
      this.selectedIds = [];
      row.forEach((element) => {
        if (element.examType == "ONLINE") {
          this.isOnlineExam = true;
        } else {
          this.isOnlineExam = false;
        }
        this.selectedIds.push(element.examRecordDataId);
      });
    },
    /**
     * pagesize改变时触发
     */
    handleSizeChange(val) {
      this.form.pageSize = val;
      this.search();
    },
    /**
     * 当前页改变时触发
     */
    handleCurrentChange(val) {
      this.form.pageNo = val;
      this.search();
    },
    curSelectedRow(examRecordDataId) {
      for (let n = 0; n < this.tableData.length; n++) {
        let row = this.tableData[n];
        if (examRecordDataId === row.examRecordDataId) {
          this.$refs["multipleTable"]?.toggleRowSelection(row, true);
        } else {
          this.$refs["multipleTable"]?.toggleRowSelection(row, false);
        }
      }
    },
    examRecordDataDialogClose() {
      this.examRecordDataDialog = false;
    },
    examRecordDataDialogOpen(examRecordDataId, showAuditButton) {
      this.examRecordDataDialog = true;
      this.curSelectedShowAuditButton = showAuditButton;
      this.curSelectedExamRecordDataId = examRecordDataId;
      this.curSelectedRow(examRecordDataId);
    },
    async changeExamRecordData(isNext) {
      // console.log("isNext:" + isNext);
      let curId = null;
      let isFirst = false;
      let isLast = false;
      let rowSize = this.tableData.length;
      for (let n = 0; n < rowSize; n++) {
        isFirst = n === 0;
        isLast = n === rowSize - 1;
        let row = this.tableData[n];
        if (this.curSelectedExamRecordDataId === row.examRecordDataId) {
          curId = row.examRecordDataId;
          if (isNext) {
            if (!isLast) {
              curId = this.tableData[n + 1].examRecordDataId;
            } else {
              if (this.form.pageNo < this.curTotalPages) {
                // 跳到下一页
                curId = null;
                this.form.pageNo = this.form.pageNo + 1;
                console.log("+++>toPage:" + this.form.pageNo);
                this.needLoading = true;
                await this.search();
                this.needLoading = false;

                if (this.tableData.length > 0) {
                  curId = this.tableData[0].examRecordDataId;
                }
              } else {
                this.$message({
                  message: "当前数据为最后一条！",
                  type: "warning",
                  showClose: true,
                });
              }
            }
          } else {
            if (!isFirst) {
              curId = this.tableData[n - 1].examRecordDataId;
            } else {
              if (this.form.pageNo > 1) {
                // 跳到上一页
                curId = null;
                this.form.pageNo = this.form.pageNo - 1;
                console.log("--->toPage:" + this.form.pageNo);
                this.needLoading = true;
                await this.search();
                this.needLoading = false;

                if (this.tableData.length > 0) {
                  curId =
                    this.tableData[this.tableData.length - 1].examRecordDataId;
                }
              } else {
                this.$message({
                  message: "当前数据为第一条！",
                  type: "warning",
                  showClose: true,
                });
              }
            }
          }
          break;
        }
      }

      if (curId) {
        this.curSelectedExamRecordDataId = curId;
        this.curSelectedRow(curId);
      } else {
        this.examRecordDataDialogClose();
      }
    },
    auditExamRecordData(isPass) {
      console.log("isPass:" + isPass);
    },
    gotoCaptureDetail(examRecordDataId) {
      this.examRecordDataDialogOpen(examRecordDataId, false);
      // this.$router.push({
      //   path: "/oe/captureDetail/" + examRecordDataId + "/alreadyAudited",
      // });
    },
    backFill() {
      var formData = this.$route.query;
      if (formData && formData.examId) {
        for (var attr in formData) {
          var value = formData[attr];
          if (value && value != "null") {
            //是数字 且 长度小于15
            if (!isNaN(value) && value.toString().length < 15) {
              value = _.toNumber(value);
            }
            this.form[attr] = value;
          }
        }
        this.search();
      }
    },
    getDisciplineTypeList(name) {
      if (!name) {
        name = "";
      }
      this.$http
        .get("/api/ecs_oe_admin/illegallyType/queryByNameLike", {
          params: { name },
        })
        .then((response) => {
          if (response.data) {
            //排除断电和机器故障
            this.disciplineTypeList = response.data.filter(
              (v) =>
                !(v.rootOrgId == -1 && v.code == "POWER_FAILURE") &&
                !(v.rootOrgId == -1 && v.code == "MACHINE_STOPPAGE")
            );
          }
        });
    },
    exportData() {
      if (!this.form.examId) {
        this.$notify({
          title: "警告",
          message: "请选择考试",
          type: "warning",
          duration: 1000,
        });
        return false;
      }
      if (!this.checkParam()) {
        return false;
      }

      this.$confirm("确定执行导出?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.form.rootOrgId = this.user.rootOrgId;
        this.form.creator = this.user.userId;
        this.$http
          .get(
            this.exportUrl +
              "?$key=" +
              this.user.key +
              "&$token=" +
              this.user.token,
            {
              params: {
                query: this.form,
              },
            }
          )
          .then(() => {
            this.$notify({
              type: "success",
              message: "正在后台导出中，请稍后到“导出任务列表”中下载！",
            });
          })
          .catch((error) => {
            this.$notify({
              type: "error",
              message: error.response.data.desc,
            });
          });
      });
    },
    changeStartExamDatetimeRange(e) {
      if (e && e.length > 0) {
        this.form.startTime = e[0];
        this.form.endTime = e[1];
      } else {
        this.form.startTime = "";
        this.form.endTime = "";
      }
    },
    changeEndExamDatetimeRange(e) {
      if (e && e.length > 0) {
        this.form.submitStartTime = e[0];
        this.form.submitEndTime = e[1];
      } else {
        this.form.submitStartTime = "";
        this.form.submitEndTime = "";
      }
    },
    changeAuditExamDatetimeRange(e) {
      if (e && e.length > 0) {
        this.form.auditStartTime = e[0];
        this.form.auditEndTime = e[1];
      } else {
        this.form.auditStartTime = "";
        this.form.auditEndTime = "";
      }
    },
  },
};
</script>
<style scoped src="../style/common.css"></style>
