<template>
  <div>
    <LinkTitlesCustom :current-paths="['评卷总览', '评卷进度', '课程详情']" />
    <section class="content">
      <div class="box box-info">
        <div class="box-body">
          <el-form
            :inline="true"
            :model="formSearch"
            label-position="right"
            label-width="60px"
          >
            <el-form-item label="登录名" class="pull-left">
              <el-input
                v-model="formSearch.userLoginName"
                placeholder="登录名"
              ></el-input>
            </el-form-item>
            <el-form-item label="姓名" class="pull-left">
              <el-input
                v-model="formSearch.userName"
                placeholder="姓名"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                size="small"
                type="primary"
                icon="el-icon-search"
                @click="searchSetting"
                >查询</el-button
              >
              <!--
                <el-button size="small"  type="primary" icon="info" @click="exportMarker">导出</el-button>
              -->
              <el-button
                size="small"
                type="primary"
                icon="el-icon-arrow-left"
                @click="back"
                >返回</el-button
              >
            </el-form-item>
          </el-form>
          <div class="block-seperator"></div>
          <!-- 页面列表 -->
          <el-table
            v-loading="loading"
            stripe
            element-loading-text="拼命加载中"
            :data="tableData"
            border
            style="width: 100%"
          >
            <el-table-column label="姓名" width="200" prop="userName" />
            <el-table-column
              label="登录名"
              min-width="100"
              prop="userLoginName"
            />
            <el-table-column
              label="试卷类型"
              min-width="200"
              prop="markRangeName"
            />
            <el-table-column
              label="完成数量"
              min-width="100"
              prop="markedCount"
              sortable
            />
            <el-table-column
              label="最低分"
              min-width="100"
              prop="minScore"
              sortable
            />
            <el-table-column
              label="最高分"
              min-width="100"
              prop="maxScore"
              sortable
            />
            <el-table-column
              label="平均分"
              min-width="100"
              prop="avgScore"
              sortable
            />
            <el-table-column
              label="标准方差"
              min-width="100"
              prop="stdDev"
              sortable
            />
          </el-table>
          <div class="page pull-right">
            <el-pagination
              background
              :current-page="currentPage"
              :page-size="pageSize"
              :page-sizes="[10, 20, 50, 100, 200, 300]"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              @current-change="handleSettingCurrentChange"
              @size-change="handleSizeChange"
            ></el-pagination>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { MARKING_LOGIC_API } from "@/constants/constants";
import { mapState } from "vuex";
import LinkTitlesCustom from "@/components/LinkTitlesCustom.vue";
export default {
  components: {
    LinkTitlesCustom,
  },
  data() {
    return {
      formSearch: {
        userLoginName: "",
        userName: "",
      },
      tableData: [],
      oldData: [],
      currentPage: 1,
      pageSize: 10,
      total: 10,
      loading: true,
      workId: "",
      courseCode: "",
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
  },
  created() {
    this.workId = this.$route.params.workId;
    this.courseCode = this.$route.params.courseCode;
    this.initSetting();
  },
  methods: {
    handleSettingCurrentChange(val) {
      this.currentPage = val;
      this.searchSetting();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.searchSetting();
    },
    pagingSetting() {
      var start = (this.currentPage - 1) * this.pageSize;
      var end =
        this.currentPage * this.pageSize < this.total
          ? this.currentPage * this.pageSize
          : this.total;
      var tempData = [];
      console.log(`当前页: ${this.currentPage},开始:${start},结束:${end}`);
      for (let i = start; i < end; i++) {
        tempData.push(this.tableData[i]);
      }
      this.tableData = tempData;
    },
    initSetting() {
      this.loading = true;
      this.$http
        .get(
          MARKING_LOGIC_API +
            "/markTasks/all/0/" +
            this.pageSize +
            "?workId=" +
            this.workId +
            "&courseCode=" +
            this.courseCode
        )
        .then((response) => {
          console.log(response);
          this.tableData = response.data.content;
          this.total = response.data.totalElements;
          this.loading = false;
        });
    },
    searchSetting() {
      this.loading = true;
      this.$http
        .get(
          MARKING_LOGIC_API +
            "/markTasks/all/" +
            (this.currentPage - 1) +
            "/" +
            this.pageSize +
            "?workId=" +
            this.workId +
            "&courseCode=" +
            this.courseCode,
          { params: this.formSearch }
        )
        .then((response) => {
          console.log(response);
          this.tableData = response.data.content;
          this.total = response.data.totalElements;
          this.loading = false;
        });
    },
    exportMarker() {
      window.location.href =
        "/api/ecs_marking/logic/markTasks/exportMarker?workId=" +
        this.workId +
        "&courseCode=" +
        this.courseCode;
    },
    back() {
      this.$router.back();
      // this.$router.push({
      //   path: "/marking/mark_work_overview"
      // });
    },
  },
};
</script>
<style lang="css" scoped>
li {
  list-style-type: none;
}

.searchFrame {
  margin-right: 10px;
  margin-bottom: 10px;
}

.page {
  margin-top: 10px;
}
.f_button {
  display: block;
  width: 57px;
  height: 20px;
  border: 1px solid #ccc;
  background: #fff;
  font-size: small;
}
</style>
