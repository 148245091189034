<template>
  <div>
    <LinkTitlesCustom
      :current-paths="['考试管理', '考试信息', '印刷考试设置']"
    />
    <section class="content">
      <div class="box box-info">
        <!-- 正文信息 -->
        <div class="box-body">
          <el-form
            ref="form"
            :inline="true"
            :rules="rules"
            :model="form"
            label-position="right"
            inline-message
          >
            <div style="margin-bottom: 10px">
              <el-button type="primary" size="small" @click="saveExam"
                >保 存</el-button
              >
              <el-button
                type="primary"
                size="small"
                icon="el-icon-arrow-left"
                @click="back"
                >返 回</el-button
              >
            </div>
            <el-tabs v-model="activeName" type="border-card">
              <!-- 基础信息 -->
              <el-tab-pane label="基础信息" name="tab1">
                <el-row v-if="examId != 'add'">
                  <el-form-item
                    label="ID"
                    prop="id"
                    :label-width="style.label_width_tab1"
                  >
                    <el-input
                      v-model="form.id"
                      class="input"
                      :disabled="true"
                      maxlength="20"
                    ></el-input>
                  </el-form-item>
                </el-row>
                <el-row v-if="examId != 'add'">
                  <el-form-item
                    label="考试编码"
                    placeholder="请输入考试编码"
                    prop="code"
                    :label-width="style.label_width_tab1"
                  >
                    <el-input
                      v-model="form.code"
                      class="input"
                      :disabled="true"
                      maxlength="20"
                    ></el-input>
                  </el-form-item>
                </el-row>
                <el-row v-else>
                  <el-form-item
                    label="考试编码"
                    placeholder="请输入考试编码"
                    prop="code"
                    :label-width="style.label_width_tab1"
                  >
                    <el-input
                      v-model="form.name"
                      class="input"
                      :disabled="true"
                      maxlength="20"
                    ></el-input>
                  </el-form-item>
                </el-row>
                <el-row>
                  <el-form-item
                    label="考试名称"
                    :label-width="style.label_width_tab1"
                    placeholder="请输入考试名称"
                    prop="name"
                  >
                    <el-input
                      v-model="form.name"
                      maxlength="20"
                      class="input"
                    ></el-input>
                  </el-form-item>
                </el-row>
                <el-row>
                  <el-form-item
                    label="考试类型"
                    :label-width="style.label_width_tab1"
                  >
                    <el-select
                      v-model="form.examType"
                      class="input"
                      :disabled="true"
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in examTypeList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-row>
                <el-row>
                  <el-form-item
                    label="状态"
                    :label-width="style.label_width_tab1"
                  >
                    <el-radio-group v-model="form.enable" class="input">
                      <el-radio label="true">启用</el-radio>
                      <el-radio label="false">禁用</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-row>
                <el-row>
                  <el-form-item
                    label="考试时间"
                    prop="examDatetimeRange"
                    :label-width="style.label_width_tab1"
                  >
                    <el-date-picker
                      v-model="examDatetimeRange"
                      class="input"
                      type="datetimerange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      :clearable="false"
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-row>
              </el-tab-pane>
            </el-tabs>
          </el-form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { EXAM_TYPE, EXAM_WORK_API } from "@/constants/constants.js";
import moment from "moment";
import LinkTitlesCustom from "@/components/LinkTitlesCustom.vue";

let _this = null;

let validateCode = (rule, value, callback) => {
  if (_this.examId == "add") {
    _this.form.code = _this.form.name;
  }
  let code = _this.form.code;
  if (code == "") {
    callback(new Error("请输入考试编码"));
    if (!_this.toActiveName) {
      _this.toActiveName = "tab1";
      _this.activeName = "tab1";
    }
  } else {
    callback();
  }
};

let validateName = (rule, value, callback) => {
  let name = _this.form.name;
  if (name == "") {
    callback(new Error("请输入考试名称"));
    _this.activeName = "tab1";
  } else {
    callback();
  }
};

let validateExamDatetimeRange = (rule, value, callback) => {
  let examDatetimeRange = _this.examDatetimeRange;
  if (!examDatetimeRange) {
    callback(new Error("请输入考试时间"));
    _this.activeName = "tab1";
  } else {
    callback();
  }
};

export default {
  components: { LinkTitlesCustom },
  data() {
    return {
      style: {
        label_width_tab1: "80px",
      },
      activeName: "tab1",
      examDatetimeRange: [],
      show_ckeditor: false,
      form: {
        started: false,
        name: "",
        examType: "PRINT_EXAM",
        examTimes: 1,
        beginTime: null,
        endTime: null,
        duration: 120,
        enable: "true",
        properties: {},
      },
      examTypeList: EXAM_TYPE,
      examId: "",
      rules: {
        code: [{ required: true, validator: validateCode, trigger: "blur" }],
        name: [{ required: true, validator: validateName, trigger: "blur" }],
        examDatetimeRange: [
          {
            required: true,
            validator: validateExamDatetimeRange,
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    _this = this;
    this.examId = this.$route.params.id;
    this.init();
  },

  methods: {
    init() {
      if (this.examId != "add") {
        let url = EXAM_WORK_API + "/exam/" + this.examId;
        this.$httpWithMsg.get(url).then((response) => {
          let body = response.data;
          body.properties = this.form.properties;
          this.form = Object.assign(this.form, response.data);
          this.form.enable = this.form.enable ? "true" : "false";
          this.examDatetimeRange = [this.form.beginTime, this.form.endTime];
          console.log("getOnlineExam(); form: ", this.form);

          let url = EXAM_WORK_API + "/exam/allProperties/" + this.examId;
          this.$httpWithMsg.get(url).then((response) => {
            this.form.properties = Object.assign(
              this.form.properties,
              response.data
            );
            this.show_ckeditor = true;
          });
        });
      } else {
        let now = moment().format("YYYY-MM-DD HH:mm:ss");
        this.examDatetimeRange = [now, now];
        this.show_ckeditor = true;
      }
    },
    saveExam: function () {
      this.form.beginTime = this.examDatetimeRange[0];
      this.form.endTime = this.examDatetimeRange[1];
      console.log(this.form);
      let url = EXAM_WORK_API + "/exam";
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.examId != "add") {
            this.$httpWithMsg.put(url, this.form).then((response) => {
              if (200 != response.status) {
                this.$notify({
                  type: "error",
                  message: response.body.desc,
                });
                return;
              }
              this.$notify({
                type: "success",
                message: "保存成功",
              });
            });
          } else {
            this.form.code = this.form.name;
            this.$httpWithMsg.post(url, this.form).then((response) => {
              console.log(response);
              this.$notify({
                type: "success",
                message: "新增成功",
              });
              this.examId = response.data.id;
              this.form.id = this.examId;
              this.$router.push({
                path: "/examwork/printExam/" + response.data.id,
              });
            });
          }
        } else {
          return false;
        }
      });
    },
    back() {
      this.$router.push({ path: "/examwork/examInfo" });
    },
  },
};
</script>
<style scoped>
.input {
  width: 440px;
}
.input >>> .el-input__inner {
  -webkit-appearance: button;
}
</style>
