<template>
  <el-container>
    <el-main class="el-main-padding">
      <commonFormVue :form="form" :get-exam-condition="getExamCondition">
        <el-col :span="6">
          <el-form-item label="违纪类型">
            <el-select
              v-model="form.disciplineType"
              filterable
              remote
              :remote-method="getDisciplineTypeList"
              clearable
              class="form_search_width"
              placeholder="全部"
              size="small"
              @clear="getDisciplineTypeList"
            >
              <el-option
                v-for="item in disciplineTypeList"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <!--        <el-col :span="6">
          <el-form-item label="Ip">
            <el-input
              v-model="form.ip"
              class="form_search_width"
              size="small"
              placeholder="Ip"
            ></el-input>
          </el-form-item>
        </el-col>-->
      </commonFormVue>
      <el-row>
        <el-col :span="24">
          <el-button
            size="small"
            icon="el-icon-search"
            type="primary"
            @click="search('clickSelectBtn')"
            >查询</el-button
          >
          <el-button
            size="small"
            icon="el-icon-refresh"
            style="margin-right: 10px"
            @click="resetForm"
            >重置</el-button
          >
        </el-col>
      </el-row>
      <el-row>
        <el-col v-show="currentPagePrivileges.BREACH_THE_PRINCIPLE_EXPORT">
          <div class="block-seperator"></div>
          <span>操作：</span>
          <commonExportVue
            :form="form"
            :export-url="exportUrl"
            :export-file-name="exportFileName"
          ></commonExportVue>
        </el-col>
      </el-row>
      <el-row class="margin-top-10">
        <el-col :span="24">
          <el-table
            ref="multipleTable"
            v-loading="tableLoading"
            element-loading-text="数据加载中"
            :data="tableData"
            border
            @selection-change="handleSelectionChange"
          >
            <el-table-column label="考试记录ID" width="95">
              <template slot-scope="scope">
                <el-button
                  v-show="currentPagePrivileges.SNAPSHOT_DETAILS"
                  type="text"
                  @click="gotoCaptureDetail(scope.row.examRecordDataId)"
                  >{{ scope.row.examRecordDataId }}</el-button
                >
                <span v-show="!currentPagePrivileges.SNAPSHOT_DETAILS">{{
                  scope.row.examRecordDataId
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              min-width="90"
              label="姓名"
              prop="studentName"
            ></el-table-column>
            <el-table-column
              label="身份证号"
              prop="identityNumber"
              min-width="100"
            ></el-table-column>
            <el-table-column
              label="学号"
              prop="studentCode"
              min-width="100"
            ></el-table-column>
            <el-table-column
              label="学习中心"
              prop="orgName"
              width="150"
            ></el-table-column>
            <el-table-column
              label="课程"
              prop="courseName"
              min-width="90"
            ></el-table-column>
            <el-table-column
              label="课程层次"
              prop="courseLevel"
              width="80"
            ></el-table-column>
            <el-table-column
              label="客观题总分"
              prop="objectiveScore"
              width="95"
            ></el-table-column>
            <el-table-column label="场次" min-width="90">
              <template slot-scope="scope">
                <el-popover trigger="hover" placement="left">
                  <div style="font-size: 18px; font-family: 新宋体">
                    <tr>
                      <td style="color: green">场次序号</td>
                      <td style="color: purple; padding-left: 20px">
                        {{ scope.row.examStageOrder }}
                      </td>
                    </tr>
                    <tr>
                      <td style="color: green">开始时间</td>
                      <td style="color: purple; padding-left: 20px">
                        {{ scope.row.startTime }}
                      </td>
                    </tr>
                    <tr>
                      <td style="color: green">结束时间</td>
                      <td style="color: purple; padding-left: 20px">
                        {{ scope.row.endTime }}
                      </td>
                    </tr>
                  </div>

                  <div slot="reference" class="name-wrapper">
                    <span>{{ scope.row.examStageOrder }}</span>
                  </div>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column
              label="校验次数"
              prop="faceTotalCount"
              width="80"
            ></el-table-column>
            <el-table-column
              label="成功次数"
              prop="faceSuccessCount"
              width="80"
            ></el-table-column>
            <el-table-column
              label="陌生人次数"
              prop="faceStrangerCount"
              width="95"
            ></el-table-column>
            <el-table-column
              label="人脸比对(%)"
              prop="faceSuccessPercent"
              width="100"
            ></el-table-column>
            <el-table-column label="IP" prop="ip" width="125">
              <template slot-scope="scope">
                {{ showShortIpsStr(scope.row.ip, ",") }}
              </template>
            </el-table-column>
            <el-table-column
              label="违纪描述"
              prop="disciplineDetail"
              width="150"
            >
              <template slot-scope="scope">
                <span
                  v-html="disciplineTypeFilter(scope.row.disciplineDetail)"
                ></span>
              </template>
            </el-table-column>
            <el-table-column
              fixed="right"
              label="违纪类型"
              prop="disciplineType"
              width="150"
            ></el-table-column>
          </el-table>
          <div class="block pull-right">
            <el-pagination
              :current-page.sync="form.pageNo"
              :page-sizes="[10, 20, 50, 100, 200, 300]"
              :page-size="form.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            ></el-pagination>
          </div>
        </el-col>
      </el-row>

      <el-dialog
        title="考试记录详情"
        width="1250px"
        :visible.sync="examRecordDataDialog"
        @close="examRecordDataDialogClose"
      >
        <ExamRecordDetail
          :exam-record-data-id="curSelectedExamRecordDataId"
          :show-audit-button="curSelectedShowAuditButton"
          :need-loading="needLoading"
          @changeExamRecordData="changeExamRecordData"
        ></ExamRecordDetail>
      </el-dialog>
    </el-main>
  </el-container>
</template>
<script>
import { mapState } from "vuex";
import commonFormVue from "../component/commonForm.vue";
import commonExportVue from "../component/commonExport.vue";
import pagePrivilege from "../mixin/pagePrivilege.js";
import { showShortIpsStr } from "@/utils/utils.js";
import _ from "lodash";
export default {
  components: { commonFormVue, commonExportVue },
  mixins: [pagePrivilege],
  data() {
    return {
      showShortIpsStr,
      curSelectedExamRecordDataId: null,
      curSelectedShowAuditButton: false,
      needLoading: false,
      examRecordDataDialog: false,
      toNext: false,
      curTotalPages: 0,
      disciplineTypeList: [],
      total: 0,
      tableLoading: false,
      exportLoading: false,
      form: {
        examRecordDataId: null,
        hasStranger: null,
        courseId: null,
        courseLevel: null,
        examId: null,
        faceSuccessPercentLower: null,
        faceSuccessPercentUpper: null,
        livenessSuccessPercentLower: null,
        livenessSuccessPercentUpper: null,
        identityNumber: null,
        orgId: null,
        orgName: null,
        studentCode: null,
        studentName: null,
        isWarn: null,
        pageNo: 1,
        pageSize: 10,
        ORG_FIND_ALL: false, //查询所有机构
        examStageId: null,
        ip: null,
      },

      getExamCondition: {
        params: {
          name: "",
          examTypes: "ONLINE",
          propertyKeys: "IS_FACE_ENABLE",
        },
        filterCondition: "IS_FACE_ENABLE",
      },
      tableData: [],
      exportUrl: "/api/ecs_oe_admin/exam/audit/discipline/list/export",
      exportFileName: "违纪名单",
      currentPagePrivileges: {
        BREACH_THE_PRINCIPLE_EXPORT: false,
        SNAPSHOT_DETAILS: false, //详情查看
      },
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
  },
  created() {
    this.form.rootOrgId = this.user.rootOrgId;
    // this.backFill();
    this.getDisciplineTypeList("");
  },
  methods: {
    getDisciplineTypeList(name) {
      if (!name) {
        name = "";
      }
      this.$http
        .get("/api/ecs_oe_admin/illegallyType/queryByNameLike", {
          params: { name },
        })
        .then((response) => {
          if (response.data) {
            //排除断电和机器故障
            this.disciplineTypeList = response.data.filter(
              (v) =>
                !(v.rootOrgId == -1 && v.code == "POWER_FAILURE") &&
                !(v.rootOrgId == -1 && v.code == "MACHINE_STOPPAGE")
            );
          }
        });
    },
    resetForm() {
      this.form = {
        examRecordDataId: null,
        hasStranger: null,
        courseId: null,
        courseLevel: null,
        examId: null,
        faceSuccessPercentLower: null,
        faceSuccessPercentUpper: null,
        livenessSuccessPercentLower: null,
        livenessSuccessPercentUpper: null,
        identityNumber: null,
        orgId: this.form.ORG_FIND_ALL ? null : this.form.orgId,
        orgName: null,
        studentCode: null,
        studentName: null,
        isWarn: null,
        pageNo: 1,
        pageSize: 10,
        ORG_FIND_ALL: this.form.ORG_FIND_ALL,
        examStageId: null,
      };
    },
    async search(type) {
      if (!this.form.examId) {
        this.$notify({
          title: "警告",
          message: "请选择考试",
          type: "warning",
          duration: 1000,
        });
        return false;
      }
      if (type && type == "clickSelectBtn") {
        this.form.pageNo = 1;
      }
      this.tableLoading = true;
      var params = JSON.parse(JSON.stringify(this.form));
      if (params.examStageId == "") {
        params.examStageId = null;
      }
      await this.$http
        .post("/api/ecs_oe_admin/exam/audit/discipline/list", params)
        .then((response) => {
          if (response.data) {
            this.tableData = response.data.content;
            this.total = response.data.totalElements;
            this.form.pageNo = response.data.number + 1;
            this.curTotalPages = response.data.totalPages;
          } else {
            this.tableData = [];
          }
          this.tableLoading = false;
          // this.$router.push({
          //   path: "/oe/illegalityNameList?" + new URLSearchParams(params),
          // });
        });
    },
    selectable(row) {
      return row.isWarn;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /**
     * pagesize改变时触发
     */
    handleSizeChange(val) {
      this.form.pageSize = val;
      this.search();
    },
    /**
     * 当前页改变时触发
     */
    handleCurrentChange() {
      this.search();
    },
    examRecordDataDialogClose() {
      this.examRecordDataDialog = false;
    },
    examRecordDataDialogOpen(examRecordDataId, showAuditButton) {
      this.examRecordDataDialog = true;
      this.curSelectedShowAuditButton = showAuditButton;
      this.curSelectedExamRecordDataId = examRecordDataId;
    },
    async changeExamRecordData(isNext) {
      // console.log("isNext:" + isNext);
      let curId = null;
      let isFirst = false;
      let isLast = false;
      let rowSize = this.tableData.length;
      for (let n = 0; n < rowSize; n++) {
        isFirst = n === 0;
        isLast = n === rowSize - 1;
        let row = this.tableData[n];
        if (this.curSelectedExamRecordDataId === row.examRecordDataId) {
          curId = row.examRecordDataId;
          if (isNext) {
            if (!isLast) {
              curId = this.tableData[n + 1].examRecordDataId;
            } else {
              if (this.form.pageNo < this.curTotalPages) {
                // 跳到下一页
                curId = null;
                this.form.pageNo = this.form.pageNo + 1;
                console.log("+++>toPage:" + this.form.pageNo);
                this.needLoading = true;
                await this.search();
                this.needLoading = false;

                if (this.tableData.length > 0) {
                  curId = this.tableData[0].examRecordDataId;
                }
              } else {
                this.$message({
                  message: "当前数据为最后一条！",
                  type: "warning",
                  showClose: true,
                });
              }
            }
          } else {
            if (!isFirst) {
              curId = this.tableData[n - 1].examRecordDataId;
            } else {
              if (this.form.pageNo > 1) {
                // 跳到上一页
                curId = null;
                this.form.pageNo = this.form.pageNo - 1;
                console.log("--->toPage:" + this.form.pageNo);
                this.needLoading = true;
                await this.search();
                this.needLoading = false;

                if (this.tableData.length > 0) {
                  curId =
                    this.tableData[this.tableData.length - 1].examRecordDataId;
                }
              } else {
                this.$message({
                  message: "当前数据为第一条！",
                  type: "warning",
                  showClose: true,
                });
              }
            }
          }
          break;
        }
      }

      if (curId) {
        this.curSelectedExamRecordDataId = curId;
      } else {
        this.examRecordDataDialogClose();
      }
    },
    gotoCaptureDetail(examRecordDataId) {
      this.examRecordDataDialogOpen(examRecordDataId, false);
      // this.$router.push({
      //   path: "/oe/captureDetail/" + examRecordDataId + "/illegalityNameList",
      // });
    },
    backFill() {
      var formData = this.$route.query;
      if (formData && formData.examId) {
        for (var attr in formData) {
          var value = formData[attr];
          if (value && value != "null") {
            //是数字 且 长度小于15
            if (!isNaN(value) && value.toString().length < 15) {
              value = _.toNumber(value);
            }
            this.form[attr] = value;
          }
        }
        this.search();
      }
    },
    disciplineTypeFilter: function (value) {
      if (value && value.indexOf("&&") > -1) {
        var arr = value.split("&&");
        var detail = "";
        for (var i = 0; i < arr.length; i++) {
          detail += arr[i] + "<br>";
        }
        return detail;
      } else {
        return value;
      }
    },
  },
};
</script>
<style scoped src="../style/common.css"></style>
