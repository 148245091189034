<template>
  <el-container>
    <el-main class="el-main-padding">
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="136px"
        class="demo-ruleForm"
        :inline-message="true"
      >
        <div style="margin-bottom: 10px">
          <el-select
            v-if="isSuperAdmin"
            v-model="ruleForm.orgId"
            placeholder="请选择"
            style="width: 180px"
            filterable
          >
            <el-option
              v-for="item in rootSchoolSelect"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
          <el-button
            style="margin-left: 10px"
            :disabled="btnSaveDiabled"
            type="primary"
            @click="submitForm('ruleForm')"
            >保 存</el-button
          >
        </div>
        <el-tabs v-model="tabActiveName" type="border-card">
          <el-tab-pane label="学生端配置" name="first">
            <el-form-item label="系统名称" prop="OE_STUDENT_SYS_NAME">
              <el-input
                v-model="ruleForm.OE_STUDENT_SYS_NAME"
                placeholder="请输入系统名称"
                class="input-width"
              ></el-input>
            </el-form-item>
            <el-form-item label="考生端登录页图片">
              <el-upload
                ref="uploadClientBgPicture"
                class="upload-width"
                accept=".jpg,.png,.jpeg"
                :action="uploadActionClientBgPicture"
                :headers="uploadHeaders"
                :data="uploadDataClientBgPicture"
                :before-upload="beforeUploadClientBgPicture"
                :on-success="uploadSuccessClientBgPicture"
                :on-error="uploadError"
                :on-remove="handleRemoveClientBgPicture"
                :file-list="fileListClientBgPicture"
                :auto-upload="false"
                :multiple="false"
                :limit="1"
                :on-exceed="handleExceed"
                list-type="picture"
              >
                <el-button slot="trigger" size="small" type="primary"
                  >选择文件</el-button
                >&nbsp;
                <el-button
                  size="small"
                  type="success"
                  @click="submitUploadClientBgPicture"
                  >确认上传</el-button
                >
                <el-button
                  size="small"
                  type="danger"
                  @click="handleRemoveClientBgPicture"
                  >清空文件</el-button
                >
                <div slot="tip" class="el-upload__tip">
                  图片宽高比3:2；宽至少为1200px，不超过2000px；小于1MB；.jpg
                  .jpeg .png 文件
                </div>
              </el-upload>
              <el-input
                v-show="false"
                v-model="ruleForm.STUDENT_CLIENT_BG_PICTURE_URL"
              ></el-input>
            </el-form-item>
            <el-form-item label="学校logo" prop="LOGO_FILE_URL">
              <el-upload
                ref="upload"
                class="upload-width"
                accept=".png"
                :action="uploadAction"
                :headers="uploadHeaders"
                :data="uploadData"
                :before-upload="beforeUpload"
                :on-progress="uploadProgress"
                :on-success="uploadSuccess"
                :on-error="uploadError"
                :on-remove="handleRemove"
                :file-list="fileList"
                :auto-upload="false"
                :multiple="false"
                :limit="1"
                :on-exceed="handleExceed"
                list-type="picture"
              >
                <el-button slot="trigger" size="small" type="primary"
                  >选择文件</el-button
                >&nbsp;
                <el-button size="small" type="success" @click="submitUpload"
                  >确认上传</el-button
                >
                <el-button size="small" type="danger" @click="removeFile"
                  >清空文件</el-button
                >
                <div slot="tip" class="el-upload__tip">
                  图片大小(长*宽)为400*100的png文件
                </div>
              </el-upload>
              <el-input
                v-show="false"
                v-model="ruleForm.LOGO_FILE_URL"
              ></el-input>
            </el-form-item>

            <el-form-item label="登录帐号选择" prop="loginType">
              <el-checkbox-group v-model="ruleForm.loginType">
                <el-checkbox
                  v-for="lt in loginTypes"
                  :key="lt.code"
                  v-model="lt.code"
                  name="loginType"
                  :label="lt.code"
                  >{{ lt.name }}</el-checkbox
                >
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="学号登录别名" prop="STUDENT_CODE_LOGIN_ALIAS">
              <el-input
                v-model="ruleForm.STUDENT_CODE_LOGIN_ALIAS"
                placeholder="请输入学号登录别名"
                class="input-width"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="身份证号登录别名"
              prop="IDENTITY_NUMBER_LOGIN_ALIAS"
            >
              <el-input
                v-model="ruleForm.IDENTITY_NUMBER_LOGIN_ALIAS"
                placeholder="请输入身份号登录别名"
                class="input-width"
              ></el-input>
            </el-form-item>
            <el-form-item label="考生端登录方式" prop="loginSupport">
              <el-checkbox-group v-model="ruleForm.loginSupport">
                <el-checkbox
                  v-for="lt in loginSupports"
                  :key="lt.code"
                  v-model="lt.code"
                  name="loginSupport"
                  :label="lt.code"
                  >{{ lt.name }}</el-checkbox
                >
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="考生端展示信息" prop="show_info_list">
              <el-checkbox-group v-model="ruleForm.show_info_list">
                <el-checkbox
                  v-for="lt in showInfoList"
                  :key="lt.code"
                  v-model="lt.code"
                  name="show_info_list"
                  :label="lt.code"
                  >{{ lt.name }}</el-checkbox
                >
              </el-checkbox-group>
            </el-form-item>

            <el-form-item
              label="控制台配置"
              prop="STUDENT_CLIENT_CONSOLE_CONFIG"
            >
              <el-input
                v-model="ruleForm.STUDENT_CLIENT_CONSOLE_CONFIG"
                placeholder="系统预留，无需配置"
                class="input-width"
              ></el-input>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane label="防作弊配置" name="second">
            <el-form-item label="防作弊配置" prop="preventCheatingConfig">
              <el-checkbox-group v-model="ruleForm.preventCheatingConfig">
                <el-checkbox
                  v-for="pcc in preventCheatingConfigs"
                  :key="pcc.code"
                  v-model="pcc.code"
                  name="preventCheatingConfig"
                  :label="pcc.code"
                  >{{ pcc.name }}</el-checkbox
                >
              </el-checkbox-group>
            </el-form-item>
            <el-form-item
              v-show="false"
              label="窗口默认大小"
              prop="STUDENT_CLIENT_DEFAULT_SIZE"
            >
              <el-input
                v-model="ruleForm.STUDENT_CLIENT_DEFAULT_SIZE"
                placeholder="窗口大小格式为：宽度*高度"
                class="input-width"
              ></el-input>
            </el-form-item>
            <el-form-item label="学生端版本" prop="studentClientVersion">
              <el-checkbox-group v-model="ruleForm.studentClientVersion">
                <el-checkbox
                  v-for="pcc in studentClientVersions"
                  :key="pcc.code"
                  v-model="pcc.code"
                  name="studentClientVersion"
                  :label="pcc.code"
                  >{{ pcc.name }}</el-checkbox
                >
              </el-checkbox-group>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane label="自定义菜单配置" name="third">
            <el-form-item label="自定义菜单logo">
              <el-radio-group
                v-model="ruleForm.IS_CUSTOM_MENU_LOGO"
                class="input"
                @change="csutomMenuLogoChange"
              >
                <el-radio label="true">是</el-radio>
                <el-radio label="false">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              v-if="ruleForm.IS_CUSTOM_MENU_LOGO == 'true'"
              label="菜单logo"
              prop="CUS_MENU_LOGO_FILE_URL"
            >
              <el-upload
                ref="menuLogUpload"
                class="upload-width"
                accept=".png"
                :action="uploadMenuLogoAction"
                :headers="uploadMenuLogoHeaders"
                :data="uploadMenuLogoData"
                :before-upload="beforeUploadMenuLogo"
                :on-progress="uploadMenuLogoProgress"
                :on-success="uploadMenuLogoSuccess"
                :on-error="uploadMenuLogoError"
                :on-remove="handleMenuLogoRemove"
                :file-list="menuLogoFileList"
                :auto-upload="false"
                :multiple="false"
                :limit="1"
                :on-exceed="handleMenuLogoExceed"
                list-type="picture"
              >
                <el-button slot="trigger" size="small" type="primary"
                  >选择文件</el-button
                >&nbsp;
                <el-button
                  size="small"
                  type="success"
                  @click="submitUploadMenuLogo"
                  >确认上传</el-button
                >
                <el-button
                  size="small"
                  type="danger"
                  @click="removeMenuLogoFile"
                  >清空文件</el-button
                >
                <div slot="tip" class="el-upload__tip">
                  图片大小(长*宽)为80*68的png文件
                </div>
              </el-upload>
              <el-input
                v-show="false"
                v-model="ruleForm.CUS_MENU_LOGO_FILE_URL"
              ></el-input>
            </el-form-item>

            <el-form-item label="菜单自定义">
              <!-- 权限树 -->
              <div style="width: 80%">
                <el-tree
                  ref="tree"
                  :data="treeData"
                  :props="defaultProps"
                  node-key="id"
                  highlight-current
                  :default-expanded-keys="[-1]"
                  :expand-on-click-node="false"
                  :render-content="renderMenuTreeContent"
                  :show-checkbox="true"
                  style="padding: 10px"
                  @check-change="treeCheckeChange"
                />
              </div>
            </el-form-item>
            <el-form-item label="离线答题纸模板" prop="ANSWERS_TEMPLATE">
              <el-upload
                ref="uploadAnswer"
                class="upload-width"
                accept=".zip"
                :action="uploadAnswer"
                :headers="uploadAnswerHeaders"
                :data="uploadAnswerData"
                :before-upload="beforeUploadAnswer"
                :on-progress="uploadProgress"
                :on-success="uploadAnswerSuccess"
                :on-error="uploadError"
                :on-remove="handleAnswerRemove"
                :file-list="answerFileList"
                :auto-upload="false"
                :multiple="false"
                :limit="1"
                :on-exceed="handleAnswerExceed"
                list-type="zip"
              >
                <el-button slot="trigger" size="small" type="primary"
                  >选择文件</el-button
                >&nbsp;
                <el-button
                  size="small"
                  type="success"
                  @click="submitUploadAnswer"
                  >确认上传</el-button
                >
                <el-button size="small" type="danger" @click="removeAnswerFile"
                  >清空文件</el-button
                >
                <div slot="tip" class="el-upload__tip">上传的格式为ZIP格式</div>
              </el-upload>
              <el-input
                v-show="false"
                v-model="ruleForm.ANSWERS_TEMPLATE"
              ></el-input>
            </el-form-item>
          </el-tab-pane>
        </el-tabs>
      </el-form>

      <!-- 修改权限 -->
      <el-dialog
        title="修改权限"
        width="450px"
        :visible.sync="cusMenuTreeDialog.show"
        :close-on-click-modal="false"
      >
        <el-form
          ref="updateForm"
          :model="cusMenuTreeDialog.privilege"
          inline
          inline-message
          label-width="100px"
          :rules="menuRules"
        >
          <el-form-item label="权限名称" prop="name">
            <el-input
              v-model="cusMenuTreeDialog.privilege.name"
              :disabled="cusMenuTreeDialog.optType == 'view'"
              class="input_width"
              maxlength="8"
              show-word-limit
            />
          </el-form-item>
          <el-form-item label="权限编码" prop="code">
            <el-input
              v-model="cusMenuTreeDialog.privilege.code"
              disabled
              class="input_width"
            />
          </el-form-item>
          <el-form-item label="描述" prop="description">
            <el-input
              v-model="cusMenuTreeDialog.privilege.description"
              :disabled="cusMenuTreeDialog.optType == 'view'"
              class="input_width"
            />
          </el-form-item>
          <el-form-item v-if="false" label="权重" prop="weight">
            <el-input
              v-model="cusMenuTreeDialog.privilege.weight"
              :disabled="cusMenuTreeDialog.optType == 'view'"
              class="input_width"
            />
          </el-form-item>
          <el-form-item v-if="false" label="属性1">
            <el-input
              v-model="cusMenuTreeDialog.privilege.ext1"
              :disabled="cusMenuTreeDialog.optType == 'view'"
              class="input_width"
            />
          </el-form-item>
          <el-form-item v-if="false" label="属性2">
            <el-input
              v-model="cusMenuTreeDialog.privilege.ext2"
              :disabled="cusMenuTreeDialog.optType == 'view'"
              class="input_width"
            />
          </el-form-item>
          <el-form-item v-if="false" label="属性3">
            <el-input
              v-model="cusMenuTreeDialog.privilege.ext3"
              :disabled="cusMenuTreeDialog.optType == 'view'"
              class="input_width"
            />
          </el-form-item>
          <el-form-item v-if="false" label="属性4">
            <el-input
              v-model="cusMenuTreeDialog.privilege.ext4"
              :disabled="cusMenuTreeDialog.optType == 'view'"
              class="input_width"
            />
          </el-form-item>
          <el-form-item v-if="false" label="属性5">
            <el-input
              v-model="cusMenuTreeDialog.privilege.ext5"
              :disabled="cusMenuTreeDialog.optType == 'view'"
              class="input_width"
            />
          </el-form-item>
          <el-form-item class="d-flex justify-content-center">
            <el-button
              v-show="cusMenuTreeDialog.optType != 'view'"
              type="primary"
              @click="updatePrivilege"
              >确 定</el-button
            >
            <el-button
              v-show="cusMenuTreeDialog.optType != 'view'"
              @click="cusMenuTreeDialog.show = false"
              >取 消</el-button
            >
            <el-button
              v-show="cusMenuTreeDialog.optType == 'view'"
              @click="cusMenuTreeDialog.show = false"
              >关 闭</el-button
            >
          </el-form-item>
        </el-form>
      </el-dialog>
    </el-main>
  </el-container>
</template>
<script>
import { mapState } from "vuex";
import {
  LOGIN_TYPE,
  PREVENT_CHEATING_CONFIG,
  STUDENT_CLIENT_VERSION,
  CORE_API,
  LOGIN_SUPPORT,
  SHOW_INFO,
} from "@/constants/constants.js";
let checkWeight = (rule, value, callback) => {
  if ("0" != value && !value) {
    return new Error("请输入权重");
  } else if ("0" != value && !value.match(/^[1-9][0-9]*$/)) {
    callback(new Error("请输入整数"));
  } else if (value < 0) {
    callback(new Error("不能小于0"));
  } else if (value > 10000) {
    callback(new Error("不能大于10000"));
  } else {
    callback();
  }
};
let checkCode = (rule, value, callback) => {
  if (0 != value && !value) {
    callback(new Error("请输入权限编码"));
  } else if (!value.match(/^[0-9a-zA-Z_]*$/)) {
    callback(new Error('只能由数字、字母和"_"组成'));
  } else {
    callback();
  }
};

export default {
  data() {
    return {
      rootOrgList: [],
      loginTypes: LOGIN_TYPE,
      loginSupports: LOGIN_SUPPORT,
      showInfoList: SHOW_INFO,
      propertyGroupId: "",
      preventCheatingConfigs: PREVENT_CHEATING_CONFIG,
      studentClientVersions: STUDENT_CLIENT_VERSION,
      formDataChanged: false,
      originalRuleForm: {},
      ruleForm: {
        relatedPropertyGroupIdList: [],
        orgId: null,
        OE_STUDENT_SYS_NAME: "",
        LOGO_FILE_URL: "",
        ANSWERS_TEMPLATE: "",
        LOGIN_TYPE: "",
        PREVENT_CHEATING_CONFIG: "",
        STUDENT_CLIENT_VERSION: "",
        STUDENT_CLIENT_DEFAULT_SIZE: "",
        STUDENT_CLIENT_CONSOLE_CONFIG: "",
        STUDENT_CODE_LOGIN_ALIAS: "学号登录",
        IDENTITY_NUMBER_LOGIN_ALIAS: "身份证号登录",
        LOGIN_SUPPORT: "",
        SHOW_INFO: "",
        show_info_list: [],
        IS_CUSTOM_MENU_LOGO: "false",
        CUS_MENU_LOGO_FILE_URL: "",
        STUDENT_CLIENT_BG_PICTURE_URL: "",
        properties: {
          OE_STUDENT_SYS_NAME: "",
          LOGO_FILE_URL: "",
          ANSWERS_TEMPLATE: "",
          LOGIN_TYPE: "",
          PREVENT_CHEATING_CONFIG: "",
          STUDENT_CLIENT_VERSION: "",
          STUDENT_CLIENT_DEFAULT_SIZE: "",
          STUDENT_CLIENT_CONSOLE_CONFIG: "",
          STUDENT_CODE_LOGIN_ALIAS: "学号登录",
          IDENTITY_NUMBER_LOGIN_ALIAS: "身份证号登录",
          LOGIN_SUPPORT: "",
          SHOW_INFO: "",
          IS_CUSTOM_MENU_LOGO: "false",
          CUS_MENU_LOGO_FILE_URL: "",
          STUDENT_CLIENT_BG_PICTURE_URL: "",
        },
        loginType: [],
        preventCheatingConfig: [],
        studentClientVersion: [],
        loginSupport: [],
      },
      logoDialog: false,
      uploadAction: "",
      uploadAnswer: "",
      uploadAnswerHeaders: {},
      uploadAnswerData: {},
      uploadHeaders: {},
      uploadData: {},
      fileList: [],

      uploadActionClientBgPicture: "",
      uploadDataClientBgPicture: {},
      fileListClientBgPicture: [],
      menuRules: {
        name: [
          {
            required: true,
            message: "请输入权限名称",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "请输入权限编码",
            trigger: "blur",
          },
          {
            validator: checkCode,
            trigger: "blur",
          },
        ],
        weight: [
          {
            required: true,
            message: "请输入权重",
            trigger: "blur",
          },
          {
            validator: checkWeight,
            trigger: "blur",
          },
        ],
      },
      rules: {
        OE_STUDENT_SYS_NAME: [
          { required: true, message: "请输入系统名称", trigger: "blur" },
          {
            min: 1,
            max: 50,
            message: "长度在 1 到 50 个字符",
            trigger: "blur",
          },
        ],
        LOGO_FILE_URL: [
          { required: true, message: "请上传学校logo", trigger: "change" },
        ],
        loginType: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个登录账号选择",
            trigger: "change",
          },
        ],
        loginSupport: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个考生端登录方式",
            trigger: "change",
          },
        ],
        STUDENT_CLIENT_DEFAULT_SIZE: [
          { required: true, message: "请输入客户端默认大小", trigger: "blur" },
        ],
        STUDENT_CODE_LOGIN_ALIAS: [
          { required: true, message: "请输入学号登录别名", trigger: "blur" },
          {
            min: 1,
            max: 10,
            message: "长度在 1 到 10 个字符",
            trigger: "blur",
          },
        ],
        IDENTITY_NUMBER_LOGIN_ALIAS: [
          {
            required: true,
            message: "请输入身份证号登录别名",
            trigger: "blur",
          },
          {
            min: 1,
            max: 10,
            message: "长度在 1 到 10 个字符",
            trigger: "blur",
          },
        ],
        studentClientVersion: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个学生端版本",
            trigger: "change",
          },
        ],
        CUS_MENU_LOGO_FILE_URL: [
          {
            required: true,
            message: "请上传自定义菜单logo",
            trigger: "change",
          },
        ],
      },
      tabActiveName: "first",
      uploadMenuLogoAction: "",
      uploadMenuLogoHeaders: {},
      uploadMenuLogoData: {},
      menuLogoFileList: [],
      answerFileList: [],
      originalCusMenuForm: {},
      cusMenuTreeDialog: {
        show: false,
        parentName: null,
        optType: null,
        privilege: {
          id: null,
          groupId: null,
          parentId: null,
          name: null,
          code: null,
          description: null,
          weight: null,
          ext1: null,
          ext2: null,
          ext3: null,
          ext4: null,
          ext5: null,
        },
      },
      treeData: [],
      data: null,
      defaultProps: {
        children: "children",
        label: "label",
      },
      privilegeGroupId: null,
      checkedKeys: [],
      originalCheckedKeys: [],
      treeCheckedChanged: false,
    };
  },

  computed: {
    ...mapState({ user: (state) => state.user }),
    btnSaveDiabled() {
      // console.log(this.formDataChanged);
      return !this.formDataChanged && !this.treeCheckedChanged;
    },
    isSuperAdmin() {
      return this.user.roleList.some((role) => role.roleCode == "SUPER_ADMIN");
    },
    rootSchoolSelect() {
      let rootSchools = [];
      for (let i = 0; i < this.rootOrgList.length; i++) {
        let info = {
          name: this.rootOrgList[i].name + "（" + this.rootOrgList[i].id + "）",
          id: this.rootOrgList[i].id,
        };
        rootSchools.push(info);
      }
      return rootSchools;
    },
  },
  watch: {
    "ruleForm.orgId": {
      handler: function () {
        this.initForm();
      },
    },
    checkedKeys: {
      handler: function (newVal) {
        this.treeCheckedChanged = !this.equalArrayIgnoreSequence(
          this.originalCheckedKeys,
          newVal
        );
      },
    },
    ruleForm: {
      deep: true,
      handler: function (newForm) {
        if (Object.keys(this.originalRuleForm).length > 0) {
          this.formDataChanged = !(
            newForm.OE_STUDENT_SYS_NAME ==
              this.originalRuleForm.OE_STUDENT_SYS_NAME &&
            newForm.STUDENT_CLIENT_BG_PICTURE_URL ==
              this.originalRuleForm.STUDENT_CLIENT_BG_PICTURE_URL &&
            newForm.LOGO_FILE_URL == this.originalRuleForm.LOGO_FILE_URL &&
            this.equalArrayIgnoreSequence(
              newForm.loginType,
              this.originalRuleForm.loginType
            ) &&
            this.equalArrayIgnoreSequence(
              newForm.loginSupport,
              this.originalRuleForm.loginSupport
            ) &&
            this.equalArrayIgnoreSequence(
              newForm.show_info_list,
              this.originalRuleForm.show_info_list
            ) &&
            this.equalArrayIgnoreSequence(
              newForm.preventCheatingConfig,
              this.originalRuleForm.preventCheatingConfig
            ) &&
            this.equalArrayIgnoreSequence(
              newForm.studentClientVersion,
              this.originalRuleForm.studentClientVersion
            ) &&
            newForm.STUDENT_CLIENT_CONSOLE_CONFIG ==
              this.originalRuleForm.STUDENT_CLIENT_CONSOLE_CONFIG &&
            newForm.STUDENT_CODE_LOGIN_ALIAS ==
              this.originalRuleForm.STUDENT_CODE_LOGIN_ALIAS &&
            newForm.IDENTITY_NUMBER_LOGIN_ALIAS ==
              this.originalRuleForm.IDENTITY_NUMBER_LOGIN_ALIAS &&
            newForm.IS_CUSTOM_MENU_LOGO ==
              this.originalRuleForm.IS_CUSTOM_MENU_LOGO &&
            newForm.ANSWERS_TEMPLATE ==
              this.originalRuleForm.ANSWERS_TEMPLATE &&
            newForm.CUS_MENU_LOGO_FILE_URL ==
              this.originalRuleForm.CUS_MENU_LOGO_FILE_URL
          );
        } else {
          this.formDataChanged = false;
        }
      },
    },
  },

  created() {
    this.ruleForm.orgId = this.user.rootOrgId;
    this.propertyGroupId = "config4Edit3";
    this.uploadHeaders = {
      key: this.user.key,
      token: this.user.token,
    };
    this.uploadAnswerHeaders = {
      key: this.user.key,
      token: this.user.token,
    };
    this.uploadMenuLogoHeaders = {
      key: this.user.key,
      token: this.user.token,
    };
    if (this.isSuperAdmin) {
      this.$httpWithMsg
        .get(CORE_API + "/org/getRootOrgList")
        .then((response) => {
          this.rootOrgList = response.data;
        });
    }
    this.initForm();
  },

  methods: {
    beforeUploadClientBgPicture() {
      if (!this.checkUploadClientBgPicture()) {
        return false;
      }
    },
    checkUploadClientBgPicture() {
      let fileList = this.$refs.uploadClientBgPicture.uploadFiles;
      if (fileList.length == 0) {
        this.$notify({
          message: "上传文件不能为空",
          type: "error",
        });
        return false;
      }
      if (fileList.length > 1) {
        this.$notify({
          message: "每次只能上传一个文件",
          type: "error",
        });
        return false;
      }
      for (let file of fileList) {
        let fileName = file.name;
        if (
          !fileName.endsWith(".jpg") &&
          !fileName.endsWith(".jpeg") &&
          !fileName.endsWith(".png")
        ) {
          this.$notify({
            message: "上传文件格式必须为jpg jpeg png",
            type: "error",
          });
          this.fileListClientBgPicture = [];
          return false;
        }
      }
      return true;
    },
    uploadSuccessClientBgPicture(response) {
      if (response && response.length > 0) {
        this.$notify({
          message: "上传成功",
          type: "success",
        });
        let fileUrl = response;

        this.ruleForm.STUDENT_CLIENT_BG_PICTURE_URL =
          this.ruleForm.properties.STUDENT_CLIENT_BG_PICTURE_URL = fileUrl;
      } else {
        this.errDialog = true;
      }
      this.fileLoading = false;
    },
    handleRemoveClientBgPicture() {
      this.fileListClientBgPicture = [];
      this.ruleForm.STUDENT_CLIENT_BG_PICTURE_URL =
        this.ruleForm.properties.STUDENT_CLIENT_BG_PICTURE_URL = "";
      if (this.$refs.uploadClientBgPicture) {
        this.$refs.uploadClientBgPicture.clearFiles();
      }
    },
    submitUploadClientBgPicture() {
      if (!this.checkUploadClientBgPicture()) {
        return false;
      }
      this.$refs.uploadClientBgPicture.submit();
      this.fileLoading = true;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (
            this.$refs.tree.getCheckedKeys() == null ||
            this.$refs.tree.getCheckedKeys().length == 0
          ) {
            this.$notify({
              message: "请至少选择一个菜单功能",
              type: "error",
            });
            return;
          }

          this.ruleForm.properties.OE_STUDENT_SYS_NAME =
            this.ruleForm.OE_STUDENT_SYS_NAME;
          this.ruleForm.properties.STUDENT_CLIENT_BG_PICTURE_URL =
            this.ruleForm.STUDENT_CLIENT_BG_PICTURE_URL;
          this.ruleForm.properties.LOGO_FILE_URL = this.ruleForm.LOGO_FILE_URL;
          this.ruleForm.properties.STUDENT_CLIENT_DEFAULT_SIZE =
            this.ruleForm.STUDENT_CLIENT_DEFAULT_SIZE;
          this.ruleForm.properties.STUDENT_CLIENT_CONSOLE_CONFIG =
            this.ruleForm.STUDENT_CLIENT_CONSOLE_CONFIG;
          this.ruleForm.properties.LOGIN_TYPE = this.ruleForm.LOGIN_TYPE =
            this.ruleForm.loginType.join(",");
          this.ruleForm.properties.LOGIN_SUPPORT = this.ruleForm.LOGIN_SUPPORT =
            this.ruleForm.loginSupport.join(",");
          this.ruleForm.properties.SHOW_INFO = this.ruleForm.SHOW_INFO =
            this.ruleForm.show_info_list.join(",");
          this.ruleForm.properties.STUDENT_CODE_LOGIN_ALIAS =
            this.ruleForm.STUDENT_CODE_LOGIN_ALIAS;
          this.ruleForm.properties.IDENTITY_NUMBER_LOGIN_ALIAS =
            this.ruleForm.IDENTITY_NUMBER_LOGIN_ALIAS;
          if (
            this.ruleForm.preventCheatingConfig == null ||
            this.ruleForm.preventCheatingConfig.length == 0
          ) {
            this.ruleForm.properties.PREVENT_CHEATING_CONFIG =
              this.ruleForm.PREVENT_CHEATING_CONFIG = "NONE";
          } else {
            let defaultArr = [];
            for (let lt of PREVENT_CHEATING_CONFIG) {
              defaultArr.push(lt.code);
            }
            let validArr = [];
            //保存时清理一下历史数据
            for (
              let i = 0;
              i < this.ruleForm.preventCheatingConfig.length;
              i++
            ) {
              let code = this.ruleForm.preventCheatingConfig[i];
              if (defaultArr.indexOf(code) > -1) {
                validArr.push(code);
              }
            }
            let res = "";
            if (validArr.length == 0) {
              res = "NONE";
            } else {
              res = validArr.join(",");
            }
            this.ruleForm.properties.PREVENT_CHEATING_CONFIG =
              this.ruleForm.PREVENT_CHEATING_CONFIG = res;
          }

          if (
            this.ruleForm.studentClientVersion == null ||
            this.ruleForm.studentClientVersion.length == 0
          ) {
            this.ruleForm.properties.STUDENT_CLIENT_VERSION =
              this.ruleForm.STUDENT_CLIENT_VERSION = "NONE";
          } else {
            let defaultArr = [];
            for (let lt of STUDENT_CLIENT_VERSION) {
              defaultArr.push(lt.code);
            }
            let validArr = [];
            //保存时清理一下历史数据
            for (
              let i = 0;
              i < this.ruleForm.studentClientVersion.length;
              i++
            ) {
              let code = this.ruleForm.studentClientVersion[i];
              if (defaultArr.indexOf(code) > -1) {
                validArr.push(code);
              }
            }
            let res = "";
            if (validArr.length == 0) {
              res = "NONE";
            } else {
              res = validArr.join(",");
            }
            this.ruleForm.properties.STUDENT_CLIENT_VERSION =
              this.ruleForm.STUDENT_CLIENT_VERSION = res;
          }

          this.ruleForm.properties.IS_CUSTOM_MENU_LOGO =
            this.ruleForm.IS_CUSTOM_MENU_LOGO;
          if (this.ruleForm.IS_CUSTOM_MENU_LOGO == "true") {
            this.ruleForm.properties.CUS_MENU_LOGO_FILE_URL =
              this.ruleForm.CUS_MENU_LOGO_FILE_URL;
          }

          Promise.all([
            this.$httpWithMsg.put(
              CORE_API + "/org/saveOrgProperties",
              this.ruleForm
            ),
            this.$httpWithMsg.post(
              CORE_API + "/rolePrivilege/updateRootOrgPrivilegeRelations",
              {
                rootOrgId: this.ruleForm.orgId,
                privilegeGroupId: this.privilegeGroupId,
                privilegeIdSet: this.checkedKeys,
              }
            ),
          ]).then(([resp1, resp2]) => {
            console.log("resp1:" + resp1);
            console.log("resp1:" + resp2);
            this.$notify({
              message: "保存成功",
              type: "success",
            });
            this.originalRuleForm = Object.assign({}, this.ruleForm);
            this.formDataChanged = false;
            this.treeCheckedChanged = false;
            this.originalCheckedKeys = this.$refs.tree.getCheckedKeys();
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    async initForm() {
      this.uploadAction = CORE_API + "/org/importLogo/" + this.ruleForm.orgId;
      this.uploadActionClientBgPicture =
        CORE_API + "/org/importClientBgPicture/" + this.ruleForm.orgId;
      this.uploadAnswer =
        CORE_API + "/org/importAnswers/" + this.ruleForm.orgId;
      this.ruleForm.STUDENT_CLIENT_DEFAULT_SIZE = "1400*900";
      this.ruleForm.relatedPropertyGroupIdList = ["studentClientConfig"];

      await this.initConfig();
      this.initTree();
    },

    beforeUpload(file) {
      if (!this.checkUpload()) {
        return false;
      }
      console.log(file);
    },
    beforeUploadAnswer(file) {
      if (!this.checkUploadAnswer()) {
        return false;
      }
      console.log(file);
    },
    uploadProgress() {
      console.log("uploadProgress");
    },
    uploadSuccess(response) {
      console.log("uploadSuccess");
      console.log(response);
      if (response && response.length > 0) {
        this.$notify({
          message: "上传成功",
          type: "success",
        });
        let fileUrl = response;

        this.ruleForm.LOGO_FILE_URL = this.ruleForm.properties.LOGO_FILE_URL =
          fileUrl;
      } else {
        this.errDialog = true;
      }
      this.fileLoading = false;
      this.logoDialog = false;
      // this.removeFile();
    },
    uploadAnswerSuccess(response) {
      console.log("uploadSuccess");
      console.log(response);
      if (response && response.length > 0) {
        this.$notify({
          message: "上传成功",
          type: "success",
        });
        let fileUrl = response;

        this.ruleForm.ANSWERS_TEMPLATE =
          this.ruleForm.properties.ANSWERS_TEMPLATE = fileUrl;
      } else {
        this.errDialog = true;
      }
      this.fileLoading = false;
      this.logoDialog = false;
      // this.removeFile();
    },
    uploadError(response) {
      let json = JSON.parse(response.message);
      if (response.status == 500) {
        this.$notify({
          message: json.desc,
          type: "error",
        });
      }
      this.fileLoading = false;
    },
    //确定上传
    submitUpload() {
      if (!this.checkUpload()) {
        return false;
      }
      this.$refs.upload.submit();
      this.fileLoading = true;
    },
    checkUpload() {
      let fileList = this.$refs.upload.uploadFiles;
      if (fileList.length == 0) {
        this.$notify({
          message: "上传文件不能为空",
          type: "error",
        });
        return false;
      }
      if (fileList.length > 1) {
        this.$notify({
          message: "每次只能上传一个文件",
          type: "error",
        });
        return false;
      }
      for (let file of fileList) {
        let fileName = file.name;
        if (
          // !fileName.endsWith(".jpg") &&
          // !fileName.endsWith(".gif") &&
          !fileName.endsWith(".png")
        ) {
          this.$notify({
            message: "上传文件格式必须为[png]",
            type: "error",
          });
          this.fileList = [];
          return false;
        }
      }
      return true;
    },
    //清空文件
    removeFile() {
      this.fileList = [];
      this.ruleForm.LOGO_FILE_URL = this.ruleForm.properties.LOGO_FILE_URL = "";
      if (this.$refs.upload) {
        this.$refs.upload.clearFiles();
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.removeFile();
    },
    handleExceed() {
      this.$notify({
        message: "当前限制选择 1 个文件,请先清空文件再试",
        type: "error",
      });
    },
    equalArrayIgnoreSequence(arr1, arr2) {
      // 判断数组的长度
      if (arr1.length !== arr2.length) {
        return false;
      } else {
        let a = Object.assign([], arr1);
        let b = Object.assign([], arr2);
        a.sort();
        b.sort();
        // 循环遍历数组的值进行比较
        for (let i = 0; i < a.length; i++) {
          if (a[i] !== b[i]) {
            return false;
          }
        }
        return true;
      }
    },

    //初始化配置
    async initConfig() {
      let url =
        CORE_API +
        "/org/getOrgPropertiesByGroupWithoutCache/" +
        this.ruleForm.orgId +
        "/" +
        this.propertyGroupId;

      await this.$httpWithMsg.get(url).then((response) => {
        console.log(response);

        if (response) {
          this.ruleForm.properties = response.data;
          // if (this.ruleForm.properties.OE_STUDENT_SYS_NAME) {
          this.ruleForm.OE_STUDENT_SYS_NAME =
            this.ruleForm.properties.OE_STUDENT_SYS_NAME ||
            this.ruleForm.OE_STUDENT_SYS_NAME;
          // }
          this.ruleForm.STUDENT_CLIENT_BG_PICTURE_URL =
            this.ruleForm.properties.STUDENT_CLIENT_BG_PICTURE_URL ||
            this.ruleForm.STUDENT_CLIENT_BG_PICTURE_URL;
          this.ruleForm.LOGO_FILE_URL =
            this.ruleForm.properties.LOGO_FILE_URL ||
            this.ruleForm.LOGO_FILE_URL;
          this.ruleForm.ANSWERS_TEMPLATE =
            this.ruleForm.properties.ANSWERS_TEMPLATE ||
            this.ruleForm.ANSWERS_TEMPLATE;
          this.ruleForm.LOGIN_TYPE =
            this.ruleForm.properties.LOGIN_TYPE || this.ruleForm.LOGIN_TYPE;
          this.ruleForm.LOGIN_SUPPORT =
            this.ruleForm.properties.LOGIN_SUPPORT ||
            this.ruleForm.LOGIN_SUPPORT;
          this.ruleForm.SHOW_INFO =
            this.ruleForm.properties.SHOW_INFO || this.ruleForm.SHOW_INFO;
          this.ruleForm.PREVENT_CHEATING_CONFIG =
            this.ruleForm.properties.PREVENT_CHEATING_CONFIG ||
            this.ruleForm.PREVENT_CHEATING_CONFIG;
          this.ruleForm.STUDENT_CLIENT_VERSION =
            this.ruleForm.properties.STUDENT_CLIENT_VERSION ||
            this.ruleForm.STUDENT_CLIENT_VERSION;
          this.ruleForm.STUDENT_CLIENT_DEFAULT_SIZE =
            this.ruleForm.properties.STUDENT_CLIENT_DEFAULT_SIZE ||
            this.ruleForm.STUDENT_CLIENT_DEFAULT_SIZE;
          this.ruleForm.STUDENT_CLIENT_CONSOLE_CONFIG = this.ruleForm.properties
            .STUDENT_CLIENT_CONSOLE_CONFIG
            ? this.ruleForm.properties.STUDENT_CLIENT_CONSOLE_CONFIG
            : "";
          this.ruleForm.STUDENT_CODE_LOGIN_ALIAS =
            this.ruleForm.properties.STUDENT_CODE_LOGIN_ALIAS ||
            this.ruleForm.STUDENT_CODE_LOGIN_ALIAS;
          this.ruleForm.IDENTITY_NUMBER_LOGIN_ALIAS =
            this.ruleForm.properties.IDENTITY_NUMBER_LOGIN_ALIAS ||
            this.ruleForm.IDENTITY_NUMBER_LOGIN_ALIAS;

          if (this.ruleForm.properties.LOGIN_TYPE) {
            this.ruleForm.loginType =
              this.ruleForm.properties.LOGIN_TYPE.split(",");
          } else {
            let defaultValue = [];
            for (let lt of LOGIN_TYPE) {
              defaultValue.push(lt.code);
            }
            this.ruleForm.loginType = defaultValue;
            console.log(defaultValue);
          }

          if (this.ruleForm.properties.LOGIN_SUPPORT) {
            this.ruleForm.loginSupport =
              this.ruleForm.properties.LOGIN_SUPPORT.split(",");
          }

          if (this.ruleForm.properties.SHOW_INFO) {
            this.ruleForm.show_info_list =
              this.ruleForm.properties.SHOW_INFO.split(",");
          } else {
            // 默认值
            this.ruleForm.show_info_list = [];
          }

          if (this.ruleForm.properties.PREVENT_CHEATING_CONFIG) {
            if (this.ruleForm.properties.PREVENT_CHEATING_CONFIG != "NONE") {
              this.ruleForm.preventCheatingConfig =
                this.ruleForm.properties.PREVENT_CHEATING_CONFIG.split(",");
            } else {
              this.ruleForm.preventCheatingConfig = [];
            }
          } else {
            let defaultValue = [];
            for (let lt of PREVENT_CHEATING_CONFIG) {
              defaultValue.push(lt.code);
            }
            this.ruleForm.preventCheatingConfig = defaultValue;
            // console.log(defaultValue);
          }

          if (this.ruleForm.properties.STUDENT_CLIENT_VERSION) {
            if (this.ruleForm.properties.STUDENT_CLIENT_VERSION != "NONE") {
              this.ruleForm.studentClientVersion =
                this.ruleForm.properties.STUDENT_CLIENT_VERSION.split(",");
            } else {
              this.ruleForm.studentClientVersion = [];
            }
          } else {
            let defaultValue = [];
            // for (let lt of STUDENT_CLIENT_VERSION) {
            //   defaultValue.push(lt.code);
            // }
            this.ruleForm.studentClientVersion = defaultValue;
            // console.log(defaultValue);
          }

          let fileUrlClientBgPicture =
            response.data.STUDENT_CLIENT_BG_PICTURE_URL;
          if (fileUrlClientBgPicture) {
            let lastIndex = fileUrlClientBgPicture.lastIndexOf("/");
            let len = fileUrlClientBgPicture.length;
            let fname = fileUrlClientBgPicture.substr(
              lastIndex + 1,
              len - lastIndex
            );
            this.fileListClientBgPicture = [
              { name: fname, url: this.ruleForm.STUDENT_CLIENT_BG_PICTURE_URL },
            ];
          } else {
            this.fileListClientBgPicture = [];
          }

          let fileUrl = response.data.LOGO_FILE_URL;
          let fname = "";
          if (fileUrl) {
            let lastIndex = fileUrl.lastIndexOf("/");
            let len = fileUrl.length;
            fname = fileUrl.substr(lastIndex + 1, len - lastIndex);
            this.fileList = [{ name: fname, url: this.ruleForm.LOGO_FILE_URL }];
          } else {
            this.fileList = [];
          }

          let answersUrl = response.data.ANSWERS_TEMPLATE;
          let answersName = "";
          if (answersUrl) {
            let lastIndex = answersUrl.lastIndexOf("/");
            let len = answersUrl.length;
            answersName = answersUrl.substr(lastIndex + 1, len - lastIndex);
            this.answerFileList = [
              { name: answersName, url: this.ruleForm.ANSWERS_TEMPLATE },
            ];
          } else {
            this.answerFileList = [];
          }

          this.uploadMenuLogoAction =
            CORE_API + "/org/importCusMenuLogo/" + this.ruleForm.orgId;

          this.ruleForm.IS_CUSTOM_MENU_LOGO =
            this.ruleForm.properties.IS_CUSTOM_MENU_LOGO ||
            this.ruleForm.IS_CUSTOM_MENU_LOGO;

          if (this.ruleForm.IS_CUSTOM_MENU_LOGO == "true") {
            this.ruleForm.CUS_MENU_LOGO_FILE_URL =
              this.ruleForm.properties.CUS_MENU_LOGO_FILE_URL ||
              this.ruleForm.CUS_MENU_LOGO_FILE_URL;

            let fileUrl2 = response.data.CUS_MENU_LOGO_FILE_URL;
            let fname2 = "";
            if (fileUrl2) {
              let lastIndex = fileUrl2.lastIndexOf("/");
              let len = fileUrl2.length;
              fname2 = fileUrl2.substr(lastIndex + 1, len - lastIndex);
              this.menuLogoFileList = [
                { name: fname2, url: this.ruleForm.CUS_MENU_LOGO_FILE_URL },
              ];
            } else {
              this.menuLogoFileList = [];
            }
          }

          this.originalRuleForm = Object.assign({}, this.ruleForm);
        } else {
          this.$notify({
            message: "学生客户端信息暂未初始化，请立即初始化",
            type: "warning",
          });
        }
      });
    },

    //初始化权限树
    async initTree() {
      var url =
        CORE_API +
        "/rolePrivilege/getStudentClientMenuTree/" +
        this.ruleForm.orgId +
        "?includeDisabledCodes=false";
      this.$httpWithMsg.get(url).then((response) => {
        if (response) {
          this.treeData = [];
          this.treeData.push(response.data.treeData);

          this.privilegeGroupId = response.data.privilegeGroupId;
          this.originalCheckedKeys = response.data.ownedPrivilegeIds;
          this.checkedKeys = response.data.ownedPrivilegeIds;
          this.setCheckedKeys(this.checkedKeys);
        } else {
          this.$notify({
            message: "自定义菜单权限树初始化失败",
            type: "warning",
          });
        }
      });
    },

    setCheckedKeys(keys) {
      this.$refs.tree.setCheckedKeys(keys);
    },

    renderMenuTreeContent(h, { node, data, store }) {
      if (node.key == -1) {
        return (
          <span>
            <span>
              <span>{node.label}</span>
            </span>
          </span>
        );
      }
      return (
        <span>
          <span>
            <span>{node.label}</span>
          </span>
          <span style="float: right; margin-left: 20px; margin-right: 20px">
            <el-button
              size="mini"
              type="text"
              on-click={() => this.openCusMenuTreeDialog(store, data, "view")}
            >
              查看
            </el-button>
            <el-button
              size="mini"
              type="text"
              on-click={() => this.openCusMenuTreeDialog(store, data, "edit")}
            >
              修改
            </el-button>
          </span>
        </span>
      );
    },
    treeCheckeChange(data, checked, indeterminate) {
      console.log(data, checked, indeterminate);
      this.checkedKeys = this.$refs.tree.getCheckedKeys();
    },
    openCusMenuTreeDialog(store, data, optType) {
      this.store = store;
      this.data = data;
      this.getPrivilege(data.id, optType);
    },

    getPrivilege(id, type) {
      var url = CORE_API + "/rolePrivilege/getPrivilege/" + id;
      this.$httpWithMsg.get(url).then((response) => {
        var resp = response.data;

        this.cusMenuTreeDialog.show = true;
        this.cusMenuTreeDialog.optType = type;

        this.cusMenuTreeDialog.privilege.id = resp.id;
        this.cusMenuTreeDialog.privilege.groupId = resp.groupId;
        this.cusMenuTreeDialog.privilege.parentId = resp.parentId;
        this.cusMenuTreeDialog.privilege.name = resp.name;
        this.cusMenuTreeDialog.privilege.code = resp.code;
        this.cusMenuTreeDialog.privilege.description = resp.description;
        this.cusMenuTreeDialog.privilege.weight = resp.weight + "";
        this.cusMenuTreeDialog.privilege.ext1 = resp.ext1;
        this.cusMenuTreeDialog.privilege.ext2 = resp.ext2;
        this.cusMenuTreeDialog.privilege.ext3 = resp.ext3;
        this.cusMenuTreeDialog.privilege.ext4 = resp.ext4;
        this.cusMenuTreeDialog.privilege.ext5 = resp.ext5;
      });
    },

    beforeUploadMenuLogo(file) {
      if (!this.checkUploadMenuLogo()) {
        return false;
      }
      console.log(file);
    },
    uploadMenuLogoProgress() {
      console.log("uploadProgress");
    },
    uploadMenuLogoSuccess(response) {
      console.log("uploadSuccess");
      // console.log(response);
      if (response && response.length > 0) {
        this.$notify({
          message: "上传成功",
          type: "success",
        });
        let fileUrl = response;

        this.ruleForm.CUS_MENU_LOGO_FILE_URL =
          this.ruleForm.properties.CUS_MENU_LOGO_FILE_URL = fileUrl;
      } else {
        this.errDialog = true;
      }
      this.fileLoading = false;
      this.logoDialog = false;
      // this.removeFile();
    },
    uploadMenuLogoError(response) {
      let json = JSON.parse(response.message);
      if (response.status == 500) {
        this.$notify({
          message: json.desc,
          type: "error",
        });
      }
      this.fileLoading = false;
    },
    //确定上传
    submitUploadAnswer() {
      if (!this.checkUploadAnswer()) {
        return false;
      }
      this.$refs.uploadAnswer.submit();
      this.fileLoading = true;
    },
    checkUploadAnswer() {
      let fileList = this.$refs.uploadAnswer.uploadFiles;
      if (fileList.length == 0) {
        this.$notify({
          message: "上传文件不能为空",
          type: "error",
        });
        return false;
      }
      if (fileList.length > 1) {
        this.$notify({
          message: "每次只能上传一个文件",
          type: "error",
        });
        return false;
      }
      for (let file of fileList) {
        let fileName = file.name;
        if (!fileName.endsWith(".zip")) {
          this.$notify({
            message: "上传文件格式必须为[zip]",
            type: "error",
          });
          this.menuLogoFileList = [];
          return false;
        }
      }
      return true;
    },
    //清空文件
    removeAnswerFile() {
      this.answerFileList = [];
      this.ruleForm.ANSWERS_TEMPLATE =
        this.ruleForm.properties.ANSWERS_TEMPLATE = "";
      if (this.$refs.uploadAnswer) {
        this.$refs.uploadAnswer.clearFiles();
      }
    },
    handleAnswerRemove(file, fileList) {
      console.log(file, fileList);
      this.removeAnswerFile();
    },
    handleAnswerExceed() {
      this.$notify({
        message: "当前限制选择 1 个文件,请先清空文件再试",
        type: "error",
      });
    },

    //确定上传
    submitUploadMenuLogo() {
      if (!this.checkUploadMenuLogo()) {
        return false;
      }
      this.$refs.menuLogUpload.submit();
      this.fileLoading = true;
    },
    checkUploadMenuLogo() {
      let fileList = this.$refs.menuLogUpload.uploadFiles;
      if (fileList.length == 0) {
        this.$notify({
          message: "上传文件不能为空",
          type: "error",
        });
        return false;
      }
      if (fileList.length > 1) {
        this.$notify({
          message: "每次只能上传一个文件",
          type: "error",
        });
        return false;
      }
      for (let file of fileList) {
        let fileName = file.name;
        if (
          // !fileName.endsWith(".jpg") &&
          // !fileName.endsWith(".gif") &&
          !fileName.endsWith(".png")
        ) {
          this.$notify({
            message: "上传文件格式必须为[png]",
            type: "error",
          });
          this.menuLogoFileList = [];
          return false;
        }
      }
      return true;
    },
    //清空文件
    removeMenuLogoFile() {
      this.menuLogoFileList = [];
      this.ruleForm.CUS_MENU_LOGO_FILE_URL =
        this.ruleForm.properties.CUS_MENU_LOGO_FILE_URL = "";
      if (this.$refs.menuLogUpload) {
        this.$refs.menuLogUpload.clearFiles();
      }
    },
    handleMenuLogoRemove(file, fileList) {
      console.log(file, fileList);
      this.removeMenuLogoFile();
    },
    handleMenuLogoExceed() {
      this.$notify({
        message: "当前限制选择 1 个文件,请先清空文件再试",
        type: "error",
      });
    },

    updatePrivilege() {
      this.$refs.updateForm.validate((valid) => {
        if (!valid) {
          return;
        }
        var url = CORE_API + "/rolePrivilege/updatePrivilege";
        this.$httpWithMsg
          .put(url, this.cusMenuTreeDialog.privilege)
          .then(() => {
            this.$notify({
              message: "修改成功",
              type: "success",
            });
            this.data.label = this.cusMenuTreeDialog.privilege.name;
          });
        this.cusMenuTreeDialog.show = false;
      });
    },
    csutomMenuLogoChange(val) {
      if (val == "false") {
        this.removeMenuLogoFile();
      }
    },
  },
};
</script>

<style scoped>
.input-width {
  width: 638px;
}
.upload-width {
  width: 638px;
}
/* .btn-margin-left {
  margin-left: 20px;
} */
</style>
