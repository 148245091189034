import { render, staticRenderFns } from "./MarkPaperCheck.vue?vue&type=template&id=bddd05c0&scoped=true"
import script from "./MarkPaperCheck.vue?vue&type=script&lang=js"
export * from "./MarkPaperCheck.vue?vue&type=script&lang=js"
import style0 from "./MarkPaperCheck.vue?vue&type=style&index=0&id=bddd05c0&prod&lang=css&scoped=true"
import style1 from "./MarkPaperCheck.vue?vue&type=style&index=1&id=bddd05c0&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bddd05c0",
  null
  
)

export default component.exports