<template>
  <section class="content">
    <div v-show="isClear == 1">
      <LinkTitlesCustom :current-paths="['题库管理 ', '导入试卷管理']" />
    </div>
    <!-- 正文信息 -->
    <div class="box-body">
      <el-form
        :inline="true"
        :model="formSearch"
        label-position="right"
        label-width="70px"
      >
        <el-row>
          <el-col :span="6">
            <el-form-item label="课程名称">
              <el-select
                v-model="formSearch.courseNo"
                class="search_width"
                filterable
                :remote-method="getCourses"
                remote
                clearable
                placeholder="请输入"
                size="small"
                @focus="(e) => getCourses(e.target.value)"
              >
                <el-option
                  v-for="item in courseInfoSelect"
                  :key="item.courseNo"
                  :label="item.courseInfo"
                  :value="item.courseNo"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="试卷名称">
              <el-input
                v-model="formSearch.name"
                class="search_width"
                placeholder="试卷名称"
                size="small"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="层次">
              <el-select
                v-model="formSearch.level"
                class="search_width"
                clearable
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in levelList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="录入人">
              <el-input
                v-model="formSearch.creator"
                class="search_width"
                placeholder="录入人"
                size="small"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="修改人">
              <el-input
                v-model="formSearch.lastModifyName"
                class="search_width"
                placeholder="修改人"
                size="small"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <div class="search_down">
              <el-button size="small" type="primary" @click="searchFrom"
                ><i class="el-icon-search"></i> 查询</el-button
              >
              <el-button size="small" type="primary" @click="impPaper"
                ><i class="el-icon-upload2"></i> 导入</el-button
              >
              <el-dropdown class="button_left">
                <el-button type="primary" size="small">
                  更多 <i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <el-button size="small" type="primary" @click="expQuesType"
                      >试题分布</el-button
                    >
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-button size="small" type="primary" @click="openQuesPro"
                      >试卷属性</el-button
                    >
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-button size="small" type="primary" @click="openQuesProC"
                      >课程属性</el-button
                    >
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </el-col>
        </el-row>
        <div
          style="width: 100%; border-bottom: 1px solid #ddd; margin: 10px 0"
        ></div>
        <el-row>
          <el-form-item>
            <span>批量操作：</span>
            <el-button
              size="small"
              type="primary"
              :disabled="noBatchSelected"
              @click="useBasePaper"
              ><i class="el-icon-star-on"></i> 使用原卷</el-button
            >
            <el-button
              size="small"
              type="danger"
              :disabled="noBatchSelected"
              @click="batchDeletePaper"
              ><i class="el-icon-delete"></i> 删除原卷</el-button
            >
          </el-form-item>
        </el-row>
      </el-form>
      <div style="width: 100%; margin-bottom: 10px"></div>
      <el-table
        v-loading="loading"
        element-loading-text="拼命加载中"
        :data="tableData"
        border
        style="width: 100%"
        @selection-change="selectChange"
      >
        <el-table-column type="selection" width="40"></el-table-column>
        <el-table-column label="课程名称" width="180">
          <template slot-scope="scope">
            <span>{{ scope.row.course.name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="课程代码" width="80">
          <template slot-scope="scope">
            <span>{{ scope.row.course.code }}</span>
          </template>
        </el-table-column>
        <el-table-column label="试卷名称" width="180">
          <template slot-scope="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="试卷总分"
          width="103"
          sortable
          prop="totalScore"
        >
        </el-table-column>
        <el-table-column
          label="试卷难度"
          width="103"
          sortable
          prop="difficultyDegree"
        >
        </el-table-column>
        <el-table-column
          label="大题数量"
          width="103"
          sortable
          prop="paperDetailCount"
        >
        </el-table-column>
        <el-table-column label="录入员" width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.creator }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="创建时间"
          width="153"
          sortable
          prop="createTime"
        >
        </el-table-column>
        <el-table-column label="修改人" width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.lastModifyName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="修改时间"
          width="153"
          sortable
          prop="updateDate"
        >
        </el-table-column>
        <el-table-column label="操作" width="180" fixed="right">
          <template slot-scope="scope">
            <div class="operate_left">
              <el-button
                size="mini"
                type="primary"
                plain
                @click="editImportPaper(scope.row)"
                ><i class="el-icon-edit"></i>编辑</el-button
              >
              <el-dropdown class="button_left">
                <el-button type="primary" size="mini" plain>
                  更多 <i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <el-button
                      size="mini"
                      type="primary"
                      plain
                      @click="copyImportPaper(scope.row)"
                      ><i class="el-icon-tickets"></i> 复制</el-button
                    >
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-button
                      size="mini"
                      type="primary"
                      plain
                      @click="exportOriginalPaper(scope.row)"
                      ><i class="el-icon-download"></i> 下载</el-button
                    >
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-button
                      size="mini"
                      type="primary"
                      plain
                      @click="exportPaperAnswer(scope.row)"
                      ><i class="el-icon-download"></i>导出答案</el-button
                    >
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-button
                      size="mini"
                      type="danger"
                      @click="deleteImportPaper(scope.row)"
                      ><i class="el-icon-delete"></i> 删除</el-button
                    >
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="page pull-right">
        <el-pagination
          :current-page="currentPage"
          :page-size="pageSize"
          :page-sizes="[10, 20, 50, 100, 200, 300]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      v-loading.body="cloneLoading"
      title="克隆试卷"
      :visible.sync="copyPaperDialog"
      width="500px"
    >
      <el-form
        ref="copyPaperForm"
        :model="copyPaperForm"
        :rules="rules"
        label-position="right"
        label-width="120px"
        inline-message
      >
        <el-row>
          <el-form-item label="试卷名称" prop="paperName">
            <el-input
              v-model="copyPaperForm.paperName"
              class="dialog_input_width"
              placeholder="请输试卷名称"
            ></el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="课程名称" prop="courseNo">
            <el-select
              v-model="copyPaperForm.courseNo"
              class="dialog_input_width"
              :remote-method="getCourses"
              remote
              filterable
              clearable
              placeholder="全部"
              @focus="(e) => getCourses(e.target.value)"
            >
              <el-option
                v-for="item in courseInfoSelect"
                :key="item.courseNo"
                :label="item.courseInfo"
                :value="item.courseNo"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-row>
        <el-row class="margin_top_10 margin_left_120">
          <el-button type="primary" @click="submitCopy('copyPaperForm')"
            >保 存</el-button
          >
          <el-button @click="resetForm2('copyPaperForm')"
            ><i class="el-icon-refresh"></i> 重 置</el-button
          >
          <el-button type="primary" @click="back2('copyPaperForm')"
            ><i class="el-icon-arrow-left"></i> 返 回</el-button
          >
        </el-row>
      </el-form>
    </el-dialog>
    <el-dialog
      title="属性修改"
      :visible.sync="quesPropertyDialog"
      width="500px"
    >
      <el-form
        :loading="quesLoading"
        label-position="right"
        label-width="120px"
      >
        <el-row>
          <el-form-item label="难  度">
            <el-select v-model="difficultyDegree" class="dialog_input_width">
              <el-option
                v-for="item in difficultyDegreeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="公开度">
            <el-select v-model="publicity" class="dialog_input_width">
              <el-option
                v-for="item in publicityList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-row>
        <el-row class="margin_top_10 margin_left_120">
          <el-button
            v-loading.fullscreen.lock="quesLoading"
            type="primary"
            @click="submitUpdate()"
            >保 存</el-button
          >
          <el-button @click="resetForm"
            ><i class="el-icon-refresh"></i> 重 置</el-button
          >
          <el-button type="primary" @click="closePropertyDialog"
            ><i class="el-icon-arrow-left"></i> 返 回</el-button
          >
        </el-row>
      </el-form>
    </el-dialog>
  </section>
</template>

<script>
import { CORE_API, QUESTION_API } from "@/constants/constants";
import { LEVEL_TYPE, PUBLICITY_LIST } from "../constants/constants";
import { mapState } from "vuex";
import LinkTitlesCustom from "@/components/LinkTitlesCustom.vue";
export default {
  components: { LinkTitlesCustom },
  data() {
    return {
      courseLoading: false,
      quesLoading: false,
      formSearch: {
        courseNo: "",
        courseName: "",
        creator: "",
        lastModifyName: "",
        level: "",
        name: "",
      },
      tableData: [],
      levelList: LEVEL_TYPE,
      currentPage: 1,
      pageSize: 10,
      isClear: 0,
      total: 0,
      loading: false,
      courseList: [],
      options: [],
      selectedPaperIds: [],
      cloneLoading: false,
      copyPaperDialog: false,
      copyPaperForm: {
        paperName: "",
        courseNo: null,
      },
      copyPaperId: "",
      quesPropertyDialog: false,
      difficultyDegree: 0.1,
      publicity: true,
      updateType: "",
      difficultyDegreeList: [
        { label: 0.1, value: 0.1 },
        { label: 0.2, value: 0.2 },
        { label: 0.3, value: 0.3 },
        { label: 0.4, value: 0.4 },
        { label: 0.5, value: 0.5 },
        { label: 0.6, value: 0.6 },
        { label: 0.7, value: 0.7 },
        { label: 0.8, value: 0.8 },
        { label: 0.9, value: 0.9 },
        { label: 1.0, value: 1.0 },
      ],
      publicityList: PUBLICITY_LIST,
      rules: {
        paperName: [
          { required: true, message: "请输试卷名称", trigger: "blur" },
        ],
        courseNo: [
          { required: true, message: "请选择课程名称", trigger: "change" },
        ],
      },
    };
  },
  computed: {
    paperIds() {
      var paperIds = "";
      for (let paperId of this.selectedPaperIds) {
        if (!paperIds) {
          paperIds += paperId;
        } else {
          paperIds += "," + paperId;
        }
      }
      return paperIds;
    },
    courseInfoSelect() {
      var courseList = [];
      for (let course of this.courseList) {
        var courseInfo = course.name + "(" + course.code + ")";
        var courseNo = course.code;
        courseList.push({ courseNo: courseNo, courseInfo: courseInfo });
      }
      return courseList;
    },
    noBatchSelected() {
      return this.selectedPaperIds.length === 0;
    },
    ...mapState({ user: (state) => state.user }),
  },
  watch: {
    $route: "initVue",
  },
  created() {
    this.initVue();
  },
  methods: {
    searchFrom() {
      this.currentPage = 1;
      this.searchImportPaper();
    },
    searchImportPaper() {
      var pageNo = Number(this.currentPage);
      this.currentPage = 1;
      this.loading = true;
      var url = QUESTION_API + "/importPaper/" + pageNo + "/" + this.pageSize;
      this.$http.get(url, { params: this.formSearch }).then((response) => {
        this.tableData = response.data.content;
        this.total = response.data.totalElements;
        this.currentPage = pageNo;
        this.loading = false;
      });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.searchImportPaper();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.searchImportPaper();
    },
    getCourseName(courseNo) {
      for (let course of this.courseList) {
        if (course.code == courseNo) {
          this.formSearch.courseName = course.name;
        }
      }
    },
    editImportPaper(row) {
      //缓存查询对象
      this.getCourseName(this.formSearch.courseNo);
      sessionStorage.setItem("import_paper", JSON.stringify(this.formSearch));
      sessionStorage.setItem("import_paper_currentPage", this.currentPage);
      sessionStorage.setItem("question_back", "false");
      this.$router.push({
        path: "/edit_paper/" + row.id + "/import_paper",
      });
    },
    deleteImportPaper(row) {
      this.$confirm("确认删除试卷吗?", "提示", {
        type: "warning",
      }).then(() => {
        this.loading = true;
        let param = new URLSearchParams({
          paperIds: row.id,
        });
        this.$http.post(QUESTION_API + "/paper", param).then(
          () => {
            this.$notify({
              message: "删除成功",
              type: "success",
            });
            this.searchImportPaper();
          },
          (error) => {
            this.$notify({
              message: error.response.data.desc,
              type: "error",
            });
            this.loading = false;
          }
        );
      });
    },
    selectChange(row) {
      this.selectedPaperIds = [];
      row.forEach((element) => {
        this.selectedPaperIds.push(element.id);
      });
    },
    batchDeletePaper() {
      this.$confirm("确认删除试卷吗?", "提示", {
        type: "warning",
      }).then(() => {
        this.loading = true;
        let param = new URLSearchParams({
          paperIds: this.paperIds,
        });
        this.$http.post(QUESTION_API + "/paper", param).then(
          () => {
            this.$notify({
              message: "删除成功",
              type: "success",
            });
            this.searchImportPaper();
            this.selectedPaperIds = [];
          },
          (error) => {
            this.$notify({
              message: error.response.data.desc,
              type: "error",
            });
            this.loading = false;
          }
        );
      });
    },
    //导入试卷
    impPaper() {
      this.$router.push({
        path: "/questions/import_paper_info",
      });
    },
    //查询所有课程
    getCourses(query) {
      this.courseLoading = true;
      this.$http
        .get(CORE_API + "/course/query?name=" + query + "&enable=true")
        .then((response) => {
          this.courseList = response.data;
          this.courseLoading = false;
        });
    },
    useBasePaper() {
      this.loading = true;
      this.$http
        .put(QUESTION_API + "/useBasePaper/" + this.selectedPaperIds)
        .then(
          (response) => {
            console.log("come in response:", response);
            if (!response.data.desc) {
              this.$notify({
                message: "操作成功",
                type: "success",
              });
            } else {
              this.$notify({
                message: response.data.desc,
                type: "error",
              });
            }
            this.loading = false;
          },
          (error) => {
            var message = error.response.data.desc;
            var err = message
              .replace("[", "")
              .replace("]", "")
              .substring(message.indexOf("desc") + 4);
            this.$notify({
              message: err,
              type: "error",
            });
            this.loading = false;
          }
        );
    },
    //克隆试卷
    copyImportPaper(row) {
      this.copyPaperForm.paperName = "";
      this.copyPaperForm.courseNo = "";
      this.copyPaperId = row.id;
      this.copyPaperDialog = true;
    },
    //保存
    submitCopy(formData) {
      this.$refs[formData].validate((valid) => {
        if (valid) {
          this.cloneLoading = true;
          this.$http
            .post(
              QUESTION_API +
                "/clonePaper/" +
                this.copyPaperId +
                "/" +
                this.copyPaperForm.paperName +
                "/" +
                this.copyPaperForm.courseNo
            )
            .then(() => {
              this.$notify({
                message: "复制成功",
                type: "success",
              });
              this.cloneLoading = false;
              this.copyPaperDialog = false;
              this.searchImportPaper();
            })
            .catch((error) => {
              this.$notify({
                type: "error",
                message: error.response.data.msg,
              });
              this.cloneLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    removeItem() {
      sessionStorage.removeItem("import_paper");
      sessionStorage.removeItem("import_paper_currentPage");
    },
    expQuesType() {
      if (!this.formSearch.courseNo) {
        this.$notify({
          title: "警告",
          message: "请输入课程",
          type: "warning",
        });
        return;
      }
      var key = this.user.key;
      var token = this.user.token;
      window.location.href =
        QUESTION_API +
        "/paper/export/course/question/" +
        this.formSearch.courseNo +
        "?$key=" +
        key +
        "&$token=" +
        token;
    },
    exportOriginalPaper(row) {
      var key = this.user.key;
      var token = this.user.token;
      //window.location.href = QUESTION_API+"/originalPaper/export/"+row.id+"/"+ this.user.displayName;
      window.open(
        QUESTION_API +
          "/originalPaper/exportPlus/" +
          row.id +
          "/" +
          this.user.displayName +
          "?$key=" +
          key +
          "&$token=" +
          token
      );
    },
    exportPaperAnswer(row) {
      var key = this.user.key;
      var token = this.user.token;
      window.open(
        QUESTION_API +
          "/paper/answer/export/" +
          row.id +
          "?$key=" +
          key +
          "&$token=" +
          token
      );
    },
    openInit() {
      this.difficultyDegree = 0.1;
      this.publicity = true;
      this.quesPropertyDialog = true;
    },
    //重置
    resetForm() {
      this.difficultyDegree = 0.1;
      this.publicity = true;
    },
    resetForm2(formData) {
      this.copyPaperForm.paperName = "";
      this.copyPaperForm.courseNo = "";
      this.$refs[formData].clearValidate();
    },
    back2(formData) {
      this.resetForm2(formData);
      this.copyPaperDialog = false;
    },
    //试卷修改属性 openQuesPro
    openQuesPro() {
      if (!this.selectedPaperIds || this.selectedPaperIds.length < 1) {
        this.$notify({
          title: "警告",
          message: "请选择试卷",
          type: "warning",
        });
        return;
      }
      this.updateType = "paper";
      this.openInit();
    },
    //课程修改属性 openQuesProC
    openQuesProC() {
      if (!this.formSearch.courseNo) {
        this.$notify({
          title: "警告",
          message: "请输入课程",
          type: "warning",
        });
        return;
      }
      this.updateType = "course";
      this.openInit();
    },
    closePropertyDialog() {
      this.quesPropertyDialog = false;
    },
    submitUpdate() {
      console.log("aaa");
      if (this.updateType == "paper") {
        console.log("paper");
        this.updateQuesPropertyByPaper();
      }
      if (this.updateType == "course") {
        console.log("course");
        this.updateQuesPropertyByCourse();
      }
    },
    updateQuesPropertyByPaper() {
      var params = "?ids=" + this.selectedPaperIds;
      params = params + "&difficultyDegree=" + this.difficultyDegree;
      params = params + "&publicity=" + this.publicity;
      this.quesLoading = true;
      this.$http
        .put(QUESTION_API + "/question/updatePro/paperIds" + params)
        .then(() => {
          this.$notify({
            message: "更新成功",
            type: "success",
          });
          this.searchImportPaper();
          this.quesLoading = false;
          this.closePropertyDialog();
        })
        .catch(() => {
          this.$notify({
            type: "error",
            message: "更新失败",
          });
          this.quesLoading = false;
          this.closePropertyDialog();
        });
    },
    updateQuesPropertyByCourse() {
      this.quesLoading = true;
      var orgId = this.user.rootOrgId;
      this.$http
        .put(
          QUESTION_API +
            "/question/updatePro/courseCode/" +
            this.formSearch.courseNo +
            "/" +
            this.difficultyDegree +
            "/" +
            this.publicity +
            "/" +
            orgId
        )
        .then(() => {
          this.$notify({
            message: "更新成功",
            type: "success",
          });
          this.quesLoading = false;
          this.closePropertyDialog();
        })
        .catch(() => {
          this.$notify({
            type: "error",
            message: "更新失败",
          });
          this.quesLoading = false;
          this.closePropertyDialog();
        });
    },
    initVue() {
      this.isClear = this.$route.params.isClear;
      if (this.isClear == 0 || !this.isClear) {
        this.removeItem();
        this.formSearch = {
          courseNo: "",
          level: "",
          name: "",
        };
        this.currentPage = 1;
      } else {
        this.formSearch = JSON.parse(sessionStorage.getItem("import_paper"));
        this.currentPage =
          sessionStorage.getItem("import_paper_currentPage") == null
            ? 1
            : parseInt(sessionStorage.getItem("import_paper_currentPage"));
      }
      if (this.formSearch.courseName) {
        this.getCourses(this.formSearch.courseName);
      }
      this.handleCurrentChange(this.currentPage);
    },
  },
};
</script>
<style scoped src="../styles/Common.css"></style>
