import Vue from "vue";
import Router from "vue-router";
import PortalRoutes from "../modules/portal/routes/routes";
import BasicRoutes from "../modules/basic/routes/routes";
import examworkRoutes from "../modules/examwork/routes/routes";
import MarklRoutes from "../modules/marking/routes/routes";
import QuestionsRoutes from "../modules/questions/routes/routes";
import OeRoutes from "../modules/oe/routes/routes";
import PrintRoutes from "../modules/print/routes/routes";
import ReportsRoutes from "../modules/reports/routes/routes";
import { CORE_API } from "@/constants/constants.js";

// ignore NavigationDuplicated. https://github.com/vuejs/vue-router/issues/2881
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  try {
    return originalPush.call(this, location).catch((err) => err);
  } catch (error) {
    console.log(error);
  }
};
// end ignore

Vue.use(Router);

let router = new Router({
  mode: "history",
  base: "/admin",
  routes: [
    ...PortalRoutes,
    ...BasicRoutes,
    ...examworkRoutes,
    ...MarklRoutes,
    ...QuestionsRoutes,
    ...OeRoutes,
    ...PrintRoutes,
    ...ReportsRoutes,
  ],
});

router.beforeEach((to, from, next) => {
  if (to.path) {
    window._hmt.push(["_trackPageview", "/admin" + to.fullPath]);
  }

  if (to.path.includes("/preview_paper/")) {
    next();
    return;
  }
  if (!to.meta.privilegeCodes) {
    next();
  } else {
    let params = new URLSearchParams();
    params.append("privilegeCodes", to.meta.privilegeCodes);
    Vue.prototype.$httpWithMsg
      .post(CORE_API + "/rolePrivilege/checkPrivileges?" + params)
      .then((response) => {
        if (Object.values(response.data).includes(true)) {
          next();
        } else {
          Vue.prototype.$alert("没有权限访问！", "提示");
          next(false);
        }
      });
  }
});

export default router;
