<template>
  <section class="content">
    <div class="box box-info">
      <div class="box-body">
        <el-table
          :data="appList"
          border
          resizable
          stripe
          style="width: 800px; text-align: center"
        >
          <el-table-column prop="id" label="ID" width="100" />
          <el-table-column prop="code" label="应用编码" />
          <el-table-column prop="name" label="应用名称" />
        </el-table>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { USER_SIGNIN } from "../../portal/store/user";
import { CORE_API } from "@/constants/constants.js";

export default {
  name: "AppList",
  data() {
    return {
      appList: [],
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
  },
  created() {
    this.queryAppList();
  },
  methods: {
    ...mapActions([USER_SIGNIN]),

    queryAppList: function () {
      var url = CORE_API + "/app/getAllApp";
      this.$http
        .get(url)
        .then((response) => {
          console.log(response);
          this.appList = response.data;
        })
        .catch((response) => {
          if (response.status == 500) {
            this.$notify({
              showClose: true,
              message: response.data.desc,
              type: "error",
            });
          }
        });
    },
  },
};
</script>
