<template>
  <el-main style="display: flex; align-items: center; margin-bottom: 20px">
    <el-row width="100vw">
      <el-col
        v-for="(menu, index) in menuList.filter((m) => m.parentId === null)"
        :key="menu.id"
        :span="11"
        :offset="1"
      >
        <div
          :class="['module-card', index % 2 ? 'float-left' : 'float-right']"
          @click="() => $router.push('/' + menu.ext4 + '/tips')"
        >
          <div style="position: relative">
            <img :class="['module-image', menu.ext4 + '-main-module']" />
            <div class="cover"></div>
          </div>
          <div
            class="align-self-left d-flex d-flex flex-column align-items-start module-desc"
          >
            <div class="h4">{{ menu.name }}</div>
            <div style="width: 400px; font-size: 14px; text-align: left">
              {{ moduleDesc[menu.ext4] && moduleDesc[menu.ext4].detail }}
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </el-main>
</template>

<script>
const moduleDesc = {
  basic: {
    detail: "包括学习中心查询、课程查询、用户查询",
  },
  examwork: {
    detail: "包括安排考试、考试课程、学生档案",
  },
  questions: {
    detail: "包括试卷导入、卷库组成、考试用卷绑定",
  },
  oe: {
    detail: "包括考试过程监考、考试明细查询、考试完成进度查询",
  },
  marking: {
    detail: "包括评卷进度查询、评卷员工作量核算、评卷结果检查",
  },
  print: {
    detail: "记录线下纸笔考试数码印刷准确数量与实施过程",
  },
  reports: {
    detail: "报表",
  },
};

import { mapState } from "vuex";
import { CORE_API } from "@/constants/constants";

export default {
  name: "HomeMain",
  components: {},
  data() {
    return {
      moduleDesc: moduleDesc,
      menuList: [],
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
  },
  async created() {
    this.menuList = await this.getUserPrivileges("PORTAL_MENUS");
  },
  methods: {
    async getUserPrivileges(groupCode) {
      var url = CORE_API + "/rolePrivilege/getUserPrivileges";
      const params = new URLSearchParams();
      params.append("groupCode", groupCode);
      params.append("full", false);
      const res = await this.$httpWithMsg.post(url, params, {
        headers: { "content-type": "application/x-www-form-urlencoded" },
      });

      return res.data;
    },
  },
};
</script>

<style scoped>
.el-main {
  background-color: #e9eef3;
  color: #333;
  text-align: center;
  line-height: 60px;
}

.main-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.module-card {
  height: 120px;
  margin-bottom: 50px;
  margin-right: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;

  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
</style>

<style scoped>
.module-card:hover .h4 {
  color: #4d7cc4 !important;
}
.module-card:hover div {
  color: #65738b !important;
}
.module-image {
  width: 90px;
  height: 90px;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #4d7cc4;
  border-top-left-radius: 20px 20px;
  border-bottom-left-radius: 20px 20px;
}
.module-card:hover .module-image {
  background-color: #55bfff;
  box-shadow: 0px 5px 20px 0px #55bfff;
  color: #4d7cc4 !important;
}
.module-card .cover {
  width: 100px;
  height: 116px;
  position: absolute;
  top: -13px;
  left: 0px;
  transition: all 1s ease-in-out;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: rgba(255, 255, 255, 0.8);
}
.module-card:hover .cover {
  /* background-color: transparent !important; */
  /* left: -100px; */
  width: 0px !important;
  left: -80px !important;
  /* transition: all 2s ease-in-out; */
}
.module-desc {
  background-color: white;
  padding: 10px;
}
.module-card:hover .module-desc {
  box-shadow: 7px 7px 10px 0px rgba(0, 0, 0, 0.1);
}
.basic-main-module {
  background-image: url("./images/basic.png");
}
.module-card:hover .basic-main-module {
  background-image: url("./images/basic-hover.png");
}
.examwork-main-module {
  background-image: url("./images/examwork.png");
}
.module-card:hover .examwork-main-module {
  background-image: url("./images/examwork-hover.png");
}
.questions-main-module {
  background-image: url("./images/questions.png");
}
.module-card:hover .questions-main-module {
  background-image: url("./images/questions-hover.png");
}
.oe-main-module {
  background-image: url("./images/oe.png");
}
.module-card:hover .oe-main-module {
  background-image: url("./images/oe-hover.png");
}
.marking-main-module {
  background-image: url("./images/marking.png");
}
.module-card:hover .marking-main-module {
  background-image: url("./images/marking-hover.png");
}
.print-main-module {
  background-image: url("./images/print.png");
}
.module-card:hover .print-main-module {
  background-image: url("./images/print-hover.png");
}
</style>
