<template>
  <el-container>
    <el-main class="el-main-padding main-wrap">
      <el-row>
        <el-col :span="7">
          <el-form>
            <el-form-item label="考试">
              <el-select
                v-model="examId"
                filterable
                remote
                :remote-method="getExams"
                clearable
                placeholder="请选择考试"
                size="small"
                @clear="getExams"
                @change="changeExam"
              >
                <el-option
                  v-for="item in examList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="7">
          <el-form>
            <el-form-item label="场次">
              <el-select
                v-model="examStageId"
                clearable
                :disabled="examStageDisabled4Search"
                :remote-method="queryExamStages4Search"
                remote
                :loading="queryExamStages4SearchLoading"
                :filterable="true"
                placeholder="请选择"
                size="small"
                @change="changeExamStage"
              >
                <el-option
                  v-for="item in examStageList4Search"
                  :key="item.id"
                  :label="`${item.stageOrder} (${item.startTime}至${item.endTime})`"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="10">
          <el-form>
            <el-form-item label="考试数据同步状态">
              <el-progress
                :text-inside="true"
                :stroke-width="22"
                :percentage="examSyncPercentage"
                status="success"
                style="width: 200px; float: left; line-height: 38px"
              ></el-progress>
              <el-button
                size="small"
                type="primary"
                icon="el-icon-refresh"
                style="float: left; margin-left: 20px; margin-top: 4px"
                @click="refreshExamSyncPercentage"
                >刷新</el-button
              >
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <div class="statistics-info">
        <span class="title">考试实时统计</span>
        <p>
          全校在线人数：
          <span>{{ examRealData.allOnlineCount }}</span>
        </p>
        <p>
          全校在考人数：
          <span>{{ examRealData.allExamOnlineCount }}</span>
        </p>
        <p>
          当前批次在考人数：
          <span>{{ examRealData.examOnlineCount }}</span>
        </p>
      </div>
      <el-row :gutter="2">
        <el-col :span="10" class="chart-border">
          <div class="chart-header">考试进度情况</div>
          <div>
            <v-chart :options="pieOptions" />
          </div>
        </el-col>
        <el-col :span="14" class="chart-border">
          <div class="chart-header">课程完成进度TOP5</div>
          <div>
            <v-chart :options="lineOptions" />
          </div>
        </el-col>
      </el-row>
      <el-row style="margin-top: 10px">
        <el-col :span="24">
          <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane label="学习中心完成进度" name="first">
              <el-row style="margin-top: 20px">
                <el-col :span="24">
                  <el-form>
                    <el-form-item label="学习中心">
                      <el-select
                        v-model="orgId"
                        filterable
                        remote
                        :remote-method="getOrgs"
                        clearable
                        placeholder="请选择学习中心"
                        size="small"
                        @clear="getOrgs"
                        @change="getOrgExamInfos"
                      >
                        <el-option
                          v-for="item in orgList"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item>
                      <el-button
                        v-show="!exportOrgLoading"
                        type="primary"
                        size="small"
                        icon="el-icon-download"
                        @click="exportOrg"
                        >导出</el-button
                      >
                      <el-button
                        v-show="exportOrgLoading"
                        size="small"
                        icon="el-icon-download"
                        :loading="true"
                        >导出数据中...</el-button
                      >
                    </el-form-item>
                  </el-form>
                </el-col>
                <el-col :span="24">
                  <el-table
                    element-loading-text="数据加载中"
                    :data="orgExamInfos"
                    border
                  >
                    <el-table-column
                      sortable
                      label="学习中心代码"
                      prop="orgCode"
                    ></el-table-column>
                    <el-table-column
                      sortable
                      label="学习中心名称"
                      prop="orgName"
                    ></el-table-column>
                    <el-table-column
                      sortable
                      label="报考人数"
                      prop="totalCount"
                    ></el-table-column>
                    <el-table-column
                      sortable
                      label="实考人数"
                      prop="finishedCount"
                    ></el-table-column>
                    <el-table-column
                      sortable
                      :sort-method="sortByFinishedPercent"
                      label="完成比率"
                      prop="finishedPercent"
                    ></el-table-column>
                  </el-table>
                </el-col>
              </el-row>
            </el-tab-pane>
            <el-tab-pane label="课程完成进度" name="two">
              <el-row style="margin-top: 20px">
                <el-col :span="24">
                  <el-form>
                    <el-form-item label="课程">
                      <el-select
                        v-model="courseId"
                        filterable
                        remote
                        :remote-method="getCourses"
                        clearable
                        placeholder="请选择课程"
                        size="small"
                        @clear="getCourses"
                        @change="getCourseProgress"
                      >
                        <el-option
                          v-for="item in courseList"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item>
                      <el-button
                        v-show="!exportCourseLoading"
                        type="primary"
                        size="small"
                        icon="el-icon-download"
                        @click="exportCourse"
                        >导出</el-button
                      >
                      <el-button
                        v-show="exportCourseLoading"
                        size="small"
                        icon="el-icon-download"
                        :loading="true"
                        >导出数据中...</el-button
                      >
                    </el-form-item>
                  </el-form>
                </el-col>
                <el-col :span="24">
                  <el-table
                    element-loading-text="数据加载中"
                    :data="courseProgressList"
                    border
                  >
                    <el-table-column
                      sortable
                      label="课程名称"
                      prop="courseName"
                    ></el-table-column>
                    <el-table-column
                      sortable
                      label="课程代码"
                      prop="courseCode"
                    ></el-table-column>
                    <el-table-column
                      sortable
                      label="报考人数"
                      prop="allNum"
                    ></el-table-column>
                    <el-table-column
                      sortable
                      label="实考人数"
                      prop="completedNum"
                    ></el-table-column>
                    <el-table-column
                      sortable
                      label="完成比率"
                      prop="completedProportion"
                    ></el-table-column>
                  </el-table>
                </el-col>
              </el-row>
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>
<script>
import { mapState } from "vuex";
import ECharts from "vue-echarts/components/ECharts";
import "echarts/lib/component/legend";
import "echarts/lib/component/legendScroll";
import "echarts/lib/chart/pie";
import "echarts/lib/component/polar";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/title";
import "echarts/lib/chart/bar";
import "echarts/lib/chart/line";
import qs from "qs";
import { EXAM_WORK_API } from "@/constants/constants";
export default {
  components: { "v-chart": ECharts },
  data() {
    return {
      examList: [],
      orgList: [],
      courseList: [],
      examId: "",
      examStageId: "",
      orgId: "",
      courseId: "",
      activeName: "first",
      orgExamInfos: [],
      courseProgressList: [],
      lineOptions: {},
      pieOptions: {},
      exportOrgLoading: false,
      exportCourseLoading: false,
      examSyncPercentage: 0,
      examStageDisabled4Search: true,
      queryExamStages4SearchLoading: false,
      examStageList4Search: [],
      currentExamType: null,
      examRealData: {
        allExamOnlineCount: "",
        allOnlineCount: "",
        examOnlineCount: "",
      },
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
  },
  created() {
    this.getExams();
    this.getOrgs();
  },
  methods: {
    getExams(examName) {
      if (!examName) {
        examName = "";
      }
      this.$http
        .get("/api/ecs_exam_work/exam/queryByNameLike", {
          params: {
            enable: true,
            name: examName,
            examTypes: "ONLINE#OFFLINE#ONLINE_HOMEWORK",
          },
        })
        .then((response) => {
          this.examList = response.data;
        });
    },
    getOrgs(orgName) {
      if (!orgName) {
        orgName = "";
      }
      var rootOrgId = this.user.rootOrgId;
      this.$http
        .get("/api/ecs_core/org/query", {
          params: {
            name: orgName,
            rootOrgId: rootOrgId,
            enable: true,
          },
        })
        .then((response) => {
          this.orgList = response.data;
        });
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    getOrgExamInfos() {
      this.$http
        .post(
          "/api/ecs_oe_admin/exam/student/statistic/by/org?examId=" +
            this.examId +
            "&examStageId=" +
            this.examStageId +
            "&orgId=" +
            this.orgId
        )
        .then((response) => {
          if (response.data && response.data.length > 0) {
            this.orgExamInfos = response.data;
          } else {
            this.orgExamInfos = [];
          }
        });
    },
    getCourseProgress() {
      this.$http
        .get("/api/ecs_oe_admin/exam/student/courseProgress/list", {
          params: {
            examId: this.examId,
            examStageId: this.examStageId,
            courseId: this.courseId,
          },
        })
        .then((response) => {
          if (response.data && response.data.length > 0) {
            this.courseProgressList = response.data;
            this.buildLine(response.data);
          } else {
            this.courseProgressList = [];
            this.lineOptions = {};
          }
        });
    },
    getCourses() {
      if (!this.examId) {
        return false;
      }
      this.$http
        .get("/api/ecs_oe_admin/exam/student/findCoursesByExamIdAndOrgId", {
          params: {
            examId: this.examId,
            examStageId: this.examStageId,
            orgId: this.orgId,
          },
        })
        .then((response) => {
          if (response.data && response.data.length > 0) {
            this.courseList = response.data;
          } else {
            this.courseList = [];
          }
        });
    },
    changeExam(examId) {
      this.examStageId = "";
      var exam = this.examList.filter((item) => {
        return item.id == examId;
      })[0];
      this.currentExamType = exam.examType;
      this.getPieData(this.currentExamType);
      this.getCourses();
      this.getOrgExamInfos();
      this.getCourseProgress();

      //场次联动
      if (this.examList.length > 0) {
        let examArr = this.examList.filter((p) => p.id == examId);
        if (examArr && examArr.length > 0) {
          let exam = examArr[0];
          if (
            exam.specialSettingsEnabled &&
            exam.specialSettingsType == "STAGE_BASED"
          ) {
            this.examStageDisabled4Search = false;
            this.queryExamStages4Search("");
          } else {
            this.examStageList4Search = [];
            this.examStageDisabled4Search = true;
          }
        }
      }
      this.getRealTimeData(examId);
    },
    getRealTimeData(examId) {
      this.$http
        .post(
          `/api/ecs_reports/examData/online/statistic?${qs.stringify({
            examId,
            rootOrgId: this.user.rootOrgId,
          })}`
        )
        .then((res) => {
          Object.assign(this.examRealData, res.data);
        });
    },
    changeExamStage() {
      this.getPieData(this.currentExamType);
      this.getCourses();
      this.getOrgExamInfos();
      this.getCourseProgress();
    },

    getPieData(examType) {
      var completedWord =
        examType == "ONLINE" || examType == "ONLINE_HOMEWORK"
          ? "已完成:"
          : "已抽题:";
      var noCompletedWord =
        examType == "ONLINE" || examType == "ONLINE_HOMEWORK"
          ? "未完成:"
          : "未抽题:";
      if (!this.examId) {
        return;
      }
      this.$http
        .post(
          "/api/ecs_oe_admin/exam/student/statistic/by/finished?examId=" +
            this.examId +
            "&examStageId=" +
            this.examStageId
        )
        .then((response) => {
          var resp = response.data;
          var optionData = {
            title: "考试人次:" + (resp.finished + resp.unFinished),
            legendData: [
              noCompletedWord + resp.unFinished,
              completedWord + resp.finished,
            ],
            seriesData: [
              {
                name: noCompletedWord + resp.unFinished,
                value: resp.unFinished,
              },
              {
                name: completedWord + resp.finished,
                value: resp.finished,
              },
            ],
          };
          this.buildPieOptions(optionData);
        });
    },
    buildPieOptions(data) {
      var colors = ["#7CB5EC", "#FE8463"];
      this.pieOptions = {
        color: colors,
        title: {
          text: data.title,
          subtext: "",
          x: "left",
        },
        tooltip: {
          trigger: "item",
          formatter: "{b}人次<br/>占比:{d}%",
        },
        legend: {
          type: "scroll",
          orient: "vertical",
          right: 200,
          top: 30,
          data: data.legendData,
        },
        series: [
          {
            name: "",
            type: "pie",
            radius: "50%",
            center: ["35%", "60%"],
            data: data.seriesData,
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
    },
    buildLine(courseProgressList) {
      courseProgressList.sort(function (a, b) {
        if (b["completedProportion"] != a["completedProportion"]) {
          return b["completedProportion"] - a["completedProportion"];
        } else if (b["allNum"] != a["allNum"]) {
          return b["allNum"] - a["allNum"];
        } else {
          return b["completedNum"] - a["completedNum"];
        }
      });

      var campusCount = 5;
      var courseProgressDataList = [];
      //找出5个完成比例最高的
      if (courseProgressList.length >= campusCount) {
        courseProgressDataList = courseProgressList.slice(0, campusCount);
      } else {
        courseProgressDataList = courseProgressList;
      }
      var xAxisData = [];
      var seriesBar = {
        name: "计划数",
        type: "bar",
        data: [],
      };
      var seriesLine1 = {
        name: "完成数",
        type: "line",
        data: [],
      };
      var seriesLine2 = {
        name: "完成比(%)",
        type: "line",
        yAxisIndex: 1,
        data: [],
      };
      var yAxis_maxScale1 = 0;
      for (var i = 0; i < courseProgressDataList.length; i++) {
        xAxisData.push(courseProgressDataList[i].courseName);
        seriesBar.data.push(courseProgressDataList[i].allNum);
        seriesLine1.data.push(courseProgressDataList[i].completedNum);
        seriesLine2.data.push(courseProgressDataList[i].completedProportion);
        if (courseProgressDataList[i].allNum > yAxis_maxScale1) {
          yAxis_maxScale1 = courseProgressDataList[i].allNum;
        }
      }
      var optionData = {
        legendData: ["计划数", "完成数", "完成比(%)"],
        xAxis: { data: xAxisData },
        series: [seriesBar, seriesLine1, seriesLine2],
        yAxis_maxScale1,
      };
      this.buildLineOptions(optionData);
    },
    buildLineOptions(optionData) {
      var colors = ["#FE8463", "#66CCFF", "#675bba"];
      this.lineOptions = {
        color: colors,
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        toolbox: {
          feature: {
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ["line", "bar"] },
            restore: { show: true },
            saveAsImage: { show: true },
          },
        },
        legend: {
          data: optionData.legendData,
        },
        xAxis: [
          {
            type: "category",
            data: optionData.xAxis.data,
            axisPointer: {
              type: "shadow",
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "人次",
            min: 0,
            max: optionData.yAxis_maxScale1,
            interval: 10000,
            axisLabel: {
              formatter: "{value} ",
            },
          },
          {
            type: "value",
            name: "完成比例",
            min: 0,
            max: 100,
            interval: 20,
            axisLabel: {
              formatter: "{value} %",
            },
          },
        ],
        series: optionData.series,
      };
    },
    sortByFinishedPercent(obj1, obj2) {
      let p1 = Number(obj1.finishedPercent);
      let p2 = Number(obj2.finishedPercent);
      return p1 - p2;
    },
    exportOrg() {
      if (!this.examId) {
        this.$notify({
          title: "警告",
          message: "请选择考试",
          type: "warning",
          duration: 1000,
        });
        return false;
      }

      this.$confirm("确定执行导出?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.exportOrgLoading = true;
        this.$http
          .get("/api/ecs_oe_admin/exam/student/statistic/by/org/export", {
            params: {
              examId: this.examId,
              examStageId: this.examStageId,
              orgId: this.orgId,
            },
            responseType: "arraybuffer",
            timeout: 20 * 60 * 1000, //限时20分钟
          })
          .then((response) => {
            if (response.data) {
              var blob = new Blob([response.data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              });
              var url = URL.createObjectURL(blob);
              var a = document.createElement("a");
              a.href = url;
              a.download = "学习中心完成进度.xlsx";
              a.target = "_blank";
              a.click();
              URL.revokeObjectURL(url);
            }
            this.exportOrgLoading = false;
          });
      });
    },
    exportCourse() {
      this.exportCourseLoading = true;
      this.$http
        .get("/api/ecs_oe_admin/exam/student/courseProgress/list/export", {
          params: {
            examId: this.examId,
            examStageId: this.examStageId,
            courseId: this.courseId,
          },
          responseType: "arraybuffer",
          timeout: 20 * 60 * 1000, //限时20分钟
        })
        .then((response) => {
          if (response.data) {
            var blob = new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            var url = URL.createObjectURL(blob);
            var a = document.createElement("a");
            a.href = url;
            a.download = "课程完成进度.xlsx";
            a.target = "_blank";
            a.click();
            URL.revokeObjectURL(url);
          }
          this.exportCourseLoading = false;
        });
    },
    refreshExamSyncPercentage() {
      if (!this.examId) {
        this.$notify({
          title: "警告",
          message: "请选择考试",
          type: "warning",
          duration: 2000,
        });
        return false;
      }
      this.$http
        .get("/api/ecs_oe_admin/examControl/getExamSyncPercentage", {
          params: { examId: this.examId },
        })
        .then((response) => {
          this.examSyncPercentage = response.data;
        })
        .catch((res) => {
          var errorMsg = "操作失败";
          if (res.response && res.response.data) {
            errorMsg = res.response.data.desc;
          }
          this.$notify({
            title: "提示",
            message: errorMsg,
            type: "error",
          });
        });
    },
    queryExamStages4Search(name) {
      this.queryExamStages(this.examId, name, "search");
    },
    queryExamStages(examId, name, where) {
      console.log("queryExams; name: " + name);
      let url =
        EXAM_WORK_API +
        "/examStage/queryByNameLike?examId=" +
        examId +
        "&enable=true&name=" +
        name;
      this.$httpWithMsg
        .get(url)
        .then((response) => {
          if ("search" == where) {
            this.queryExamStages4SearchLoading = false;
            this.examStageList4Search = response.data;
          }
        })
        .catch((response) => {
          console.log(response);
          if ("search" == where) {
            this.queryExamStages4SearchLoading = false;
          }
        });
    },
  },
};
</script>
<style>
.chart-border {
  border: 1px solid #ddd;
}
.chart-header {
  color: #333;
  font-size: 14px;
  background-color: #f5f5f5;
  border-color: #ddd;
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
</style>
<style scoped src="../style/common.css"></style>
<style scoped lang="scss">
.statistics-info {
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-top: 1px solid #eee;
  margin-top: 10px;
  p {
    margin: 0;
    margin-left: 80px;
    font-size: 13px;
    span {
      font-weight: bold;
    }
  }
  span.title {
    font-weight: bold;
    color: #909399;
  }
}
</style>
