import Vue from "vue";
import {
  QUESTION_TYPES,
  EXAM_TYPES,
  EXPORT_TYPES,
} from "../constants/constants";
//option字母顺序过滤器
Vue.filter("optionOrderWordFilter", function (value) {
  return String.fromCharCode(65 + value);
});
//题型过滤器
Vue.filter("questionType", function (value) {
  for (let questionType of QUESTION_TYPES) {
    if (questionType.value === value) {
      return questionType.label;
    }
  }
});
Vue.filter("examTypesFilter", function (value) {
  for (let item of EXAM_TYPES) {
    if (item.value === value) {
      return item.label;
    }
  }
});
Vue.filter("exportTypesFilter", function (value) {
  for (let item of EXPORT_TYPES) {
    if (item.value === value) {
      return item.label;
    }
  }
});
