<template>
  <div>
    <section class="content">
      <div>
        <div>
          <el-form
            :inline="true"
            :model="formSearch"
            label-position="right"
            label-width="100px"
            @submit.native.prevent
          >
            <el-form-item label="评卷工作名称" class="pull-left">
              <el-input
                v-model="formSearch.name"
                placeholder="评卷工作名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="归档状态" class="pull-left">
              <el-select
                v-model="formSearch.archived"
                clearable
                filterable
                class="input"
                placeholder="请选择"
              >
                <el-option label="已归档" value="true"></el-option>
                <el-option label="未归档" value="false"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button size="small" type="primary" @click="search"
                >查询</el-button
              >
              <el-button
                size="small"
                type="primary"
                icon="el-icon-plus"
                @click="addMarkWorkModal"
                >新增</el-button
              >
            </el-form-item>
          </el-form>
          <div class="block-seperator"></div>
          <el-table
            v-loading="loading"
            stripe
            element-loading-text="拼命加载中"
            :data="tableData"
            border
            style="width: 100%"
          >
            <el-table-column label="评卷工作名称" width="150">
              <template slot-scope="scope">
                <div>
                  <span>{{ scope.row.name }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="考试名称-类型" width="250">
              <template slot-scope="scope">
                <div>
                  <span
                    v-for="item in scope.row.examNameAndType"
                    :key="item.index"
                    style="display: block"
                    >{{ item }}</span
                  >
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="进度(%)"
              width="100"
              prop="progress"
              sortable
            >
            </el-table-column>
            <el-table-column label="备注" width="100">
              <template slot-scope="scope">
                <div>
                  <span>{{ scope.row.remark }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="状态" width="100">
              <template slot-scope="scope">
                <div>
                  <span>{{ getStatus(scope.row.status) }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="评卷是否结束" width="100">
              <template slot-scope="scope">
                <div>
                  <span>{{ getIsEndText(scope.row.isEnd) }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="是否归档" width="90">
              <template slot-scope="scope">
                <div>
                  <span>{{ getArchivedText(scope.row.archived) }}</span>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="操作">
              <template v-if="!scope.row.archived" slot-scope="scope">
                <div class="pull-left">
                  <el-button
                    type="primary"
                    size="mini"
                    plain
                    @click="settingMarkWork(scope.row)"
                    >评卷设置</el-button
                  >
                  <el-dropdown
                    style="margin-left: 10px"
                    placement="bottom-start"
                  >
                    <el-button size="mini" type="primary" plain>
                      更多 <i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item v-if="scope.row.status == 2">
                        <el-button
                          type="primary"
                          size="mini"
                          plain
                          @click="recreate(scope.row)"
                          >重新创建</el-button
                        >
                      </el-dropdown-item>
                      <el-dropdown-item>
                        <el-button
                          type="primary"
                          size="mini"
                          icon="el-icon-edit"
                          plain
                          @click="editMarkWorkModal(scope.row)"
                          >编辑</el-button
                        >
                      </el-dropdown-item>
                      <el-dropdown-item>
                        <el-button
                          type="danger"
                          size="mini"
                          icon="el-icon-delete"
                          @click="delMarkWork(scope.row)"
                          >删除</el-button
                        >
                      </el-dropdown-item>
                      <el-dropdown-item>
                        <el-button
                          size="mini"
                          icon="el-icon-bell"
                          @click="pubGrade(scope.row)"
                          >成绩发布</el-button
                        >
                      </el-dropdown-item>
                      <el-dropdown-item
                        v-if="!scope.row.isEnd && scope.row.status == 1"
                      >
                        <el-button
                          type="primary"
                          size="mini"
                          plain
                          @click="endMarkWork(scope.row)"
                          >结束评卷</el-button
                        >
                      </el-dropdown-item>
                      <el-dropdown-item
                        v-if="scope.row.isEnd && scope.row.status == 1"
                      >
                        <el-button
                          type="primary"
                          size="mini"
                          plain
                          @click="disendMarkWork(scope.row)"
                          >开启评卷</el-button
                        >
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </template>
            </el-table-column>
          </el-table>

          <div class="page pull-right">
            <el-pagination
              background
              :current-page="currentPage"
              :page-size="pageSize"
              :page-sizes="[10, 20, 50, 100, 200, 300]"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              @current-change="handleCurrentChange"
              @size-change="handleSizeChange"
            ></el-pagination>
          </div>

          <el-dialog
            v-if="markWorkDialog"
            v-loading="loading"
            :title="examTitle"
            element-loading-text="拼命加载中"
            :visible.sync="markWorkDialog"
            width="500px"
            @open="openModal"
            @close="closeModal"
          >
            <el-form
              ref="formMarkWork"
              :model="formMarkWork"
              label-width="120px"
              :rules="rules"
              inline
              inline-message
            >
              <el-form-item label="评卷工作名称" prop="name">
                <el-input
                  v-model="formMarkWork.name"
                  placeholder="评卷工作名称"
                  style="width: 200px"
                ></el-input>
              </el-form-item>
              <el-form-item label="考试类型" prop="examType">
                <el-select
                  v-model="formMarkWork.examType"
                  style="width: 200px"
                  :disabled="examDisabled"
                  @change="examTypeChange()"
                >
                  <el-option label="在线考试" value="ONLINE"> </el-option>
                  <el-option label="离线考试" value="OFFLINE"> </el-option>
                  <el-option label="在线作业" value="ONLINE_HOMEWORK">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="考试" prop="examIds">
                <el-select
                  v-model="formMarkWork.examIds"
                  :disabled="examDisabled"
                  filterable
                  multiple
                  placeholder="考试"
                  style="width: 200px"
                >
                  <el-option
                    v-for="item in examSelect"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="按学习中心阅卷" prop="orgEnabled">
                <el-radio-group v-model="formMarkWork.orgEnabled">
                  <el-radio :label="true">是</el-radio>
                  <el-radio :label="false">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="备注" prop="remark">
                <el-input
                  v-model="formMarkWork.remark"
                  style="width: 200px"
                  type="textarea"
                  :rows="3"
                  placeholder="备注"
                ></el-input>
              </el-form-item>
              <el-form-item style="margin-top: 10px; margin-left: 100px">
                <el-button
                  :loading="saveLoading"
                  type="primary"
                  @click="saveMarkWork"
                  >保存</el-button
                >
                <el-button @click="closeMarkWork">取消</el-button>
                <el-button @click="resetForm">重置</el-button>
              </el-form-item>
            </el-form>
          </el-dialog>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { MARKING_API, MARKING_LOGIC_API } from "@/constants/constants";
export default {
  data() {
    return {
      formSearch: {
        name: "",
        archived: "",
      },
      formMarkWork: {
        examType: "",
        name: "",
        examIds: [],
        remark: "",
        orgEnabled: false,
      },
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        examIds: [
          {
            required: true,
            message: "请选择考试",
            trigger: "change",
            type: "array",
          },
        ],
        examType: [
          {
            required: true,
            message: "请选择考试类型",
            trigger: "change",
            type: "string",
          },
        ],
      },
      statusList: [
        { code: 0, name: "创建中" },
        { code: 1, name: "创建成功" },
        { code: 2, name: "创建失败" },
        { code: 3, name: "追加中" },
      ],
      examSelect: [],
      tableData: [],
      totalTableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      markWorkDialog: false,
      markWorkId: "",
      examDisabled: false,
      examTitle: "",
      loading: false,
      closeClickModal: false,
      saveLoading: false,
      oldWorkName: "",
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
  },
  watch: {},
  created() {
    this.initMarkWorkData();
  },
  methods: {
    settingMarkWork(row) {
      if (Number.parseInt(row.status) != 1) {
        this.$notify({
          message: "该评卷工作未完成，不能进行设置.",
          type: "warning",
        });
        return;
      }
      var url =
        "/marking/mark_setting_main/" +
        row.id +
        "/" +
        encodeURIComponent(row.name);
      this.$router.push({
        path: url,
      });
    },
    getStatus(code) {
      for (let status of this.statusList) {
        if (status.code == code) {
          return status.name;
        }
      }
    },
    getIsEndText(isEnd) {
      if (isEnd) {
        return "已结束";
      } else {
        return "未结束";
      }
    },

    getArchivedText(archived) {
      if (archived) {
        return "已归档";
      } else {
        return "未归档";
      }
    },

    appendPaper(row) {
      this.loading = true;
      this.$http
        .put(MARKING_LOGIC_API + "/markWorks/append-paper/" + row.id)
        .then(() => {
          this.$notify({
            message: "操作成功",
            type: "success",
          });
          this.initMarkWorkData();
          this.loading = false;
        });
    },
    recreate(row) {
      this.loading = true;
      this.$http.put(MARKING_LOGIC_API + "/markWorks/" + row.id).then(() => {
        this.$notify({
          message: "操作成功",
          type: "success",
        });
        this.initMarkWorkData();
        this.loading = false;
      });
    },
    examTypeChange() {
      this.examSelect = [];
      this.formMarkWork.examIds = [];
      if (this.formMarkWork.examType) {
        this.getExamSelect();
      }
    },
    getExamSelect() {
      var orgId = this.user.rootOrgId;
      var examIds = this.formMarkWork.examIds;
      var tempSelect = [];
      //this.loading = true;
      var params = "?orgId=" + orgId;
      if (examIds) {
        params = params + "&examIds=" + examIds;
      }
      if (this.formMarkWork.examType) {
        params = params + "&examType=" + this.formMarkWork.examType;
      }
      this.$http
        .get(MARKING_LOGIC_API + "/exam/all" + params)
        .then((response) => {
          response.data.forEach((element, index) => {
            tempSelect[index] = {
              value: element.id,
              label: element.name,
            };
          });
          this.examSelect = tempSelect;
          //this.loading = false;
        });
    },
    search() {
      this.currentPage = 1;
      this.initMarkWorkData();
    },
    initMarkWorkData() {
      var orgId = this.user.rootOrgId;
      this.loading = true;
      this.$http
        .get(
          MARKING_LOGIC_API +
            "/markWorks?orgId=" +
            orgId +
            `&pageNumber=${this.currentPage - 1}&pageSize=${
              this.pageSize
            }&name=${this.formSearch.name}` +
            "&ignoreArchived=true&archived=" +
            this.formSearch.archived
        )
        .then((response) => {
          console.log(response);
          // this.totalTableData = response.data;
          this.tableData = response.data?.content || [];
          // this.total = response.data.length;
          this.total = response.data?.totalElements;
          // this.filterMarkWork();
          // this.paging();
          this.loading = false;
          // this.getWorkStatus();
        });
    },
    getWorkStatus() {
      let creatingWorkIds = this.getCreatingWorkId();
      if (creatingWorkIds != "") {
        this.$httpWithoutBar
          .get(
            MARKING_API + "/markWorks/getWorkList?workIds=" + creatingWorkIds
          )
          .then((response) => {
            response.data.forEach((element) => {
              if (element.status != 0) {
                this.tableData.forEach((e) => {
                  if (e.id == element.id) {
                    e.status = element.status;
                  }
                });
              }
            });
            setTimeout(() => {
              this.getWorkStatus();
            }, 30000);
          });
      }
    },
    getCreatingWorkId() {
      var tempData = [];
      this.tableData.forEach((element) => {
        if (element.status == 0) {
          tempData.push(element.id);
        }
      });
      return tempData.join(",");
    },
    searchMarkWork() {
      this.filterMarkWork();
      this.paging();
    },
    filterMarkWork() {
      var tempData = this.totalTableData.filter((element) => {
        if (this.formSearch.name) {
          return element.name.includes(this.formSearch.name);
        } else {
          return true;
        }
      });
      this.tableData = tempData;
      this.total = tempData.length;
    },
    paging() {
      // var start = (this.currentPage - 1) * this.pageSize;
      // var end =
      //   this.currentPage * this.pageSize < this.total
      //     ? this.currentPage * this.pageSize
      //     : this.total;
      // var tempData = [];
      // console.log(`当前页: ${this.currentPage},开始:${start},结束:${end}`);
      // for (let i = start; i < end; i++) {
      //   tempData.push(this.tableData[i]);
      // }
      // this.tableData = tempData;
    },
    openModal() {
      console.log("open");
    },
    closeModal() {
      console.log("close");
    },
    addMarkWorkModal() {
      this.formMarkWork = {
        name: "",
        remark: "",
        examType: "",
        examIds: [],
        orgEnabled: false,
      };
      this.examSelect = [];
      this.markWorkId = "";
      this.examDisabled = false;
      this.examTitle = "新增评卷工作";
      this.oldWorkName = "";
      if (this.$refs["formMarkWork"] !== undefined) {
        this.$refs["formMarkWork"].resetFields();
      }
      this.markWorkDialog = true;
    },
    editMarkWorkModal(row) {
      this.examTitle = "编辑评卷工作";
      this.formMarkWork = Object.assign({}, row, {});
      this.oldWorkName = this.formMarkWork.name;
      this.markWorkId = row.id;
      this.getExamSelect();
      this.loading = false;
      this.examDisabled = true;
      this.markWorkDialog = true;
    },
    delMarkWork(row) {
      if (Number.parseInt(row.status) == 0) {
        this.$notify({
          message: "创建中的评卷工作不能删除！",
          type: "error",
        });
        return;
      }
      this.$confirm("确认删除评卷工作?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.loading = true;
        this.$http
          .delete(MARKING_API + "/markWorks/" + row.id)
          .then(() => {
            this.$notify({
              message: "删除成功",
              type: "success",
            });
            this.initMarkWorkData();
          })
          .catch((e) => {
            this.$notify({
              message: e.response.data.desc,
              type: "error",
            });
          });
        this.loading = false;
      });
    },
    endMarkWork(row) {
      this.$confirm("确认结束评卷工作?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.loading = true;
        this.$http
          .put(MARKING_API + "/markWorks/end/" + row.id)
          .then(() => {
            this.$notify({
              message: "操作成功",
              type: "success",
            });
            this.initMarkWorkData();
          })
          .catch((e) => {
            this.$notify({
              message: e.response.data.desc,
              type: "error",
            });
          });
        this.loading = false;
      });
    },
    disendMarkWork(row) {
      this.$confirm("确认开启评卷工作?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.loading = true;
        this.$http
          .put(MARKING_API + "/markWorks/disend/" + row.id)
          .then(() => {
            this.$notify({
              message: "操作成功",
              type: "success",
            });
            this.initMarkWorkData();
          })
          .catch((e) => {
            this.$notify({
              message: e.response.data.desc,
              type: "error",
            });
          });
        this.loading = false;
      });
    },
    pubGrade(row) {
      if (!(Number.parseInt(row.progress) == 100)) {
        this.$notify({
          message: "进度未达到100%，不能进行成绩发布",
          type: "error",
        });
        return;
      }
      this.$confirm("确认成绩发布?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.loading = true;
        this.$http.post(MARKING_API + "/markWorks/" + row.id + "/publish").then(
          () => {
            this.$notify({
              message: "成绩发布成功",
              type: "success",
            });
          },
          () => {
            this.$notify({
              message: "成绩发布失败",
              type: "error",
            });
          }
        );
        this.initMarkWorkData();
        this.loading = false;
      });
    },
    checkWorkName(workName) {
      for (let workList of this.totalTableData) {
        if (workList.name == workName) {
          this.$notify({
            message: "评卷工作名称已存在，请重新输入！",
            type: "warning",
          });
          return true;
        }
      }
      return false;
    },
    saveMarkWork() {
      this.$refs.formMarkWork.validate((valid) => {
        if (valid) {
          var newWorkName = this.formMarkWork.name;
          if (newWorkName != this.oldWorkName) {
            if (this.checkWorkName(newWorkName)) {
              return;
            }
          }
          this.saveLoading = true;
          this.oldWorkName = "";
          this.loading = true;
          if (this.markWorkId) {
            this.$http
              .put(
                MARKING_API + "/markWorks/" + this.markWorkId,
                this.formMarkWork
              )
              .then(
                () => {
                  this.$notify({
                    message: "评卷工作保存成功",
                    type: "success",
                  });
                  this.closeMarkWork();
                  this.initMarkWorkData();
                  this.loading = false;
                  this.saveLoading = false;
                },
                (error) => {
                  this.$notify({
                    message: error.response.data.desc,
                    type: "error",
                  });
                  this.loading = false;
                  this.saveLoading = false;
                }
              );
          } else {
            this.$http
              .post(MARKING_LOGIC_API + "/markWorks", this.formMarkWork)
              .then(
                () => {
                  this.$notify({
                    message: "评卷工作新增成功",
                    type: "success",
                  });
                  this.closeMarkWork();
                  this.initMarkWorkData();
                  this.loading = false;
                  this.saveLoading = false;
                },
                (error) => {
                  this.$notify({
                    message: error.response.data.desc,
                    type: "error",
                  });
                  this.loading = false;
                  this.saveLoading = false;
                }
              );
          }
        } else {
          return false;
        }
      });
    },
    closeMarkWork() {
      this.markWorkDialog = false;
    },
    resetForm() {
      if (this.markWorkId) {
        //编辑
        this.formMarkWork.name = "";
        this.formMarkWork.remark = "";
        this.formMarkWork.orgEnabled = false;
        this.$refs["formMarkWork"].clearValidate();
      } else {
        //新增
        this.formMarkWork = Object.assign(this.formMarkWork, {
          remark: "",
          examName: "",
          name: "",
          orgEnabled: false,
        });
        this.$refs["formMarkWork"].clearValidate();
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.initMarkWorkData();
      // this.filterMarkWork();
      // this.paging();
      //this.searchMarkWork()
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.initMarkWorkData();
      // this.filterMarkWork();
      // this.paging();
    },
  },
};
</script>
<style scoped>
.el-button--mini {
  margin-left: 10px;
}
</style>
