<template>
  <div>
    <LinkTitlesCustom
      :current-paths="['考试管理', '考试信息', '学习中心设置']"
    />
    <section class="content">
      <div class="box-body">
        <el-form
          :model="formSearch"
          :inline="true"
          label-position="right"
          label-width="80px"
        >
          <el-form-item label="学习中心">
            <el-select
              v-model="formSearch.orgId"
              class="select"
              :remote-method="getOrgList4Search"
              :loading="loadingOrg4Search"
              remote
              filterable
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in orgList4Search"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="d-block">
            <el-button
              size="small"
              type="primary"
              icon="el-icon-search"
              @click="resetPageAndSearch"
              >查询</el-button
            >
            <el-button
              size="small"
              type="primary"
              icon="el-icon-plus"
              @click="showAddOrgSetting"
              >新增</el-button
            >
            <el-button size="small" type="primary" @click="back"
              >返 回</el-button
            >
          </el-form-item>
        </el-form>

        <!-- 页面列表 -->
        <el-table
          v-loading="loading4FormSearch"
          :data="tableData"
          border
          style="width: 100%; text-align: center"
        >
          <el-table-column prop="id" width="80" label="ID"></el-table-column>
          <el-table-column
            prop="orgCode"
            label="学习中心代码"
          ></el-table-column>
          <el-table-column
            prop="orgName"
            label="学习中心名称"
          ></el-table-column>
          <el-table-column prop="beginTime" width="155" label="开始时间">
          </el-table-column>
          <el-table-column prop="endTime" width="155" label="结束时间">
          </el-table-column>
          <el-table-column prop="updateTime" width="155" label="更新时间">
          </el-table-column>
          <el-table-column width="80" label="上传附件">
            <span slot-scope="scope"> {{ getCanUpload(scope.row) }} </span>
          </el-table-column>
          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <div>
                <el-button
                  size="mini"
                  type="primary"
                  plain
                  icon="el-icon-edit"
                  @click="showUpdateOrgSetting(scope.row)"
                >
                  编辑
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="page pull-right">
          <el-pagination
            :current-page="currentPage"
            :page-size="pageSize"
            :page-sizes="[10, 20, 50, 100, 200, 300]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          >
          </el-pagination>
        </div>

        <!-- 新增 -->
        <el-dialog
          title="新增学习中心考试设置"
          size="tiny"
          :visible.sync="addOrgSettingDialog"
        >
          <el-form
            ref="addOrgSettingForm"
            :model="orgSetting"
            :rules="orgSettingRules"
            label-position="right"
            :inline="true"
            inline-message
          >
            <el-row>
              <el-form-item label="学习中心" prop="orgId" label-width="120px">
                <el-select
                  v-model="orgSetting.orgId"
                  class="select"
                  :remote-method="getOrgList4InsertOrUpdate"
                  :loading="loadingOrg4InsertOrUpdate"
                  remote
                  filterable
                  clearable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in orgList4InsertOrUpdate"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item
                label="考试时间"
                prop="orgSettingDatetimeRange"
                label-width="120px"
              >
                <el-date-picker
                  v-model="orgSettingDatetimeRange"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label-width="120px" label="上传附件">
                <el-radio-group
                  v-model="orgSetting.properties.CAN_UPLOAD_ATTACHMENT"
                >
                  <el-radio label="true">允许</el-radio>
                  <el-radio label="false">不允许</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-row>
            <el-row>
              <div style="text-align: center">
                <el-button type="primary" @click="addOrgSetting"
                  >确 定</el-button
                >
                <el-button @click="addOrgSettingDialog = false"
                  >取 消</el-button
                >
              </div>
            </el-row>
          </el-form>
        </el-dialog>

        <!-- 修改 -->
        <el-dialog
          title="修改学习中心考试设置"
          size="tiny"
          :visible.sync="updateOrgSettingDialog"
        >
          <el-form
            ref="updateOrgSettingForm"
            :model="orgSetting"
            :rules="orgSettingRules"
            label-position="right"
            :inline="true"
            inline-message
          >
            <el-row>
              <el-form-item label="学习中心" prop="orgId" label-width="120px">
                <el-select
                  v-model="orgSetting.orgId"
                  class="select"
                  :remote-method="getOrgList4InsertOrUpdate"
                  :loading="loadingOrg4InsertOrUpdate"
                  remote
                  filterable
                  clearable
                  :disabled="true"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in orgList4InsertOrUpdate"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item
                label="考试时间"
                prop="orgSettingDatetimeRange"
                label-width="120px"
              >
                <el-date-picker
                  v-model="orgSettingDatetimeRange"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label-width="120px" label="上传附件">
                <el-radio-group
                  v-model="orgSetting.properties.CAN_UPLOAD_ATTACHMENT"
                >
                  <el-radio label="true">允许</el-radio>
                  <el-radio label="false">不允许</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-row>
            <el-row>
              <div style="margin-left: 30%">
                <el-button type="primary" @click="updateOrgSetting"
                  >确 定</el-button
                >
                <el-button @click="updateOrgSettingDialog = false"
                  >取 消</el-button
                >
              </div>
            </el-row>
          </el-form>
        </el-dialog>
      </div>
    </section>
  </div>
</template>

<script>
import { CORE_API, EXAM_WORK_API } from "@/constants/constants.js";
import LinkTitlesCustom from "@/components/LinkTitlesCustom.vue";

export default {
  components: { LinkTitlesCustom },
  data() {
    return {
      examId: null,
      loading4FormSearch: false,
      formSearch: {
        examId: null,
        orgId: "",
      },
      loadingOrg4Search: false,
      orgList4Search: [],

      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,

      orgSetting: {
        examId: null,
        orgId: null,
        beginTime: null,
        endTime: null,
        properties: {
          CAN_UPLOAD_ATTACHMENT: "true",
        },
      },
      orgSettingDatetimeRange: [],
      addOrgSettingDialog: false,
      updateOrgSettingDialog: false,
      orgList4InsertOrUpdate: [],
      loadingOrg4InsertOrUpdate: false,
      orgSettingRules: {
        orgId: [
          { required: true, message: "请选择学习中心", trigger: "blur,change" },
        ],
      },
    };
  },
  created() {
    this.examId = this.$route.params.id;
    this.formSearch.examId = this.examId;
    this.init();
  },
  methods: {
    getOrgList4Search(name) {
      this.loadingOrg4Search = true;
      var url = CORE_API + "/org/query?" + new URLSearchParams({ name: name });
      this.$httpWithMsg
        .get(url)
        .then((response) => {
          this.orgList4Search = response.data;
          this.loadingOrg4Search = false;
        })
        .catch((response) => {
          console.log(response);
          this.loadingOrg4Search = false;
        });
    },
    getOrgList4InsertOrUpdate(name) {
      this.loadingOrg4InsertOrUpdate = true;
      var url = CORE_API + "/org/query?" + new URLSearchParams({ name: name });
      this.$httpWithMsg
        .get(url)
        .then((response) => {
          this.orgList4InsertOrUpdate = response.data;
          this.loadingOrg4InsertOrUpdate = false;
        })
        .catch((response) => {
          console.log(response);
          this.loadingOrg4InsertOrUpdate = false;
        });
    },
    updateOrgSetting() {
      if (!this.orgSettingDatetimeRange) {
        this.orgSetting.beginTime = null;
        this.orgSetting.endTime = null;
      } else {
        this.orgSetting.beginTime = this.orgSettingDatetimeRange[0];
        this.orgSetting.endTime = this.orgSettingDatetimeRange[1];
      }

      this.$refs.updateOrgSettingForm.validate((valid) => {
        if (valid) {
          let url = EXAM_WORK_API + "/exam/examOrgSettings";
          this.$httpWithMsg.post(url, this.orgSetting).then((response) => {
            console.log(response);
            this.$notify({
              type: "success",
              message: "保存成功",
            });
            this.search();
            this.updateOrgSettingDialog = false;
          });
        } else {
          return false;
        }
      });
    },
    addOrgSetting() {
      if (!this.orgSettingDatetimeRange) {
        this.orgSetting.beginTime = null;
        this.orgSetting.endTime = null;
      } else {
        this.orgSetting.beginTime = this.orgSettingDatetimeRange[0];
        this.orgSetting.endTime = this.orgSettingDatetimeRange[1];
      }
      this.$refs.addOrgSettingForm.validate((valid) => {
        if (valid) {
          let url = EXAM_WORK_API + "/exam/examOrgSettings";
          this.$httpWithMsg.put(url, this.orgSetting).then((response) => {
            console.log(response);
            this.$notify({
              type: "success",
              message: "保存成功",
            });
            this.search();
            this.addOrgSettingDialog = false;
          });
        } else {
          return false;
        }
      });
    },
    back() {
      this.$router.push({ path: "/examwork/examInfo" });
    },
    getCanUpload(row) {
      if (row.properties.CAN_UPLOAD_ATTACHMENT === null) {
        if (Date.now() > row.endTime) {
          return "不允许";
        } else {
          return "允许";
        }
      } else {
        return row.properties.CAN_UPLOAD_ATTACHMENT == "true"
          ? "允许"
          : "不允许";
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.search();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.search();
    },
    showUpdateOrgSetting(row) {
      this.orgSetting.examId = row.examId;
      this.orgSetting.beginTime = row.beginTime;
      this.orgList4InsertOrUpdate = [{ id: row.orgId, name: row.orgName }];
      this.orgSetting.orgId = row.orgId;
      this.orgSetting.endTime = row.endTime;
      this.orgSetting.properties.CAN_UPLOAD_ATTACHMENT =
        row.properties.CAN_UPLOAD_ATTACHMENT;
      if (row.beginTime && row.endTime) {
        this.orgSettingDatetimeRange = [row.beginTime, row.endTime];
      } else {
        this.orgSettingDatetimeRange = [];
      }
      this.updateOrgSettingDialog = true;
    },
    showAddOrgSetting() {
      this.orgSetting.examId = this.examId;
      this.orgSetting.beginTime = null;
      this.orgList4InsertOrUpdate = [];
      this.orgSetting.orgId = null;
      this.orgSetting.endTime = null;
      this.orgSetting.properties.CAN_UPLOAD_ATTACHMENT = "true";
      this.orgSettingDatetimeRange = [];
      this.addOrgSettingDialog = true;
    },

    resetPageAndSearch() {
      this.currentPage = 1;
      this.search();
    },
    search() {
      let param = new URLSearchParams(this.formSearch);
      let url =
        EXAM_WORK_API +
        "/exam/getExamOrgSettingsList/" +
        (this.currentPage - 1) +
        "/" +
        this.pageSize +
        "?" +
        param;
      this.loading4FormSearch = true;
      this.$httpWithMsg
        .get(url)
        .then((response) => {
          console.log(response);
          this.tableData = response.data.list;
          this.total = response.data.total;
          this.loading4FormSearch = false;
        })
        .catch((response) => {
          console.log(response);
          this.loading4FormSearch = false;
        });
    },
    init() {
      this.search();
    },
  },
};
</script>
<style scoped></style>
