import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import "./registerServiceWorker";
import "./components/registerComponents";
import "./modules/basic/components/registerComponents";
import "./modules/oe/component/registerComponents";
import "./plugins/element.js";
import "./plugins/axios";
import "./plugins/vueAwesome";
import "./directives/directives.js";
import "./filters/filters.js";
import "./styles/bootstrap.scss";
import "./styles/global.css";

Vue.config.productionTip = process.env.NODE_ENV !== "production";

if (
  process.env.NODE_ENV === "development" &&
  process.env.VUE_APP_ENABLE_VUE_RENDER_LOGS === "true"
) {
  const vueLifecylceLogs = require("./plugins/vueLifecylceLogs").default;
  vueLifecylceLogs({});
}

Vue.prototype.$eventHub = new Vue();

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
