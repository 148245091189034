<template>
  <el-container>
    <el-main v-loading="loading" class="el-main-padding">
      <commonFormVue :form="form" :get-exam-condition="getExamCondition">
        <el-row v-show="showAllCondition">
          <el-col :span="6">
            <el-form-item label="完成状态">
              <el-select
                v-if="
                  form.examType == '' ||
                  form.examType == 'ONLINE' ||
                  form.examType == 'ONLINE_HOMEWORK'
                "
                v-model="form.finished"
                class="form_search_width"
                size="small"
                clearable
                placeholder="全部"
              >
                <el-option value="1" label="已完成"></el-option>
                <el-option value="0" label="未完成"></el-option>
              </el-select>
              <el-select
                v-if="form.examType == 'OFFLINE'"
                v-model="form.finished"
                class="form_search_width"
                size="small"
                clearable
                placeholder="全部"
              >
                <el-option value="0" label="未抽题"></el-option>
                <el-option value="1" label="已抽题"></el-option>
                <el-option value="2" label="已上传"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="采集人">
              <el-input
                v-model="form.infoCollector"
                class="form_search_width"
                size="small"
                placeholder="采集人"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </commonFormVue>
      <el-col :span="24">
        <el-button
          size="small"
          type="primary"
          icon="el-icon-search"
          @click="search('clickSelectBtn')"
          >查询</el-button
        >
        <el-button
          v-if="!showAllCondition"
          size="small"
          type="primary"
          icon="el-icon-more"
          @click="showMoreCondition"
          >高级查询</el-button
        >
        <el-button
          v-if="showAllCondition"
          size="small"
          type="primary"
          @click="showSimpleCondition"
          >简单查询</el-button
        >
        <el-button
          size="small"
          icon="el-icon-refresh"
          class="margin-bottom-10"
          @click="resetForm"
          >重置</el-button
        >
      </el-col>
      <el-row>
        <el-col
          v-show="currentPagePrivileges.EXAM_PARTICULARS_EXPORT"
          :span="24"
        >
          <div class="block-seperator"></div>
          <span>操作：</span>
          <el-button
            type="primary"
            size="small"
            icon="el-icon-download"
            @click="exportData"
            >导出</el-button
          >
        </el-col>
      </el-row>
      <el-row class="margin-top-10">
        <el-col :span="24">
          <el-table
            ref="multipleTable"
            v-loading="tableLoading"
            element-loading-text="数据加载中"
            :data="tableData"
            border
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              label="姓名"
              prop="studentName"
              width="120"
            ></el-table-column>
            <el-table-column
              label="身份证号"
              prop="identityNumber"
              width="120"
            ></el-table-column>
            <el-table-column
              label="学号"
              prop="studentCode"
              width="120"
            ></el-table-column>
            <el-table-column
              label="已考次数"
              prop="usedNum"
              width="90"
            ></el-table-column>
            <el-table-column
              label="学习中心"
              prop="orgName"
              min-width="120"
            ></el-table-column>
            <el-table-column
              label="课程"
              prop="courseName"
              width="120"
            ></el-table-column>
            <el-table-column
              label="课程层次"
              prop="courseLevel"
              width="90"
            ></el-table-column>
            <el-table-column label="场次" width="120">
              <template slot-scope="scope">
                <el-popover trigger="hover" placement="left">
                  <div style="font-size: 18px; font-family: 新宋体">
                    <tr>
                      <td style="color: green">场次序号</td>
                      <td style="color: purple; padding-left: 20px">
                        {{ scope.row.examStageOrder }}
                      </td>
                    </tr>
                    <tr>
                      <td style="color: green">开始时间</td>
                      <td style="color: purple; padding-left: 20px">
                        {{ scope.row.startTime }}
                      </td>
                    </tr>
                    <tr>
                      <td style="color: green">结束时间</td>
                      <td style="color: purple; padding-left: 20px">
                        {{ scope.row.endTime }}
                      </td>
                    </tr>
                  </div>

                  <div slot="reference" class="name-wrapper">
                    <span>{{ scope.row.examStageOrder }}</span>
                  </div>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column
              label="专业"
              prop="specialtyName"
              min-width="90"
            ></el-table-column>

            <el-table-column
              label="年级"
              prop="grade"
              width="90"
            ></el-table-column>
            <el-table-column
              label="学生电话"
              prop="phone"
              width="90"
            ></el-table-column>
            <el-table-column
              label="采集人"
              prop="infoCollector"
              width="90"
            ></el-table-column>
            <el-table-column
              fixed="right"
              label="完成状态"
              prop="finishedStatus"
              width="90"
            ></el-table-column>
            <el-table-column fixed="right" label="操作" width="120">
              <template slot-scope="scope">
                <el-row class="operateRow">
                  <el-col :span="24">
                    <el-button
                      v-if="scope.row.examType == 'OFFLINE'"
                      plain
                      type="primary"
                      size="mini"
                      icon="el-icon-view"
                      @click="previewPaper(scope.row.examStudentId)"
                      >查看考题</el-button
                    >
                  </el-col>
                </el-row>
                <el-row class="operateRow">
                  <el-col :span="24">
                    <el-button
                      v-if="scope.row.examType == 'OFFLINE'"
                      plain
                      type="primary"
                      size="mini"
                      icon="el-icon-download"
                      @click="exportPaper(scope.row.examStudentId)"
                      >下载考题</el-button
                    >
                  </el-col>
                </el-row>
                <el-row class="operateRow">
                  <el-col :span="24">
                    <el-button
                      v-if="
                        scope.row.examType == 'OFFLINE' &&
                        scope.row.canUploadAttachment
                      "
                      plain
                      type="primary"
                      size="mini"
                      icon="el-icon-upload2"
                      @click="openUploadAnswerDialog(scope.row.examStudentId)"
                      >上传作答</el-button
                    >
                  </el-col>
                </el-row>
                <el-row class="operateRow">
                  <el-col :span="24">
                    <el-button
                      v-if="
                        scope.row.examType == 'OFFLINE' &&
                        scope.row.offlineFiles
                      "
                      plain
                      type="primary"
                      size="mini"
                      icon="el-icon-download"
                      @click="downloadAnswer(scope.row.offlineFiles)"
                      >下载作答</el-button
                    >
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
          </el-table>
          <div class="page pull-right">
            <el-pagination
              :current-page.sync="form.pageNo"
              :page-sizes="[10, 20, 50, 100, 200, 300]"
              :page-size="form.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            ></el-pagination>
          </div>
        </el-col>
      </el-row>
      <div>
        <el-dialog
          v-loading="uploadAnswerDialogLoading"
          title="上传作答"
          :visible.sync="uploadAnswerDialogVisible"
        >
          <el-form>
            <el-form-item label="选择文件类型">
              <el-radio-group v-model="fileType" @change="handleFileTypeChange">
                <el-radio v-show="optZipVisible" label="zip">ZIP</el-radio>
                <el-radio v-show="optPdfVisible" label="pdf">PDF</el-radio>
                <el-radio v-show="optImageVisible" label="image"
                  >图片(jpg、jpeg、png)</el-radio
                >
              </el-radio-group>
            </el-form-item>
            <el-form-item label="选择文件">
              <div
                v-show="fileType == 'image'"
                style="width: 580px; padding-left: 80px"
              >
                <el-upload
                  ref="upload"
                  :class="{ disabled: uploadDisabled }"
                  action
                  :limit="fileLimit"
                  :http-request="customUpload"
                  :before-upload="beforeFileUpload"
                  :on-success="handleSuccess"
                  :file-list="fileList"
                  :auto-upload="false"
                  :accept="accept"
                  :on-change="handleChange"
                  multiple
                  list-type="picture-card"
                >
                  <i slot="default" class="el-icon-plus"></i>
                  <div slot="file" slot-scope="{ file }">
                    <img
                      class="el-upload-list__item-thumbnail"
                      :src="file.url"
                      alt
                    />
                    <span class="el-upload-list__item-actions">
                      <span
                        class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview(file)"
                      >
                        <i class="el-icon-zoom-in"></i>
                      </span>
                      <span
                        v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleRemove(file)"
                      >
                        <i class="el-icon-delete"></i>
                      </span>
                    </span>
                  </div>
                </el-upload>

                <div style="width: 580px">
                  温馨提示:仅支持JPG,JPEG和PNG文件,单个图片大小请不要超过5M,图片数量最多6张!
                </div>
              </div>
              <div v-show="fileType != 'image'">
                <input
                  id="importFile"
                  ref="offlineFileInput"
                  type="file"
                  :accept="accept"
                  :class="{ offline_file: offlineAnswerFile }"
                  @change="uploadAnswerChange"
                />
                <div>温馨提示:仅支持pdf和zip文件,文件大小请不要超过30M!</div>
              </div>
            </el-form-item>
            <div class="dialog-footer">
              <el-button @click="cancelUpload">取 消</el-button>
              <el-button
                v-show="fileType != 'image'"
                :disabled="!offlineAnswerFile"
                type="primary"
                @click="doUploadAnswer"
                >确 定</el-button
              >
              <el-button
                v-show="fileType == 'image'"
                :disabled="batchSubmitUploadDisabled"
                type="primary"
                @click="batchSubmitUpload"
                >确 定</el-button
              >
            </div>
          </el-form>
        </el-dialog>
        <el-dialog :append-to-body="true" :visible.sync="dialogVisible">
          <img width="100%" :src="dialogImageUrl" alt />
        </el-dialog>
        <el-dialog title="图片作答" :visible.sync="downloadImageDialogVisible">
          <el-form>
            <el-form-item label="作答结果">
              <div style="width: 580px; padding-left: 80px">
                <el-upload
                  :class="{ disabled: true }"
                  action
                  :limit="6"
                  :file-list="imageAnswerFileList"
                  multiple
                  list-type="picture-card"
                  :disabled="true"
                >
                  <i slot="default" class="el-icon-plus"></i>
                  <div slot="file" slot-scope="{ file }">
                    <img
                      class="el-upload-list__item-thumbnail"
                      :src="file.url"
                      :alt="file.name"
                    />
                    <span class="el-upload-list__item-actions">
                      <span
                        class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview(file)"
                      >
                        <i class="el-icon-zoom-in"></i>
                      </span>
                      <span
                        class="el-upload-list__item-delete"
                        @click="handleDownload(file)"
                      >
                        <i class="el-icon-download"></i>
                      </span>
                    </span>
                  </div>
                </el-upload>
              </div>
            </el-form-item>
            <div class="dialog-footer">
              <el-button
                type="primary"
                @click="downloadImageDialogVisible = false"
                >确 定</el-button
              >
            </div>
          </el-form>
        </el-dialog>
      </div>
    </el-main>
  </el-container>
</template>
<script>
import { mapState } from "vuex";
import commonFormVue from "../component/commonForm.vue";
import pagePrivilege from "../mixin/pagePrivilege.js";
// import MD5 from "js-md5";
import SparkMD5 from "spark-md5";
export default {
  components: { commonFormVue },
  mixins: [pagePrivilege],
  data() {
    return {
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      loading: false,
      uploadAnswerDialogLoading: false,
      uploadAnswerDialogVisible: false,
      total: 0,
      tableLoading: false,
      showAllCondition: false,
      fileType: "zip",
      accept: "application/zip",
      fileList: [],
      downloadImageDialogVisible: false,
      imageAnswerFileList: [],
      fileLimit: 6,
      form: {
        examRecordDataId: null,
        hasStranger: null,
        courseId: null,
        courseLevel: null,
        examId: null,
        faceSuccessPercentLower: null,
        faceSuccessPercentUpper: null,
        livenessSuccessPercentLower: null,
        livenessSuccessPercentUpper: null,
        identityNumber: null,
        orgId: null,
        studentCode: null,
        studentName: null,
        isWarn: null,
        pageNo: 1,
        pageSize: 10,
        examType: "",
        ORG_FIND_ALL: false, //查询所有机构
        examStageId: null,
      },
      getExamCondition: {
        params: {
          name: "",
          examTypes: "ONLINE#OFFLINE#ONLINE_HOMEWORK",
        },
        filterCondition: "",
      },
      tableData: [],
      exportUrl:
        "/api/ecs_oe_admin/exam/student/examScheduling/list/export/async",
      exportFileName: "考试进度详情",
      currentOfflineExamRecordDataId: "",
      offlineAnswerFile: "",
      currentPagePrivileges: {
        EXAM_PARTICULARS_EXPORT: false, //导出
      },
      summary: "",
      summaryList: [],
      md5Size: 0,
      optZipVisible: false,
      optPdfVisible: false,
      optImageVisible: false,
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
    batchSubmitUploadDisabled() {
      return this.fileList.length == 0 || this.fileList.length != this.md5Size;
    },
    //计算是否显示图片上传框
    uploadDisabled() {
      return this.fileList.length >= this.fileLimit;
    },
  },
  created() {
    if (this.form.examId) {
      this.getUploadFileType();
    }
  },
  methods: {
    exportData() {
      if (!this.form.examId) {
        this.$notify({
          title: "警告",
          message: "请选择考试",
          type: "warning",
          duration: 1000,
        });
        return false;
      }

      this.$confirm("确定执行导出?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http
          .get(this.exportUrl, {
            params: {
              query: this.form,
            },
          })
          .then(() => {
            this.$notify({
              type: "success",
              message: "正在后台导出中，请稍后到“导出任务列表”中下载！",
            });
          })
          .catch((error) => {
            this.$notify({
              type: "error",
              message: error.response.data.desc,
            });
          });
      });
    },
    cancelUpload() {
      this.uploadAnswerDialogVisible = false;
      this.removeImgs();
    },
    handleChange(file, fileList) {
      this.fileList = fileList;
      this.calcSummary(this.fileList);
    },
    getFileMD5(dataFile, callback) {
      var fileReader = new FileReader();
      var spark = new SparkMD5(); //创建md5对象（基于SparkMD5）
      if (dataFile.size > 1024 * 1024 * 10) {
        var data1 = dataFile.slice(0, 1024 * 1024 * 10); //将文件进行分块 file.slice(start,length)
        fileReader.readAsBinaryString(data1); //将文件读取为二进制码
      } else {
        fileReader.readAsBinaryString(dataFile);
      }

      //文件读取完毕之后的处理
      //a639e28526d1809745b46bf1189594fe  6d9efe0c593b1383482feb229318e03a
      fileReader.onload = function (e) {
        spark.appendBinary(e.target.result);
        var md5 = spark.end();
        console.log(md5);
        callback(md5);
      };
    },
    handleRemove(file) {
      let index = this.fileList.findIndex((p) => p.uid == file.uid);
      this.fileList.splice(index, 1);
      this.calcSummary(this.fileList);
    },

    calcSummary(fileList) {
      var summaryList = [];
      var md5Size = 0;

      for (let i = 0; i < fileList.length; i++) {
        let f = fileList[i];
        this.getFileMD5(f.raw, (md5) => {
          summaryList.push({ index: i, summary: md5 });
          md5Size++;
          this.summaryList = summaryList;
          this.md5Size = md5Size;
        });
      }
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      window.open(file.url);
      console.log(file);
    },
    removeImgs() {
      this.fileList = [];
      this.$refs.upload.clearFiles();
    },
    resetForm() {
      this.form = {
        examRecordDataId: null,
        hasStranger: null,
        courseId: null,
        courseLevel: null,
        examId: null,
        faceSuccessPercentLower: null,
        faceSuccessPercentUpper: null,
        livenessSuccessPercentLower: null,
        livenessSuccessPercentUpper: null,
        identityNumber: null,
        orgId: this.form.ORG_FIND_ALL ? null : this.form.orgId,
        studentCode: null,
        studentName: null,
        isWarn: null,
        pageNo: 1,
        pageSize: 10,
        examType: "",
        ORG_FIND_ALL: this.form.ORG_FIND_ALL,
        examStageId: null,
      };
    },
    showMoreCondition() {
      this.showAllCondition = true;
    },
    showSimpleCondition() {
      this.$notify({
        title: "提示",
        message: "高级查询条件值已重置",
        type: "info",
        duration: 2000,
      });
      this.resetForm();
      this.showAllCondition = false;
    },
    search(type) {
      if (!this.form.examId) {
        this.$notify({
          title: "警告",
          message: "请选择考试",
          type: "warning",
          duration: 2000,
        });
        return false;
      }
      if (type && type == "clickSelectBtn") {
        this.form.pageNo = 1;
      }
      this.tableLoading = true;
      this.$http
        .post("/api/ecs_oe_admin/exam/student/examScheduling/list", this.form)
        .then((response) => {
          if (response.data) {
            var dataList = response.data.content;
            this.tableData = dataList;
            this.total = response.data.totalElements;
          } else {
            this.tableData = [];
          }
          this.tableLoading = false;
        });
      this.getUploadFileType();
    },
    selectable(row) {
      return row.isWarn;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /**
     * pagesize改变时触发
     */
    handleSizeChange(val) {
      this.form.pageSize = val;
      this.search();
    },
    /**
     * 当前页改变时触发
     */
    handleCurrentChange() {
      this.search();
    },
    previewPaper(examStudentId) {
      this.$http
        .get("/api/ecs_oe_admin/exam/record/select/byExamStudentId", {
          params: { examStudentId: examStudentId },
        })
        .then((response) => {
          if (response.data) {
            var examRecordList = response.data;
            if (examRecordList && examRecordList.length > 0) {
              window.open(
                "/admin/preview_paper/" + examRecordList[0].basePaperId
              );
            } else {
              this.$notify({
                title: "提示",
                message: "该考生未参加考试",
                type: "error",
                duration: 2000,
              });
            }
          }
        });
    },
    exportPaper(examStudentId) {
      this.loading = true;
      this.$http
        .get("/api/ecs_oe_admin/exam/record/select/byExamStudentId", {
          params: { examStudentId: examStudentId },
        })
        .then((response) => {
          if (response.data) {
            var examRecordList = response.data;
            if (examRecordList && examRecordList.length > 0) {
              var basePaperId = examRecordList[0].basePaperId;
              this.$http
                .get(
                  "/api/ecs_ques/paper/export/" +
                    basePaperId +
                    "/PAPER/offLine",
                  {
                    responseType: "arraybuffer",
                    filename: "utf-8",
                  }
                )
                .then((response) => {
                  if (response.data && response.data.byteLength > 0) {
                    var blob = new Blob([response.data], {
                      type: "application/zip",
                    });
                    var url = URL.createObjectURL(blob);
                    var fileName = response.headers["content-disposition"]
                      .split(";")[1]
                      .replace("filename=", "");
                    var a = document.createElement("a");
                    a.href = url;
                    a.download = decodeURI(fileName);
                    a.target = "_blank";
                    a.click();
                    URL.revokeObjectURL(url);
                  } else {
                    this.$notify({
                      title: "提示",
                      message: "无相关文件",
                      type: "error",
                      duration: 2000,
                    });
                  }
                  this.loading = false;
                })
                .catch(() => {
                  this.loading = false;
                });
            } else {
              this.loading = false;
              this.$notify({
                title: "提示",
                message: "该考生未参加考试",
                type: "error",
                duration: 2000,
              });
            }
          }
        });
    },
    openUploadAnswerDialog(examStudentId) {
      this.fileList = [];
      this.imageAnswerFileList = [];
      // this.offlineAnswerFile = "";
      this.cleanOfflineFile();

      this.$http
        .get("/api/ecs_oe_admin/exam/record/data/findByExamStudentId", {
          params: { examStudentId: examStudentId },
        })
        .then((response) => {
          var examRecordDataList = response.data;
          if (examRecordDataList.length == 0) {
            this.$notify({
              title: "提示",
              message: "该考生未参加考试",
              type: "error",
              duration: 2000,
            });
          } else {
            this.uploadAnswerDialogVisible = true;
            this.currentOfflineExamStudentId = examStudentId;
            this.currentOfflineExamRecordDataId = examRecordDataList[0].id;
          }
        });
    },
    uploadAnswerChange(event) {
      if (event.target.files.length > 0) {
        this.offlineAnswerFile = event.target.files[0];
      } else {
        this.offlineAnswerFile = "";
      }
    },
    doUploadAnswer() {
      var index = this.offlineAnswerFile.name.lastIndexOf(".");
      var fileNameLength = this.offlineAnswerFile.name.length;
      var fileSuffix = this.offlineAnswerFile.name
        .substring(index + 1, fileNameLength)
        .toUpperCase();
      this.$http
        .get(
          "/api/ecs_exam_work/exam/property/" +
            this.form.examId +
            "/OFFLINE_UPLOAD_FILE_TYPE"
        )
        .then((response) => {
          var allowfileSuffixs = response.data;
          if (!allowfileSuffixs || allowfileSuffixs.length == 0) {
            this.$notify({
              title: "提示",
              message: "当前考试设置不允许上传附件",
              type: "error",
              duration: 2000,
            });
            return false;
          }
          if (allowfileSuffixs.toString().indexOf(fileSuffix) < 0) {
            this.$notify({
              title: "提示",
              message:
                "当前考试允许上传文件格式为:" + allowfileSuffixs.toString(),
              type: "error",
              duration: 2000,
            });
            return false;
          }
          this.uploadAnswerDialogLoading = true;
          let config = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          let param = new FormData();

          param.append("file", this.offlineAnswerFile);
          param.append("examRecordDataId", this.currentOfflineExamRecordDataId);
          this.$http
            .post("/api/ecs_oe_admin/offlineExam/submitPaper", param, config)
            .then(() => {
              this.$notify({
                title: "提示",
                message: "上传成功",
                type: "success",
                duration: 2000,
              });
              this.uploadAnswerDialogVisible = false;
              this.uploadAnswerDialogLoading = false;
              this.$refs.offlineFileInput.value = "";
              this.offlineAnswerFile = "";
              this.search();
            })
            .catch((e) => {
              let errMsg = "上传失败";
              if (e.response) {
                errMsg = e.response.data.desc;
              }
              this.$notify({
                title: "提示",
                message: errMsg,
                type: "error",
                duration: 2000,
              });
              this.uploadAnswerDialogLoading = false;
              this.$refs.offlineFileInput.value = "";
              this.offlineAnswerFile = "";
            });
        });
    },
    //获取支持的文件上传类型
    getUploadFileType() {
      this.$http
        .get(
          "/api/ecs_exam_work/exam/property/" +
            this.form.examId +
            "/OFFLINE_UPLOAD_FILE_TYPE"
        )
        .then((response) => {
          var allowfileSuffixs = response.data;
          if (!allowfileSuffixs || allowfileSuffixs.length == 0) {
            return false;
          }

          if (allowfileSuffixs.some((p) => p.toUpperCase() == "ZIP")) {
            this.optZipVisible = true;
          }

          if (allowfileSuffixs.some((p) => p.toUpperCase() == "PDF")) {
            this.optPdfVisible = true;
          }

          if (allowfileSuffixs.some((p) => p.toUpperCase() == "IMAGE")) {
            this.optImageVisible = true;
          }
        });
    },
    beforeUpload(file) {
      var index = file.name.lastIndexOf(".");
      var fileNameLength = file.name.length;
      var fileSuffix = file.name
        .substring(index + 1, fileNameLength)
        .toUpperCase();
      this.$http
        .get(
          "/api/ecs_exam_work/exam/property/" +
            this.form.examId +
            "/OFFLINE_UPLOAD_FILE_TYPE"
        )
        .then((response) => {
          var allowfileSuffixs = response.data;
          if (!allowfileSuffixs || allowfileSuffixs.length == 0) {
            this.$notify({
              title: "提示",
              message: "当前考试设置不允许上传附件",
              type: "error",
              duration: 2000,
            });
            return false;
          }
          if (allowfileSuffixs.toString().indexOf(fileSuffix) < 0) {
            this.$notify({
              title: "提示",
              message:
                "当前考试允许上传文件格式为:" + allowfileSuffixs.toString(),
              type: "error",
              duration: 2000,
            });
            return false;
          }
          this.offlineAnswerFile = file;
        });

      this.uploadData = {
        examRecordDataId: this.currentOfflineExamRecordDataId,
        summary: this.summary,
      };
      console.log(this.uploadData);
      let promise = new Promise((resolve) => {
        this.$nextTick(function () {
          resolve(true);
        });
      });
      return promise; //通过返回一个promis对象解决
    },
    submitUpload() {
      this.uploadAnswerDialogLoading = true;
      let config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      let param = new FormData();
      param.append("file", this.offlineAnswerFile);
      param.append("examRecordDataId", this.currentOfflineExamRecordDataId);
      param.append("fileType", this.fileType);

      this.$http
        .post("/api/ecs_oe_admin/offlineExam/submitPaper", param, config)
        .then(() => {
          this.$notify({
            title: "提示",
            message: "上传成功",
            type: "success",
            duration: 2000,
          });
          this.uploadAnswerDialogVisible = false;
          this.$refs.offlineFileInput.value = "";
          this.offlineAnswerFile = "";
          this.search();
        })
        .catch((e) => {
          let errMsg = "上传失败";
          if (e.response) {
            errMsg = e.response.data.desc;
          }
          this.$notify({
            title: "提示",
            message: errMsg,
            type: "error",
            duration: 2000,
          });
          this.uploadAnswerDialogLoading = false;
          this.$refs.offlineFileInput.value = "";
          this.offlineAnswerFile = "";
        });
    },
    cleanOfflineFile() {
      if (this.$refs.offlineFileInput) {
        this.$refs.offlineFileInput.value = "";
      }

      this.offlineAnswerFile = "";
    },
    downloadAnswer(files) {
      if (files && files.length > 0) {
        if (files[0].fileType != "image") {
          window.open(files[0].offlineFileUrl);
          return;
        }

        this.imageAnswerFileList = [];
        for (let f of files) {
          this.imageAnswerFileList.push({
            url: f.offlineFileUrl,
            name: f.offlineFileName,
          });
        }
        this.downloadImageDialogVisible = true;
      }
    },
    handleFileTypeChange(ft) {
      switch (ft) {
        case "zip":
          this.accept = "application/zip";
          break;
        case "pdf":
          this.accept = "application/pdf";
          break;
        case "image":
          this.accept = "image/jpeg,image/png";
          break;
      }
    },
    batchSubmitUpload() {
      this.$refs.upload.submit();

      let params = new FormData();
      params.append("examRecordDataId", this.currentOfflineExamRecordDataId);
      params.append("fileType", this.fileType);
      for (let f of this.fileList) {
        params.append("fileArray", f.raw);
      }

      //先对文件md5进行排序(按索引正序排列)
      this.summaryList.sort((a, b) => a.index - b.index);
      let summaries = [];
      for (let s of this.summaryList) {
        summaries.push(s.summary);
      }
      params.append("fileMd5Array", summaries);

      let config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      this.$http
        .post("/api/ecs_oe_admin/offlineExam/batchSubmitPaper", params, config)
        .then(() => {
          this.$notify({
            title: "提示",
            message: "上传成功",
            type: "success",
            duration: 2000,
          });

          this.uploadAnswerDialogVisible = false;
          this.removeImgs();
          this.offlineAnswerFile = "";
          this.search();
        })
        .catch((e) => {
          let errMsg = "上传失败";
          if (e.response) {
            errMsg = e.response.data.desc;
          }
          this.$notify({
            title: "提示",
            message: errMsg,
            type: "error",
            duration: 2000,
          });
          this.uploadAnswerDialogLoading = false;
          this.offlineAnswerFile = "";
        });
    },
    beforeFileUpload(file) {
      const isLt5M = file.size / 1024 / 1024 <= 5;
      if (!isLt5M) {
        this.$message.error("上传的单个数据文件大小不能超过5MB!");
        let index = this.fileList.findIndex((p) => p.uid == file.uid);
        this.fileList.splice(index, 1);
        this.calcSummary(this.fileList);
      }
      return isLt5M;
    },
    handleSuccess(response, file, fileList) {
      console.log(response);
      console.log(file);
      console.log(fileList);
      this.$message.success("上传成功");
    },
    customUpload(file) {
      console.log(file);
      return false;
    },
  },
};
</script>
<style scoped>
.offline_file {
  color: blue;
}
.disabled .el-upload--picture-card {
  display: none !important;
}
</style>
<style scoped src="../style/common.css"></style>
