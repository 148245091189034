<template>
  <section class="content" style="margin-top: 20px">
    <div class="box box-info">
      <!-- 正文信息 -->
      <div class="box-body">
        <el-form
          :model="formSearch"
          :inline="true"
          label-position="right"
          class="el-form el-form--inline"
          style="
            border-bottom: 1px solid rgb(221, 221, 221);
            padding-bottom: 10px;
            margin-bottom: 10px;
          "
        >
          <el-form-item label="学校">
            <el-select
              v-model="formSearch.orgId"
              placeholder="请选择"
              @change="loadExamList(formSearch.orgId)"
            >
              <el-option
                v-for="item in orgList"
                :key="item.orgId"
                :label="item.orgName"
                :value="item.orgId"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="考试">
            <el-select
              v-model="formSearch.examId"
              placeholder="请选择"
              clearable
              @change="searchRecords(1)"
            >
              <el-option
                v-for="item in examList"
                :key="item.examId"
                :label="item.examName"
                :value="item.examId"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button
              size="small"
              type="primary"
              icon="el-icon-search"
              @click="searchRecords(1)"
              >查询
            </el-button>

            <el-button
              v-show="!checkEmptyNumber(formSearch.orgId)"
              size="small"
              type="primary"
              icon="el-icon-plus"
              :disabled="!hasPermit"
              @click="openAddStructureDialog"
              >新增
            </el-button>
          </el-form-item>
        </el-form>

        <!-- 数据列表 -->
        <el-table
          v-loading="loading"
          :data="tableData"
          element-loading-text="数据加载中"
          style="width: 100%"
          border
          stripe
        >
          <el-table-column label="学校名称" prop="orgName" />
          <el-table-column label="考试名称" prop="examName" />
          <el-table-column width="220" label="题数">
            <template slot-scope="scope">
              单选：{{ scope.row.questionStructure.singleChoiceTotal }}<br />
              多选：{{ scope.row.questionStructure.multipleChoiceTotal }}<br />
              判断：{{ scope.row.questionStructure.boolQuestionTotal }}
            </template>
          </el-table-column>

          <el-table-column width="200px" label="操作" :context="_self">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="primary"
                icon="el-icon-menu"
                :disabled="!hasPermit"
                plain
                @click="openCloneStructureDialog(scope.row)"
                >复用
              </el-button>

              <el-button
                size="mini"
                type="danger"
                icon="el-icon-delete"
                :disabled="!hasPermit"
                @click="removeStructure(scope.row)"
                >删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>

        <!-- 分页 -->
        <div class="page pull-right">
          <el-pagination
            :current-page="formSearch.pageNo"
            :page-size="formSearch.pageSize"
            :page-sizes="[10, 20, 50, 100, 200, 300]"
            :total="totalElements"
            layout="total, sizes, prev, pager, next, jumper"
            @current-change="handlePagerNo"
            @size-change="handlePagerSize"
          ></el-pagination>
        </div>
      </div>

      <!-- 新增考试结构弹窗 -->
      <el-dialog
        title="新增考试结构"
        width="480px"
        :visible.sync="addStructureDialog"
        @close="closeAddStructureDialog"
      >
        <el-form
          ref="addStructureForm"
          :model="addStructureForm"
          :rules="rules"
          label-position="right"
          label-width="100px"
          inline-message
        >
          <el-form-item label="学校名称" prop="orgId">
            <el-select
              v-model="addStructureForm.orgId"
              placeholder="请选择"
              :disabled="true"
              class="w220"
            >
              <el-option
                v-for="item in orgList"
                :key="item.orgId"
                :label="item.orgName"
                :value="item.orgId"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="考试名称" prop="examId">
            <el-select
              v-model="addStructureForm.examId"
              placeholder="请选择"
              class="w220"
            >
              <el-option
                v-for="item in examList"
                :key="item.examId"
                :label="item.examName"
                :value="item.examId"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="单选题数量" prop="singleChoiceTotal">
            <el-input
              v-model="addStructureForm.questionStructure.singleChoiceTotal"
              class="w220"
            ></el-input>
          </el-form-item>

          <el-form-item label="多选题数量" prop="multipleChoiceTotal">
            <el-input
              v-model="addStructureForm.questionStructure.multipleChoiceTotal"
              class="w220"
            ></el-input>
          </el-form-item>

          <el-form-item label="判断题数量" prop="boolQuestionTotal">
            <el-input
              v-model="addStructureForm.questionStructure.boolQuestionTotal"
              class="w220"
            ></el-input>
          </el-form-item>

          <div style="text-align: center">
            <el-button type="primary" @click="addStructure">确 定</el-button>
            <el-button @click="closeAddStructureDialog">取 消</el-button>
          </div>
        </el-form>
      </el-dialog>

      <!-- 复用考试结构弹窗 -->
      <el-dialog
        title="复用考试结构"
        width="440px"
        :visible.sync="cloneStructureDialog"
        @close="closeCloneStructureDialog"
      >
        <el-form
          ref="cloneStructureForm"
          :model="cloneStructureForm"
          :rules="rules"
          label-position="right"
          label-width="80px"
          inline-message
        >
          <el-tabs v-model="sourceTab">
            <el-tab-pane label="原结构信息" name="first">
              <el-form-item label="学校名称" prop="orgId">
                <el-select
                  v-model="cloneStructureForm.orgId"
                  placeholder="请选择"
                  :disabled="true"
                  class="w220"
                >
                  <el-option
                    v-for="item in orgList"
                    :key="item.orgId"
                    :label="item.orgName"
                    :value="item.orgId"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="考试名称" prop="examId">
                <el-select
                  v-model="cloneStructureForm.examId"
                  placeholder="请选择"
                  :disabled="true"
                  class="w220"
                >
                  <el-option
                    v-for="item in examList"
                    :key="item.examId"
                    :label="item.examName"
                    :value="item.examId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-tab-pane>
          </el-tabs>

          <el-tabs v-model="targetTab">
            <el-tab-pane label="新结构信息" name="first">
              <el-form-item label="学校名称" prop="newOrgId">
                <el-select
                  v-model="cloneStructureForm.newOrgId"
                  placeholder="请选择"
                  class="w220"
                  @change="loadCloneExamList(cloneStructureForm.newOrgId)"
                >
                  <el-option
                    v-for="item in orgList"
                    :key="item.orgId"
                    :label="item.orgName"
                    :value="item.orgId"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="考试名称" prop="newExamId">
                <el-select
                  v-model="cloneStructureForm.newExamId"
                  placeholder="请选择"
                  class="w220"
                >
                  <el-option
                    v-for="item in cloneExamList"
                    :key="item.examId"
                    :label="item.examName"
                    :value="item.examId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-tab-pane>
          </el-tabs>

          <div style="text-align: center">
            <el-button type="primary" @click="cloneStructure">确 定</el-button>
            <el-button @click="closeCloneStructureDialog">取 消</el-button>
          </div>
        </el-form>
      </el-dialog>
    </div>
  </section>
</template>

<script>
import { PRINT_API } from "@/constants/constants";
import {} from "../constants/constants.js";
import { mapState } from "vuex";
import { checkEmptyNumber } from "../utils/common.js";

export default {
  data() {
    let validateSingleChoiceTotal = (rule, value, callback) => {
      if (
        checkEmptyNumber(
          this.addStructureForm.questionStructure.singleChoiceTotal
        )
      ) {
        callback(new Error("请输入正确的数值！"));
        return;
      }
      if (this.addStructureForm.questionStructure.singleChoiceTotal > 1000) {
        callback(new Error("请输入合理的数值！"));
        return;
      }
      callback();
    };

    let validateMultipleChoiceTotal = (rule, value, callback) => {
      if (
        checkEmptyNumber(
          this.addStructureForm.questionStructure.multipleChoiceTotal
        )
      ) {
        callback(new Error("请输入正确的数值！"));
        return;
      }
      if (this.addStructureForm.questionStructure.multipleChoiceTotal > 1000) {
        callback(new Error("请输入合理的数值！"));
        return;
      }
      callback();
    };

    let validateBoolChoiceTotal = (rule, value, callback) => {
      if (
        checkEmptyNumber(
          this.addStructureForm.questionStructure.boolQuestionTotal
        )
      ) {
        callback(new Error("请输入正确的数值！"));
        return;
      }
      if (this.addStructureForm.questionStructure.boolQuestionTotal > 1000) {
        callback(new Error("请输入合理的数值！"));
        return;
      }
      callback();
    };

    return {
      formSearch: {
        orgId: "",
        examId: "",
        pageNo: 1,
        pageSize: 10,
      },
      curUserRole: {},
      hasPermit: false,
      totalElements: 0,
      loading: false,
      tableData: [],
      orgList: [],
      examList: [],
      addStructureDialog: false,
      addStructureForm: {
        examId: "",
        examName: "",
        orgId: "",
        orgName: "",
        questionStructure: {
          singleChoiceTotal: 0,
          multipleChoiceTotal: 0,
          boolQuestionTotal: 0,
        },
      },
      cloneStructureDialog: false,
      cloneExamList: [],
      sourceTab: "first",
      targetTab: "first",
      cloneStructureForm: {
        examId: "",
        orgId: "",
        newExamId: "",
        newExamName: "",
        newOrgId: "",
        newOrgName: "",
      },
      rules: {
        orgId: [
          { required: true, message: "学校不能为空！", trigger: "change" },
        ],
        examId: [
          { required: true, message: "考试不能为空！", trigger: "change" },
        ],
        newOrgId: [
          { required: true, message: "学校不能为空！", trigger: "change" },
        ],
        newExamId: [
          { required: true, message: "考试不能为空！", trigger: "change" },
        ],
        singleChoiceTotal: [
          {
            required: true,
            validator: validateSingleChoiceTotal,
            trigger: "change",
          },
        ],
        multipleChoiceTotal: [
          {
            required: true,
            validator: validateMultipleChoiceTotal,
            trigger: "change",
          },
        ],
        boolQuestionTotal: [
          {
            required: true,
            validator: validateBoolChoiceTotal,
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
  },
  created() {
    this.loadOrgList();

    this.loadUserRole(this.user);

    if (this.curUserRole.isSuperLeader || this.curUserRole.isPM) {
      this.hasPermit = true;
    } else {
      this.hasPermit = false;
    }
  },
  methods: {
    handlePagerNo(pageNo) {
      /* 处理分页 */
      this.searchRecords(pageNo);
    },
    handlePagerSize(pageSize) {
      /* 处理每页条数 */
      this.formSearch.pageSize = pageSize;
      this.searchRecords(1);
    },
    searchRecords(pageNo) {
      this.formSearch.pageNo = pageNo;

      /* 查询记录列表 */
      let orgId = this.formSearch.orgId;
      if (checkEmptyNumber(orgId)) {
        this.$notify({
          title: "提示",
          message: "请选择学校！",
          type: "warning",
        });
        return;
      }

      this.loading = true;
      let url = PRINT_API + "/examStructure/list";
      this.$http.post(url, this.formSearch).then(
        (response) => {
          this.tableData = response.data.content;
          this.totalElements = response.data.totalElements;
          this.loading = false;
        },
        (error) => {
          console.log(error);
          this.loading = false;
        }
      );
    },
    selectDefault() {
      if (this.orgList.length > 0) {
        let firstOrgId = this.orgList[0].orgId;
        this.formSearch.orgId = firstOrgId;
        this.loadExamList(firstOrgId);
      }
    },
    loadOrgList() {
      /* 查询学校列表 */
      let url = PRINT_API + "/printing/project/org/list";
      this.$http.post(url).then(
        (response) => {
          this.orgList = response.data;
          this.selectDefault();
        },
        (error) => {
          console.log(error.response);
          // ignore
        }
      );
    },
    loadExamList(orgId) {
      /* 查询考试列表 */
      this.formSearch.examId = "";
      this.examList = [];
      this.tableData = [];

      if (!checkEmptyNumber(orgId)) {
        let url = PRINT_API + "/printing/project/exam/list?orgId=" + orgId;
        this.$http.post(url).then((response) => {
          this.examList = response.data;
          if (this.examList.length > 0) {
            this.formSearch.examId = this.examList[0].examId;
            this.searchRecords(1);
          }
        });
      }
    },
    loadCloneExamList(orgId) {
      /* 查询考试列表 */
      this.cloneStructureForm.newExamId = "";
      this.cloneExamList = [];

      if (!checkEmptyNumber(orgId)) {
        let url = PRINT_API + "/printing/project/exam/list?orgId=" + orgId;
        this.$http.post(url).then((response) => {
          this.cloneExamList = response.data;
        });
      }
    },
    getOrgNameById(orgList, orgId) {
      for (let i = 0; i < orgList.length; i++) {
        if (orgList[i].orgId == orgId) {
          return orgList[i].orgName;
        }
      }
      return "";
    },
    getExamNameById(examList, examId) {
      for (let i = 0; i < examList.length; i++) {
        if (examList[i].examId == examId) {
          return examList[i].examName;
        }
      }
      return "";
    },
    openAddStructureDialog() {
      /* 打开考试结构弹窗 */
      this.addStructureDialog = true;
      this.addStructureForm.orgId = this.formSearch.orgId;
      this.addStructureForm.examId = this.formSearch.examId;
      this.addStructureForm.questionStructure.singleChoiceTotal = "";
      this.addStructureForm.questionStructure.multipleChoiceTotal = "";
      this.addStructureForm.questionStructure.boolQuestionTotal = "";
    },
    closeAddStructureDialog() {
      /* 关闭考试结构弹窗 */
      this.addStructureDialog = false;
    },
    addStructure() {
      /* 新增考试结构 */
      this.$refs.addStructureForm.validate((valid) => {
        if (!valid) {
          return false;
        }

        this.addStructureForm.orgName = this.getOrgNameById(
          this.orgList,
          this.addStructureForm.orgId
        );
        this.addStructureForm.examName = this.getExamNameById(
          this.examList,
          this.addStructureForm.examId
        );

        let curLoading = this.$loading({
          lock: true,
          text: "数据保存中！",
        });

        let url = PRINT_API + "/examStructure/save";
        this.$http.post(url, this.addStructureForm).then(
          () => {
            curLoading.close();
            this.$notify({
              title: "提示",
              message: "考试结构新增成功！",
              type: "success",
            });
            this.addStructureDialog = false;
            this.searchRecords(1);
          },
          (error) => {
            console.log(error.response);
            curLoading.close();
            this.$notify({
              title: "错误",
              type: "error",
              message: error.response.data.desc,
            });
          }
        );
      });
    },
    openCloneStructureDialog(row) {
      /* 打开复用考试结构弹窗 */
      this.cloneStructureDialog = true;
      this.cloneStructureForm.orgId = row.orgId;
      this.cloneStructureForm.examId = row.examId;
      this.cloneStructureForm.newOrgId = "";
      this.cloneStructureForm.newExamId = "";
    },
    closeCloneStructureDialog() {
      /* 关闭复用考试结构弹窗 */
      this.cloneStructureDialog = false;
    },
    cloneStructure() {
      /* 复用考试结构 */
      this.$refs.cloneStructureForm.validate((valid) => {
        if (!valid) {
          return false;
        }
        this.cloneStructureForm.newOrgName = this.getOrgNameById(
          this.orgList,
          this.cloneStructureForm.newOrgId
        );
        this.cloneStructureForm.newExamName = this.getExamNameById(
          this.cloneExamList,
          this.cloneStructureForm.newExamId
        );

        let curLoading = this.$loading({
          lock: true,
          text: "数据保存中！",
        });

        let url = PRINT_API + "/examStructure/clone";
        this.$http.post(url, this.cloneStructureForm).then(
          () => {
            curLoading.close();
            this.$notify({
              title: "提示",
              message: "考试结构复用成功！",
              type: "success",
            });
            this.cloneStructureDialog = false;
            this.searchRecords(1);
          },
          (error) => {
            console.log(error.response);
            curLoading.close();
            this.$notify({
              title: "错误",
              type: "error",
              message: error.response.data.desc,
            });
          }
        );
      });
    },
    removeStructure(row) {
      /* 删除考试结构 */
      this.$confirm("确定删除当前结构吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let url = PRINT_API + "/examStructure/delete/" + row.id;
          this.$http.post(url).then(
            () => {
              this.$notify({
                title: "提示",
                type: "success",
                message: "删除当前结构成功！",
              });
              this.searchRecords(1);
            },
            (error) => {
              console.log(error.response);
              this.$notify({
                title: "错误",
                type: "error",
                message: error.response.data.desc,
              });
            }
          );
        })
        .catch(() => {
          /*ignore*/
        });
    },
    checkEmptyNumber: checkEmptyNumber,
  },
};
</script>

<style scoped>
.page {
  margin-top: 10px;
}
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
.w220 {
  width: 220px;
}
</style>
