import rasterizeHTML from "rasterizehtml";

export function Drawing(canvas, option) {
  typeof canvas == "string" && (canvas = document.getElementById(canvas));
  if (!canvas || !canvas.getContext) {
    throw new Error(100, "do not support canvas!");
  }
  this.init(canvas, option);
}
Drawing.prototype = {
  init: function (canvas, option) {
    typeof option == "object" ||
      (option = { score: 0, data: "", width: 500, height: 500, loc: [] });
    this.itemId = "";
    this.paperKey = "";
    this.scoreLoc = [];
    this.option = option;
    this.canvas = canvas;
    this.context = this.canvas.getContext("2d");
    this.context.fillStyle = "red";
    this.clearDraw();
    this.bindEvent();
    this.drawHtml(option.data);
  },
  setItemId: function (itemId) {
    this.itemId = itemId;
  },
  setPaperKey: function (paperKey) {
    this.paperKey = paperKey;
  },
  setImg: function (imgSrc) {
    var img = new Image();
    img.src = imgSrc;
    this.img = img;
    this.drawImg();
  },
  setHtml: function (html) {
    this.clearDraw();
    this.option.data = html;
    this.drawHtml(this.option.data);
  },
  setLocation: function (scoreLocs, type) {
    if (!scoreLocs) return;
    //初始化时从localStorage中获取
    if (type == "localStorage") {
      this.scoreLoc = Object.assign([], scoreLocs);
    }
    for (let scoreLoc of this.scoreLoc) {
      this.option.score = scoreLoc.score;
      this.drawScoreLoc(scoreLoc.loc);
    }
  },
  getLocation: function (x, y) {
    var bbox = this.canvas.getBoundingClientRect();
    return {
      x: (x - bbox.left) * (this.canvas.width / bbox.width),
      y: (y - bbox.top) * (this.canvas.height / bbox.height),
    };
  },
  drawHtml: function (data) {
    if (!data) return;
    var self = this;
    rasterizeHTML
      .drawHTML(data, this.canvas, {
        width: this.option.width,
        height: this.option.height,
      })
      .then(function (renderResult) {
        self.img = renderResult.image;
        self.context.drawImage(renderResult.image, 0, 0);
      });
  },
  drawImg: function () {
    this.context.drawImage(this.img, 0, 0);
  },
  bindEvent: function () {
    var self = this;
    this.canvas.addEventListener(
      "click",
      function (e) {
        var location = self.getLocation(e.clientX, e.clientY);
        self.onClick(location);
      },
      false
    );
  },
  removeEvent: function () {
    this.canvas.removeEventListener("click", function () {}, false);
  },
  ResetDrawAll: function () {
    this.clearDraw();
    this.drawImg();
  },
  clearDraw: function () {
    this.context.clearRect(0, 0, this.option.width, this.option.height);
  },
  clearScoreLoc: function () {
    this.scoreLoc.splice(0, this.scoreLoc.length);
  },
  drawScoreLoc(pos) {
    this.context.font = "48px serif";
    this.context.fillText(this.option.score, pos.x, pos.y);
  },
  onClick: function (pos) {
    var numReg = /^\d+$/;
    if (numReg.test(this.option.score)) {
      this.drawScoreLoc(pos);
      this.scoreLoc.push({
        loc: { x: pos.x, y: pos.y },
        score: this.option.score,
        itemId: this.itemId,
      });
      //选中一次分数只能标记一次
      this.option.score = "";
    }
  },
};
