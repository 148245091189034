<template>
  <el-container>
    <el-main class="el-main-padding">
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="200px"
        class="demo-ruleForm"
        :inline-message="true"
      >
        <el-form-item v-if="isSuperAdmin" label="学校">
          <el-select
            v-model="ruleForm.orgId"
            placeholder="请选择"
            style="width: 180px"
            filterable
          >
            <el-option
              v-for="item in rootSchoolSelect"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item
          label="使用第三方登录"
          prop="STUDENT_CLIENT_ACCESS_FROM_THIRD_PARTY"
        >
          <el-switch
            v-model="ruleForm.STUDENT_CLIENT_ACCESS_FROM_THIRD_PARTY"
            on-text="是"
            off-text="否"
          ></el-switch>
        </el-form-item>

        <el-form-item
          label="第三方登录页地址"
          prop="STUDENT_CLIENT_THIRD_PARTY_LOGIN_URL"
        >
          <el-input
            v-model="ruleForm.STUDENT_CLIENT_THIRD_PARTY_LOGIN_URL"
            :disabled="!ruleForm.STUDENT_CLIENT_ACCESS_FROM_THIRD_PARTY"
            placeholder="请输入第三方登录页地址,以http://或https://开头"
            class="input-width"
          ></el-input>
        </el-form-item>

        <el-form-item label="展示我司logo" prop="SHOW_QMTH_LOGO">
          <el-switch
            v-model="ruleForm.SHOW_QMTH_LOGO"
            on-text="是"
            off-text="否"
          ></el-switch>
        </el-form-item>

        <el-form-item label="证件号隐私模式" prop="ID_NUMBER_PRIVATE_MODE">
          <el-switch
            v-model="ruleForm.ID_NUMBER_PRIVATE_MODE"
            on-text="是"
            off-text="否"
          ></el-switch>
        </el-form-item>

        <el-form-item
          label="展示APP下载二维码"
          prop="SHOW_STUDENT_CLIENT_APP_QRCODE"
        >
          <el-switch
            v-model="ruleForm.SHOW_STUDENT_CLIENT_APP_QRCODE"
            on-text="是"
            off-text="否"
          ></el-switch>
        </el-form-item>

        <el-form-item label="开放APP" prop="APP_ENABLED">
          <el-switch
            v-model="ruleForm.APP_ENABLED"
            on-text="是"
            off-text="否"
          ></el-switch>
        </el-form-item>

        <el-form-item label="开放微信小程序作答" prop="WEIXIN_ANSWER_ENABLED">
          <el-switch
            v-model="ruleForm.WEIXIN_ANSWER_ENABLED"
            on-text="是"
            off-text="否"
          ></el-switch>
        </el-form-item>

        <el-form-item label="启用C端考生端" prop="PC_CLIENT_ENABLED">
          <el-switch
            v-model="ruleForm.PC_CLIENT_ENABLED"
            on-text="是"
            off-text="否"
            :disabled="false"
          ></el-switch>
        </el-form-item>

        <el-form-item
          label="活体检测方案"
          prop="IDENTIFICATION_OF_LIVING_BODY_SCHEME"
        >
          <el-radio-group
            v-model="ruleForm.IDENTIFICATION_OF_LIVING_BODY_SCHEME"
            class="input"
          >
            <el-radio label="S1" :disabled="ruleForm.PC_CLIENT_ENABLED"
              >标准活体</el-radio
            >
            <el-radio label="S2" hidden :disabled="ruleForm.PC_CLIENT_ENABLED"
              >自研活体</el-radio
            >
            <el-radio label="S3" :disabled="!ruleForm.PC_CLIENT_ENABLED"
              >C端活体</el-radio
            >
          </el-radio-group>
        </el-form-item>

        <div v-show="ruleForm.IDENTIFICATION_OF_LIVING_BODY_SCHEME !== 'S3'">
          <el-form-item
            label="开考时人脸识别API"
            prop="FACE_VERIFY_API_PROVIDER"
          >
            <el-radio-group
              v-model="ruleForm.FACE_VERIFY_API_PROVIDER"
              class="input"
            >
              <el-radio label="FACEPP">旷视</el-radio>
              <el-radio label="BAIDU">百度</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item
            label="抓拍时人脸比对API"
            prop="FACE_COMPARE_API_PROVIDER"
          >
            <el-radio-group
              v-model="ruleForm.FACE_COMPARE_API_PROVIDER"
              class="input"
            >
              <el-radio label="FACEPP">旷视</el-radio>
              <el-radio label="BAIDU">百度</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item
            label="百度人脸比对通过阈值"
            prop="BAIDU_EXPECT_FACE_COMPARE_SCORE"
          >
            <el-input
              v-model="ruleForm.BAIDU_EXPECT_FACE_COMPARE_SCORE"
              style="width: 180px"
            ></el-input>
            <span style="font-size: 14px; line-height: 44px"></span>
          </el-form-item>
        </div>

        <div v-show="ruleForm.IDENTIFICATION_OF_LIVING_BODY_SCHEME === 'S3'">
          <el-form-item label="C端人脸识别阈值" prop="PC_CLIENT_FACE_THRESHOLD">
            <el-input
              v-model="ruleForm.PC_CLIENT_FACE_THRESHOLD"
              style="width: 180px"
            ></el-input>
            <span style="font-size: 14px; line-height: 44px"></span>
          </el-form-item>

          <el-form-item label="指定动作检测提醒" prop="ACTION_ALERT">
            <el-input
              v-model="ruleForm.ACTION_ALERT"
              style="width: 180px"
            ></el-input>
            <span style="font-size: 14px; line-height: 44px">
              秒后开始检测</span
            >
          </el-form-item>

          <el-form-item label="动作个数" prop="ACTION_NUM">
            <el-select v-model="ruleForm.ACTION_NUM" style="width: 180px">
              <el-option label="1" value="1"></el-option>
              <el-option label="2" value="2"></el-option>
              <el-option label="3" value="3"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="动作选项" prop="ACTION_OPTIONS">
            <el-checkbox-group v-model="ruleForm.ACTION_OPTIONS">
              <el-checkbox
                v-for="opt in actionOptions"
                :key="opt.code"
                v-model="opt.code"
                name="ACTION_OPTION"
                :label="opt.code"
                >{{ opt.name }}</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>

          <el-form-item label="动作顺序" prop="ACTION_ORDER">
            <el-radio-group v-model="ruleForm.ACTION_ORDER" class="input">
              <el-radio label="RANDOM">随机</el-radio>
              <el-radio label="FIXED">固定</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="单个动作最大时长" prop="ACTION_DURATION">
            <el-input
              v-model="ruleForm.ACTION_DURATION"
              style="width: 180px"
            ></el-input>
            <span style="font-size: 14px; line-height: 44px"> 秒</span>
          </el-form-item>
          <el-form-item label="活体总时长限制" prop="ALL_ACTION_DURATION">
            <el-input
              v-model="ruleForm.ALL_ACTION_DURATION"
              style="width: 180px"
            ></el-input>
            <span style="font-size: 14px; line-height: 44px"> 秒</span>
          </el-form-item>
        </div>

        <el-form-item>
          <el-button
            :disabled="btnSaveDiabled"
            type="primary"
            @click="submitForm('ruleForm')"
            >保 存</el-button
          >
        </el-form-item>
      </el-form>
    </el-main>
  </el-container>
</template>
<script>
import { mapState } from "vuex";
import { ACTION_OPTION_LIST, CORE_API } from "@/constants/constants.js";

export default {
  data() {
    var validateUrl = (rule, value, callback) => {
      var ass = this.ruleForm.STUDENT_CLIENT_ACCESS_FROM_THIRD_PARTY;
      var reg = /^(http:\/\/|https:\/\/){1}.*$/;
      if (ass && !value) {
        return callback(new Error("请输入第三方登录页地址"));
      } else if (ass && !value.match(reg)) {
        return callback(new Error("url以http://或https://开头"));
      } else {
        callback();
      }
    };

    let checkFaceType = (rule, value, callback) => {
      if (this.ruleForm.PC_CLIENT_ENABLED) {
        if (value != "S3") {
          return callback(new Error("请选择可用的 “活体检测方案”"));
        }
      } else {
        if (value == "S3") {
          return callback(new Error("请选择可用的 “活体检测方案”"));
        }
      }
      callback();
    };

    let checkActionOptions = (rule, value, callback) => {
      if (this.ruleForm.IDENTIFICATION_OF_LIVING_BODY_SCHEME === "S3") {
        if (this.ruleForm.ACTION_NUM != this.ruleForm.ACTION_OPTIONS.length) {
          return callback(new Error("动作个数与动作选项数量不一致"));
        }
      }
      callback();
    };

    let checkDuration = (rule, value, callback) => {
      if (this.ruleForm.IDENTIFICATION_OF_LIVING_BODY_SCHEME === "S3") {
        let reg = /^[1-9][0-9]*$/;
        if (!new String(value).match(reg) || value < 1 || value > 200) {
          return callback(new Error("范围1至200之间"));
        }
      }
      callback();
    };

    let checkFaceScore = (rule, value, callback) => {
      if (
        this.ruleForm.FACE_VERIFY_API_PROVIDER === "BAIDU" ||
        this.ruleForm.FACE_COMPARE_API_PROVIDER === "BAIDU"
      ) {
        let reg = /^[1-9][0-9]*$/;
        if (!new String(value).match(reg) || value < 50 || value > 90) {
          return callback(new Error("范围50至90之间"));
        }
      }
      callback();
    };

    let checkPcClientFaceThreshold = (rule, value, callback) => {
      if (this.ruleForm.IDENTIFICATION_OF_LIVING_BODY_SCHEME === "S3") {
        let reg = /^[1-9][0-9]*$/;
        if (!new String(value).match(reg) || value < 1 || value > 99) {
          return callback(new Error("范围1至99之间"));
        }
      }
      callback();
    };

    return {
      actionOptions: ACTION_OPTION_LIST,
      rootOrgList: [],
      propertyGroupId: "",
      btnSaveDiabled: false,
      originalRuleForm: {},
      ruleForm: {
        relatedPropertyGroupIdList: [],
        orgId: null,
        STUDENT_CLIENT_ACCESS_FROM_THIRD_PARTY: false,
        STUDENT_CLIENT_THIRD_PARTY_LOGIN_URL: "",
        SHOW_QMTH_LOGO: false,
        ID_NUMBER_PRIVATE_MODE: false,
        SHOW_STUDENT_CLIENT_APP_QRCODE: false,
        APP_ENABLED: false,
        WEIXIN_ANSWER_ENABLED: false,
        PC_CLIENT_ENABLED: false,
        IDENTIFICATION_OF_LIVING_BODY_SCHEME: "S1",
        FACE_VERIFY_API_PROVIDER: "FACEPP",
        FACE_COMPARE_API_PROVIDER: "FACEPP",
        BAIDU_EXPECT_FACE_COMPARE_SCORE: 70,
        PC_CLIENT_FACE_THRESHOLD: 50,
        ACTION_ALERT: "",
        ACTION_NUM: "",
        ACTION_OPTIONS: [],
        ACTION_ORDER: "",
        ACTION_DURATION: "",
        ALL_ACTION_DURATION: "",
      },
      rules: {
        STUDENT_CLIENT_THIRD_PARTY_LOGIN_URL: [
          {
            validator: validateUrl,
            trigger: "change",
          },
        ],
        IDENTIFICATION_OF_LIVING_BODY_SCHEME: [
          {
            validator: checkFaceType,
            trigger: "change",
          },
        ],
        ACTION_OPTIONS: [
          {
            validator: checkActionOptions,
            trigger: "change",
          },
        ],
        ACTION_ALERT: [
          { required: true, message: " ", trigger: "blur" },
          {
            validator: checkDuration,
            trigger: "blur",
          },
        ],
        ACTION_DURATION: [
          { required: true, message: " ", trigger: "blur" },
          {
            validator: checkDuration,
            trigger: "blur",
          },
        ],
        ALL_ACTION_DURATION: [
          { required: true, message: " ", trigger: "blur" },
          {
            validator: checkDuration,
            trigger: "blur",
          },
        ],
        BAIDU_EXPECT_FACE_COMPARE_SCORE: [
          { required: true, message: " ", trigger: "blur" },
          {
            validator: checkFaceScore,
            trigger: "blur",
          },
        ],
        PC_CLIENT_FACE_THRESHOLD: [
          { required: true, message: " ", trigger: "blur" },
          {
            validator: checkPcClientFaceThreshold,
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
    isSuperAdmin() {
      return this.user.roleList.some((role) => role.roleCode === "SUPER_ADMIN");
    },
    rootSchoolSelect() {
      let rootSchools = [];
      for (let i = 0; i < this.rootOrgList.length; i++) {
        let info = {
          name: this.rootOrgList[i].name + "（" + this.rootOrgList[i].id + "）",
          id: this.rootOrgList[i].id,
        };
        rootSchools.push(info);
      }
      return rootSchools;
    },
  },
  watch: {
    "ruleForm.orgId": {
      handler: function () {
        this.initForm();
      },
    },
    "ruleForm.STUDENT_CLIENT_ACCESS_FROM_THIRD_PARTY": {
      handler: function (val) {
        this.$refs["ruleForm"].validate();
        if (!val) {
          this.ruleForm.STUDENT_CLIENT_THIRD_PARTY_LOGIN_URL = "";
        }
      },
    },
    /*ruleForm: {
      deep: true,
      handler: function (newForm) {
        if (Object.keys(this.originalRuleForm).length > 0) {

        }
      },
    },*/
  },
  created() {
    this.ruleForm.orgId = this.user.rootOrgId;
    this.propertyGroupId = "config4Edit1";
    if (this.isSuperAdmin) {
      this.$httpWithMsg
        .get(CORE_API + "/org/getRootOrgList")
        .then((response) => {
          this.rootOrgList = response.data;
        });
    }
    this.initForm();
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.btnSaveDiabled = true;

          let params = {
            orgId: null,
            relatedPropertyGroupIdList: [],
            properties: {},
          };

          params.orgId = this.ruleForm.orgId;

          params.relatedPropertyGroupIdList =
            this.ruleForm.relatedPropertyGroupIdList;

          params.properties.STUDENT_CLIENT_ACCESS_FROM_THIRD_PARTY =
            this.ruleForm.STUDENT_CLIENT_ACCESS_FROM_THIRD_PARTY;

          params.properties.STUDENT_CLIENT_THIRD_PARTY_LOGIN_URL =
            this.ruleForm.STUDENT_CLIENT_THIRD_PARTY_LOGIN_URL;

          params.properties.SHOW_QMTH_LOGO = this.ruleForm.SHOW_QMTH_LOGO;

          params.properties.ID_NUMBER_PRIVATE_MODE =
            this.ruleForm.ID_NUMBER_PRIVATE_MODE;

          params.properties.SHOW_STUDENT_CLIENT_APP_QRCODE =
            this.ruleForm.SHOW_STUDENT_CLIENT_APP_QRCODE;

          params.properties.APP_ENABLED = this.ruleForm.APP_ENABLED;

          params.properties.WEIXIN_ANSWER_ENABLED =
            this.ruleForm.WEIXIN_ANSWER_ENABLED;

          params.properties.PC_CLIENT_ENABLED = this.ruleForm.PC_CLIENT_ENABLED;

          params.properties.IDENTIFICATION_OF_LIVING_BODY_SCHEME =
            this.ruleForm.IDENTIFICATION_OF_LIVING_BODY_SCHEME;

          if (this.ruleForm.IDENTIFICATION_OF_LIVING_BODY_SCHEME === "S3") {
            params.properties.PC_CLIENT_FACE_THRESHOLD =
              this.ruleForm.PC_CLIENT_FACE_THRESHOLD;
            params.properties.ACTION_ALERT = this.ruleForm.ACTION_ALERT;
            params.properties.ACTION_NUM = this.ruleForm.ACTION_NUM;

            // 固定动作顺序
            let finalActionOptions = [];
            for (let n = 0; n < ACTION_OPTION_LIST.length; n++) {
              let code = ACTION_OPTION_LIST[n].code;
              if (this.ruleForm.ACTION_OPTIONS.indexOf(code) > -1) {
                finalActionOptions.push(code);
              }
            }
            params.properties.ACTION_OPTIONS = finalActionOptions.join(",");

            params.properties.ACTION_ORDER = this.ruleForm.ACTION_ORDER;

            params.properties.ACTION_DURATION = this.ruleForm.ACTION_DURATION;

            params.properties.ALL_ACTION_DURATION =
              this.ruleForm.ALL_ACTION_DURATION;
          } else {
            params.properties.FACE_VERIFY_API_PROVIDER =
              this.ruleForm.FACE_VERIFY_API_PROVIDER;

            params.properties.FACE_COMPARE_API_PROVIDER =
              this.ruleForm.FACE_COMPARE_API_PROVIDER;

            params.properties.BAIDU_EXPECT_FACE_COMPARE_SCORE =
              this.ruleForm.BAIDU_EXPECT_FACE_COMPARE_SCORE;
          }

          this.$httpWithMsg
            .put(CORE_API + "/org/saveOrgProperties", params)
            .then(
              () => {
                this.$notify({
                  message: "保存成功",
                  type: "success",
                });
                this.btnSaveDiabled = false;
                // this.originalRuleForm = Object.assign({}, this.ruleForm);
              },
              () => {
                this.btnSaveDiabled = false;
              }
            );
        } else {
          return false;
        }
      });
    },

    initForm() {
      this.ruleForm.relatedPropertyGroupIdList = ["studentClientConfig"];
      var url =
        CORE_API +
        "/org/getOrgPropertiesByGroupWithoutCache/" +
        this.ruleForm.orgId +
        "/" +
        this.propertyGroupId;

      this.$httpWithMsg.get(url).then((response) => {
        if (response) {
          this.ruleForm.STUDENT_CLIENT_ACCESS_FROM_THIRD_PARTY =
            response.data.STUDENT_CLIENT_ACCESS_FROM_THIRD_PARTY === "true";

          this.ruleForm.STUDENT_CLIENT_THIRD_PARTY_LOGIN_URL =
            response.data.STUDENT_CLIENT_THIRD_PARTY_LOGIN_URL;

          this.ruleForm.SHOW_QMTH_LOGO =
            response.data.SHOW_QMTH_LOGO === "true";

          this.ruleForm.ID_NUMBER_PRIVATE_MODE =
            response.data.ID_NUMBER_PRIVATE_MODE === "true";

          this.ruleForm.SHOW_STUDENT_CLIENT_APP_QRCODE =
            response.data.SHOW_STUDENT_CLIENT_APP_QRCODE === "true";

          this.ruleForm.APP_ENABLED = response.data.APP_ENABLED === "true";

          this.ruleForm.WEIXIN_ANSWER_ENABLED =
            response.data.WEIXIN_ANSWER_ENABLED === "true";

          // 未配置时，赋默认值
          this.ruleForm.PC_CLIENT_ENABLED =
            response.data.PC_CLIENT_ENABLED === "true";

          if (response.data.IDENTIFICATION_OF_LIVING_BODY_SCHEME) {
            this.ruleForm.IDENTIFICATION_OF_LIVING_BODY_SCHEME =
              response.data.IDENTIFICATION_OF_LIVING_BODY_SCHEME;
          } else {
            this.ruleForm.IDENTIFICATION_OF_LIVING_BODY_SCHEME = "S1";
          }

          if (response.data.FACE_VERIFY_API_PROVIDER) {
            this.ruleForm.FACE_VERIFY_API_PROVIDER =
              response.data.FACE_VERIFY_API_PROVIDER;
          } else {
            // BAIDU、FACEPP
            this.ruleForm.FACE_VERIFY_API_PROVIDER = "FACEPP";
          }

          if (response.data.FACE_COMPARE_API_PROVIDER) {
            this.ruleForm.FACE_COMPARE_API_PROVIDER =
              response.data.FACE_COMPARE_API_PROVIDER;
          } else {
            // BAIDU、FACEPP
            this.ruleForm.FACE_COMPARE_API_PROVIDER = "FACEPP";
          }

          if (response.data.BAIDU_EXPECT_FACE_COMPARE_SCORE) {
            this.ruleForm.BAIDU_EXPECT_FACE_COMPARE_SCORE =
              response.data.BAIDU_EXPECT_FACE_COMPARE_SCORE;
          } else {
            this.ruleForm.BAIDU_EXPECT_FACE_COMPARE_SCORE = 70;
          }

          if (response.data.ACTION_ALERT) {
            this.ruleForm.ACTION_ALERT = response.data.ACTION_ALERT;
          } else {
            this.ruleForm.ACTION_ALERT = 5;
          }

          if (response.data.PC_CLIENT_FACE_THRESHOLD) {
            this.ruleForm.PC_CLIENT_FACE_THRESHOLD =
              response.data.PC_CLIENT_FACE_THRESHOLD;
          } else {
            this.ruleForm.PC_CLIENT_FACE_THRESHOLD = 50;
          }

          if (response.data.ACTION_NUM) {
            this.ruleForm.ACTION_NUM = response.data.ACTION_NUM;
          } else {
            this.ruleForm.ACTION_NUM = 2;
          }

          if (response.data.ACTION_OPTIONS) {
            this.ruleForm.ACTION_OPTIONS =
              response.data.ACTION_OPTIONS.split(",");
          } else {
            this.ruleForm.ACTION_OPTIONS = ["NOD", "SHAKE"];
          }

          if (response.data.ACTION_ORDER) {
            this.ruleForm.ACTION_ORDER = response.data.ACTION_ORDER;
          } else {
            this.ruleForm.ACTION_ORDER = "RANDOM";
          }

          if (response.data.ACTION_DURATION) {
            this.ruleForm.ACTION_DURATION = response.data.ACTION_DURATION;
          } else {
            this.ruleForm.ACTION_DURATION = 10;
          }

          if (response.data.ALL_ACTION_DURATION) {
            this.ruleForm.ALL_ACTION_DURATION =
              response.data.ALL_ACTION_DURATION;
          } else {
            this.ruleForm.ALL_ACTION_DURATION = 120;
          }

          // this.originalRuleForm = Object.assign({}, this.ruleForm);
        } else {
          this.$notify({
            message: "学校设置信息暂未初始化，请立即初始化",
            type: "warning",
          });
        }
      });
    },
  },
};
</script>
<style scoped>
.input-width {
  width: 638px;
}
</style>
