<template>
  <el-aside v-if="menuList.length > 0" width="">
    <div
      style="height: 50px; margin-top: 20px; margin-left: 20px; font-size: 20px"
    >
      <router-link
        v-if="!isCollapse"
        :to="{ path: group.path + '/tips' }"
        style="color: white; text-decoration: none"
      >
        {{ group.name }}
      </router-link>

      <el-button
        type="text"
        class="float-right"
        size="mini"
        style="padding: 0; padding-right: 16px; outline: none"
        @click="toggoleSidebar"
      >
        <v-icon name="bars" scale="1.6" />
      </el-button>
    </div>
    <el-menu
      class="el-menu-vertical-demo"
      background-color="#222c32"
      text-color="#fff"
      active-text-color="#409eff"
      router
      :default-active="$route.path"
      :collapse="isCollapse"
    >
      <el-submenu
        v-for="menu1 in menuList1"
        :key="menu1.id"
        :index="menu1.nodeCode"
      >
        <template slot="title">
          <i class="el-icon-menu"></i> <span>{{ menu1.name }}</span>
        </template>

        <el-menu-item
          v-for="menu2 in menuList2(menu1)"
          :key="menu2.id"
          :index="menu2.ext5"
          :route="{ path: menu2.ext5 }"
        >
          <span style="margin-left: 9px">{{ menu2.name }}</span>
        </el-menu-item>
      </el-submenu>
    </el-menu>
  </el-aside>
</template>

<script>
import { mapState } from "vuex";
import { CORE_API } from "@/constants/constants";

const routesToMenu = [
  {
    path: "/basic",
    name: "基础信息",
    groupCode: "BASIC_MENUS",
  },
  {
    path: "/examwork",
    name: "考务管理",
    groupCode: "EXAM_WORK_MENUS",
  },
  {
    path: "/questions",
    name: "题库",
    groupCode: "QUESTIONS_WORK_MENUS",
  },
  {
    path: "/oe",
    name: "网考",
    groupCode: "NETEXAM_WORK_MENUS",
  },
  {
    path: "/marking",
    name: "阅卷",
    groupCode: "MARK_WORK_MENUS",
  },
  {
    path: "/portal",
    groupCode: "PORTAL_MENUS",
  },
  {
    path: "/print",
    name: "印刷管理",
    groupCode: "PRINT_MENUS",
  },
  {
    path: "/reports",
    name: "报表",
    groupCode: "REPORTS_MENUS",
  },
];

import { mapMutations } from "vuex";
import { UPDATE_CURRENT_PATHS } from "../../store/currentPaths";
import { UPDATE_MENU_LIST } from "../../store/menuList";

export default {
  name: "HomeSide",
  data() {
    return {
      group: null,
      menuList: [],
      isCollapse: false,
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
    menuList1() {
      return this.menuList.filter(
        (m) => m.parentId === null && m.ext1 === "menu"
      );
    },
  },
  watch: {
    $route() {
      this.updatePath();
    },
  },
  async created() {
    this.group = routesToMenu.find((v) => this.$route.path.startsWith(v.path));
    const groupCode = this.group && this.group.groupCode;
    if (groupCode) {
      this.menuList = await this.getUserPrivileges(groupCode);
      this.UPDATE_MENU_LIST(this.menuList);
      this.updatePath();
    }
  },
  methods: {
    ...mapMutations([UPDATE_CURRENT_PATHS, UPDATE_MENU_LIST]),
    toggoleSidebar() {
      this.isCollapse = !this.isCollapse;
    },
    async getUserPrivileges(groupCode) {
      var url = CORE_API + "/rolePrivilege/getUserPrivileges";
      const params = new URLSearchParams();
      params.append("groupCode", groupCode);
      params.append("full", false);
      const res = await this.$httpWithMsg.post(url, params, {
        headers: { "content-type": "application/x-www-form-urlencoded" },
      });

      return res.data;
    },
    menuList2(menu1) {
      return this.menuList.filter(
        (m) => m.parentId === menu1.id && m.ext1 === "menu"
      );
    },
    updatePath() {
      let currentPaths = [];
      let part = this.menuList.find((v) => v.ext5 === this.$route.path);
      if (!part) {
        this.UPDATE_CURRENT_PATHS([]);
        return;
      }
      currentPaths.unshift(part.name);
      while (this.menuList.find((v) => v.id === part.parentId)) {
        part = this.menuList.find((v) => v.id === part.parentId);
        currentPaths.unshift(part.name);
      }
      // console.log(currentPaths);
      this.UPDATE_CURRENT_PATHS(currentPaths);
    },
  },
};
</script>

<style scoped>
.el-menu-vertical-demo {
  height: calc(100vh - 60px - 70px);
  border-right: none;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

.el-aside {
  background: rgba(34, 44, 50, 1);
  color: #fff;
}

.router-link-active {
  color: #409eff !important;
  font-weight: bold;
}
</style>
