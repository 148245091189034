<template>
  <section class="content">
    <LinkTitlesCustom :current-paths="['考试管理', '考试信息', '人脸白名单']" />

    <div class="box box-info">
      <div class="box-body">
        <el-form
          ref="searchForm"
          :model="searchForm"
          :inline="true"
          label-width="70px"
        >
          <el-form-item label="考试名称">
            <el-input
              v-model="examName"
              class="input"
              :disabled="true"
            ></el-input>
          </el-form-item>

          <el-form-item label="考生姓名">
            <el-input
              v-model="searchForm.studentName"
              class="input"
              clearable
            ></el-input>
          </el-form-item>

          <el-form-item label="学号">
            <el-input
              v-model="searchForm.studentCode"
              class="input"
              clearable
            ></el-input>
          </el-form-item>

          <el-form-item label="身份证号">
            <el-input
              v-model="searchForm.identityNumber"
              class="input"
              clearable
            ></el-input>
          </el-form-item>

          <el-form-item>
            <el-button
              size="small"
              type="primary"
              icon="el-icon-search"
              @click="doSearch(1)"
              >查询
            </el-button>
            <el-button
              size="small"
              type="primary"
              icon="el-icon-arrow-left"
              @click="back"
              >返回
            </el-button>
          </el-form-item>
        </el-form>

        <div class="block-seperator"></div>

        <el-button
          size="small"
          type="primary"
          icon="el-icon-download"
          @click="exportSetting"
          >导出
        </el-button>

        <div style="width: 100%; margin-bottom: 10px"></div>

        <el-table
          :data="tableData"
          style="width: 100%; text-align: center"
          border
        >
          <el-table-column
            prop="examStudentId"
            label="ID"
            width="55"
          ></el-table-column>
          <el-table-column
            prop="studentName"
            label="考生姓名"
            width="90"
          ></el-table-column>
          <el-table-column
            prop="studentCode"
            label="学号"
            width="120"
          ></el-table-column>
          <el-table-column
            prop="identityNumber"
            label="身份证号"
            width="120"
          ></el-table-column>
          <el-table-column prop="courseCode" label="课程代码"></el-table-column>
          <el-table-column prop="courseName" label="课程名称"></el-table-column>
          <el-table-column prop="orgName" label="学习中心"></el-table-column>
          <el-table-column
            prop="operateName"
            label="操作者"
            width="90"
          ></el-table-column>
          <el-table-column
            prop="updateTime"
            label="操作时间"
            width="150"
          ></el-table-column>
        </el-table>

        <div class="page pull-right">
          <el-pagination
            :current-page="searchForm.pageNo"
            :page-size="searchForm.pageSize"
            :page-sizes="[10, 20, 50, 100, 200, 300]"
            :total="totalElements"
            layout="total, sizes, prev, pager, next, jumper"
            @current-change="handlePagerNo"
            @size-change="handlePagerSize"
          ></el-pagination>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { EXAM_WORK_API } from "@/constants/constants.js";
import { mapState } from "vuex";

export default {
  data() {
    return {
      examName: "",
      searchForm: {
        examId: null,
        studentName: "",
        studentCode: "",
        identityNumber: "",
        pageNo: 1,
        pageSize: 10,
      },
      loading: false,
      tableData: [],
      totalElements: 0,
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
  },
  created() {
    this.searchForm.examId = this.$route.params.id;
    this.examName = this.$route.params.examName;
    this.doSearch(1);
  },
  methods: {
    back() {
      this.$router.push({ path: "/examwork/examInfo" });
    },
    doSearch(pageNo) {
      this.searchForm.pageNo = pageNo;

      let url = EXAM_WORK_API + "/exam/skip/face/list";
      this.$httpWithMsg.post(url, this.searchForm).then((response) => {
        this.tableData = response.data.content;
        this.totalElements = response.data.totalElements;
      });
    },
    exportSetting() {
      this.$confirm("确认导出？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let url =
          EXAM_WORK_API +
          "/exam/skip/face/list/export?examId=" +
          this.searchForm.examId;

        if (this.searchForm.studentCode) {
          url += "&studentCode=" + this.searchForm.studentCode;
        }
        if (this.searchForm.identityNumber) {
          url += "&identityNumber=" + this.searchForm.identityNumber;
        }
        if (this.searchForm.studentName) {
          url += "&studentName=" + this.searchForm.studentName;
        }

        window.open(url);
      });
    },
    handlePagerNo(pageNo) {
      this.doSearch(pageNo);
    },
    handlePagerSize(pageSize) {
      this.searchForm.pageSize = pageSize;
      this.doSearch(1);
    },
  },
};
</script>
<style scoped>
.input {
  width: 180px;
}
</style>
