<template>
  <section class="content">
    <div class="box box-info">
      <div
        v-loading.body="loading"
        v-loading.fullscreen="loading"
        class="box-body"
        element-loading-text="请稍后..."
      >
        <!-- 表单 -->
        <el-form inline :model="formSearch">
          <el-form-item label="项目名称">
            <el-input
              v-model="formSearch.name"
              placeholder="请输入项目名称"
              style="width: 180px"
            />
          </el-form-item>
          <el-form-item label="分析类型">
            <el-select
              v-model="formSearch.analyseType"
              :clearable="true"
              class="input"
            >
              <el-option label="单项分析" value="SINGLE"> </el-option>
              <el-option label="趋势分析" value="TREND"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="数据来源">
            <el-select
              v-model="formSearch.dataOrigin"
              :clearable="true"
              class="input"
            >
              <el-option label="同步" value="SYNC"> </el-option>
              <el-option label="导入" value="IMPORT"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              size="small"
              type="primary"
              icon="el-icon-search"
              @click="handleSearchBtn"
            >
              查询
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button
              size="small"
              type="primary"
              icon="el-icon-plus"
              @click="openAddDialog"
            >
              新增
            </el-button>
            <el-button
              size="small"
              type="success"
              icon="el-icon-check"
              :disabled="noBatchSelected"
              @click="enable('')"
              >启用
            </el-button>
            <el-button
              size="small"
              type="danger"
              icon="el-icon-close"
              :disabled="noBatchSelected"
              @click="disable('')"
            >
              禁用
            </el-button>
          </el-form-item>
        </el-form>

        <div class="block-seperator"></div>
        <!-- 页面列表 -->
        <el-table
          :data="tableData"
          border
          resizable
          stripe
          style="width: 100%"
          @selection-change="selectChange"
        >
          <el-table-column type="selection" width="50"></el-table-column>
          <el-table-column width="50" label="ID">
            <span slot-scope="scope">{{ scope.row.id }}</span>
          </el-table-column>
          <el-table-column width="120" prop="name" label="项目名称">
          </el-table-column>
          <el-table-column width="100" prop="analyseTypeName" label="分析类型">
          </el-table-column>
          <el-table-column width="100" prop="dataOriginName" label="数据来源">
          </el-table-column>
          <el-table-column width="100" prop="sampleCount" label="样本数量">
          </el-table-column>
          <el-table-column width="100" prop="reportStatusName" label="项目状态">
          </el-table-column>
          <el-table-column prop="remarks" label="备注说明"> </el-table-column>
          <el-table-column width="50" label="状态">
            <span slot-scope="scope">
              <span v-if="scope.row.enable">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="启用"
                  placement="left"
                >
                  <i class="el-icon-success" style="color: green"></i>
                </el-tooltip>
              </span>
              <span v-else>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="禁用"
                  placement="left"
                >
                  <i class="el-icon-error" style="color: red"></i>
                </el-tooltip>
              </span>
            </span>
          </el-table-column>
          <el-table-column :context="_self" label="操作" width="270">
            <div slot-scope="scope">
              <el-button
                size="mini"
                type="primary"
                plain
                @click="openEdtDialog(scope.row)"
              >
                <i class="el-icon-edit"></i> 修改
              </el-button>
              <el-button
                size="mini"
                type="danger"
                plain
                @click="deleteProject(scope.row)"
              >
                <i class="el-icon-delete"></i> 删除
              </el-button>
              <el-button
                v-if="!scope.row.enable"
                size="mini"
                plain
                type="primary"
                icon="el-icon-check"
                @click="enable(scope.row)"
              >
                <i class="fa fa-check" aria-hidden="true"></i>启用
              </el-button>
              <el-button
                v-else-if="scope.row.enable"
                size="mini"
                type="danger"
                icon="el-icon-close"
                @click="disable(scope.row)"
              >
                <i class="fa fa-close" aria-hidden="true"></i>禁用
              </el-button>
            </div>
          </el-table-column>
        </el-table>
        <div class="page pull-right">
          <el-pagination
            v-if="paginationShow"
            :current-page="currentPage"
            :page-size="pageSize"
            :page-sizes="[10, 20, 50, 100, 200, 300]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          />
        </div>
      </div>
      <el-dialog
        :title="getTitle()"
        width="500px"
        :visible.sync="model"
        :close-on-click-modal="false"
        @close="closeModel"
      >
        <el-form
          ref="projectForm"
          :key="modelKey"
          :inline="true"
          :model="projectForm"
          :rules="rules"
          label-width="90px"
          class="editForm"
        >
          <el-row>
            <el-form-item label="项目名称" prop="name" class="form-item">
              <el-input
                v-model="projectForm.name"
                class="pull-length"
                maxlength="50"
              />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="分析类型" prop="analyseType" class="form-item">
              <el-select
                v-model="projectForm.analyseType"
                :clearable="true"
                class="input"
                :disabled="isEdit()"
              >
                <el-option label="单项分析" value="SINGLE"> </el-option>
              </el-select>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="数据来源" prop="dataOrigin" class="form-item">
              <el-select
                v-model="projectForm.dataOrigin"
                :clearable="true"
                class="input"
                :disabled="isEdit()"
              >
                <el-option label="同步" value="SYNC"> </el-option>
              </el-select>
            </el-form-item>
          </el-row>
          <el-row v-if="projectForm.dataOrigin == 'IMPORT'">
            <el-form-item label="样本数量" prop="sampleCount" class="form-item">
              <el-input
                v-model="projectForm.sampleCount"
                class="pull-length"
                :disabled="isEdit()"
                maxlength="3"
              />
            </el-form-item>
          </el-row>
          <el-row v-if="projectForm.dataOrigin == 'SYNC'">
            <el-form-item label="选择考试" prop="examIds" class="form-item">
              <el-select
                ref="select"
                v-model="projectForm.examIds"
                class="input"
                clearable
                filterable
                multiple
                :loading="loading"
                :disabled="isEdit()"
              >
                <el-option
                  v-for="item in examList"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="备注说明" prop="remarks" class="form-item">
              <el-input
                v-model="projectForm.remarks"
                class="pull-length"
                maxlength="255"
              />
            </el-form-item>
          </el-row>
          <el-row class="pull-center">
            <el-button
              type="primary"
              :loading="projectForm.loading"
              @click="sub"
              >确定</el-button
            >
            <el-button @click="closeModel">取消</el-button>
          </el-row>
        </el-form>
      </el-dialog>
    </div>
  </section>
</template>
<script>
import { REPORTS_API } from "@/constants/constants.js";
import { mapState } from "vuex";

export default {
  name: "Project",
  data() {
    return {
      examList: [],
      examSelectloading: false,
      loading: false,
      selectedIds: [],
      analyseTypeList: [
        {
          value: "SINGLE",
          label: "单项分析",
        },
        {
          value: "TREND",
          label: "趋势分析",
        },
      ],
      model: false,
      modelKey: Math.random(),

      paginationShow: false,
      formSearch: {
        analyseType: "",
        dataOrigin: "",
        name: "",
      },
      projectForm: {
        id: null,
        name: "",
        analyseType: "",
        dataOrigin: "",
        sampleCount: null,
        examIds: [],
        loading: false,
      },
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 10,

      rules: {
        name: [
          { required: true, message: "请输入项目名称", trigger: "change" },
        ],
        analyseType: [
          { required: true, message: "请选择分析类型", trigger: "change" },
        ],
        dataOrigin: [
          { required: true, message: "请选择数据来源", trigger: "change" },
        ],
        sampleCount: [
          { required: true, message: "请输入样本数量", trigger: "change" },
          {
            pattern: /^[1-5]{1}$/,
            message: "样本数量必须为1-5的整数",
            trigger: "change",
          },
        ],
        examIds: [
          {
            required: true,
            type: "array",
            message: "请选择考试",
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
    ids() {
      var ids = "";
      for (let id of this.selectedIds) {
        if (!ids) {
          ids += id;
        } else {
          ids += "," + id;
        }
      }
      return ids;
    },
    noBatchSelected() {
      return this.selectedIds.length === 0;
    },
    isSuperAdmin() {
      return this.user.roleList.some((role) => role.roleCode == "SUPER_ADMIN");
    },
  },
  //初始化查询
  created() {
    this.init();
  },
  methods: {
    getStatus() {
      let doingIds = this.getDoingId();
      if (doingIds != "") {
        this.$httpWithoutBar
          .get(REPORTS_API + "/project/getProjectList?ids=" + doingIds)
          .then((response) => {
            response.data.forEach((element) => {
              this.tableData.forEach((e) => {
                if (e.id == element.id) {
                  e.reportStatus = element.reportStatus;
                  e.reportStatusName = element.reportStatusName;
                }
              });
            });
            setTimeout(() => {
              this.getStatus();
            }, 30000);
          });
      }
    },
    getDoingId() {
      var tempData = [];
      this.tableData.forEach((element) => {
        if (
          element.reportStatus == "WAITCOMPUTE" ||
          element.reportStatus == "COMPUTING"
        ) {
          tempData.push(element.id);
        }
      });
      return tempData.join(",");
    },
    deleteProject(row) {
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        var url = REPORTS_API + "/project/" + row.id;
        this.$httpWithMsg.delete(url).then(() => {
          this.$notify({
            type: "success",
            message: "删除成功！",
          });
          this.searchForm();
        });
      });
    },
    async examSearch() {
      this.$http.get(REPORTS_API + "/project/allExam").then((response) => {
        this.examList = response.data;
      });
    },
    getTitle() {
      if (this.projectForm.id) {
        return "修改项目";
      } else {
        return "新增项目";
      }
    },
    isEdit() {
      if (this.projectForm.id) {
        return true;
      } else {
        return false;
      }
    },
    //禁用
    disable(row) {
      var ids = this.checkIds(row);
      if (!ids) return;
      this.$confirm("是否禁用所选项目？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        var url = REPORTS_API + "/project/disable?ids=" + ids;
        this.$httpWithMsg.put(url).then(() => {
          this.$notify({
            type: "success",
            message: "禁用成功",
          });
          this.searchForm();
        });
      });
    },

    //启用
    enable(row) {
      var ids = this.checkIds(row);
      if (!ids) return;
      this.$confirm("是否启用所选项目？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        var url = REPORTS_API + "/project/enable?ids=" + ids;
        this.$httpWithMsg.put(url).then(() => {
          this.$notify({
            type: "success",
            message: "启用成功",
          });
          this.searchForm();
        });
      });
    },
    checkIds(row) {
      if (row) {
        return row.id;
      } else {
        if (this.ids.length == 0) {
          this.$notify({
            type: "warning",
            message: "请选择项目",
          });
          return "";
        } else {
          return this.ids;
        }
      }
    },
    selectChange(row) {
      this.selectedIds = [];
      row.forEach((element) => {
        this.selectedIds.push(element.id);
      });
    },
    openAddDialog() {
      this.projectForm = Object.assign({});
      this.modelKey = Math.random();
      this.model = true;
    },
    openEdtDialog(row) {
      this.modelKey = Math.random();
      this.projectForm = Object.assign({}, row);
      this.model = true;
    },
    async sub() {
      const res = await this.$refs.projectForm.validate();

      if (res === false) {
        return;
      }
      this.projectForm.loading = true;
      if (this.projectForm.id) {
        let url = REPORTS_API + "/project/update";
        this.$httpWithMsg
          .post(url, this.projectForm)
          .then(() => {
            this.$notify({
              type: "success",
              message: "修改成功！",
            });
            this.closeModel();
            this.searchForm();
          })
          .finally(() => (this.projectForm.loading = false));
      } else {
        let url = REPORTS_API + "/project/add";
        this.$httpWithMsg
          .post(url, this.projectForm)
          .then(() => {
            this.$notify({
              type: "success",
              message: "保存成功！",
            });
            this.closeModel();
            this.searchForm();
          })
          .finally(() => (this.projectForm.loading = false));
      }
    },
    closeModel() {
      this.model = false;
      this.$refs.projectForm.resetFields();
      this.modelKey = Math.random();
    },
    handleSearchBtn() {
      this.currentPage = 1;
      this.searchForm();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.searchForm();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.searchForm();
    },
    //查询
    searchForm() {
      this.loading = true;
      var url =
        REPORTS_API + "/project/page/" + this.currentPage + "/" + this.pageSize;
      this.$httpWithMsg
        .get(url, { params: this.formSearch })
        .then((response) => {
          this.tableData = response.data.list;
          this.total = response.data.total;
          this.loading = false;

          this.$nextTick(function () {
            this.paginationShow = true;
          });
          this.getStatus();
        })
        .finally(() => (this.loading = false));
    },
    init() {
      this.searchForm();
      this.examSearch();
    },
  },
};
</script>

<style scoped>
.page {
  margin-top: 10px;
}
.pull-length {
  width: 300px;
}
.pull-center {
  margin-top: 20px;
}
.editForm .el-form-item {
  margin-bottom: 12px;
}
</style>
