export const UPDATE_MENU_LIST = "UPDATE_MENU_LIST";

export default {
  state: [],
  mutations: {
    [UPDATE_MENU_LIST](state, menuList) {
      // console.log(state, menuList);
      state.length = 0;
      state.push(...menuList);
    },
  },
};
