<template>
  <div id="editSelectApp" v-loading="fullscreenLoading">
    <section class="content">
      <!-- 头信息 -->
      <h3 class="box-title">
        <span v-if="!quesModel.id"
          ><LinkTitlesCustom :current-paths="['试题管理', '试题新增']"
        /></span>
        <span v-if="quesModel.id"
          ><LinkTitlesCustom :current-paths="['试题管理', '试题修改']"
        /></span>
      </h3>
      <!-- 正文信息 -->
      <div class="box-body">
        <el-form
          ref="quesModel"
          :model="quesModel"
          :rules="rules"
          label-width="100px"
          class="demo-ruleForm"
          label-position="right"
        >
          <el-form-item label="题型">
            <el-select
              v-model="quesModel.questionType"
              :disabled="true"
              placeholder="请输入题型"
            >
              <el-option
                v-for="item in questionTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!--
              <el-form-item label="分数">
                  <el-input placeholder="分数" v-model="quesModel.score" style="width:50px;"></el-input>
              </el-form-item>
            -->
          <!-- created by weiwenhai -->
          <el-form-item label="难度">
            <el-select
              v-model="quesModel.difficultyDegree"
              placeholder="请输入难度"
            >
              <el-option
                v-for="item in difficultyDegreeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="公开度">
            <el-select v-model="quesModel.publicity" placeholder="请输入公开度">
              <el-option
                v-for="item in publicityList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            v-if="quesModel.questionType == 'TEXT_ANSWER_QUESTION'"
            label="作答类型"
          >
            <el-select v-model="quesModel.answerType">
              <el-option
                v-for="item in answerTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="属性列表">
            <el-tooltip
              v-for="(content, index) in quesModel.quesProperties"
              :key="index"
              placement="top"
            >
              <div slot="content">
                <span v-if="content.firstProperty != null"
                  >一级属性：{{ content.firstProperty.name }}</span
                ><br />
                <span v-if="content.secondProperty != null"
                  >二级属性：{{ content.secondProperty.name }}</span
                >
              </div>
              <el-tag
                :key="content.id"
                style="margin-right: 5px"
                closable
                type="primary"
                @close="handleClose(content)"
              >
                {{ content.coursePropertyName }}
              </el-tag>
            </el-tooltip>
          </el-form-item>
          <el-row :gutter="20">
            <el-col :xs="6" :sm="6" :md="6" :lg="6">
              <el-form-item label="属性名">
                <el-select
                  v-model="coursePropertyName"
                  placeholder="属性名"
                  class="property_with"
                  @change="searchFirst"
                >
                  <el-option
                    v-for="item in coursePropertyList"
                    :key="item.name"
                    :label="item.name"
                    :value="item.name"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="6" :sm="6" :md="6" :lg="6">
              <el-form-item label="一级">
                <el-select
                  v-model="firstPropertyId"
                  placeholder="一级"
                  class="property_with"
                  @change="searchSecond"
                >
                  <el-option
                    v-for="item in firstPropertyList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="6" :sm="6" :md="6" :lg="6">
              <el-form-item label="二级">
                <el-select
                  v-model="secondPropertyId"
                  placeholder="二级"
                  class="property_with"
                >
                  <el-option
                    v-for="item in secondPropertyList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="3" :sm="3" :md="3" :lg="3">
              <el-form-item>
                <el-button
                  type="primary"
                  style="margin-left: -30px"
                  @click="insertProperty"
                  ><i class="el-icon-plus"></i> 新增属性</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
          <!-- end -->
          <el-form-item label="题干" prop="quesBody">
            <ckeditor v-model="quesModel.quesBody"></ckeditor>
          </el-form-item>
          <el-form-item
            v-for="(option, index) in quesModel.quesOptions"
            :key="option.number"
          >
            <el-col :span="1">
              <el-radio
                v-if="quesModel.questionType === 'SINGLE_ANSWER_QUESTION'"
                v-model="singleRightAnswer"
                :label="index | optionOrderWordFilter"
              ></el-radio>
              <el-checkbox
                v-if="quesModel.questionType === 'MULTIPLE_ANSWER_QUESTION'"
                v-model="multipleRightAnswer"
                :label="index | optionOrderWordFilter"
              ></el-checkbox>
            </el-col>
            <el-col :span="20">
              <ckeditor v-model="option.optionBody"></ckeditor>
            </el-col>
            <el-col :span="2">
              <i
                class="el-icon-delete"
                title="删除"
                @click.prevent="removeQuesOption(option)"
              ></i>
            </el-col>
          </el-form-item>
          <el-form-item label="答案">
            <el-col> <span v-html="answer"></span> </el-col>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="addQuesOption"
              ><i class="el-icon-plus"></i> 新增选项</el-button
            >
            <el-button
              type="primary"
              icon="check"
              :disabled="saveDisabled"
              @click="submitForm('quesModel')"
              >保存</el-button
            >
            <el-button
              type="primary"
              icon="caret-left"
              @click="backToQuesList()"
              ><i class="el-icon-arrow-left"></i> 返回列表
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </section>
  </div>
</template>
<script>
import { QUESTION_API } from "@/constants/constants";
import { isEmptyStr, QUESTION_TYPES } from "../constants/constants";
import ckeditor from "../component/ckeditor.vue";
import LinkTitlesCustom from "@/components/LinkTitlesCustom.vue";

export default {
  name: "EditSelectApp",
  components: { ckeditor, LinkTitlesCustom },
  data() {
    return {
      questionTypes: QUESTION_TYPES,
      fullscreenLoading: false,
      paperId: "",
      paperDetailId: "",
      questionId: "",
      quesModel: {
        quesBody: "",
        quesOptions: [],
        quesAnswer: "",
        questionType: "",
        courseName: "",
        courseNo: "",
        difficultyDegree: "",
        publicity: true,
        answerType: "",
        quesProperties: [],
        score: 1,
      },
      difficultyDegreeList: [
        { label: 0.1, value: 0.1 },
        { label: 0.2, value: 0.2 },
        { label: 0.3, value: 0.3 },
        { label: 0.4, value: 0.4 },
        { label: 0.5, value: 0.5 },
        { label: 0.6, value: 0.6 },
        { label: 0.7, value: 0.7 },
        { label: 0.8, value: 0.8 },
        { label: 0.9, value: 0.9 },
        { label: 1.0, value: 1.0 },
      ],
      publicityList: [
        { label: "公开", value: true },
        { label: "非公开", value: false },
      ],
      answerTypes: [
        { label: "文本", value: "DIVERSIFIED_TEXT" },
        { label: "音频", value: "SINGLE_AUDIO" },
      ],
      coursePropertyList: [],
      coursePropertyName: "", //课程属性名
      firstPropertyList: [], //一级属性集合
      firstPropertyId: "", //一级属性id
      secondPropertyList: [], //二级属性集合
      secondPropertyId: "", //二级属性id
      //验证
      rules: {
        // quesBody: [
        //     { required: true, message: '请输入题干', trigger: 'blur'}
        // ]
      },
      singleRightAnswer: "", //接收单选答案
      multipleRightAnswer: [], //接收多选答案
    };
  },
  computed: {
    answer() {
      if (this.quesModel.questionType == "SINGLE_ANSWER_QUESTION") {
        return this.singleRightAnswer;
      } else if (this.quesModel.questionType == "MULTIPLE_ANSWER_QUESTION") {
        var obj = this.multipleRightAnswer;
        return obj.sort().toString();
      }
      return this.quesModel.quesAnswer;
    },
    saveDisabled: function () {
      if (!this.questionId && this.quesModel.quesOptions.length == 0) {
        return true;
      }
      return false;
    },
  },
  created() {
    let questionId = this.$route.params.id;
    if (questionId) {
      this.questionId = questionId;
      this.quesModel["id"] = questionId;
      this.findQuestionById(questionId);
    }
    this.paperId = this.$route.params.paperId;
    this.paperDetailId = this.$route.params.paperDetailId;
    this.courseNo = this.$route.params.courseNo;
    let questionType = this.$route.params.questionType;
    if (questionType) {
      this.quesModel.questionType = questionType;
    }
    if (this.courseNo) {
      this.$http
        .get(QUESTION_API + "/courseProperty/enable/" + this.courseNo)
        .then((response) => {
          this.coursePropertyList = response.data;
        });
    }

    if (isEmptyStr(this.quesModel.answerType)) {
      this.quesModel.answerType = "DIVERSIFIED_TEXT";
    }
  },
  methods: {
    findQuestionById(questionId) {
      this.$http
        .get(QUESTION_API + "/question/" + questionId)
        .then((response) => {
          this.quesModel = response.data;
          if (this.quesModel.quesOptions) {
            for (var i = 0; i < this.quesModel.quesOptions.length; i++) {
              var option = this.quesModel.quesOptions[i];
              if (
                this.quesModel.questionType == "SINGLE_ANSWER_QUESTION" &&
                option.isCorrect == 1
              ) {
                this.singleRightAnswer = String.fromCharCode(65 + i);
              }
              if (
                this.quesModel.questionType == "MULTIPLE_ANSWER_QUESTION" &&
                option.isCorrect == 1
              ) {
                this.multipleRightAnswer.push(String.fromCharCode(65 + i));
              }
            }
          }

          if (isEmptyStr(this.quesModel.answerType)) {
            this.quesModel.answerType = "DIVERSIFIED_TEXT";
          }

          this.initCourseProperty();
        });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.setRightAnswer();
          this.quesModel.quesAnswer = this.answer;
          if (this.questionId) {
            this.editQuestion();
          } else {
            this.saveNewQuestion();
          }
        } else {
          return false;
        }
      });
    },
    //修改试题
    editQuestion() {
      if (this.quesModel.quesOptions.length == 0) {
        this.$confirm("无选项将删除该试题, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.fullscreenLoading = true;
            this.$httpWithMsg
              .delete(
                QUESTION_API + "/paper/deleteQuestion/" + this.quesModel.id
              )
              .then(() => {
                this.$notify({
                  message: "删除成功",
                  type: "success",
                });
                this.$router.push({ path: "/questions/question_list/0" });
              });
          })
          .finally(() => (this.fullscreenLoading = false));
      } else {
        this.fullscreenLoading = true;
        this.$httpWithMsg
          .put(QUESTION_API + "/question", this.quesModel)
          .then(() => {
            this.$notify({
              message: "保存成功",
              type: "success",
            });
          })
          .finally(() => (this.fullscreenLoading = false));
      }
    },
    //新增试题
    saveNewQuestion() {
      if (!this.quesModel.difficultyDegree) {
        this.$notify({
          message: "请选择试题难度",
          type: "error",
        });
        return false;
      }
      this.fullscreenLoading = true;
      this.$httpWithMsg
        .post(
          QUESTION_API +
            "/paper/addQuestion/" +
            this.paperId +
            "/" +
            this.paperDetailId,
          this.quesModel
        )
        .then(() => {
          this.fullscreenLoading = false;
          this.$notify({
            type: "success",
            message: "保存成功",
          });
          this.$router.push({ path: "/questions/question_list/0" });
        })
        .finally(() => (this.fullscreenLoading = false));
    },
    //新增选项
    addQuesOption() {
      this.quesModel.quesOptions.push({
        number: "",
        optionBody: "",
        isCorrect: "",
      });
      for (var i = 0; i < this.quesModel.quesOptions.length; i++) {
        this.quesModel.quesOptions[i]["number"] = i + 1;
      }
    },
    //删除选项
    removeQuesOption(option) {
      this.singleRightAnswer = "";
      this.multipleRightAnswer = [];
      let index = this.quesModel.quesOptions.indexOf(option);
      if (index !== -1) {
        this.quesModel.quesOptions.splice(index, 1);
      }
      if (this.quesModel.quesOptions.length > 0) {
        for (var i = 0; i < this.quesModel.quesOptions.length; i++) {
          var quesOption = this.quesModel.quesOptions[i];
          quesOption["number"] = i + 1;
          if (quesOption.isCorrect == 1) {
            var answerOrderNum = String.fromCharCode(65 + i);
            if (this.quesModel.questionType == "SINGLE_ANSWER_QUESTION") {
              this.singleRightAnswer = answerOrderNum;
            }
            if (this.quesModel.questionType == "MULTIPLE_ANSWER_QUESTION") {
              this.multipleRightAnswer.push(answerOrderNum);
            }
          }
        }
      }
    },
    //在正确的option上设置isCorrect=1
    setRightAnswer() {
      if (this.quesModel.quesOptions.length == 0) {
        return false;
      }
      for (var i = 0; i < this.quesModel.quesOptions.length; i++) {
        var option = this.quesModel.quesOptions[i];
        var answerOrderNum = String.fromCharCode(65 + i);
        if (this.quesModel.questionType == "SINGLE_ANSWER_QUESTION") {
          option["isCorrect"] =
            answerOrderNum == this.singleRightAnswer ? 1 : 0;
        }
        if (this.quesModel.questionType == "MULTIPLE_ANSWER_QUESTION") {
          option["isCorrect"] =
            this.multipleRightAnswer.indexOf(answerOrderNum) > -1 ? 1 : 0;
        }
      }
    },
    backToQuesList() {
      this.$router.push({
        path: "/questions/question_list/1",
      });
    },
    //查询所有课程属性名
    initCourseProperty() {
      var code = this.quesModel.course.code;
      this.$http
        .get(QUESTION_API + "/courseProperty/enable/" + code)
        .then((response) => {
          this.coursePropertyList = response.data;
        });
    },
    //查询一级属性
    searchFirst() {
      this.firstPropertyId = "";
      this.secondPropertyId = "";
      this.secondPropertyList = [];
      for (let courseProperty of this.coursePropertyList) {
        if (courseProperty.name == this.coursePropertyName) {
          this.$http
            .get(QUESTION_API + "/property/first/" + courseProperty.id)
            .then((response) => {
              this.firstPropertyList = response.data;
            });
        }
      }
    },
    //查询二级属性
    searchSecond() {
      this.secondPropertyId = "";
      if (this.firstPropertyId) {
        this.$http
          .get(QUESTION_API + "/property/second/" + this.firstPropertyId)
          .then((response) => {
            this.secondPropertyList = response.data;
          });
      }
    },
    //新增属性
    insertProperty() {
      if (!this.checkInsertPro()) {
        return false;
      }
      var quesProperty = {
        id: "",
        coursePropertyName: "",
        firstProperty: {},
        secondProperty: {},
      };
      if (
        this.quesModel.quesProperties === undefined ||
        this.quesModel.quesProperties === null ||
        this.quesModel.quesProperties.length == 0
      ) {
        this.quesModel.quesProperties = [];
      }
      quesProperty.id =
        this.coursePropertyName +
        "-" +
        this.firstPropertyId +
        "-" +
        this.secondPropertyId;
      for (let quesPro of this.quesModel.quesProperties) {
        if (quesPro.id == quesProperty.id) {
          this.$notify({
            message: "该属性已存在，请重新选择",
            type: "error",
          });
          return false;
        }
      }
      quesProperty.coursePropertyName = this.coursePropertyName;
      //取到一级属性对象
      for (let property of this.firstPropertyList) {
        if (property.id == this.firstPropertyId) {
          quesProperty.firstProperty = property;
        }
      }
      //判断是否有二级属性
      if (
        this.secondPropertyList != undefined &&
        this.secondPropertyList.length > 0
      ) {
        if (!this.secondPropertyId) {
          this.$notify({
            message: "请选择二级属性",
            type: "error",
          });
          return false;
        }
      }
      //取到二级属性对象
      for (let property of this.secondPropertyList) {
        if (property.id == this.secondPropertyId) {
          quesProperty.secondProperty = property;
        }
      }
      this.quesModel.quesProperties.push(quesProperty);
      this.quesModel = Object.assign({}, this.quesModel);

      //清空下拉框
      this.coursePropertyName = "";
      this.firstPropertyId = "";
      this.secondPropertyId = "";
      this.firstPropertyList = [];
      this.secondPropertyList = [];
    },
    //删除属性
    handleClose(tag) {
      this.quesModel.quesProperties.splice(
        this.quesModel.quesProperties.indexOf(tag),
        1
      );
      this.quesModel = Object.assign({}, this.quesModel);
    },
    //新增属性验证
    checkInsertPro() {
      if (!this.coursePropertyName) {
        this.$notify({
          message: "请选择属性",
          type: "error",
        });
        return false;
      }
      if (!this.firstPropertyId) {
        this.$notify({
          message: "请选择一级属性",
          type: "error",
        });
        return false;
      }
      return true;
    },
  },
};
</script>
<style scoped src="../styles/Common.css"></style>
