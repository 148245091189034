import { render, staticRenderFns } from "./specially.vue?vue&type=template&id=1655d256&scoped=true"
import script from "./specially.vue?vue&type=script&lang=js"
export * from "./specially.vue?vue&type=script&lang=js"
import style0 from "./specially.vue?vue&type=style&index=0&id=1655d256&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1655d256",
  null
  
)

export default component.exports