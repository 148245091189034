import { render, staticRenderFns } from "./ExportStructure.vue?vue&type=template&id=0f88fb8d&scoped=true"
import script from "./ExportStructure.vue?vue&type=script&lang=js"
export * from "./ExportStructure.vue?vue&type=script&lang=js"
import style0 from "../styles/Common.css?vue&type=style&index=0&id=0f88fb8d&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f88fb8d",
  null
  
)

export default component.exports