<template>
  <section class="content">
    <div v-show="isClear == 1">
      <LinkTitlesCustom :current-paths="['基础信息', '蓝图试卷结构']" />
    </div>
    <!-- 正文信息 -->
    <div class="box-body">
      <el-form
        :inline="true"
        :model="formSearch"
        label-position="right"
        label-width="70px"
      >
        <el-row>
          <el-col :span="6">
            <el-form-item label="结构名称">
              <el-input
                v-model="formSearch.name"
                class="search_width"
                placeholder="请输入结构名称"
                size="small"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="制定课程">
              <el-select
                v-model="formSearch.courseNo"
                class="search_width"
                filterable
                :remote-method="getCourses"
                remote
                clearable
                placeholder="请选择"
                size="small"
                @focus="(e) => getCourses(e.target.value)"
              >
                <el-option
                  v-for="item in courseInfoSelect"
                  :key="item.courseNo"
                  :label="item.courseInfo"
                  :value="item.courseNo"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <div class="search_down">
              <el-button size="small" type="primary" @click="searchFrom"
                ><i class="el-icon-search"></i> 查询</el-button
              >
              <el-button size="small" type="primary" @click="insertStruct"
                ><i class="el-icon-plus"></i> 新增</el-button
              >
            </div>
          </el-col>
        </el-row>
        <div
          style="width: 100%; border-bottom: 1px solid #ddd; margin: 10px 0"
        ></div>
        <el-row>
          <el-form-item>
            <span>批量操作：</span>
            <el-button
              size="small"
              type="danger"
              :disabled="noBatchSelected"
              @click="deleteStructs"
            >
              <i class="el-icon-delete"></i> 删除
            </el-button>
          </el-form-item>
        </el-row>
      </el-form>
      <div style="width: 100%; margin-bottom: 10px"></div>
      <!-- 页面列表 -->
      <el-table
        v-loading="loading"
        :data="tableData"
        element-loading-text="拼命加载中"
        border
        style="width: 100%; text-align: center"
        @selection-change="selectionChange"
      >
        <el-table-column type="selection" width="40"></el-table-column>
        <el-table-column label="蓝图试卷结构名称">
          <template slot-scope="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="关联课程" width="180">
          <template slot-scope="scope">
            <span>{{ scope.row.courseName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="课程代码" width="90">
          <template slot-scope="scope">
            <span>{{ scope.row.courseNo }}</span>
          </template>
        </el-table-column>
        <el-table-column label="类型" width="80">
          <template slot-scope="scope">
            <span>{{ getType(scope.row.genPaperType) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="大题数" width="88" sortable prop="detailCount">
        </el-table-column>
        <el-table-column label="难度" width="95">
          <template slot-scope="scope">
            <span>{{ getDifficulty(scope.row.difficulty) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="总分" width="78" sortable prop="totalScore">
        </el-table-column>
        <el-table-column label="操作" width="175">
          <template slot-scope="scope">
            <div class="operate_left">
              <el-button
                size="mini"
                type="primary"
                plain
                @click="editStruct(scope.row)"
                ><i class="el-icon-edit"></i>编辑</el-button
              >
              <el-button
                size="mini"
                type="danger"
                @click="deleteStruct(scope.row)"
                ><i class="el-icon-delete"></i> 删除</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="page pull-right">
        <el-pagination
          :current-page="currentPage"
          :page-size="pageSize"
          :page-sizes="[10, 20, 50, 100, 200, 300]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        ></el-pagination>
      </div>
    </div>
  </section>
</template>
<script>
import { CORE_API, QUESTION_API } from "@/constants/constants";
import LinkTitlesCustom from "@/components/LinkTitlesCustom.vue";
export default {
  components: { LinkTitlesCustom },
  data() {
    return {
      formSearch: {
        name: "",
        courseNo: "",
        type: "BLUEPRINT",
        courseName: "",
      },
      currentPage: 1,
      pageSize: 10,
      total: 10,
      courseList: [],
      loading: false,
      tableData: [],
      selectedList: [],
      isClear: 0,
    };
  },
  computed: {
    selectedIds() {
      var selectedIdsStr = "";
      for (let id of this.selectedList) {
        if (!selectedIdsStr) {
          selectedIdsStr += id;
        } else {
          selectedIdsStr += "," + id;
        }
      }
      return selectedIdsStr;
    },
    courseInfoSelect() {
      var courseList = [];
      for (let course of this.courseList) {
        var courseInfo = course.name + "(" + course.code + ")";
        var courseNo = course.code;
        courseList.push({ courseNo: courseNo, courseInfo: courseInfo });
      }
      return courseList;
    },
    noBatchSelected() {
      return this.selectedList.length === 0;
    },
  },
  watch: {
    $route: "initVue",
  },
  created() {
    this.initVue();
    this.removeItem();
  },
  methods: {
    //新增
    insertStruct() {
      sessionStorage.setItem(
        "blue_paper_stucture",
        JSON.stringify(this.formSearch)
      );
      sessionStorage.setItem(
        "blue_paper_stucture_currentPage",
        this.currentPage
      );
      this.$router.push({
        path: "/questions/insert_blue_paper_structure/add",
      });
    },
    //分页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.searchAll();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.searchAll();
    },
    searchFrom() {
      this.currentPage = 1;
      this.searchAll();
    },
    //查询所有
    searchAll() {
      var pageNo = Number(this.currentPage);
      this.currentPage = 1;
      this.loading = true;
      var url = QUESTION_API + "/paperStruct/" + pageNo + "/" + this.pageSize;
      this.$http.get(url, { params: this.formSearch }).then((response) => {
        this.tableData = response.data.content;
        this.total = response.data.totalElements;
        this.currentPage = pageNo;
      });
      this.loading = false;
    },
    //删除多个试卷结构
    deleteStructs() {
      this.$confirm("是否删除试卷结构？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      })
        .then(() => {
          this.loading = true;
          var url = QUESTION_API + "/paperStruct/" + this.selectedIds;
          this.$httpWithMsg.delete(url).then(() => {
            this.$notify({
              type: "success",
              message: "删除成功！",
            });
            this.searchAll();
            this.selectedList = [];
          });
        })
        .finally(() => (this.loading = false));
    },
    //得到难度
    getDifficulty(val) {
      if (parseFloat(val) >= 0.8 && parseFloat(val) <= 1.0) {
        return val + "（易）";
      }
      if (parseFloat(val) >= 0.4 && parseFloat(val) <= 0.7) {
        return val + "（中）";
      } else {
        return val + "（难）";
      }
    },
    getCourseName(courseNo) {
      for (let course of this.courseList) {
        if (course.code == courseNo) {
          this.formSearch.courseName = course.name;
        }
      }
    },
    //修改
    editStruct(row) {
      this.getCourseName(this.formSearch.courseNo);
      sessionStorage.setItem(
        "blue_paper_stucture",
        JSON.stringify(this.formSearch)
      );
      sessionStorage.setItem(
        "blue_paper_stucture_currentPage",
        this.currentPage
      );
      sessionStorage.setItem("blueStruct", JSON.stringify(row));
      this.$router.push({
        path: "/questions/insert_blue_paper_structure/" + row.id,
      });
    },
    deleteStruct(row) {
      this.$confirm("是否删除试卷结构?", "提示", {
        type: "warning",
      })
        .then(() => {
          this.loading = true;
          this.$httpWithMsg
            .delete(QUESTION_API + "/paperStruct/" + row.id)
            .then(() => {
              this.$notify({
                message: "删除成功",
                type: "success",
              });
              this.searchAll();
            });
        })
        .finally(() => (this.loading = false));
    },
    //清理缓存试卷结构
    removeItem() {
      sessionStorage.removeItem("blueStruct");
    },
    //全选
    selectionChange(val) {
      this.selectedList = [];
      var selectedList = this.selectedList;
      val.forEach((element) => {
        selectedList.push(element.id);
      });
      this.selectedList = selectedList;
    },
    //查询所有课程
    getCourses(query) {
      query = query.trim();
      this.courseLoading = true;
      this.$http
        .get(CORE_API + "/course/query?name=" + query + "&enable=true")
        .then((response) => {
          this.courseList = response.data;
          this.courseLoading = false;
        });
    },
    getType(val) {
      if (val == "ENSEMBLE") {
        return "宏观结构";
      }
      return "微观结构";
    },
    initVue() {
      this.isClear = this.$route.params.isClear;
      if (this.isClear == 0 || !this.isClear) {
        sessionStorage.removeItem("blue_paper_stucture");
        sessionStorage.removeItem("blue_paper_stucture_currentPage");
        this.formSearch = {
          name: "",
          courseNo: "",
          type: "BLUEPRINT",
          courseName: "",
        };
        this.currentPage = 1;
      } else {
        this.formSearch = JSON.parse(
          sessionStorage.getItem("blue_paper_stucture")
        );
        this.currentPage = parseInt(
          sessionStorage.getItem("blue_paper_stucture_currentPage")
        );
      }
      if (this.formSearch.courseName) {
        this.getCourses(this.formSearch.courseName);
      }
      this.searchAll();
    },
  },
};
</script>
<style scoped src="../styles/Common.css"></style>
