<template>
  <div id="insertApp">
    <LinkTitlesCustom
      :current-paths="['基础信息 ', '精确结构预设', '精确结构创建']"
    />
    <section class="content">
      <!-- 正文信息 -->
      <div class="box-body">
        <el-form
          ref="paperStruct"
          :inline="true"
          :model="paperStruct"
          :rules="rules2"
          label-position="right"
          label-width="78px"
        >
          <el-row>
            <el-col :span="8">
              <el-form-item label="结构名称" prop="name">
                <el-input
                  v-model="paperStruct.name"
                  class="search_width"
                  placeholder="试卷结构名称"
                  size="small"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="总分" prop="score">
                <el-input-number
                  v-model="paperStruct.totalScore"
                  size="small"
                  :precision="1"
                  :min="0"
                ></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="制定课程">
                <el-select
                  v-model="paperStruct.courseNo"
                  class="search_width"
                  filterable
                  :remote-method="getCourses"
                  remote
                  clearable
                  placeholder="请选择"
                  size="small"
                >
                  <el-option key="ALL" label="请选择" value="ALL"></el-option>
                  <el-option key="" label="公用" value=""></el-option>
                  <el-option
                    v-for="item in courseInfoSelect"
                    :key="item.courseNo"
                    :label="item.courseInfo"
                    :value="item.courseNo"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <div style="height: 20px"></div>
          <el-row>
            <el-col :span="24">
              <el-form-item label="考试说明" class="pull-left">
                <ckeditor
                  v-model="paperStruct.examRemark"
                  :display="display"
                  :width="wValue"
                  :height="hValue"
                ></ckeditor>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

        <!-- 添加大题弹出框 -->
        <el-dialog
          title="大题信息"
          :visible.sync="paperDetailStructDialog"
          width="500px"
        >
          <el-form
            ref="paperDetailStructForm"
            :model="paperDetailStructForm"
            :rules="rules"
            label-position="right"
            label-width="120px"
            inline-message
          >
            <el-row>
              <el-form-item label="大题名称" prop="name">
                <el-input
                  v-model="paperDetailStructForm.name"
                  class="dialog_input_width"
                  placeholder="请输入题型名称"
                ></el-input>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="题型描述" prop="remark">
                <el-input
                  v-model="paperDetailStructForm.remark"
                  class="dialog_input_width"
                  type="textarea"
                  :rows="2"
                  placeholder="请输入题型描述"
                ></el-input>
              </el-form-item>
            </el-row>
            <el-row class="margin_top_10 margin_left_120">
              <span v-if="dialogType == 'ADD'">
                <el-button
                  type="primary"
                  @click="submitForm('paperDetailStructForm')"
                  >保 存</el-button
                >
              </span>
              <span v-else>
                <el-button
                  type="primary"
                  @click="submitEditForm('paperDetailStructForm')"
                  >保 存</el-button
                >
              </span>
              <span class="margin_left_10">
                <el-button @click="resetForm('paperDetailStructForm')"
                  ><i class="el-icon-refresh"></i> 重 置</el-button
                >
              </span>
              <span class="margin_left_10">
                <el-button
                  type="primary"
                  @click="back2('paperDetailStructForm')"
                  ><i class="el-icon-arrow-left"></i> 返 回</el-button
                >
              </span>
            </el-row>
          </el-form>
        </el-dialog>

        <!-- 页面列表 -->
        <div class="margin_top_10"></div>
        <el-table :data="paperDetailStructs" border style="width: 88.5%">
          <el-table-column label="大题名称">
            <template slot-scope="scope">
              <span>{{ scope.row.name }}</span>
            </template>
          </el-table-column>
          <el-table-column label="小题数">
            <template slot-scope="scope">
              <span>{{ scope.row.detailCount }}</span>
            </template>
          </el-table-column>
          <el-table-column label="大题分数">
            <template slot-scope="scope">
              <span>{{ scope.row.totalScore }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="292">
            <template slot-scope="scope">
              <div class="operate_left">
                <el-button
                  size="mini"
                  type="primary"
                  @click="insertTopicStruct(scope.row)"
                  ><i class="el-icon-plus"></i> 添加小题</el-button
                >
                <el-button
                  size="mini"
                  type="primary"
                  @click="editPaperDetail(scope.row.id)"
                  ><i class="el-icon-edit"></i>编辑
                </el-button>
                <el-button
                  size="mini"
                  type="danger"
                  @click="deletePaperDetail(scope.row.id)"
                  ><i class="el-icon-delete"></i> 删除</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="search_down margin_top_10">
        <el-button
          size="small"
          type="primary"
          :disabled="button_disabled"
          @click="savePaperStruct()"
          >保存试卷结构</el-button
        >
        <el-button
          size="small"
          type="primary"
          :disabled="button_disabled"
          @click="insert()"
          ><i class="el-icon-plus"></i> 新增大题</el-button
        >
        <el-button size="small" type="primary" @click="back"
          ><i class="el-icon-arrow-left"></i> 返 回</el-button
        >
      </div>
    </section>
  </div>
</template>

<script>
import { CORE_API, QUESTION_API } from "@/constants/constants";
import ckeditor from "../component/ckeditor.vue";
import LinkTitlesCustom from "@/components/LinkTitlesCustom.vue";
export default {
  name: "InsertApp",
  components: {
    ckeditor,
    LinkTitlesCustom,
  },

  data() {
    return {
      hValue: "100px",
      wValue: "800px",
      display: "block",
      loading: false,
      courseList: [],
      paperDetailStructForm: {
        id: "",
        number: "",
        name: "",
        remark: "",
        totalScore: "",
        detailCount: "",
        unitStructs: [],
      },
      paperStruct: {
        name: "",
        totalScore: "",
        paperDetailStructs: [],
        courseNo: "ALL",
        courseName: "",
        type: "EXACT", //试卷结构类型
        genPaperType: "COMMON", //组卷类型
        examRemark: "",
      },
      paperDetailStructs: [],
      paperDeatilId: "",
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        remark: [
          { required: true, message: "请输入题型描述", trigger: "blur" },
        ],
      },
      rules2: {
        name: [
          { required: true, message: "请输入试卷结构名称", trigger: "blur" },
        ],
      },
      paperStructId: "",
      paperDetailStructDialog: false,
      dialogType: "",
    };
  },
  computed: {
    button_disabled: function () {
      //var reg = /^[-\+]?\d+(\.\d+)?$/; //正小数
      var reg = /^\d+(?=\.{0,1}\d+$|$)/;
      if (
        reg.test(this.paperStruct.totalScore) &&
        this.paperStruct.totalScore > 0 &&
        this.paperStruct.name
      ) {
        return false;
      } else {
        return true;
      }
    },
    totalScore() {
      var sum = 0.0;
      for (let paperDetailStruct of this.paperDetailStructs) {
        sum = this.accAdd(paperDetailStruct.totalScore, sum);
      }
      return sum;
    },
    courseInfoSelect() {
      var courseList = [];
      for (let course of this.courseList) {
        var courseInfo = course.name + "(" + course.code + ")";
        var courseNo = course.code;
        courseList.push({ courseNo: courseNo, courseInfo: courseInfo });
      }
      return courseList;
    },
  },
  created() {
    this.paperStructId = this.$route.params.id;
    this.searchForm();
  },

  methods: {
    accAdd(num1, num2) {
      let sq1, sq2, m;
      try {
        sq1 = num1.toString().split(".")[1].length;
      } catch (e) {
        sq1 = 0;
      }
      try {
        sq2 = num2.toString().split(".")[1].length;
      } catch (e) {
        sq2 = 0;
      }
      m = Math.pow(10, Math.max(sq1, sq2));
      return (num1 * m + num2 * m) / m;
    },
    //查询列表集合
    searchForm() {
      this.loading = true;
      var paperStructStorge = sessionStorage.getItem("paperStruct");
      if (typeof paperStructStorge == "string") {
        var paperStruct = JSON.parse(paperStructStorge);
        this.getCourses(paperStruct.courseName);
        this.paperStruct = paperStruct;
        this.paperDetailStructs = paperStruct.paperDetailStructs;
      }
      this.loading = false;
    },
    //保存
    savePaperStruct() {
      if (this.paperStruct.name.trim().length == 0) {
        this.$notify({
          message: "结构名称不能为空格",
          type: "error",
        });
        return false;
      }
      if (!this.checkPaperStruct()) {
        return false;
      }
      if (this.paperStruct.courseNo == "ALL") {
        this.$notify({
          message: "请选择课程",
          type: "error",
        });
        return false;
      }
      this.loading = true;
      var url = QUESTION_API + "/paperStruct";
      console.log(this.paperStructId);
      if (this.paperStructId != "add") {
        //假如没ID就是新增
        this.$httpWithMsg
          .put(url, this.paperStruct)
          .then(() => {
            this.$notify({
              message: "保存成功",
              type: "success",
            });
            this.loading = false;
            this.removeItem();
            this.back();
          })
          .catch((e) => {
            this.$notify({
              type: "error",
              message: e.data.desc,
            });

            this.loading = false;
          });
      } else {
        this.$httpWithMsg
          .post(url, this.paperStruct)
          .then(() => {
            this.$notify({
              message: "保存成功",
              type: "success",
            });
            this.loading = false;
            this.removeItem();
            this.back();
          })
          .catch((e) => {
            this.loading = false;
            this.$notify({
              type: "error",
              message: e.data.desc,
            });
          });
      }
    },
    checkPaperStruct() {
      if (
        !(
          this.paperStruct.paperDetailStructs &&
          this.paperStruct.paperDetailStructs.length > 0
        )
      ) {
        this.$notify({
          message: "请添加大题",
          type: "error",
        });
        return false;
      }
      for (let paperDetailStruct of this.paperStruct.paperDetailStructs) {
        if (
          !(
            paperDetailStruct.unitStructs &&
            paperDetailStruct.unitStructs.length > 0
          )
        ) {
          this.$notify({
            message: "请添加大题下的题型",
            type: "error",
          });
          return false;
        }
      }
      if (this.paperStruct.totalScore !== this.totalScore) {
        this.$notify({
          message: "大题总分与结构满分不一致",
          type: "error",
        });
        return false;
      }
      return true;
    },
    //保存大题
    submitForm(formData) {
      this.$refs[formData].validate((valid) => {
        if (valid) {
          var ids = [];
          for (let paperDetailStruct of this.paperDetailStructs) {
            ids.push(paperDetailStruct.id);
          }
          ids.sort();
          var maxId;
          if (ids.length == 0) {
            maxId = 1;
          } else {
            maxId = ids[ids.length - 1];
          }
          var paperDetailStruct = Object.assign({}, this.paperDetailStructForm);
          paperDetailStruct.id = maxId + 1;
          paperDetailStruct.number = this.paperDetailStructs.length + 1;
          this.paperDetailStructs.push(paperDetailStruct);
          this.paperDetailStructDialog = false;
          this.paperDetailStructForm = { name: "", remark: "" };
          this.paperStruct.paperDetailStructs = this.paperDetailStructs;
          sessionStorage.setItem(
            "paperStruct",
            JSON.stringify(this.paperStruct)
          );
        } else {
          return false;
        }
      });
    },
    submitEditForm(formData) {
      this.$refs[formData].validate((valid) => {
        if (valid) {
          var paperDetailStructs = this.paperDetailStructs;
          for (let i = paperDetailStructs.length - 1; i >= 0; i--) {
            if (paperDetailStructs[i].id == this.paperDeatilId) {
              paperDetailStructs[i].remark = this.paperDetailStructForm.remark;
              paperDetailStructs[i].name = this.paperDetailStructForm.name;
            }
          }
          this.paperDetailStructDialog = false;
          this.paperDetailStructForm = { name: "", remark: "" };
          this.paperStruct.paperDetailStructs = paperDetailStructs;
          sessionStorage.setItem(
            "paperStruct",
            JSON.stringify(this.paperStruct)
          );
        } else {
          return false;
        }
      });
    },
    deletePaperDetail(id) {
      var paperDetailStructs = this.paperDetailStructs;
      for (let i = paperDetailStructs.length - 1; i >= 0; i--) {
        if (paperDetailStructs[i].id == id) {
          paperDetailStructs.splice(i, 1);
        }
      }
      this.paperStruct.paperDetailStructs = paperDetailStructs;
      sessionStorage.setItem("paperStruct", JSON.stringify(this.paperStruct));
      console.log(sessionStorage.getItem("paperStruct"));
    },
    setPaperStruct() {
      var paperStruct = sessionStorage.getItem("paperStruct");
      this.paperStruct = JSON.parse(paperStruct);
    },
    removeItem() {
      sessionStorage.removeItem("paperStruct");
    },
    //重置
    resetForm(formData) {
      this.paperDetailStructForm.remark = "";
      this.paperDetailStructForm.name = "";
      this.$refs[formData].clearValidate();
    },

    //添加大题型
    insert() {
      this.dialogType = "ADD";
      this.paperDetailStructForm.remark = "";
      this.paperDetailStructForm.name = "";
      this.paperDetailStructDialog = true;
    },
    editPaperDetail(paperDeatilId) {
      this.dialogType = "EDIT";
      this.paperDeatilId = paperDeatilId;
      var paperDetailStructs = this.paperDetailStructs;
      for (let i = paperDetailStructs.length - 1; i >= 0; i--) {
        if (paperDetailStructs[i].id == this.paperDeatilId) {
          this.paperDetailStructForm.remark = paperDetailStructs[i].remark;
          this.paperDetailStructForm.name = paperDetailStructs[i].name;
        }
      }
      this.paperDetailStructDialog = true;
    },
    getCourseName(courseNo) {
      for (let course of this.courseList) {
        if (course.code == courseNo) {
          this.paperStruct.courseName = course.name;
        }
      }
    },
    //添加小题
    insertTopicStruct(row) {
      let courseNo =
        this.paperStruct.courseNo === "" ? "all" : this.paperStruct.courseNo;
      this.getCourseName(this.paperStruct.courseNo);
      sessionStorage.setItem("paperStruct", JSON.stringify(this.paperStruct));
      this.$router.push({
        path:
          "/questions/insert_paper_structure_info/" +
          this.paperStructId +
          "/" +
          row.name +
          "/" +
          row.id +
          "/" +
          courseNo,
      });
    },
    //返回
    back() {
      this.$router.push({
        path: "/questions/paper_structure/1",
      });
    },
    back2(formData) {
      this.resetForm(formData);
      this.paperDetailStructDialog = false;
    },
    //查询所有课程
    getCourses(query) {
      query = query.trim();
      if (query) {
        if (!(query.indexOf("(") > -1 && query.indexOf(")") > -1)) {
          this.courseLoading = true;
          this.$http
            .get(CORE_API + "/course/query?name=" + query + "&enable=true")
            .then((response) => {
              this.courseList = response.data;
              this.courseLoading = false;
            });
        }
      } else {
        this.courseList = [];
      }
    },
  },
};
</script>
<style scoped src="../styles/Common.css"></style>
