<template>
  <section style="margin-top: 0px">
    <el-form
      :model="formSearch"
      :inline="true"
      style="border-bottom: 1px solid rgb(221, 221, 221); margin-bottom: 10px"
    >
      <el-form-item label="评卷名称" prop="workId">
        <el-select
          v-model="formSearch.workId"
          clearable
          filterable
          :remote-method="getMarkWorks"
          remote
          class="input"
          placeholder="请选择"
          size="small"
          style="width: 150px"
        >
          <el-option
            v-for="item in markWorkSelect"
            :key="item.workId"
            :label="item.markName"
            :value="item.workId"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="类型">
        <el-select
          v-model="formSearch.type"
          size="small"
          class="w180"
          placeholder="请选择"
          clearable
          @clear="clearTypeValue"
        >
          <el-option label="试卷检查" value="PAPER_CHECK"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="状态">
        <el-select
          v-model="formSearch.status"
          size="small"
          class="w180"
          placeholder="请选择"
          clearable
          @clear="clearStatusValue"
        >
          <el-option label="导出中" value="EXPORTING"></el-option>
          <el-option label="成功" value="SUCCESS"></el-option>
          <el-option label="失败" value="ERROR"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button
          size="small"
          type="primary"
          icon="el-icon-search"
          @click="doSearch(1)"
          >查询
        </el-button>

        <el-button size="small" icon="el-icon-refresh" @click="resetSearchForm">
          重置
        </el-button>
      </el-form-item>
    </el-form>

    <el-table
      v-loading="loading"
      :data="tableData"
      element-loading-text="数据加载中"
      style="width: 100%"
      border
      stripe
    >
      <el-table-column label="评卷ID" prop="workId" width="90px" sortable />
      <el-table-column
        label="评卷名称"
        prop="workName"
        width="105px"
        sortable
      />
      <el-table-column
        label="任务类型"
        prop="typeTitle"
        width="105px"
        sortable
      />
      <el-table-column
        label="任务状态"
        prop="statusTitle"
        width="105px"
        sortable
      />
      <el-table-column label="状态描述" prop="statusMsg" />
      <el-table-column label="创建人" prop="createName" width="90px" sortable />
      <el-table-column
        label="创建时间"
        prop="creationTime"
        width="155px"
        sortable
      />
      <el-table-column
        label="开始时间"
        prop="startTime"
        width="155px"
        sortable
      />
      <el-table-column label="结束时间" prop="endTime" width="155px" sortable />

      <el-table-column label="操作" width="180px">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-download"
            :disabled="scope.row.status != 'SUCCESS'"
            plain
            @click="download(scope.row)"
            >下载
          </el-button>

          <el-button
            size="mini"
            type="danger"
            icon="el-icon-delete"
            :disabled="scope.row.status == 'EXPORTING'"
            plain
            @click="doDelete(scope.row)"
            >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="page pull-right">
      <el-pagination
        :current-page="formSearch.pageNo"
        :page-size="formSearch.pageSize"
        :page-sizes="[10, 20, 50, 100, 200, 300]"
        :total="totalElements"
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="handlePagerNo"
        @size-change="handlePagerSize"
      ></el-pagination>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import { MARKING_API, MARKING_LOGIC_API } from "@/constants/constants";

export default {
  data() {
    return {
      formSearch: {
        workId: "",
        type: null,
        status: null,
        pageNo: 1,
        pageSize: 10,
      },
      loading: false,
      tableData: [],
      totalElements: 0,
      markWorkList: [],
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
    markWorkSelect() {
      let markWorkNames = [];
      for (let markWork of this.markWorkList) {
        markWorkNames.push({
          workId: markWork.id,
          markName: markWork.name,
        });
      }
      return markWorkNames;
    },
  },
  created() {
    this.getMarkWorks();
  },
  methods: {
    getMarkWorks(name = "") {
      this.$http
        .get(MARKING_LOGIC_API + "/markWorks?status=1" + `&name=${name}`)
        .then((response) => {
          this.markWorkList = response.data?.content || [];
          if (this.$route.query && this.$route.query.workId) {
            this.formSearch.workId = parseInt(this.$route.query.workId);
          }
        });
    },
    doSearch(pageNo) {
      if (!this.formSearch.workId) {
        this.$notify({
          title: "警告",
          message: "请选择评卷名称",
          type: "warning",
        });
        return false;
      }
      this.formSearch.pageNo = pageNo;

      this.loading = true;
      let url = MARKING_API + "/export/task/list";
      this.$http.post(url, this.formSearch).then(
        (response) => {
          this.tableData = response.data.content;
          this.totalElements = response.data.totalElements;
          this.loading = false;
        },
        (error) => {
          console.log(error);
          this.loading = false;
        }
      );
    },
    doDelete(row) {
      this.$confirm("确认删除当前任务？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let url = MARKING_API + "/export/task/delete?taskId=" + row.id;
        this.$httpWithMsg.post(url).then(() => {
          this.$notify({
            type: "success",
            message: "删除成功",
          });
          this.doSearch();
        });
      });
    },
    download(row) {
      let opath = row.filePath;
      let indx = opath.lastIndexOf("/");
      let head = opath.substring(0, indx + 1);
      let tail = opath.substring(indx + 1);
      window.location.href = head + encodeURIComponent(tail);
    },
    handlePagerNo(pageNo) {
      this.doSearch(pageNo);
    },
    handlePagerSize(pageSize) {
      this.formSearch.pageSize = pageSize;
      this.doSearch(1);
    },
    clearTypeValue() {
      this.formSearch.type = null;
    },
    clearStatusValue() {
      this.formSearch.status = null;
    },
    resetSearchForm() {
      // this.formSearch.examId = "";
      this.formSearch.workId = "";
      this.formSearch.type = null;
      this.formSearch.status = null;
      this.doSearch(1);
    },
  },
};
</script>

<style scoped>
.page {
  margin-top: 10px;
}
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
.w180 {
  width: 180px;
}
</style>
