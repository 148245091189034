<template>
  <div>
    <LinkTitlesCustom
      :current-paths="['评卷总览', '评卷员一览', '评卷员详情']"
    />
    <section class="content">
      <div class="box box-info">
        <div class="box-body">
          <el-form
            :inline="true"
            :model="formSearch"
            label-position="right"
            label-width="70px"
          >
            <el-form-item label="课程代码" class="pull-left">
              <el-input
                v-model="formSearch.courseCode"
                placeholder="课程代码"
              ></el-input>
            </el-form-item>
            <el-form-item label="课程名称" class="pull-left">
              <el-input
                v-model="formSearch.courseName"
                placeholder="课程名称"
              ></el-input>
            </el-form-item>

            <el-form-item>
              <el-button
                size="small"
                type="primary"
                icon="el-icon-search"
                @click="search"
                >查询</el-button
              >
              <el-button
                size="small"
                type="primary"
                icon="el-icon-download"
                @click="exportIt"
                >导出</el-button
              >
              <el-button
                size="small"
                type="primary"
                icon="el-icon-arrow-left"
                @click="back"
                >返回</el-button
              >
            </el-form-item>
          </el-form>
          <div class="block-seperator"></div>
          <!-- 页面列表 -->
          <el-table
            v-loading="loading"
            stripe
            element-loading-text="拼命加载中"
            :data="tableData"
            border
            style="width: 100%"
          >
            <el-table-column label="课程名称" width="200" prop="name" />
            <el-table-column label="课程代码" min-width="100" prop="code" />
            <el-table-column
              label="设置任务数"
              min-width="100"
              prop="limitCount"
            />
            <el-table-column
              label="已评数量"
              min-width="100"
              prop="markedCount"
              sortable
            />
            <el-table-column
              label="打回数量"
              min-width="100"
              prop="rejectCount"
              sortable
            />
            <el-table-column label="完成状态" min-width="100">
              <template slot-scope="scope">
                <div>
                  <span v-if="scope.row.leftCount == 0">完成</span>
                  <span v-if="scope.row.leftCount != 0">未完成</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column :context="_self" width="250" label="操作">
              <template slot-scope="scope">
                <div class="pull-left">
                  <el-button
                    type="primary"
                    size="mini"
                    plain
                    @click="openStepModel(scope.row)"
                    >设置任务数</el-button
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="page pull-right">
            <el-pagination
              background
              :current-page="currentPage"
              :page-size="pageSize"
              :page-sizes="[10, 20, 50, 100, 200, 300]"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              @current-change="handleSettingCurrentChange"
              @size-change="handleSizeChange"
            ></el-pagination>
          </div>
        </div>
        <el-dialog
          title="设置任务数"
          width="500px"
          :visible.sync="stepModel"
          :close-on-click-modal="false"
          @close="closeStepModel"
        >
          <el-form
            ref="stepForm"
            :key="stepModelKey"
            :inline="true"
            :model="stepForm"
            :rules="stepRules"
          >
            <el-row>
              <el-form-item label="设置任务数" prop="limitCount">
                <el-input v-model="stepForm.limitCount" size="mini"></el-input>
              </el-form-item>
            </el-row>
            <div style="margin-bottom: 20px"></div>
            <el-row class="pull-center">
              <el-button
                type="primary"
                :loading="stepForm.loading"
                @click="subStep"
                >确定</el-button
              >
              <el-button @click="closeStepModel">取消</el-button>
            </el-row>
          </el-form>
        </el-dialog>
      </div>
    </section>
  </div>
</template>

<script>
import { MARKING_API } from "@/constants/constants";
import { mapState } from "vuex";
import LinkTitlesCustom from "@/components/LinkTitlesCustom.vue";

let reg = /^[1-9][0-9]*$/;
let validateLimitCount = (rule, value, callback) => {
  if (value && !reg.test(value)) {
    callback(new Error("设置任务数必须是正整数"));
  } else {
    callback();
  }
};
export default {
  components: {
    LinkTitlesCustom,
  },
  data() {
    return {
      formSearch: {
        courseCode: "",
        courseName: "",
      },
      tableData: [],
      oldData: [],
      currentPage: 1,
      pageSize: 10,
      total: 10,
      loading: true,
      workId: "",
      courseCode: "",
      stepModel: false,
      stepModelKey: Math.random(),
      stepForm: {
        workId: null,
        courseCode: null,
        limitCount: null,
        loading: false,
      },
      stepRules: {
        limitCount: [
          {
            required: false,
            validator: validateLimitCount,
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
  },
  created() {
    this.workId = this.$route.params.workId;
    this.userId = this.$route.params.markerId;
    this.initSetting();
  },
  methods: {
    openStepModel(row) {
      this.stepForm.limitCount = row.limitCount;
      this.stepForm.workId = row.workId;
      this.stepForm.courseCode = row.code;
      this.stepModel = true;
    },
    closeStepModel() {
      this.stepModel = false;
      this.stepModelKey = Math.random();
    },
    async subStep() {
      let res = await this.$refs.stepForm.validate();
      if (!res) {
        return;
      }
      this.stepForm.loading = true;
      var url =
        MARKING_API +
        "/markCourses/task-limit?workId=" +
        this.stepForm.workId +
        "&courseCode=" +
        this.stepForm.courseCode +
        "&userId=" +
        this.userId +
        "&limitCount=" +
        this.stepForm.limitCount;
      this.$httpWithMsg
        .put(url)
        .then(() => {
          this.$notify({
            type: "success",
            message: "设置成功！",
          });
          this.closeStepModel();
          this.initSetting();
        })
        .finally(() => (this.stepForm.loading = false));
    },
    exportIt() {
      var key = this.user.key;
      var token = this.user.token;
      let url =
        MARKING_API +
        "/markCourses/progress/export?workId=" +
        this.workId +
        "&$key=" +
        key +
        "&$token=" +
        token;
      let userId = this.userId;
      if (userId) {
        url = url + "&userId=" + userId;
      }
      let courseCode = this.formSearch.courseCode;
      if (courseCode) {
        url = url + "&courseCode=" + courseCode;
      }
      let courseName = this.formSearch.courseName;
      if (courseName) {
        url = url + "&courseName=" + courseName;
      }
      window.location.href = url;
    },
    handleSettingCurrentChange(val) {
      this.currentPage = val;
      this.searchSetting();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.searchSetting();
    },
    pagingSetting() {
      var start = (this.currentPage - 1) * this.pageSize;
      var end =
        this.currentPage * this.pageSize < this.total
          ? this.currentPage * this.pageSize
          : this.total;
      var tempData = [];
      console.log(`当前页: ${this.currentPage},开始:${start},结束:${end}`);
      for (let i = start; i < end; i++) {
        tempData.push(this.tableData[i]);
      }
      this.tableData = tempData;
    },
    initSetting() {
      this.loading = true;
      this.$http
        .get(
          MARKING_API +
            "/markCourses/all/marker/0/" +
            this.pageSize +
            "?workId=" +
            this.workId +
            "&userId=" +
            this.userId
        )
        .then((response) => {
          console.log(response);
          this.tableData = response.data.content;
          this.total = response.data.totalElements;
          this.loading = false;
        });
    },
    search() {
      this.currentPage = 1;
      this.searchSetting();
    },
    searchSetting() {
      this.loading = true;
      this.$http
        .get(
          MARKING_API +
            "/markCourses/all/marker/" +
            (this.currentPage - 1) +
            "/" +
            this.pageSize +
            "?workId=" +
            this.workId +
            "&userId=" +
            this.userId,
          { params: this.formSearch }
        )
        .then((response) => {
          console.log(response);
          this.tableData = response.data.content;
          this.total = response.data.totalElements;
          this.loading = false;
        });
    },
    back() {
      this.$router.back();
    },
  },
};
</script>
<style lang="css" scoped>
li {
  list-style-type: none;
}

.searchFrame {
  margin-right: 10px;
  margin-bottom: 10px;
}

.page {
  margin-top: 10px;
}
.f_button {
  display: block;
  width: 57px;
  height: 20px;
  border: 1px solid #ccc;
  background: #fff;
  font-size: small;
}
</style>
