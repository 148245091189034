import Vue from "vue";
import Icon from "vue-awesome/components/Icon";

import "vue-awesome/icons/flag";
import "vue-awesome/icons/print";
import "vue-awesome/icons/user";
import "vue-awesome/icons/users";
import "vue-awesome/icons/sign-out-alt";
import "vue-awesome/icons/lock";
import "vue-awesome/icons/bars";
import "vue-awesome/icons/user-check";
import "vue-awesome/icons/eye";
import "vue-awesome/icons/eye-slash";

Vue.component("VIcon", Icon);
