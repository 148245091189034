import Home from "../../portal/views/home/Home.vue";
import Tips from "../../portal/views/tips/Tips.vue";
import Project from "../views/Project.vue";
import Overview from "../views/Overview.vue";
import ComputeJobList from "../views/ComputeJobList.vue";
import ReportView from "../views/ReportView.vue";
import ExamStudentOnline from "../views/ExamStudentOnline.vue";
import ExamStudentOnlineDetail from "../views/ExamStudentOnlineDetail.vue";
export default [
  {
    path: "/reports",
    component: Home,
    children: [
      {
        path: "tips", //模块说明
        component: Tips,
      },
      {
        path: "project", //项目列表
        meta: { privilegeCodes: "index_project" },
        component: Project,
      },
      {
        path: "overview", //报表展示
        meta: { privilegeCodes: "index_overview" },
        component: Overview,
      },
      {
        path: "exam-student-online",
        meta: { privilegeCodes: "reports_exam_student_online" },
        component: ExamStudentOnline,
      },
      {
        path: "compute-job-list/:projectId",
        component: ComputeJobList,
      },
      {
        path: "report-view/:projectId",
        component: ReportView,
      },
      {
        path: "online-detail",
        component: ExamStudentOnlineDetail,
      },
    ],
  },
];
