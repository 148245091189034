<template>
  <section class="content">
    <LinkTitlesCustom :current-paths="['属性结构']" />
    <!-- 正文信息 -->
    <div class="box-body">
      <el-form
        :inline="true"
        :model="courseProperty"
        label-position="right"
        label-width="90px"
      >
        <el-row :gutter="10">
          <el-col :xs="7" :sm="7" :md="7" :lg="8">
            <el-form-item label="属性名称" class="pull-left">
              <el-input
                v-model="courseProperty.name"
                placeholder="请输入课程名称"
                :disabled="true"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="7" :sm="7" :md="7" :lg="8">
            <el-form-item label="课程名称">
              <el-select
                v-model="courseProperty.courseId"
                class="form_width"
                filterable
                :remote-method="getCourses"
                remote
                clearable
                :disabled="true"
              >
                <el-option
                  v-for="item in courseInfoSelect"
                  :key="item.courseId"
                  :label="item.courseInfo"
                  :value="item.courseId"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :xs="7" :sm="7" :md="7" :lg="8">
          <div style="margin-left: 20px">
            <el-form-item>
              <el-button type="primary" size="small" @click="insertParent"
                ><i class="el-icon-plus"></i> 新增一级</el-button
              >

              <el-button
                type="primary"
                size="small"
                :disabled="showSonButtton"
                @click="insertSon"
                ><i class="el-icon-plus"></i> 新增二级</el-button
              >
              <el-button
                type="primary"
                size="small"
                :disabled="showButton"
                @click="updateProperty"
                ><i class="el-icon-edit"></i>编辑</el-button
              >
              <el-button
                type="danger"
                size="small"
                :disabled="showButton"
                @click="deleteProperty"
                ><i class="el-icon-delete"></i> 删除</el-button
              >
              <el-button
                type="info"
                :disabled="showMoveButtton"
                size="small"
                @click="moveUp"
                ><i class="el-icon-arrow-up"></i> 上移</el-button
              >
              <el-button
                type="info"
                :disabled="showMoveButtton"
                size="small"
                @click="moveDown"
                ><i class="el-icon-arrow-down"></i> 下移</el-button
              >
              <el-button
                type="primary"
                icon="caret-left"
                size="small"
                @click="back"
                ><i class="el-icon-arrow-left"></i> 返 回</el-button
              >
            </el-form-item>
          </div>
        </el-row>
      </el-form>

      <el-tree
        :data="treeData"
        node-key="id"
        :props="defaultProps"
        :default-expanded-keys="ids"
        @node-click="handleNodeClick"
        ><span slot-scope="{ data }" class="custom-tree-node">
          <span>{{ data.name }}({{ data.code }})</span>
        </span></el-tree
      >
    </div>
    <el-dialog
      :title="title"
      :visible.sync="propertyDialog"
      @close="closeModel"
    >
      <el-form
        ref="propertyForm"
        :model="propertyForm"
        :inline="true"
        :rules="rules"
        :inline-message="true"
      >
        <el-row>
          <el-form-item label="编码" label-width="100px" prop="code">
            <el-input
              v-model="propertyForm.code"
              auto-complete="off"
              style="width: 220px"
            ></el-input>
          </el-form-item>
        </el-row>
        <el-row v-if="isFirstLev()">
          <el-form-item label="一级名称" label-width="100px" prop="name">
            <el-input
              v-model="propertyForm.name"
              auto-complete="off"
              style="width: 220px"
            ></el-input>
          </el-form-item>
        </el-row>
        <el-row v-if="isSecondLev()">
          <el-form-item label="二级名称" label-width="100px" prop="name">
            <el-input
              v-model="propertyForm.name"
              auto-complete="off"
              style="width: 220px"
            ></el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="名称备注" label-width="100px">
            <el-input
              v-model="propertyForm.remark"
              auto-complete="off"
              style="width: 220px"
            ></el-input>
          </el-form-item>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeModel">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import { CORE_API, QUESTION_API } from "@/constants/constants";
import LinkTitlesCustom from "@/components/LinkTitlesCustom.vue";
export default {
  components: { LinkTitlesCustom },
  data() {
    return {
      courseProperty: {
        name: "",
        courseId: "",
      },
      courseList: [],
      ids: [],
      loading: false,
      propertyDialog: false,
      propertyForm: {
        id: "",
        code: "",
        name: "",
        parentId: "",
        number: "",
        coursePropertyId: "",
        remark: "",
      },
      curProperty: {
        id: "",
        code: "",
        name: "",
        parentId: "",
        number: "",
        coursePropertyId: "",
        remark: "",
      },
      showButton: true,
      showSonButtton: true,
      showMoveButtton: true,
      treeData: [],
      defaultProps: {
        children: "propertyList",
      },
      title: "新增属性",
      rules: {
        code: [
          {
            required: true,
            message: "请输入编码",
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: "请输入名称",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    courseInfoSelect() {
      var courseList = [];
      for (var i = 0; i < this.courseList.length; i++) {
        var courseInfo = {
          courseInfo:
            this.courseList[i].name + "(" + this.courseList[i].code + ")",
          courseId: this.courseList[i].id,
        };
        courseList.push(courseInfo);
      }
      return courseList;
    },
  },
  created() {
    this.coursePropertyId = this.$route.params.id;
    this.searchProperty();
  },
  methods: {
    disAllBtn() {
      this.showButton = true;
      this.showSonButtton = true;
      this.showMoveButtton = true;
    },
    isFirstLev() {
      if (this.propertyForm.parentId == "0") {
        return true;
      } else {
        return false;
      }
    },
    isSecondLev() {
      if (this.propertyForm.parentId && this.propertyForm.parentId != "0") {
        return true;
      } else {
        return false;
      }
    },
    closeModel() {
      this.propertyDialog = false;
      this.$refs.propertyForm.resetFields();
    },
    //树形节点选中
    handleNodeClick(object) {
      this.showButton = false;
      //判断选中节点，如果是父节点，可以新增二级
      if (object.parentId == "0") {
        this.showSonButtton = false;
      } else {
        this.showSonButtton = true;
      }
      this.showMoveButtton = false;
      this.curProperty = Object.assign({}, object);
    },
    //查询所有课程
    getCourses(query) {
      this.courseList = [];
      if (query) {
        this.courseLoading = true;
        this.$http.get(CORE_API + "/course/" + query).then((response) => {
          var courseBean = response.data;
          this.courseList.push(courseBean);
        });
      } else {
        this.courseList = [];
      }
    },
    //查询
    searchProperty() {
      this.loading = true;
      var coursePropertyStorge = sessionStorage.getItem("courseProperty");
      if (typeof coursePropertyStorge == "string") {
        this.courseProperty = JSON.parse(coursePropertyStorge);
        this.getCourses(this.courseProperty.courseId);
      }
      this.$http
        .get(QUESTION_API + "/property/all/" + this.coursePropertyId)
        .then((response) => {
          this.treeData = response.data;
          for (var i = 0; i < this.treeData.length; i++) {
            var property = this.treeData[i];
            this.ids.push(property.id);
          }
          this.loading = false;
        });
    },
    //新增一级
    insertParent() {
      this.disAllBtn();
      this.title = "新增属性";
      this.propertyForm = {
        id: "",
        code: "",
        name: "",
        parentId: "0",
        number: "",
        coursePropertyId: this.coursePropertyId,
        remark: "",
      };
      this.propertyDialog = true;
    },
    //新增二级
    insertSon() {
      this.disAllBtn();
      this.title = "新增属性";
      //父对象id赋值
      this.propertyForm = {
        id: "",
        code: "",
        name: "",
        parentId: this.curProperty.id,
        number: "",
        coursePropertyId: this.coursePropertyId,
        remark: "",
      };
      this.propertyDialog = true;
    },
    //修改
    updateProperty() {
      this.disAllBtn();
      this.title = "修改属性";
      this.propertyForm = Object.assign({}, this.curProperty);
      this.propertyDialog = true;
    },
    //保存
    async submit() {
      const res = await this.$refs.propertyForm.validate();
      if (res === false) {
        return;
      }
      this.$http
        .post(QUESTION_API + "/property/save", this.propertyForm)
        .then(() => {
          this.$notify({
            message: this.propertyForm.id ? "修改成功" : "新增成功",
            type: "success",
          });
          this.propertyDialog = false;
          this.searchProperty();
        })
        .catch((error) => {
          this.$notify({
            type: "error",
            message: error.response.data.desc,
          });
        });
      this.showButton = true;
      this.showSonButtton = true;
    },
    //删除
    deleteProperty() {
      this.disAllBtn();
      this.$confirm("确认删除属性吗?", "提示", {
        type: "warning",
      }).then(() => {
        this.loading = true;
        this.$http
          .delete(
            QUESTION_API +
              "/property/delete/" +
              this.curProperty.id +
              "/" +
              this.curProperty.coursePropertyId
          )
          .then(() => {
            this.$notify({
              message: "删除成功",
              type: "success",
            });
            this.searchProperty();
          })
          .catch((error) => {
            this.$notify({
              type: "error",
              message: error.response.data.desc,
            });
          });
      });
      this.showButton = true;
      this.showSonButtton = true;
    },
    //返回
    back() {
      this.$router.push({
        path: "/questions/course_property/1",
      });
    },
    //上移
    moveUp() {
      this.disAllBtn();
      this.$http
        .put(QUESTION_API + "/property/moveUp", this.curProperty)
        .then(() => {
          this.searchProperty();
          this.showMoveButtton = true;
        })
        .catch((error) => {
          this.$notify({
            type: "error",
            message: error.response.data.desc,
          });
          this.showMoveButtton = true;
        });
    },
    //下移
    moveDown() {
      this.disAllBtn();
      this.$http
        .put(QUESTION_API + "/property/moveDown", this.curProperty)
        .then(() => {
          this.searchProperty();
          this.showMoveButtton = true;
        })
        .catch((error) => {
          this.$notify({
            type: "error",
            message: error.response.data.desc,
          });
          this.showMoveButtton = true;
        });
    },
  },
};
</script>
