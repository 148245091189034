<template>
  <div v-loading="loading === 'fetch'" class="black-list-container">
    <el-row style="margin-bottom: 20px">
      <el-alert
        title="软件黑名单配置"
        type="warning"
        show-icon
        :closable="false"
      >
        注：编辑时以换行符分隔, 最好不要包含分号';'！
        <el-button
          class="el-alert__closebtn"
          type="warning"
          size="small"
          round
          plain
          @click="onSave"
        >
          保存</el-button
        >
      </el-alert>
    </el-row>
    <el-row>
      <el-col :span="8">
        <h5>虚拟摄像头黑名单配置:</h5>
        <el-input
          v-model="vCamList"
          type="textarea"
          placeholder="虚拟摄像头黑名单配置"
          :rows="20"
        ></el-input>
      </el-col>
      <el-col :span="8" :offset="1">
        <h5>远程桌面软件黑名单配置:</h5>
        <el-input
          v-model="remoteApp"
          type="textarea"
          placeholder="远程桌面软件黑名单配置"
          :rows="20"
        ></el-input>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "BasicBlackListApp",
  data() {
    return {
      /** loading  'fetch' | 'add' */
      loading: null,
      /** 虚拟摄像头配置 */
      vCamList: "",
      /** 远程桌面软件配置 */
      remoteApp: "",
    };
  },
  mounted() {
    this.fetchConfig();
  },
  methods: {
    /** click 保存 */
    async onSave() {
      this.handleSaveConfig().then(this.fetchConfig);
    },
    /** 保存黑名单APP配置 */
    async handleSaveConfig() {
      try {
        this.loading = "add";
        const params = new URLSearchParams();
        params.append(
          "blacklist",
          JSON.stringify({
            vCamList: this.vCamList,
            remoteApp: this.remoteApp,
          })
        );
        await this.$http.put("/api/ecs_core/systemProperty/blacklist", params, {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        });
      } catch (error) {
        error.message && this.$message.warning(error.message);
      } finally {
        this.loading = null;
      }
    },
    /** 查询黑名单APP配置 */
    async fetchConfig() {
      try {
        this.loading = "fetch";
        const { vCamList = "", remoteApp = "" } = await this.$http
          .get("/api/ecs_core/systemProperty/blacklist")
          .then((response) => response.data);
        this.vCamList = vCamList;
        this.remoteApp = remoteApp;
      } catch (error) {
        error.message && this.$message.warning(error.message);
      } finally {
        this.loading = null;
      }
    },
  },
};
</script>
