<template>
  <section class="content">
    <div
      v-loading.fullscreen="loading"
      class="box-body"
      element-loading-text="请稍后..."
    >
      <!-- 表单 -->
      <el-form :inline="true" :model="formSearch">
        <el-form-item label="学校名称">
          <el-input v-model="formSearch.name" placeholder="请输入学校名称" />
        </el-form-item>
        <el-form-item label="学校代码">
          <el-input v-model="formSearch.code" placeholder="请输入学校代码" />
        </el-form-item>
        <el-form-item label="学校域名">
          <el-input
            v-model="formSearch.domainName"
            placeholder="请输入学校域名"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="handleSearchBtn"
            >查询</el-button
          >
          <el-button
            size="small"
            icon="el-icon-refresh"
            @click="resetSearchForm"
            >重置</el-button
          >
          <el-button
            size="small"
            type="primary"
            icon="el-icon-plus"
            @click="insert"
            >新增</el-button
          >
        </el-form-item>
      </el-form>

      <div class="block-seperator"></div>

      <span>操作：</span>
      <el-button
        size="small"
        type="success"
        :disabled="noBatchSelected"
        icon="el-icon-check"
        @click="enableOrg"
        >启用</el-button
      >
      <el-button
        size="small"
        type="danger"
        :disabled="noBatchSelected"
        icon="el-icon-close"
        @click="disableOrg"
        >禁用</el-button
      >

      <div style="width: 100%; margin-bottom: 10px"></div>

      <!-- 添加或修改学校弹出框 -->
      <el-dialog
        title="学校信息"
        width="500px"
        :visible.sync="schoolDialog"
        @close="dialogBeforeClose"
      >
        <el-form
          ref="schoolForm"
          :inline="true"
          inline-message
          :model="schoolForm"
          :rules="rules"
          label-position="right"
          label-width="90px"
          class="editSchoolForm"
        >
          <el-row>
            <el-form-item label="学校代码" label-width="120px" prop="code">
              <el-input
                v-model="schoolForm.code"
                class="pull_length"
                auto-complete="off"
                placeholder="学校域名"
                :disabled="null != schoolForm.id"
              />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="学校名称" label-width="120px" prop="name">
              <el-input
                v-model="schoolForm.name"
                class="pull_length"
                auto-complete="off"
                placeholder="学校名称"
              />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item
              label="学校域名"
              label-width="120px"
              prop="domainName"
            >
              <el-input
                v-model="schoolForm.domainName"
                class="pull_length"
                auto-complete="off"
                placeholder="学校域名"
              />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="负责人" label-width="120px" prop="contacts">
              <el-input
                v-model="schoolForm.contacts"
                class="pull_length"
                auto-complete="off"
                placeholder="负责人"
              />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="联系方式" label-width="120px" prop="telephone">
              <el-input
                v-model="schoolForm.telephone"
                class="pull_length"
                auto-complete="off"
                placeholder="联系方式"
              />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="状态" label-width="120px" prop="enable">
              <el-radio-group v-model="schoolForm.enable" class="pull_right_sm">
                <el-radio label="true">启用</el-radio>
                <el-radio label="false">禁用</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-row>
          <el-row class="d-flex justify-content-center">
            <el-button type="primary" @click="submitForm">保 存</el-button>
            <el-button @click="schoolDialog = false">取 消</el-button>
          </el-row>
        </el-form>
      </el-dialog>

      <!-- 页面列表 -->
      <el-table
        :data="tableData"
        border
        resizable
        stripe
        @selection-change="selectChange"
      >
        <el-table-column type="selection" width="40" />
        <el-table-column prop="id" label="ID" width="60" />
        <el-table-column label="学校名称">
          <template slot-scope="scope">
            <el-popover trigger="hover" placement="right">
              <div style="font-size: 18px; font-family: 新宋体">
                <tr>
                  <td style="color: green">学校名称</td>
                  <td style="color: purple; padding-left: 20px">
                    {{ scope.row.name }}
                  </td>
                </tr>
                <tr>
                  <td style="color: green">学校代码</td>
                  <td style="color: purple; padding-left: 20px">
                    {{ scope.row.code }}
                  </td>
                </tr>
                <tr>
                  <td style="color: green">负责人</td>
                  <td style="color: purple; padding-left: 20px">
                    {{ scope.row.contacts }}
                  </td>
                </tr>
                <tr>
                  <td style="color: green">联系方式</td>
                  <td style="color: purple; padding-left: 20px">
                    {{ scope.row.telephone }}
                  </td>
                </tr>
              </div>

              <div slot="reference" class="name-wrapper">
                <span>{{ scope.row.name }}</span>
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="code" width="190" label="学校代码" />
        <el-table-column prop="domainName" width="190" label="学校域名" />
        <el-table-column
          sortable
          prop="updateTime"
          width="170"
          label="更新时间"
        />
        <el-table-column width="50" label="状态">
          <span slot-scope="scope">
            <span v-if="scope.row.enable">
              <el-tooltip
                class="item"
                effect="dark"
                content="启用"
                placement="left"
              >
                <i class="el-icon-success" style="color: green"></i>
              </el-tooltip>
            </span>
            <span v-else>
              <el-tooltip
                class="item"
                effect="dark"
                content="禁用"
                placement="left"
              >
                <i class="el-icon-error" style="color: red"></i>
              </el-tooltip>
            </span>
          </span>
        </el-table-column>
        <el-table-column :context="_self" width="210" prop label="操作">
          <div slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              plain
              @click="edit(scope.row)"
            >
              <i class="el-icon-edit"></i> 编辑
            </el-button>
            &nbsp;
            <el-popover
              placement="left"
              width="200"
              trigger="hover"
              :v-model="false"
            >
              <div style="margin: 0">
                <qrcode
                  :value="qrcodeContent(scope.row)"
                  :options="{ width: 180 }"
                ></qrcode>
              </div>
              <el-button slot="reference" size="mini" type="primary" plain
                ><i class="el-icon-view"></i> 二维码</el-button
              >
            </el-popover>
          </div>
        </el-table-column>
      </el-table>
      <div class="page pull-right">
        <el-pagination
          :current-page="currentPage"
          :page-size="pageSize"
          :page-sizes="[10, 20, 50, 100, 200, 300]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { CORE_API } from "@/constants/constants.js";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import { mapState } from "vuex";

export default {
  name: "School",
  components: {
    qrcode: VueQrcode,
  },
  data() {
    return {
      formSearch: {
        code: "",
        name: "",
        domainName: "",
      },
      schoolForm: {
        id: null,
        name: "",
        code: "",
        domainName: "",
        contacts: "",
        telephone: "",
        enable: "true",
        remark: "",
      },
      schoolDialog: false,
      logoDialog: false,
      curSchool: {
        name: null,
        code: null,
      },
      uploadAction: "",
      uploadHeaders: {},
      uploadData: {},
      fileList: [],
      selectedOrgIds: [],
      loading: true,
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 10,
      imp: "",
      rules: {
        code: [{ required: true, message: "请输入学校代码", trigger: "blur" }],
        domainName: [
          { required: true, message: "请输入学校域名", trigger: "blur" },
        ],
        name: [{ required: true, message: "请输入学校名称", trigger: "blur" }],
        contacts: [
          { required: true, message: "请输入负责人", trigger: "blur" },
        ],
        telephone: [
          { required: true, message: "请输入联系方式", trigger: "blur" },
        ],
        enable: [{ required: true, message: "请选择状态", trigger: "change" }],
      },
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
    noBatchSelected() {
      return this.selectedOrgIds.length === 0;
    },
    orgIds() {
      var orgIds = "";
      for (let orgId of this.selectedOrgIds) {
        if (!orgIds) {
          orgIds += orgId;
        } else {
          orgIds += "," + orgId;
        }
      }
      return orgIds;
    },
  },
  //初始化查询
  created() {
    this.searchForm();

    this.uploadHeaders = {
      key: this.user.key,
      token: this.user.token,
    };
  },
  methods: {
    resetSearchForm() {
      this.formSearch.code = "";
      this.formSearch.name = "";
      this.formSearch.domainName = "";
    },
    dialogBeforeClose() {
      this.$refs.schoolForm.clearValidate();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.searchForm();
    },
    getTag(status) {
      if (status == true) {
        return "success";
      } else if (status == false) {
        return "danger";
      }
      return status;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.searchForm();
    },
    handleSearchBtn() {
      this.currentPage = 1;
      this.searchForm();
    },
    //查询
    searchForm() {
      let searchLock = true;
      setTimeout(() => {
        if (searchLock) {
          this.loading = true;
        }
      }, 500);
      var param = new URLSearchParams(this.formSearch);
      var url =
        CORE_API +
        "/org/rootOrgPage/" +
        (this.currentPage - 1) +
        "/" +
        this.pageSize +
        "?" +
        param;
      this.$httpWithMsg
        .get(url)
        .then((response) => {
          this.tableData = response.data.list;
          this.total = response.data.total;
          this.loading = false;
        })
        .finally(() => {
          searchLock = false;
          this.loading = false;
        });
    },
    //启用
    enableOrg() {
      if (this.selectedOrgIds.length === 0) {
        this.$notify({
          type: "warning",
          message: "请选择要启用的机构",
        });
      } else {
        this.$confirm("是否启用这些机构？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          var url = CORE_API + "/org/enable/" + this.orgIds;
          this.$httpWithMsg.put(url).then(() => {
            this.$notify({
              type: "success",
              message: "启用成功！",
            });
            this.searchForm();
          });
        });
      }
    },
    disableOrg() {
      if (this.selectedOrgIds.length === 0) {
        this.$notify({
          type: "warning",
          message: "请选择要禁用的机构",
        });
      } else {
        this.$confirm("是否禁用这些机构？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          var url = CORE_API + "/org/disable/" + this.orgIds;
          this.$httpWithMsg.put(url, {}).then(() => {
            this.$notify({
              type: "success",
              message: "禁用成功！",
            });
            this.searchForm();
          });
        });
      }
    },
    selectChange(row) {
      this.selectedOrgIds = [];
      row.forEach((element) => {
        this.selectedOrgIds.push(element.id);
      });
      console.log(this.selectedOrgIds);
    },
    //重置
    resetForm() {
      this.$refs.schoolForm.resetFields();
    },
    //提交
    submitForm() {
      this.$refs.schoolForm.validate((valid) => {
        if (valid) {
          if (null != this.schoolForm.id) {
            //修改
            url = CORE_API + "/org/updateRootOrg";
            this.$httpWithMsg.put(url, this.schoolForm).then(() => {
              this.$notify({
                type: "success",
                message: "修改成功！",
              });
              this.searchForm();
              this.schoolDialog = false;
            });
          } else {
            //新增
            var url = CORE_API + "/org/addRootOrg";
            this.$httpWithMsg.post(url, this.schoolForm).then(() => {
              this.$notify({
                type: "success",
                message: "新增成功！",
              });
              this.searchForm();
              this.schoolDialog = false;
            });
          }
        } else {
          console.log("error submit!");
          return false;
        }
      });
    },
    //新增
    insert() {
      this.schoolForm.id = null;
      this.schoolForm.name = "";
      this.schoolForm.code = "";
      this.schoolForm.domainName = "";
      this.schoolForm.telephone = "";
      this.schoolForm.contacts = "";
      this.schoolForm.enable = "true";

      this.schoolDialog = true;
    },
    //修改
    edit(row) {
      this.schoolForm.id = row.id;
      this.schoolForm.name = row.name;
      this.schoolForm.code = row.code;
      this.schoolForm.domainName = row.domainName;
      this.schoolForm.telephone = row.telephone;
      this.schoolForm.contacts = row.contacts;
      this.schoolForm.enable = row.enable ? "true" : "false";

      this.schoolDialog = true;
    },
    qrcodeContent(row) {
      let str = row.id + "@" + row.name;
      let base64Str = new Buffer(str).toString("base64");
      // console.log(base64Str);
      return base64Str;
    },
  },
};
</script>

<style scoped>
.page {
  margin-top: 10px;
}

.el-upload {
  width: 80px;
}

.editSchoolForm .pull_length {
  width: 200px;
}
</style>
