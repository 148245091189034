export const LEVEL_TYPE = [
  { label: "专升本", value: "ZSB" },
  { label: "高起专", value: "GQZ" },
  { label: "高起本", value: "GQB" },
  { label: "不限", value: "ALL" },
];
export const ENABLE_TYPE = [
  { label: "启用", value: true },
  { label: "禁用", value: false },
];
export const ROLE_TYPE = [
  { code: "ECS_QUES_INPUTER", name: "题库录入员" },
  { code: "ECS_QUES_AUDITER", name: "题库审核员" },
  { code: "ECS_QUES_GENER", name: "题库组卷员" },
  { code: "ECS_QUES_ADMIN", name: "题库管理员" },
  { code: "ECS_MARKING_MARKER", name: "阅卷评卷员" },
  { code: "ECS_MARKING_ADMIN", name: "阅卷管理员" },
  { code: "ECS_OE_STUDENT", name: "网考考生" },
  { code: "ECS_OE_ADMIN", name: "网考管理员" },
];
export const PERMISSION_TYPE = { MENU: "MENU", PAGE: "PAGE" };
