<template>
  <div></div>
</template>

<script>
import { mapActions } from "vuex";
import { USER_SIGNIN } from "../store/user";
import { CORE_API } from "@/constants/constants";

export default {
  name: "Access",
  data() {
    return {
      accessInfo: {
        orgId: null,
        loginName: null,
        appId: null,
        timestamp: null,
        token: null,
      },
    };
  },
  created() {
    sessionStorage.clear();
    var params = this.$route.query;

    this.accessInfo.orgId = params.orgId;
    this.accessInfo.loginName = params.loginName;
    this.accessInfo.appId = params.appId;
    this.accessInfo.timestamp = params.timestamp;
    this.accessInfo.token = params.token;

    const url = CORE_API + "/auth/thirdPartyAccess";
    this.$httpWithMsg
      .post(url, new URLSearchParams(this.accessInfo), {
        headers: { "content-type": "application/x-www-form-urlencoded" },
      })
      .then((response) => {
        var user = response.data;
        this.USER_SIGNIN(user);
        this.$router.replace({ path: "/home/overview" });
        this.$notify({
          message: "接入成功",
          type: "success",
        });
      })
      .catch(() => {
        this.$notify({
          message: "接入失败",
          type: "error",
        });
      });
  },
  methods: {
    ...mapActions([USER_SIGNIN]),
  },
};
</script>
