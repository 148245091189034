<template>
  <span>
    <el-button
      v-show="!exportLoading"
      type="primary"
      size="small"
      icon="el-icon-download"
      @click="exportData"
      >导出
    </el-button>
    <el-button
      v-show="exportLoading"
      size="small"
      icon="el-icon-download"
      :loading="true"
      >导出数据中...
    </el-button>
  </span>
</template>
<script>
import { mapState } from "vuex";
export default {
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    exportUrl: {
      type: String,
      default: "",
    },
    exportFileName: {
      type: String,
      default: "",
    },
  },
  data() {
    return { exportLoading: false };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  methods: {
    exportData() {
      var key = this.user.key;
      var token = this.user.token;
      if (!this.form.examId) {
        this.$notify({
          title: "警告",
          message: "请选择考试",
          type: "warning",
          duration: 1000,
        });
        return false;
      }
      this.$confirm("确定执行导出?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.exportLoading = true;
        this.$http
          .get(this.exportUrl + "?$key=" + key + "&$token=" + token, {
            params: {
              query: this.form,
            },
            responseType: "arraybuffer",
            timeout: 20 * 60 * 1000, //限时20分钟
          })
          .then((response) => {
            if (response.data) {
              var blob = new Blob([response.data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              });
              var url = URL.createObjectURL(blob);
              var a = document.createElement("a");
              a.href = url;
              a.download = this.exportFileName + ".xlsx";
              a.target = "_blank";
              a.click();
              URL.revokeObjectURL(url);
            }
            this.exportLoading = false;
          });
      });
    },
  },
};
</script>
