import Home from "../../portal/views/home/Home.vue";
import PaperStructure from "../views/PaperStructure.vue";
import InsertPaperStructure from "../views/InsertPaperStructure.vue";
import InsertPaperStructureInfo from "../views/InsertPaperStructureInfo.vue";
import BluePaperStructure from "../views/BluePaperStructure.vue";
import InsertBluePaperStructure from "../views/InsertBluePaperStructure.vue";
import InsertBluePaperStructureInfo from "../views/InsertBluePaperStructureInfo.vue";
import CourseProperty from "../views/CourseProperty.vue";
import PropertyInfo from "../views/PropertyInfo.vue";
import ImportPaper from "../views/ImportPaper.vue";
import GenPaper from "../views/GenPaper.vue";
import ImportPaperInfo from "../views/ImportPaperInfo.vue";
import GenPaperDetail from "../views/GenPaperDetail.vue";
import ExtractPaperRule from "../views/ExtractPaperRule.vue";
import ExtractPaperInfo from "../views/ExtractPaperInfo.vue";
import ExportStructure from "../views/ExportStructure.vue";
import Question from "../views/Question.vue";
import EditSelectQuestion from "../views/EditSelectQuestion.vue";
import EditOtherQuestion from "../views/EditOtherQuestion.vue";
import InsertPaperTitle from "../views/InsertPaperTitle.vue";
import EditPaper from "../views/EditPaper.vue";
import PreviewPaper from "../views/PreviewPaper.vue";
import SelectQuestion from "../views/SelectQuestion.vue";
import Tips from "../../portal/views/tips/Tips.vue";
import ExportTemplate from "../views/ExportTemplate.vue";
import PaperStorage from "../views/PaperStorage.vue";
import ViewPaper from "../views/ViewPaper.vue";
import AddPaperSelect from "../views/AddPaperSelect.vue";
import ExtractPaperTemplate from "../views/ExtractPaperTemplate.vue";

export default [
  {
    path: "/questions", //首页
    meta: { auth: false },
    component: Home,
    children: [
      {
        path: "tips", //模块说明
        component: Tips,
      },
      {
        path: "paper_structure/:isClear", //精确试卷结构列表
        component: PaperStructure,
      },
      {
        path: "insert_paper_structure/:id", //精确试卷结构（新增/修改）
        component: InsertPaperStructure,
      },
      {
        path: "insert_paper_structure_info/:id/:name/:detailId/:courseNo", //精确试卷结构添加题型结构
        component: InsertPaperStructureInfo,
      },
      {
        path: "blue_paper_structure/:isClear", //蓝图试卷结构列表
        component: BluePaperStructure,
      },
      {
        path: "insert_blue_paper_structure/:id", //蓝图试卷结构（新增/修改）
        component: InsertBluePaperStructure,
      },
      {
        path: "insert_blue_paper_structure_info/:id/:paperStructId", //蓝图试卷结构添加题型结构
        component: InsertBluePaperStructureInfo,
      },
      {
        path: "course_property/:isClear", //课程属性列表
        component: CourseProperty,
      },
      {
        path: "property_info/:id", //课程属性
        component: PropertyInfo,
      },
      {
        path: "import_paper/:isClear", //题库试卷列表
        component: ImportPaper,
      },
      {
        path: "gen_paper/:isClear", //卷库试卷列表
        component: GenPaper,
      },
      {
        path: "paper_storage/:isClear", //卷库试卷列表
        component: PaperStorage,
      },

      {
        path: "import_paper_info", //导入试卷页面
        component: ImportPaperInfo,
      },
      {
        path: "gen_paper_detail/:courseNo", //组卷页面
        component: GenPaperDetail,
      },
      {
        path: "extract_paper_rule/:isClear", //调卷规则列表
        component: ExtractPaperRule,
      },
      {
        path: "extract_paper_info_add", //调卷规则——新增
        name: "extract_paper_info_add",
        component: ExtractPaperInfo,
      },
      {
        path: "extract_paper_info_edit/:extractConfigId", //调卷规则——修改
        name: "extract_paper_info_edit",
        component: ExtractPaperInfo,
      },
      {
        path: "export_structure", //导出结构列表
        component: ExportStructure,
      },
      {
        path: "export_template", //导出模板设定
        component: ExportTemplate,
      },
      {
        path: "question_list/:isClear", //试题列表
        component: Question,
      },
      {
        path: "edit_select_question/:id", //编辑选择题(单选、多选)
        component: EditSelectQuestion,
      },
      {
        path: "edit_other_question/:id", //编辑其他题（填空、问答）
        component: EditOtherQuestion,
      },
      {
        path: "insert_paper_title", //添加试卷
        component: InsertPaperTitle,
      },
      {
        path: "edit_other_question/:paperId/:paperDetailId/:questionType/:courseNo/:courseName",
        component: EditOtherQuestion,
      },
      {
        path: "edit_select_question/:paperId/:paperDetailId/:questionType/:courseNo/:courseName",
        component: EditSelectQuestion,
      },
      {
        path: "add_paper_select",
        component: AddPaperSelect,
      },
      {
        path: "add_paper_select/:id",
        component: AddPaperSelect,
      },
      {
        path: "extract_paper_template",
        component: ExtractPaperTemplate,
      },
    ],
  },
  {
    path: "/edit_paper/:id/:parentView", //试卷编辑
    component: EditPaper,
  },
  {
    path: "/select_question/:id/:courseNo/:courseName/:paperDetailId/:parentView", //试卷选题
    component: SelectQuestion,
  },
  {
    path: "/preview_paper/:paperId", //预览试卷
    component: PreviewPaper,
  },
  {
    path: "/view_paper/:id", //试卷查看
    component: ViewPaper,
  },
];
