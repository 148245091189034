<template>
  <el-select
    v-model="selected"
    class="size-select"
    placeholder="请选择"
    :style="styles"
    clearable
    @change="select"
  >
    <el-option
      v-for="item in optionList"
      :key="item.code"
      :label="item.name"
      :value="item.code"
    >
      <span>{{ item.name }}</span>
    </el-option>
  </el-select>
</template>

<script>
import { EXAM_TYPE_SELECT } from "@/constants/constants";
export default {
  name: "ExamTypeSelect",
  props: {
    value: {
      type: String,
      default: "",
    },
    options: { type: Array, default: () => null },
    styles: { type: String, default: "" },
  },
  data() {
    return {
      optionList: this.options || EXAM_TYPE_SELECT,
      selected: "",
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.selected = val;
      },
    },
  },
  async created() {},
  methods: {
    select() {
      this.$emit("input", this.selected);
      this.$emit("change", this.selected);
    },
  },
};
</script>

<style></style>
