<template>
  <el-select
    v-model="selected"
    class="size-select"
    placeholder="请选择"
    :style="styles"
    filterable
    remote
    :remote-method="search"
    clearable
    :disabled="disabled"
    @change="select"
  >
    <el-option
      v-for="item in optionList"
      :key="item.id"
      :label="item.name"
      :value="item.id"
    >
      <span>{{ item.name }}</span>
    </el-option>
  </el-select>
</template>

<script>
import { CORE_API } from "@/constants/constants";
export default {
  name: "SchoolSelectForRoot",
  props: {
    value: { type: [String, Number], default: "" },
    styles: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      optionList: [],
      selected: "",
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.selected = val;
      },
    },
  },
  async created() {
    this.search();
  },
  methods: {
    async search(query) {
      const url1 = CORE_API + "/org/getRootOrgList";
      const res = await this.$httpWithMsg.get(url1);
      this.optionList = res?.data.map((v) => {
        return { id: v.id, name: `${v.name}(${v.id})` };
      });
      const all = this.optionList;
      if (query) {
        this.optionList = this.optionList.filter((v) => v.name.includes(query));
      } else {
        this.optionList = all;
      }
      // console.log(res, this.optionList);
    },
    select() {
      this.$emit("input", this.selected);
      this.$emit("change", this.selected);
    },
  },
};
</script>

<style></style>
