<template>
  <div>
    <section class="content">
      <div class="box box-info">
        <div class="box-body">
          <el-form
            ref="formSearch"
            :inline="true"
            :model="formSearch"
            label-width="70px"
          >
            <el-form-item label="评卷名称" prop="workId">
              <el-select
                v-model="formSearch.workId"
                clearable
                filterable
                :remote-method="getMarkWorks"
                remote
                class="input"
                placeholder="请选择"
                style="width: 150px"
                @change="changeMarkWork"
                @clear="resetForm"
              >
                <el-option
                  v-for="item in markWorkSelect"
                  :key="item.workId"
                  :label="item.markName"
                  :value="item.workId"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="学习中心" prop="orgCode">
              <el-select
                v-model="formSearch.orgCode"
                :remote-method="getOrgs"
                remote
                :loading="getOrgsSearchLoading"
                class="input"
                clearable
                placeholder="请选择"
                filterable
                style="width: 150px"
                @focus="(e) => getOrgs(e.target.value)"
              >
                <el-option
                  v-for="item in orgList"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="姓名" prop="studentName">
              <el-input
                v-model="formSearch.studentName"
                placeholder="请输入姓名"
                style="width: 150px"
              ></el-input>
            </el-form-item>
            <el-form-item label="学号" prop="studentCode">
              <el-input
                v-model="formSearch.studentCode"
                placeholder="请输入学号"
                style="width: 150px"
              ></el-input>
            </el-form-item>
            <el-form-item label="标记卷" prop="tag">
              <el-select
                v-model="formSearch.tag"
                clearable
                placeholder="请选择"
                filterable
                style="width: 150px"
              >
                <el-option
                  v-for="item in tags"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="课程" prop="courseCode">
              <el-select
                v-model="formSearch.courseCode"
                clearable
                filterable
                class="input"
                placeholder="请选择"
                style="width: 150px"
              >
                <el-option
                  v-for="item in courseAllListSelect"
                  :key="item.code"
                  :label="item.courseInfo"
                  :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="评委" prop="userId">
              <el-select
                v-model="formSearch.userId"
                clearable
                filterable
                class="input"
                placeholder="请选择"
                style="width: 150px"
              >
                <el-option
                  v-for="item in userList"
                  :key="item.userId"
                  :label="item.name"
                  :value="item.userId"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="评卷备注" prop="markRemark">
              <el-input
                v-model="formSearch.markRemark"
                placeholder="请输入备注"
                style="width: 150px"
              ></el-input>
            </el-form-item>
            <el-form-item label="试卷ID" prop="examRecordDataId">
              <el-input
                v-model="formSearch.examRecordDataId"
                placeholder="请输入试卷ID"
                style="width: 150px"
                maxlength="18"
              ></el-input>
            </el-form-item>
            <el-form-item label="身份证号" prop="identityNumber">
              <el-input
                v-model="formSearch.identityNumber"
                placeholder="请输入身份证号"
                style="width: 150px"
              ></el-input>
            </el-form-item>
            <el-form-item label="总分">
              <el-input
                v-model="formSearch.scoreStart"
                style="width: 80px"
              ></el-input>
              -
              <el-input
                v-model="formSearch.scoreEnd"
                style="width: 80px"
              ></el-input>
            </el-form-item>
            <div></div>
            <el-form-item>
              <el-button
                size="small"
                type="primary"
                icon="el-icon-search"
                @click="searchMarkPaperCheck"
                >查询</el-button
              >
              <el-button
                size="small"
                icon="el-icon-refresh"
                @click="resetForm()"
                >重置</el-button
              >
            </el-form-item>
          </el-form>

          <div class="block-seperator"></div>
          <span>操作：</span>
          <el-button
            type="danger"
            icon="el-icon-arrow-left"
            size="mini"
            :disabled="noBatchSelected"
            @click="bachBackRefresh"
            >打回
          </el-button>
          <el-button
            type="primary"
            size="mini"
            icon="el-icon-download"
            @click="exportData"
            >导出</el-button
          >
          <div style="width: 100%; margin-bottom: 10px"></div>
          <!-- 页面列表 -->
          <el-table
            v-loading="loading"
            stripe
            element-loading-text="拼命加载中"
            :data="tableData"
            border
            style="width: 100%"
            @selection-change="selectChange"
          >
            <el-table-column type="selection" width="50"></el-table-column>
            <el-table-column
              label="试卷ID"
              width="150"
              prop="examRecordDataId"
            />
            <el-table-column
              label="身份证号"
              width="150"
              prop="identityNumber"
            />
            <el-table-column label="学习中心" width="150" prop="orgName" />
            <el-table-column
              label="学号"
              width="120"
              prop="studentCode"
              sortable
            />
            <el-table-column label="姓名" width="120" prop="studentName" />
            <el-table-column
              label="课程代码"
              width="120"
              prop="courseCode"
              sortable
            />
            <el-table-column label="课程" width="120" prop="courseName" />
            <el-table-column label="试卷名称" width="120" prop="paperType" />
            <el-table-column label="评卷员" width="120" prop="userName" />
            <el-table-column label="主观总分" width="120" prop="score" />
            <el-table-column
              label="客观总分"
              width="120"
              prop="objectiveScore"
              sortable
            />
            <el-table-column
              label="卷面总分"
              width="120"
              prop="totalScore"
              sortable
            />
            <el-table-column label="标记卷" width="120">
              <template slot-scope="scope">
                <div>
                  <span>{{ scope.row.tag | tagFilter }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="是否违纪" width="100">
              <template slot-scope="scope">
                <div>
                  <span>{{ scope.row.illegality == true ? "是" : "否" }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="评卷备注" width="120">
              <template slot-scope="scope">
                <el-popover trigger="hover" name="text" placement="top">
                  <div style="width: 200px">{{ scope.row.markRemark }}</div>
                  <div slot="reference" class="interceptStr">
                    <span>{{ scope.row.markRemark }}</span>
                  </div>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="300">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="mini"
                  icon="el-icon-view"
                  plain
                  @click="viewPaper(scope.row)"
                  >原卷</el-button
                >
                <el-button
                  type="danger"
                  icon="el-icon-arrow-left"
                  size="mini"
                  @click="backRefresh(scope.row)"
                  >打回</el-button
                >
                <el-button
                  v-if="scope.row.examFaceEnable == true"
                  type="danger"
                  icon="el-icon-arrow-left"
                  size="mini"
                  @click="openAudit(scope.row.examRecordDataId)"
                  >违纪审核
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="page pull-right">
            <el-pagination
              v-if="paginationShow"
              :current-page="currentPage"
              :page-size="pageSize"
              :page-sizes="[10, 20, 50, 100, 200, 300]"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              @current-change="handleCurrentChange"
              @size-change="handleSizeChange"
            ></el-pagination>
          </div>
        </div>
      </div>
    </section>

    <el-dialog
      title="预览"
      width="400px"
      :visible.sync="imageDialog"
      @close="closeImageDialog"
    >
      <div
        style="text-align: center; width: 90%; height: 90%"
        v-html="imgHtml"
      ></div>
    </el-dialog>

    <el-dialog
      title="违纪审核"
      width="400px"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      @closed="auditDialogClosed"
    >
      <el-form ref="auditForm" :model="auditForm" label-width="90px">
        <el-form-item
          label="违纪类型"
          prop="illegallyTypeId"
          :rules="[
            { required: true, message: '请选择违纪类型', trigger: 'change' },
          ]"
        >
          <el-select
            v-model="auditForm.illegallyTypeId"
            filterable
            remote
            :remote-method="getDisciplineTypeList"
            clearable
            placeholder="请选择"
            size="small"
            @clear="getDisciplineTypeList"
          >
            <el-option
              v-for="item in disciplineTypeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="详情描述" style="margin-top: 15px">
          <el-input
            v-model="auditForm.disciplineDetail"
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 5 }"
            placeholder="请输入内容"
            style="width: 215px"
            maxlength="100"
          ></el-input>
        </el-form-item>
        <div class="dialog-footer margin-top-10 text-center">
          <el-button type="primary" @click="doAudit">确 定</el-button>
          <el-button @click="dialogFormVisible = false">取 消</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  CORE_API,
  MARKING_API,
  MARKING_LOGIC_API,
} from "@/constants/constants";
import "viewerjs/dist/viewer.css";
import Viewer from "viewerjs";

export default {
  data() {
    return {
      selectedIds: [],
      formSearch: {
        orgCode: "",
        studentName: "",
        studentCode: "",
        tag: "",
        courseCode: "",
        userId: "",
        workId: "",
        markRemark: "",
        examRecordDataId: "",
        identityNumber: "",
        scoreStart: "",
        scoreEnd: "",
      },
      auditForm: {
        examRecordDataIds: [],
        illegallyTypeId: null,
        disciplineDetail: "",
        isPass: false,
      },
      orgList: [],
      courseList: [],
      specialtyList: [],
      userList: [],
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      paginationShow: false,
      tags: [],
      loading: false,
      getOrgsSearchLoading: false,
      markWorkList: [],
      imgHtml: "",
      imageDialog: false,
      disciplineTypeList: [],
      dialogFormVisible: false,
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
    markWorkSelect() {
      let markWorkNames = [];
      for (let markWork of this.markWorkList) {
        markWorkNames.push({
          workId: markWork.id,
          markName: markWork.name,
        });
      }
      return markWorkNames;
    },
    courseAllListSelect() {
      let courseSelect = [];
      for (let course of this.courseList) {
        let courseInfo = course.name + "(" + course.code + ")";
        courseSelect.push({ code: course.code, courseInfo: courseInfo });
      }
      return courseSelect;
    },
    noBatchSelected() {
      return this.selectedIds.length === 0;
    },
  },
  created() {
    //查询标记卷
    this.getDisciplineTypeList("");
    this.getTags();
    this.getMarkWorks();
    this.operaQuery();
  },
  mounted() {
    window.viewPicture = this.viewPicture;
  },
  methods: {
    auditDialogClosed() {
      this.$refs["auditForm"].resetFields();
    },
    openAudit(dataId) {
      var dataIds = [];
      dataIds.push(dataId);
      this.dialogFormVisible = true;
      this.auditForm = {
        examRecordDataIds: dataIds,
        illegallyTypeId: null,
        disciplineDetail: "",
        isPass: false,
        fromMarking: true,
      };
    },
    doAudit() {
      this.$refs["auditForm"].validate((valid) => {
        if (valid) {
          this.$confirm("确定执行?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            this.$httpWithMsg
              .post("/api/ecs_oe_admin/exam/audit/redoAudit", this.auditForm)
              .then(() => {
                this.$notify({
                  title: "成功",
                  message: "操作成功",
                  type: "success",
                  duration: 5000,
                });
                this.dialogFormVisible = false;
                this.searchMarkPaperCheck();
              });
          });
        } else {
          return false;
        }
      });
    },
    getDisciplineTypeList(name) {
      if (!name) {
        name = "";
      }
      this.$http
        .get("/api/ecs_oe_admin/illegallyType/queryByNameLike", {
          params: { name, queryScope: "audit" },
        })
        .then((response) => {
          this.disciplineTypeList = response.data;
        });
    },
    exportData() {
      if (!this.formSearch.workId) {
        this.$notify({
          title: "警告",
          message: "请选择评卷工作",
          type: "warning",
          duration: 1000,
        });
        return false;
      }
      let regx = /^\d*$/;
      if (!regx.test(this.formSearch.examRecordDataId)) {
        this.$notify({
          title: "警告",
          message: "试卷ID只能输入数字",
          type: "warning",
        });
        return false;
      }
      this.$confirm("确定执行导出?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http
          .get(MARKING_API + "/export/task/paper_check", {
            params: {
              query: this.formSearch,
            },
          })
          .then(() => {
            this.$notify({
              type: "success",
              message: "正在后台导出中，请稍后到“导出任务列表”中下载！",
            });
          })
          .catch((error) => {
            this.$notify({
              type: "error",
              message: error.response.data.desc,
            });
          });
      });
    },
    selectChange(rows) {
      this.selectedIds = [];
      this.auditForm.examRecordDataIds = [];
      rows.forEach((element) => {
        this.selectedIds.push(element.id);
        this.auditForm.examRecordDataIds.push(element.examRecordDataId);
      });
    },
    viewPicture(imagesClass, index) {
      const viewer = new Viewer(document.querySelector(imagesClass), {
        container: "#app",
        zIndex: 99999,
        title: false,
        toolbar: {
          zoomIn: 1,
          zoomOut: 1,
          oneToOne: 1,
          reset: 1,
          prev: 1,
          play: {
            show: 0,
            size: "large",
          },
          next: 1,
          rotateLeft: 1,
          rotateRight: 1,
          flipHorizontal: 1,
          flipVertical: 1,
        },
        ready() {
          viewer.view(index);
        },
        hidden() {
          viewer.destroy();
        },
      });
      viewer.show();
    },
    changeMarkWork() {
      if (this.$route.query) {
        this.$router.push({ path: "/marking/mark_paper_check" });
      }

      this.getCourses();
      this.getUserList();

      this.formSearch = Object.assign(this.formSearch, {
        orgCode: "",
        studentName: "",
        studentCode: "",
        tag: "",
        courseCode: "",
        userId: "",
        markRemark: "",
        examRecordDataId: "",
        identityNumber: "",
      });
    },
    getMarkWorks(name = "") {
      this.$http
        .get(MARKING_LOGIC_API + "/markWorks?status=1" + `&name=${name}`)
        .then((response) => {
          this.markWorkList = response.data?.content || [];
          if (this.$route.query && this.$route.query.workId) {
            this.formSearch.workId = parseInt(this.$route.query.workId);
          }
        });
    },
    //查询学习中心
    getOrgs(name) {
      this.getOrgsSearchLoading = true;
      this.$http.get(CORE_API + "/org/query?name=" + name).then((response) => {
        this.getOrgsSearchLoading = false;
        this.orgList = response.data;
      });
    },
    //查询课程
    getCourses() {
      this.$http
        .get(
          MARKING_API +
            "/markResults/queryExamCourseList?workId=" +
            this.formSearch.workId
        )
        .then((response) => {
          this.courseList = response.data;
          if (this.$route.query && this.$route.query.courseCode) {
            this.formSearch.courseCode = this.$route.query.courseCode;
          }
        });
    },
    //查询评委
    getUserList() {
      if (!this.formSearch.workId) {
        this.userList = [];
        return;
      }

      this.$http
        .get(
          MARKING_LOGIC_API +
            "/markUsers/assign?workId=" +
            this.formSearch.workId +
            "&courseCode="
        )
        .then((response) => {
          this.userList = response.data;
          if (this.$route.query && this.$route.query.userId) {
            this.formSearch.userId = parseInt(this.$route.query.userId);
          }
        });
    },
    //查询标记卷
    getTags() {
      this.$http.get(MARKING_API + "/markResults/tag").then((response) => {
        this.tags = response.data;
        //移除科目错误
        for (let i = 0; i < this.tags.length; i++) {
          if (this.tags[i].code === "SUBJECT_ERROR") {
            this.tags.splice(i, 1);
          }
        }
      });
    },
    //查询专业
    getSpecialtys() {
      var orgId = this.user.rootOrgId;
      this.$http
        .get(CORE_API + "/specialty/all?orgId=" + orgId)
        .then((response) => {
          this.specialtyList = response.data;
        });
    },
    //查询方法
    searchMarkPaperCheck() {
      this.currentPage = 1;
      this.initMarkPaperCheck();
    },
    initMarkPaperCheck() {
      if (!this.formSearch.workId) {
        this.$notify({
          title: "警告",
          message: "请选择评卷名称",
          type: "warning",
        });
        return false;
      }
      let regx = /^\d*$/;
      if (!regx.test(this.formSearch.examRecordDataId)) {
        this.$notify({
          title: "警告",
          message: "试卷ID只能输入数字",
          type: "warning",
        });
        return false;
      }
      regx = /(^\d+$)|(^\d+.\d+$)/;
      if (this.formSearch.scoreStart) {
        if (!regx.test(this.formSearch.scoreStart)) {
          this.$notify({
            title: "警告",
            message: "分数输入有误",
            type: "warning",
          });
          return false;
        }
      }
      if (this.formSearch.scoreEnd) {
        if (!regx.test(this.formSearch.scoreEnd)) {
          this.$notify({
            title: "警告",
            message: "分数输入有误",
            type: "warning",
          });
          return false;
        }
      }
      this.loading = true;
      var url =
        MARKING_LOGIC_API +
        "/markResults/all/" +
        (this.currentPage - 1) +
        "/" +
        this.pageSize;
      this.$http.get(url, { params: this.formSearch }).then((response) => {
        this.tableData = response.data.list;
        this.total = response.data.total;
        this.loading = false;
        this.$router.push({
          path: "/marking/mark_paper_check",
          query: {
            workId: this.formSearch.workId,
            orgCode: this.formSearch.orgCode,
            studentName: this.formSearch.studentName,
            studentCode: this.formSearch.studentCode,
            tag: this.formSearch.tag,
            courseCode: this.formSearch.courseCode,
            userId: this.formSearch.userId,
            markRemark: this.formSearch.markRemark,
            currentPage: this.currentPage,
            pageSize: this.pageSize,
            examRecordDataId: this.formSearch.examRecordDataId,
            identityNumber: this.formSearch.identityNumber,
          },
        });
        this.$nextTick(function () {
          this.paginationShow = true;
        });
      });
    },
    resetForm() {
      this.formSearch = Object.assign(this.formSearch, {
        orgCode: "",
        studentName: "",
        studentCode: "",
        tag: "",
        courseCode: "",
        userId: "",
        workId: "",
        markRemark: "",
        examRecordDataId: "",
        identityNumber: "",
        scoreStart: "",
        scoreEnd: "",
      });
      this.tableData = [];
      this.total = 0;
      this.$router.push({ path: "/marking/mark_paper_check" });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.initMarkPaperCheck();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.initMarkPaperCheck();
    },
    //查看原卷
    viewPaper(row) {
      if (row.examType == "OFFLINE") {
        let url = row.studentSubjectiveHtml;
        if (/(.*)\.(jpg|gif|jpeg|png)$/.test(url)) {
          this.imgHtml = this.parseImgs(url);
          this.imageDialog = true;
        } else {
          window.open(url);
        }
      } else {
        let studentPaperId = row.studentPaper.id;
        let url =
          "/marking/view_paper/" +
          this.formSearch.workId +
          "/" +
          studentPaperId;
        this.$router.push({
          path: url,
        });
      }
    },
    closeImageDialog() {
      this.imageDialog = false;
      this.imgHtml = "";
    },
    parseImgs(urls) {
      let uuid = this.generateUUID();
      let group1 = [];
      let group2 = [];

      let elements = urls.split(",");
      for (let n = 0; n < elements.length; n++) {
        let url = elements[n];

        group1.push(
          "<a onclick=\"window.viewPicture('#pic-" +
            uuid +
            "','" +
            n +
            '\')" target="_blank"><img class="photo-answer" src="' +
            url +
            '?x-oss-process=image/resize,m_lfit,h_200,w_200"/></a>'
        );

        if ((n + 1) % 3 == 0) {
          group1.push("</br>");
        }

        group2.push('<img src="' + url + '"/>');
      }

      let html =
        '<div class="photo-answers-block">' +
        group1.join("") +
        '</div><div id="pic-' +
        uuid +
        '" style="display:none">' +
        group2.join("") +
        "</div>";
      return html;
    },
    generateUUID() {
      let date = new Date().getTime();
      return "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        let r = (date + Math.random() * 16) % 16 | 0;
        date = Math.floor(date / 16);
        return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
      });
    },

    //批量打回
    bachBackRefresh() {
      this.$confirm("确定打回?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var url = MARKING_API + "/markResults/reject";
          this.$http.post(url, this.selectedIds).then(() => {
            this.searchMarkPaperCheck();
          });
        })
        .catch(() => {
          /*ignore*/
        });
    },
    //打回
    backRefresh(row) {
      this.$confirm("确定打回?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var url = MARKING_API + "/markResults/reject";
          var markResultIds = [row.id];
          this.$http.post(url, markResultIds).then(() => {
            this.searchMarkPaperCheck();
          });
        })
        .catch(() => {
          /*ignore*/
        });
    },
    operaQuery() {
      let formData = this.$route.query;
      if (formData && formData.workId) {
        this.formSearch.workId = formData.workId;
        this.formSearch.orgCode = formData.orgCode;
        this.formSearch.studentName = formData.studentName;
        this.formSearch.studentCode = formData.studentCode;
        this.formSearch.markRemark = formData.markRemark;
        this.formSearch.courseCode = formData.courseCode;
        this.formSearch.userId = formData.userId;
        this.formSearch.tag = formData.tag;
        this.formSearch.examRecordDataId = formData.examRecordDataId;
        this.formSearch.identityNumber = formData.identityNumber;
        this.currentPage = parseInt(formData.currentPage);
        this.pageSize = parseInt(formData.pageSize);
        this.getCourses();
        this.getUserList();
        this.initMarkPaperCheck();
      }
    },
  },
};
</script>
<style lang="css" scoped>
.interceptStr {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>

<style>
img.photo-answer {
  width: 100px;
  padding: 10px;
  height: 100px;
}
.photo-answers-block {
  width: 350px !important;
}
.photo-answers-block a {
  cursor: pointer;
}
</style>
