<template>
  <el-container>
    <el-main class="el-main-padding">
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="200px"
        class="demo-ruleForm"
        :inline-message="true"
      >
        <el-form-item v-if="isSuperAdmin" label="学校">
          <el-select
            v-model="ruleForm.orgId"
            placeholder="请选择"
            style="width: 180px"
            filterable
          >
            <el-option
              v-for="item in rootSchoolSelect"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="服务接口默认考试">
          <el-select
            v-model="ruleForm.THIRD_PARTY_API_DEFAULT_EXAM_ID"
            class="input"
            :remote-method="queryExams4Select"
            remote
            :loading="queryExams4ThirdPartyApiDefaultExamId"
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in examList4ThirdPartyApiDefaultExamId"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            :disabled="btnSaveDiabled"
            type="primary"
            @click="submitForm('ruleForm')"
            >保 存
          </el-button>
        </el-form-item>
      </el-form>
    </el-main>
  </el-container>
</template>
<script>
import { mapState } from "vuex";
import { CORE_API } from "@/constants/constants.js";
import { EXAM_WORK_API } from "@/constants/constants.js";

export default {
  data() {
    return {
      rootOrgList: [],
      propertyGroupId: "",
      formDataChanged: false,
      originalRuleForm: {},
      ruleForm: {
        relatedPropertyGroupIdList: [],
        orgId: null,
        THIRD_PARTY_API_DEFAULT_EXAM_ID: null,
        properties: {
          THIRD_PARTY_API_DEFAULT_EXAM_ID: null,
        },
      },
      queryExams4ThirdPartyApiDefaultExamId: false,
      examList4ThirdPartyApiDefaultExamId: [],
      rules: {},
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
    btnSaveDiabled() {
      console.log(this.formDataChanged);
      return !this.formDataChanged;
    },
    isSuperAdmin() {
      return this.user.roleList.some((role) => role.roleCode == "SUPER_ADMIN");
    },
    rootSchoolSelect() {
      let rootSchools = [];
      for (let i = 0; i < this.rootOrgList.length; i++) {
        let info = {
          name: this.rootOrgList[i].name + "（" + this.rootOrgList[i].id + "）",
          id: this.rootOrgList[i].id,
        };
        rootSchools.push(info);
      }
      return rootSchools;
    },
  },
  watch: {
    "ruleForm.orgId": {
      handler: function () {
        this.initForm();
      },
    },
    ruleForm: {
      deep: true,
      handler: function (newForm) {
        if (Object.keys(this.originalRuleForm).length > 0) {
          this.formDataChanged = !(
            newForm.THIRD_PARTY_API_DEFAULT_EXAM_ID ==
            this.originalRuleForm.THIRD_PARTY_API_DEFAULT_EXAM_ID
          );
        } else {
          this.formDataChanged = false;
        }
      },
    },
  },
  created() {
    this.ruleForm.orgId = this.user.rootOrgId;
    this.propertyGroupId = "config4Edit2";
    if (this.isSuperAdmin) {
      this.$httpWithMsg
        .get(CORE_API + "/org/getRootOrgList")
        .then((response) => {
          this.rootOrgList = response.data;
        });
    }
    this.initForm();
  },
  methods: {
    queryExams4Select(name) {
      console.log("queryExams; name: " + name);
      this.queryExams4ThirdPartyApiDefaultExamId = true;
      this.$httpWithMsg
        .get(
          EXAM_WORK_API +
            "/exam/queryByNameLike?enable=true&rootOrgId=" +
            this.ruleForm.orgId +
            "&name=" +
            name
        )
        .then((response) => {
          this.queryExams4ThirdPartyApiDefaultExamId = false;
          this.examList4ThirdPartyApiDefaultExamId = response.data;
        })
        .catch((response) => {
          console.log(response);
          this.queryExams4ThirdPartyApiDefaultExamId = false;
        });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ruleForm.properties.THIRD_PARTY_API_DEFAULT_EXAM_ID =
            this.ruleForm.THIRD_PARTY_API_DEFAULT_EXAM_ID;

          this.$httpWithMsg
            .put(CORE_API + "/org/saveOrgProperties", this.ruleForm)
            .then(
              () => {
                this.$notify({
                  message: "保存成功",
                  type: "success",
                });
                this.originalRuleForm = Object.assign({}, this.ruleForm);
                this.formDataChanged = false;
              },
              () => {}
            );
        } else {
          return false;
        }
      });
    },

    initForm() {
      this.ruleForm.relatedPropertyGroupIdList = ["studentClientConfig"];
      var url =
        CORE_API +
        "/org/getOrgPropertiesByGroupWithoutCache/" +
        this.ruleForm.orgId +
        "/" +
        this.propertyGroupId;
      this.$httpWithMsg.get(url).then((response) => {
        if (response) {
          let examId = response.data.THIRD_PARTY_API_DEFAULT_EXAM_ID;
          this.ruleForm.THIRD_PARTY_API_DEFAULT_EXAM_ID == examId;

          this.examList4ThirdPartyApiDefaultExamId = [];
          if (examId != null && examId != "") {
            this.ruleForm.THIRD_PARTY_API_DEFAULT_EXAM_ID = parseInt(examId);
            this.$httpWithMsg
              .get(EXAM_WORK_API + "/exam/" + examId)
              .then((response) => {
                this.examList4ThirdPartyApiDefaultExamId = [
                  { id: response.data.id, name: response.data.name },
                ];
              });
          }

          this.originalRuleForm = Object.assign({}, this.ruleForm);
        } else {
          this.$notify({
            message: "学校设置信息暂未初始化，请立即初始化",
            type: "warning",
          });
        }
      });
    },
  },
};
</script>
<style scoped>
.input-width {
  width: 638px;
}
</style>
