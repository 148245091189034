import Home from "../../portal/views/home/Home.vue";
import Tips from "../../portal/views/tips/Tips.vue";
import Marking from "../views/Marking.vue";
import MarkSettingMain from "../views/MarkSettingMain.vue";
import MarkWork from "../views/MarkWork.vue";
import MarkPaperCheck from "../views/MarkPaperCheck.vue";
import MarkSettingWork from "../views/MarkSettingWork.vue";
import MarkSettingFast from "../views/MarkSettingFast.vue";
import ViewPaper from "../views/ViewPaper.vue";
import MarkWorkOverview from "../views/MarkWorkOverview.vue";
import Marker from "../views/Marker.vue";
import CourseDetail from "../views/CourseDetail.vue";
import MarkerDetail from "../views/MarkerDetail.vue";
import ExportTaskList from "../views/export_task_list.vue";

export default [
  {
    path: "/marking", //首页
    meta: { auth: false },
    component: Home,
    children: [
      {
        path: "tips", //模块说明
        component: Tips,
      },
      {
        path: "mark_work_overview", //评卷进度
        meta: { privilegeCodes: "mark_process" },
        component: MarkWorkOverview,
      },
      {
        path: "course_detail/:workId/:markWorkName/:courseCode/:courseName", //课程详情
        component: CourseDetail,
      },
      {
        path: "marker", //评卷员一览
        meta: { privilegeCodes: "mark_people" },
        component: Marker,
      },
      {
        path: "marker_detail/:workId/:markerId/:userName", //评卷员详情
        component: MarkerDetail,
      },
      {
        path: "mark_setting_main/:workId/:name", //评卷设置主页面
        component: MarkSettingMain,
      },
      {
        path: "mark_setting_fast/:workId/:name/:courseCode/:courseName", //评卷快速设置主页面
        component: MarkSettingFast,
      },
      {
        path: "mark_work", //评卷工作
        meta: { privilegeCodes: "create_marking_task" },
        component: MarkWork,
      },
      {
        path: "mark_paper_check", //试卷检查
        meta: { privilegeCodes: "marking_check_menu2" },
        component: MarkPaperCheck,
      },
      {
        path: "mark_setting_work/:type", //选择评卷工作
        meta: { privilegeCodes: "marking" },
        component: MarkSettingWork,
      },
      {
        path: "view_paper/:workId/:studentPaperId", //查看原卷
        component: ViewPaper,
      },
      {
        path: "export_task_list",
        component: ExportTaskList, //导出任务列表
      },
    ],
  },
  {
    path: "/marking/:workId", //试卷正评
    component: Marking,
  },
  { path: "*", redirect: "/login" },
];
