<template>
  <section
    v-loading="loading"
    class="content"
    element-loading-text="保存调卷规则及生成试卷文件中..."
  >
    <LinkTitlesCustom :current-paths="['考试管理', '调卷规则', '调卷详情']" />
    <!-- 正文信息 -->
    <div class="box-body">
      <el-form
        ref="extractPaperForm"
        :inline="true"
        :model="extractConfig"
        label-position="left"
        label-width="120px"
      >
        <el-row>
          <el-form-item label="考试名称">
            <el-select
              v-model="extractConfig.examId"
              filterable
              clearable
              placeholder="请选择"
              :remote-method="getExams"
              remote
              :disabled="isInsert"
              @change="getExamCourses"
            >
              <el-option
                v-for="item in examList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="课程名称">
            <el-select
              v-model="extractConfig.courseCode"
              filterable
              clearable
              placeholder="请选择"
              :disabled="isInsert"
              @change="getPaperTypeList"
            >
              <el-option
                v-for="item in courseInfoSelect"
                :key="item.courseNo"
                :label="item.courseInfo"
                :value="item.courseNo"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-row>

        <el-tabs v-model="tabs">
          <el-tab-pane label="步骤一：调卷模式选择" name="first">
            <el-form-item prop="status">
              <el-radio-group
                v-model="extractConfig.callType"
                class="pull_right_ss"
              >
                <el-radio label="WHOLE_SET">成套调用</el-radio>
                <el-radio label="RANDOM_PAPER">随机抽题</el-radio>
                <!-- <el-radio label="RESTRUCT">重组调用</el-radio> -->
              </el-radio-group>
            </el-form-item>
          </el-tab-pane>
        </el-tabs>
        <template v-if="extractConfig.callType === 'WHOLE_SET'">
          <el-tabs v-model="tabs">
            <el-tab-pane label="步骤二：试卷抽取比例" name="first">
              <el-row>
                <el-col :span="24">
                  <el-tag v-if="extractConfig.examType === 'TRADITION'"
                    >传统考试</el-tag
                  >
                  <el-tag v-if="extractConfig.examType === 'ONLINE'"
                    >在线考试</el-tag
                  >
                  <el-tag v-if="extractConfig.examType === 'PRACTICE'"
                    >练习考试</el-tag
                  >
                </el-col>
              </el-row>
              <el-row v-for="(examPaper, num) in examPaperList" :key="num">
                <el-col :span="24">
                  <el-form-item label="试卷类型" class="pull-left">
                    <el-input
                      v-model="examPaper.groupCode"
                      class="input_width_lg"
                      placeholder="请输入试卷类型"
                      disabled
                    ></el-input>
                  </el-form-item>
                  <el-button
                    v-if="extractConfig.examType != 'TRADITION'"
                    type="primary"
                    @click="addOnLinePaper(examPaper.groupCode)"
                    ><i class="el-icon-plus"></i> 新增试卷
                  </el-button>
                </el-col>
                <el-col
                  v-for="(paperInfo, index) in examPaper.paperInfoList"
                  :key="paperInfo.selectedPaperId"
                  :span="20"
                  :offset="2"
                >
                  <el-form-item>
                    <span>试卷选定</span>
                    <el-select
                      v-model="paperInfo.paper.id"
                      filterable
                      placeholder="请选择"
                      class="select_width_lg"
                      @change="searchAudioTimeConfig"
                    >
                      <el-option
                        v-for="item in paperList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                    <span>选取几率</span>
                    <span>
                      <el-input
                        v-model="paperInfo.weight"
                        class="input_width_sms"
                        :disabled="extractConfig.examType === 'TRADITION'"
                      ></el-input
                      >%
                      <i
                        v-if="index > 0"
                        class="el-icon-delete"
                        @click.prevent="
                          removePaperInfo(examPaper.groupCode, paperInfo)
                        "
                      ></i> </span
                    >&nbsp;
                    <span :class="'errorMsg_' + num + '_' + index"></span>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-tab-pane>
          </el-tabs>
          <div>
            <el-tabs v-model="tabs">
              <el-tab-pane label="步骤三：随机设定" name="first">
                <el-form-item
                  label="客观题小题乱序"
                  prop="scrambling_the_question_order"
                >
                  <el-radio-group
                    v-model="extractConfig.scrambling_the_question_order"
                    class="pull_right_sm"
                  >
                    <el-radio :label="1">开启</el-radio>
                    <el-radio :label="0">关闭</el-radio>
                  </el-radio-group>
                </el-form-item>
                <br />
                <el-form-item
                  label="客观题选项乱序"
                  prop="scrambling_the_option_order"
                >
                  <el-radio-group
                    v-model="extractConfig.scrambling_the_option_order"
                    class="pull_right_sm"
                  >
                    <el-radio :label="1">开启</el-radio>
                    <el-radio :label="0">关闭</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-tab-pane>
            </el-tabs>
          </div>
          <div
            v-show="extractConfig.examType != 'PRACTICE'"
            style="padding: 5px"
          >
            <el-tabs v-model="tabs">
              <el-tab-pane label="步骤四：音频播放次数设置" name="first">
                <el-row
                  v-show="audioTimeConfigList.length > 0"
                  class="row_header_word"
                >
                  <el-col :span="4" :offset="1">试卷类型</el-col>
                  <el-col :span="4">试卷名称</el-col>
                  <el-col :span="3">大题号</el-col>
                  <el-col :span="3">小题号</el-col>
                  <el-col :span="4">播放次数</el-col>
                </el-row>
                <el-row
                  v-for="(audioTimeConfigInner, index) in audioTimeConfigList"
                  :key="index"
                  class="margin-top-10"
                >
                  <el-col :span="4" :offset="1">
                    {{ audioTimeConfigInner.groupCode }}
                  </el-col>
                  <el-col :span="4">
                    {{ audioTimeConfigInner.paper.name }}
                  </el-col>
                  <el-col :span="3">
                    {{
                      audioTimeConfigInner.paperDetailUnit.paperDetail.number
                    }}
                  </el-col>
                  <el-col :span="3">
                    {{ audioTimeConfigInner.paperDetailUnit.number }}
                  </el-col>
                  <el-col :span="4">
                    <el-input
                      v-model="audioTimeConfigInner.playTime"
                      class="input_width_ss"
                      size="small"
                      >次
                    </el-input>
                  </el-col>
                </el-row>
                <el-row v-show="audioTimeConfigList.length == 0">
                  <el-col :span="18" :offset="1"> 无音频试题 </el-col>
                </el-row>
              </el-tab-pane>
            </el-tabs>
          </div>
          <div>
            <el-tabs v-model="tabs">
              <el-row>
                <el-col>
                  <el-form-item label="是否生成文件">
                    <el-radio-group v-model="isbuildFile">
                      <el-radio label="1">是</el-radio>
                      <el-radio label="0">否</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row v-if="isbuildFile == '1'">
                <el-form-item label="小题序号">
                  <el-radio-group v-model="seqMode" class="input">
                    <el-radio label="MODE1">单题型连续</el-radio>
                    <el-radio label="MODE2">客观题整体连续</el-radio>
                    <el-radio label="MODE3">按大题独立</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-button type="primary" @click="submitForm">
                    保存
                  </el-button>
                  <el-button type="primary" @click="back"
                    ><i class="el-icon-arrow-left"></i> 返 回</el-button
                  >
                </el-col>
              </el-row>
            </el-tabs>
          </div>
        </template>

        <template v-else>
          <el-tabs v-model="tabs">
            <el-tab-pane label="步骤二：随机抽题模板选择" name="first">
              <el-form-item label="抽题模板" label-width="90px">
                <el-select
                  v-model="extractConfig2.randomPaperId"
                  filterable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in tplList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <div class="flex items-center">
                <el-form-item label="组卷结构" label-width="90px">
                  <el-select v-model="curStructInfo.paperStructId" disabled>
                    <el-option
                      v-for="item in randomTplInfo.paperStructOptions"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                  <p
                    style="display: inline-block; margin-left: 40px"
                    class="label"
                  >
                    难度：<span style="color: #409eff; font-weight: bold">{{
                      randomTplInfo.difficultyDegree
                    }}</span>
                  </p>
                </el-form-item>
              </div>
              <el-table
                :data="randomTplInfo.tableData"
                border
                style="margin-top: 8px; width: 700px"
              >
                <el-table-column
                  v-for="(item, index) in tableColumns"
                  :key="index"
                  :label="item.label"
                  :prop="item.prop"
                >
                  <template slot-scope="scope">
                    <span
                      v-if="
                        ![
                          'hardInfo',
                          'mediumInfo',
                          'easyInfo',
                          'anyInfo',
                        ].includes(item.prop)
                      "
                      >{{ scope.row[item.prop] }}</span
                    >
                    <span v-else>{{ scope.row[item.prop]?.count }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>

          <el-tabs v-model="tabs">
            <el-tab-pane label="步骤三：音频播放次数设置" name="first">
              <el-form-item label="播放次数" label-width="90px">
                <el-input-number
                  v-model="extractConfig2.playTime"
                  :min="1"
                  step-strictly
                  :precision="0"
                  :controls="false"
                  style="width: 60px; margin-right: 5px"
                ></el-input-number>
              </el-form-item>
            </el-tab-pane>
          </el-tabs>
          <el-row style="margin-top: 10px">
            <el-col :span="12">
              <el-button type="primary" @click="submitForm"> 保存 </el-button>
              <el-button type="primary" @click="back"
                ><i class="el-icon-arrow-left"></i> 返 回</el-button
              >
            </el-col>
          </el-row>
        </template>
      </el-form>
    </div>
  </section>
</template>
<script>
import { EXAM_WORK_API, QUESTION_API } from "@/constants/constants";
import { mapState } from "vuex";
import _ from "lodash";
import LinkTitlesCustom from "@/components/LinkTitlesCustom.vue";
export default {
  components: { LinkTitlesCustom },
  data() {
    return {
      loading: false,
      seqMode: "MODE1",
      extractConfig: {
        examId: "", //考试ID
        examName: "", //考试名称
        examType: "", //考试类型：网络考试、传统考试
        courseCode: "", //课程代码
        courseName: "", //课程名称
        callType: "WHOLE_SET", //调卷类型：默认成套调用
        examPaperList: [],
        scrambling_the_question_order: 0, //小题乱序  默认关闭
        scrambling_the_option_order: 0, //选项乱序  默认关闭
      },
      extractConfig2: {
        randomPaperId: "", //抽题模板id
        playTime: 2,
      },
      curId: "",
      randomTplInfo: {
        paperStructOptions: [],
        paperStructType: "",
        paperIds: [],
        tableData: [],
        // tableColumns: [
        //   { label: "题型", prop: "detailName", minWidth: "100" },
        //   { label: "总分", prop: "totalScore", minWidth: "80" },
        //   { label: "数量", prop: "totalCount", minWidth: "80" },
        //   { label: "难", prop: "hardInfo", minWidth: "80" },
        //   { label: "中", prop: "mediumInfo", minWidth: "80" },
        //   { label: "易", prop: "easyInfo", minWidth: "80" },
        // ],
        difficultyDegree: "",
      },
      curStructInfo: {
        difficulty: "",
        paperStructId: "",
      },
      examPaperList: [],
      audioTimeConfig: {
        examId: "",
        examName: "",
        paper: {
          id: "",
        },
        groupCode: "",
        courseCode: "",
        playTime: "",
      },
      audioTimeConfigModelList: [],
      tabs: "first",
      paperList: [],
      isbuildFile: "0",
      audioTimeConfigList: [],
      paperName: "",
      examList: [],
      courseList: [],
      courseAll: [],
      isInsert: false,
      tplList: [],
      curCourseId: "",
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
    courseInfoSelect() {
      var courseList = [];
      for (let course of this.courseList) {
        var courseInfo = course.courseName + "(" + course.courseCode + ")";
        courseList.push({
          courseNo: course.courseCode,
          courseName: course.courseName,
          courseInfo: courseInfo,
        });
      }
      return courseList;
    },
    tableColumns() {
      let end = this.randomTplInfo.paperStructType === "EXACT" ? 7 : 6;
      return [
        { label: "题型", prop: "detailName", minWidth: "100" },
        { label: "总分", prop: "totalScore", minWidth: "80" },
        { label: "数量", prop: "totalCount", minWidth: "80" },
        { label: "难", prop: "hardInfo", minWidth: "80" },
        { label: "中", prop: "mediumInfo", minWidth: "80" },
        { label: "易", prop: "easyInfo", minWidth: "80" },
        { label: "不限难度", prop: "anyInfo", minWidth: "80" },
      ].slice(0, end);
    },
  },
  watch: {
    // "extractConfig.examId"(val) {
    //   if (val && this.examList.length) {
    //     this.getCurCallType();
    //   }
    // },
    // examList(val) {
    //   if (this.extractConfig.examId && val.length) {
    //     this.getCurCallType();
    //   }
    // },
    "extractConfig2.randomPaperId"() {
      this.getData();
    },
    "extractConfig.courseCode"(val) {
      if (!val) {
        this.extractConfig2.randomPaperId = "";
      }
      let id = "";
      for (let course of this.courseList) {
        if (course.courseCode == val) {
          id = course.courseId;
          break;
        }
      }
      this.getTplList("", id);
    },
  },
  //初始化
  async created() {
    let extractConfigId = this.$route.params.extractConfigId;
    //1.查询考试集合
    this.getExams("");
    if (extractConfigId) {
      this.isInsert = true;
      //规则ID存在，表示是修改操作
      this.getExtractConfig(extractConfigId);
    }
  },
  methods: {
    getData() {
      if (!this.tplList.length || !this.extractConfig2.randomPaperId) {
        this.randomTplInfo.paperStructOptions = [];
        this.randomTplInfo.paperStructType = "";
        this.randomTplInfo.paperIds = [];
        this.randomTplInfo.tableData = [];
        this.randomTplInfo.difficultyDegree = "";
        return;
      }
      let item = this.tplList.find(
        (v) => v.id == this.extractConfig2.randomPaperId
      );
      this.randomTplInfo.paperStructType = item ? item.paperStructType : "";

      let apiUrl = "/api/ecs_ques/paperStruct/1/10000";
      let params =
        this.randomTplInfo.paperStructType == "EXACT"
          ? { courseNo: "ALL", type: "EXACT" }
          : this.randomTplInfo.paperStructType === "BLUEPRINT"
          ? { type: "BLUEPRINT" }
          : null;
      if (params) {
        this.$http.get(apiUrl, { params }).then((res) => {
          this.randomTplInfo.paperStructOptions = res.data.content;

          this.curStructInfo.paperStructId = item ? item.paperStructId : "";
          this.randomTplInfo.paperIds = item ? item.paperIds : [];

          let structItem = res.data.content.find(
            (v) => v.id == this.curStructInfo.paperStructId
          );
          this.curStructInfo.difficulty = structItem
            ? structItem.difficulty
            : "";
          this.$http
            .post("/api/ecs_ques/randompaper/struct/question/info", null, {
              params: { structId: this.curStructInfo.paperStructId },
              headers: { "content-type": "application/x-www-form-urlencoded" },
            })
            .then((res) => {
              this.randomTplInfo.tableData = res.data.structQuestionInfo || [];
              this.randomTplInfo.difficultyDegree = res.data.difficultyDegree;
            });
        });
      }
    },
    async getTplList(id, cId) {
      let courseId = cId || this.$route.query.courseId;
      let params = {
        pageNumber: 1,
        pageSize: 10000,
        enable: true,
        courseId,
      };
      !!id && (params.id = id);
      try {
        let res = await this.$http.post(
          QUESTION_API + "/randompaper/page",
          null,
          {
            params,
          }
        );
        this.tplList = res.data.content || [];
        this.getData();
      } catch (e) {
        // this.$message.error("获取抽题模板失败");
        this.tplList = [];
        this.getData();
      }
    },
    // getCurCallType() {
    //   let item = this.examList.find(
    //     (item) => item.id == this.extractConfig.examId
    //   );
    //   this.extractConfig.callType = item.callType || "WHOLE_SET";
    // },
    //根据考务查询考试集合
    getExams(query) {
      query = query.trim();
      this.$http
        .get(
          EXAM_WORK_API + "/exam/queryByNameLike?name=" + query + "&enable=true"
        )
        .then((response) => {
          this.examList = response.data;
        });
    },
    clearPageData() {
      this.extractConfig2 = {
        randomPaperId: "", //抽题模板id
        playTime: 1,
      };
      this.randomTplInfo.paperStructOptions = [];
      this.randomTplInfo.paperStructType = "";
      this.randomTplInfo.paperIds = [];
      this.randomTplInfo.tableData = [];
      this.curStructInfo = {
        difficulty: "",
        paperStructId: "",
      };
    },
    //根据考试查询课程
    getExamCourses() {
      if (!this.isInsert) {
        this.extractConfig.courseCode = "";
        // this.clearPageData();

        this.courseList = [];
        if (this.extractConfig.examId) {
          //查询该考试下的课程
          this.$http
            .get(
              QUESTION_API +
                "/findCourseByExtractConfig/" +
                this.extractConfig.examId
            )
            .then((response) => {
              this.courseList = response.data;
            });
        }
        //查询考试对象,获取考试类型：离线，在线，传统
        if (this.extractConfig.examId) {
          this.$http
            .get(EXAM_WORK_API + "/exam/" + this.extractConfig.examId)
            .then((response) => {
              var exam = response.data;
              this.extractConfig.examType = exam.examType;
              this.extractConfig.examName = exam.name;
            });
        }
      }
    },
    //根据考试ID和课程code取出试卷类型集合
    getPaperTypeList() {
      if (!this.isInsert) {
        var id = "";
        for (let course of this.courseList) {
          if (this.extractConfig.courseCode == course.courseCode) {
            id = course.courseId;
            this.curCourseId = id;
            break;
          }
        }
        this.$http
          .get(
            EXAM_WORK_API +
              "/exam/queryExamCoursePaperTypeList?examId=" +
              this.extractConfig.examId +
              "&courseId=" +
              id
          )
          .then((response) => {
            let examPaperArr = response.data;
            if (examPaperArr && examPaperArr.length > 0) {
              let _examPaperList = [];
              for (let examPaper of examPaperArr) {
                let groupCode = examPaper.paperType;
                let weight =
                  this.extractConfig.examType == "TRADITION" ? 100 : "";
                let examPaperListByGroupCode =
                  this.getWeightFromExamPaperList(groupCode);
                if (examPaperListByGroupCode) {
                  _examPaperList.push({
                    groupCode: groupCode,
                    paperInfoList: examPaperListByGroupCode,
                  });
                } else {
                  _examPaperList.push({
                    groupCode: groupCode,
                    paperInfoList: [
                      {
                        weight: weight,
                        paper: { id: "" },
                      },
                    ],
                  });
                }
              }
              this.examPaperList = _examPaperList;
            }
          });
        this.getPaperList();
      }
    },
    //根据groupCode得到examPaper集合
    getWeightFromExamPaperList(groupCode) {
      let examPaperListByGroupCode = [];
      let examPaperList = this.extractConfig.examPaperList;
      if (!examPaperList || examPaperList.length == 0) {
        return null;
      }
      for (let examPaper of examPaperList) {
        if (examPaper.groupCode == groupCode) {
          examPaperListByGroupCode.push(examPaper);
        }
      }
      return examPaperListByGroupCode;
    },
    //根据课程，查询试卷集合
    getPaperList() {
      if (this.extractConfig.courseCode) {
        this.$http
          .get(
            QUESTION_API +
              "/getGenPaper?courseNo=" +
              this.extractConfig.courseCode
          )
          .then((response) => {
            this.paperList = response.data;
          });
      }
    },
    //根据选择试卷集合查询音频题
    searchAudioTimeConfig() {
      this.audioTimeConfigModelList = [];
      //取到数组里面每个对象
      for (var i = 0; i < this.examPaperList.length; i++) {
        //定一个试卷的集合，用来去重
        var paperIds = [];
        for (var j = 0; j < this.examPaperList[i].paperInfoList.length; j++) {
          paperIds.push(this.examPaperList[i].paperInfoList[j].paper.id);
        }
        //对试卷集合进行去重
        var ids = [];
        ids = _.uniq(paperIds);
        for (var k = 0; k < ids.length; k++) {
          //把对象添加到集合
          this.audioTimeConfigModelList.push({
            courseCode: this.extractConfig.courseCode,
            groupCode: this.examPaperList[i].groupCode,
            paper: {
              id: ids[k],
            },
            examId: this.extractConfig.examId,
            examName: this.extractConfig.examName,
          });
        }
      }
      this.$http
        .post(
          QUESTION_API + "/audioTimeConfig/all",
          this.audioTimeConfigModelList
        )
        .then((response) => {
          this.audioTimeConfigList = response.data;
        });
    },
    //增加在线考试试卷
    addOnLinePaper(groupCode) {
      let num = 0;
      for (let i = 0; i < this.examPaperList.length; i++) {
        if (this.examPaperList[i].groupCode == groupCode) {
          num = i;
          break;
        }
      }
      this.examPaperList[num].paperInfoList.push({
        weight: 0,
        paper: {
          id: "",
        },
      });
    },
    //删除试卷
    removePaperInfo(groupCode, item) {
      let num = 0;
      for (let i = 0; i < this.examPaperList.length; i++) {
        if (this.examPaperList[i].groupCode == groupCode) {
          num = i;
          break;
        }
      }
      let index = this.examPaperList[num].paperInfoList.indexOf(item);
      if (index !== -1) {
        this.examPaperList[num].paperInfoList.splice(index, 1);
      }
      if (this.extractConfig.examType != "PRACTICE") {
        //重新查询一次所有试卷的音频题数
        this.searchAudioTimeConfig();
      }
    },
    //保存提交
    submitForm() {
      this.saveExtractConfig();
    },
    //保存调卷规则
    saveExtractConfig() {
      if (!this.extractConfig.examId) {
        this.$notify({
          message: "请选择考试",
          type: "error",
        });
        return false;
      }
      if (!this.extractConfig.courseCode) {
        if (this.isInsert) {
          this.$notify({
            message:
              "考务数据中，该场考试下已经没有这门课程，无法保存调卷规则。",
            type: "error",
          });
        } else {
          this.$notify({
            message: "请选择课程",
            type: "error",
          });
        }
        return false;
      }
      if (this.extractConfig.callType === "RANDOM_PAPER") {
        this.toSubmit2();
        return;
      }
      if (this.extractConfig.examType != "PRACTICE") {
        for (let audioTimeConfig of this.audioTimeConfigList) {
          if (!this.checkValue(audioTimeConfig.playTime)) {
            this.$notify({
              message: "播放次数只能为整数",
              type: "error",
            });
            return false;
          }
        }
      }
      let myExamPaperList = this.verificationData();
      if (myExamPaperList.length > 0) {
        this.loading = true;
        this.extractConfig.examPaperList = myExamPaperList;
        this.$http
          .put(
            QUESTION_API +
              "/extractConfig" +
              "/" +
              this.isbuildFile +
              "?seqMode=" +
              this.seqMode,
            this.extractConfig
          )
          .then(
            () => {
              if (this.extractConfig.examType != "PRACTICE") {
                this.addAudioTimeConfig();
              }
              this.loading = false;
              this.$notify({ type: "success", message: "保存成功" });
              this.back();
            },
            (error) => {
              this.loading = false;
              this.$notify({
                type: "error",
                message: error.response.data.desc,
              });
            }
          );
      }
    },
    toSubmit2() {
      let msg = "";
      if (!this.extractConfig2.randomPaperId) {
        msg = "请选择抽题模板";
      } else if (
        this.extractConfig2.playTime === "" ||
        this.extractConfig2.playTime === undefined
      ) {
        msg = "请填写播放次数";
      }
      if (msg) {
        this.$notify({
          message: msg,
          type: "error",
        });
        return;
      }
      const { callType, courseCode, examId, examName, examType } =
        this.extractConfig;
      this.loading = true;
      let params = {
        ...this.extractConfig2,
        callType,
        courseCode,
        examId,
        examName,
        examType,
      };
      if (this.curId) {
        params.id = this.curId;
      }
      this.$http.put(QUESTION_API + "/extractConfig/0", params).then(
        () => {
          this.loading = false;
          this.$notify({ type: "success", message: "保存成功" });
          this.back();
        },
        (error) => {
          this.loading = false;
          this.$notify({
            type: "error",
            message: error.response.data.desc,
          });
        }
      );
    },
    //音频播放次数校验
    checkValue(val) {
      if (/(^[0-9]\d*$)/.test(val)) {
        return true;
      } else {
        return false;
      }
    },
    //新增音频播放次数
    addAudioTimeConfig() {
      if (!this.audioTimeConfigList || this.audioTimeConfigList.length == 0) {
        /* 未设置时则清理已存在的数据 */
        this.$http
          .post(
            QUESTION_API +
              "/deleteAudioTimeConfig/" +
              this.extractConfig.examId +
              "/" +
              this.extractConfig.courseCode
          )
          .then(() => {});
      } else {
        this.$http
          .post(
            QUESTION_API +
              "/addAudioTimeConfig?examId=" +
              this.extractConfig.examId +
              "&courseCode=" +
              this.extractConfig.courseCode,
            this.audioTimeConfigList
          )
          .then(() => {});
      }
    },
    //校验调卷规则数据
    verificationData() {
      let myExamPaperList = [];
      let examPaperList = this.examPaperList;
      for (let i = 0; i < examPaperList.length; i++) {
        let selectedPaperIds = []; //校验试卷重复性
        let weightCount = 0; //校验概率之和
        let _paperInfoList = examPaperList[i].paperInfoList;
        let groupCode = examPaperList[i].groupCode;
        if (!groupCode) {
          this.$notify({
            message: "试卷类型不能为空",
            type: "error",
            duration: 2000,
          });
          break;
        }
        for (let j = 0; j < _paperInfoList.length; j++) {
          if (!_paperInfoList[j].paper.id) {
            myExamPaperList = [];
            // $(".errorMsg_" + i + "_" + j)
            //   .text("试卷不能为空")
            //   .show();
            document.getElementsByClassName(
              "errorMsg_" + i + "_" + j
            )[0].innerHTML = "试卷不能为空";
            document.getElementsByClassName(
              "errorMsg_" + i + "_" + j
            )[0].style.display = "inline";
            break;
          } else {
            //$(".errorMsg_" + i + "_" + j).hide();
            document.getElementsByClassName(
              "errorMsg_" + i + "_" + j
            )[0].style.display = "none";
          }
          var reg = /^([1]?\d{1,2})$/;
          if (
            !reg.test(_paperInfoList[j].weight) ||
            _paperInfoList[j].weight == 0
          ) {
            myExamPaperList = [];
            // $(".errorMsg_" + i + "_" + j)
            //   .text("请输入1-100的整数")
            //   .show();
            document.getElementsByClassName(
              "errorMsg_" + i + "_" + j
            )[0].innerHTML = "请输入1-100的整数";
            document.getElementsByClassName(
              "errorMsg_" + i + "_" + j
            )[0].style.display = "inline";
            break;
          } else {
            //$(".errorMsg_" + i + "_" + j).hide();
            if (document.getElementsByClassName("errorMsg_" + i + "_" + j)) {
              document.getElementsByClassName(
                "errorMsg_" + i + "_" + j
              )[0].style.display = "none";
            }
            weightCount += parseInt(_paperInfoList[j].weight);
            selectedPaperIds.push(_paperInfoList[j].paper.id);
            myExamPaperList.push({
              groupCode: groupCode,
              paper: {
                id: _paperInfoList[j].paper.id,
              },
              weight: _paperInfoList[j].weight,
            });
          }
        }
        if (myExamPaperList.length == 0) {
          break;
        } else {
          if (_.uniq(selectedPaperIds).length != selectedPaperIds.length) {
            myExamPaperList = [];
            this.$notify({
              message: "试卷选择不能重复",
              type: "error",
              duration: 2000,
            });
            break;
          }
          if (weightCount != 100) {
            myExamPaperList = [];
            this.$notify({
              message: "试卷选取几率总和必须等于100",
              type: "error",
              duration: 2000,
            });
            break;
          }
        }
      }
      return myExamPaperList;
    },
    //返回
    back() {
      if (this.isInsert) {
        this.$router.push({
          path: "/questions/extract_paper_rule/1",
        });
      } else {
        if (this.extractConfig.examId) {
          sessionStorage.setItem(
            "extract_config_add_examId",
            this.extractConfig.examId
          );
          this.$router.push({
            path: "/questions/extract_paper_rule/2",
          });
        } else {
          this.$router.push({
            path: "/questions/extract_paper_rule/0",
          });
        }
      }
    },
    //修改调卷规则
    getExtractConfig(extractConfigId) {
      this.$http
        .get(QUESTION_API + "/extractConfig/" + extractConfigId)
        .then((response) => {
          this.extractConfig = response.data;

          this.extractConfig2.randomPaperId = response.data.randomPaperId || "";
          this.extractConfig2.playTime = response.data.playTime || "";
          this.curId = response.data.curId || "";

          this.getTplList(response.data.randomPaperId || "");

          //查询该考试下的课程
          this.$http
            .get(
              EXAM_WORK_API +
                "/exam/queryExamCourseList?examId=" +
                this.extractConfig.examId +
                "&enable=true&name=" +
                this.extractConfig.courseCode
            )
            .then((response) => {
              this.courseList = response.data;
              if (!this.courseList || this.courseList.length < 1) {
                this.$notify({
                  message: "考务中，该场考试下面，没有设置课程！",
                  type: "error",
                });
              } else {
                //根据考试ID和课程code取出试卷类型集合
                this.getPaperTypes();
              }
            });
        });
    },
    //根据考试ID和课程code取出试卷类型集合
    getPaperTypes() {
      var id = "";
      if (this.courseList && this.courseList.length > 0) {
        for (let course of this.courseList) {
          if (this.extractConfig.courseCode == course.courseCode) {
            id = course.courseId;
            break;
          }
        }
        if (id != "") {
          //查询该课程下的所有试卷
          this.getPaperList();
          this.$http
            .get(
              EXAM_WORK_API +
                "/exam/queryExamCoursePaperTypeList?examId=" +
                this.extractConfig.examId +
                "&courseId=" +
                id
            )
            .then((response) => {
              let examPaperArr = response.data;
              if (examPaperArr && examPaperArr.length > 0) {
                let _examPaperList = [];
                for (let examPaper of examPaperArr) {
                  let groupCode = examPaper.paperType;
                  let weight =
                    this.extractConfig.examType == "TRADITION" ? 100 : "";
                  let examPaperListByGroupCode =
                    this.getWeightFromExamPaperList(groupCode);
                  if (examPaperListByGroupCode) {
                    _examPaperList.push({
                      groupCode: groupCode,
                      paperInfoList: examPaperListByGroupCode,
                    });
                  } else {
                    _examPaperList.push({
                      groupCode: groupCode,
                      paperInfoList: [
                        {
                          weight: weight,
                          paper: { id: "" },
                        },
                      ],
                    });
                  }
                }
                this.examPaperList = _examPaperList;
                if (this.extractConfig.examType != "PRACTICE") {
                  this.searchAddAudioTimeConfig();
                }
              }
            });
          this.getPaperList();
        } else {
          this.$notify({
            message: "考务中，该场考试下面，没有设置该门课程！",
            type: "error",
          });
        }
      }
    },
    //存在调卷规则id，先查询
    searchAddAudioTimeConfig() {
      this.audioTimeConfigModelList = [];
      //取到数组里面每个对象
      for (var i = 0; i < this.examPaperList.length; i++) {
        //定一个试卷的集合，用来去重
        var paperIds = [];
        for (var j = 0; j < this.examPaperList[i].paperInfoList.length; j++) {
          paperIds.push(this.examPaperList[i].paperInfoList[j].paper.id);
        }
        //对试卷集合进行去重
        var ids = [];
        ids = _.uniq(paperIds);
        for (var k = 0; k < ids.length; k++) {
          //把对象添加到集合
          this.audioTimeConfigModelList.push({
            courseCode: this.extractConfig.courseCode,
            groupCode: this.examPaperList[i].groupCode,
            paper: {
              id: ids[k],
            },
            examId: this.extractConfig.examId,
            examName: this.extractConfig.examName,
          });
        }
      }
      this.$http
        .post(
          QUESTION_API + "/audioTimeConfig/update",
          this.audioTimeConfigModelList
        )
        .then((response) => {
          this.audioTimeConfigList = response.data;
        });
    },
  },
};
</script>
<style scoped>
.label {
  color: #606266;
  font-size: 14px;
}
.pull_right_ss {
  margin-left: 30px;
}
.paper_title_top {
  margin-top: -10px;
}
.input_width_sms {
  width: 80px;
}
.input_width_ss {
  width: 50px;
}
.el-icon-delete {
  cursor: pointer;
}
[class^="errorMsg_"] {
  color: #ff4949;
  display: none;
}
.margin-top-10 {
  margin-top: 10px;
}

.select_width_lg {
  width: 420px;
}
</style>
<style scoped src="../styles/Common.css"></style>
