<template>
  <section class="content">
    <div class="box box-info">
      <div class="box-body">
        <!-- 选择 -->
        <el-form :inline="true" :model="form" label-position="right">
          <el-row>
            <el-form-item label="学校" class="pull-left">
              <el-select
                v-model="form.orgId"
                class="input_width_lg"
                placeholder="请选择"
                :disabled="!isSuperAdmin"
                filterable
                @change="rootOrgChanged"
              >
                <el-option
                  v-for="item in rootSchoolSelect"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="权限组" class="pull-left">
              <el-select
                v-model="form.privilegeGroupId"
                class="input_width_lg"
                placeholder="请选择"
                @change="change"
              >
                <el-option
                  v-for="item in privilegeGroupList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-row>
        </el-form>

        <div style="margin-bottom: 10px">
          <el-button type="primary" :disabled="!treeChanged" @click="save">
            保 存
          </el-button>
        </div>

        <!-- 权限树 -->
        <div style="width: 50%">
          <el-tree
            ref="tree"
            class="el-tree"
            :data="treeData"
            :props="defaultProps"
            show-checkbox
            node-key="id"
            highlight-current
            :check-strictly="true"
            :default-expanded-keys="checkedKeys"
            :default-checked-keys="checkedKeys"
            :expand-on-click-node="true"
            @check="nodeCheck"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import { CORE_API } from "@/constants/constants.js";

export default {
  name: "SchoolPrivilegeSettings",
  data() {
    return {
      form: {
        orgId: null,
        privilegeGroupId: null,
      },
      rootOrgList: [],
      privilegeGroupList: [],
      treeChanged: false,
      treeData: [],
      checkedKeys: [],
      uncheckChildren_nodeId: null,
      defaultProps: {
        children: "children",
        label: "label",
      },
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
    isSuperAdmin() {
      return this.user.roleList.some((role) => role.roleCode == "SUPER_ADMIN");
    },
    rootSchoolSelect() {
      let rootSchools = [];
      for (let i = 0; i < this.rootOrgList.length; i++) {
        let info = {
          name: this.rootOrgList[i].name + "（" + this.rootOrgList[i].id + "）",
          id: this.rootOrgList[i].id,
        };
        rootSchools.push(info);
      }
      return rootSchools;
    },
  },
  created() {
    this.init();
  },
  methods: {
    /*初始化*/
    init() {
      var url1 = CORE_API + "/org/getRootOrgList";
      var url2 =
        CORE_API + "/rolePrivilege/getRoles?includeSuperAdmin=" + false;
      var url3 = CORE_API + "/rolePrivilege/getPrivilegeGroupList";

      Promise.all([
        this.$httpWithMsg.get(url1),
        this.$httpWithMsg.post(url2),
        this.$httpWithMsg.get(url3),
      ]).then(([resp1, resp2, resp3]) => {
        this.rootOrgList = resp1.data;

        this.form.orgId = this.user.rootOrgId;
        this.roleList = resp2.data;
        if (0 < this.roleList.length) {
          this.form.roleId = this.roleList[0].roleId;
        }
        this.privilegeGroupList = resp3.data.filter(function (item) {
          if (item.ext1 == "function") {
            return item;
          }
        });
        if (0 < this.privilegeGroupList.length) {
          this.form.privilegeGroupId = this.privilegeGroupList[0].id;
        }

        this.initTree(this.form.orgId, this.form.privilegeGroupId);
      });
    },
    /*初始化权限树*/
    initTree(orgId, privilegeGroupId) {
      var url1 =
        CORE_API + "/rolePrivilege/getPrivilegeTree/" + privilegeGroupId;
      var url2 =
        CORE_API +
        "/rolePrivilege/getRootOrgPrivilegeIdList/" +
        orgId +
        "/" +
        privilegeGroupId;

      Promise.all([
        this.$httpWithMsg.get(url1),
        this.$httpWithMsg.get(url2),
      ]).then(([resp1, resp2]) => {
        console.log("initTree(). treeData:", resp1.data.children);
        console.log("initTree(). checkedKeys:", resp2.data);
        this.treeData = resp1.data.children;
        this.checkedKeys = resp2.data;
      });
    },
    /*change事件*/
    change() {
      this.initTree(this.form.orgId, this.form.privilegeGroupId);
    },
    rootOrgChanged() {
      var url =
        CORE_API +
        "/rolePrivilege/getRoles?includeSuperAdmin=false&rootOrgId=" +
        this.form.orgId;
      this.$httpWithMsg.post(url).then((response) => {
        this.roleList = response.data;
        if (0 < this.roleList.length) {
          this.form.roleId = this.roleList[0].roleId;
        }

        this.initTree(this.form.orgId, this.form.privilegeGroupId);
      });
    },
    nodeCheck({ id, parentId, children }, { checkedKeys }) {
      // console.log("[tree change] node:", node);

      const checked = checkedKeys.includes(id);
      // 当前node的状态
      if (checked) {
        this.checkedKeys = [...this.checkedKeys, id];
      } else {
        this.checkedKeys = this.checkedKeys.filter((id0) => id0 !== id);
      }
      // 选中状态下对子节点的影响：递归选中所有子孙节点
      if (checked && children) {
        this.checkedKeys = [...this.checkedKeys, ...children.map((v) => v.id)];
        for (const child of children) {
          this.checkedKeys = [...this.checkedKeys, child.id];
          for (const child of child.children || []) {
            // 树最多只有三个层级，这里就不写递归了
            this.checkedKeys = [...this.checkedKeys, child.id];
          }
        }
      }

      // 选中状态下对父节点的影响：递归选中所有父辈节点
      if (checked && parentId) {
        this.checkedKeys = [...this.checkedKeys, parentId];
        const parent1 = this.$refs.tree.getNode(parentId).data;
        // this.$refs.tree.setChecked(parentId, true, false);
        if (parent1.parentId) {
          // 第二个父节点
          this.checkedKeys = [...this.checkedKeys, parent1.parentId];
          // this.$refs.tree.setChecked(parent1.parentId, true, false);
        }
      }

      // 取消选中状态下对子节点的影响：递归取消选中所有子孙节点
      if (!checked && children) {
        this.checkedKeys = this.checkedKeys.filter((id0) => id0 !== id);
        // console.log(this.checkedKeys);
        for (const child of children) {
          this.checkedKeys = this.checkedKeys.filter((id) => id !== child.id);
          // console.log(this.checkedKeys);
          for (const child of child.children || []) {
            // 树最多只有三个层级，这里就不写递归了
            this.checkedKeys = this.checkedKeys.filter((id) => id !== child.id);
          }
        }
      }

      this.checkedKeys = [...new Set(this.checkedKeys)];
      this.$refs.tree.setCheckedKeys(this.checkedKeys);

      this.treeChanged = true;
    },
    save() {
      console.log("save(). checkedKeys:", this.checkedKeys);
      var url = CORE_API + "/rolePrivilege/updateRootOrgPrivilegeRelations";
      this.$httpWithMsg
        .post(url, {
          rootOrgId: this.form.orgId,
          privilegeGroupId: this.form.privilegeGroupId,
          privilegeIdSet: this.checkedKeys,
        })
        .then(() => {
          this.$notify({
            message: "更新成功",
            type: "success",
          });
          this.treeChanged = false;
        });
    },
  },
};
</script>

<style scoped>
.el-tree >>> label {
  margin-bottom: 0;
}
</style>
