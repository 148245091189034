<template>
  <el-dialog
    v-if="sourceUser"
    ref="dialog"
    title="数据权限复制"
    width="700px"
    :visible.sync="visible"
    @close="closeDialog"
  >
    <div>
      <div>
        <span>从数据源：</span> <span>{{ sourceUser.name }}</span>
      </div>
      <div>
        <span>复制到：</span>
        <el-tag v-for="user in targetUsers" :key="user.name" class="mr-1">
          {{ user.name }}
        </el-tag>
      </div>
    </div>
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      :inline="true"
      label-position="right"
      label-width="80px"
    >
      <el-row>
        <div class="float-right">
          <el-form-item label="姓名">
            <el-input
              v-model="form.name"
              placeholder="请输入姓名"
              class="input_width"
            />
          </el-form-item>
          <el-form-item>
            <el-button
              size="small"
              type="primary"
              icon="el-icon-search"
              @click="search"
            >
              查询
            </el-button>
          </el-form-item>
        </div>
      </el-row>

      <el-row>
        <div>
          <el-table
            ref="table"
            :data="tableData"
            border
            resizable
            stripe
            style="width: 100%"
            @selection-change="selectChange"
          >
            <el-table-column type="selection" width="40" />
            <el-table-column width="85" label="用户ID">
              <span slot-scope="scope">{{ scope.row.id }}</span>
            </el-table-column>
            <el-table-column width="200" label="姓名">
              <span slot-scope="scope">{{ scope.row.name }}</span>
            </el-table-column>
            <el-table-column label="登录名">
              <span slot-scope="scope">{{ scope.row.loginName }}</span>
            </el-table-column>
            <el-table-column width="120" label="角色">
              <span
                slot-scope="scope"
                v-html="scope.row.roleNamesStr.replace(/,/g, '<br />')"
              >
              </span>
            </el-table-column>
          </el-table>
          <div class="page pull-right">
            <el-pagination
              v-if="paginationShow"
              :current-page="currentPage"
              :page-size="pageSize"
              :page-sizes="[10, 20, 50, 100, 200, 300]"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              @current-change="handleCurrentChange"
              @size-change="handleSizeChange"
            />
          </div>
        </div>
        <div style="margin-bottom: 20px"></div>
      </el-row>

      <el-row class="d-flex justify-content-center mt-2">
        <el-button type="primary" :loading="loading" @click="submitForm"
          >确 定</el-button
        >
        <el-button @click="closeDialog">取 消</el-button>
      </el-row>
    </el-form>
  </el-dialog>
</template>

<script>
import { CORE_API } from "@/constants/constants";
import { mapState } from "vuex";

export default {
  name: "UserCopyPrevillegeDialog",
  props: {
    sourceUser: { type: Object, default: () => {} },
  },
  data() {
    return {
      visible: false,
      form: {
        name: "",
      },
      rules: {},
      targetUsers: [],
      loading: false,
      paginationShow: false,
      tableData: [],
      noBatchSelected: true,
      currentPage: 1,
      pageSize: 10,
      total: 10,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    isOrgAdminNotSuperAdmin() {
      return (
        this.user.roleList.some((role) => role.roleCode == "ORG_ADMIN") &&
        !this.user.roleList.some((role) => role.roleCode == "SUPER_ADMIN")
      );
    },
    isSuperAdminOrOrgAdmin() {
      return this.user.roleList.some(
        (role) => role.roleCode == "SUPER_ADMIN" || role.roleCode == "ORG_ADMIN"
      );
    },
  },
  methods: {
    async search() {
      var param = new URLSearchParams(this.form);
      if (!this.isSuperAdminOrOrgAdmin)
        param.append("ignoreRoleCodes", "SUPER_ADMIN,ORG_ADMIN");
      else if (this.isOrgAdminNotSuperAdmin) {
        param.append("ignoreRoleCodes", "SUPER_ADMIN");
      }
      var url =
        CORE_API +
        "/user/all/" +
        this.currentPage +
        "/" +
        this.pageSize +
        "?" +
        param;
      this.loading = true;
      return this.$httpWithMsg.get(url).then((response) => {
        this.tableData = response.data.list;
        this.total = response.data.total;
        this.loading = false;
        this.paginationShow = true;
      });
    },
    async openDialog() {
      this.visible = true;
      try {
        await this.search();
      } catch (error) {
        console.log(error);
        this.$notify({ type: "error", title: "获取用户列表失败" });
      }
    },
    closeDialog() {
      this.visible = false;
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.search();
    },
    selectChange(row) {
      this.targetUsers = row;
      // row.forEach(element => {
      //   this.selectedUserIds.push(element.id);
      // });
      // console.log(this.selectedUserIds);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.search();
    },
    async submitForm() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        console.log("校验失败", error);
        return;
      }

      const refIds = this.$refs.table.selection.map((v) => v.id);
      console.log(refIds);
      if (refIds.length === 0) {
        this.$notify({ type: "warning", message: "请先选择一行或多行" });
        return;
      }

      try {
        this.loading = true;
        // await saveActivity(data);
        await this.$httpWithMsg.post(CORE_API + "/user/data/rule/copy", {
          sourceUserId: this.sourceUser.id,
          targetUserIds: refIds,
        });
        this.$emit("reload");
        this.$notify({ title: "保存成功", type: "success" });
        this.closeDialog();
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style></style>
