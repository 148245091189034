<template>
  <div class="score-statistic main-wrap">
    <el-form :inline="true" :model="searchParams">
      <el-form-item label="考试">
        <el-select
          v-model="searchParams.examId"
          filterable
          remote
          :remote-method="getExamList"
          clearable
          placeholder="请选择考试"
          size="small"
          @change="changeExam"
        >
          <el-option
            v-for="item in examList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <!-- <el-button type="primary" :disabled="disableParam()" @click="searchHandler">查询</el-button>-->

      <el-button
        type="primary"
        :disabled="disableParam()"
        @click="refreshHandler"
        >刷新计算</el-button
      >
      <el-button
        type="primary"
        style="float: right"
        :disabled="disableParam()"
        @click="openDialog"
        >及格线设置</el-button
      >
    </el-form>

    <div class="search-result flex">
      <div class="result-column">
        <div class="desc-item">
          应考人次： <span>{{ examResult.allCount }}</span>
        </div>
        <div class="desc-item">
          实考人次：<span> {{ examResult.finishCount }}</span>
        </div>
      </div>
      <div class="result-column">
        <div class="desc-item">
          缺考人次：<span> {{ examResult.unFinishCount }}</span>
        </div>
        <div class="desc-item">
          缺考率（%）：<span> {{ examResult.unFinishRate }}</span>
        </div>
      </div>
      <div class="result-column">
        <div class="desc-item">
          及格人数：<span> {{ examResult.passScoreCount }}</span>
        </div>
        <div class="desc-item">
          及格率（%）：<span> {{ examResult.passScoreRate }}</span>
        </div>
      </div>
      <div class="result-column">
        <div class="desc-item">
          优秀人数：<span> {{ examResult.goodScoreCount }}</span>
        </div>
        <div class="desc-item">
          优秀率（%）：<span> {{ examResult.goodScoreRate }}</span>
        </div>
      </div>
      <div class="result-column">
        <div class="desc-item">
          违纪人数：<span> {{ examResult.illegalityCount }}</span>
        </div>
        <div class="desc-item">
          违纪率（%）：<span> {{ examResult.illegalityRate }}</span>
        </div>
      </div>
    </div>

    <el-tabs type="border-card">
      <el-tab-pane label="学习中心统计">
        <el-form :inline="true">
          <el-form-item label="">
            <el-select
              v-model="searchParams.orgId"
              placeholder="请选择学习中心"
              clearable
              @change="changeOrg"
            >
              <el-option
                v-for="item in orgList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-button
            type="primary"
            :disabled="disableParam()"
            @click="exportOrgStatisticExcel"
            >导出</el-button
          >
        </el-form>

        <el-table :data="orgTableData" style="margin-top: 10px">
          <el-table-column
            v-for="(item, index) in orgTableColumns"
            :key="index"
            :label="item.label"
            :prop="item.prop"
          >
          </el-table-column>
        </el-table>
      </el-tab-pane>

      <el-tab-pane label="课程统计">
        <el-form :inline="true">
          <el-form-item label="">
            <el-select
              v-model="searchParams.courseId"
              class="form_search_width"
              clearable
              filterable
              placeholder="请选择课程"
              size="small"
              @change="changeCourse"
            >
              <el-option
                v-for="item in courseList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-button
            type="primary"
            :disabled="disableParam()"
            @click="exportCourseStatisticExcel"
            >导出</el-button
          >
        </el-form>

        <el-table :data="courseTableData" style="margin-top: 10px">
          <el-table-column
            v-for="(item, index) in courseTableColumns"
            :key="index"
            :label="item.label"
            :prop="item.prop"
          >
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>

    <el-dialog
      ref="dialog"
      title="及格线设置"
      width="600px"
      :visible.sync="visible"
      :close-on-click-modal="false"
      @close="
        visible = false;
        dialogChecked = false;
      "
    >
      <el-table
        v-loading="dialogLoading"
        :data="dialogTableData"
        :columns="dialogTableColumns"
        border
      >
        <el-table-column
          v-for="(item, index) in dialogTableColumns"
          :key="index"
          :label="item.label"
          :prop="item.prop"
        >
          <template slot-scope="scope">
            <span v-if="item.prop === 'courseName'">
              {{ scope.row[item.prop] }}
            </span>
            <div v-else>
              <el-input-number
                v-model="dialogTableData[scope.$index][item.prop]"
                :class="{
                  'none-value':
                    dialogChecked &&
                    !hasNumValue(dialogTableData[scope.$index][item.prop]),
                }"
                :controls="false"
                style="width: 60px; margin-right: 5px"
              ></el-input-number>
              %

              <span
                v-if="
                  dialogChecked &&
                  !hasNumValue(dialogTableData[scope.$index][item.prop])
                "
                class="none-value-tip"
                >请输入</span
              >
            </div>
          </template>
        </el-table-column>
      </el-table>

      <div style="font-size: 13px; margin-top: 30px; color: #e6a23c">
        数值设置需要1~100的整数
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          style="float: left"
          type="primary"
          plain
          @click="resetDialogData"
          >重置</el-button
        >
        <el-button @click="visible = false">取 消</el-button>
        <el-button
          type="primary"
          :disabled="setLineLoading"
          @click="passLineConfirm"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      refreshing: false,
      setLineLoading: false,
      visible: false,
      dialogLoading: false,
      dialogChecked: false,
      originDialogTableData: [],
      dialogTableData: [],
      dialogTableColumns: [
        { label: "科目名称", prop: "courseName" },
        { label: "及格线", prop: "passScoreLine" },
        { label: "优秀线", prop: "goodScoreLine" },
      ],
      searchParams: {
        examId: "",
        courseId: "",
        orgId: "",
      },
      examList: [],
      courseList: [],
      orgList: [],
      examResult: {
        examName: "",
        allCount: "",
        finishCount: "",
        unFinishCount: "",
        unFinishRate: "",
        passScoreCount: "",
        passScoreRate: "",
        goodScoreCount: "",
        goodScoreRate: "",
        illegalityCount: "",
        illegalityRate: "",
      },
      orgTableData: [],
      orgTableColumns: [
        { label: "学习中心", prop: "orgName" },
        { label: "应考", prop: "allCount" },
        { label: "实考", prop: "finishCount" },
        { label: "缺考", prop: "unFinishCount" },
        { label: "缺考率（%）", prop: "unFinishRate" },
        { label: "及格人数", prop: "passScoreCount" },
        { label: "及格率（%）", prop: "passScoreRate" },
        { label: "优秀人数", prop: "goodScoreCount" },
        { label: "优秀率（%）", prop: "goodScoreRate" },
        { label: "违纪人数", prop: "illegalityCount" },
        { label: "违纪率（%）", prop: "illegalityRate" },
      ],
      courseTableData: [],
      courseTableColumns: [
        { label: "课程代码", prop: "courseCode" },
        { label: "课程名称", prop: "courseName" },
        { label: "应考", prop: "allCount" },
        { label: "实考", prop: "finishCount" },
        { label: "缺考", prop: "unFinishCount" },
        { label: "缺考率（%）", prop: "unFinishRate" },
        { label: "及格人数", prop: "passScoreCount" },
        { label: "及格率（%）", prop: "passScoreRate" },
        { label: "优秀人数", prop: "goodScoreCount" },
        { label: "优秀率（%）", prop: "goodScoreRate" },
        { label: "违纪人数", prop: "illegalityCount" },
        { label: "违纪率（%）", prop: "illegalityRate" },
      ],
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
  },
  created() {
    this.getExamList();
    this.getOrgList();
  },

  methods: {
    disableParam() {
      return !this.searchParams.examId;
    },
    getExamList(examName) {
      if (!examName) {
        examName = "";
      }
      this.$http
        .get("/api/ecs_exam_work/exam/queryByNameLike", {
          params: {
            enable: true,
            name: examName,
            examTypes: "ONLINE#OFFLINE#ONLINE_HOMEWORK",
          },
        })
        .then((response) => {
          this.examList = response.data;
        });
    },
    getOrgList() {
      this.$http
        .get("/api/ecs_core/org/query", {
          params: { name: "", rootOrgId: this.user.rootOrgId, enable: true },
        })
        .then((res) => {
          this.orgList = res.data;
        });
    },
    getCourseList() {
      this.courseList = [];
      this.searchParams.courseId = "";
      var examId = this.searchParams.examId;
      if (!examId) {
        return false;
      }
      this.$http
        .get("/api/ecs_oe_admin/exam/student/findCoursesByExamIdAndOrgId", {
          params: {
            examId: examId,
          },
        })
        .then((response) => {
          this.courseList = response.data;
        });
    },
    searchHandler() {
      this.getStatisticForExam();
      this.getStatisticForOrg();
      this.getStatisticForCourse();
    },
    changeExam() {
      this.getCourseList();
      this.getStatisticForExam();
      this.getStatisticForOrg();
      this.getStatisticForCourse();
    },
    changeOrg() {
      this.getStatisticForOrg();
    },
    changeCourse() {
      this.getStatisticForCourse();
    },
    getStatisticForExam() {
      const { examId } = this.searchParams;
      this.$http
        .post("/api/ecs_oe_admin/exam/statistic/overview/for/exam", null, {
          params: {
            examId,
          },
          headers: { "content-type": "application/x-www-form-urlencoded" },
        })
        .then((res) => {
          Object.assign(this.examResult, res.data || {});
        });
    },

    getStatisticForOrg() {
      const { examId, orgId } = this.searchParams;
      this.$http
        .post("/api/ecs_oe_admin/exam/statistic/overview/for/org", null, {
          params: { examId, orgId },
          headers: { "content-type": "application/x-www-form-urlencoded" },
        })
        .then((res) => {
          this.orgTableData = res.data || [];
        });
    },

    getStatisticForCourse() {
      const { examId, courseId } = this.searchParams;
      this.$http
        .post("/api/ecs_oe_admin/exam/statistic/overview/for/course", null, {
          params: { examId, courseId },
          headers: { "content-type": "application/x-www-form-urlencoded" },
        })
        .then((res) => {
          this.courseTableData = res.data || [];
        });
    },
    exportOrgStatisticExcel() {
      this.$confirm("确定执行导出?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const { examId, orgId } = this.searchParams;
        let key = this.user.key;
        let token = this.user.token;
        let url = `/api/ecs_oe_admin/exam/statistic/overview/for/org/export?examId=${examId}&orgId=${orgId}&$key=${key}&$token=${token}`;
        location.href = url;
      });
    },
    exportCourseStatisticExcel() {
      this.$confirm("确定执行导出?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const { examId, courseId } = this.searchParams;
        let key = this.user.key;
        let token = this.user.token;
        let url = `/api/ecs_oe_admin/exam/statistic/overview/for/course/export?examId=${examId}&courseId=${courseId}&$key=${key}&$token=${token}`;
        location.href = url;
      });
    },
    refreshHandler() {
      this.refreshing = true;
      this.$http
        .post(
          "/api/ecs_oe_admin/exam/statistic/overview/refresh",
          {},
          {
            params: {
              examId: this.searchParams.examId,
            },
          }
        )
        .then(() => {
          this.refreshing = false;
          this.searchHandler();
        })
        .catch((e) => {
          if (e.response.status === 500) {
            this.$notify({
              showClose: true,
              message: e.response.data.desc,
              type: "error",
            });
          }
        });
    },

    openDialog() {
      this.getDialogData();
      this.visible = true;
    },
    resetDialogData() {
      this.getDialogData();
    },
    getDialogData() {
      if (!this.searchParams.examId) {
        return false;
      }
      this.dialogLoading = true;
      this.$http
        .post("/api/ecs_exam_work/exam/course/list", {
          courseCode: "",
          courseName: "",
          examId: this.searchParams.examId,
          pageNo: 1,
          pageSize: 1000,
          weixinAnswerEnabled: null,
        })
        .then((res) => {
          this.dialogTableData = res.data.content || [];
          this.originDialogTableData = JSON.parse(
            JSON.stringify(res.data.content || [])
          );
          this.dialogLoading = false;
        });
    },
    hasNumValue(val) {
      return !!val || val === 0;
    },
    passLineConfirm() {
      this.dialogChecked = true;
      if (
        this.dialogTableData.every((item) => {
          return (
            this.hasNumValue(item.passScoreLine) &&
            this.hasNumValue(item.goodScoreLine)
          );
        })
      ) {
        this.setLineLoading = true;
        let num = this.dialogTableData.length;
        this.dialogTableData.forEach((item) => {
          this.$http
            .post(
              "/api/ecs_exam_work/exam/course/settingScoreLine",
              {},
              {
                params: {
                  courseId: item.courseId,
                  examId: item.examId,
                  goodScoreLine: item.goodScoreLine,
                  passScoreLine: item.passScoreLine,
                },
              }
            )
            .then(() => {
              num--;
              if (num === 0) {
                this.$message.success("设置成功");
                this.setLineLoading = false;
                this.visible = false;
              }
            })
            .catch(() => {
              this.setLineLoading = false;
            });
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.score-statistic {
  .none-value {
    ::v-deep .el-input__inner {
      border-color: #f56c6c;
    }
  }
  ::v-deep .none-value-tip {
    font-size: 12px;
    color: #f56c6c;
    margin-left: 10px;
  }
  .search-result {
    padding: 15px 0;
    margin-bottom: 30px;
    .result-column {
      width: 25%;
      text-align: center;
      .title {
        color: #333;
        font-size: 13px;
        font-weight: bold;
        line-height: 24px;
        margin-bottom: 5px;
        span {
          font-weight: bold;
          color: #409eff;
        }
      }
      .desc-item {
        line-height: 24px;
        font-size: 12px;
        color: #909399;
        span {
          font-weight: bold;
          color: #409eff;
        }
      }
    }
  }
}
</style>
