<template>
  <div>
    <LinkTitlesCustom
      :current-paths="['考试管理', '考试信息', '练习考试设置']"
    />
    <section class="content">
      <div class="box box-info">
        <!-- 正文信息 -->
        <div class="box-body">
          <el-form
            ref="form"
            :inline="true"
            :rules="rules"
            :model="form"
            label-position="right"
            inline-message
          >
            <div style="margin-bottom: 10px">
              <el-button type="primary" size="small" @click="saveExam"
                >保 存</el-button
              >
              <el-button
                type="primary"
                size="small"
                icon="el-icon-arrow-left"
                @click="back"
                >返 回</el-button
              >
            </div>
            <el-tabs v-model="activeName" type="border-card">
              <!-- 基础信息 -->
              <el-tab-pane label="基础信息" name="tab1">
                <el-row v-if="examId != 'add'">
                  <el-form-item
                    label="ID"
                    prop="id"
                    :label-width="style.label_width_tab1"
                  >
                    <el-input
                      v-model="form.id"
                      class="input"
                      :disabled="true"
                      maxlength="20"
                    ></el-input>
                  </el-form-item>
                </el-row>
                <el-row v-if="examId != 'add'">
                  <el-form-item
                    label="考试编码"
                    placeholder="请输入考试编码"
                    prop="code"
                    :label-width="style.label_width_tab1"
                  >
                    <el-input
                      v-model="form.code"
                      class="input"
                      :disabled="true"
                      maxlength="20"
                    ></el-input>
                  </el-form-item>
                </el-row>
                <el-row v-else>
                  <el-form-item
                    label="考试编码"
                    placeholder="请输入考试编码"
                    prop="code"
                    :label-width="style.label_width_tab1"
                  >
                    <el-input
                      v-model="form.name"
                      class="input"
                      :disabled="true"
                      maxlength="20"
                    ></el-input>
                  </el-form-item>
                </el-row>
                <el-row>
                  <el-form-item
                    label="考试名称"
                    :label-width="style.label_width_tab1"
                    placeholder="请输入考试名称"
                    prop="name"
                  >
                    <el-input
                      v-model="form.name"
                      maxlength="20"
                      class="input"
                    ></el-input>
                  </el-form-item>
                </el-row>
                <el-row>
                  <el-form-item
                    label="考试类型"
                    :label-width="style.label_width_tab1"
                  >
                    <el-select
                      v-model="form.examType"
                      class="input"
                      :disabled="true"
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in examTypeList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-row>
                <el-row>
                  <el-form-item
                    label="状态"
                    :label-width="style.label_width_tab1"
                  >
                    <el-radio-group v-model="form.enable" class="input">
                      <el-radio label="true">启用</el-radio>
                      <el-radio label="false">禁用</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-row>
                <el-row>
                  <el-form-item
                    label="考试时间"
                    prop="examDatetimeRange"
                    :label-width="style.label_width_tab1"
                  >
                    <el-date-picker
                      v-model="examDatetimeRange"
                      class="input"
                      type="datetimerange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      :clearable="false"
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-row>
              </el-tab-pane>
              <!-- 周期设置 -->
              <el-tab-pane label="周期设置" name="tab4">
                <el-row>
                  <el-form-item
                    label="是否开启周期设置"
                    :label-width="style.label_width_tab4"
                  >
                    <el-radio-group
                      v-model="form.properties.EXAM_CYCLE_ENABLED"
                      class="input"
                      @change="examCycleEnabledChange"
                    >
                      <el-radio label="true">开启</el-radio>
                      <el-radio label="false">不开启</el-radio>
                    </el-radio-group></el-form-item
                  >
                </el-row>
                <el-row v-if="form.properties.EXAM_CYCLE_ENABLED == 'true'">
                  <el-form-item
                    label="日期循环设置"
                    prop="examCycleWeekArr"
                    :label-width="style.label_width_tab4"
                  >
                    <el-checkbox-group
                      v-model="examCycleWeekArr"
                      style="width: 680px; display: -webkit-inline-box"
                    >
                      <el-checkbox :label="1">星期一</el-checkbox>
                      <el-checkbox :label="2">星期二</el-checkbox>
                      <el-checkbox :label="3">星期三</el-checkbox>
                      <el-checkbox :label="4">星期四</el-checkbox>
                      <el-checkbox :label="5">星期五</el-checkbox>
                      <el-checkbox :label="6">星期六</el-checkbox>
                      <el-checkbox :label="7">星期日</el-checkbox>
                    </el-checkbox-group></el-form-item
                  >
                </el-row>
                <template v-if="form.properties.EXAM_CYCLE_ENABLED == 'true'">
                  <el-row>
                    <el-col :span="14">
                      <el-form-item
                        label="时间分段设置"
                        :label-width="style.label_width_tab4"
                        prop="examCycleTimeRangeArr"
                      >
                      </el-form-item>
                    </el-col>
                    <el-col :span="10" style="line-height: 48px; height: 40px">
                      <i
                        class="el-icon-circle-plus"
                        style="color: #00a4ff; font-size: 24px"
                        @click="addCycleTimeRange"
                      ></i>
                    </el-col>
                  </el-row>

                  <el-row
                    v-for="(item, index) in examCycleTimeRangeArr"
                    :key="'tr' + index"
                  >
                    <el-col :span="14">
                      <el-form-item
                        :label-width="style.label_width_tab4"
                        :label="index + 1 + '.'"
                      >
                        <el-time-picker
                          v-model="item.timeRange"
                          class="input"
                          style="width: 100%"
                          is-range
                          start-placeholder="开始时间"
                          range-separator="至"
                          end-placeholder="结束时间"
                          format="HH:mm"
                          value-format="HH:mm"
                          :clearable="false"
                          size="small"
                        ></el-time-picker>
                      </el-form-item>
                    </el-col>
                    <el-col :span="10" style="line-height: 48px; height: 40px">
                      <i
                        class="el-icon-remove"
                        style="color: #00a4ff; font-size: 24px"
                        @click="removeExamCycleTimeRange(index)"
                      ></i>
                    </el-col>
                  </el-row>
                </template>
              </el-tab-pane>
              <!-- 控制设置 -->
              <el-tab-pane label="控制设置" name="tab2">
                <el-row>
                  <el-form-item
                    label="考试时长"
                    prop="duration"
                    :label-width="style.label_width_tab2"
                  >
                    <el-input
                      v-model.trim.number="form.duration"
                      maxlength="5"
                      class="input"
                      auto-complete="off"
                      ><template slot="append">分钟</template></el-input
                    >
                  </el-form-item>
                </el-row>
                <el-row>
                  <el-form-item
                    label="考试次数"
                    prop="examTimes"
                    :label-width="style.label_width_tab2"
                  >
                    <el-input
                      v-model.trim.number="form.examTimes"
                      maxlength="5"
                      class="input"
                      auto-complete="off"
                      ><template slot="append">次</template></el-input
                    >
                  </el-form-item>
                </el-row>
                <el-row>
                  <el-form-item
                    label="交卷冻结时间"
                    prop="FREEZE_TIME"
                    :label-width="style.label_width_tab2"
                  >
                    <el-input
                      v-model.trim.number="form.properties.FREEZE_TIME"
                      maxlength="5"
                      class="input"
                      auto-complete="off"
                      ><template slot="append">分钟</template></el-input
                    >
                    <el-radio-group
                      v-model="form.properties.FREEZE_TIME_TYPE"
                      style="margin-left: 30px"
                    >
                      <el-radio label="DURATION">按实际考试时长</el-radio>
                      <el-radio label="BEGINTIME">按考试开始时间</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-row>
                <el-row>
                  <el-form-item
                    label="断点续考时间"
                    prop="EXAM_RECONNECT_TIME"
                    :label-width="style.label_width_tab2"
                  >
                    <el-input
                      v-model.trim.number="form.properties.EXAM_RECONNECT_TIME"
                      maxlength="5"
                      class="input"
                      auto-complete="off"
                      ><template slot="append">分钟</template></el-input
                    >
                  </el-form-item>
                </el-row>
              </el-tab-pane>
              <el-tab-pane label="显示设置" name="tab3">
                <el-row>
                  <el-form-item
                    label="考生承诺书"
                    :label-width="style.label_width_tab3"
                  >
                    <el-radio-group
                      v-model="form.properties.SHOW_UNDERTAKING"
                      class="input"
                    >
                      <el-radio label="true">开启</el-radio>
                      <el-radio label="false">关闭</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-row>
                <el-row v-if="show_ckeditor">
                  <el-form-item
                    label="承诺书说明"
                    :label-width="style.label_width_tab3"
                  >
                    <ckeditor
                      v-model="form.properties.UNDERTAKING"
                      extrabuttons="Font,FontSize,TextColor"
                    ></ckeditor>
                  </el-form-item>
                </el-row>
                <el-row v-if="show_ckeditor">
                  <el-form-item
                    label="考前说明"
                    :label-width="style.label_width_tab3"
                  >
                    <ckeditor
                      v-model="form.properties.BEFORE_EXAM_REMARK"
                      extrabuttons="Font,FontSize,TextColor"
                    ></ckeditor>
                  </el-form-item>
                </el-row>
                <el-row>
                  <el-form-item
                    label="客观题成绩显示"
                    :label-width="style.label_width_tab3"
                  >
                    <el-radio-group
                      v-model="form.properties.IS_OBJ_SCORE_VIEW"
                      class="input"
                    >
                      <el-radio label="true">开启</el-radio>
                      <el-radio label="false">关闭</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-row>
                <el-row>
                  <el-form-item
                    label="练习模式"
                    :label-width="style.label_width_tab3"
                  >
                    <el-radio-group
                      v-model="form.properties.PRACTICE_TYPE"
                      class="input"
                    >
                      <el-radio label="IN_PRACTICE">边答边显示答案</el-radio>
                      <el-radio label="AFTER_PRACTICE"
                        >结束统一显示答案</el-radio
                      >
                      <el-radio label="NO_ANSWER">不显示答案</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-row>
              </el-tab-pane>
            </el-tabs>
          </el-form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { EXAM_TYPE, EXAM_WORK_API } from "@/constants/constants.js";
import moment from "moment";
import ckeditor from "@/components/ckeditor.vue";
import LinkTitlesCustom from "@/components/LinkTitlesCustom.vue";

let _this = null;

let validateExamCycleTimeRange = (rule, value, callback) => {
  if (_this.examCycleTimeRangeArr.length == 0) {
    callback(new Error("请至少设置一个时间段"));
  } else {
    callback();
  }
};

let validateExamCycleWeek = (rule, value, callback) => {
  if (_this.examCycleWeekArr.length == 0) {
    callback(new Error("请至少勾选一个日期"));
  } else {
    callback();
  }
};

let validateCode = (rule, value, callback) => {
  if (_this.examId == "add") {
    _this.form.code = _this.form.name;
  }
  let code = _this.form.code;
  if (code == "") {
    callback(new Error("请输入考试编码"));
    if (!_this.toActiveName) {
      _this.toActiveName = "tab1";
      _this.activeName = "tab1";
    }
  } else {
    callback();
  }
};

let validateName = (rule, value, callback) => {
  let name = _this.form.name;
  if (name == "") {
    callback(new Error("请输入考试名称"));
    if (!_this.toActiveName) {
      _this.toActiveName = "tab1";
      _this.activeName = "tab1";
    }
  } else {
    callback();
  }
};

let validateExamDatetimeRange = (rule, value, callback) => {
  let examDatetimeRange = _this.examDatetimeRange;
  if (!examDatetimeRange) {
    callback(new Error("请输入考试时间"));
    if (!_this.toActiveName) {
      _this.toActiveName = "tab1";
      _this.activeName = "tab1";
    }
  } else {
    callback();
  }
};

let validateDuration = (rule, value, callback) => {
  let duration = _this.form.duration;
  if (duration === "") {
    callback(new Error("请输入考试时长"));
    if (!_this.toActiveName) {
      _this.toActiveName = "tab2";
      _this.activeName = "tab2";
    }
  } else if (0 > duration || !duration.toString().match(/^[1-9]\d*|0$/)) {
    callback(new Error("只能是非负整数"));
    if (!_this.toActiveName) {
      _this.toActiveName = "tab2";
      _this.activeName = "tab2";
    }
  } else {
    callback();
  }
};

let validateExamTimes = (rule, value, callback) => {
  let examTimes = _this.form.examTimes;
  if (examTimes === "") {
    callback(new Error("请输入考试次数"));
    if (!_this.toActiveName) {
      _this.toActiveName = "tab2";
      _this.activeName = "tab2";
    }
  } else if (0 >= examTimes || !examTimes.toString().match(/^[1-9]\d*$/)) {
    callback(new Error("只能是正整数"));
    if (!_this.toActiveName) {
      _this.toActiveName = "tab2";
      _this.activeName = "tab2";
    }
  } else {
    callback();
  }
};

let validateFreezeTime = (rule, value, callback) => {
  let freezeTime = _this.form.properties.FREEZE_TIME;
  let duration = _this.form.duration;
  if (freezeTime === "") {
    callback(new Error("请输入交卷冻结时长"));
    if (!_this.toActiveName) {
      _this.toActiveName = "tab2";
      _this.activeName = "tab2";
    }
  } else if (0 > freezeTime || !freezeTime.toString().match(/^[1-9]\d*|0$/)) {
    callback(new Error("只能是非负整数"));
    if (!_this.toActiveName) {
      _this.toActiveName = "tab2";
      _this.activeName = "tab2";
    }
  } else if (duration != "" && parseInt(freezeTime) > parseInt(duration)) {
    callback(new Error("交卷冻结时长不能大于考试时长"));
    if (!_this.toActiveName) {
      _this.toActiveName = "tab2";
      _this.activeName = "tab2";
    }
  } else {
    callback();
  }
};

let validateExamReconnectTime = (rule, value, callback) => {
  let examReconnectTime = _this.form.properties.EXAM_RECONNECT_TIME;
  if (examReconnectTime === "") {
    callback(new Error("请输入断点续考时间"));
    if (!_this.toActiveName) {
      _this.toActiveName = "tab2";
      _this.activeName = "tab2";
    }
  } else if (
    0 >= examReconnectTime ||
    !examReconnectTime.toString().match(/^[1-9]\d*$/)
  ) {
    callback(new Error("只能是正整数"));
    if (!_this.toActiveName) {
      _this.toActiveName = "tab2";
      _this.activeName = "tab2";
    }
  } else if (examReconnectTime < 3) {
    callback(new Error("最小设置值为3"));
    if (!_this.toActiveName) {
      _this.toActiveName = "tab2";
      _this.activeName = "tab2";
    }
  } else {
    callback();
  }
};

export default {
  components: {
    ckeditor,
    LinkTitlesCustom,
  },
  data() {
    return {
      style: {
        label_width_tab1: "80px",
        label_width_tab2: "110px",
        label_width_tab3: "120px",
        label_width_tab4: "170px",
      },
      examCycleTimeRangeArr: [],
      examCycleWeekArr: [1, 2, 3, 4, 5, 6, 7],
      activeName: "tab1",
      toActiveName: null,
      examDatetimeRange: [],
      show_ckeditor: false,
      form: {
        started: false,
        name: "",
        examType: "PRACTICE",
        examTimes: 1,
        beginTime: null,
        endTime: null,
        duration: 120,
        enable: "true",
        properties: {
          FREEZE_TIME: 0,
          FREEZE_TIME_TYPE: "DURATION",
          EXAM_RECONNECT_TIME: 30,
          BEFORE_EXAM_REMARK: "",
          AFTER_EXAM_REMARK: "",
          IS_OBJ_SCORE_VIEW: "true",
          PRACTICE_TYPE: "IN_PRACTICE",
          EXAM_CYCLE_ENABLED: "false",
          EXAM_CYCLE_WEEK: "",
          EXAM_CYCLE_TIME_RANGE: "",
          SHOW_UNDERTAKING: "false",
          UNDERTAKING: "",
        },
      },
      examTypeList: EXAM_TYPE,
      examId: "",
      rules: {
        code: [{ required: true, validator: validateCode, trigger: "blur" }],
        name: [{ required: true, validator: validateName, trigger: "blur" }],
        examDatetimeRange: [
          {
            required: true,
            validator: validateExamDatetimeRange,
            trigger: "blur",
          },
        ],
        duration: [
          { required: true, validator: validateDuration, trigger: "blur" },
        ],
        examTimes: [
          { required: true, validator: validateExamTimes, trigger: "blur" },
        ],
        FREEZE_TIME: [
          { required: true, validator: validateFreezeTime, trigger: "blur" },
        ],
        EXAM_RECONNECT_TIME: [
          {
            required: true,
            validator: validateExamReconnectTime,
            trigger: "blur",
          },
        ],
        examCycleWeekArr: [
          {
            required: true,
            validator: validateExamCycleWeek,
            trigger: "change",
          },
        ],
        examCycleTimeRangeArr: [
          {
            required: true,
            validator: validateExamCycleTimeRange,
            trigger: "change",
          },
        ],
      },
    };
  },
  created() {
    _this = this;
    this.examId = this.$route.params.id;
    this.init();
  },

  methods: {
    examCycleEnabledChange(val) {
      if (val == "true") {
        this.examCycleWeekArr = [1, 2, 3, 4, 5, 6, 7];
        this.addCycleTimeRange();
      } else {
        this.examCycleTimeRangeArr = [];
        this.examCycleWeekArr = [];
      }
    },
    getEndTimeStr() {
      return "23:59";
    },
    getNowTimeStr() {
      let now = new Date();
      let hour = now.getHours();
      if (hour < 10) {
        hour = "0" + hour;
      }
      let minute = now.getMinutes();
      if (minute < 10) {
        minute = "0" + minute;
      }
      return hour + ":" + minute;
    },
    removeExamCycleTimeRange(index) {
      if (this.examCycleTimeRangeArr.length == 1) {
        this.$notify({
          type: "warning",
          message: "不能删除最后一个分段",
        });
        return;
      }
      this.examCycleTimeRangeArr.splice(index, 1);
      this.$refs.form.validateField("examCycleTimeRangeArr");
    },
    addCycleTimeRange() {
      if (this.examCycleTimeRangeArr.length >= 12) {
        this.$notify({
          type: "warning",
          message: "时间分段不得超过12条",
        });
        return;
      }
      this.examCycleTimeRangeArr.push({
        timeRange: [this.getNowTimeStr(), this.getEndTimeStr()],
      });
      this.$refs.form.validateField("examCycleTimeRangeArr");
    },
    init() {
      if (this.examId != "add") {
        let url = EXAM_WORK_API + "/exam/" + this.examId;
        this.$httpWithMsg.get(url).then((response) => {
          let body = response.data;
          body.properties = this.form.properties;
          this.form = Object.assign(this.form, response.data);
          this.form.enable = this.form.enable ? "true" : "false";
          this.examDatetimeRange = [this.form.beginTime, this.form.endTime];
          console.log("getOnlineExam(); form: ", this.form);

          let url = EXAM_WORK_API + "/exam/allProperties/" + this.examId;
          this.$httpWithMsg.get(url).then((response) => {
            this.form.properties = Object.assign(
              this.form.properties,
              response.data
            );
            if (this.form.properties.EXAM_CYCLE_TIME_RANGE) {
              this.examCycleTimeRangeArr = JSON.parse(
                this.form.properties.EXAM_CYCLE_TIME_RANGE
              );
            }
            if (this.form.properties.EXAM_CYCLE_WEEK) {
              this.examCycleWeekArr = JSON.parse(
                this.form.properties.EXAM_CYCLE_WEEK
              );
            }
            this.show_ckeditor = true;
          });
        });
      } else {
        let now = moment().format("YYYY-MM-DD HH:mm:ss");
        this.examDatetimeRange = [now, now];
        this.show_ckeditor = true;
      }
    },
    compare: function (x, y) {
      if (x < y) {
        return -1;
      } else if (x > y) {
        return 1;
      } else {
        return 0;
      }
    },
    saveExam: function () {
      this.toActiveName = null;
      this.form.beginTime = this.examDatetimeRange[0];
      this.form.endTime = this.examDatetimeRange[1];
      this.form.properties.EXAM_CYCLE_TIME_RANGE = JSON.stringify(
        this.examCycleTimeRangeArr
      );
      this.examCycleWeekArr.sort(this.compare);
      this.form.properties.EXAM_CYCLE_WEEK = JSON.stringify(
        this.examCycleWeekArr
      );
      if (!this.checkCkeditorVals()) {
        return false;
      }
      let url = EXAM_WORK_API + "/exam";
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.examId != "add") {
            this.$httpWithMsg.put(url, this.form).then((response) => {
              if (200 != response.status) {
                this.$notify({
                  type: "error",
                  message: response.body.desc,
                });
                return;
              }
              this.$notify({
                type: "success",
                message: "保存成功",
              });
            });
          } else {
            this.form.code = this.form.name;
            this.$httpWithMsg.post(url, this.form).then((response) => {
              console.log(response);
              this.$notify({
                type: "success",
                message: "新增成功",
              });
              this.examId = response.data.id;
              this.form.id = this.examId;
              this.$router.push({
                path: "/examwork/practiceExam/" + response.data.id,
              });
            });
          }
        } else {
          return false;
        }
      });
    },
    checkCkeditorVals() {
      if (
        this.form.properties.SHOW_UNDERTAKING == "true" &&
        !this.form.properties.UNDERTAKING
      ) {
        this.$notify({
          type: "warning",
          message: "请输入承诺书说明",
        });
        this.activeName = "tab3";
        return false;
      }
      return true;
    },
    back() {
      this.$router.push({ path: "/examwork/examInfo" });
    },
  },
};
</script>
<style scoped>
.input {
  width: 440px;
}
.input >>> .el-input__inner {
  -webkit-appearance: button;
}
</style>
