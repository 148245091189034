<template>
  <el-container>
    <el-main class="el-main-padding">
      <commonFormVue :form="form" :get-exam-condition="getExamCondition">
        <el-row v-show="showAllCondition">
          <el-col :span="6">
            <el-form-item label="考试记录ID">
              <el-input
                v-model="form.examRecordDataId"
                class="form_search_width"
                size="small"
                placeholder="考试记录ID"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="需要审核">
              <el-select
                v-model="form.isWarn"
                class="form_search_width"
                size="small"
                clearable
                placeholder="全部"
              >
                <el-option value="true" label="是"></el-option>
                <el-option value="false" label="否"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="陌生人脸">
              <el-select
                v-model="form.hasStranger"
                class="form_search_width"
                size="small"
                clearable
                placeholder="全部"
              >
                <el-option value="true" label="有"></el-option>
                <el-option value="false" label="无"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="虚拟设备">
              <el-select
                v-model="form.hasVirtual"
                class="form_search_width"
                size="small"
                clearable
                placeholder="全部"
              >
                <el-option value="true" label="有"></el-option>
                <el-option value="false" label="无"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="人脸比对">
              <el-input
                v-model="form.faceSuccessPercentLower"
                class="form_search_width_50"
                size="small"
                placeholder="下限"
              ></el-input>
              <span>-</span>
              <el-input
                v-model="form.faceSuccessPercentUpper"
                class="form_search_width_50"
                size="small"
                placeholder="上限"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="真实性">
              <el-row>
                <el-input
                  v-model="form.livenessSuccessPercentLower"
                  class="form_search_width_50"
                  size="small"
                  placeholder="下限"
                ></el-input>
                <span>-</span>
                <el-input
                  v-model="form.livenessSuccessPercentUpper"
                  class="form_search_width_50"
                  size="small"
                  placeholder="上限"
                ></el-input>
              </el-row>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="开考时间">
              <el-date-picker
                v-model="startExamDatetimeRange"
                class="input"
                type="datetimerange"
                start-placeholder="开始日期"
                range-separator="至"
                end-placeholder="结束日期"
                value-format="yyyy/MM/dd HH:mm:ss"
                :clearable="false"
                size="small"
                @change="changeStartExamDatetimeRange"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="交卷时间">
              <el-date-picker
                v-model="endExamDatetimeRange"
                class="input"
                type="datetimerange"
                start-placeholder="开始日期"
                range-separator="至"
                end-placeholder="结束日期"
                value-format="yyyy/MM/dd HH:mm:ss"
                :clearable="false"
                size="small"
                @change="changeEndExamDatetimeRange"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="其它异常">
              <el-select
                v-model="form.warnType"
                class="form_search_width"
                size="small"
                clearable
                placeholder="全部"
              >
                <el-option value="MISS_FACE" label="开考照片缺失"></el-option>
                <el-option
                  value="SKIP_FACE"
                  label="人工跳过人脸验证"
                ></el-option>
                <el-option
                  value="FACE_LIVE_UNPASS"
                  label="过程活体不通过"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </commonFormVue>
      <el-col :span="24">
        <el-button
          size="small"
          type="primary"
          icon="el-icon-search"
          @click="search('clickSelectBtn')"
          >查询</el-button
        >
        <el-button
          v-if="!showAllCondition"
          size="small"
          type="primary"
          icon="el-icon-more"
          @click="showMoreCondition"
          >高级查询</el-button
        >
        <el-button
          v-if="showAllCondition"
          size="small"
          type="primary"
          @click="showSimpleCondition"
          >简单查询</el-button
        >
        <el-button
          size="small"
          icon="el-icon-refresh"
          class="margin-bottom-10"
          @click="resetForm"
          >重置</el-button
        >
      </el-col>
      <el-row>
        <el-col v-show="currentPagePrivileges.PANEING_BATCHAUDIT">
          <div class="block-seperator"></div>
          <span>操作：</span>
          <el-dropdown class="button_left">
            <el-button size="small" type="primary">
              批量审核
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <el-button
                  size="mini"
                  type="success"
                  :disabled="batchAuditBtnDisabled"
                  @click="batchAudit('pass')"
                >
                  <i class="el-icon-success"></i> 通 &nbsp;&nbsp;过
                </el-button>
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button
                  size="mini"
                  type="danger"
                  :disabled="batchAuditBtnDisabled"
                  @click="openBatchAudit()"
                >
                  <i class="el-icon-error"></i> 不通过
                </el-button>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
      </el-row>
      <el-row class="margin-top-10">
        <el-col :span="24">
          <el-table
            ref="multipleTable"
            v-loading="tableLoading"
            element-loading-text="数据加载中"
            :data="tableData"
            border
            resizable
            stripe
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              type="selection"
              width="55"
              :selectable="selectable"
            ></el-table-column>
            <el-table-column label="考试记录ID" width="95">
              <template slot-scope="scope">
                <el-button
                  v-show="currentPagePrivileges.SNAPSHOT_DETAILS"
                  type="text"
                  @click="gotoCaptureDetail(scope.row)"
                  >{{ scope.row.dataId }}</el-button
                >
                <span v-show="!currentPagePrivileges.SNAPSHOT_DETAILS">
                  {{ scope.row.dataId }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              label="姓名"
              prop="studentName"
              min-width="100"
            ></el-table-column>
            <el-table-column
              label="身份证号"
              prop="identityNumber"
              min-width="100"
            ></el-table-column>
            <el-table-column
              label="学号"
              prop="studentCode"
              min-width="100"
            ></el-table-column>
            <el-table-column
              label="学习中心"
              prop="orgName"
              min-width="90"
            ></el-table-column>
            <el-table-column
              label="课程"
              prop="courseName"
              min-width="90"
            ></el-table-column>
            <el-table-column
              label="课程层次"
              prop="courseLevel"
              width="80"
            ></el-table-column>
            <el-table-column
              label="客观题总分"
              prop="objectiveTotalScore"
              width="95"
            ></el-table-column>
            <el-table-column
              label="校验次数"
              prop="faceTotalCount"
              width="80"
            ></el-table-column>
            <el-table-column
              label="成功次数"
              prop="faceSuccessCount"
              width="80"
            ></el-table-column>
            <el-table-column
              label="陌生人次数"
              prop="faceStrangerCount"
              width="95"
            ></el-table-column>
            <el-table-column
              label="人脸比对(%)"
              prop="faceSuccessPercent"
              width="100"
            ></el-table-column>
            <el-table-column
              label="人脸真实性(%)"
              prop="baiduFaceLivenessSuccessPercent"
              width="115"
            ></el-table-column>
            <el-table-column
              label="开考时间"
              prop="paperStartTime"
              width="180"
            ></el-table-column>
            <el-table-column
              label="交卷时间"
              prop="paperSubmitTime"
              width="180"
            ></el-table-column>
            <el-table-column label="IP" prop="ip" width="125">
              <template slot-scope="scope">
                {{ showShortIpsStr(scope.row.ip, ",") }}
              </template>
            </el-table-column>
            <el-table-column
              label="虚拟设备"
              prop="virtualCameraNames"
              width="100"
            ></el-table-column>
            <el-table-column
              v-if="currentPagePrivileges.PENDING_OPERATE"
              :context="_self"
              label="操作"
              fixed="right"
              width="100"
            >
              <div slot-scope="scope">
                <!-- <el-row
                  v-if="scope.row.examType != 'OFFLINE'"
                  class="operateRow"
                >
                  <el-col :span="24">
                    <el-button
                      size="mini"
                      type="primary"
                      icon="el-icon-view"
                      @click="examPaperDetail(scope.row)"
                      >调卷</el-button
                    >
                  </el-col>
                </el-row>-->
                <el-row v-if="scope.row.isWarn" class="operateRow">
                  <el-col :span="24">
                    <el-dropdown>
                      <el-button
                        size="mini"
                        icon="el-icon-arrow-down"
                        type="primary"
                        plain
                        >审核</el-button
                      >
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item>
                          <el-button
                            v-if="scope.row.isWarn"
                            size="mini"
                            type="success"
                            icon="el-icon-success"
                            @click="auditPass(scope.row.dataId, false)"
                            >通&nbsp;&nbsp;&nbsp;&nbsp;过</el-button
                          >
                        </el-dropdown-item>
                        <el-dropdown-item>
                          <el-button
                            v-if="scope.row.isWarn"
                            size="mini"
                            type="danger"
                            icon="el-icon-error"
                            @click="openAuditDialog(scope.row.dataId, false)"
                            >不通过</el-button
                          >
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </div>
            </el-table-column>
          </el-table>
          <div class="block pull-right">
            <el-pagination
              :current-page.sync="form.pageNo"
              :page-sizes="[10, 20, 50, 100, 200, 300]"
              :page-size="form.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            ></el-pagination>
          </div>
        </el-col>
      </el-row>
      <el-dialog
        title="审核"
        width="400px"
        :visible.sync="dialogFormVisible"
        @closed="auditDialogClosed"
      >
        <el-form ref="auditForm" :model="auditForm" label-width="90px">
          <el-form-item
            label="违纪类型"
            prop="illegallyTypeId"
            :rules="[
              { required: true, message: '请选择违纪类型', trigger: 'change' },
            ]"
          >
            <el-select
              v-model="auditForm.illegallyTypeId"
              filterable
              remote
              :remote-method="getDisciplineTypeList"
              clearable
              placeholder="请选择"
              size="small"
              @clear="getDisciplineTypeList"
            >
              <el-option
                v-for="item in disciplineTypeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="详情描述" style="margin-top: 15px">
            <el-input
              v-model="auditForm.disciplineDetail"
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 5 }"
              placeholder="请输入内容"
              maxlength="100"
            ></el-input>
          </el-form-item>
          <div class="dialog-footer margin-top-10 text-center">
            <el-button type="primary" @click="doAudit">确 定</el-button>
            <el-button @click="dialogFormVisible = false">取 消</el-button>
          </div>
        </el-form>
      </el-dialog>
      <el-dialog
        title="批量审核"
        width="400px"
        :visible.sync="dialogBatchFormVisible"
        @closed="batchAuditDialogClosed"
      >
        <el-form
          ref="batchAuditForm"
          :model="batchAuditForm"
          label-width="90px"
        >
          <el-form-item
            label="违纪类型"
            prop="illegallyTypeId"
            :rules="[
              { required: true, message: '请选择违纪类型', trigger: 'change' },
            ]"
          >
            <el-select
              v-model="batchAuditForm.illegallyTypeId"
              filterable
              remote
              :remote-method="getDisciplineTypeList"
              clearable
              placeholder="请选择"
              size="small"
              @clear="getDisciplineTypeList"
            >
              <el-option
                v-for="item in disciplineTypeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="详情描述" style="margin-top: 15px">
            <el-input
              v-model="batchAuditForm.disciplineDetail"
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 5 }"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <div class="dialog-footer margin-top-10 text-center">
            <el-button type="primary" @click="doBatchAudit">确 定</el-button>
            <el-button @click="dialogBatchFormVisible = false">取 消</el-button>
          </div>
        </el-form>
      </el-dialog>

      <el-dialog
        title="考试记录详情"
        width="1250px"
        :visible.sync="examRecordDataDialog"
        @close="examRecordDataDialogClose"
      >
        <ExamRecordDetail
          :exam-record-data-id="curSelectedExamRecordDataId"
          :show-audit-button="curSelectedShowAuditButton"
          :need-loading="needLoading"
          @changeExamRecordData="changeExamRecordData"
          @auditExamRecordData="auditExamRecordData"
        ></ExamRecordDetail>
      </el-dialog>
    </el-main>
  </el-container>
</template>
<script>
import { mapState } from "vuex";
import pagePrivilege from "../mixin/pagePrivilege.js";
import commonFormVue from "../component/commonForm.vue";
import { showShortIpsStr } from "@/utils/utils.js";
import _ from "lodash";
export default {
  components: { commonFormVue },
  mixins: [pagePrivilege],
  // beforeRouteEnter(to, from, next) {
  //   next((vm) => {
  //     if (from.params?.checkedRows) {
  //       const unwatch = vm.$watch("tableData", (tableData = []) => {
  //         tableData.forEach((d) => {
  //           vm.$refs["multipleTable"]?.toggleRowSelection(
  //             d,
  //             from.params.checkedRows.includes(d.dataId)
  //           );
  //         });
  //         if (unwatch) {
  //           unwatch();
  //         }
  //       });
  //     }
  //   });
  // },
  // beforeRouteLeave(to, from, next) {
  //   to.params.checkedRows = this.routeSelectedIds;
  //   next();
  // },
  data() {
    return {
      showShortIpsStr,
      curSelectedExamRecordDataId: null,
      curSelectedShowAuditButton: false,
      needLoading: false,
      examRecordDataDialog: false,
      toNext: false,
      curTotalPages: 0,
      routeSelectedIds: [],
      total: 0,
      tableLoading: false,
      exportLoading: false,
      dialogBatchFormVisible: false,
      dialogFormVisible: false,
      showAllCondition: false,
      form: {
        examRecordDataId: null,
        hasStranger: null,
        courseId: null,
        courseLevel: null,
        examId: null,
        examStageId: null,
        faceSuccessPercentLower: null,
        faceSuccessPercentUpper: null,
        livenessSuccessPercentLower: null,
        livenessSuccessPercentUpper: null,
        identityNumber: null,
        orgId: null,
        studentCode: null,
        studentName: null,
        isWarn: null,
        pageNo: 1,
        pageSize: 10,
        ORG_FIND_ALL: false, //查询所有机构
        hasVirtual: null,
        startTime: null, //开考时间起
        endTime: null, //开考时间止
        submitStartTime: null, //交卷时间起
        submitEndTime: null, //交卷时间止
        warnType: null,
      },
      auditForm: {
        examRecordDataId: null,
        illegallyTypeId: null,
        disciplineDetail: "",
        isPass: null,
      },
      batchAuditForm: {
        examRecordDataIds: null,
        illegallyTypeId: null,
        disciplineDetail: "",
        isPass: null,
      },
      getExamCondition: {
        params: {
          name: "",
          examTypes: "ONLINE#ONLINE_HOMEWORK",
          propertyKeys: "IS_FACE_ENABLE",
        },
        filterCondition: "IS_FACE_ENABLE",
      },
      tableData: [],
      multipleSelection: [],
      batchAuditBtnDisabled: true,
      currentPagePrivileges: {
        PANEING_BATCHAUDIT: false,
        PENDING_OPERATE: false,
        SNAPSHOT_DETAILS: false,
      },
      disciplineTypeList: [],
      startExamDatetimeRange: [],
      endExamDatetimeRange: [],
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
  },
  watch: {
    multipleSelection: function () {
      this.batchAuditBtnDisabled = !(this.multipleSelection.length > 0);
    },
  },
  created() {
    // this.backFill();
    this.getDisciplineTypeList("");
  },
  methods: {
    getDisciplineTypeList(name) {
      if (!name) {
        name = "";
      }
      this.$http
        .get("/api/ecs_oe_admin/illegallyType/queryByNameLike", {
          params: { name, queryScope: "audit" },
        })
        .then((response) => {
          this.disciplineTypeList = response.data;
        });
    },
    resetForm() {
      this.form = {
        examRecordDataId: null,
        hasStranger: null,
        courseId: null,
        courseLevel: null,
        examId: null,
        examStageId: null,
        faceSuccessPercentLower: null,
        faceSuccessPercentUpper: null,
        livenessSuccessPercentLower: null,
        livenessSuccessPercentUpper: null,
        identityNumber: null,
        orgId: this.form.ORG_FIND_ALL ? null : this.form.orgId,
        studentCode: null,
        studentName: null,
        isWarn: null,
        pageNo: 1,
        pageSize: 10,
        ORG_FIND_ALL: this.form.ORG_FIND_ALL,
        startTime: null, //开考时间起
        endTime: null, //开考时间止
        submitStartTime: null, //交卷时间起
        submitEndTime: null, //交卷时间止
      };
      this.startExamDatetimeRange = [];
      this.endExamDatetimeRange = [];
    },
    showMoreCondition() {
      this.showAllCondition = true;
    },
    showSimpleCondition() {
      this.$notify({
        title: "提示",
        message: "高级查询条件值已重置",
        type: "info",
        duration: 2000,
      });
      this.resetForm();
      this.showAllCondition = false;
    },
    async search(type) {
      if (!this.form.examId) {
        this.$notify({
          title: "警告",
          message: "请选择考试",
          type: "warning",
          duration: 2000,
        });
        return false;
      }
      if (type && type == "clickSelectBtn") {
        this.form.pageNo = 1;
      }
      this.tableLoading = true;
      var params = JSON.parse(JSON.stringify(this.form));
      await this.$http
        .post("/api/ecs_oe_admin/exam/record/waiting/audit/list", params)
        .then((response) => {
          if (response.data) {
            this.tableData = response.data.content;
            this.total = response.data.totalElements;
            this.form.pageNo = response.data.number + 1;
            this.curTotalPages = response.data.totalPages;
          } else {
            this.tableData = [];
          }
          this.tableLoading = false;
          // this.$router.push({
          //   path: "/oe/awaitingAudit?" + new URLSearchParams(params),
          // });
        });
    },
    formatVirtualCameraName(row) {
      if (row.virtualCameraNames === "") {
        return ";";
      } else {
        return row.virtualCameraNames;
      }
    },
    selectable(row) {
      return row.isWarn;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /**
     * pagesize改变时触发
     */
    handleSizeChange(val) {
      this.form.pageSize = val;
      this.search();
    },
    /**
     * 当前页改变时触发
     */
    handleCurrentChange(val) {
      this.form.pageNo = val;
      this.search();
    },
    batchAudit(operate) {
      var isPass = operate == "pass" ? true : false;
      this.$confirm("确定执行?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var examRecordDataIds = [];
          for (var i = 0; i < this.multipleSelection.length; i++) {
            examRecordDataIds.push(this.multipleSelection[i].dataId);
          }
          var param = new URLSearchParams({
            examRecordDataIds: examRecordDataIds,
            isPass: isPass,
          });
          this.$http
            .post("/api/ecs_oe_admin/exam/audit/batch/audit", param)
            .then(() => {
              this.$notify({
                title: "成功",
                message: "操作成功",
                type: "success",
                duration: 5000,
              });
              // this.search();
            });
        })
        .catch(() => {});
    },
    openAuditDialog(examRecordDataId, toNext) {
      this.toNext = toNext;
      this.dialogFormVisible = true;
      this.auditForm = {
        examRecordDataId: examRecordDataId,
        illegallyTypeId: null,
        disciplineDetail: "",
        isPass: false,
      };
    },
    examPaperDetail(row) {
      window.open(
        "/admin/oe/examPaperDetail/" + row.courseId + "/" + row.dataId
      );
    },
    openBatchAudit() {
      this.dialogBatchFormVisible = true;
      var examRecordDataIds = [];
      for (var i = 0; i < this.multipleSelection.length; i++) {
        examRecordDataIds.push(this.multipleSelection[i].dataId);
      }
      this.batchAuditForm = {
        examRecordDataIds: examRecordDataIds,
        illegallyTypeId: null,
        disciplineDetail: "",
        isPass: false,
      };
    },
    doAudit() {
      this.$refs["auditForm"].validate((valid) => {
        if (valid) {
          var param = new URLSearchParams(this.auditForm);
          this.$http
            .post("/api/ecs_oe_admin/exam/audit/single/audit", param)
            .then(() => {
              this.$notify({
                title: "成功",
                message: "操作成功",
                type: "success",
                duration: 5000,
              });
              this.dialogFormVisible = false;
              // this.search();
              if (this.toNext) {
                this.changeExamRecordData(true);
              }
            });
        } else {
          return false;
        }
      });
    },
    doBatchAudit() {
      this.$refs["batchAuditForm"].validate((valid) => {
        if (valid) {
          this.$confirm("确定执行?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            var param = new URLSearchParams(this.batchAuditForm);
            this.$http
              .post("/api/ecs_oe_admin/exam/audit/batch/audit", param)
              .then(() => {
                this.$notify({
                  title: "成功",
                  message: "操作成功",
                  type: "success",
                  duration: 5000,
                });
                this.dialogBatchFormVisible = false;
                // this.search();
              });
          });
        } else {
          return false;
        }
      });
    },
    auditDialogClosed() {
      this.$refs["auditForm"].resetFields();
    },
    batchAuditDialogClosed() {
      this.$refs["batchAuditForm"].resetFields();
    },
    /**
     * 审核通过
     */
    auditPass(examRecordDataId, toNext) {
      var auditParams = {
        examRecordDataId: examRecordDataId,
        isPass: true,
      };
      var param = new URLSearchParams(auditParams);
      this.$http
        .post("/api/ecs_oe_admin/exam/audit/single/audit", param)
        .then(() => {
          this.$notify({
            title: "成功",
            message: "操作成功",
            type: "success",
            duration: 5000,
          });

          // this.search();
          if (toNext) {
            this.changeExamRecordData(true);
          }
        });
    },
    curSelectedRow(examRecordDataId) {
      for (let n = 0; n < this.tableData.length; n++) {
        let row = this.tableData[n];
        if (examRecordDataId === row.dataId) {
          this.$refs["multipleTable"]?.toggleRowSelection(row, true);
        } else {
          this.$refs["multipleTable"]?.toggleRowSelection(row, false);
        }
      }
    },
    examRecordDataDialogClose() {
      this.examRecordDataDialog = false;
      this.search("clickSelectBtn");
    },
    examRecordDataDialogOpen(examRecordDataId, showAuditButton) {
      this.examRecordDataDialog = true;
      this.curSelectedShowAuditButton = showAuditButton;
      this.curSelectedExamRecordDataId = examRecordDataId;
      this.curSelectedRow(examRecordDataId);
    },
    async changeExamRecordData(isNext) {
      // console.log("isNext:" + isNext);
      let curId = null;
      let isFirst = false;
      let isLast = false;
      let rowSize = this.tableData.length;
      for (let n = 0; n < rowSize; n++) {
        isFirst = n === 0;
        isLast = n === rowSize - 1;
        let row = this.tableData[n];
        if (this.curSelectedExamRecordDataId === row.dataId) {
          curId = row.dataId;
          if (isNext) {
            if (!isLast) {
              curId = this.tableData[n + 1].dataId;
            } else {
              if (this.form.pageNo < this.curTotalPages) {
                // 跳到下一页
                curId = null;
                this.form.pageNo = this.form.pageNo + 1;
                console.log("+++>toPage:" + this.form.pageNo);
                this.needLoading = true;
                await this.search();
                this.needLoading = false;

                if (this.tableData.length > 0) {
                  curId = this.tableData[0].dataId;
                }
              } else {
                this.$message({
                  message: "当前数据为最后一条！",
                  type: "warning",
                  showClose: true,
                });
              }
            }
          } else {
            if (!isFirst) {
              curId = this.tableData[n - 1].dataId;
            } else {
              if (this.form.pageNo > 1) {
                // 跳到上一页
                curId = null;
                this.form.pageNo = this.form.pageNo - 1;
                console.log("--->toPage:" + this.form.pageNo);
                this.needLoading = true;
                await this.search();
                this.needLoading = false;

                if (this.tableData.length > 0) {
                  curId = this.tableData[this.tableData.length - 1].dataId;
                }
              } else {
                this.$message({
                  message: "当前数据为第一条！",
                  type: "warning",
                  showClose: true,
                });
              }
            }
          }
          break;
        }
      }

      if (curId) {
        this.curSelectedExamRecordDataId = curId;
        this.curSelectedRow(curId);
      } else {
        this.examRecordDataDialogClose();
      }
    },
    auditExamRecordData(isPass) {
      console.log("isPass:" + isPass);
      if (isPass) {
        this.auditPass(this.curSelectedExamRecordDataId, true);
      } else {
        this.openAuditDialog(this.curSelectedExamRecordDataId, true);
      }
    },
    gotoCaptureDetail(row) {
      if (!this.form.examId) {
        this.$notify({
          title: "警告",
          message: "请选择考试",
          type: "warning",
          duration: 2000,
        });
        return false;
      }

      let pendingOperate = this.currentPagePrivileges.PENDING_OPERATE;
      this.examRecordDataDialogOpen(row.dataId, pendingOperate);

      // this.routeSelectedIds = [];
      // this.routeSelectedIds.push(row.dataId);
      // this.$refs["multipleTable"]?.toggleRowSelection(row, true);

      // let searchParam = JSON.stringify(this.form);
      // this.$nextTick(() => {
      //   /** checkbox UI选中状态延迟 */
      //   setTimeout(() => {
      //     this.$router.push({
      //       path: "/oe/captureDetail/" + row.dataId + "/awaitingAudit",
      //       query: { pendingOperate, searchParam },
      //     });
      //   }, 200);
      // });
    },
    backFill() {
      var formData = this.$route.query;
      if (formData && formData.examId) {
        for (var attr in formData) {
          var value = formData[attr];
          if (value && value != "null") {
            //是数字 且 长度小于15
            if (!isNaN(value) && value.toString().length < 15) {
              value = _.toNumber(value);
            }
            this.form[attr] = value;
          }
        }
        this.search();
      }
    },
    changeStartExamDatetimeRange(e) {
      if (e && e.length > 0) {
        this.form.startTime = e[0];
        this.form.endTime = e[1];
      } else {
        this.form.startTime = "";
        this.form.endTime = "";
      }
    },
    changeEndExamDatetimeRange(e) {
      if (e && e.length > 0) {
        this.form.submitStartTime = e[0];
        this.form.submitEndTime = e[1];
      } else {
        this.form.submitStartTime = "";
        this.form.submitEndTime = "";
      }
    },
  },
};
</script>
<style scoped src="../style/common.css"></style>
<style lang="scss" scoped>
.el-form-item {
  margin-bottom: 15px !important;
  :deep(.el-form-item__label) {
    margin-bottom: 2px !important;
  }
}
</style>
