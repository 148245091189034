<template>
  <section class="content" style="margin-top: -10px">
    <div class="box box-info">
      <!-- 正文信息 -->
      <div class="box-body">
        <div style="margin-top: 20px">
          <el-button
            size="small"
            type="primary"
            icon="el-icon-upload2"
            @click="imp"
            >导入
          </el-button>
        </div>
        <div style="width: 100%; margin-bottom: 10px"></div>

        <!-- 导入弹窗 -->
        <el-dialog
          title="考生信息导入页"
          size="tiny"
          :visible.sync="studentImportDialog"
        >
          <el-form ref="studentImportForm" :model="studentImportForm">
            <el-row>
              <el-form-item style="margin-left: 30px" label="考试" prop="value">
                <el-select
                  v-model="studentImportForm.examId"
                  class="input"
                  :remote-method="queryExams"
                  remote
                  :loading="queryExamsLoading"
                  filterable
                  clearable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in examList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                    :disabled="!item.enable"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item style="margin-left: 30px">
                <el-upload
                  ref="upload"
                  class="form_left"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  :action="uploadAction"
                  :headers="uploadHeaders"
                  :data="uploadData"
                  :before-upload="beforeUpload"
                  :on-progress="uploadProgress"
                  :on-success="uploadSuccess"
                  :on-error="uploadError"
                  :file-list="fileList"
                  :auto-upload="false"
                  :multiple="false"
                >
                  <el-button
                    slot="trigger"
                    size="small"
                    type="primary"
                    icon="el-icon-search"
                    >选择文件</el-button
                  >
                  <el-button
                    size="small"
                    style="margin-left: 10px"
                    type="primary"
                    icon="el-icon-check"
                    @click="submitUpload"
                    >确认上传
                  </el-button>
                  <el-button
                    size="small"
                    style="margin-left: 10px"
                    type="primary"
                    icon="el-icon-refresh"
                    @click="removeFile"
                    >清空文件
                  </el-button>
                  <el-button
                    size="small"
                    type="primary"
                    icon="el-icon-download"
                    @click="exportFile"
                    >下载模板
                  </el-button>
                  <div slot="tip" class="el-upload__tip">只能上传xlsx文件</div>
                </el-upload>
              </el-form-item>
            </el-row>
          </el-form>
        </el-dialog>

        <!-- 页面列表 -->
        <el-table
          :data="tableData"
          border
          style="width: 100%; text-align: center"
        >
          <el-table-column
            prop="id"
            width="80"
            label="ID"
            sortable
          ></el-table-column>
          <el-table-column prop="rootOrgName" label="学校" sortable>
          </el-table-column>
          <el-table-column prop="examName" label="考试" sortable>
          </el-table-column>
          <el-table-column prop="fileName" label="上传文件名" sortable>
          </el-table-column>
          <el-table-column prop="statusDesc" width="120" label="状态">
          </el-table-column>
          <el-table-column prop="errorDesc" label="异常"> </el-table-column>
          <el-table-column
            prop="creationTime"
            width="170"
            label="上传时间"
            sortable
          >
          </el-table-column>
          <el-table-column label="操作" width="230">
            <template slot-scope="scope">
              <div>
                <el-button
                  size="mini"
                  type="primary"
                  plain
                  icon="el-icon-download"
                  @click="getUploadedFile(scope.row.id)"
                  >下载文件
                </el-button>
                <el-button
                  :disabled="
                    !(
                      scope.row.status == 'DATA_PROCESSING_COMPLETE' ||
                      scope.row.status == 'ERROR'
                    )
                  "
                  size="mini"
                  type="primary"
                  plain
                  icon="el-icon-download"
                  @click="getReports(scope.row.id)"
                  >导出报告
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="page pull-right">
          <el-pagination
            :current-page="currentPage"
            :page-size="pageSize"
            :page-sizes="[10, 20, 50, 100, 200, 300]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { TASK_API, EXAM_WORK_API } from "@/constants/constants.js";
import { mapState } from "vuex";

let _this = null;

export default {
  data() {
    return {
      formSearch: {},
      uploadAction: TASK_API + "/examStudentImport/import",
      studentImportDialog: false,
      examList: [],
      uploadHeaders: {},
      uploadData: { examId: "" },
      fileList: [],
      fileLoading: false,
      queryExamsLoading: false,
      studentImportForm: {
        examId: null,
      },

      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
  },
  created() {
    _this = this;
    this.queryTableDatas();
    this.uploadHeaders = {
      key: this.user.key,
      token: this.user.token,
    };
  },
  methods: {
    handleCurrentChange(val) {
      this.currentPage = val;
      this.queryTableDatas();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryTableDatas();
    },
    imp() {
      this.studentImportDialog = true;
      this.initUpload();
    },
    initUpload() {
      if (this.$refs.studentImportForm) {
        this.$refs.studentImportForm.resetFields();
      }
      this.fileList = [];
      this.studentImportForm.examId = null;
      this.examList = [];
      this.queryExams("");
    },
    beforeUpload(file) {
      console.log(file);
    },
    uploadProgress(event, file, fileList) {
      console.log("uploadProgress");
      console.log(event);
      console.log(file);
      console.log(fileList);
    },
    uploadSuccess(response, file, fileList) {
      console.log("uploadSuccess");
      console.log(response);
      console.log(file);
      console.log(fileList);
      this.$notify({
        message: "上传成功",
        type: "success",
      });
      this.fileLoading = false;
      this.studentImportDialog = false;
      this.queryTableDatas();
    },
    uploadError(response, file, fileList) {
      console.log("uploadError");
      console.log(response);
      console.log(file);
      console.log(fileList);
      var json = JSON.parse(response.message);
      if (response.status == 500) {
        this.$notify({
          message: json.desc,
          type: "error",
        });
      }
      this.fileLoading = false;
    },
    //确定上传
    submitUpload() {
      if (!this.checkUpload()) {
        return false;
      }
      this.$refs.upload.submit();
      this.fileLoading = true;
    },
    checkUpload() {
      if (!this.studentImportForm.examId) {
        this.$notify({
          message: "请选择考试",
          type: "error",
        });
        return false;
      } else {
        this.uploadData.examId = this.studentImportForm.examId;
      }
      var fileList = this.$refs.upload.uploadFiles;
      if (fileList.length == 0) {
        this.$notify({
          message: "上传文件不能为空",
          type: "error",
        });
        return false;
      }
      if (fileList.length > 1) {
        this.$notify({
          message: "每次只能上传一个文件",
          type: "error",
        });
        return false;
      }
      for (let file of fileList) {
        if (!file.name.endsWith(".xlsx")) {
          this.$notify({
            message: "上传文件必须为xlsx格式",
            type: "error",
          });
          this.initUpload();
          return false;
        }
      }
      return true;
    },
    //清空文件
    removeFile() {
      this.$refs.upload.clearFiles();
    },
    //下载模板
    exportFile() {
      window.location.href =
        "/api/ecs_exam_work/exam_student/downloadTemplate?$key=" +
        this.user.key +
        "&$token=" +
        this.user.token;
    },
    //下载报告
    getReports(id) {
      window.location.href =
        TASK_API +
        "/examStudentImport/getReports/" +
        id +
        "?$key=" +
        this.user.key +
        "&$token=" +
        this.user.token;
    },
    //下载导入文件
    getUploadedFile(id) {
      window.location.href =
        TASK_API +
        "/examStudentImport/getUploadedFile/" +
        id +
        "?$key=" +
        this.user.key +
        "&$token=" +
        this.user.token;
    },
    queryExams(name) {
      console.log("queryExams; name: " + name);
      this.queryExamsLoading = true;
      this.$httpWithMsg
        .get(EXAM_WORK_API + "/exam/queryByNameLike?enable=true&name=" + name)
        .then((response) => {
          this.queryExamsLoading = false;
          this.examList = response.data;
        })
        .catch(() => {
          this.queryExamsLoading = false;
        });
    },
    queryTableDatas: function () {
      var url =
        TASK_API +
        "/examStudentImport/all/" +
        (this.currentPage - 1) +
        "/" +
        this.pageSize;
      this.$httpWithMsg.get(url).then((response) => {
        console.log(response);
        _this.tableData = response.data.list;
        _this.total = response.data.total;
      });
    },
  },
};
</script>
