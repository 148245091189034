<template lang="html">
  <div>
    <section class="content">
      <div class="box box-info">
        <div class="box-body">
          <el-form
            :inline="true"
            :model="formSearch"
            label-position="right"
            label-width="100px"
            @submit.native.prevent
          >
            <el-form-item label="评卷工作名称" class="pull-left">
              <el-input
                v-model="formSearch.name"
                placeholder="评卷工作名称"
                @keyup.native="searchMarkWork"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button size="small" type="primary" @click="search"
                >查询</el-button
              >
            </el-form-item>
          </el-form>
          <el-table
            v-loading="loading"
            stripe
            element-loading-text="拼命加载中"
            :data="tableData"
            border
            style="width: 100%"
          >
            <el-table-column label="评卷工作名称" width="250" prop="name">
            </el-table-column>
            <el-table-column label="考试名称-类型" width="300">
              <template slot-scope="scope">
                <div>
                  <span
                    v-for="item in scope.row.examNameAndType"
                    :key="item.index"
                    style="display: block"
                    >{{ item }}</span
                  >
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="进度(%)"
              width="100"
              prop="progress"
              sortable
            >
            </el-table-column>
            <el-table-column label="备注" width="100" prop="remark">
            </el-table-column>
            <el-table-column :context="_self" label="操作">
              <template slot-scope="scope">
                <div class="pull-left">
                  <el-button
                    type="primary"
                    size="mini"
                    plain
                    @click="marking(scope.row)"
                    >阅卷</el-button
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="page pull-right">
            <el-pagination
              background
              :current-page="currentPage"
              :page-size="pageSize"
              :page-sizes="[10, 20, 50, 100, 200, 300]"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              @current-change="handleCurrentChange"
              @size-change="handleSizeChange"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { MARKING_LOGIC_API, MARKING_API } from "@/constants/constants";
import { mapState } from "vuex";
export default {
  data() {
    return {
      formSearch: {
        name: "",
      },
      tableData: [],
      totalTableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      markWorkId: "",
      loading: false,
      isMarkingView: true,
    };
  },
  computed: {
    routeType() {
      return this.$route.params.type;
    },
    ...mapState({ user: (state) => state.user }),
  },
  created() {
    this.initMarkWork();
  },
  methods: {
    search() {
      this.currentPage = 1;
      this.initMarkWork();
    },
    initMarkWork() {
      this.loading = true;
      this.$http
        .get(
          MARKING_LOGIC_API +
            "/markWorks?isEnd=0&userId=" +
            this.user.userId +
            `&pageNumber=${this.currentPage - 1}&pageSize=${
              this.pageSize
            }&name=${this.formSearch.name}`
        )
        .then((response) => {
          this.tableData = response.data?.content || [];
          // this.total = response.data.length;
          this.total = response.data?.totalElements;
          // this.filterMarkWork();
          // this.paging();
          this.loading = false;
          // this.totalTableData = response.data;
          // this.total = response.data.length;
          // this.filterMarkWork();
          // this.paging();
          // this.loading = false;
        });
    },
    searchMarkWork() {
      this.filterMarkWork();
      this.paging();
    },
    filterMarkWork() {
      var tempData = this.totalTableData.filter((element) => {
        if (this.formSearch.name) {
          return element.name.includes(this.formSearch.name);
        } else {
          return true;
        }
      });
      this.tableData = tempData;
      this.total = tempData.length;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.initMarkWork();
      // this.filterMarkWork();
      // this.paging();
      //this.searchMarkWork()
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.initMarkWork();
      // this.filterMarkWork();
      // this.paging();
      //this.searchMarkWork()
    },
    paging() {
      // var start = (this.currentPage - 1) * this.pageSize;
      // var end =
      //   this.currentPage * this.pageSize < this.total
      //     ? this.currentPage * this.pageSize
      //     : this.total;
      // var tempData = [];
      // console.log(`当前页: ${this.currentPage},开始:${start},结束:${end}`);
      // for (let i = start; i < end; i++) {
      //   tempData.push(this.tableData[i]);
      // }
      // console.log(tempData);
      // this.tableData = tempData;
    },
    marking(row) {
      var userId = this.user.userId;
      var self = this;
      var url = "/marking/" + row.id;
      self.$http
        .get(
          MARKING_API +
            "/markTasks/count?workId=" +
            row.id +
            "&userId=" +
            userId
        )
        .then((response) => {
          if (response.data > 0) {
            self.$router.push({
              path: url,
            });
          } else {
            self.$notify({
              message: "没有评卷任务",
              type: "warning",
            });
          }
        });
    },
  },
};
</script>
