<template>
  <div class="scoreboard pull-right">
    <div class="board-margin">
      <div v-if="workId && workId != 1848" class="first-board">
        <span class="title-font">操作方式切换:</span>
        <el-switch
          v-model="isMouseMode"
          class="pull-right"
          active-text="鼠标"
          inactive-text="键盘"
          active-color="#4cb0f9"
          inactive-color="#13ce66"
        ></el-switch>
      </div>
      <div v-show="examType != 'OFFLINE'" class="first-board">
        <el-checkbox v-model="showBody">显示题干</el-checkbox
        ><el-checkbox v-model="showAnswer">显示标答</el-checkbox>
      </div>
      <div class="second-board">
        <div class="board-title"><span>总分</span></div>
        <div class="board-score">
          <span>{{ totalScore }}分</span>
        </div>
        <div class="total-all-score-title"><span>全卷分</span></div>
        <div class="total-all-score">
          <span>{{ totalAllScore }}</span>
        </div>
      </div>
      <!-- 鼠标模式 -->
      <div v-if="isMouseMode">
        <div class="title-board"><span class="title-font">评分</span></div>
        <div class="itemScroll">
          <div
            v-for="(resultItemInner, index) in resultItemsData"
            :id="resultItemInner.markItem.id"
            :key="resultItemInner.markItem.id"
            class="pull-left scoretitle titlebutton"
            size="small"
            @click="
              itemClick(
                resultItemInner.markItem.id,
                $event,
                resultItemInner.markItem.orders
              )
            "
          >
            <div
              :class="{ 'active-btn': itemClass[index] }"
              class="title-item-top"
            >
              <span>
                {{ resultItemInner.markItem.mainNumber }}({{
                  resultItemInner.markItem.orders
                }})
              </span>
            </div>
            <div
              class="title-item-bottom"
              :class="{ 'active-text': itemClass[index] }"
            >
              <span>{{ resultItemInner.score }}分</span>
            </div>
          </div>
        </div>
        <div v-if="markTypeView">
          <div class="step-board"></div>
          <div class="title-board"><span class="title-font">打分</span></div>
          <div class="itemScroll">
            <div
              v-for="(score, index) in itemScores"
              :key="score"
              class="pull-left scoretitle"
            >
              <div
                class="scorebutton"
                :class="{ 'score-btn': itemScoreBtn[index] }"
                @click="scoreClick(score, index)"
              >
                <span class="score-item">
                  {{
                    workId && workId != 2320
                      ? score
                      : ["不及格", "及格", "良好", "优秀"][index] || "优秀"
                  }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 键盘模式 -->
      <div v-if="!isMouseMode">
        <div class="title-board"><span class="title-font">评分</span></div>
        <div>
          <div class="itemScroll">
            <div
              v-for="(resultItemInner, index) in resultItemsData"
              :key="resultItemInner.markItem.id"
              class="scoretitle"
            >
              <div class="box-card">
                <div class="item-title">
                  <div class="pull-left item-number">
                    {{ resultItemInner.markItem.mainNumber }}({{
                      resultItemInner.markItem.orders
                    }})
                  </div>
                  <div class="pull-left item-number2">
                    <span>{{ resultItemInner.markItem.maxScore }}分</span>
                  </div>
                  <div class="pull-left item-number3">
                    间隔:{{
                      itemScoreStep(resultItemInner.markItem.scoreInterval)
                    }}
                  </div>
                </div>
                <div class="item-score">
                  <input
                    :id="getItemId(resultItemInner.markItem.id)"
                    v-model="resultItemInner.score"
                    class="score-input"
                    @focus="scoreFocus(resultItemInner)"
                    @keydown.enter="scoreEnter(index, resultItemInner)"
                    @keydown.up="scoreUp(index, resultItemInner)"
                    @keydown.down="scoreDown(index, resultItemInner)"
                    @change="scoreChange(index, resultItemInner.score)"
                  />
                  分
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div class="step-board"></div>
        <div class="title-board">
          <span class="title-font">备注</span>
          <div
            v-if="!textareaflag"
            class="el-icon-caret-right remark-board"
            @click="showTextarea"
          ></div>
          <div
            v-if="textareaflag"
            class="el-icon-caret-top remark-board"
            @click="showTextarea"
          ></div>
        </div>
        <div v-if="textareaflag" class="remark-step">
          <textarea
            id="remark"
            v-model="markedResultData.markRemark"
            type="text"
            rows="3"
            cols="35"
            placeholder="请输入内容"
          />
        </div>
      </div>

      <div>
        <div v-if="problemView">
          <div class="problem-button"></div>
          <span v-if="tagFlag">
            <el-radio-group
              v-model="markedResultData.tag"
              @change="processTagPaper"
            >
              <div
                v-for="tag in tags"
                :key="tag.code"
                class="pull-left scoretitle"
              >
                <el-radio :label="tag.code">{{ tag.name }}</el-radio>
              </div>
            </el-radio-group>
          </span>
          <span v-if="!tagFlag">
            <el-radio-group v-model="unusualType" @change="processTagPaper">
              <div
                v-for="tag in tags"
                :key="tag.code"
                class="pull-left scoretitle"
              >
                <el-radio :key="tag.code" :label="tag.code">{{
                  tag.name
                }}</el-radio>
              </div>
            </el-radio-group>
          </span>
        </div>
        <div v-if="paperMark" class="pull-left btn">
          <el-button
            id="subMarkBtn"
            class="submit-button"
            type="primary"
            size="small"
            :round="true"
            :loading="loading"
            @keydown.enter="submitMark"
            @click="submitMark"
          >
            <span class="sub-btn">提</span> <span>交</span>
          </el-button>
        </div>
        <div v-if="paperMark" class="pull-left btn">
          <el-button
            type="danger"
            :round="true"
            size="small"
            @click="problemClick"
            >问题卷</el-button
          >
        </div>
        <div v-if="paperMark && problemView" class="pull-left btn">
          <el-button
            type="danger"
            :round="true"
            size="small"
            @click="resetProblem"
            >清除问题卷</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { USER_SIGNOUT } from "../../portal/store/user";
import { mapState } from "vuex";
import { CORE_API, MARKING_API } from "@/constants/constants";
import { EVENTHUB } from "../constants/constants";
export default {
  props: {
    objectiveScore: { type: Number, default: 0.0 },
    paperMarkSign: {
      type: Array,
      default: () => [],
    },
    signScores: {
      type: Array,
      default: () => [],
    },
    signOption: {
      type: Object,
      default: () => {},
    },
    markSign: {
      type: Boolean,
      default: false,
    },
    studentPaperId: {
      type: [String, Number],
      default: "",
    },
    markTaskId: {
      type: Number,
      default: 0,
    },
    resultItems: {
      type: Array,
      default: () => [],
    },
    markedResult: {
      type: Object,
      default: () => {},
    },
    paperMark: {
      type: Boolean,
      default: true,
    },
    scoreStep: {
      type: Number,
      default: 0.5,
    },
    examType: {
      type: String,
      default: "",
    },
    workId: {
      type: [Number, String],
      default: "",
    },
  },
  data() {
    return {
      showBody: true,
      showAnswer: true,
      resultItemsData: this.resultItems,
      signOptionData: this.signOption,
      signScoresData: this.signScores,
      markedResultData: this.markedResult,
      activeName: "first",
      markType: "mouse",
      isMouseMode: true,
      op: "first",
      maxScore: 0,
      markItemId: "",
      resultItem: {
        markItem: {},
        score: 0,
      },
      markTypeView: false,
      problemView: false,
      markBack: false,
      markSame: false,
      markBlank: false,
      markDiff: false,
      curIndex: 0,
      steps: [],
      btnflag: false,
      textareaflag: false,
      tmpSignItem: this.signItem,
      tags: [],
      unusualType: "",
      scoreError: [],
      itemScoreBtn: [],
      loading: false,
    };
  },
  computed: {
    tagFlag() {
      if (this.markedResultData.id) {
        return true;
      } else {
        return false;
      }
    },
    paperKey() {
      if (!this.markSign) return "";
      return this.studentPaperId;
    },
    signScoreSum() {
      var sum = 0;
      for (let signScore of this.signScoresData) {
        sum += signScore;
      }
      return sum;
    },
    itemClass() {
      var itemClass = [];
      for (let resultItem of this.resultItemsData) {
        if (resultItem.score.length == 0) {
          itemClass.push(false);
        } else {
          itemClass.push(true);
        }
      }
      return itemClass;
    },
    totalScore() {
      var totalScore = 0;
      for (let resultItem of this.resultItemsData) {
        if (resultItem.score.length == 0) {
          totalScore = this.accAdd(totalScore, 0);
        } else {
          totalScore = this.accAdd(
            totalScore,
            Number.parseFloat(resultItem.score)
          );
        }
      }
      return totalScore;
    },
    totalAllScore() {
      return this.accAdd(this.totalScore, this.objectiveScore);
    },
    itemScores() {
      var itemScores = [];
      var scoreInterval = this.resultItem.markItem.scoreInterval;
      if (this.scoreStep != null) {
        scoreInterval = this.scoreStep;
      }
      let i = 0;
      let j = 0;
      do {
        itemScores[j] = i;
        i = this.accAdd(i, scoreInterval);
        j++;
      } while (i <= this.resultItem.markItem.maxScore);
      if (
        itemScores[itemScores.length - 1] < this.resultItem.markItem.maxScore
      ) {
        itemScores[itemScores.length] = this.resultItem.markItem.maxScore;
      }
      return itemScores;
    },
    ...mapState({ user: (state) => state.user }),
  },
  watch: {
    resultItems: {
      immediate: false,
      handler(val) {
        this.resultItemsData = val;
        if (!this.isMouseMode) {
          //切换不同考试的试卷时，光标定位要在resultItems变化之后做
          this.initKeyBoardMode();
        }
      },
    },
    signOption: {
      immediate: false,
      handler(val) {
        this.signOptionData = val;
      },
    },
    signScores: {
      immediate: false,
      handler(val) {
        this.signScoresData = val;
      },
    },
    markedResult: {
      immediate: false,
      handler(val) {
        this.markedResultData = val;
      },
    },
    signItem(val) {
      this.tmpSignItem = val;
    },
    tmpSignItem(val) {
      this.$emit("changeSign", val);
    },
    isMouseMode(val) {
      if (!val) {
        this.initKeyBoardMode();
      }
    },
    studentPaperId() {
      if (!this.isMouseMode) {
        this.initKeyBoardMode();
      }
    },
    showAnswer: {
      immediate: false,
      handler() {
        this.showAnswerChange();
      },
    },
    showBody: {
      immediate: false,
      handler() {
        this.showBodyChange();
      },
    },
  },
  created() {
    this.getTags();
  },
  methods: {
    ...mapActions([USER_SIGNOUT]),
    showAnswerChange() {
      if (this.examType == "OFFLINE") {
        return;
      }
      var es = Array.from(document.getElementsByClassName("right-answer"));
      var displayVal = this.showAnswer ? "block" : "none";
      es.forEach((element) => {
        element.style.display = displayVal;
      });
    },
    showBodyChange() {
      if (this.examType == "OFFLINE") {
        return;
      }
      var es = Array.from(document.getElementsByClassName("question-body"));
      var displayVal = this.showBody ? "block" : "none";
      es.forEach((element) => {
        element.style.display = displayVal;
      });
    },
    resetProblem() {
      if (this.markedResultData.tag) {
        this.markedResultData.tag = "";
      }
      if (this.unusualType) {
        this.unusualType = "";
      }
    },
    //处理问题卷
    processTagPaper() {
      if (!this.markedResultData.id) {
        if (this.unusualType) {
          for (let resultItem of this.resultItemsData) {
            resultItem.score = 0;
          }
        }
      } else {
        if (this.markedResultData.tag) {
          for (let resultItem of this.resultItemsData) {
            resultItem.score = 0;
          }
        }
      }
    },
    getTags() {
      this.$http.get(MARKING_API + "/markResults/tag").then((response) => {
        this.tags = response.data;
        //移除科目错误
        for (let i = 0; i < this.tags.length; i++) {
          if (this.tags[i].code === "SUBJECT_ERROR") {
            this.tags.splice(i, 1);
          }
        }
      });
    },
    //在内存中对试卷进行标记保存
    saveMarkSign() {
      localStorage.removeItem(this.paperKey);
      localStorage.setItem(this.paperKey, JSON.stringify(this.paperMarkSign));
    },
    checkItemClass(resultItem) {
      if (resultItem.score.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    itemClick(id, event, order) {
      this.markTypeView = true;
      this.resultItemsData.find((value, index) => {
        if (value.markItem.id === id) {
          this.maxScore = value.markItem.maxScore;
          this.markItemId = value.markItem.id;
          if (value.score.length > 0) {
            this.resultItem = value;
          } else {
            this.resultItem = {
              markItem: value.markItem,
              score: "",
            };
          }
          this.curIndex = index;
        }
      });
      this.showItemTitle();
      if (this.markSign) {
        this.tmpSignItem = this.resultItem.markItem;
        this.signScoresData.splice(0, this.signScoresData.length);
      }
      this.positionDiv(order);
      //重置打分板样式
      this.itemScoreBtn = [];
    },
    positionDiv(order) {
      EVENTHUB.$emit("positionDiv", order);
    },
    showItemTitle() {
      var title =
        this.resultItem.markItem.mainNumber +
        "(" +
        this.resultItem.markItem.orders +
        ")";
      this.$notify({
        showClose: true,
        message: "当前选择题目为" + title,
        type: "warning",
      });
    },
    checkSignScore(score) {
      var sum = 0;
      for (let signScore of this.signScoresData) {
        sum += signScore;
      }
      if (sum + score > this.maxScore) {
        return false;
      } else {
        return true;
      }
    },
    //检查每项分数是否满足要求
    checkScore(resultItem) {
      var score = resultItem.score + "";
      var maxScore = resultItem.markItem.maxScore + "";
      if (score.trim().length === 0) {
        this.$notify({
          message: "分数不能为空",
          type: "error",
          duration: 2000,
        });
        return false;
      } else {
        let regex = "^\\d+(\\.[\\d])?$";
        if (!score.match(regex)) {
          this.$notify({
            message: "分数必须为数字且最多一位小数",
            type: "error",
            duration: 2000,
          });
          return false;
        } else {
          if (parseFloat(score) > parseFloat(maxScore)) {
            this.$notify({
              message: "分数不能超过" + maxScore + "分",
              type: "error",
              duration: 2000,
            });
            return false;
          }
        }
      }
      //校验分数是否是间隔的倍数
      var scoreInterval = resultItem.markItem.scoreInterval;
      if (this.scoreStep != null) {
        scoreInterval = this.scoreStep;
      }
      if (
        parseFloat(score) != 0 &&
        parseFloat(score) != parseFloat(maxScore) &&
        (parseFloat(score) * 10) % (scoreInterval * 10) != 0
      ) {
        this.$notify({
          message: "分数必须是给分间隔的倍数或者零分、满分",
          type: "error",
          duration: 2000,
        });
        return false;
      }
      return true;
    },
    scoreClick(score, index1) {
      //轨迹模式处理分值问题
      if (this.markSign) {
        if (this.checkSignScore(score)) {
          this.signScoresData.push(score);
          this.signOptionData.score = score;
          this.resultItem.score = this.signScoreSum;
          this.resultItemsData[this.curIndex].score = this.signScoreSum;
        } else {
          this.$notify({
            message: "轨迹总分不能超过满分",
            type: "error",
          });
        }
      } else {
        //正常模式
        this.resultItem.score = score;
        this.resultItemsData[this.curIndex].score = score;
        //设置动态样式（打分板）
        this.itemScoreBtn = [];
        for (let i = 0; i < this.itemScores.length; i++) {
          if (i == index1) {
            this.itemScoreBtn.push(true);
          } else {
            this.itemScoreBtn.push(false);
          }
        }
      }
    },
    //键盘打分板change事件
    scoreChange(index, score) {
      this.resultItem.score = score;
      this.resultItemsData[index].score = score;
    },
    //键盘打分板聚焦后试卷跳转对应试题
    scoreFocus(resultItem) {
      var order = resultItem.markItem.orders;
      this.positionDiv(order);
    },
    //键盘打分板下键跳转下一题
    scoreDown(index, resultItem) {
      var curItemId = this.resultItemsData[index].markItem.id;
      var curItemInput = "item" + curItemId;
      if (!this.checkScore(resultItem)) {
        document.getElementById(curItemInput).focus();
        return;
      }
      if (index === this.resultItemsData.length - 1) {
        return;
      } else {
        var nextResultItem = this.resultItemsData[index + 1];
        var nextItemId = nextResultItem.markItem.id;
        var nextItemInput = "item" + nextItemId;
        var order = nextResultItem.markItem.orders;
        document.getElementById(nextItemInput).focus();
        this.positionDiv(order);
      }
    },
    //键盘打分板上键跳转上一题
    scoreUp(index, resultItem) {
      var curItemId = this.resultItemsData[index].markItem.id;
      var curItemInput = "item" + curItemId;
      if (!this.checkScore(resultItem)) {
        document.getElementById(curItemInput).focus();
        return;
      }
      if (index === 0) {
        let preResultItem = this.resultItemsData[0];
        let preItemId = preResultItem.markItem.id;
        let preItemInput = "item" + preItemId;
        let order = preResultItem.markItem.orders;
        document.getElementById(preItemInput).focus();
        this.positionDiv(order);
      } else {
        let preResultItem = this.resultItemsData[index - 1];
        let preItemId = preResultItem.markItem.id;
        let preItemInput = "item" + preItemId;
        let order = preResultItem.markItem.orders;
        document.getElementById(preItemInput).focus();
        this.positionDiv(order);
      }
    },
    //键盘打分板回车后跳转下一题
    scoreEnter(index, resultItem) {
      if (!this.checkScore(resultItem)) {
        var curItemId = this.resultItemsData[index].markItem.id;
        var curItemInput = "item" + curItemId;
        document.getElementById(curItemInput).focus();
        return;
      }
      if (index === this.resultItemsData.length - 1) {
        document.getElementById("subMarkBtn").focus();
      } else {
        var nextResultItem = this.resultItemsData[index + 1];
        var nextItemId = nextResultItem.markItem.id;
        var nextItemInput = "item" + nextItemId;
        var order = nextResultItem.markItem.orders;
        document.getElementById(nextItemInput).focus();
        this.positionDiv(order);
      }
    },
    getItemId(itemId) {
      return "item" + itemId;
    },
    problemClick() {
      this.problemView = !this.problemView;
    },
    //检查评分项
    checkItems() {
      var itemName = "";
      for (var resultItem of this.resultItemsData) {
        if (resultItem.score.length == 0) {
          itemName =
            Number.parseInt(resultItem.markItem.mainNumber) +
            "(" +
            resultItem.markItem.orders +
            ")";
          this.$notify({
            title: "警告",
            message: itemName + "没有打分，请打分",
            type: "warning",
          });
          if (!this.isMouseMode) {
            let curItemInput = "item" + resultItem.markItem.id;
            document.getElementById(curItemInput).focus();
          }
          return false;
        } else {
          if (!this.checkScore(resultItem)) {
            if (!this.isMouseMode) {
              let curItemInput = "item" + resultItem.markItem.id;
              document.getElementById(curItemInput).focus();
            }
            return false;
          }
        }
      }
      return true;
    },
    submitMark() {
      if (!this.checkItems()) {
        return;
      }
      this.processTagPaper();
      this.loading = true;
      this.$loading({ fullscreen: true });
      let remarkValue = "";
      if (document.getElementById("remark")) {
        remarkValue = document.getElementById("remark").value;
      }
      if (!this.markedResultData.id) {
        //正常提交情况
        this.$httpWithMsg
          .post(
            MARKING_API +
              "/markResults?studentPaperId=" +
              this.studentPaperId +
              "&markTaskId=" +
              this.markTaskId +
              "&tag=" +
              this.unusualType +
              "&remark=" +
              remarkValue,
            this.resultItemsData
          )
          .then(
            (response) => {
              this.saveMarkSign();
              if (response.data.id) {
                this.$notify({
                  message: "提交成功",
                  type: "success",
                });
              } else {
                this.$notify({
                  message: "提交失败",
                  type: "error",
                });
              }
              //调用Marking.vue中方法：执行的是拿取下一份试卷
              this.$emit("submitMark");
              this.loading = false;
              this.markTypeView = false;
              this.problemView = false;
              this.unusualType = "";
            },
            (error) => {
              // 响应错误回调
              if (error.response.data.desc) {
                var errorInfo = error.response.data.desc;
                if (errorInfo.includes("超时")) {
                  this.$loading().close();
                  this.loading = false;
                  this.$alert(
                    "该评卷任务已超时，请点击确定重新登录！",
                    "提示",
                    {
                      confirmButtonText: "确定",
                      callback: () => {
                        this.$http
                          .post(CORE_API + "/auth/logout")
                          .then(() => {
                            const orgId = this.user.rootOrgId;
                            this.USER_SIGNOUT();
                            window.name = "";
                            this.$router.replace({
                              path: "/login" + "?orgId=" + orgId,
                            });
                          })
                          .catch((response) => {
                            const orgId = this.user.rootOrgId;
                            if (response.status == 500) {
                              this.$notify({
                                showClose: true,
                                message: response.data.desc,
                                type: "error",
                              });
                            }
                            this.USER_SIGNOUT();
                            window.name = "";
                            this.$router.replace({
                              path: "/login" + "?orgId=" + orgId,
                            });
                          });
                        return;
                      },
                    }
                  );
                } else {
                  if (error.response.data.code == 403) {
                    this.$loading().close();
                    return;
                  }
                  this.$emit("submitMark");
                  this.loading = false;
                  this.markTypeView = false;
                  this.problemView = false;
                  this.unusualType = "";
                }
              } else {
                this.$notify({
                  message: "提交失败",
                  type: "error",
                });
                this.$emit("submitMark");
                this.loading = false;
                this.markTypeView = false;
                this.problemView = false;
                this.unusualType = "";
              }
            }
          );
      } else {
        this.markedResultData.resultItems = this.resultItemsData;
        this.markedResultData.remark = remarkValue;
        this.$httpWithMsg
          .put(MARKING_API + "/markResults", this.markedResultData)
          .then(
            () => {
              this.saveMarkSign();
              this.$notify({
                message: "提交成功",
                type: "success",
              });
              this.$emit("submitMark");
              this.loading = false;
              this.markTypeView = false;
              this.problemView = false;
              this.unusualType = "";
            },
            (error) => {
              // 响应错误回调
              if (error.response.data.code == 403) {
                this.$loading().close();
                return;
              }
              this.$emit("submitMark");
              this.loading = false;
              this.markTypeView = false;
              this.problemView = false;
              this.unusualType = "";
            }
          );
      }
    },
    initKeyBoardMode() {
      if (this.resultItemsData[0]) {
        var itemId = this.resultItemsData[0].markItem.id;
        var order = this.resultItemsData[0].markItem.orders;
        var itemInput = "item" + itemId;
        this.$nextTick(() => {
          var firstInput = document.getElementById(itemInput);
          if (firstInput) {
            firstInput.focus();
          }
        });
        this.positionDiv(order);
      }
    },
    showTextarea() {
      if (this.textareaflag) {
        this.textareaflag = false;
      } else {
        this.textareaflag = true;
      }
    },
    accAdd(num1, num2) {
      let sq1, sq2, m;
      try {
        sq1 = num1.toString().split(".")[1].length;
      } catch (e) {
        sq1 = 0;
      }
      try {
        sq2 = num2.toString().split(".")[1].length;
      } catch (e) {
        sq2 = 0;
      }
      m = Math.pow(10, Math.max(sq1, sq2));
      return (num1 * m + num2 * m) / m;
    },
    itemScoreStep(scoreInterval) {
      if (this.scoreStep != null) {
        return this.scoreStep;
      }
      return scoreInterval;
    },
  },
};
</script>
<style scoped>
.scoreboard {
  width: 25%;
  min-height: 600px;
}
.itemScroll {
  overflow: hidden;
  height: 140px;
  margin-top: 15px;
}
.itemScroll:hover {
  overflow: auto;
}
.itemScroll::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 8px; /*高宽分别对应横竖滚动条的尺寸*/
}
.itemScroll::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.2);
}
.itemScroll::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}
.scoreScroll {
  overflow: auto;
  height: 100px;
}
.scoretitle {
  margin-right: 15px;
  margin-bottom: 15px;
}
li {
  list-style-type: none;
}
.actionbutton {
  width: 80px;
  height: 30px;
}
.second-board {
  color: white;
  margin-top: 20px;
  padding-bottom: 54px;
  border-bottom: 1px solid #ccc;
}
.clear {
  clear: both;
}
.board-margin {
  margin-left: 20px;
}
.sub-btn {
  margin-right: 15px;
}
.active-btn {
  background: rgb(85, 191, 255) !important;
  border-color: rgb(85, 191, 255) !important;
}
.score-input {
  border: 1px solid black;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  box-sizing: border-box;
  color: rgb(77, 124, 196);
  font-size: 15px;
  height: 30px;
  width: 40px;
  padding: 2px 10px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  outline: none;
  text-align: center;
}
.el-switch__label {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}
.button-border.button-small {
  line-height: 26px;
}
.button-small {
  font-size: 12px;
}
.button-border {
  border-width: 2px;
  border-style: solid;
}
.button-box {
  padding: 0 !important;
  border-radius: 10px;
}
.button-primary {
  border-color: #1b9af7;
}
.button {
  font-weight: 300;
  text-decoration: none;
  text-align: center;
  margin: 0;
  display: inline-block;
  outline: none;
}
.pull-left {
  float: left;
}
.pull-right {
  float: right;
}
.scorebutton {
  display: inline-block;
  min-width: 40px;
  height: 40px;
  cursor: pointer;
  color: rgb(77, 124, 196);
  border-radius: 15px;
  font-weight: 600;
  border: 1px solid rgb(77, 124, 196);
  text-align: center;
}
.scorebutton:hover {
  background: rgb(85, 191, 255);
  color: white;
  border-color: rgb(85, 191, 255);
}
.titlebutton {
  border-radius: 15px;
  cursor: pointer;
}
.titlebutton:hover {
  background: yellow;
}
.title-font {
  font-size: 14px;
  font-weight: 800;
}
.first-board {
  margin-top: 20px;
  height: 50px;
  border-bottom: 1px solid #ccc;
}
.board-title {
  height: 34px;
  width: 80px;
  background: rgb(255, 109, 109);
  border: 2px solid rgb(255, 109, 109);
  text-align: center;
  padding-top: 20px;
  font-weight: 600;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  float: left;
  font-size: 24px;
  line-height: 0;
}
.board-score {
  float: left;
  height: 34px;
  border: 2px solid rgb(255, 109, 109);
  text-align: center;
  padding-top: 20px;
  padding-right: 10px;
  font-weight: 600;
  color: rgb(255, 109, 109);
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  font-size: 20px;
  line-height: 0;
}
.total-all-score-title {
  color: black;
  margin-left: 10px;
  float: left;
  font-weight: 600;
  line-height: 3;
}
.total-all-score {
  color: red;
  margin-left: 10px;
  font-weight: 600;
  line-height: 3;
  float: left;
}
.title-board {
  margin-top: 20px;
}
.title-item-top {
  color: white;
  width: 50px;
  height: 25px;
  border: 1px solid rgb(77, 124, 196);
  text-align: center;
  line-height: 2;
  font-size: 12px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background: rgb(77, 124, 196);
  border-bottom: 0px;
}
.title-item-bottom {
  width: 50px;
  height: 30px;
  border: 1px solid rgb(77, 124, 196);
  text-align: center;
  font-size: 12px;
  line-height: 2.5;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border-top: 0px;
  font-weight: 600;
  color: rgb(77, 124, 196);
  display: block;
}
.active-text {
  color: rgb(85, 191, 255) !important;
  border-color: rgb(85, 191, 255);
}
.score-item {
  line-height: 2.5;
}
textarea {
  background: rgb(245, 245, 245);
  border-radius: 10px;
  outline: none;
}
.step-board {
  margin-top: 20px;
  border-bottom: 1px solid #ccc;
}
.problem-button {
  border-bottom: 1px solid #ccc;
  margin-bottom: 15px;
  margin-top: 20px;
}
.remark-step {
  height: 100px;
  margin-top: 15px;
}
.box-card {
  width: 160px;
  height: 60px;
  border: 1px solid rgb(77, 124, 196);
  border-radius: 10px;
}
.item-title {
  background: rgb(77, 124, 196);
  color: white;
  height: 25px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-size: 12px;
  text-align: center;
}
.item-score {
  height: 35px;
  color: rgb(77, 124, 196);
  font-weight: bold;
  font-size: 15px;
  text-align: center;
}
.item-number {
  width: 52px;
  height: 22px;
  text-align: center;
  line-height: 1.5;
}
.item-number2 {
  width: 40px;
  height: 22px;
  border: 1px solid rgb(77, 124, 196);
  border-right-color: white;
  border-left-color: white;
  text-align: center;
  line-height: 1.5;
}
.item-number3 {
  width: 62px;
  height: 22px;
  text-align: center;
  line-height: 1.5;
}
.btn {
  margin-top: 20px;
  margin-bottom: 10px;
  padding-right: 1px;
}
.score-btn {
  background: rgb(85, 191, 255);
  color: white;
  border-color: rgb(85, 191, 255);
}
.remark-board {
  cursor: pointer;
}
.el-button {
  outline: none;
}
</style>
