import queryString from "qs";

export function object2QueryString(obj) {
  return queryString.stringify(obj);
}

export function showShortIpsStr(ip, splitStr) {
  let arr = (ip || "").split(splitStr);
  let ipsArr = arr[arr.length - 1] ? arr : arr.slice(0, -1);
  return ipsArr.length > 3 ? ipsArr.slice(0, 3).join(splitStr) + " ..." : ip;
}
