export const LEVEL_TYPE = [
  { label: "专升本", value: "ZSB" },
  { label: "高起专", value: "GQZ" },
  { label: "高起本", value: "GQB" },
  { label: "不限", value: "ALL" },
];
export const QUESTION_TYPES = [
  { value: "SINGLE_ANSWER_QUESTION", label: "单选" },
  { value: "MULTIPLE_ANSWER_QUESTION", label: "多选" },
  { value: "BOOL_ANSWER_QUESTION", label: "判断" },
  { value: "FILL_BLANK_QUESTION", label: "填空" },
  { value: "TEXT_ANSWER_QUESTION", label: "问答" },
  { value: "NESTED_ANSWER_QUESTION", label: "套题" },
];
export const PUBLICITY_LIST = [
  { label: "公开", value: true },
  { label: "非公开", value: false },
];
export const EXAM_TYPES = [
  { value: "TRADITION", label: "传统考试" },
  { value: "ONLINE", label: "网络考试" },
  { value: "PRACTICE", label: "在线练习" },
  { value: "ONLINE_HOMEWORK", label: "在线作业" },
];
export const EXPORT_TYPES = [
  { value: "ONLINE", label: "机考" },
  { value: "NORMAL", label: "普通" },
];

/* 是否为空字符串(包含数字0时则返回true) */
export function isEmptyStr(str) {
  if (str == undefined || String(str).length < 1) {
    return true;
  }
  return false;
}
