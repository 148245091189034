<template>
  <el-breadcrumb
    v-if="currentPaths.length > 0"
    separator-class="el-icon-arrow-right"
    style="
      border-bottom: 1px solid #ddd;
      padding-bottom: 5px;
      margin-top: -20px;
      margin-bottom: 20px;
    "
  >
    <el-breadcrumb-item :to="{ path: '/home/overview' }">
      首页
    </el-breadcrumb-item>
    <el-breadcrumb-item v-for="item in currentPaths" :key="item">
      {{ item }}
    </el-breadcrumb-item>
  </el-breadcrumb>
</template>

<script>
export default {
  name: "LinkTitlesCustom",
  props: {
    currentPaths: { type: Array, default: () => [] },
  },
};
</script>
