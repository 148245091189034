<template>
  <el-breadcrumb
    v-if="currentPaths.length > 0"
    separator-class="el-icon-arrow-right"
    style="border-bottom: 1px solid #ddd; padding-bottom: 5px"
  >
    <el-breadcrumb-item :to="{ path: '/home/overview' }"
      >首页</el-breadcrumb-item
    >
    <el-breadcrumb-item
      v-for="(item, index) in currentPaths"
      :key="item + index"
    >
      {{ item }}
    </el-breadcrumb-item>
  </el-breadcrumb>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "LinkTitles",
  computed: {
    ...mapState(["currentPaths"]),
  },
};
</script>
