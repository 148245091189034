<template>
  <section class="content">
    <div v-show="isClear == 1">
      <LinkTitlesCustom :current-paths="['题库管理 ', '试卷仓库管理']" />
    </div>
    <div class="box-body">
      <el-form
        :inline="true"
        :model="formSearch"
        label-position="right"
        label-width="70px"
      >
        <el-row>
          <el-col :span="6">
            <el-form-item label="课程名称">
              <el-select
                v-model="formSearch.courseNo"
                class="search_width"
                filterable
                :remote-method="getCourses"
                remote
                clearable
                placeholder="全部"
                size="small"
                @focus="(e) => getCourses(e.target.value)"
              >
                <el-option
                  v-for="item in courseInfoSelect"
                  :key="item.courseNo"
                  :label="item.courseInfo"
                  :value="item.courseNo"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="试卷名称">
              <el-input
                v-model="formSearch.name"
                class="search_width"
                placeholder="试卷名称"
                size="small"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="层次">
              <el-select
                v-model="formSearch.level"
                class="search_width"
                clearable
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in levelList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="录入人">
              <el-input
                v-model="formSearch.creator"
                class="search_width"
                placeholder="录入人"
                size="small"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="修改人">
              <el-input
                v-model="formSearch.lastModifyName"
                class="search_width"
                placeholder="修改人"
                size="small"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <div class="search_down">
              <el-button size="small" type="primary" @click="searchFrom"
                ><i class="el-icon-search"></i> 查询</el-button
              >
              <el-button size="small" @click="resetForm"
                ><i class="el-icon-refresh"></i> 重 置</el-button
              >
            </div>
          </el-col>
        </el-row>
        <div
          style="width: 100%; border-bottom: 1px solid #ddd; margin: 10px 0"
        ></div>
        <el-row>
          <el-form-item>
            <span>批量操作：</span>
            <el-button
              :disabled="noBatchSelected"
              size="mini"
              type="primary"
              plain
              @click="releasePapers"
              ><i class="el-icon-share"></i>释放</el-button
            >
          </el-form-item>
        </el-row>
      </el-form>
      <div style="width: 100%; margin-bottom: 10px"></div>
      <el-table
        v-loading="loading"
        element-loading-text="拼命加载中"
        :data="tableData"
        border
        style="width: 100%"
        @selection-change="selectChange"
      >
        <el-table-column type="selection" width="40"></el-table-column>
        <el-table-column label="课程名称" width="180">
          <template slot-scope="scope">
            <span>{{ scope.row.course.name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="课程代码" width="80">
          <template slot-scope="scope">
            <span>{{ scope.row.course.code }}</span>
          </template>
        </el-table-column>
        <el-table-column label="试卷名称" width="180">
          <template slot-scope="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="试卷总分"
          width="103"
          sortable
          prop="totalScore"
        >
        </el-table-column>
        <el-table-column
          label="试卷难度"
          width="103"
          sortable
          prop="difficultyDegree"
        >
        </el-table-column>
        <el-table-column
          label="大题数量"
          width="103"
          sortable
          prop="paperDetailCount"
        >
        </el-table-column>
        <el-table-column label="录入员" width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.creator }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="创建时间"
          width="153"
          sortable
          prop="createTime"
        >
        </el-table-column>
        <el-table-column label="修改人" width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.lastModifyName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="修改时间"
          width="153"
          sortable
          prop="updateDate"
        >
        </el-table-column>
        <el-table-column label="操作" width="180" fixed="right">
          <template slot-scope="scope">
            <div class="operate_left">
              <el-button
                size="mini"
                type="primary"
                plain
                @click="viewPaper(scope.row)"
                ><i class="el-icon-view"></i>查看</el-button
              >
              <el-dropdown class="button_left">
                <el-button type="primary" size="mini" plain>
                  更多 <i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <el-button
                      size="mini"
                      type="primary"
                      plain
                      @click="releasePaper(scope.row)"
                      ><i class="el-icon-share"></i>释放</el-button
                    >
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="page pull-right">
        <el-pagination
          :current-page="currentPage"
          :page-size="pageSize"
          :page-sizes="[10, 20, 50, 100, 200, 300]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        >
        </el-pagination>
      </div>
    </div>
  </section>
</template>

<script>
import { CORE_API, QUESTION_API, EXAM_WORK_API } from "@/constants/constants";
import { LEVEL_TYPE, PUBLICITY_LIST } from "../constants/constants";
import { mapState } from "vuex";
import LinkTitlesCustom from "@/components/LinkTitlesCustom.vue";
export default {
  components: { LinkTitlesCustom },
  data() {
    return {
      publicityDis: false,
      difficultyDegreeDis: false,
      quesLoading: false,
      quesPropertyDialog: false,
      difficultyDegree: 0.1,
      publicity: true,
      difficultyDegreeList: [
        { label: 0.1, value: 0.1 },
        { label: 0.2, value: 0.2 },
        { label: 0.3, value: 0.3 },
        { label: 0.4, value: 0.4 },
        { label: 0.5, value: 0.5 },
        { label: 0.6, value: 0.6 },
        { label: 0.7, value: 0.7 },
        { label: 0.8, value: 0.8 },
        { label: 0.9, value: 0.9 },
        { label: 1.0, value: 1.0 },
      ],
      publicityList: PUBLICITY_LIST,
      isClear: 0,
      courseLoading: false,
      formSearch: {
        courseNo: "",
        courseName: "",
        creator: "",
        lastModifyName: "",
        level: "",
        name: "",
      },
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      courseList: [],
      levelList: LEVEL_TYPE,
      dialogVisible: false,
      selectedPaperIds: [],
      fileList: [],
      uploadAction: "",
      formUpload: {
        paperName: "",
      },
      uploadData: {},
      fileLoading: false,
      exportDialog: false,
      exportModel: {
        id: "",
        courseCode: "",
        courseName: "",
        exportContentList: [],
        seqMode: "MODE1",
      },
      isShow: true,
      examList: [],
      Org: {},
      dialogModel: false,
      rowIds: [],
      isShowPrintExamPackage: false,
      isShowPrintExamPackagePassword: false,
      printExamPackagePassword: "",
      printFrom: {
        examId: "",
      },
      rules: {
        examId: [{ required: true, message: "请输入名称", trigger: "change" }],
      },
    };
  },
  computed: {
    paperIds() {
      var paperIds = "";
      for (let paperId of this.selectedPaperIds) {
        if (!paperIds) {
          paperIds += paperId;
        } else {
          paperIds += "," + paperId;
        }
      }
      return paperIds;
    },
    courseInfoSelect() {
      var courseList = [];
      for (let course of this.courseList) {
        var courseInfo = course.name + "(" + course.code + ")";
        var courseNo = course.code;
        var courseName = course.name;
        courseList.push({
          courseNo: courseNo,
          courseInfo: courseInfo,
          courseName: courseName,
        });
      }
      return courseList;
    },
    noBatchSelected() {
      return this.selectedPaperIds.length === 0;
    },
    ...mapState({ user: (state) => state.user }),
  },
  watch: {
    $route: "initVue",
  },
  created() {
    this.initVue();
    this.searchExamList();
    this.searchOrgName();
  },
  methods: {
    resetForm() {
      this.formSearch = {
        courseNo: "",
        courseName: "",
        level: "",
        name: "",
      };
    },
    //查询
    searchFrom() {
      this.currentPage = 1;
      this.searchGenPaper();
    },
    searchGenPaper() {
      var pageNo = this.currentPage;
      this.currentPage = 1;
      this.loading = true;
      var url =
        QUESTION_API +
        "/paper_storage/findPage/" +
        pageNo +
        "/" +
        this.pageSize;
      this.$http.get(url, { params: this.formSearch }).then((response) => {
        this.tableData = response.data.content;
        this.total = response.data.totalElements;
        this.currentPage = Number(pageNo);
      });
      this.loading = false;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.searchGenPaper();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.searchGenPaper();
    },
    getCourseObj(courseNo) {
      for (let course of this.courseList) {
        if (course.code == courseNo) {
          return course;
        }
      }
      return "";
    },
    viewPaper(row) {
      var course = this.getCourseObj(this.formSearch.courseNo);
      if (course) {
        this.formSearch.courseName = course.name;
      }
      sessionStorage.setItem("gen_paper", JSON.stringify(this.formSearch));
      sessionStorage.setItem("gen_paper_currentPage", this.currentPage);
      sessionStorage.setItem("question_back", "false");
      this.$router.push({
        path: "/view_paper/" + row.id,
      });
    },
    selectChange(row) {
      this.selectedPaperIds = [];
      row.forEach((element) => {
        this.selectedPaperIds.push(element.id);
      });
    },
    releasePapers() {
      var paperIds = this.paperIds;
      if (this.selectedPaperIds.length != 0) {
        this.$confirm("确认释放试卷吗?", "提示", {
          type: "warning",
        }).then(() => {
          this.loading = true;
          this.$http
            .put(QUESTION_API + "/paper_storage/release/" + paperIds)
            .then(
              () => {
                this.$notify({
                  message: "操作成功",
                  type: "success",
                });
                this.searchGenPaper();
                this.selectedPaperIds = [];
              },
              (response) => {
                this.$notify({
                  message: response.response.data.desc,
                  type: "error",
                });
                this.loading = false;
              }
            );
        });
      } else {
        this.$notify({
          message: "请勾选释放的试卷",
          type: "warning",
        });
      }
    },
    releasePaper(row) {
      this.$confirm("确认释放试卷吗?", "提示", {
        type: "warning",
      }).then(() => {
        this.loading = true;
        this.$http.put(QUESTION_API + "/paper_storage/release/" + row.id).then(
          () => {
            this.$notify({
              message: "操作成功",
              type: "success",
            });
            this.selectedPaperIds = [];
            this.searchGenPaper();
          },
          (response) => {
            this.$notify({
              message: response.response.data.desc,
              type: "error",
            });
            this.loading = false;
          }
        );
      });
    },
    //查询所有课程
    getCourses(query) {
      if (query) {
        query = query.trim();
      }
      this.courseLoading = true;
      this.$http
        .get(CORE_API + "/course/query?name=" + query + "&enable=true")
        .then((response) => {
          this.courseList = response.data;
          this.courseLoading = false;
        });
    },
    removeItem() {
      sessionStorage.removeItem("gen_paper");
      sessionStorage.removeItem("gen_paper_currentPage");
    },
    cancel(formData) {
      this.resetForm2(formData);
      this.dialogModel = false;
    },
    resetForm2(formData) {
      this.printFrom.examId = "";
      this.$refs[formData].clearValidate();
    },
    //查询考试
    getExams(query) {
      if (query) {
        query = query.trim();
      }
      this.$http
        .get(
          EXAM_WORK_API + "/exam/queryByNameLike?name=" + query + "&enable=true"
        )
        .then((response) => {
          this.examList = response.data;
        });
    },
    searchExamList() {
      //查询所有考试
      this.$http
        .get(EXAM_WORK_API + "/exam/queryByNameLike?name=&enable=true")
        .then((response) => {
          var list = response.data;
          for (var i = 0; i < list.length; i++) {
            if (list[i].examType == "TRADITION") {
              this.examList.push(list[i]);
            }
          }
        });
    },
    //根据orgId查询学校名称
    searchOrgName() {
      this.$http
        .get(CORE_API + "/org/rootOrg/" + this.user.rootOrgId)
        .then((response) => {
          this.Org = response.data;
        });
    },
    initVue() {
      this.isClear = this.$route.params.isClear;
      if (this.isClear == 0 || !this.isClear) {
        this.removeItem();
        this.formSearch = {
          courseNo: "",
          courseName: "",
          level: "",
          name: "",
        };
        this.currentPage = 1;
      } else {
        this.formSearch = JSON.parse(sessionStorage.getItem("gen_paper"));
        this.currentPage =
          sessionStorage.getItem("gen_paper_currentPage") == null
            ? 1
            : parseInt(sessionStorage.getItem("gen_paper_currentPage"));
      }
      this.getCourses(this.formSearch.courseName);
      this.searchGenPaper();
    },
  },
};
</script>
<style scoped src="../styles/Common.css"></style>
