export const CORE_API = "/api/ecs_core"; //基础信息API
export const EXAM_WORK_API = "/api/ecs_exam_work"; //考务信息API
export const MARKING_API = "/api/ecs_marking"; //阅卷API
export const MARKING_LOGIC_API = "/api/ecs_marking/logic"; //阅卷中间层API
export const OE_API = "/api/ecs_oe"; //网考API
export const QUESTION_API = "/api/ecs_ques"; //题库API
export const EXCHANGE_API = "/api/ecs_outlet"; //接口机
export const PRINT_API = "/api/ecs_prt";
export const TASK_API = "/api/ctr/task";
export const REPORTS_API = "/api/ecs_reports";

//考试类型
export const EXAM_TYPE = [
  { label: "传统", value: "TRADITION" },
  { label: "网考", value: "ONLINE" },
  { label: "练习", value: "PRACTICE" },
  { label: "离线", value: "OFFLINE" },
  { label: "在线作业", value: "ONLINE_HOMEWORK" },
  { label: "印刷", value: "PRINT_EXAM" },
];

//抽题模式
export const CALL_TYPE = [
  { label: "整卷调用", value: "WHOLE_SET" },
  { label: "随机抽题", value: "RANDOM_PAPER" },
];

// for exam type select
export const EXAM_TYPE_SELECT = [
  { code: "TRADITION", name: "传统" },
  { code: "ONLINE", name: "网考" },
  { code: "PRACTICE", name: "练习" },
  { code: "OFFLINE", name: "离线" },
  { code: "ONLINE_HOMEWORK", name: "在线作业" },
  { code: "PRINT_EXAM", name: "印刷" },
];

// for level type select
export const LEVEL_TYPE_SELECT = [
  { code: "ZSB", name: "专升本" },
  { code: "GQZ", name: "高起专" },
  { code: "GQB", name: "高起本" },
  { code: "ALL", name: "不限" },
];

//试卷类型
export const PAPER_TYPE = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

//数据类型
export const BASIC_DATA_TYPE = [
  { code: "STRING", name: "STRING" },
  { code: "INTEGER", name: "INTEGER" },
  { code: "BOOLEAN", name: "BOOLEAN" },
  { code: "LONG", name: "LONG" },
  { code: "DATE", name: "DATE" },
];
//发布状态
export const PUBLISH_STATUS = [
  { code: "DRAFT", name: "未发布" },
  { code: "TO_BE_PUBLISHED", name: "待发布" },
  { code: "PUBLISHING", name: "发布中" },
  { code: "PUBLISHED", name: "已发布" },
  { code: "RECALLED", name: "已撤回" },
];
//公告接受规则类型
export const NOTICE_RECEIVER_RULE_TYPE = [
  { code: "STUDENTS_OF_EXAM", name: "学生-按考试选择" },
  { code: "ALL_STUDENTS_OF_ROOT_ORG", name: "学生-学校中所有学生" },
  { code: "TEACHER_OF_MARK_WORK", name: "老师-按评卷老师选择" },
  { code: "COMMON_USERS_OF_ROLE", name: "老师-学习中心老师" },
];
//登录帐号类型
export const LOGIN_TYPE = [
  { code: "STUDENT_CODE", name: "学号登录" },
  { code: "IDENTITY_NUMBER", name: "身份证号登录" },
];
//防作弊配置
export const PREVENT_CHEATING_CONFIG = [
  { code: "DISABLE_REMOTE_ASSISTANCE", name: "禁用远程协助" },
  { code: "DISABLE_VIRTUAL_CAMERA", name: "禁用虚拟摄像头" },
  { code: "FULL_SCREEN_TOP", name: "强制全屏置顶" },
  { code: "DISABLE_MULTISCREEN", name: "禁用双屏" },
  { code: "RECORD_SWITCH_SCREEN", name: "计算切屏次数" },
  { code: "DISABLE_VIRTUAL_MACHINE", name: "禁用虚拟机" },
  { code: "EXAMING_BLACK_LIST_CHECK", name: "考试中黑名单检测" },
];
//学生端版本
export const STUDENT_CLIENT_VERSION = [
  { code: "1.0.0", name: "1.0.0" },
  { code: "2.0.0", name: "2.0.0" },
];
//考生端支持的登录方式
export const LOGIN_SUPPORT = [
  { code: "NATIVE", name: "考生端登录" },
  { code: "BROWSER", name: "浏览器登录" },
];
//考生端展示的用户信息
export const SHOW_INFO = [
  { code: "STU_NAME", name: "学生姓名" },
  { code: "STU_CODE", name: "学号" },
  { code: "IDENTITY_NUMBER", name: "学生证件号" },
];

//活体检查 - 动作选项
export const ACTION_OPTION_LIST = [
  { code: "NOD", name: "点头" },
  { code: "SHAKE", name: "摇头" },
  { code: "BLINK", name: "眨眼" },
];
