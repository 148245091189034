<template>
  <section class="content">
    <div class="box box-info">
      <div
        v-loading.body="loading"
        v-loading.fullscreen="loading"
        class="box-body"
        element-loading-text="请稍后..."
      >
        <!-- 表单 -->
        <el-form inline :model="formSearch">
          <el-form-item>
            <el-button
              size="small"
              type="primary"
              icon="el-icon-search"
              @click="handleSearchBtn"
            >
              刷新
            </el-button>
            <el-button
              size="small"
              type="primary"
              icon="el-icon-arrow-left"
              @click="back"
            >
              返回
            </el-button>
          </el-form-item>
        </el-form>

        <div class="block-seperator"></div>
        <!-- 页面列表 -->
        <el-table :data="tableData" border resizable stripe style="width: 100%">
          <el-table-column width="50" label="ID">
            <span slot-scope="scope">{{ scope.row.id }}</span>
          </el-table-column>
          <el-table-column width="120" prop="statusName" label="状态">
          </el-table-column>
          <el-table-column width="180" prop="creationTime" label="任务生成时间">
          </el-table-column>
          <el-table-column width="180" prop="startTime" label="计算开始时间">
          </el-table-column>
          <el-table-column width="180" prop="endTime" label="计算结束时间">
          </el-table-column>
          <el-table-column prop="errorDesc" label="错误信息"> </el-table-column>
          <el-table-column :context="_self" label="操作" width="120">
            <div slot-scope="scope">
              <el-button
                size="mini"
                type="danger"
                plain
                :disabled="disStopJob(scope.row)"
                icon="el-icon-remove-outline"
                @click="stopJob(scope.row)"
              >
                终止计算
              </el-button>
            </div>
          </el-table-column>
        </el-table>
        <div class="page pull-right">
          <el-pagination
            v-if="paginationShow"
            :current-page="currentPage"
            :page-size="pageSize"
            :page-sizes="[10, 20, 50, 100, 200, 300]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { TASK_API } from "@/constants/constants.js";
import { mapState } from "vuex";

export default {
  name: "ComputeJobList",
  data() {
    return {
      loading: false,

      paginationShow: false,
      formSearch: {
        projectId: "",
      },
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 10,
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
    isSuperAdmin() {
      return this.user.roleList.some((role) => role.roleCode == "SUPER_ADMIN");
    },
  },
  //初始化查询
  created() {
    this.formSearch.projectId = this.$route.params.projectId;
    this.init();
  },
  methods: {
    getStatus() {
      let doingIds = this.getDoingId();
      if (doingIds != "") {
        this.$httpWithoutBar
          .get(TASK_API + "/reportsCompute/getList?ids=" + doingIds)
          .then((response) => {
            response.data.forEach((element) => {
              this.tableData.forEach((e) => {
                if (e.id == element.id) {
                  e.status = element.status;
                  e.statusName = element.statusName;
                  e.startTime = element.startTime;
                  e.endTime = element.endTime;
                  e.errorDesc = element.errorDesc;
                }
              });
            });
            setTimeout(() => {
              this.getStatus();
            }, 30000);
          });
      }
    },
    getDoingId() {
      var tempData = [];
      this.tableData.forEach((element) => {
        if (
          element.status == "NONE" ||
          element.status == "COMPUTING" ||
          element.status == "STOPING"
        ) {
          tempData.push(element.id);
        }
      });
      return tempData.join(",");
    },
    back() {
      this.$router.push({
        path: "/reports/overview",
      });
    },
    disStopJob(row) {
      if (row.status != "NONE" && row.status != "COMPUTING") {
        return true;
      } else {
        return false;
      }
    },
    stopJob(row) {
      this.loading = true;
      let url = TASK_API + "/reportsCompute/stopJob/" + row.id;
      this.$httpWithMsg
        .post(url)
        .then(() => {
          this.$notify({
            type: "success",
            message: "操作成功！",
          });
          this.searchForm();
        })
        .finally(() => (this.loading = false));
    },
    handleSearchBtn() {
      this.currentPage = 1;
      this.searchForm();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.searchForm();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.searchForm();
    },
    //查询
    searchForm() {
      this.loading = true;
      var url =
        TASK_API +
        "/reportsCompute/page/" +
        this.formSearch.projectId +
        "/" +
        this.currentPage +
        "/" +
        this.pageSize;
      this.$httpWithMsg
        .get(url, { params: this.formSearch })
        .then((response) => {
          this.tableData = response.data.list;
          this.total = response.data.total;
          this.loading = false;

          this.$nextTick(function () {
            this.paginationShow = true;
          });
          this.getStatus();
        })
        .finally(() => (this.loading = false));
    },
    init() {
      this.searchForm();
    },
  },
};
</script>

<style scoped>
.page {
  margin-top: 10px;
}
.pull-length {
  width: 100px;
}
.details-length {
  width: 400px;
}
.pull-center {
  margin-top: 20px;
}
.editForm .el-form-item {
  margin-bottom: 12px;
}
.partition-main-left-div {
  width: 300px;
  padding: 5px;
  float: left;
  border: 1px solid #ddd;
  margin-left: 5px;
}
.partition-main-rigth-div {
  width: 200px;
  padding: 5px;
  float: right;
  border: 1px solid #ddd;
  margin-right: 5px;
}
.partition-detail-div {
  margin-top: 5px;
}
</style>
