<template>
  <div id="insertBlueApp">
    <LinkTitlesCustom
      :current-paths="['基础信息 ', '蓝图结构预设', '蓝图结构创建']"
    />
    <section class="content">
      <!-- 正文信息 -->
      <div class="box-body">
        <el-form
          :inline="true"
          :model="blueStruct"
          label-position="right"
          label-width="78px"
        >
          <el-row>
            <el-col :span="8">
              <el-form-item label="结构名称">
                <el-input
                  v-model="blueStruct.name"
                  class="search_width"
                  placeholder="试卷结构名称"
                  size="small"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="设置类型">
                <el-select
                  v-model="blueStruct.genPaperType"
                  class="search_width"
                  filterable
                  disabled
                  size="small"
                >
                  <el-option label="细节组卷" value="SPECIFIC"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="总 分">
                <el-input-number
                  v-model="blueStruct.totalScore"
                  :precision="1"
                  :min="0"
                  size="small"
                ></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="制定课程">
                <el-select
                  v-model="blueStruct.courseNo"
                  class="search_width"
                  :disabled="showcoursePropertyId"
                  filterable
                  :remote-method="getCourses"
                  remote
                  clearable
                  placeholder="课程名称"
                  size="small"
                  @change="clearCourseProperty"
                  @focus="(e) => getCourses(e.target.value)"
                >
                  <el-option
                    v-for="item in courseInfoSelect"
                    :key="item.courseNo"
                    :label="item.courseInfo"
                    :value="item.courseNo"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="课程属性">
                <el-select
                  v-model="blueStruct.coursePropertyId"
                  class="search_width"
                  filterable
                  :disabled="showcoursePropertyId"
                  placeholder="课程属性"
                  size="small"
                  @change="getCoursePropertyName"
                >
                  <el-option
                    v-for="item in coursePropertyList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="考试说明">
            <ckeditor
              v-model="blueStruct.examRemark"
              :display="display"
              :width="wValue"
              :height="hValue"
            ></ckeditor>
          </el-form-item>
        </el-form>

        <!-- 添加大题弹出框 -->
        <el-dialog title="大题信息" :visible.sync="detailDialog" width="500px">
          <el-form
            ref="paperDetailStructForm"
            :model="paperDetailStructForm"
            :rules="rules"
            label-position="right"
            label-width="90px"
            inline-message
          >
            <el-row>
              <el-form-item label="大题名称" label-width="120px" prop="name">
                <el-input
                  v-model="paperDetailStructForm.name"
                  class="dialog_input_width"
                  placeholder="请输入题型名称"
                ></el-input>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="题型描述" label-width="120px" prop="remark">
                <el-input
                  v-model="paperDetailStructForm.remark"
                  class="dialog_input_width"
                  type="textarea"
                  :rows="2"
                  placeholder="请输入题型描述"
                ></el-input>
              </el-form-item>
            </el-row>
            <el-row class="margin_top_10 margin_left_120">
              <span v-if="dialogType == 'ADD'">
                <el-button
                  type="primary"
                  @click="saveDetail('paperDetailStructForm')"
                  >保 存</el-button
                >
              </span>
              <span v-else>
                <el-button
                  type="primary"
                  @click="saveEditDetail('paperDetailStructForm')"
                  >保 存</el-button
                >
              </span>
              <span class="margin_left_10">
                <el-button @click="resetForm('paperDetailStructForm')"
                  ><i class="el-icon-refresh"></i> 重 置</el-button
                >
              </span>
              <span class="margin_left_10">
                <el-button
                  type="primary"
                  @click="closeDialog('paperDetailStructForm')"
                  ><i class="el-icon-arrow-left"></i> 返 回</el-button
                >
              </span>
            </el-row>
          </el-form>
        </el-dialog>

        <!-- 页面列表 -->
        <div style="margin-top: 10px"></div>
        <el-table :data="paperDetailStructs" border style="width: 100%">
          <el-table-column label="大题名称">
            <template slot-scope="scope">
              <span>{{ scope.row.name }}</span>
            </template>
          </el-table-column>
          <el-table-column label="小题数">
            <template slot-scope="scope">
              <span>{{ scope.row.detailCount }}</span>
            </template>
          </el-table-column>
          <el-table-column label="大题分数">
            <template slot-scope="scope">
              <span>{{ scope.row.totalScore }}</span>
            </template>
          </el-table-column>
          <el-table-column label="题型">
            <template slot-scope="scope">
              <span>{{ getTpye(scope.row.questionType) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="292">
            <template slot-scope="scope">
              <div class="operate_left">
                <el-button
                  size="mini"
                  type="primary"
                  @click="detailInfo(scope.row)"
                  ><i class="el-icon-plus"></i> 大题详情</el-button
                >
                <el-button
                  size="mini"
                  type="primary"
                  @click="editDetail(scope.row)"
                  ><i class="el-icon-edit"></i>编辑</el-button
                >
                <el-button
                  size="mini"
                  type="danger"
                  @click="deleteDetail(scope.row)"
                  ><i class="el-icon-delete"></i> 删除</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="search_down margin_top_10">
        <el-button size="small" type="primary" @click="saveBlueStruct"
          >保存试卷结构</el-button
        >
        <el-button size="small" type="primary" @click="insertDetail"
          ><i class="el-icon-plus"></i> 添加大题</el-button
        >
        <el-button size="small" type="primary" @click="back"
          ><i class="el-icon-arrow-left"></i> 返 回</el-button
        >
      </div>
    </section>
  </div>
</template>
<script>
import { QUESTION_TYPES } from "../constants/constants";
import { CORE_API, QUESTION_API } from "@/constants/constants";
import ckeditor from "../component/ckeditor.vue";
import LinkTitlesCustom from "@/components/LinkTitlesCustom.vue";
export default {
  name: "InsertBlueApp",
  components: {
    ckeditor,
    LinkTitlesCustom,
  },
  data() {
    return {
      hValue: "100px",
      wValue: "800px",
      display: "block",
      blueStruct: {
        name: "",
        totalScore: 0, //结构总分
        courseNo: "", //课程
        courseName: "",
        coursePropertyId: "", //课程属性
        coursePropertyName: "", //课程属性名称
        paperDetailStructs: [],
        type: "BLUEPRINT", //试卷结构类型
        genPaperType: "SPECIFIC", //组卷类型
        examRemark: "",
      },
      detailDialog: false,
      dialogType: "",
      paperDetailStructForm: {
        id: "",
        number: "",
        name: "", //大题名称
        questionType: "", //题型
        detailCount: 0, //小题总数
        score: 0, //每题分数
        totalScore: 0, //大题分数
        quesNames: [], //来源大题
        publicSimpleCount: 0, //公开简单
        publicMediumCount: 0, //公开中等
        publicDifficultyCount: 0, //公开困难
        noPublicSimpleCount: 0, //非公开简单
        noPublicMediumCount: 0, //非公开中等
        noPublicDifficultyCount: 0, //非公开困难
        courseProperties: [], //课程属性列表
        remark: "", //备注
        coursePropertyNumberDtos: [], //
      },
      quesTypes: QUESTION_TYPES,
      paperDetailStructs: [],
      courseList: [],
      loading: false,
      coursePropertyList: [],
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        remark: [
          { required: true, message: "请输入题型描述", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    totalScore() {
      var sum = 0.0;
      for (let paperDetailStruct of this.paperDetailStructs) {
        sum = this.accAdd(paperDetailStruct.score, sum);
      }
      return sum;
    },
    courseInfoSelect() {
      var courseList = [];
      for (let course of this.courseList) {
        var courseInfo = course.name + "(" + course.code + ")";
        var courseNo = course.code;
        courseList.push({ courseNo: courseNo, courseInfo: courseInfo });
      }
      return courseList;
    },
    showcoursePropertyId() {
      if (this.blueStruct.paperDetailStructs.length > 0) {
        for (let paperDetailStruct of this.blueStruct.paperDetailStructs) {
          if (paperDetailStruct.detailCount > 0) {
            return true;
          }
        }
      }
      return false;
    },
  },
  created() {
    this.paperStructId = this.$route.params.id;
    this.searchForm();
  },
  methods: {
    accAdd(num1, num2) {
      let sq1, sq2, m;
      try {
        sq1 = num1.toString().split(".")[1].length;
      } catch (e) {
        sq1 = 0;
      }
      try {
        sq2 = num2.toString().split(".")[1].length;
      } catch (e) {
        sq2 = 0;
      }
      m = Math.pow(10, Math.max(sq1, sq2));
      return (num1 * m + num2 * m) / m;
    },
    //查询大题结合
    searchForm() {
      this.loading = true;
      var blueStructStorge = sessionStorage.getItem("blueStruct");
      if (typeof blueStructStorge == "string") {
        var blueStruct = JSON.parse(blueStructStorge);
        this.getCourses(blueStruct.courseName);
        this.blueStruct = blueStruct;
        this.$http
          .get(
            QUESTION_API + "/courseProperty/enable/" + this.blueStruct.courseNo
          )
          .then((response) => {
            this.coursePropertyList = response.data;
            if (this.coursePropertyList && this.coursePropertyList.length > 0) {
              for (let objCouPro of this.coursePropertyList) {
                if (objCouPro.id == this.blueStruct.coursePropertyId) {
                  this.blueStruct.coursePropertyName = objCouPro.name;
                  console.log("blueStruct", blueStruct);
                }
              }
            }
          });
        this.paperDetailStructs = blueStruct.paperDetailStructs;
      }
      this.loading = false;
    },
    getProperties() {
      for (var course of this.courseList) {
        if (this.blueStruct.courseNo == course.code) {
          var courseId = course.id;
          this.$http
            .get(QUESTION_API + "/courseProperty/all/" + courseId)
            .then((response) => {
              this.coursePropertyList = response.data;
              console.log("this.coursePropertyList:", this.coursePropertyList);
            });
        }
      }
    },
    //查询课程下的课程属性
    getCoursePropertyList() {
      this.$http
        .get(
          QUESTION_API + "/courseProperty/enable/" + this.blueStruct.courseNo
        )
        .then((response) => {
          this.coursePropertyList = response.data;
          console.log("this.coursePropertyList:", this.coursePropertyList);
          if (this.coursePropertyList && this.coursePropertyList.length == 0) {
            this.blueStruct.coursePropertyId = "";
          }
        });
    },
    checkPaperStruct() {
      if (!this.blueStruct.name) {
        this.$notify({
          message: "请填写试卷结构名称",
          type: "error",
        });
        return false;
      }
      let curPropertyId = this.blueStruct.coursePropertyId;
      if (!curPropertyId || curPropertyId.length < 1) {
        this.$notify({
          type: "error",
          message: "选择课程属性",
        });
        return false;
      }
      if (
        !(
          this.blueStruct.paperDetailStructs &&
          this.blueStruct.paperDetailStructs.length > 0
        )
      ) {
        this.$notify({
          message: "请添加大题",
          type: "error",
        });
        return false;
      }
      for (let paperDetailStruct of this.blueStruct.paperDetailStructs) {
        if (!(paperDetailStruct.detailCount > 0)) {
          this.$notify({
            message: "请添加大题下的小题数量",
            type: "error",
          });
          return false;
        }
      }
      return true;
    },
    //验证总分
    checkTotalScore() {
      var totalScore = 0;
      for (let paperDetailStruct of this.blueStruct.paperDetailStructs) {
        totalScore = this.accAdd(paperDetailStruct.totalScore, totalScore);
      }
      if (totalScore != this.blueStruct.totalScore) {
        return false;
      } else {
        return true;
      }
    },
    //保存试卷结构
    saveBlueStruct() {
      if (this.blueStruct.name.trim().length == 0) {
        this.$notify({
          type: "error",
          message: "结构名称不能为空格",
        });
        return false;
      }
      if (!this.checkPaperStruct()) {
        return false;
      }
      if (!this.checkTotalScore()) {
        this.$notify({
          type: "error",
          message: "试卷结构总分与大题总分不一致",
        });
        return false;
      }
      this.loading = true;
      var url = QUESTION_API + "/paperStruct";
      if (this.paperStructId != "add") {
        //假如没ID就是新增
        this.$httpWithMsg
          .put(url, this.blueStruct)
          .then(() => {
            this.$notify({
              message: "保存成功",
              type: "success",
            });
            this.loading = false;
            this.removeItem();
            this.back();
          })
          .catch((e) => {
            this.$notify({
              type: "error",
              message: e.data.desc,
            });
            this.loading = false;
          });
      } else {
        this.$httpWithMsg
          .post(url, this.blueStruct)
          .then(() => {
            this.$notify({
              message: "保存成功",
              type: "success",
            });
            this.loading = false;
            this.removeItem();
            this.back();
          })
          .catch((e) => {
            this.loading = false;
            this.$notify({
              type: "error",
              message: e.data.desc,
            });
          });
      }
    },
    //添加大题
    insertDetail() {
      this.dialogType = "ADD";
      this.paperDetailStructForm.name = "";
      this.paperDetailStructForm.remark = "";
      this.detailDialog = true;
    },
    //保存新增大题
    saveDetail(formData) {
      this.$refs[formData].validate((valid) => {
        if (valid) {
          //定义一个id的集合，用来id自加
          var ids = [];
          for (let paperDetailStruct of this.paperDetailStructs) {
            ids.push(paperDetailStruct.id);
          }
          ids.sort();
          var maxId;
          if (ids.length == 0) {
            maxId = 0;
          } else {
            maxId = ids[ids.length - 1];
          }
          var paperDetailStruct = Object.assign({}, this.paperDetailStructForm);
          paperDetailStruct.id = maxId + 1;
          paperDetailStruct.number = this.paperDetailStructs.length + 1;
          this.paperDetailStructs.push(paperDetailStruct);
          this.detailDialog = false;
          this.blueStruct.paperDetailStructs = this.paperDetailStructs;
          sessionStorage.setItem("blueStruct", JSON.stringify(this.blueStruct));
        } else {
          return false;
        }
      });
    },
    //删除大题
    deleteDetail(row) {
      for (var i = 0; i < this.paperDetailStructs.length; i++) {
        if (row.id === this.paperDetailStructs[i].id) {
          this.paperDetailStructs.splice(i, 1);
        }
      }
      this.blueStruct.paperDetailStructs = this.paperDetailStructs;
      sessionStorage.setItem("blueStruct", JSON.stringify(this.blueStruct));
    },
    //修改大题
    editDetail(row) {
      this.dialogType = "EDIT";
      for (let paperDetailStruct of this.paperDetailStructs) {
        if (row.id === paperDetailStruct.id) {
          this.paperDetailStructForm.name = paperDetailStruct.name;
          this.paperDetailStructForm.remark = paperDetailStruct.remark;
          this.paperDetailStructForm.id = row.id;
        }
      }
      this.detailDialog = true;
    },
    //保存修改大题
    saveEditDetail(formData) {
      this.$refs[formData].validate((valid) => {
        if (valid) {
          for (let paperDetailStruct of this.paperDetailStructs) {
            if (this.paperDetailStructForm.id === paperDetailStruct.id) {
              console.log("paperDetailStruct", paperDetailStruct);
              paperDetailStruct.name = this.paperDetailStructForm.name;
              paperDetailStruct.remark = this.paperDetailStructForm.remark;
            }
          }
          this.paperDetailStructForm = { name: "", remark: "", id: "" };
          this.blueStruct.paperDetailStructs = this.paperDetailStructs;
          sessionStorage.setItem("blueStruct", JSON.stringify(this.blueStruct));
          this.detailDialog = false;
        }
      });
    },
    //返回
    back() {
      this.$router.push({
        path: "/questions/blue_paper_structure/1",
      });
    },
    //关闭窗口
    closeDialog(formData) {
      this.resetForm(formData);
      this.detailDialog = false;
    },
    //得到题型
    getTpye(val) {
      //console.log(val);
      for (var ques of this.quesTypes) {
        if (val === ques.value) {
          return ques.label;
        }
      }
    },
    getCourseName(courseNo) {
      for (let course of this.courseList) {
        if (course.code == courseNo) {
          this.blueStruct.courseName = course.name;
        }
      }
    },
    //大题详情
    detailInfo(row) {
      if (!this.blueStruct.coursePropertyId) {
        this.$notify({
          type: "error",
          message: "请选择课程属性",
        });
        return false;
      }
      this.getCourseName(this.blueStruct.courseNo);
      sessionStorage.setItem("blueStruct", JSON.stringify(this.blueStruct));
      this.$router.push({
        path:
          "/questions/insert_blue_paper_structure_info/" +
          row.id +
          "/" +
          this.paperStructId,
      });
    },
    //清空
    removeItem() {
      sessionStorage.removeItem("blueStruct");
    },
    getCoursePropertyName() {
      if (this.blueStruct.coursePropertyId) {
        for (let objCouPro of this.coursePropertyList) {
          if (objCouPro.id == this.blueStruct.coursePropertyId) {
            this.blueStruct.coursePropertyName = objCouPro.name;
          }
        }
      } else {
        this.blueStruct.coursePropertyName = "";
      }
    },
    //查询所有课程
    getCourses(query) {
      query = query.trim();
      this.courseLoading = true;
      this.$http
        .get(CORE_API + "/course/query?name=" + query + "&enable=true")
        .then((response) => {
          this.courseList = response.data;
          this.courseLoading = false;
          if (this.blueStruct.courseNo) {
            this.getCoursePropertyList();
          }
        });
    },
    clearCourseProperty() {
      this.blueStruct.coursePropertyId = "";
      this.coursePropertyList = [];
      this.getCoursePropertyList();
    },
    resetForm(formData) {
      this.paperDetailStructForm.name = "";
      this.paperDetailStructForm.remark = "";
      this.$refs[formData].clearValidate();
    },
  },
};
</script>
<style scoped src="../styles/Common.css"></style>
