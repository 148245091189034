<template>
  <section class="content">
    <div class="box box-info">
      <div
        v-loading.body="loading"
        v-loading.fullscreen="loading"
        class="box-body"
        element-loading-text="请稍后..."
      >
        <div class="left-div">
          <div class="row-div">
            <div class="total-div">
              <span style="width: 85px; height: 85px; display: block">
                <v-icon name="users" style="height: 50px; width: 50px" /> </span
              ><span style="display: block; margin-left: 10px">
                <span
                  style="display: block; font-weight: bold; font-size: 40px"
                  >{{ totalStudent }}</span
                >
                <span style="display: block">注册学生</span>
              </span>
            </div>
            <div class="online-div">
              <span style="width: 85px; height: 85px; display: block">
                <v-icon
                  name="user-check"
                  style="height: 50px; width: 50px"
                /> </span
              ><span style="display: block; margin-left: 10px">
                <span
                  style="display: block; font-weight: bold; font-size: 40px"
                  >{{ totalOnlineStudent }}</span
                >
                <span style="display: block">在线学生</span>
              </span>
            </div>
          </div>
          <div class="tb-div">
            <div class="row-div">
              <span style="float: left"> 学校在线学生</span
              ><span style="float: right"
                ><el-button
                  size="small"
                  type="primary"
                  icon="el-icon-document"
                  @click="toInfoPage"
                >
                  详情
                </el-button></span
              >
              <span style="float: right; margin-right: 10px"
                ><el-button
                  size="small"
                  type="primary"
                  icon="el-icon-refresh"
                  @click="refresh"
                >
                  刷新
                </el-button></span
              >
            </div>
            <div class="row-div">
              <el-table
                :data="tableData"
                border
                resizable
                stripe
                style="width: 100%"
              >
                <el-table-column prop="rootOrgName" label="学校">
                </el-table-column>
                <el-table-column width="100" prop="totalCount" label="注册学生">
                </el-table-column>
                <el-table-column
                  width="100"
                  prop="onlineCount"
                  label="在线学生"
                >
                </el-table-column>
                <el-table-column
                  width="100"
                  prop="onExamCount"
                  label="在考学生"
                >
                </el-table-column>
              </el-table>
              <div class="page pull-right">
                <el-pagination
                  v-if="paginationShow"
                  :current-page="currentPage"
                  :page-size="pageSize"
                  :page-sizes="[10, 20, 50, 100, 200, 300]"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="total"
                  @current-change="handleCurrentChange"
                  @size-change="handleSizeChange"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="right-div">
          <div class="row-div">近5日在线学生</div>
          <div class="row-div">
            <v-charts style="width: 500px" :options="lastNdayData"></v-charts>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { REPORTS_API } from "@/constants/constants.js";
import { mapState } from "vuex";
import ECharts from "vue-echarts/components/ECharts";
export default {
  name: "ExamStudentOnline",
  components: {
    "v-charts": ECharts,
  },
  data() {
    return {
      lastNdayData: {
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          axisLabel: {
            interval: 0,
            rotate: 20,
          },
          type: "category",
          data: [],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: [],
            type: "line",
          },
        ],
      },
      totalStudent: 0,
      totalOnlineStudent: 0,
      loading: false,
      paginationShow: false,
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 10,
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
    isSuperAdmin() {
      return this.user.roleList.some((role) => role.roleCode == "SUPER_ADMIN");
    },
  },
  //初始化查询
  created() {
    this.init();
  },
  methods: {
    toInfoPage() {
      this.$router.push({
        path: "/reports/online-detail",
      });
    },
    refresh() {
      this.searchTotalStudent();
      this.searchLastNDayData();
      this.searchTotalOnlineStudent();
      this.searchForm();
    },
    handleSearchBtn() {
      this.currentPage = 1;
      this.searchForm();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.searchForm();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.searchForm();
    },
    //查询
    async searchForm() {
      this.loading = true;
      var url =
        REPORTS_API +
        "/studentTotalCount/page/" +
        this.currentPage +
        "/" +
        this.pageSize;
      this.$httpWithMsg
        .get(url)
        .then((response) => {
          this.tableData = response.data.list;
          this.total = response.data.total;
          this.loading = false;

          this.$nextTick(function () {
            this.paginationShow = true;
          });
        })
        .finally(() => (this.loading = false));
    },
    async searchTotalStudent() {
      var url = REPORTS_API + "/studentTotalCount/getSumTotalCount";
      this.$httpWithMsg.get(url).then((response) => {
        this.totalStudent = response.data;
      });
    },
    async searchTotalOnlineStudent() {
      var url = REPORTS_API + "/studentCount/getSumOnlineCount";
      this.$httpWithMsg.get(url).then((response) => {
        this.totalOnlineStudent = response.data;
      });
    },
    async searchLastNDayData() {
      var url =
        REPORTS_API + "/studentCumulativeCount/getLastNdayOnlineCount?nday=5";
      this.$httpWithMsg.get(url).then((response) => {
        let xdata = [];
        response.data.forEach((e) => {
          xdata.push(e.reportDay);
        });
        this.lastNdayData.xAxis.data = xdata;
        let ydata = [];
        response.data.forEach((e) => {
          ydata.push(e.totalCount);
        });
        this.lastNdayData.series = [
          {
            data: ydata,
            type: "line",
          },
        ];
      });
    },
    init() {
      this.searchTotalStudent();
      this.searchLastNDayData();
      this.searchTotalOnlineStudent();
      this.searchForm();
    },
  },
};
</script>

<style scoped>
.page {
  margin-top: 10px;
}
.pull-length {
  width: 300px;
}
.pull-center {
  margin-top: 20px;
}
.editForm .el-form-item {
  margin-bottom: 12px;
}
.left-div {
  width: 49%;
  float: left;
}
.right-div {
  width: 49%;
  float: right;
  border: 1px solid #ddd;
}
.row-div {
  width: 100%;
  display: inline-block;
}
.total-div {
  display: flex;
  float: left;
  border: 1px solid #ddd;
  padding: 5px;
  width: 49%;
}
.online-div {
  display: flex;
  float: right;
  border: 1px solid #ddd;
  padding: 5px;
  width: 49%;
}
.tb-div {
  width: 100%;
  border: 1px solid #ddd;
  padding: 5px;
}
</style>
