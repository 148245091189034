<template>
  <section style="margin-top: 0px">
    <el-form
      :model="formSearch"
      :inline="true"
      style="border-bottom: 1px solid rgb(221, 221, 221); margin-bottom: 10px"
    >
      <el-form-item label="考试">
        <el-select
          v-model="formSearch.examId"
          :remote-method="getExams"
          placeholder="请选择"
          remote
          filterable
          clearable
          size="small"
          class="w180"
          @clear="getExams"
        >
          <el-option
            v-for="item in examList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="类型">
        <el-select
          v-model="formSearch.type"
          size="small"
          class="w180"
          placeholder="请选择"
          clearable
          @clear="clearTypeValue"
        >
          <el-option label="考试明细" value="EXAM_DETAIL"></el-option>
          <el-option label="成绩明细" value="SCORE_STATISTIC"></el-option>
          <el-option label="考试进度" value="EXAM_SCHEDULING"></el-option>
          <el-option label="监考已审" value="AUDIT"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="状态">
        <el-select
          v-model="formSearch.status"
          size="small"
          class="w180"
          placeholder="请选择"
          clearable
          @clear="clearStatusValue"
        >
          <el-option label="导出中" value="EXPORTING"></el-option>
          <el-option label="成功" value="SUCCESS"></el-option>
          <el-option label="失败" value="ERROR"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button
          size="small"
          type="primary"
          icon="el-icon-search"
          @click="doSearch(1)"
          >查询
        </el-button>

        <el-button size="small" icon="el-icon-refresh" @click="resetSearchForm">
          重置
        </el-button>
      </el-form-item>
    </el-form>

    <el-table
      v-loading="loading"
      :data="tableData"
      element-loading-text="数据加载中"
      style="width: 100%"
      border
      stripe
    >
      <el-table-column label="考试ID" prop="examId" width="90px" sortable />
      <el-table-column
        label="考试名称"
        prop="examName"
        width="105px"
        sortable
      />
      <el-table-column
        label="任务类型"
        prop="typeTitle"
        width="105px"
        sortable
      />
      <el-table-column
        label="任务状态"
        prop="statusTitle"
        width="105px"
        sortable
      />
      <el-table-column label="状态描述" prop="statusMsg" />
      <el-table-column label="创建人" prop="createName" width="90px" sortable />
      <el-table-column
        label="创建时间"
        prop="creationTime"
        width="155px"
        sortable
      />
      <el-table-column
        label="开始时间"
        prop="startTime"
        width="155px"
        sortable
      />
      <el-table-column label="结束时间" prop="endTime" width="155px" sortable />

      <el-table-column label="操作" width="270px">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-download"
            :disabled="scope.row.status != 'SUCCESS'"
            plain
            @click="download(scope.row)"
            >下载
          </el-button>

          <el-button
            size="mini"
            type="danger"
            icon="el-icon-delete"
            :disabled="scope.row.status == 'EXPORTING'"
            plain
            @click="doDelete(scope.row)"
            >删除
          </el-button>
          <el-button
            size="mini"
            type="danger"
            icon="el-icon-delete"
            :disabled="scope.row.status != 'EXPORTING'"
            plain
            @click="doStop(scope.row)"
            >终止
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="page pull-right">
      <el-pagination
        :current-page="formSearch.pageNo"
        :page-size="formSearch.pageSize"
        :page-sizes="[10, 20, 50, 100, 200, 300]"
        :total="totalElements"
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="handlePagerNo"
        @size-change="handlePagerSize"
      ></el-pagination>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      formSearch: {
        examId: "",
        type: null,
        status: null,
        pageNo: 1,
        pageSize: 10,
      },
      loading: false,
      tableData: [],
      totalElements: 0,
      examList: [],
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
  },
  created() {
    this.getExams();
  },
  methods: {
    getExams(examName) {
      if (!examName) {
        examName = "";
      }
      this.$http
        .get("/api/ecs_exam_work/exam/queryByNameLike", {
          params: {
            enable: true,
            name: examName,
            examTypes: "ONLINE#OFFLINE#ONLINE_HOMEWORK",
          },
        })
        .then((response) => {
          this.examList = response.data;
        });
    },
    doSearch(pageNo) {
      if (!this.formSearch.examId) {
        this.$notify({
          title: "警告",
          message: "请选择考试",
          type: "warning",
          duration: 2000,
        });
        return false;
      }
      this.formSearch.pageNo = pageNo;

      this.loading = true;
      let url = "/api/ecs_oe_admin/export/task/list";
      this.$http.post(url, this.formSearch).then(
        (response) => {
          this.tableData = response.data.content;
          this.totalElements = response.data.totalElements;
          this.loading = false;
        },
        (error) => {
          console.log(error);
          this.loading = false;
        }
      );
    },
    doDelete(row) {
      this.$confirm("确认删除当前任务？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let url = "/api/ecs_oe_admin/export/task/delete?taskId=" + row.id;
        this.$httpWithMsg.post(url).then(() => {
          this.$notify({
            type: "success",
            message: "删除成功",
          });
          this.doSearch();
        });
      });
    },
    doStop(row) {
      this.$confirm("确认终止当前任务？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let url = "/api/ecs_oe_admin/export/task/stop?taskId=" + row.id;
        this.$httpWithMsg.post(url).then(() => {
          this.$notify({
            type: "success",
            message: "操作成功",
          });
          this.doSearch();
        });
      });
    },
    download(row) {
      let opath = row.filePath;
      let indx = opath.lastIndexOf("/");
      let head = opath.substring(0, indx + 1);
      let tail = opath.substring(indx + 1);
      window.location.href = head + encodeURIComponent(tail);
    },
    handlePagerNo(pageNo) {
      this.doSearch(pageNo);
    },
    handlePagerSize(pageSize) {
      this.formSearch.pageSize = pageSize;
      this.doSearch(1);
    },
    clearTypeValue() {
      this.formSearch.type = null;
    },
    clearStatusValue() {
      this.formSearch.status = null;
    },
    resetSearchForm() {
      this.formSearch.examId = "";
      this.formSearch.type = null;
      this.formSearch.status = null;
      this.doSearch(1);
    },
  },
};
</script>

<style scoped>
.page {
  margin-top: 10px;
}
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
.w180 {
  width: 180px;
}
</style>
