<template>
  <div v-loading="fullscreenLoading" element-loading-text="正在组卷中...">
    <div class="tabs">
      <el-form
        :inline="true"
        :model="genPaper"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="6">
            <el-form-item label="课程代码" class="form-item">
              <el-input
                v-model="genPaper.courseNo"
                class="search_width"
                :disabled="true"
                placeholder="课程代码"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="课程名称" class="form-item">
              <el-input
                v-model="genPaper.courseName"
                class="search_width"
                :disabled="true"
                placeholder="课程名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="试卷名称" class="form-item">
              <el-input
                v-model="genPaper.paperName"
                class="search_width"
                placeholder="试卷名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="组卷套数" class="form-item">
              <el-input
                v-model="genPaper.genNumber"
                class="search_width"
                placeholder="组卷套数"
                :disabled="genModelType == 'M-Type'"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="组卷模式" class="form-item">
              <el-radio-group
                v-model="genModelType"
                @change="genModelTypeChange"
              >
                <el-radio label="A-Type">自动组卷</el-radio>
                <el-radio label="M-Type">手动组卷</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div v-show="genModelType == 'A-Type'">
      <div class="tabs">
        <el-tabs v-model="tabs">
          <el-tab-pane label="步骤一：组卷模式选择" name="first">
            <el-radio-group
              v-model="genType"
              class="pull-left"
              @change="genTypeChange"
            >
              <el-radio label="SIMPLE">简易成卷</el-radio>
              <el-radio label="NORMAL">精确成卷</el-radio>
              <el-radio label="BLUE">蓝图成卷</el-radio>
            </el-radio-group>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="tabs">
        <el-tabs v-model="tabs">
          <el-tab-pane label="步骤二：题源选择" name="first">
            <div v-show="genType == 'SIMPLE'" style="width: 50%">
              <el-form
                :inline="true"
                label-position="right"
                label-width="100px"
              >
                <el-form-item label="题源选择" class="form-item">
                  <el-select
                    v-model="paperType"
                    clearable
                    placeholder="题源选择"
                    style="width: 120px"
                    @change="changePaperType"
                  >
                    <el-option label="题库来源" value="IMPORT"></el-option>
                    <el-option label="卷库来源" value="GENERATE"></el-option>
                  </el-select>
                </el-form-item>
              </el-form>
              <el-table
                v-loading="loading"
                :data="selectPapers"
                border
                @selection-change="selectChange"
              >
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column label="名称" width="200">
                  <template slot-scope="scope">
                    <div>
                      <span>{{ scope.row.name }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="总分" width="100">
                  <template slot-scope="scope">
                    <div>
                      <span>{{ scope.row.totalScore }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="小题数量">
                  <template slot-scope="scope">
                    <div>
                      <span>{{ scope.row.unitCount }}</span>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <div class="page pull-right">
                <el-pagination
                  :current-page="curSelect"
                  :page-size="pageSize"
                  layout="total, prev, pager, next, jumper"
                  :total="totalSelect"
                  @current-change="selectCurrentChange"
                >
                </el-pagination>
              </div>
            </div>
            <!-- 精确组卷题源 -->
            <div
              v-show="genType == 'NORMAL'"
              style="width: 50%; overflow: auto"
            >
              <el-form
                :inline="true"
                label-position="right"
                label-width="100px"
              >
                <el-form-item label="题源选择" class="form-item">
                  <el-select
                    v-model="paperType"
                    clearable
                    placeholder="题源选择"
                    style="width: 120px"
                    @change="changePaperType"
                  >
                    <el-option label="题库来源" value="IMPORT"></el-option>
                    <el-option label="卷库来源" value="GENERATE"></el-option>
                  </el-select>
                </el-form-item>
              </el-form>
              <el-table
                v-loading="loading"
                :data="selectPapers"
                border
                @selection-change="selectChange"
              >
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column label="名称" width="200">
                  <template slot-scope="scope">
                    <div>
                      <span>{{ scope.row.name }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="总分" width="100">
                  <template slot-scope="scope">
                    <div>
                      <span>{{ scope.row.totalScore }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="小题数量">
                  <template slot-scope="scope">
                    <div>
                      <span>{{ scope.row.unitCount }}</span>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <div class="page pull-right">
                <el-pagination
                  :current-page="curSelect"
                  :page-size="pageSize"
                  layout="total, prev, pager, next, jumper"
                  :total="totalSelect"
                  @current-change="selectCurrentChange"
                >
                </el-pagination>
              </div>
              <el-form
                :inline="true"
                label-position="right"
                label-width="100px"
              >
                <el-form-item
                  label="选中试卷列表"
                  class="form-item"
                ></el-form-item>
              </el-form>
              <el-table :data="selectedPapers" border>
                <el-table-column label="名称" width="200">
                  <template slot-scope="scope">
                    <div>
                      <span>{{ scope.row.name }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <div>
                      <el-button
                        type="danger"
                        size="mini"
                        @click="removeSelected(scope.row.id)"
                        >移除</el-button
                      >
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <!-- 蓝图组卷题源 -->
            <div v-show="genType == 'BLUE'" style="width: 50%; overflow: auto">
              <el-form
                :inline="true"
                label-position="right"
                label-width="100px"
              >
                <el-form-item label="题源选择" class="form-item">
                  <el-select
                    v-model="paperType"
                    clearable
                    placeholder="题源选择"
                    style="width: 120px"
                    @change="changePaperType"
                  >
                    <el-option label="题库来源" value="IMPORT"></el-option>
                    <el-option label="卷库来源" value="GENERATE"></el-option>
                  </el-select>
                </el-form-item>
              </el-form>
              <el-table
                v-loading="loading"
                :data="selectPapers"
                border
                @selection-change="selectChange"
              >
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column label="名称" width="200">
                  <template slot-scope="scope">
                    <div>
                      <span>{{ scope.row.name }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="总分" width="100">
                  <template slot-scope="scope">
                    <div>
                      <span>{{ scope.row.totalScore }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="小题数量">
                  <template slot-scope="scope">
                    <div>
                      <span>{{ scope.row.unitCount }}</span>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <div class="page pull-right">
                <el-pagination
                  :current-page="curSelect"
                  :page-size="pageSize"
                  layout="total, prev, pager, next, jumper"
                  :total="totalSelect"
                  @current-change="selectCurrentChange"
                >
                </el-pagination>
              </div>
              <el-form
                :inline="true"
                label-position="right"
                label-width="100px"
              >
                <el-form-item
                  label="选中试卷列表"
                  class="form-item"
                ></el-form-item>
              </el-form>
              <el-table :data="selectedPapers" border>
                <el-table-column label="名称" width="200">
                  <template slot-scope="scope">
                    <div>
                      <span>{{ scope.row.name }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <div>
                      <el-button
                        type="danger"
                        size="mini"
                        @click="removeSelected(scope.row.id)"
                        >移除</el-button
                      >
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>

      <div class="tabs">
        <el-tabs v-model="tabs">
          <el-tab-pane label="步骤三：确定构成" name="first">
            <!-- 简易 -->
            <div
              v-show="genType == 'SIMPLE'"
              style="width: 98%; overflow: auto"
            >
              <el-form
                :inline="true"
                label-position="right"
                label-width="100px"
              >
                <el-form-item label="抽取策略" class="form-item">
                  <el-select
                    v-model="genPaper.simpleGenPaperPolicy"
                    clearable
                    placeholder="抽取策略"
                  >
                    <el-option
                      v-for="item in simpleGenPaperPolicys"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-form>
              <el-table :data="tempPapers" border>
                <el-table-column label="冻结" width="90">
                  <template slot-scope="scope">
                    <el-checkbox
                      @change="(checked) => frozenChange(checked, scope.row.id)"
                    ></el-checkbox>
                  </template>
                </el-table-column>
                <el-table-column label="名称" width="140">
                  <template slot-scope="scope">
                    <div>
                      <span>{{ scope.row.name }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="总分" width="80">
                  <template slot-scope="scope">
                    <div>
                      <span>{{ scope.row.totalScore }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="小题数量" width="80">
                  <template slot-scope="scope">
                    <div>
                      <span>{{ scope.row.unitCount }}</span>
                    </div>
                  </template>
                </el-table-column>
                <!-- 新增公开度和难度 -->
                <el-table-column label="公开（简单）" width="100">
                  <template slot-scope="scope">
                    <div
                      @mouseenter="showActiveIn(1, 1, scope.row)"
                      @mouseleave="showActiveOff"
                    >
                      <el-tooltip placement="top">
                        <div slot="content">{{ message }}</div>
                        <span
                          ><el-input
                            v-model="scope.row.publicSimple"
                            @input="
                              sum(
                                scope.row,
                                scope.row.publicSimple,
                                'publicSimple'
                              )
                            "
                          ></el-input
                        ></span>
                      </el-tooltip>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="公开（中等）" width="100">
                  <template slot-scope="scope">
                    <div
                      @mouseenter="showActiveIn(1, 2, scope.row)"
                      @mouseleave="showActiveOff"
                    >
                      <el-tooltip placement="top">
                        <div slot="content">{{ message }}</div>
                        <span
                          ><el-input
                            v-model="scope.row.publicMedium"
                            @input="
                              sum(
                                scope.row,
                                scope.row.publicMedium,
                                'publicMedium'
                              )
                            "
                          ></el-input
                        ></span>
                      </el-tooltip>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="公开（困难）" width="100">
                  <template slot-scope="scope">
                    <div
                      @mouseenter="showActiveIn(1, 3, scope.row)"
                      @mouseleave="showActiveOff"
                    >
                      <el-tooltip placement="top">
                        <div slot="content">{{ message }}</div>
                        <span
                          ><el-input
                            v-model="scope.row.publicDifficulty"
                            @input="
                              sum(
                                scope.row,
                                scope.row.publicDifficulty,
                                'publicDifficulty'
                              )
                            "
                          ></el-input
                        ></span>
                      </el-tooltip>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="非公开（简单）" width="100">
                  <template slot-scope="scope">
                    <div
                      @mouseenter="showActiveIn(0, 1, scope.row)"
                      @mouseleave="showActiveOff"
                    >
                      <el-tooltip placement="top">
                        <div slot="content">{{ message }}</div>
                        <span
                          ><el-input
                            v-model="scope.row.noPublicSimple"
                            @input="
                              sum(
                                scope.row,
                                scope.row.noPublicSimple,
                                'noPublicSimple'
                              )
                            "
                          ></el-input
                        ></span>
                      </el-tooltip>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="非公开（中等）" width="100">
                  <template slot-scope="scope">
                    <div
                      @mouseenter="showActiveIn(0, 2, scope.row)"
                      @mouseleave="showActiveOff"
                    >
                      <el-tooltip placement="top">
                        <div slot="content">{{ message }}</div>
                        <span
                          ><el-input
                            v-model="scope.row.noPublicMedium"
                            @input="
                              sum(
                                scope.row,
                                scope.row.noPublicMedium,
                                'noPublicMedium'
                              )
                            "
                          ></el-input
                        ></span>
                      </el-tooltip>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="非公开（困难）" width="100">
                  <template slot-scope="scope">
                    <div
                      @mouseenter="showActiveIn(0, 3, scope.row)"
                      @mouseleave="showActiveOff"
                    >
                      <el-tooltip placement="top">
                        <div slot="content">{{ message }}</div>
                        <span
                          ><el-input
                            v-model="scope.row.noPublicDifficulty"
                            @input="
                              sum(
                                scope.row,
                                scope.row.noPublicDifficulty,
                                'noPublicDifficulty'
                              )
                            "
                          ></el-input
                        ></span>
                      </el-tooltip>
                    </div>
                  </template>
                </el-table-column>
                <!-- 总数求和 -->
                <el-table-column :label="setName()" width="100">
                  <template slot-scope="scope">
                    <div>
                      <span
                        ><el-input
                          v-model="scope.row.count"
                          placeholder="抽取数值"
                          :disabled="true"
                        ></el-input
                      ></span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <div>
                      <el-button
                        type="danger"
                        size="mini"
                        @click="removeSelected(scope.row.id)"
                        >移除</el-button
                      >
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <!-- 精确 -->
            <div
              v-show="genType == 'NORMAL'"
              style="width: 50%; overflow: auto"
            >
              <el-form
                :inline="true"
                label-position="right"
                label-width="100px"
              >
                <el-form-item label="试卷结构" class="form-item">
                  <el-select
                    v-model="genPaper.paperStructId"
                    clearable
                    placeholder="试卷结构"
                    @change="changePaperStruct"
                  >
                    <el-option
                      v-for="item in exactStructs"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-form>
              <el-table :data="paperDetailStructs" border>
                <el-table-column label="名称" width="250">
                  <template slot-scope="scope">
                    <div>
                      <span>{{ scope.row.name }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="总分" width="100">
                  <template slot-scope="scope">
                    <div>
                      <span>{{ scope.row.totalScore }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="题量">
                  <template slot-scope="scope">
                    <div>
                      <span>{{ scope.row.detailCount }}</span>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <!-- 蓝图 -->
            <div v-show="genType == 'BLUE'" style="width: 50%; overflow: auto">
              <el-form
                :inline="true"
                label-position="right"
                label-width="100px"
              >
                <el-form-item label="试卷结构" class="form-item">
                  <el-select
                    v-model="genPaper.paperStructId"
                    clearable
                    placeholder="试卷结构"
                    style="width: 120px"
                    @change="changePaperStruct"
                  >
                    <el-option
                      v-for="item in blueStructs"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="难度">
                  <el-input
                    v-model="difficulty"
                    style="width: 50px"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-form>
              <el-table :data="paperDetailStructs" border>
                <el-table-column label="名称" width="250">
                  <template slot-scope="scope">
                    <div>
                      <span>{{ scope.row.name }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="总分" width="100">
                  <template slot-scope="scope">
                    <div>
                      <span>{{ scope.row.totalScore }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="题量">
                  <template slot-scope="scope">
                    <div>
                      <span>{{ scope.row.detailCount }}</span>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-tab-pane>
        </el-tabs>
        <div class="pull-right">
          <el-button type="primary" icon="check" @click="confirmGenPaper"
            >确定</el-button
          >
          <el-button type="primary" icon="caret-left" @click="back"
            ><i class="el-icon-arrow-left"></i> 返 回</el-button
          >
        </div>
      </div>
    </div>
    <div v-show="genModelType == 'M-Type'">
      <div style="width: 50%; overflow: auto">
        <el-form :inline="true" label-position="right" label-width="100px">
          <el-form-item label="试卷结构" class="form-item">
            <el-select
              v-model="genPaper.paperStructId"
              clearable
              placeholder="试卷结构"
              @change="changePaperStruct"
            >
              <el-option
                v-for="item in exactStructs"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <el-table :data="paperDetailStructs" border>
          <el-table-column label="名称" width="250">
            <template slot-scope="scope">
              <div>
                <span>{{ scope.row.name }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="总分" width="100">
            <template slot-scope="scope">
              <div>
                <span>{{ scope.row.totalScore }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="题量">
            <template slot-scope="scope">
              <div>
                <span>{{ scope.row.detailCount }}</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pull-right">
        <el-button type="primary" icon="check" @click="confirmGenPaperMType"
          >确定</el-button
        >
        <el-button type="primary" icon="caret-left" @click="back"
          ><i class="el-icon-arrow-left"></i> 返 回</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { QUESTION_API, CORE_API } from "@/constants/constants";
import { mapState } from "vuex";
export default {
  data() {
    return {
      tabs: "first",
      genType: "SIMPLE",
      genModelType: "A-Type",
      frozenPaperSet: new Set(),
      genPaper: {
        frozenPaperIds: [],
        courseNo: "",
        courseName: "",
        level: "",
        paperName: "",
        paperIds: [],
        genNumber: "",
        paperStructId: "",
        simpleParams: {},
        simpleGenPaperPolicy: "BY_QUESTIONNUM",
      },
      simpleGenPaperPolicys: [
        { label: "按试题数量抽取", value: "BY_QUESTIONNUM" },
        { label: "按试题分数抽取", value: "BY_SCORE" },
      ],
      tempPapers: [],
      tempPaperIds: [],
      selectPapers: [],
      selectAllPapers: [],
      paperStructs: [],
      paperDetailStructs: [],
      blueStructs: [], //蓝图组卷
      exactStructs: [], //精确组卷
      curSelect: 1,
      totalSelect: 10,
      pageSize: 10,
      loading: false,
      fullscreenLoading: false,
      difficulty: "",
      coursePropertyList: [],
      paperType: "IMPORT",
      selectedNPapers: [],
      message: "",
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
    selectedPapers() {
      var selectedPapers = [];
      for (let paper of this.tempPapers) {
        selectedPapers.push({
          id: paper.id,
          name: paper.name,
          totalScore: paper.totalScore,
          unitCount: paper.unitCount,
          simpleParam: "",
        });
      }
      return selectedPapers;
    },
    selectedIds() {
      var selectedIds = [];
      for (let paper of this.selectedPapers) {
        selectedIds.push(paper.id);
      }
      return selectedIds;
    },
  },
  created() {
    this.genPaper.courseNo = this.$route.params.courseNo;
    var url =
      CORE_API +
      "/course/byCode?" +
      "rootOrgId=" +
      this.user.rootOrgId +
      "&code=" +
      this.genPaper.courseNo;
    this.$httpWithMsg
      .get(url)
      .then((response) => {
        this.genPaper.courseName = response.data.name;
        this.genPaper.level = response.data.level;
      })
      .finally(() => {});
    this.searchPaper();
    this.getCoursePropertyList();
  },
  methods: {
    frozenChange(val, paperId) {
      if (val) {
        this.frozenPaperSet.add(paperId);
      } else {
        this.frozenPaperSet.delete(paperId);
      }
    },
    setName() {
      if (this.genPaper.simpleGenPaperPolicy == "BY_QUESTIONNUM") {
        return "抽取数量";
      } else {
        return "抽取分数";
      }
    },
    genModelTypeChange() {
      if (this.genModelType == "M-Type") {
        this.genPaper.genNumber = "1";
      }
    },
    genTypeChange() {
      this.curSelect = 1;
      this.tempPapers = [];
      this.paperIds = [];
      this.genPaper.paperStructId = "";
      //this.genPaper.simpleGenPaperPolicy = '';
      this.tempPaperIds = [];
      this.paperDetailStructs = [];
      this.searchPaper();
    },
    selectChange(val) {
      console.log("val123:");
      val.forEach((element) => {
        element.publicSimple = 0;
        element.publicMedium = 0;
        element.publicDifficulty = 0;
        element.noPublicSimple = 0;
        element.noPublicMedium = 0;
        element.noPublicDifficulty = 0;
        element.count = 0;
        this.tempPapers.push(Object.assign({}, element));
        this.tempPaperIds.push(element.id);
      });
      if (
        val.length == this.selectPapers.length &&
        this.curSelect * this.pageSize + 1 > this.totalSelect
      ) {
        this.curSelect = 1;
      }
      this.searchPaper();
    },
    searchPaper() {
      this.loading = true;
      let fillCount = false;
      if (this.genType == "SIMPLE") {
        fillCount = true;
      } else {
        fillCount = false;
      }

      if (this.paperType == "IMPORT") {
        this.$http
          .get(
            QUESTION_API +
              "/importPaper/huoge/" +
              this.curSelect +
              "/" +
              this.pageSize +
              "?courseNo=" +
              this.genPaper.courseNo +
              "&ids=" +
              this.tempPaperIds +
              "&fillCount=" +
              fillCount
          )
          .then((response) => {
            console.log("response:", response);
            this.selectPapers = response.data.content;
            this.totalSelect = response.data.totalElements;
            this.curSelect = response.data.number + 1;
            this.loading = false;
          });
      } else if (this.paperType == "GENERATE") {
        this.$http
          .get(
            QUESTION_API +
              "/genPaper/huoge/" +
              this.curSelect +
              "/" +
              this.pageSize +
              "?courseNo=" +
              this.genPaper.courseNo +
              "&ids=" +
              this.tempPaperIds +
              "&fillCount=" +
              fillCount
          )
          .then((response) => {
            this.selectPapers = response.data.content;
            this.totalSelect = response.data.totalElements;
            this.curSelect = response.data.number + 1;
            this.loading = false;
          });
      } else {
        console.log("空值");
        this.selectPapers = [];
        this.totalSelect = 0;
        this.loading = false;
      }
    },
    changePaperStruct() {
      this.paperDetailStructs = [];
      this.searchPaperDetailStructs();
    },
    searchPaperStructs() {
      console.log("bbb");
      var courseNo = this.genPaper.courseNo;
      var url = QUESTION_API + "/paperStruct?courseNo=" + courseNo;
      this.loading = true;
      this.$http.get(url).then((response) => {
        this.paperStructs = response.data;
        for (let paperStructObj of this.paperStructs) {
          if (paperStructObj.paperStrucType == "BLUEPRINT") {
            for (let couProperty of this.coursePropertyList) {
              if (couProperty.id == paperStructObj.coursePropertyId) {
                this.blueStructs.push(paperStructObj);
              }
            }
          } else {
            this.exactStructs.push(paperStructObj);
          }
        }
        this.loading = false;
      });
    },
    searchPaperDetailStructs() {
      console.log("this.genPaper.paperStructId:", this.genPaper.paperStructId);
      for (let paperStruct of this.paperStructs) {
        if (paperStruct.id == this.genPaper.paperStructId) {
          this.paperDetailStructs = paperStruct.paperDetailStructs;
          this.difficulty = paperStruct.difficulty;
          break;
        }
      }
    },
    checkGenPaper() {
      if (!this.genPaper.paperName) {
        this.$notify({
          message: "试卷名称不能为空!",
          type: "error",
        });
        return false;
      }
      var numReg = /^[1-9]\d*$/;
      if (!this.genPaper.genNumber) {
        this.$notify({
          message: "请输入需要组卷套数!",
          type: "error",
        });
        return false;
      }
      if (
        !numReg.test(this.genPaper.genNumber) ||
        parseInt(this.genPaper.genNumber) >= 100
      ) {
        this.$notify({
          message: "组卷套数必须为1-99之间的整数!",
          type: "error",
        });
        return false;
      }
      if (this.genType == "SIMPLE" && this.selectedIds.length == 0) {
        this.$notify({
          message: "请选择题源范围!",
          type: "error",
        });
        return false;
      }
      if (this.genType == "NORMAL") {
        if (!this.selectedIds || this.selectedIds.length == 0) {
          this.$notify({
            message: "请选择题源范围！",
            type: "error",
          });
          return false;
        } else if (this.paperDetailStructs.length == 0) {
          this.$notify({
            message: "请选择试卷结构！",
            type: "error",
          });
          return false;
        }
      }
      if (this.genType == "BLUE") {
        if (!this.selectedIds || this.selectedIds.length == 0) {
          this.$notify({
            message: "请选择题源范围！",
            type: "error",
          });
          return false;
        } else if (this.paperDetailStructs.length == 0) {
          this.$notify({
            message: "请选择试卷结构！",
            type: "error",
          });
          return false;
        }
      }
      return true;
    },
    confirmGenPaperMType() {
      if (!this.genPaper.paperName) {
        this.$notify({
          message: "试卷名称不能为空!",
          type: "error",
        });
        return false;
      }
      if (this.paperDetailStructs.length == 0) {
        this.$notify({
          message: "请选择试卷结构！",
          type: "error",
        });
        return false;
      }
      var url = QUESTION_API + "/genPaper/manual";

      this.fullscreenLoading = true;
      this.$http.post(url, this.genPaper).then(
        (response) => {
          this.$notify({
            message: "组卷成功",
            type: "success",
          });
          this.fullscreenLoading = false;
          let paperId = response.data.paper.id;
          this.$router.push({
            path: "/edit_paper/" + paperId + "/gen_paper",
          });
        },
        (error) => {
          this.$notify({
            message: error.response.data.msg,
            type: "error",
          });
          this.fullscreenLoading = false;
        }
      );
    },
    confirmGenPaper() {
      if (!this.checkGenPaper()) {
        return false;
      } else {
        var url = "";
        if (this.genType == "SIMPLE") {
          url = QUESTION_API + "/genPaper/simple";
        } else if (this.genType == "NORMAL") {
          url = QUESTION_API + "/genPaper/normal";
        } else {
          url = QUESTION_API + "/genPaper/blue";
        }
        if (!this.setSimpleParams()) {
          return;
        }
        this.fullscreenLoading = true;
        this.genPaper.paperIds = this.selectedIds;
        this.genPaper.frozenPaperIds = [];
        this.frozenPaperSet.forEach((element) => {
          this.genPaper.frozenPaperIds.push(element);
        });
        this.$http.post(url, this.genPaper).then(
          (response) => {
            this.$notify({
              message: "组卷成功",
              type: "success",
            });
            this.fullscreenLoading = false;
            if (this.genPaper.genNumber == 1) {
              let paperId = response.data.paper.id;
              this.$router.push({
                path: "/edit_paper/" + paperId + "/gen_paper",
              });
            } else {
              this.back();
            }
          },
          (error) => {
            this.$notify({
              message: error.response.data.msg,
              type: "error",
            });
            this.fullscreenLoading = false;
          }
        );
      }
    },
    selectCurrentChange(val) {
      this.curSelect = val;
      this.searchPaper();
    },
    back() {
      this.$router.push({ path: "/questions/gen_paper/1" });
    },
    removePaper(id) {
      for (let [index, paper] of this.selectPapers.entries()) {
        if (id == paper.id) {
          this.selectPapers.splice(index, 1);
          break;
        }
      }
    },
    removeSelected(id) {
      for (let [index, paper] of this.tempPapers.entries()) {
        if (id == paper.id) {
          this.tempPapers.splice(index, 1);
          this.selectPapers.push(paper);
          break;
        }
      }
      for (var i = 0; i < this.tempPaperIds.length; i++) {
        if (this.tempPaperIds[i] == id) {
          this.tempPaperIds.splice(i, 1);
          break;
        }
      }
      this.searchPaper();
    },
    setSimpleParams() {
      var simpleParams = new Object();
      for (let paper of this.tempPapers) {
        console.log("paper", paper);
        if (this.genPaper.simpleGenPaperPolicy == "BY_SCORE") {
          if (!this.isNumber(paper.publicSimple)) {
            this.$notify({
              message: "“抽取策略”中的“抽取分数”列要为正数！",
              type: "error",
            });
            return false;
          }
          if (!this.isNumber(paper.publicMedium)) {
            this.$notify({
              message: "“抽取策略”中的“抽取分数”列要为正数！",
              type: "error",
            });
            return false;
          }
          if (!this.isNumber(paper.publicDifficulty)) {
            this.$notify({
              message: "“抽取策略”中的“抽取分数”列要为正数！",
              type: "error",
            });
            return false;
          }
          if (!this.isNumber(paper.noPublicSimple)) {
            this.$notify({
              message: "“抽取策略”中的“抽取分数”列要为正数！",
              type: "error",
            });
            return false;
          }
          if (!this.isNumber(paper.noPublicMedium)) {
            this.$notify({
              message: "“抽取策略”中的“抽取分数”列要为正数！",
              type: "error",
            });
            return false;
          }
          if (!this.isNumber(paper.noPublicDifficulty)) {
            this.$notify({
              message: "“抽取策略”中的“抽取分数”列要为正数！",
              type: "error",
            });
            return false;
          }
        } else {
          if (!this.isFloat(paper.publicSimple)) {
            this.$notify({
              message: "“抽取策略”中的“抽取数量”列要为正整数！",
              type: "error",
            });
            return false;
          }
          if (!this.isFloat(paper.publicMedium)) {
            this.$notify({
              message: "“抽取策略”中的“抽取数量”列要为正整数！",
              type: "error",
            });
            return false;
          }
          if (!this.isFloat(paper.publicDifficulty)) {
            this.$notify({
              message: "“抽取策略”中的“抽取数量”列要为正整数！",
              type: "error",
            });
            return false;
          }
          if (!this.isFloat(paper.noPublicSimple)) {
            this.$notify({
              message: "“抽取策略”中的“抽取数量”列要为正整数！",
              type: "error",
            });
            return false;
          }
          if (!this.isFloat(paper.noPublicMedium)) {
            this.$notify({
              message: "“抽取策略”中的“抽取数量”列要为正整数！",
              type: "error",
            });
            return false;
          }
          if (!this.isFloat(paper.noPublicDifficulty)) {
            this.$notify({
              message: "“抽取策略”中的“抽取数量”列要为正整数！",
              type: "error",
            });
            return false;
          }
        }
        var numberParams = {};
        (numberParams.publicSimple = paper.publicSimple),
          (numberParams.publicMedium = paper.publicMedium),
          (numberParams.publicDifficulty = paper.publicDifficulty),
          (numberParams.noPublicSimple = paper.noPublicSimple),
          (numberParams.noPublicMedium = paper.noPublicMedium),
          (numberParams.noPublicDifficulty = paper.noPublicDifficulty),
          (numberParams.count = paper.count);
        simpleParams[paper.id] = numberParams;
      }
      this.genPaper.simpleParams = simpleParams;
      console.log("this.genPaper.simpleParams:", this.genPaper.simpleParams);
      return true;
    },
    //查询课程下开启的课程属性
    getCoursePropertyList() {
      var courseNo = this.genPaper.courseNo;
      this.$http
        .get(QUESTION_API + "/courseProperty/enable/" + courseNo)
        .then((response) => {
          this.coursePropertyList = response.data;
          this.searchPaperStructs();
        });
    },
    changePaperType() {
      this.curSelect = 1;
      this.tempPapers = [];
      this.tempPaperIds = [];
      this.searchPaper();
    },
    sum(row) {
      row.count =
        parseFloat(row.publicSimple) +
        parseFloat(row.publicMedium) +
        parseFloat(row.publicDifficulty) +
        parseFloat(row.noPublicSimple) +
        parseFloat(row.noPublicMedium) +
        parseFloat(row.noPublicDifficulty);
    },
    //判断是否为正数
    isNumber(num) {
      var reg = /^\d+(?=\.{0,1}\d+$|$)/;
      if (reg.test(num)) {
        return true;
      }
      return false;
    },
    //判断正小数
    isFloat(num) {
      var reg = /^[0-9]+$/;
      if (reg.test(num)) {
        return true;
      }
      return false;
    },
    //判断输入字符串是否为空或者全部都是空格
    isNull(str) {
      if (str == "") {
        return true;
      }
      var regu = "^[ ]+$";
      var re = new RegExp(regu);
      return re.test(str);
    },
    //鼠标悬浮事件
    showActiveIn(publicity, difficulty, row) {
      //console.log('this.genPaper.simpleGenPaperPolicy',row);
      if (this.genPaper.simpleGenPaperPolicy == "BY_QUESTIONNUM") {
        this.message = row.quesCount[publicity + "-" + difficulty];
      }
      if (this.genPaper.simpleGenPaperPolicy == "BY_SCORE") {
        this.message = row.quesScore[publicity + "-" + difficulty];
      }
    },
    //鼠标离开事件
    showActiveOff() {
      this.message = "";
    },
  },
};
</script>
<style lang="css" scoped>
.tabs {
  margin-left: 20px;
  margin-bottom: 10px;
}

.form-item {
  width: 300px;
}

.margin-bottom {
  margin-bottom: 10px;
}

.label-margin {
  margin-right: 10px;
}
</style>
<style scoped src="../styles/Common.css"></style>
