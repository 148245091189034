import Login from "../views/Login.vue";
import Access from "../views/Access.vue";
import Home from "../views/home/Home.vue";
import HomeMain from "../views/home/main/HomeMain.vue";
import Component404 from "../views/Component404.vue";
import SiteMessageHome from "../views/home/SiteMessageHome.vue";
import SiteMessageDetail from "../views/home/SiteMessageDetail.vue";
export default [
  {
    path: "/",
    name: "Root",
    meta: { pageName: "首页" },
    component: Login,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/access",
    name: "Access",
    component: Access,
  },
  {
    path: "/home",
    component: Home,
    meta: { pageName: "云平台概览" },
    children: [
      {
        path: "overview",
        component: HomeMain,
      },
      {
        path: "site-message",
        name: "SiteMessageHome",
        component: SiteMessageHome,
      },
      {
        path: "site-message/:id",
        name: "SiteMessageDetail",
        component: SiteMessageDetail,
      },
    ],
  },
  {
    // will match everything
    path: "*",
    component: Component404,
  },
  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "./views/About.vue")
  // }
];
