<template>
  <el-container>
    <el-main class="el-main-padding">
      <commonFormVue :form="form" :get-exam-condition="getExamCondition">
        <el-row v-show="showAllCondition">
          <el-col :span="6">
            <el-form-item label="采集人">
              <el-input
                v-model="form.infoCollector"
                class="form_search_width"
                size="small"
                placeholder="采集人"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="虚拟设备">
              <el-select
                v-model="form.hasVirtual"
                class="form_search_width"
                size="small"
                clearable
                placeholder="全部"
              >
                <el-option value="true" label="有"></el-option>
                <el-option value="false" label="无"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="虚拟设备名">
              <el-input
                v-model="form.virtualName"
                class="form_search_width"
                size="small"
                placeholder="虚拟设备名"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="IP">
              <el-input
                v-model="form.ip"
                class="form_search_width"
                size="small"
                placeholder="IP"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-show="showAllCondition">
          <el-col :span="6">
            <el-form-item label="是否违纪">
              <el-select
                v-model="form.isIllegality"
                class="form_search_width"
                size="small"
                clearable
                placeholder="全部"
              >
                <el-option value="true" label="是"></el-option>
                <el-option value="false" label="否"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="审核人">
              <el-input
                v-model="form.auditUserName"
                class="form_search_width"
                size="small"
                placeholder="审核人"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="切屏次数">
              <el-input
                v-model="form.switchScreenCountStart"
                style="width: 160px"
                size="small"
              ></el-input>
              至
              <el-input
                v-model="form.switchScreenCountEnd"
                style="width: 160px"
                size="small"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-show="showAllCondition">
          <el-col :span="12">
            <el-form-item label="开考时间">
              <el-date-picker
                v-model="startExamDatetimeRange"
                class="input"
                type="datetimerange"
                start-placeholder="开始日期"
                range-separator="至"
                end-placeholder="结束日期"
                value-format="yyyy/MM/dd HH:mm:ss"
                :clearable="false"
                size="small"
                @change="changeStartExamDatetimeRange"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </commonFormVue>
      <el-col :span="24">
        <el-button
          size="small"
          type="primary"
          icon="el-icon-search"
          @click="search('clickSelectBtn')"
          >查询</el-button
        >
        <el-button
          v-if="!showAllCondition"
          size="small"
          type="primary"
          icon="el-icon-more"
          @click="showMoreCondition"
          >高级查询</el-button
        >
        <el-button
          v-if="showAllCondition"
          size="small"
          type="primary"
          @click="showSimpleCondition"
          >简单查询</el-button
        >
        <el-button
          size="small"
          icon="el-icon-refresh"
          class="margin-bottom-10"
          @click="resetForm"
          >重置</el-button
        >
      </el-col>
      <el-row>
        <el-col>
          <div class="block-seperator"></div>
          <span>操作：</span>
          <el-button
            v-show="currentPagePrivileges.EXAM_DETAIL_EXPORT"
            type="primary"
            size="small"
            icon="el-icon-download"
            @click="exportData"
            >导出</el-button
          >
          <el-dropdown v-show="currentPagePrivileges.REDO_AUDIT">
            <el-button
              style="margin-left: 10px"
              icon="el-icon-arrow-down"
              type="primary"
              :disabled="noBatchSelected"
              size="small"
              >重审</el-button
            >
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <el-button
                  size="mini"
                  type="success"
                  icon="el-icon-success"
                  @click="redoAuditBatch('pass')"
                  >通&nbsp;&nbsp;过</el-button
                >
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button
                  size="mini"
                  type="danger"
                  icon="el-icon-error"
                  @click="redoAuditBatch('nopass')"
                  >不通过</el-button
                >
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
      </el-row>
      <el-row class="margin-top-10">
        <el-col :span="24">
          <el-table
            ref="multipleTable"
            v-loading="tableLoading"
            element-loading-text="数据加载中"
            :data="tableData"
            border
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="40"></el-table-column>
            <el-table-column label="考试记录ID" width="95">
              <template slot-scope="scope">
                <el-button
                  v-show="
                    (scope.row.examType == 'ONLINE' ||
                      scope.row.examType == 'ONLINE_HOMEWORK') &&
                    currentPagePrivileges.SNAPSHOT_DETAILS
                  "
                  type="text"
                  @click="gotoCaptureDetail(scope.row)"
                  >{{ scope.row.dataId }}</el-button
                >
                <span
                  v-show="
                    (scope.row.examType != 'ONLINE' &&
                      scope.row.examType != 'ONLINE_HOMEWORK') ||
                    !currentPagePrivileges.SNAPSHOT_DETAILS
                  "
                  >{{ scope.row.dataId }}</span
                >
              </template>
            </el-table-column>
            <el-table-column
              label="姓名"
              prop="studentName"
              min-width="100"
            ></el-table-column>
            <el-table-column
              label="身份证号"
              prop="identityNumber"
              min-width="100"
            ></el-table-column>
            <el-table-column
              label="学号"
              prop="studentCode"
              min-width="100"
            ></el-table-column>
            <el-table-column
              label="学习中心"
              prop="orgName"
              min-width="90"
            ></el-table-column>
            <el-table-column
              label="课程"
              prop="courseNameAndCode"
              width="160"
            ></el-table-column>
            <el-table-column
              label="课程层次"
              prop="courseLevel"
              width="80"
            ></el-table-column>
            <el-table-column label="场次" min-width="90">
              <template slot-scope="scope">
                <el-popover trigger="hover" placement="left">
                  <div style="font-size: 18px; font-family: 新宋体">
                    <tr>
                      <td style="color: green">场次序号</td>
                      <td style="color: purple; padding-left: 20px">
                        {{ scope.row.examStageOrder }}
                      </td>
                    </tr>
                    <tr>
                      <td style="color: green">开始时间</td>
                      <td style="color: purple; padding-left: 20px">
                        {{ scope.row.stageStartTime }}
                      </td>
                    </tr>
                    <tr>
                      <td style="color: green">结束时间</td>
                      <td style="color: purple; padding-left: 20px">
                        {{ scope.row.stageEndTime }}
                      </td>
                    </tr>
                  </div>

                  <div slot="reference" class="name-wrapper">
                    <span>{{ scope.row.examStageOrder }}</span>
                  </div>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column
              label="考试次数"
              prop="examOrder"
              width="80"
            ></el-table-column>
            <el-table-column
              label="续考次数"
              prop="continuedCount"
              width="80"
            ></el-table-column>
            <el-table-column
              sortable
              :sort-method="sortByExamTime"
              label="持续时间"
              prop="examTime"
              width="110"
            ></el-table-column>
            <el-table-column
              label="开考时间"
              prop="paperStartTime"
              width="160"
            ></el-table-column>
            <el-table-column
              label="交卷时间"
              prop="paperSubmitTime"
              width="160"
            ></el-table-column>
            <el-table-column
              sortable
              :sort-method="sortByPaperTotalScore"
              label="卷面总分"
              prop="paperTotalScore"
              width="110"
            ></el-table-column>
            <el-table-column
              label="年级"
              prop="grade"
              width="80"
            ></el-table-column>
            <el-table-column
              label="学生电话"
              prop="phone"
              width="80"
            ></el-table-column>
            <el-table-column
              label="采集人"
              prop="infoCollector"
              width="80"
            ></el-table-column>

            <el-table-column
              label="切屏次数"
              prop="switchScreenCount"
              width="80"
            ></el-table-column>
            <el-table-column
              label="人脸比对(%)"
              prop="faceSuccessPercent"
              width="100"
            ></el-table-column>

            <el-table-column
              label="是否交卷"
              prop="isSubmit"
              width="80"
            ></el-table-column>
            <el-table-column
              label="是否违纪"
              prop="isIllegality"
              width="80"
            ></el-table-column>
            <el-table-column
              label="审核人"
              prop="auditUserName"
              width="120"
            ></el-table-column>
            <el-table-column label="IP" prop="ip" width="125">
              <template slot-scope="scope">
                {{ showShortIpsStr(scope.row.ip, ";") }}
              </template>
            </el-table-column>
            <el-table-column
              label="虚拟设备"
              prop="virtualCameraNames"
              width="100"
            ></el-table-column>

            <el-table-column fixed="right" label="详情" width="100">
              <template slot-scope="scope">
                <el-row
                  v-if="
                    (scope.row.examType == 'ONLINE' ||
                      scope.row.examType == 'ONLINE_HOMEWORK') &&
                    currentPagePrivileges.EXAM_QUERY_GETPAPER
                  "
                  class="operateRow"
                >
                  <el-col :span="24">
                    <el-button
                      size="mini"
                      type="primary"
                      icon="el-icon-view"
                      plain
                      @click="
                        examPaperDetail(scope.row.courseId, scope.row.dataId)
                      "
                      >调&nbsp;卷</el-button
                    >
                  </el-col>
                </el-row>
                <el-row
                  v-if="
                    scope.row.examType == 'ONLINE' &&
                    currentPagePrivileges.REDO_AUDIT
                  "
                  class="operateRow"
                >
                  <el-col :span="24">
                    <el-dropdown>
                      <el-button
                        v-show="scope.row.showReAudit"
                        size="mini"
                        icon="el-icon-arrow-down"
                        type="primary"
                        plain
                        >重审</el-button
                      >
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item>
                          <el-button
                            size="mini"
                            type="success"
                            icon="el-icon-success"
                            @click="redoAudit(scope.row.dataId, 'pass')"
                            >通&nbsp;&nbsp;过</el-button
                          >
                        </el-dropdown-item>
                        <el-dropdown-item>
                          <el-button
                            size="mini"
                            type="danger"
                            icon="el-icon-error"
                            @click="redoAudit(scope.row.dataId, 'nopass')"
                            >不通过</el-button
                          >
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
                <el-row
                  v-if="
                    scope.row.examType == 'OFFLINE' &&
                    scope.row.offlineFiles &&
                    currentPagePrivileges.EXAM_QUERY_GETPAPER
                  "
                  class="operateRow"
                >
                  <el-col :span="24">
                    <el-button
                      size="mini"
                      type="primary"
                      icon="el-icon-view"
                      plain
                      @click="downloadOfflineFile(scope.row.offlineFiles)"
                      >调卷</el-button
                    >
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
          </el-table>
          <div class="block pull-right">
            <el-pagination
              :current-page.sync="form.pageNo"
              :page-sizes="[10, 20, 50, 100, 200, 300]"
              :page-size="form.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            ></el-pagination>
          </div>
        </el-col>
      </el-row>
      <el-dialog
        title="重审"
        width="400px"
        :visible.sync="dialogAuditFormVisible"
        @closed="auditDialogClosed"
      >
        <el-form ref="redoAuditForm" :model="auditForm" label-width="90px">
          <el-form-item
            label="违纪类型"
            prop="illegallyTypeId"
            :rules="[
              { required: true, message: '请选择违纪类型', trigger: 'change' },
            ]"
          >
            <el-select
              v-model="auditForm.illegallyTypeId"
              filterable
              remote
              :remote-method="getDisciplineTypeList"
              clearable
              placeholder="请选择"
              size="small"
              @clear="getDisciplineTypeList"
            >
              <el-option
                v-for="item in disciplineTypeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="详情描述" style="margin-top: 15px">
            <el-input
              v-model="auditForm.disciplineDetail"
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 5 }"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <div class="dialog-footer margin-top-10 text-center">
            <el-button type="primary" @click="doRedoAuditNoPass"
              >确 定</el-button
            >
            <el-button @click="dialogAuditFormVisible = false">取 消</el-button>
          </div>
        </el-form>
      </el-dialog>
      <el-dialog title="图片作答" :visible.sync="downloadImageDialogVisible">
        <el-form>
          <el-form-item label="作答结果">
            <div style="width: 580px; padding-left: 80px">
              <el-upload
                :class="{ disabled: true }"
                action
                :limit="6"
                :file-list="imageAnswerFileList"
                multiple
                list-type="picture-card"
                :disabled="true"
              >
                <i slot="default" class="el-icon-plus"></i>
                <div slot="file" slot-scope="{ file }">
                  <img
                    class="el-upload-list__item-thumbnail"
                    :src="file.url"
                    :alt="file.name"
                  />
                  <span class="el-upload-list__item-actions">
                    <span
                      class="el-upload-list__item-preview"
                      @click="handlePictureCardPreview(file)"
                    >
                      <i class="el-icon-zoom-in"></i>
                    </span>
                    <span
                      class="el-upload-list__item-delete"
                      @click="handleDownload(file)"
                    >
                      <i class="el-icon-download"></i>
                    </span>
                  </span>
                </div>
              </el-upload>
            </div>
          </el-form-item>
          <div class="dialog-footer">
            <el-button
              type="primary"
              @click="downloadImageDialogVisible = false"
              >确 定</el-button
            >
          </div>
        </el-form>
      </el-dialog>
      <el-dialog :append-to-body="true" :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt />
      </el-dialog>

      <el-dialog
        title="考试记录详情"
        width="1250px"
        :visible.sync="examRecordDataDialog"
        @close="examRecordDataDialogClose"
      >
        <ExamRecordDetail
          :exam-record-data-id="curSelectedExamRecordDataId"
          :show-audit-button="curSelectedShowAuditButton"
          :need-loading="needLoading"
          @changeExamRecordData="changeExamRecordData"
        ></ExamRecordDetail>
      </el-dialog>
    </el-main>
  </el-container>
</template>
<script>
import { mapState } from "vuex";
import commonFormVue from "../component/commonForm.vue";
import pagePrivilege from "../mixin/pagePrivilege.js";
import { showShortIpsStr } from "@/utils/utils.js";
import _ from "lodash";
export default {
  components: { commonFormVue },
  mixins: [pagePrivilege],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.params?.checkedRows) {
        const unwatch = vm.$watch("tableData", (tableData = []) => {
          tableData.forEach((d) => {
            vm.$refs["multipleTable"]?.toggleRowSelection(
              d,
              from.params.checkedRows.includes(d.dataId)
            );
          });
          if (unwatch) {
            unwatch();
          }
        });
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    to.params.checkedRows = this.routeSelectedIds;
    next();
  },
  data() {
    return {
      showShortIpsStr,
      curSelectedExamRecordDataId: null,
      curSelectedShowAuditButton: false,
      needLoading: false,
      examRecordDataDialog: false,
      toNext: false,
      curTotalPages: 0,
      isOnlineExam: false,
      needShowReAudit: false,
      selectedIds: [],
      routeSelectedIds: [],
      disciplineTypeList: [],
      total: 0,
      tableLoading: false,
      showAllCondition: false,
      startExamDatetimeRange: [],
      downloadImageDialogVisible: false,
      imageAnswerFileList: [],
      dialogImageUrl: "",
      dialogVisible: false,
      // uploadDisabled: true,
      form: {
        virtualName: null,
        ip: null,
        switchScreenCountStart: null,
        switchScreenCountEnd: null,
        rootOrgId: null,
        examRecordDataId: null,
        hasStranger: null,
        courseId: null,
        courseLevel: null,
        examId: null,
        faceSuccessPercentLower: null,
        faceSuccessPercentUpper: null,
        identityNumber: null,
        orgId: null,
        studentCode: null,
        studentName: null,
        isWarn: null,
        pageNo: 1,
        pageSize: 10,
        startTime: null,
        endTime: null,
        infoCollector: null,
        auditUserName: null,
        hasVirtual: null,
        isIllegality: null,
        ORG_FIND_ALL: false, //查询所有机构
        examStageId: null,
        creator: null,
      },
      getExamCondition: {
        params: {
          name: "",
          examTypes: "ONLINE#OFFLINE#ONLINE_HOMEWORK",
        },
        filterCondition: "",
      },
      tableData: [],
      exportUrl: "/api/ecs_oe_admin/exam/record/detail/list/export/async",
      exportFileName: "考试明细",
      currentPagePrivileges: {
        EXAM_DETAIL_EXPORT: false, //导出
        SNAPSHOT_DETAILS: false, //详情查看
        EXAM_QUERY_GETPAPER: false, //调卷
        REDO_AUDIT: false, //重新审核
      },
      dialogAuditFormVisible: false,
      auditForm: {
        examRecordDataIds: [],
        illegallyTypeId: null,
        disciplineDetail: "",
        isPass: null,
      },
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
    noBatchSelected() {
      if (!this.needShowReAudit) {
        return true;
      }
      if (!this.isOnlineExam) {
        return true;
      }
      if (this.selectedIds.length === 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    this.form.rootOrgId = this.user.rootOrgId;
    // this.backFill();
    this.getDisciplineTypeList("");
  },
  methods: {
    checkParam() {
      var reg = /(^0$)|(^[1-9][0-9]*$)/;
      if (this.form.switchScreenCountStart) {
        if (!reg.test(this.form.switchScreenCountStart)) {
          this.$notify({
            title: "警告",
            message: "切屏次数起始值错误",
            type: "warning",
            duration: 2000,
          });
          return false;
        }
      }
      if (this.form.switchScreenCountEnd) {
        if (!reg.test(this.form.switchScreenCountEnd)) {
          this.$notify({
            title: "警告",
            message: "切屏次数截止值错误",
            type: "warning",
            duration: 2000,
          });
          return false;
        }
      }
      return true;
    },
    handleDownload(file) {
      window.open(file.url);
      console.log(file);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    getDisciplineTypeList(name) {
      if (!name) {
        name = "";
      }
      this.$http
        .get("/api/ecs_oe_admin/illegallyType/queryByNameLike", {
          params: { name, queryScope: "audit" },
        })
        .then((response) => {
          this.disciplineTypeList = response.data;
        });
    },
    resetForm() {
      this.form = {
        examRecordDataId: null,
        hasStranger: null,
        courseId: null,
        courseLevel: null,
        examId: null,
        faceSuccessPercentLower: null,
        faceSuccessPercentUpper: null,
        identityNumber: null,
        orgId: this.form.ORG_FIND_ALL ? null : this.form.orgId,
        studentCode: null,
        studentName: null,
        isWarn: null,
        pageNo: 1,
        pageSize: 10,
        rootOrgId: null,
        startTime: null,
        endTime: null,
        infoCollector: null,
        ORG_FIND_ALL: this.form.ORG_FIND_ALL,
        examStageId: null,
      };
      this.startExamDatetimeRange = [];
    },
    showMoreCondition() {
      this.showAllCondition = true;
    },
    showSimpleCondition() {
      this.$notify({
        title: "提示",
        message: "高级查询条件值已重置",
        type: "info",
        duration: 2000,
      });
      this.resetForm();
      this.showAllCondition = false;
    },
    changeStartExamDatetimeRange(e) {
      if (e && e.length > 0) {
        this.form.startTime = e[0];
        this.form.endTime = e[1];
      } else {
        this.form.startTime = "";
        this.form.endTime = "";
      }
    },
    async search(type) {
      if (!this.form.examId) {
        this.$notify({
          title: "警告",
          message: "请选择考试",
          type: "warning",
          duration: 2000,
        });
        return false;
      }
      if (!this.checkParam()) {
        return false;
      }
      if (type && type == "clickSelectBtn") {
        this.form.pageNo = 1;
      }
      this.tableLoading = true;
      this.needShowReAudit = false;

      var params = JSON.parse(JSON.stringify(this.form));
      await this.$http
        .post("/api/ecs_oe_admin/exam/record/detail/list", params)
        .then((response) => {
          if (response.data) {
            this.tableData = response.data.content;
            this.total = response.data.totalElements;
            this.form.pageNo = response.data.number + 1;
            this.curTotalPages = response.data.totalPages;

            this.tableData.forEach((obj) => {
              if (obj.showReAudit) {
                this.needShowReAudit = true;
              }
            });
          } else {
            this.tableData = [];
          }
          this.tableLoading = false;
          // this.$router.push({
          //   path: "/oe/examDetail?" + new URLSearchParams(params),
          // });
        });
    },
    handleSelectionChange(row) {
      this.selectedIds = [];
      row.forEach((element) => {
        if (element.examType == "ONLINE") {
          this.isOnlineExam = true;
        } else {
          this.isOnlineExam = false;
        }
        this.selectedIds.push(element.dataId);
      });
    },
    /**
     * pagesize改变时触发
     */
    handleSizeChange(val) {
      this.form.pageSize = val;
      this.search();
    },
    /**
     * 当前页改变时触发
     */
    handleCurrentChange() {
      this.search();
    },
    examPaperDetail(courseId, examRecordDataId) {
      window.open(
        "/admin/oe/examPaperDetail/" + courseId + "/" + examRecordDataId
      );
    },
    curSelectedRow(examRecordDataId) {
      for (let n = 0; n < this.tableData.length; n++) {
        let row = this.tableData[n];
        if (examRecordDataId === row.dataId) {
          this.$refs["multipleTable"]?.toggleRowSelection(row, true);
        } else {
          this.$refs["multipleTable"]?.toggleRowSelection(row, false);
        }
      }
    },
    examRecordDataDialogClose() {
      this.examRecordDataDialog = false;
    },
    examRecordDataDialogOpen(examRecordDataId, showAuditButton) {
      this.examRecordDataDialog = true;
      this.curSelectedShowAuditButton = showAuditButton;
      this.curSelectedExamRecordDataId = examRecordDataId;
      this.curSelectedRow(examRecordDataId);
    },
    async changeExamRecordData(isNext) {
      // console.log("isNext:" + isNext);
      let curId = null;
      let isFirst = false;
      let isLast = false;
      let rowSize = this.tableData.length;
      for (let n = 0; n < rowSize; n++) {
        isFirst = n === 0;
        isLast = n === rowSize - 1;
        let row = this.tableData[n];
        if (this.curSelectedExamRecordDataId === row.dataId) {
          curId = row.dataId;
          if (isNext) {
            if (!isLast) {
              curId = this.tableData[n + 1].dataId;
            } else {
              if (this.form.pageNo < this.curTotalPages) {
                // 跳到下一页
                curId = null;
                this.form.pageNo = this.form.pageNo + 1;
                console.log("+++>toPage:" + this.form.pageNo);
                this.needLoading = true;
                await this.search();
                this.needLoading = false;

                if (this.tableData.length > 0) {
                  curId = this.tableData[0].dataId;
                }
              } else {
                this.$message({
                  message: "当前数据为最后一条！",
                  type: "warning",
                  showClose: true,
                });
              }
            }
          } else {
            if (!isFirst) {
              curId = this.tableData[n - 1].dataId;
            } else {
              if (this.form.pageNo > 1) {
                // 跳到上一页
                curId = null;
                this.form.pageNo = this.form.pageNo - 1;
                console.log("--->toPage:" + this.form.pageNo);
                this.needLoading = true;
                await this.search();
                this.needLoading = false;

                if (this.tableData.length > 0) {
                  curId = this.tableData[this.tableData.length - 1].dataId;
                }
              } else {
                this.$message({
                  message: "当前数据为第一条！",
                  type: "warning",
                  showClose: true,
                });
              }
            }
          }
          break;
        }
      }

      if (curId) {
        this.curSelectedExamRecordDataId = curId;
        this.curSelectedRow(curId);
      } else {
        this.examRecordDataDialogClose();
      }
    },
    gotoCaptureDetail(row) {
      this.examRecordDataDialogOpen(row.dataId, false);

      // this.routeSelectedIds = [];
      // this.routeSelectedIds.push(row.dataId);
      // this.$refs["multipleTable"]?.toggleRowSelection(row, true);
      // this.$nextTick(() => {
      //   /** checkbox UI选中状态延迟 */
      //   setTimeout(() => {
      //     this.$router.push({
      //       path: "/oe/captureDetail/" + row.dataId + "/examDetail",
      //     });
      //   }, 200);
      // });
    },
    exportData() {
      if (!this.form.examId) {
        this.$notify({
          title: "警告",
          message: "请选择考试",
          type: "warning",
          duration: 1000,
        });
        return false;
      }
      if (!this.checkParam()) {
        return false;
      }
      this.$confirm("确定执行导出?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.form.rootOrgId = this.user.rootOrgId;
        this.form.creator = this.user.userId;
        this.$http
          .get(
            this.exportUrl +
              "?$key=" +
              this.user.key +
              "&$token=" +
              this.user.token,
            {
              params: {
                query: this.form,
              },
            }
          )
          .then(() => {
            this.$notify({
              type: "success",
              message: "正在后台导出中，请稍后到“导出任务列表”中下载！",
            });
          })
          .catch((error) => {
            this.$notify({
              type: "error",
              message: error.response.data.desc,
            });
          });
      });
    },
    downloadOfflineFile(files) {
      if (files && files.length > 0) {
        if (files[0].fileType != "image") {
          window.open(files[0].offlineFileUrl);
          return;
        }

        this.imageAnswerFileList = [];
        for (let f of files) {
          this.imageAnswerFileList.push({
            url: f.offlineFileUrl,
            name: f.offlineFileName,
          });
        }
        this.downloadImageDialogVisible = true;
      }
    },
    redoAudit(examRecordDataId, isPass) {
      this.auditForm.examRecordDataIds = [];
      this.auditForm.examRecordDataIds.push(examRecordDataId);
      if (isPass != "pass") {
        this.dialogAuditFormVisible = true;
      } else {
        //审核通过
        var redoAuditInfo = {
          examRecordDataIds: this.auditForm.examRecordDataIds,
          isPass: true,
          illegallyTypeId: null,
          disciplineDetail: "",
        };
        this.$http
          .post("/api/ecs_oe_admin/exam/audit/redoAudit", redoAuditInfo)
          .then(() => {
            this.$notify({
              title: "成功",
              message: "操作成功",
              type: "success",
            });
            this.search();
          })
          .catch((res) => {
            var errorMsg = "操作失败";
            if (res.response && res.response.data) {
              errorMsg = res.response.data.desc;
            }
            this.$notify({
              title: "提示",
              message: errorMsg,
              type: "error",
            });
          });
      }
    },
    redoAuditBatch(isPass) {
      this.auditForm.examRecordDataIds = this.selectedIds;
      if (isPass != "pass") {
        this.dialogAuditFormVisible = true;
      } else {
        //审核通过
        var redoAuditInfo = {
          examRecordDataIds: this.auditForm.examRecordDataIds,
          isPass: true,
          illegallyTypeId: null,
          disciplineDetail: "",
        };
        this.$http
          .post("/api/ecs_oe_admin/exam/audit/redoAudit", redoAuditInfo)
          .then(() => {
            this.$notify({
              title: "成功",
              message: "操作成功",
              type: "success",
            });
            this.search();
          })
          .catch((res) => {
            var errorMsg = "操作失败";
            if (res.response && res.response.data) {
              errorMsg = res.response.data.desc;
            }
            this.$notify({
              title: "提示",
              message: errorMsg,
              type: "error",
            });
          });
      }
    },
    doRedoAuditNoPass() {
      this.$refs["redoAuditForm"].validate((valid) => {
        if (valid) {
          var redoAuditInfo = {
            examRecordDataIds: this.auditForm.examRecordDataIds,
            isPass: false,
            illegallyTypeId: this.auditForm.illegallyTypeId,
            disciplineDetail: this.auditForm.disciplineDetail,
          };
          this.$http
            .post("/api/ecs_oe_admin/exam/audit/redoAudit", redoAuditInfo)
            .then(() => {
              this.$notify({
                title: "成功",
                message: "操作成功",
                type: "success",
              });
              this.doRedoAuditNoPassPostProcess();
              this.search();
            })
            .catch((res) => {
              var errorMsg = "操作失败";
              if (res.response && res.response.data) {
                errorMsg = res.response.data.desc;
              }
              this.doRedoAuditNoPassPostProcess();
              this.$notify({
                title: "提示",
                message: errorMsg,
                type: "error",
              });
            });
        } else {
          return false;
        }
      });
    },
    doRedoAuditNoPassPostProcess() {
      this.auditForm = {
        examRecordDataId: null,
        illegallyTypeId: null,
        disciplineDetail: "",
        isPass: null,
      };
      this.$refs["redoAuditForm"].resetFields();
      this.dialogAuditFormVisible = false;
    },
    auditDialogClosed() {
      this.$refs["redoAuditForm"].resetFields();
    },
    backFill() {
      var formData = this.$route.query;
      if (formData && formData.examId) {
        for (var attr in formData) {
          var value = formData[attr];
          if (value && value != "null") {
            //是数字 且 长度小于15
            if (!isNaN(value) && value.toString().length < 15) {
              value = _.toNumber(value);
            }
            this.form[attr] = value;
          }
        }
        this.search();
      }
    },
    sortByPaperTotalScore(obj1, obj2) {
      let p1 = Number(obj1.paperTotalScore);
      let p2 = Number(obj2.paperTotalScore);
      return p1 - p2;
    },
    sortByExamTime(obj1, obj2) {
      let p1 = Number(obj1.usedExamTime);
      let p2 = Number(obj2.usedExamTime);
      if (isNaN(p1)) {
        p1 = 0;
      }
      if (isNaN(p2)) {
        p2 = 0;
      }
      return p1 - p2;
    },
    closeDownloadImageDialog() {
      this.downloadImageDialogVisible = false;
    },
  },
};
</script>
<style scope>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: auto !important;
}
.disabled .el-upload--picture-card {
  display: none;
}
</style>
<style scoped src="../style/common.css"></style>
