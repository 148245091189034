<template>
  <div class="bg">
    <!-- <header class="login-header">
          <img v-if="!jwptCustomize" src="../assets/images/login_footer_logo.jpg" />
          <img v-else src="../assets/images/new_login_logo.png" />
          <span class="qm-logo-text" v-if="!jwptCustomize">考试云平台</span>
          <span class="qm-logo-text" v-else>{{ title }}</span>
        </header> -->

    <main class="login-main">
      <!-- <img class="left_tree" src="../assets/images/login_main_left_tree.png" /> -->

      <div class="logo-text">考试云平台</div>
      <div class="right_login">
        <h1 style="font-size: 20px; color: #666">欢迎登录</h1>
        <div class="username">
          <v-icon
            name="user"
            scale="1.4"
            style="padding: 5px 0px; z-index: 3"
          />
          <input
            id="accountValue"
            v-model="loginInfo.accountValue"
            type="text"
            placeholder="请输入账号"
            @keyup.enter="login"
            @change="nameChange"
          />
        </div>
        <div class="password">
          <v-icon
            name="lock"
            scale="1.4"
            style="padding: 5px 0px; z-index: 3"
          />
          <input
            id="password"
            v-model="loginInfo.password"
            :type="seePassword ? 'text' : 'password'"
            placeholder="请输入密码"
            @keyup.enter="login"
          />
          <v-icon
            v-if="!seePassword"
            name="eye-slash"
            scale="1.2"
            class="eye-icon"
            @click="seePassword = !seePassword"
          ></v-icon>
          <v-icon
            v-else
            name="eye"
            scale="1.2"
            class="eye-icon"
            @click="seePassword = !seePassword"
          ></v-icon>
        </div>
        <div class="smscode">
          <v-icon
            v-if="dialogVisible"
            name="lock"
            scale="1.4"
            style="padding: 5px 0px; z-index: 3"
          />
          <input
            v-if="dialogVisible"
            id="smsCode"
            v-model="loginInfo.smsCode"
            type="text"
            placeholder="请输入短信验证码"
            @keyup.enter="login"
          />
        </div>
        <button class="login-btn" @click="login">登 录</button>
      </div>
    </main>
    <footer class="login-footer">
      启明泰和 Copyright © www.qmth.com.cn, All Rights Reserved.
      鄂ICP备12000033号-7
    </footer>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { USER_SIGNIN } from "../store/user";
import { CORE_API } from "@/constants/constants";

export default {
  data() {
    return {
      errorInfo: "",
      title: "考试云平台",
      jwptCustomize: false,
      dialogVisible: false,
      loginInfo: {
        rootOrgId: "",
        domain: "",
        accountType: "COMMON_LOGIN_NAME",
        accountValue: "",
        password: "",
        smsCode: null,
      },
      seePassword: false,
    };
  },
  watch: {
    $route(to) {
      this.loginInfo.rootOrgId = to.query.orgId;
    },
  },
  created() {
    if (this.$route.hash && this.$route.hash.startsWith("#/access?")) {
      this.$router.push(this.$route.hash.slice(1));
      return;
    }
    this.loginInfo.domain =
      window.location.hostname.split(".")[0] + ".ecs.qmth.com.cn";
    sessionStorage.clear();
    var params = this.$route.query;

    this.loginInfo.rootOrgId = params.orgId;

    this.jwptCustomizeMethod();
  },
  methods: {
    ...mapActions([USER_SIGNIN]),
    nameChange() {
      // this.dialogVisible = false;
    },
    checkAccountValue() {
      this.errorInfo = "";
      if (!this.loginInfo.accountValue) {
        this.errorInfo += "账号不能为空!\n";
      }
      if (this.errorInfo) {
        this.$notify({
          showClose: true,
          message: this.errorInfo,
          type: "error",
        });
        return false;
      }
      return true;
    },

    checkPassword() {
      this.errorInfo = "";
      if (!this.loginInfo.password) {
        this.errorInfo += "密码不能为空!\n";
      }
      if (this.errorInfo) {
        this.$notify({
          showClose: true,
          message: this.errorInfo,
          type: "error",
        });
        return false;
      }
      return true;
    },

    login() {
      if (!this.checkAccountValue()) {
        return;
      }
      if (!this.checkPassword()) {
        return;
      }
      var url = CORE_API + "/auth/login";
      this.$httpWithMsg
        .post(url, this.loginInfo)
        .then((response) => {
          var user = response.data;
          this.USER_SIGNIN(user);
          this.$router.replace({ path: "/home/overview" });
          this.$notify({
            message: "登录成功",
            type: "success",
            duration: 1000,
          });
        })
        .catch((error) => {
          if (
            error.response.data.code === "B-003100" ||
            error.response.data.code === "B-EX-102008"
          ) {
            this.dialogVisible = true;
          }
        });
    },
    loginWithSms() {
      this.dialogVisible = false;
      this.login();
    },
    jwptCustomizeMethod() {
      // 普通高校考试综合管理平台 定制
      if (location.host === "jwpt.ecs.qmth.com.cn") {
        document.title = "普通高校-题库";
        this.title = " | 普通高校-题库";
        this.jwptCustomize = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bg {
  width: 100vw;
  height: 100vh;
  background-image: url("../assets/images/bg.jpg");
  background-size: cover;
}

.qm-logo-text {
  font-size: 36px;
  font-weight: 700;
  font-stretch: normal;
  line-height: 36px;
  letter-spacing: 0px;
  margin-left: 40px;
}

.logo-text {
  /* font-family: "Xingkai SC", "STXingkai", "KaiTi"; */
  width: 100%;
  height: 40px;
  font-size: 40px;
  margin-bottom: 30px;
  font-weight: bold;
  font-stretch: normal;
  line-height: 48px;
  letter-spacing: 0px;
  color: #3968d7;
  text-shadow: 0px 7px 4px rgba(77, 124, 196, 0.3);
  text-align: center;
  letter-spacing: 0.2em;
}

.login-main {
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.right_login {
  width: 480px;
  height: 330px;
  background-color: #ffffff;
  box-shadow: 0px 7px 20px 0px rgba(77, 124, 196, 0.1);
  border-radius: 38px;
  opacity: 0.8;
  padding: 0 80px;
}

.right_login h1 {
  text-align: center;
  height: 20px;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: 0px;
  color: #666666;
  margin: 30px 0;
}

.right_login .username {
  display: flex;
  align-items: center;
  justify-items: center;
}
.right_login .password {
  display: flex;
  align-items: center;
  justify-items: center;
  margin-top: 10px;
  position: relative;
  .eye-icon {
    position: absolute;
    right: 10px;
    cursor: pointer;
  }
}
.right_login .smscode {
  height: 38px;
  display: flex;
  align-items: center;
  justify-items: center;
  margin-top: 10px;
}

.right_login input {
  width: 100%;
  padding: 5px 30px;
  margin-left: -21px;
  font-size: 16px;
  border-radius: 10px;
}

.right_login input::placeholder {
  font-size: 16px;
  color: #000000;
  opacity: 0.3;
}

.login-btn {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  background-color: #4d7cc4;
  font-size: 25px;
  color: #fff;
  cursor: pointer;
  border-radius: 27px;
}

.login-btn:hover {
  box-shadow: 0 16px 29px rgba(29, 170, 240, 0.3);
}

.login-footer {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  font-size: 14px;
  color: #333;
  text-align: center;
}
</style>
