<template>
  <section class="content">
    <div class="box box-info">
      <!-- 正文信息 -->
      <div class="box-body">
        <el-form
          ref="formSearch"
          :model="formSearch"
          :inline="true"
          label-width="70px"
        >
          <el-form-item v-if="isSuperAdmin" label="学校">
            <el-select
              v-model="formSearch.rootOrgId"
              placeholder="请选择"
              class="input"
              @change="rootOrgChanged"
            >
              <el-option
                v-for="item in rootOrgList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="姓名">
            <el-input
              v-model="formSearch.name"
              placeholder="请输入姓名"
              class="input"
            ></el-input>
          </el-form-item>
          <el-form-item label="学号">
            <el-input
              v-model="formSearch.studentCode"
              placeholder="请输入学号"
              class="input"
            ></el-input>
          </el-form-item>
          <el-form-item label="身份证">
            <el-input
              v-model="formSearch.identityNumber"
              placeholder="请输入身份证"
              class="input"
            ></el-input>
          </el-form-item>
          <el-form-item label="学习中心">
            <el-select
              v-model="formSearch.orgId"
              class="input"
              :remote-method="getOrgList4Search"
              :loading="getOrgList4SearchLoading"
              remote
              filterable
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in orgList4Search"
                :key="item.id"
                :label="item.name + ' - ' + item.code"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="照片状态">
            <el-select v-model="formSearch.hasPhoto" class="input">
              <el-option label="未选择" value="UNDEFINED"></el-option>
              <el-option label="已上传" value="TRUE"></el-option>
              <el-option label="未上传" value="FALSE"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="状态">
            <el-select v-model="formSearch.enable" class="input">
              <el-option label="未选择" value="UNDEFINED"></el-option>
              <el-option label="已启用" value="TRUE"></el-option>
              <el-option label="已禁用" value="FALSE"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item class="d-block">
            <el-button
              v-if="rolePrivileges.search_student"
              size="small"
              type="primary"
              icon="el-icon-search"
              @click="resetPageAndSearchForm"
              >查询</el-button
            >
            <el-button
              size="small"
              icon="el-icon-refresh"
              @click="resetSearchForm"
              >重置</el-button
            >
            <el-button
              v-if="rolePrivileges.upload_student_photo"
              size="small"
              type="primary"
              icon="el-icon-upload2"
              @click="uploadPhoto"
              >上传照片</el-button
            >
            <el-button
              size="small"
              type="primary"
              icon="el-icon-download"
              @click="exportStudent"
              >导出</el-button
            >
          </el-form-item>
        </el-form>

        <div class="block-seperator"></div>
        <span>批量操作：</span>

        <el-button
          v-if="rolePrivileges.reset_student_password"
          size="small"
          type="danger"
          icon="el-icon-refresh"
          :disabled="noBatchSelected"
          @click="resetPassword('')"
          >重置密码</el-button
        >
        <el-button
          v-if="rolePrivileges.change_student_availability"
          size="small"
          type="success"
          icon="el-icon-check"
          :disabled="noBatchSelected"
          @click="enableStudent('')"
          >启用</el-button
        >
        <el-button
          v-if="rolePrivileges.change_student_availability"
          size="small"
          type="danger"
          icon="el-icon-close"
          :disabled="noBatchSelected"
          @click="disableStudent('')"
          >禁用</el-button
        >
        <el-button
          v-if="rolePrivileges.reset_student_password"
          size="small"
          type="danger"
          icon="el-icon-refresh"
          @click="resetPasswordByOrgId()"
          >重置学习中心所有学生密码</el-button
        >

        <div style="width: 100%; margin-bottom: 10px"></div>

        <!-- 重置学习中心所有学生密码-->
        <el-dialog
          title="重置学习中心所有学生密码"
          width="500px"
          :visible.sync="resetPasswordByOrgIdDialog"
        >
          <el-form
            ref="resetPasswordByOrgIdForm"
            :model="resetPasswordByOrgIdForm"
            :inline="true"
            label-width="80px"
            :rules="resetPasswordByOrgIdRules"
          >
            <el-form-item label="学习中心" prop="orgId">
              <el-select
                v-model="resetPasswordByOrgIdForm.orgId"
                class="input"
                :remote-method="getOrgList4RestPassword"
                :loading="getOrgList4RestPasswordLoading"
                remote
                filterable
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in orgList4RestPassword"
                  :key="item.id"
                  :label="item.name + ' - ' + item.code"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div style="text-align: center; margin-top: 20px">
            <el-button type="primary" @click="submitResetPasswordByOrgId"
              >确 定</el-button
            >
            <el-button @click="resetPasswordByOrgIdDialog = false"
              >取 消</el-button
            >
          </div>
        </el-dialog>

        <!-- 页面列表 -->
        <el-table
          :data="tableData"
          border
          style="width: 100%; text-align: center"
          @selection-change="selectChange"
        >
          <el-table-column type="selection" width="50"></el-table-column>
          <el-table-column
            prop="id"
            width="100"
            label="ID"
            sortable
          ></el-table-column>
          <el-table-column label="考生" width="100">
            <template slot-scope="scope">
              <el-popover trigger="hover" placement="left">
                <div style="font-size: 18px; font-family: 新宋体">
                  <tr>
                    <td style="color: green">姓名</td>
                    <td style="color: purple; padding-left: 20px">
                      {{ scope.row.name }}
                    </td>
                  </tr>
                  <tr>
                    <td style="color: green">身份证号</td>
                    <td style="color: purple; padding-left: 20px">
                      {{ scope.row.privateIdentityNumber }}
                    </td>
                  </tr>
                  <tr>
                    <td style="color: green">学号</td>
                    <td style="color: purple; padding-left: 20px">
                      {{ scope.row.studentCodeList }}
                    </td>
                  </tr>
                  <tr>
                    <td style="color: green">学习中心名称</td>
                    <td style="color: purple; padding-left: 20px">
                      {{ scope.row.orgName }}
                    </td>
                  </tr>
                  <tr>
                    <td style="color: green">学习中心编码</td>
                    <td style="color: purple; padding-left: 20px">
                      {{ scope.row.orgCode }}
                    </td>
                  </tr>
                  <tr>
                    <td style="color: green">手机号</td>
                    <td style="color: purple; padding-left: 20px">
                      {{ scope.row.phoneNumber }}
                    </td>
                  </tr>
                  <tr>
                    <td style="color: green">安全手机号</td>
                    <td style="color: purple; padding-left: 20px">
                      {{ scope.row.securityPhone }}
                    </td>
                  </tr>
                  <tr>
                    <td style="color: green">创建时间</td>
                    <td style="color: purple; padding-left: 20px">
                      {{ scope.row.creationTime }}
                    </td>
                  </tr>
                </div>

                <div slot="reference" class="name-wrapper">
                  <span>{{ scope.row.name }}</span>
                </div>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="学号">
            <span slot-scope="scope" v-html="scope.row.studentCodesStr"></span>
          </el-table-column>
          <el-table-column
            prop="privateIdentityNumber"
            width
            label="身份证"
            sortable
          ></el-table-column>
          <el-table-column
            prop="orgName"
            width
            label="学习中心"
            sortable
          ></el-table-column>
          <el-table-column width="168" label="更新时间" sortable>
            <template slot-scope="scope">
              <el-button type="text" @click="gotoOperateLog(scope.row.id)">{{
                scope.row.updateTime
              }}</el-button>
            </template>
          </el-table-column>
          <el-table-column width="50" label="状态">
            <span slot-scope="scope">
              <span v-if="scope.row.enable">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="启用"
                  placement="left"
                >
                  <i class="el-icon-success" style="color: green"></i>
                </el-tooltip>
              </span>
              <span v-else>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="禁用"
                  placement="left"
                >
                  <i class="el-icon-error" style="color: red"></i>
                </el-tooltip>
              </span>
            </span>
          </el-table-column>
          <el-table-column label="操作" width="300">
            <div slot-scope="scope">
              <el-button
                :disabled="!scope.row.photoPath"
                size="mini"
                type="primary"
                plain
                icon="el-icon-picture"
                @click="showPhoto(scope.row)"
                >查看照片</el-button
              >
              <el-button
                v-if="
                  null != scope.row.enable &&
                  !scope.row.enable &&
                  rolePrivileges.change_student_availability
                "
                size="mini"
                type="primary"
                plain
                icon="el-icon-check"
                @click="enableStudent(scope.row)"
                >启用</el-button
              >
              <el-button
                v-else-if="rolePrivileges.change_student_availability"
                size="mini"
                type="danger"
                icon="el-icon-close"
                @click="disableStudent(scope.row)"
                >禁用</el-button
              >

              <el-dropdown style="margin-left: 10px">
                <el-button type="primary" plain size="mini">
                  更多
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-if="rolePrivileges.reset_student_password"
                  >
                    <el-button
                      size="mini"
                      type="danger"
                      icon="el-icon-refresh"
                      @click="resetPassword(scope.row)"
                      >重置密码</el-button
                    >
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-button
                      size="mini"
                      type="primary"
                      icon="el-icon-document"
                      @click="showStuExamDialog(scope.row)"
                      >考试记录</el-button
                    >
                  </el-dropdown-item>
                  <el-dropdown-item v-if="rolePrivileges.unbind_student_code">
                    <el-button
                      size="mini"
                      type="danger"
                      icon="el-icon-refresh"
                      @click="showUnbindStudentCode(scope.row)"
                      >解绑学号</el-button
                    >
                  </el-dropdown-item>
                  <el-dropdown-item v-if="rolePrivileges.unbind_security_phone">
                    <el-button
                      size="mini"
                      type="danger"
                      icon="el-icon-refresh"
                      @click="unbindSecurityPhone(scope.row)"
                      >解绑安全手机</el-button
                    >
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </el-table-column>
        </el-table>
        <div class="page pull-right">
          <el-pagination
            :current-page="currentPage"
            :page-size="pageSize"
            :page-sizes="[10, 20, 50, 100, 200, 300]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          ></el-pagination>
        </div>

        <!-- 解绑学号-->
        <el-dialog
          title="解绑学号"
          width="50%"
          :visible.sync="unbindStudentCodeDialog"
        >
          <el-table
            :data="unbindStudentCodeData.tableData"
            border
            style="width: 100%; text-align: center"
          >
            <el-table-column prop="name" label="姓名" />
            <el-table-column prop="identityNumber" label="身份证" />
            <el-table-column prop="studentCode" label="学号" />
            <el-table-column label="操作" width="100">
              <div slot-scope="scope">
                <el-button
                  size="mini"
                  type="danger"
                  icon="el-icon-delete"
                  @click="unbindStudentCode(scope.row)"
                  >解绑</el-button
                >
              </div>
            </el-table-column>
          </el-table>
        </el-dialog>

        <!-- 考试记录 -->
        <el-dialog
          v-loading="stuExamLoading"
          title="学生考试记录"
          width="60%"
          :visible.sync="stuExamDialog"
          element-loading-text="拼命加载中"
        >
          <el-form
            :inline="true"
            :model="stuExamSearch"
            label-position="right"
            label-width="50px"
          >
            <el-form-item label="考试" class="pull-left">
              <el-select
                v-model="stuExamSearch.examId"
                class="input"
                :remote-method="queryExams4Search"
                remote
                :loading="queryExams4SearchLoading"
                filterable
                clearable
                placeholder="请选择"
                @change="handleExamChange4Search"
              >
                <el-option
                  v-for="item in examList4Search"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="场次" class="pull-left">
              <el-select
                v-model="stuExamSearch.examStageId"
                clearable
                :disabled="examStageDisabled4Search"
                class="input"
                :remote-method="queryExamStages4Search"
                remote
                :loading="queryExamStages4SearchLoading"
                :filterable="true"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in examStageList4Search"
                  :key="item.id"
                  :label="item.stageOrder"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="pull-left">
              <el-button
                size="small"
                type="primary"
                icon="el-icon-search"
                @click="searchExamRecord"
                >查询</el-button
              >
            </el-form-item>
          </el-form>

          <el-table
            :data="stuExamList"
            border
            style="width: 100%; text-align: center"
          >
            <el-table-column
              prop="studentName"
              label="学生姓名"
            ></el-table-column>
            <el-table-column prop="studentCode" label="学号"></el-table-column>
            <el-table-column prop="ext2" label="身份证"></el-table-column>
            <el-table-column
              prop="examName"
              label="考试"
              sortable
            ></el-table-column>
            <el-table-column
              v-if="!examStageDisabled4Search"
              prop="examStageOrder"
              label="场次"
              width="120"
              sortable
            ></el-table-column>
            <el-table-column
              prop="courseName"
              label="课程"
              sortable
            ></el-table-column>
            <el-table-column width="130" label="考试状态">
              <div slot-scope="scope">
                <span v-if="null == scope.row.started"></span>
                <span v-else-if="scope.row.started">已考</span>
                <span v-else>未考</span>
              </div>
            </el-table-column>
          </el-table>
          <div class="page pull-right">
            <el-pagination
              :current-page="stuExamCurPage"
              :page-size="stuExamPageSize"
              :page-sizes="[10, 20, 50, 100, 200, 300]"
              layout="total, sizes, prev, pager, next, jumper"
              :total="stuExamTotal"
              @current-change="stuExamCurChange"
              @size-change="handleStuExamSizeChange"
            ></el-pagination>
          </div>
          <div style="margin-top: 10px"></div>
        </el-dialog>

        <!-- 导入照片弹窗 -->
        <el-dialog
          title="学生照片导入"
          width="350px"
          :visible.sync="photoUploadDialog"
        >
          <el-form>
            <el-row>
              <el-form-item style="margin-left: 30px">
                <el-upload
                  ref="upload"
                  class="form_left"
                  list-type="picture"
                  :action="uploadAction"
                  :headers="uploadHeaders"
                  :data="uploadData"
                  :before-upload="beforeUpload"
                  :on-progress="uploadProgress"
                  :on-success="uploadSuccess"
                  :on-error="uploadError"
                  :file-list="fileList"
                  :auto-upload="false"
                  :multiple="false"
                >
                  <el-button
                    slot="trigger"
                    size="small"
                    type="primary"
                    icon="el-icon-search"
                    >选择文件</el-button
                  >
                  <el-button
                    size="small"
                    style="margin-left: 10px"
                    type="primary"
                    icon="el-icon-check"
                    @click="submitUpload"
                    >确认上传</el-button
                  >
                  <div slot="tip" class="el-upload__tip">
                    只能上传jpg,png文件
                  </div>
                </el-upload>
              </el-form-item>
            </el-row>
          </el-form>
        </el-dialog>

        <!--查看照片-->
        <el-dialog
          title="照片"
          :visible.sync="photoDialog"
          width="300px"
          :center="true"
          @close="closePhotoDialog"
        >
          <img :src="photo.url" height="100%" width="100%" />
        </el-dialog>

        <el-dialog
          v-loading="studentLog.loading"
          title="学生日志"
          width="60%"
          :visible.sync="stuLogDialog"
          :close-on-click-modal="false"
          element-loading-text="拼命加载中"
        >
          <!-- 表单 -->
          <el-form inline :model="studentLog.formSearch">
            <el-form-item label="操作内容">
              <el-input
                v-model="studentLog.formSearch.operate"
                placeholder="请输入操作内容"
                style="width: 180px"
              />
            </el-form-item>
            <el-form-item label="操作时间">
              <el-date-picker
                v-model="studentLog.timeRange"
                class="input"
                type="datetimerange"
                start-placeholder="开始日期"
                range-separator="至"
                end-placeholder="结束日期"
                value-format="yyyy/MM/dd HH:mm:ss"
                :clearable="false"
                size="small"
                @change="changeTimeRange"
              ></el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button
                size="small"
                type="primary"
                icon="el-icon-search"
                @click="loghandleSearchBtn"
                >查询</el-button
              >
            </el-form-item>
          </el-form>

          <div class="block-seperator"></div>
          <!-- 页面列表 -->
          <el-table
            :data="studentLog.tableData"
            border
            resizable
            stripe
            style="width: 100%"
          >
            <el-table-column
              width="200"
              prop="operateTime"
              label="操作时间"
            ></el-table-column>
            <el-table-column
              width="200"
              prop="operateIp"
              label="IP"
            ></el-table-column>
            <el-table-column
              width="200"
              prop="operateClient"
              label="操作端"
            ></el-table-column>
            <el-table-column prop="operate" label="操作内容"></el-table-column>
          </el-table>
          <div class="page pull-right">
            <el-pagination
              v-if="studentLog.paginationShow"
              :current-page="studentLog.currentPage"
              :page-size="studentLog.pageSize"
              :page-sizes="[10, 20, 50, 100, 200, 300]"
              layout="total, sizes, prev, pager, next, jumper"
              :total="studentLog.total"
              @current-change="loghandleCurrentChange"
              @size-change="loghandleSizeChange"
            />
          </div>
        </el-dialog>
      </div>
    </div>
  </section>
</template>

<script>
import {
  CORE_API,
  EXAM_WORK_API,
  EXCHANGE_API,
  REPORTS_API,
} from "@/constants/constants.js";
import { mapState } from "vuex";

export default {
  data() {
    return {
      rolePrivileges: {
        search_student: false,
        upload_student_photo: false,
        reset_student_password: false,
        change_student_availability: false,
        unbind_student_code: false,
        unbind_security_phone: false,
      },
      rootOrgList: null,
      stuExamLoading: false,
      button: {},
      pureLC: false,
      lc_id: null,
      lc_code: null,
      lc_name: null,
      orgList4Search: [],
      orgList4RestPassword: [],
      getOrgList4SearchLoading: false,
      getOrgList4RestPasswordLoading: false,
      resetPasswordByOrgIdDialog: false,
      resetPasswordByOrgIdForm: {
        orgId: "",
      },
      formSearch: {
        orgId: "",
        rootOrgId: null,
        name: "",
        studentCode: "",
        identityNumber: "",
        enable: "UNDEFINED",
        hasPhoto: "UNDEFINED",
      },
      selectedStuIds: [],
      statusList: [
        {
          value: true,
          label: "启用",
        },
        {
          value: false,
          label: "禁用",
        },
      ],

      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 10,
      photoDialog: false,
      stuLogDialog: false,
      photo: { url: "" },

      unbindStudentCodeDialog: false,
      unbindStudentCodeData: {
        tableData: [],
      },

      errMessages: [],
      uploadAction: EXCHANGE_API + "/facepp/add",
      uploadData: {},
      fileLoading: false,
      fileList: [],
      uploadHeaders: {},
      photoUploadDialog: false,

      stuExamCurPage: 1,
      stuExamPageSize: 10,
      stuExamTotal: 10,
      stuExamDialog: false,
      stuExamList: [],
      examList4Search: [],
      queryExams4SearchLoading: false,
      stuExamSearch: {
        rootOrgId: "",
        examId: "",
        examStageId: "",
        studentCode: "",
        studentName: "",
        orgId: "",
        specialtyName: "",
        courseCode: "",
        courseName: "",
        infoCollector: "",
        identityNumber: "",
        identityNumberLike: false,
      },
      resetPasswordByOrgIdRules: {
        orgId: [
          {
            type: "number",
            required: true,
            message: "请选择学习中心",
            trigger: "change",
          },
        ],
      },
      examStageDisabled4Search: true,
      queryExamStages4SearchLoading: false,
      examStageList4Search: [],
      studentLog: {
        loading: false,
        timeRange: [],
        paginationShow: false,
        formSearch: {
          operate: null,
          studentId: "",
          startTime: null,
          endTime: null,
        },
        tableData: [],
        currentPage: 1,
        pageSize: 10,
        total: 10,
      },
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
    stuIds() {
      var stuIds = "";
      for (let stuId of this.selectedStuIds) {
        if (!stuIds) {
          stuIds += stuId;
        } else {
          stuIds += "," + stuId;
        }
      }
      return stuIds;
    },
    noBatchSelected() {
      return this.selectedStuIds.length === 0;
    },
    isSuperAdmin() {
      return this.user.roleList.some((role) => role.roleCode == "SUPER_ADMIN");
    },
  },
  created() {
    this.init();
    this.uploadHeaders = {
      key: this.user.key,
      token: this.user.token,
    };
  },
  methods: {
    gotoOperateLog(studentId) {
      this.studentLog.formSearch.operate = null;
      this.studentLog.timeRange = [];
      this.studentLog.formSearch.startTime = null;
      this.studentLog.formSearch.endTime = null;
      this.studentLog.formSearch.studentId = studentId;
      this.studentLog.tableData = [];
      this.studentLog.total = 0;
      this.studentLog.currentPage = 1;
      this.stuLogDialog = true;
      this.logsearchForm();
    },
    changeTimeRange(e) {
      if (e && e.length > 0) {
        this.studentLog.formSearch.startTime = e[0];
        this.studentLog.formSearch.endTime = e[1];
      } else {
        this.studentLog.formSearch.startTime = "";
        this.studentLog.formSearch.endTime = "";
      }
    },
    loghandleSearchBtn() {
      this.studentLog.currentPage = 1;
      this.logsearchForm();
    },
    loghandleSizeChange(val) {
      this.studentLog.pageSize = val;
      this.studentLog.currentPage = 1;
      this.logsearchForm();
    },
    loghandleCurrentChange(val) {
      this.studentLog.currentPage = val;
      this.logsearchForm();
    },
    //查询
    logsearchForm() {
      this.studentLog.loading = true;
      var url =
        REPORTS_API +
        "/studentOperate/page/" +
        this.studentLog.currentPage +
        "/" +
        this.studentLog.pageSize;
      this.$httpWithMsg
        .get(url, { params: this.studentLog.formSearch })
        .then((response) => {
          this.studentLog.tableData = response.data.list;
          this.studentLog.total = response.data.total;
          this.studentLog.loading = false;

          this.$nextTick(function () {
            this.studentLog.paginationShow = true;
          });
        })
        .finally(() => (this.studentLog.loading = false));
    },
    resetPasswordByOrgId() {
      this.orgList4RestPassword = [];
      this.resetPasswordByOrgIdForm.orgId = null;

      this.getOrgList4RestPassword("");
      if (this.pureLC) {
        // this.orgList4RestPassword = [
        //   { id: this.lc_id, name: this.lc_name, code: this.lc_code },
        // ];
        // this.resetPasswordByOrgIdForm.orgId = this.lc_id;
      }

      this.resetPasswordByOrgIdDialog = true;
    },
    submitResetPasswordByOrgId() {
      this.$refs.resetPasswordByOrgIdForm.validate((valid) => {
        if (valid) {
          var url =
            CORE_API +
            "/student/resetPasswordByOrgId/" +
            this.resetPasswordByOrgIdForm.orgId;
          this.$httpWithMsg.put(url).then((response) => {
            console.log(response);
            this.$notify({
              type: "success",
              message: "重置密码成功",
            });
            this.searchForm();
            this.resetPasswordByOrgIdDialog = false;
          });
        } else {
          return false;
        }
      });
    },
    rootOrgChanged() {
      this.getOrgList4Search("");
    },
    getOrgList4Search(orgName) {
      this.getOrgList4SearchLoading = true;
      let url =
        CORE_API +
        "/org/query?rootOrgId=" +
        this.formSearch.rootOrgId +
        "&name=" +
        orgName;
      this.$httpWithMsg
        .get(url)
        .then((response) => {
          this.getOrgList4SearchLoading = false;
          this.orgList4Search = response.data;
        })
        .catch((response) => {
          console.log(response);
          this.getOrgList4SearchLoading = false;
        });
    },
    getOrgList4RestPassword(orgName) {
      this.getOrgList4RestPasswordLoading = true;
      let url =
        CORE_API +
        "/org/query?rootOrgId=" +
        this.formSearch.rootOrgId +
        "&name=" +
        orgName;
      this.$httpWithMsg
        .get(url)
        .then((response) => {
          this.getOrgList4RestPasswordLoading = false;
          this.orgList4RestPassword = response.data;
        })
        .catch((response) => {
          console.log(response);
          this.getOrgList4RestPasswordLoading = false;
        });
    },
    queryExams4Search(name) {
      this.queryExams(name, "search");
    },
    queryExams(name, where) {
      console.log("queryExams; name: " + name);
      if ("search" == where) {
        this.queryExams4SearchLoading = true;
      }
      this.$httpWithMsg
        .get(EXAM_WORK_API + "/exam/queryByNameLike?enable=true&name=" + name)
        .then((response) => {
          if ("search" == where) {
            this.queryExams4SearchLoading = false;
            this.examList4Search = response.data;
          }
        })
        .catch(() => {
          if ("search" == where) {
            this.queryExams4SearchLoading = false;
          }
        });
    },
    showStuExamDialog(row) {
      this.stuExamSearch.identityNumber = row.identityNumber;
      this.stuExamSearch.rootOrgId = row.rootOrgId;
      this.stuExamList = [];
      this.stuExamTotal = 0;
      this.queryExams4Search("");
      this.stuExamCurPage = 1;
      this.searchStuExam(this.stuExamCurPage);
      this.stuExamDialog = true;
    },
    searchExamRecord() {
      this.stuExamCurPage = 1;
      this.searchStuExam(this.stuExamCurPage);
    },
    searchStuExam(curPage) {
      this.stuExamSearch.skipRule = true;
      var param = new URLSearchParams(this.stuExamSearch);
      this.stuExamLoading = true;
      var url =
        EXAM_WORK_API +
        "/exam_student/examStudentPage/" +
        (curPage - 1) +
        "/" +
        this.stuExamPageSize +
        "?" +
        param;
      this.$httpWithMsg
        .get(url)
        .then((response) => {
          this.stuExamList = response.data.list;
          this.stuExamTotal = response.data.total;
          this.stuExamLoading = false;
        })
        .catch(function (response) {
          console.log(response);
          this.stuExamLoading = false;
        });
    },
    stuExamCurChange(val) {
      this.stuExamCurPage = val;
      this.searchStuExam(val);
    },
    handleStuExamSizeChange(val) {
      this.stuExamPageSize = val;
      this.searchStuExam(val);
    },
    closePhotoDialog() {
      this.photo.url = "/img/blank.png";
      this.photoDialog = false;
    },
    showPhoto(row) {
      if (row.photoPath) {
        this.photo.url = row.photoPath;
        this.photoDialog = true;
      } else {
        this.$notify({
          showClose: true,
          message: "未上传照片",
          type: "error",
        });
      }
    },
    uploadPhoto() {
      this.photoUploadDialog = true;
      this.initUpload();
    },
    selectChange(row) {
      this.selectedStuIds = [];
      row.forEach((element, index) => {
        console.log(index);
        this.selectedStuIds.push(element.id);
      });
      console.log(this.selectedStuIds);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.searchForm();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.searchForm();
    },
    resetSearchForm() {
      this.formSearch.name = "";
      this.formSearch.studentCode = "";
      this.formSearch.identityNumber = "";
      this.formSearch.enable = "UNDEFINED";
      this.formSearch.hasPhoto = "UNDEFINED";
      if (!this.pureLC) {
        this.formSearch.orgId = "";
      }
    },
    resetPageAndSearchForm() {
      this.currentPage = 1;
      this.searchForm();
    },
    //查询方法
    searchForm() {
      var param = new URLSearchParams(this.formSearch);
      var url =
        CORE_API +
        "/student/studentPage/" +
        (this.currentPage - 1) +
        "/" +
        this.pageSize +
        "?" +
        param;
      this.$httpWithMsg.get(url).then((response) => {
        this.tableData = response.data.list;
        this.total = response.data.total;
      });
    },
    exportStudent() {
      var param = new URLSearchParams(this.formSearch);
      window.open(
        CORE_API +
          "/student/export?$key=" +
          this.user.key +
          "&$token=" +
          this.user.token +
          "&" +
          param
      );
    },
    checkIds(row) {
      if (row) {
        return row.id;
      } else {
        if (this.stuIds.length == 0) {
          this.$notify({
            type: "warning",
            message: "请选择学生",
          });
          return "";
        } else {
          return this.stuIds;
        }
      }
    },
    showUnbindStudentCode(row) {
      this.unbindStudentCodeData.tableData = [];
      for (let cur of row.studentCodeList) {
        this.unbindStudentCodeData.tableData.push({
          studentCode: cur,
          rootOrgId: row.rootOrgId,
          name: row.name,
          identityNumber: row.identityNumber,
        });
      }
      this.unbindStudentCodeDialog = true;
    },
    unbindStudentCode(row) {
      var params = new URLSearchParams({
        studentCode: row.studentCode,
        rootOrgId: row.rootOrgId,
      });
      this.$confirm("是否解绑学号？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        var url = CORE_API + "/student/unbindStudentCode?" + params;
        this.$httpWithMsg.post(url).then((response) => {
          console.log(response);
          let newTableData = [];
          for (let cur of this.unbindStudentCodeData.tableData) {
            if (row.studentCode != cur.studentCode) {
              newTableData.push(cur);
            }
          }
          this.unbindStudentCodeData.tableData = newTableData;
          this.$notify({
            type: "success",
            message: "解绑成功",
          });
          this.searchForm();
        });
      });
    },
    unbindSecurityPhone(row) {
      var stuIds = this.checkIds(row);
      if (!stuIds) return;
      this.$confirm("是否解绑安全手机号？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        var url = CORE_API + "/student/unbindSecurityPhone/" + stuIds;
        this.$httpWithMsg.put(url).then((response) => {
          console.log(response);
          this.$notify({
            type: "success",
            message: "解绑成功",
          });
          this.searchForm();
        });
      });
    },
    //重置密码方法
    resetPassword(row) {
      var stuIds = this.checkIds(row);
      if (!stuIds) return;
      this.$confirm("是否重置所选学生的密码？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        var url = CORE_API + "/student/resetPass/" + stuIds;
        this.$httpWithMsg.put(url).then((response) => {
          console.log(response);
          this.$notify({
            type: "success",
            message: "重置密码成功",
          });
          this.searchForm();
        });
      });
    },

    //禁用
    disableStudent(row) {
      var stuIds = this.checkIds(row);
      if (!stuIds) return;
      this.$confirm("是否禁用所选学生？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        var url = CORE_API + "/student/disable/" + stuIds;
        this.$httpWithMsg.put(url).then((response) => {
          console.log(response);
          this.$notify({
            type: "success",
            message: "禁用成功",
          });
          this.searchForm();
        });
      });
    },

    //启用
    enableStudent(row) {
      var stuIds = this.checkIds(row);
      if (!stuIds) return;
      this.$confirm("是否启用所选学生？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        var url = CORE_API + "/student/enable/" + stuIds;
        this.$httpWithMsg.put(url).then((response) => {
          console.log(response);
          this.$notify({
            type: "success",
            message: "启用成功",
          });
          this.searchForm();
        });
      });
    },
    beforeUpload(file) {
      console.log(file);
    },
    uploadProgress(event, file, fileList) {
      console.log("uploadProgress");
      console.log(event);
      console.log(file);
      console.log(fileList);
    },
    uploadSuccess(response, file, fileList) {
      console.log(response);
      console.log(file);
      console.log(fileList);
      this.$notify({
        message: "上传成功",
        type: "success",
      });
      this.fileLoading = false;
      this.photoUploadDialog = false;
      this.searchForm();
    },
    uploadError(response, file, fileList) {
      console.log(response);
      console.log(file);
      console.log(fileList);
      var json = JSON.parse(response.message);
      if (response.status == 500) {
        this.$notify({
          message: json.desc,
          type: "error",
        });
      }
      this.fileLoading = false;
    },
    initUpload() {
      this.fileList = [];
    },
    checkUpload() {
      var fileList = this.$refs.upload.uploadFiles;
      if (fileList.length == 0) {
        this.$notify({
          message: "上传文件不能为空",
          type: "error",
        });
        return false;
      }
      if (fileList.length > 1) {
        this.$notify({
          message: "每次只能上传一个文件",
          type: "error",
        });
        return false;
      }
      for (let file of fileList) {
        let f = file.name.toLowerCase();
        if (!f.endsWith(".jpg") && !f.endsWith(".png")) {
          this.$notify({
            message: "上传文件必须为jpg或者png格式",
            type: "error",
          });
          this.initUpload();
          return false;
        }
      }
      return true;
    },
    //确定上传
    submitUpload() {
      if (!this.checkUpload()) {
        return false;
      }
      this.$refs.upload.submit();
      this.fileLoading = true;
    },
    //清空文件
    removeFile() {
      this.$refs.upload.clearFiles();
    },
    initPrivileges() {
      let params = new URLSearchParams();
      params.append(
        "privilegeCodes",
        Object.keys(this.rolePrivileges).toString()
      );
      let url = CORE_API + "/rolePrivilege/checkPrivileges?" + params;
      this.$httpWithMsg.post(url).then((response) => {
        this.rolePrivileges = response.data;
      });
    },
    async init() {
      this.initPrivileges();

      for (let role of this.user.roleList) {
        if (role.roleCode == "LC_USER") {
          this.pureLC = true;
          continue;
        }
      }

      if (1 < this.user.roleList.length) {
        this.pureLC = false;
      }

      this.formSearch.rootOrgId = this.user.rootOrgId;
      this.getOrgList4Search("");

      if (this.pureLC) {
        let url = CORE_API + "/user/" + this.user.userId;
        await this.$httpWithMsg.get(url).then((response) => {
          var userInfo = response.data;
          this.lc_id = userInfo.orgId;
          // this.lc_name = userInfo.orgName;
          // this.lc_code = userInfo.orgCode;
          // this.orgList4Search = [
          //   { id: this.lc_id, name: this.lc_name, code: this.lc_code },
          // ];
          // this.formSearch.orgId = this.lc_id;
        });
      }

      let url = CORE_API + "/org/getRootOrgList";
      this.$httpWithMsg.get(url).then((response) => {
        this.rootOrgList = response.data;
      });

      this.searchForm();
    },
    handleExamChange4Search(value) {
      this.stuExamSearch.examStageId = "";
      if (this.examList4Search.length > 0) {
        let examArr = this.examList4Search.filter((p) => p.id == value);
        if (examArr && examArr.length > 0) {
          let exam = examArr[0];
          if (
            exam.specialSettingsEnabled &&
            exam.specialSettingsType == "STAGE_BASED"
          ) {
            this.examStageDisabled4Search = false;
            this.queryExamStages4Search("");
          } else {
            this.examStageList4Search = [];
            this.examStageDisabled4Search = true;
          }
        }
      }
    },
    queryExamStages4Search(name) {
      this.queryExamStages(this.stuExamSearch.examId, name, "search");
    },
    queryExamStages(examId, name, where) {
      console.log("queryExams; name: " + name);

      this.$httpWithMsg
        .get(
          EXAM_WORK_API +
            "/examStage/queryByNameLike?examId=" +
            examId +
            "&enable=true&name=" +
            name
        )
        .then((response) => {
          if ("search" == where) {
            this.queryExamStages4SearchLoading = false;
            this.examStageList4Search = response.data;
          } else if ("insertOrUpdate" == where) {
            this.queryExamStages4InsertOrUpdateLoading = false;
            this.examStageList4InsertOrUpdate = response.data;
          }
        })
        .catch((response) => {
          console.log(response);
          if ("search" == where) {
            this.queryExamStages4SearchLoading = false;
          } else if ("insertOrUpdate" == where) {
            this.queryExamStages4InsertOrUpdateLoading = false;
          }
        });
    },
  },
};
</script>
<style scoped>
.input {
  width: 180px;
}
</style>
