<template>
  <section class="content">
    <LinkTitlesCustom :current-paths="['添加试题']" />
    <!-- 正文信息 -->
    <div class="box-body">
      <el-form
        :inline="true"
        :model="formSearch"
        label-position="right"
        label-width="90px"
      >
        <el-row>
          <el-col :span="6">
            <el-form-item label="课程">
              <el-select
                v-model="course"
                class="search_width"
                :disabled="true"
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in courseList"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="题型">
              <el-select
                v-model="formSearch.questionType"
                class="search_width"
                placeholder="请选择题型"
                size="small"
                @change="searchQuestionPaper"
              >
                <el-option
                  v-for="item in questionTypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="题干">
              <el-input
                v-model="formSearch.quesBody"
                class="search_width"
                placeholder="请输入题干"
                size="small"
                @blur="searchQuestionPaper"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <div class="search_down">
              <el-button
                size="small"
                type="primary"
                icon="el-icon-circle-check"
                @click="submitQues()"
                >确定</el-button
              >
              <el-button
                size="small"
                type="primary"
                icon="el-icon-arrow-left"
                @click="back()"
                >返回</el-button
              >
            </div>
          </el-col>
        </el-row>
      </el-form>

      <!-- 页面列表 -->
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="45"> </el-table-column>
        <el-table-column label="课程">
          <template slot-scope="scope">
            <span v-if="scope.row.course != null">{{
              scope.row.course.name
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="题型">
          <template slot-scope="scope">
            <span>{{ scope.row.questionType | questionType }}</span>
          </template>
        </el-table-column>
        <el-table-column label="题干">
          <template slot-scope="scope">
            <span class="small">
              <a
                href="javascript:;"
                @click="prevViewQues(scope.row)"
                v-html="scope.row.quesBody"
              >
              </a>
            </span>
          </template>
        </el-table-column>
      </el-table>
      <!--分页栏-->
      <div class="page pull-right">
        <el-pagination
          :current-page="currentPage"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>

    <div class="text-left">
      <el-dialog
        v-loading.body="dialogLoading"
        title="试题预览"
        :visible.sync="quesDialog"
        @close="closeQuesDialog"
      >
        <el-form :model="quesModel" label-position="right" label-width="80px">
          <el-row :gutter="10">
            <el-col :xs="10" :sm="10" :md="10" :lg="10">
              <el-form-item label="题型">
                <el-select
                  v-model="quesModel.questionType"
                  :disabled="true"
                  placeholder="题型"
                >
                  <el-option
                    v-for="item in questionTypes"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :xs="30" :sm="30" :md="30" :lg="30">
              <el-form-item label="题干">
                <span v-html="quesModel.quesBody"></span>
              </el-form-item>
            </el-col>
          </el-row>
          <!--非套题-->
          <div v-if="quesModel.questionType !== 'NESTED_ANSWER_QUESTION'">
            <el-row
              v-for="(quesOption, index) in quesModel.quesOptions"
              :key="quesOption.number"
              :gutter="10"
            >
              <el-col :xs="30" :sm="30" :md="30" :lg="30">
                <el-form-item :label="index | optionOrderWordFilter">
                  <span v-html="quesOption.optionBody"></span>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <!--套题-->
          <div v-if="quesModel.questionType === 'NESTED_ANSWER_QUESTION'">
            <el-row
              v-for="(subQuestionModel, index) in quesModel.subQuestions"
              :key="index"
              :gutter="10"
            >
              <el-col :xs="30" :sm="30" :md="30" :lg="30">
                <el-row>
                  <el-form-item label="题目">
                    <span v-html="subQuestionModel.quesBody"></span>
                  </el-form-item>
                </el-row>
                <el-row
                  v-for="(
                    subQuesOption, index2
                  ) in subQuestionModel.quesOptions"
                  :key="index2"
                  :gutter="10"
                >
                  <el-col :xs="30" :sm="30" :md="30" :lg="30">
                    <el-form-item :label="index2 | optionOrderWordFilter">
                      <span v-html="subQuesOption.optionBody"></span>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-form-item label="答案">
                    <span v-html="subQuestionModel.quesAnswer"></span>
                  </el-form-item>
                </el-row>
              </el-col>
            </el-row>
          </div>

          <!--非套题答案-->
          <div v-if="quesModel.questionType !== 'NESTED_ANSWER_QUESTION'">
            <el-form-item label="答案">
              <span v-html="quesModel.quesAnswer"></span>
            </el-form-item>
          </div>
        </el-form>
      </el-dialog>
    </div>
  </section>
</template>
<script>
import { QUESTION_API } from "@/constants/constants";
import { QUESTION_TYPES } from "../constants/constants";
import LinkTitlesCustom from "@/components/LinkTitlesCustom.vue";
export default {
  components: { LinkTitlesCustom },
  data() {
    return {
      formSearch: {
        questionType: "",
        quesBody: "",
      },
      course: "",
      paperId: "",
      paperDetailId: "",
      parentView: "",
      courseList: [],
      tableData: [],
      multipleSelection: [],
      currentPage: 1,
      pageSize: 10,
      total: 10,
      courseName: "",
      quesModel: {},
      questionTypes: QUESTION_TYPES,
      quesDialog: false,
      loading: false,
      dialogLoading: false,
      singleRightAnswer: "", //接收单选答案
      multipleRightAnswer: [], //接收多选答案
    };
  },
  //钩子函数
  created() {
    this.paperId = this.$route.params.id;
    this.courseName = this.$route.params.courseName;
    var courseNo = this.$route.params.courseNo;
    this.paperDetailId = this.$route.params.paperDetailId;
    this.parentView = this.$route.params.parentView;
    this.courseList.push({ name: this.courseName, value: courseNo });
    this.course = this.courseList[0].value;
    this.searchQuestionPaper();
  },
  methods: {
    //查询列表
    searchQuestionPaper() {
      this.loading = true;
      this.$http
        .get(
          QUESTION_API +
            "/paper/listQuestion/" +
            this.paperId +
            "/" +
            this.currentPage +
            "/" +
            this.pageSize +
            "?quesType=" +
            this.formSearch.questionType +
            "&quesBody=" +
            this.formSearch.quesBody
        )
        .then((response) => {
          this.tableData = response.data.content;
          this.total = response.data.totalElements;
          this.loading = false;
        });
    },
    //分页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.searchQuestionPaper();
    },
    openQuesDialog() {
      this.quesDialog = true;
    },
    closeQuesDialog() {
      this.quesDialog = false;
      this.quesModel = {};
    },
    //预览
    prevViewQues(row) {
      this.quesModel = row;
      this.disposeSelectAnswer();
      this.openQuesDialog();
    },
    //添加试题
    submitQues() {
      if (this.multipleSelection.length === 0) {
        this.$notify({
          type: "error",
          message: "请选择要添加的试题",
        });
      } else {
        this.$confirm("是否添加这些试题？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.$httpWithMsg
            .post(
              QUESTION_API +
                "/paper/selectQuestions/" +
                this.paperId +
                "/" +
                this.paperDetailId,
              this.multipleSelection
            )
            .then(() => {
              this.$notify({
                type: "success",
                message: "添加成功！",
              });
              this.$router.push({
                path: "/edit_paper/" + this.paperId + "/" + this.parentView,
              });
            });
        });
      }
    },
    //返回
    back() {
      this.$router.push({
        path: "/edit_paper/" + this.paperId + "/" + this.parentView,
      });
    },
    //全选
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /**
     * 处理选择题答案显示
     * 处理套题下选择题答案显示
     */
    disposeSelectAnswer() {
      this.singleRightAnswer = "";
      this.multipleRightAnswer = [];
      //处理选择题答案显示
      if (
        this.quesModel.questionType == "SINGLE_ANSWER_QUESTION" ||
        this.quesModel.questionType == "MULTIPLE_ANSWER_QUESTION"
      ) {
        for (var i = 0; i < this.quesModel.quesOptions.length; i++) {
          var option_i = this.quesModel.quesOptions[i];
          var orderNum_i = String.fromCharCode(65 + i);
          if (
            this.quesModel.questionType == "SINGLE_ANSWER_QUESTION" &&
            option_i.isCorrect == 1
          ) {
            this.singleRightAnswer = orderNum_i;
          }
          if (
            this.quesModel.questionType == "MULTIPLE_ANSWER_QUESTION" &&
            option_i.isCorrect == 1
          ) {
            this.multipleRightAnswer.push(orderNum_i);
          }
        }
      }
      //处理套题下选择题答案显示
      if (this.quesModel.questionType == "NESTED_ANSWER_QUESTION") {
        var subQuestions = this.quesModel.subQuestions;
        for (var k = 0; k < subQuestions.length; k++) {
          var subQuestion = subQuestions[k];
          if (subQuestion.questionType == "SINGLE_ANSWER_QUESTION") {
            for (var j = 0; j < subQuestion.quesOptions.length; j++) {
              var option_j = subQuestion.quesOptions[j];
              var orderNum_j = String.fromCharCode(65 + j);
              if (option_j.isCorrect == 1) {
                subQuestion["quesAnswer"] = orderNum_j;
              }
            }
          }
          if (subQuestion.questionType == "MULTIPLE_ANSWER_QUESTION") {
            var subQuestionMultipleRightAnswer = [];
            for (var z = 0; z < subQuestion.quesOptions.length; z++) {
              var option_k = subQuestion.quesOptions[z];
              var orderNum_k = String.fromCharCode(65 + z);
              if (option_k.isCorrect == 1) {
                subQuestionMultipleRightAnswer.push(orderNum_k);
              }
            }
            subQuestion["quesAnswer"] = subQuestionMultipleRightAnswer
              .sort()
              .toString();
          }
        }
      }
    },
  },
};
</script>
<style></style>
