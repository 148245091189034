<template>
  <div>
    <section class="content">
      <div class="box box-info">
        <div class="box-body">
          <el-form
            :inline="true"
            :model="formSearch"
            label-position="right"
            label-width="70px"
          >
            <el-form-item label="评卷名称" class="pull-left">
              <el-select
                v-model="formSearch.workId"
                clearable
                filterable
                :remote-method="getMarkWorks"
                remote
                class="input"
                placeholder="请选择"
                @clear="clearData"
              >
                <el-option
                  v-for="item in markWorkSelect"
                  :key="item.workId"
                  :label="item.markName"
                  :value="item.workId"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="登录名" class="pull-left">
              <el-input
                v-model="formSearch.userLoginName"
                placeholder="登录名"
              ></el-input>
            </el-form-item>
            <el-form-item label="姓名" class="pull-left">
              <el-input
                v-model="formSearch.userName"
                placeholder="姓名"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                size="small"
                type="primary"
                icon="el-icon-search"
                @click="searchBtnFn"
                >查询</el-button
              >
              <el-button
                size="small"
                type="primary"
                icon="el-icon-download"
                @click="exportIt"
                >导出</el-button
              >
            </el-form-item>
          </el-form>
          <div class="block-seperator"></div>
          <!-- 页面列表 -->
          <el-table
            v-loading="loading"
            stripe
            element-loading-text="拼命加载中"
            :data="tableData"
            border
            style="width: 100%"
          >
            <el-table-column label="姓名" width="200" prop="userName" />
            <el-table-column
              label="登录名"
              min-width="100"
              prop="userLoginName"
            />
            <el-table-column
              label="分配课程"
              min-width="200"
              prop="courseCount"
              sortable
            />
            <el-table-column
              label="完成课程"
              min-width="120"
              prop="finishedCount"
              sortable
            />
            <el-table-column
              label="完成数量"
              min-width="120"
              prop="markedCount"
              sortable
            />
            <el-table-column
              label="最低分"
              min-width="100"
              prop="minScore"
              sortable
            />
            <el-table-column
              label="最高分"
              min-width="100"
              prop="maxScore"
              sortable
            />
            <el-table-column
              label="平均分"
              min-width="100"
              prop="avgScore"
              sortable
            />
            <el-table-column
              label="标准方差"
              min-width="120"
              prop="stdDev"
              sortable
            />
            <el-table-column :context="_self" label="操作" fixed="right">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="mini"
                  plain
                  @click="markerDetail(scope.row)"
                  >详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="page pull-right">
            <el-pagination
              v-if="paginationShow"
              :current-page="currentPage"
              :page-size="pageSize"
              :page-sizes="[10, 20, 50, 100, 200, 300]"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              @current-change="handleSettingCurrentChange"
              @size-change="handleSizeChange"
            ></el-pagination>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { MARKING_LOGIC_API } from "@/constants/constants";
import { mapState } from "vuex";
export default {
  data() {
    return {
      formSearch: {
        userLoginName: "",
        userName: "",
        workId: "",
      },
      tableData: [],
      oldData: [],
      currentPage: 1,
      pageSize: 10,
      total: 10,
      loading: false,
      markWorkList: [],
      paginationShow: false,
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
    markWorkSelect() {
      let markWorkNames = [];
      for (let markWork of this.markWorkList) {
        markWorkNames.push({
          workId: markWork.id,
          markName: markWork.name,
        });
      }
      return markWorkNames;
    },
  },
  created() {
    this.getMarkWorks();
    this.backFill();
  },
  methods: {
    exportIt() {
      if (!this.formSearch.workId) {
        this.$notify({
          title: "警告",
          message: "请选择评卷名称",
          type: "warning",
        });
        return false;
      }
      var key = this.user.key;
      var token = this.user.token;
      let url =
        MARKING_LOGIC_API +
        "/markers/export?workId=" +
        this.formSearch.workId +
        "&$key=" +
        key +
        "&$token=" +
        token;
      let userLoginName = this.formSearch.userLoginName;
      if (userLoginName) {
        url = url + "&userLoginName=" + userLoginName;
      }
      let userName = this.formSearch.userName;
      if (userName) {
        url = url + "&userName=" + userName;
      }
      window.location.href = url;
    },
    clearData() {
      this.formSearch = Object.assign(this.formSearch, {
        workId: "",
        userLoginName: "",
        userName: "",
      });
      this.tableData = [];
      this.total = 0;
      this.$router.push({
        path: "/marking/marker",
      });
    },
    searchBtnFn() {
      this.currentPage = 1;
      this.searchSetting();
    },
    handleSettingCurrentChange(val) {
      this.currentPage = val;
      this.searchSetting();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.searchSetting();
    },
    searchSetting() {
      if (!this.formSearch.workId) {
        this.$notify({
          title: "警告",
          message: "请选择评卷名称",
          type: "warning",
        });
        return false;
      }
      this.loading = true;
      this.$http
        .get(
          MARKING_LOGIC_API +
            "/markers/all/" +
            (this.currentPage - 1) +
            "/" +
            this.pageSize +
            "?workId=" +
            this.formSearch.workId,
          { params: this.formSearch }
        )
        .then((response) => {
          console.log(response);
          this.tableData = response.data.content;
          this.total = response.data.totalElements;
          this.loading = false;
          this.$router.push({
            path:
              "/marking/marker?" +
              new URLSearchParams(JSON.parse(JSON.stringify(this.formSearch))),
          });
          this.$nextTick(function () {
            this.paginationShow = true;
          });
        });
    },
    markerDetail(row) {
      var url =
        "/marking/marker_detail/" +
        this.formSearch.workId +
        "/" +
        row.userId +
        "/" +
        row.userName;
      this.$router.push({
        path: url,
      });
    },
    //获取创建成功的markWork列表
    getMarkWorks(name = "") {
      this.$http
        .get(MARKING_LOGIC_API + "/markWorks?status=1" + `&name=${name}`)
        .then((response) => {
          this.markWorkList = response.data?.content || [];
          if (this.$route.query && this.$route.query.workId) {
            this.formSearch.workId = parseInt(this.$route.query.workId);
          }
        });
    },
    backFill() {
      var formData = this.$route.query;
      if (formData && formData.workId) {
        this.formSearch.workId = parseInt(formData.workId);
        this.formSearch.userLoginName = formData.userLoginName;
        this.formSearch.userName = formData.userName;
        this.searchSetting();
      }
    },
  },
};
</script>
<style lang="css" scoped>
li {
  list-style-type: none;
}

.searchFrame {
  margin-right: 10px;
  margin-bottom: 10px;
}

.page {
  margin-top: 10px;
}
.f_button {
  display: block;
  width: 57px;
  height: 20px;
  border: 1px solid #ccc;
  background: #fff;
  font-size: small;
}
</style>
