<template>
  <section class="content">
    <div class="box box-info">
      <div
        v-loading.fullscreen="loading"
        class="box-body"
        element-loading-text="请稍后..."
      >
        <!-- 搜索 -->
        <el-form inline :model="searchForm">
          <el-form-item v-if="isSuperAdmin" label="学校">
            <el-select
              v-model="searchForm.rootOrgId"
              placeholder="请选择"
              :disabled="!isSuperAdmin"
              class="input_width"
              filterable
              @change="rootOrgChanged4Search"
            >
              <el-option
                v-for="item in rootSchoolSelect"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="角色">
            <el-select
              v-model="searchForm.roleId"
              clearable
              placeholder="请选择"
              class="input_width"
            >
              <el-option
                v-for="item in roleList4Search"
                :key="item.roleId"
                :label="item.roleName"
                :value="item.roleId"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="登录名">
            <el-input
              v-model="searchForm.loginName"
              placeholder="请输入登录名"
              class="input_width"
            />
          </el-form-item>
          <el-form-item label="姓名">
            <el-input
              v-model="searchForm.name"
              placeholder="请输入姓名"
              class="input_width"
            />
          </el-form-item>

          <el-form-item label="状态">
            <el-select v-model="searchForm.enable" class="input">
              <el-option label="未选择" value=""></el-option>
              <el-option label="已启用" value="true"></el-option>
              <el-option label="已禁用" value="false"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button
              size="small"
              type="primary"
              icon="el-icon-search"
              @click="handleSearchBtn"
            >
              查询
            </el-button>

            <el-button
              size="small"
              icon="el-icon-refresh"
              @click="resetEcsFormSearch"
            >
              重置
            </el-button>

            <el-button
              size="small"
              type="primary"
              icon="el-icon-plus"
              @click="openAddingDialog"
            >
              新增
            </el-button>

            <el-button
              size="small"
              type="primary"
              icon="el-icon-plus"
              @click="copyPrevillegeDialog"
            >
              权限复制
            </el-button>
          </el-form-item>
        </el-form>

        <div class="block-seperator"></div>
        <span>批量操作：</span>

        <el-button
          size="small"
          type="success"
          icon="el-icon-check"
          :disabled="noBatchSelected"
          @click="enableByIds"
          >启用
        </el-button>
        <el-button
          size="small"
          type="danger"
          icon="el-icon-close"
          :disabled="noBatchSelected"
          @click="disableByIds"
          >禁用
        </el-button>
        <el-button
          size="small"
          type="danger"
          :disabled="noBatchSelected"
          @click="resetPassBatch"
          ><v-icon name="lock" scale="0.7" />重置密码
        </el-button>

        <div class="block-seperator"></div>
        <!-- 添加用户信息弹出框 -->
        <el-dialog title="新增用户" width="500px" :visible.sync="addingDialog">
          <el-form
            ref="addingForm"
            :inline="true"
            :model="userForm"
            :rules="rules"
            label-position="right"
            label-width="80px"
          >
            <el-row>
              <el-form-item label="姓名" prop="name">
                <el-input
                  v-model="userForm.name"
                  class="input_width_lg"
                  auto-complete="off"
                  placeholder="请输入姓名"
                />
              </el-form-item>
            </el-row>
            <el-row class="mt20">
              <el-form-item label="登录名" prop="loginName">
                <el-input
                  v-model="userForm.loginName"
                  class="input_width_lg"
                  auto-complete="off"
                  placeholder="请输入登录名"
                />
              </el-form-item>
            </el-row>
            <el-row class="mt20">
              <el-form-item label="密码" prop="password">
                <el-input
                  v-model="userForm.password"
                  class="input_width_lg"
                  auto-complete="off"
                  placeholder="请输入密码"
                />
              </el-form-item>
            </el-row>
            <el-row class="mt20">
              <el-form-item label="联系方式" prop="phoneNumber">
                <el-input
                  v-model="userForm.phoneNumber"
                  class="input_width_lg"
                  auto-complete="off"
                  placeholder="请输入联系方式"
                />
              </el-form-item>
            </el-row>
            <el-row v-if="isSuperAdmin" class="mt20">
              <el-form-item label="学校" prop="rootOrgId">
                <el-select
                  v-model="userForm.rootOrgId"
                  class="input_width_lg"
                  placeholder="请选择"
                  :disabled="!isSuperAdmin"
                  filterable
                  @change="rootOrgChanged4InsertOrUpdate"
                >
                  <el-option
                    v-for="item in rootSchoolSelect"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-row>
            <el-row class="mt20">
              <el-form-item label="角色" prop="roleIds">
                <el-select
                  v-model="userForm.roleIds"
                  class="input_width_lg"
                  multiple
                  placeholder="请选择"
                  @change="rolesChanged"
                >
                  <el-option
                    v-for="item in roleList4InsertOrUpdateWithoutSuperAdmin"
                    :key="item.roleId"
                    :label="item.roleName"
                    :value="item.roleId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-row>
            <el-row class="mt20">
              <el-form-item label="子机构" prop="orgId">
                <el-select
                  v-model="userForm.orgId"
                  class="input_width_lg"
                  :remote-method="getOrgList4InsertOrUpdate"
                  :loading="orgLoading4InsertOrUpdate"
                  remote
                  filterable
                  clearable
                  placeholder="请选择"
                  @focus="(e) => getOrgList4InsertOrUpdate(e.target.value)"
                >
                  <el-option
                    v-for="item in orgList4InsertOrUpdate"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-row>
            <el-row class="mt20">
              <el-form-item label="状态">
                <el-radio-group v-model="enableStr" class="pull_right_sm">
                  <el-radio label="true">启用</el-radio>
                  <el-radio label="false">禁用</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-row>
            <el-row class="d-flex justify-content-center">
              <el-button type="primary" @click="add">保 存</el-button>
              <el-button @click="addingDialog = false">取 消</el-button>
            </el-row>
          </el-form>
        </el-dialog>

        <!-- 修改用户信息弹出框 -->
        <el-dialog title="编辑用户" width="500px" :visible.sync="updateDialog">
          <el-form
            ref="updateForm"
            :inline="true"
            :model="userForm"
            :rules="rules"
            label-position="right"
            label-width="80px"
          >
            <el-row>
              <el-form-item label="ID">
                <el-input
                  v-model="userForm.id"
                  class="input_width_lg"
                  :disabled="true"
                />
              </el-form-item>
            </el-row>
            <el-row class="mt20">
              <el-form-item label="姓名" prop="name">
                <el-input
                  v-model="userForm.name"
                  class="input_width_lg"
                  auto-complete="off"
                  placeholder="请输入姓名"
                />
              </el-form-item>
            </el-row>
            <el-row class="mt20">
              <el-form-item label="登录名" prop="loginName">
                <el-input
                  v-model="userForm.loginName"
                  class="input_width_lg"
                  auto-complete="off"
                  placeholder="请输入登录名"
                />
              </el-form-item>
            </el-row>
            <el-row class="mt20">
              <el-form-item label="联系方式" prop="phoneNumber">
                <el-input
                  v-model="userForm.phoneNumber"
                  class="input_width_lg"
                  auto-complete="off"
                  placeholder="请输入联系方式"
                />
              </el-form-item>
            </el-row>
            <el-row v-if="isSuperAdmin" class="mt20">
              <el-form-item label="学校" prop="rootOrgId">
                <el-select
                  v-model="userForm.rootOrgId"
                  class="input_width_lg"
                  placeholder="请选择"
                  :disabled="true"
                >
                  <el-option
                    v-for="item in rootSchoolSelect"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-row>
            <el-row class="mt20">
              <el-form-item label="角色" prop="roleIds">
                <el-select
                  v-model="userForm.roleIds"
                  class="input_width_lg"
                  multiple
                  placeholder="请选择"
                  :disabled="rowIsSuperAdmin"
                  @change="rolesChanged"
                >
                  <el-option
                    v-for="item in roleList4InsertOrUpdate"
                    :key="item.roleId"
                    :label="item.roleName"
                    :disabled="item.roleCode == 'SUPER_ADMIN'"
                    :value="item.roleId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-row>
            <el-row class="mt20">
              <el-form-item label="子机构" prop="orgId">
                <el-select
                  v-model="userForm.orgId"
                  class="input_width_lg"
                  :remote-method="getOrgList4InsertOrUpdate"
                  :loading="orgLoading4InsertOrUpdate"
                  remote
                  filterable
                  clearable
                  placeholder="请选择"
                  @focus="(e) => getOrgList4InsertOrUpdate(e.target.value)"
                >
                  <el-option
                    v-for="item in orgList4InsertOrUpdate"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-row>
            <el-row class="mt20">
              <el-form-item label="状态">
                <el-radio-group
                  v-model="enableStr"
                  class="pull_right_sm"
                  :disabled="rowIsSuperAdmin"
                >
                  <el-radio label="true">启用</el-radio>
                  <el-radio label="false">禁用</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-row>
            <el-row class="d-flex justify-content-center">
              <el-button type="primary" @click="update">保 存</el-button>
              <el-button @click="updateDialog = false">取 消</el-button>
            </el-row>
          </el-form>
        </el-dialog>

        <!-- 页面列表 -->
        <el-table
          ref="table"
          :data="tableData"
          border
          resizable
          stripe
          style="width: 100%"
          @selection-change="selectChange"
        >
          <el-table-column type="selection" width="50"></el-table-column>
          <el-table-column prop="id" width="80" label="ID" />
          <el-table-column prop="name" width="120" label="姓名" />
          <el-table-column prop="loginName" width="100" label="登录名" />
          <el-table-column prop="phoneNumber" width="100" label="联系方式" />
          <el-table-column prop="rootOrgName" label="学校" />
          <!-- <el-table-column prop="roleNamesStr" width="110" label="角色" /> -->
          <el-table-column width="120" label="角色">
            <span
              slot-scope="scope"
              v-html="scope.row.roleNamesStr.replace(/,/g, '<br />')"
            >
            </span>
          </el-table-column>
          <el-table-column
            sortable
            prop="updateTime"
            width="160"
            label="更新时间"
          />
          <el-table-column width="50" label="状态">
            <span slot-scope="scope">
              <span v-if="scope.row.enable">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="启用"
                  placement="left"
                >
                  <i class="el-icon-success" style="color: green"></i>
                </el-tooltip>
              </span>
              <span v-else>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="禁用"
                  placement="left"
                >
                  <i class="el-icon-error" style="color: red"></i>
                </el-tooltip>
              </span>
            </span>
          </el-table-column>
          <el-table-column width="190" label="操作">
            <div slot-scope="scope">
              <el-button
                v-if="!scope.row.enable"
                size="mini"
                type="primary"
                plain
                icon="el-icon-check"
                @click="enableById(scope.row)"
              >
                启用
              </el-button>
              <el-button
                v-if="scope.row.enable"
                size="mini"
                type="danger"
                icon="el-icon-close"
                @click="disableById(scope.row)"
              >
                禁用
              </el-button>
              &nbsp;
              <el-dropdown>
                <el-button type="primary" plain size="mini">
                  更多<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <el-button
                      v-if="showUserDataRuleSetting(scope.row)"
                      size="mini"
                      type="primary"
                      plain
                      icon="el-icon-edit"
                      @click="
                        () =>
                          $router.push(
                            `./user/data_previllege/${scope.row.id}?rootOrgId=${scope.row.rootOrgId}`
                          )
                      "
                      >数据权限
                    </el-button>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-button
                      size="mini"
                      type="primary"
                      plain
                      icon="el-icon-edit"
                      @click="openUpdateDialog(scope.row)"
                      >编辑
                    </el-button>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-button
                      v-if="false"
                      size="mini"
                      type="danger"
                      icon="el-icon-delete"
                      @click="deleteById(scope.row)"
                      >删除
                    </el-button>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-button
                      size="mini"
                      type="danger"
                      @click="resetPass(scope.row)"
                    >
                      <v-icon name="lock" scale="0.7" /> 重置密码
                    </el-button>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </el-table-column>
        </el-table>
        <div class="page pull-right">
          <el-pagination
            :current-page="currentPage"
            :page-size="10"
            :page-sizes="[10, 20, 50, 100, 200, 300]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          />
        </div>
      </div>
    </div>

    <UserCopyPrevillegeDialog
      ref="copyPrevillegeDialog"
      :source-user="selectedUserToCopy"
      @reload="init"
    />
  </section>
</template>

<script>
import { CORE_API } from "@/constants/constants.js";
import { mapState } from "vuex";
import UserCopyPrevillegeDialog from "./user_copy_previllege_dialog";

export default {
  name: "User",
  components: { UserCopyPrevillegeDialog },
  data() {
    let pswReg =
      /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_]+$)(?![a-z0-9]+$)(?![a-z\W_]+$)(?![0-9\W_]+$)[a-zA-Z0-9\W_]{6,10}$/;

    var validatePsw = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else if (value && !value.match(pswReg)) {
        callback(
          new Error(
            "至少包含大写字母、小写字母、数字、特殊符号中的三种，且长度限制在6-10位！"
          )
        );
      } else {
        // if (this.userForm.password !== "") {
        //   this.$refs.userForm.validateField("password");
        // }
        callback();
      }
    };

    var validateRootOrg = (rule, value, callback) => {
      if (0 != value && !value) {
        callback(new Error("请选择学校"));
      } else {
        callback();
      }
    };
    var validateRoles = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请选择角色"));
      } else {
        callback();
      }
    };
    return {
      rolePrivileges: {
        user_data_rule_setting: false,
      },
      loading: false,
      orgLoading4InsertOrUpdate: false,
      roleList4Search: [],
      roleList4InsertOrUpdate: [],
      rootOrgList: [],
      orgList4InsertOrUpdate: [],
      searchForm: {
        name: "",
        loginName: "",
        enable: "",
        rootOrgId: "",
        roleId: "",
      },
      userForm: {
        id: null,
        name: "",
        loginName: "",
        password: "",
        phoneNumber: "",
        enable: true,
        rootOrgId: null,
        orgId: null,
        roleIds: [],
      },
      enableStr: "true",

      tempOrgList: [],
      userId: "",
      selectedUserIds: [],
      selectedUserToCopy: null,
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 10,
      addingDialog: false,
      updateDialog: false,
      rowIsSuperAdmin: false,
      rules: {
        name: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur",
          },
        ],
        loginName: [
          {
            required: true,
            message: "请输入登录名",
            trigger: "blur",
          },
        ],
        password: [
          {
            // required: true,
            // message: "请输入密码",
            validator: validatePsw,
            trigger: "blur",
          },
        ],
        phoneNumber: [
          // {
          //   message: "请输入联系方式",
          //   trigger: "blur"
          // }
        ],
        rootOrgId: [
          {
            validator: validateRootOrg,
            trigger: "blur",
          },
        ],
        orgId: [],
        roleIds: [
          {
            required: true,
            validator: validateRoles,
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    userIds() {
      var userIds = "";
      for (let userId of this.selectedUserIds) {
        if (!userIds) {
          userIds += userId;
        } else {
          userIds += "," + userId;
        }
      }
      return userIds;
    },
    noBatchSelected() {
      return this.selectedUserIds.length === 0;
    },
    roleList4InsertOrUpdateWithoutSuperAdmin() {
      return this.roleList4InsertOrUpdate.filter(
        (item) => item.roleCode != "SUPER_ADMIN"
      );
    },
    isSuperAdmin() {
      return this.user.roleList.some((role) => role.roleCode == "SUPER_ADMIN");
    },
    rootSchoolSelect() {
      let rootSchools = [];
      for (let i = 0; i < this.rootOrgList.length; i++) {
        let info = {
          name: this.rootOrgList[i].name + "（" + this.rootOrgList[i].id + "）",
          id: this.rootOrgList[i].id,
        };
        rootSchools.push(info);
      }
      return rootSchools;
    },
  },
  //初始化查询
  created() {
    this.initPrivileges();
    this.init();
  },
  methods: {
    resetEcsFormSearch() {
      this.searchForm = Object.assign(this.searchForm, {
        roleId: "",
        loginName: "",
        name: "",
        enable: "",
      });
    },
    handleSizeChange(val) {
      this.currentPage = 1;
      this.pageSize = val;
      this.search();
    },
    validateOrg(rule, value, callback) {
      if (0 != value && !value) {
        callback(new Error("请选择子机构"));
      } else {
        callback();
      }
    },
    rolesChanged() {
      var isLC = false;
      for (let cur of this.roleList4InsertOrUpdate) {
        if (
          cur.roleCode == "LC_USER" &&
          this.userForm.roleIds.includes(cur.roleId)
        ) {
          isLC = true;
          break;
        }
      }
      console.log("rolesChanged(); isLC:", isLC);
      if (isLC) {
        this.rules.orgId = [
          {
            validator: this.validateOrg,
            trigger: ["blur", "change"],
          },
        ];
      } else {
        this.rules.orgId = [];
        if (this.updateDialog) {
          this.$refs.updateForm && this.$refs.updateForm.clearValidate("orgId");
        } else if (this.addingDialog) {
          this.$refs.addingForm && this.$refs.addingForm.clearValidate("orgId");
        }
      }
      if (this.updateDialog) {
        this.$refs.updateForm && this.$refs.updateForm.validateField("orgId");
      } else if (this.addingDialog) {
        this.$refs.addingForm && this.$refs.addingForm.validateField("orgId");
      }
    },
    rootOrgChanged4Search() {
      var url =
        CORE_API +
        "/rolePrivilege/getRoles?includeSuperAdmin=" +
        true +
        "&rootOrgId=" +
        this.searchForm.rootOrgId;
      this.$httpWithMsg.post(url).then((response) => {
        this.roleList4Search = response.data;
      });
    },
    rootOrgChanged4InsertOrUpdate() {
      this.orgList4InsertOrUpdate = [];

      var url =
        CORE_API +
        "/rolePrivilege/getRoles?includeSuperAdmin=" +
        true +
        "&rootOrgId=" +
        this.userForm.rootOrgId;
      this.$httpWithMsg.post(url).then((response) => {
        this.roleList4InsertOrUpdate = response.data;
        this.rolesChanged();
      });
    },
    getOrgList4InsertOrUpdate(query) {
      this.orgLoading4InsertOrUpdate = true;
      var url =
        CORE_API +
        "/org/query?" +
        new URLSearchParams({
          name: query,
          rootOrgId: this.userForm.rootOrgId,
        });
      this.$httpWithMsg
        .get(url)
        .then((response) => {
          this.orgList4InsertOrUpdate = response.data;
          this.orgLoading4InsertOrUpdate = false;
        })
        .catch(() => {
          this.orgLoading4InsertOrUpdate = false;
        });
    },
    getTag(status) {
      if (status == true) {
        return "success";
      } else if (status == false) {
        return "danger";
      }
      return status;
    },
    getLevel(level) {
      if (level == "ZSB") {
        return "专升本";
      } else if (level == "GQZ") {
        return "高起专";
      }
      return level;
    },
    handleSearchBtn() {
      this.currentPage = 1;
      this.search();
    },
    search() {
      let searchLock = true;
      setTimeout(() => {
        if (searchLock) {
          this.loading = true;
        }
      }, 500);
      var param = new URLSearchParams(this.searchForm);
      var url =
        CORE_API +
        "/user/all/" +
        this.currentPage +
        "/" +
        this.pageSize +
        "?" +
        param;
      this.$httpWithMsg
        .get(url)
        .then((response) => {
          // console.log(response);
          this.tableData = response.data.list;
          this.total = response.data.total;
          this.loading = false;
        })
        .finally(() => {
          searchLock = false;
          this.loading = false;
        });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.search();
    },
    selectChange(row) {
      this.selectedUserIds = [];
      row.forEach((element) => {
        this.selectedUserIds.push(element.id);
      });
    },
    //新增
    openAddingDialog() {
      if (this.$refs.addingForm) {
        this.$refs.addingForm.resetFields();
      }
      if (this.$refs.updateForm) {
        this.$refs.updateForm.resetFields();
      }
      this.addingDialog = true;
      this.userForm.name = "";
      this.userForm.loginName = "";
      this.userForm.password = "";
      this.userForm.phoneNumber = "";
      this.userForm.roleIds = [];
      this.userForm.orgId = null;
      this.enableStr = "true";
      this.orgList4InsertOrUpdate = [];
      this.rolesChanged();
    },
    //修改
    openUpdateDialog(row) {
      if (this.$refs.updateForm) {
        this.$refs.updateForm.resetFields();
      }
      if (this.$refs.addingForm) {
        this.$refs.addingForm.resetFields();
      }

      this.updateDialog = true;
      this.userForm.id = row.id;
      this.userForm.roleIds = row.roleIds;
      this.userForm.name = row.name;
      this.userForm.loginName = row.loginName;
      this.enableStr = row.enable ? "true" : "false";
      this.userForm.phoneNumber = row.phoneNumber;
      this.userForm.password = null;
      this.userForm.rootOrgId = row.rootOrgId;
      this.rowIsSuperAdmin = false;
      for (let roleCode of row.roleCodes) {
        if (roleCode == "SUPER_ADMIN") {
          this.rowIsSuperAdmin = true;
          break;
        }
      }

      this.rootOrgChanged4InsertOrUpdate();
      this.orgList4InsertOrUpdate = [{ id: row.orgId, name: row.orgName }];
      this.userForm.orgId = row.orgId;
    },
    exportUser() {
      var param = new URLSearchParams(this.searchForm);
      window.open(CORE_API + "/user/export?" + param);
    },
    //保存
    add() {
      var url = CORE_API + "/user";
      this.$refs.addingForm.validate((valid) => {
        if (valid) {
          this.userForm.enable = this.enableStr == "true";
          this.$httpWithMsg.post(url, this.userForm).then(() => {
            this.$notify({
              type: "success",
              message: "添加成功",
            });
            this.search();
            this.addingDialog = false;
          });
        } else {
          return false;
        }
      });
    },
    //保存
    update() {
      var url = CORE_API + "/user";
      this.$refs.updateForm.validate((valid) => {
        if (valid) {
          this.userForm.enable = this.enableStr == "true";
          this.$httpWithMsg.put(url, this.userForm).then(() => {
            this.$notify({
              type: "success",
              message: "添加成功",
            });
            this.search();
            this.updateDialog = false;
          });
        } else {
          return false;
        }
      });
    },
    //重置密码
    resetPass(row) {
      this.$confirm("是否重置密码？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var url = CORE_API + "/user/resetPass/" + row.id;
          this.$httpWithMsg.put(url).then(() => {
            this.$notify({
              type: "success",
              message: "重置成功！",
            });
            return this.search();
          });
        })
        .catch((e) => console.log(e));
    },
    resetPassBatch() {
      this.$confirm("是否重置密码？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var url = CORE_API + "/user/resetPass";
          this.$httpWithMsg
            .post(url, new URLSearchParams({ ids: this.selectedUserIds }))
            .then(() => {
              this.$notify({
                type: "success",
                message: "重置成功！",
              });
              return this.search();
            });
        })
        .catch((e) => console.log(e));
    },
    //删除单个数据
    deleteById(row) {
      this.$confirm("是否删除该用户？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        var url = CORE_API + "/user/" + row.id;
        this.$httpWithMsg.delete(url).then(() => {
          this.$notify({
            type: "success",
            message: "删除成功！",
          });
          return this.search();
        });
      });
    },
    //删除多条数据
    deleteByIds() {
      if (this.selectedUserIds.length === 0) {
        this.$notify({
          type: "warning",
          message: "请选择要删除的用户",
        });
      } else {
        this.$confirm("是否删除这些用户？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "error",
        }).then(() => {
          var url = CORE_API + "/user/" + this.userIds;
          this.$httpWithMsg.delete(url).then(() => {
            this.$notify({
              type: "success",
              message: "删除成功！",
            });
            this.search();
          });
        });
      }
    },
    //启用
    enableByIds() {
      if (this.selectedUserIds.length === 0) {
        this.$notify({
          type: "warning",
          message: "请选择要启用的用户",
        });
      } else {
        this.$confirm("是否启用这些用户？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          var url = CORE_API + "/user/enable/" + this.userIds;
          this.$httpWithMsg.put(url, {}).then(() => {
            this.$notify({
              type: "success",
              message: "启用成功！",
            });
            return this.search();
          });
        });
      }
    },
    enableById(row) {
      this.$confirm("是否启用该用户？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        var url = CORE_API + "/user/enable/" + row.id;
        this.$httpWithMsg.put(url, {}).then(() => {
          this.$notify({
            type: "success",
            message: "启用成功！",
          });
          this.search();
        });
      });
    },
    //禁用
    disableByIds() {
      if (this.selectedUserIds.length === 0) {
        this.$notify({
          type: "warning",
          message: "请选择要禁用的用户",
        });
      } else {
        this.$confirm("是否禁用这些用户？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "error",
        }).then(() => {
          var url = CORE_API + "/user/disable/" + this.userIds;
          this.$httpWithMsg.put(url, {}).then(() => {
            this.$notify({
              type: "success",
              message: "禁用成功！",
            });
            this.search();
          });
        });
      }
    },
    //禁用
    disableById(row) {
      this.$confirm("是否禁用该用户？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      }).then(() => {
        var url = CORE_API + "/user/disable/" + row.id;
        this.$httpWithMsg.put(url, {}).then(() => {
          this.$notify({
            type: "success",
            message: "禁用成功！",
          });
          this.search();
        });
      });
    },
    /*初始化*/
    init() {
      this.searchForm.rootOrgId = this.user.rootOrgId;
      this.userForm.rootOrgId = this.user.rootOrgId;

      var url1 = CORE_API + "/org/getRootOrgList";
      var url2 =
        CORE_API +
        "/rolePrivilege/getRoles?includeSuperAdmin=true&rootOrgId=" +
        this.user.rootOrgId;

      Promise.all([
        this.$httpWithMsg.get(url1),
        this.$httpWithMsg.post(url2),
      ]).then(([resp1, resp2]) => {
        this.rootOrgList = resp1.data;
        this.roleList4Search = resp2.data;
        this.roleList4InsertOrUpdate = resp2.data;
        this.search();
      });
    },
    initPrivileges() {
      let params = new URLSearchParams();
      params.append(
        "privilegeCodes",
        Object.keys(this.rolePrivileges).toString()
      );
      var url = CORE_API + "/rolePrivilege/checkPrivileges?" + params;
      this.$httpWithMsg.post(url).then((response) => {
        this.rolePrivileges = response.data;
      });
    },
    showUserDataRuleSetting(row) {
      if (!this.rolePrivileges.user_data_rule_setting) {
        return false;
      }

      let roleCodes = row.roleCodes;
      // console.log(JSON.stringify(roleCodes));

      for (let n = 0; n < roleCodes.length; n++) {
        let roleCode = roleCodes[n];
        if (roleCode == "SUPER_ADMIN" || roleCode == "ORG_ADMIN") {
          return false;
        }
      }

      return true;
    },
    copyPrevillegeDialog() {
      const refIds = this.$refs.table.selection;
      if (refIds.length === 0 || refIds > 1) {
        this.$notify({ type: "warning", message: "请先选择一行" });
        return;
      }
      this.selectedUserToCopy = refIds[0];
      this.$refs.copyPrevillegeDialog.openDialog();
    },
  },
};
</script>

<style scoped>
.page {
  margin-top: 10px;
}

.input_width_lg {
  width: 300px;
}
.input_width {
  width: 150px;
}

.mt20 {
  margin-top: 20px;
}
</style>
