<template>
  <section class="content">
    <div><img src="./tips.png" /></div>
    <div class="text">
      <div v-for="(menu1, index) in instructions" :key="index" class="menu1">
        <h4 v-if="menu1.menu2.filter((m) => m.canAccess).length > 0">
          {{ menu1.menu1Name }}
        </h4>
        <div v-for="(menu2, index2) in menu1.menu2" :key="index2" class="menu1">
          <span v-if="menu2.canAccess">
            <router-link :to="menu2.link" class="link">
              {{ menu2.name }}
            </router-link>
            {{ menu2.detail }}
          </span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
const ALL_INSTRUCTIONS = {
  "/basic/tips": [
    {
      menu1Name: "学习中心查询",
      menu2: [
        {
          name: "机构管理——中心列表",
          link: "/basic/campus",
          detail:
            "查询学校下属所有学习中心编码及名称，可按学习中心设置学习中心平台权限，即A学习中心只能查询A中心的相关考试数据。",
        },
      ],
    },
    {
      menu1Name: "课程查询",
      menu2: [
        {
          name: "课程管理——课程列表",
          link: "/basic/course",
          detail:
            "查看所有课程名称及代码，如一门课程即有专科又有本科，课程对应在的教材、试卷不一样时，需要建立专科课程及本科课程。",
        },
      ],
    },
    {
      menu1Name: "用户查询",
      menu2: [
        {
          name: "用户管理——普通用户管理",
          link: "/basic/user",
          detail:
            "添加、查询、禁用管理平台用户，并可以针对不同用户设置不同的平台模块权限。",
        },
      ],
    },
  ],
  "/examwork/tips": [
    {
      menu1Name: "安排考试",
      menu2: [
        {
          name: "考试管理——考试信息",
          link: "/examwork/examInfo",
          detail: "安排一场在线考试，设置考试开启、结束时间。",
        },
      ],
    },
    {
      menu1Name: "考试课程",
      menu2: [
        {
          name: "考试管理——考生信息",
          link: "/examwork/examStudent",
          detail: "新增或导入考生报考的相关课程。",
        },
      ],
    },
    {
      menu1Name: "学生档案",
      menu2: [
        {
          name: "学生管理——学生信息",
          link: "/examwork/student",
          detail: "更新学生的登录名、密码、相片。",
        },
      ],
    },
  ],
  "/questions/tips": [
    {
      menu1Name: "导入试卷",
      menu2: [
        {
          name: "题库管理——导入试卷管理",
          link: "/questions/import_paper/0",
          detail: "可以将word版试卷导入题库，由题库按课程、题型形成题库。",
        },
      ],
    },
    {
      menu1Name: "卷库组卷",
      menu2: [
        {
          name: "卷库管理——考试试卷管理",
          link: "/questions/gen_paper/0",
          detail:
            "可按需求将XX课程的所有试题，按分数、题量、题型结构、章节等多种方式进行随机组合，生成若干套试卷。",
        },
      ],
    },
    {
      menu1Name: "考试用卷绑定",
      menu2: [
        {
          name: "考试管理——调卷规则",
          link: "/questions/extract_paper_rule/0",
          detail: "来指定在本次考试中使用卷库中的哪一套试卷，及抽卷的比例。",
        },
      ],
    },
  ],
  "/oe/tips": [
    {
      menu1Name: "考试过程监考",
      menu2: [
        {
          name: "网考管理——监考待审",
          link: "/oe/awaitingAudit",
          detail: "列出所有考生考试过程中的数据，并提示出违纪数据审核。",
        },
      ],
    },
    {
      menu1Name: "考试明细查询",
      menu2: [
        {
          name: "统计报表——考试明细",
          link: "/oe/examDetail",
          detail:
            "需要查询学生所有作答的试卷，可以在考试明细中输入学生信息进行查询考试时间、违纪情况、客观题得分、答卷等。",
        },
      ],
    },
    {
      menu1Name: "考试完成进度查询",
      menu2: [
        {
          name: "考试进度——考试概览",
          link: "/oe/examSummary",
          detail: "可以查看按考试、学习中心、课程数来查看考试完成的进度。",
        },
      ],
    },
    {
      menu1Name: "考试完成进度查询",
      menu2: [
        {
          name: "考试进度——考试进度详情",
          link: "/oe/examScheduling",
          detail: "可以查看按考试的人数、学习中心、查看考生的完成情况。",
        },
      ],
    },
  ],
  "/print/tips": [
    {
      menu1Name: "项目管理",
      menu2: [
        {
          name: "项目管理——项目列表",
          link: "/print/project/list",
          detail:
            "查询学校下传统考试的统计概况信息，如考生人数，试卷数、试卷印刷数量等相关数据。",
        },
      ],
    },
  ],
  "/marking/tips": [
    {
      menu1Name: "评卷进度查询",
      menu2: [
        {
          name: "评卷总览——评卷进度",
          link: "/marking/mark_work_overview",
          detail: "本次评卷完成进度，可以按卷量、课程进行查询。",
        },
      ],
    },
    {
      menu1Name: "评卷员工作量核算",
      menu2: [
        {
          name: "评卷总览——评卷员一览",
          link: "/marking/marker",
          detail: "可以按评卷员统计出阅卷量、最高分、最低分、方差等。",
        },
      ],
    },
    {
      menu1Name: "评卷结果检查",
      menu2: [
        {
          name: "试卷检查",
          link: "/marking/mark_paper_check",
          detail:
            "可以查看所有已评卷的结果，并对有疑问的试卷进行打回，让评卷员进行重新给分。",
        },
      ],
    },
    {
      menu1Name: "试卷评分",
      menu2: [
        {
          name: "试卷评阅-阅卷",
          link: "/marking/mark_setting_work/marking",
          detail: "评卷员可以对已分配的试卷进行打分。",
        },
      ],
    },
  ],
  "/reports/tips": [
    {
      menu1Name: "分析项目",
      menu2: [
        {
          name: "分析项目——项目列表",
          link: "/reports/project",
          detail: "分析项目——项目列表",
        },
      ],
    },
  ],
};

import { mapState } from "vuex";

export default {
  name: "Tips",
  data() {
    return {
      instructions: [],
    };
  },
  computed: { ...mapState({ menuList: (state) => state.menuList }) },
  watch: {
    menuList() {
      const tipsLink = this.menuList.map((m) => m.ext5);
      this.instructions.map((menu) => {
        menu.menu2 = menu.menu2.map((m) => {
          if (tipsLink.includes(m.link)) {
            m.canAccess = true;
          } else {
            m.canAccess = false;
          }
          return m;
        });
        return menu;
      });
    },
  },
  created() {
    this.instructions = ALL_INSTRUCTIONS[this.$route.path];
  },
  methods: {},
};
</script>

<style scoped>
.content {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  width: 100%;

  /* margin: 100px; */
}

.text {
  max-width: 600px;
  margin: 0 20px;
}

.menu1 {
  margin-bottom: 20px;
}

.link {
  padding: 0em 1em;
  background-color: #dbe7ef;
  border-radius: 12px;

  color: #3c8dbd;
}
</style>
