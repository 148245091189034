<template>
  <div style="position: relative">
    <LinkTitlesCustom :current-paths="['试卷检查', '试卷列表', '试卷详情']" />
    <div style="position: absolute; right: 10px">
      <el-button
        type="primary"
        size="small"
        style="padding-bottom: 8px"
        icon="el-icon-arrow-left"
        @click="back"
        >返回</el-button
      >
    </div>
    <br />
    <div style="margin-left: 50px; width: 95%; height: 80%" v-html="html"></div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { MARKING_LOGIC_API } from "@/constants/constants";
import LinkTitlesCustom from "@/components/LinkTitlesCustom.vue";
import "viewerjs/dist/viewer.css";
import Viewer from "viewerjs";
export default {
  components: {
    LinkTitlesCustom,
  },
  data() {
    return {
      workId: "",
      studentPaperId: "",
      html: "",
      picModelKey: Math.random(),
      picModel: false,
      picForm: {
        imgUrl: "",
        rotate: 0,
      },
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
  },
  created() {
    this.workId = this.$route.params.workId;
    this.studentPaperId = this.$route.params.studentPaperId;
    this.getStudentPaper();
  },
  mounted() {
    window.viewPicture = this.viewPicture;
  },
  methods: {
    viewPicture(imagesClass, index) {
      const viewer = new Viewer(document.querySelector(imagesClass), {
        container: "#app",
        zIndex: 99999,
        title: false,
        toolbar: {
          zoomIn: 1,
          zoomOut: 1,
          oneToOne: 1,
          reset: 1,
          prev: 1,
          play: {
            show: 0,
            size: "large",
          },
          next: 1,
          rotateLeft: 1,
          rotateRight: 1,
          flipHorizontal: 1,
          flipVertical: 1,
        },
        ready() {
          viewer.view(index);
        },
        hidden() {
          viewer.destroy();
        },
      });
      viewer.show();
    },
    back() {
      this.$router.back();
    },
    getStudentPaper() {
      var url =
        MARKING_LOGIC_API + "/studentPapers/check/" + this.studentPaperId;
      this.$http.get(url).then((response) => {
        console.log(response);
        this.html = response.data.studentSubjectiveHtml;
      });
    },
  },
};
</script>
<style>
img.photo-answer {
  width: 100px;
  padding: 10px;
  height: 100px;
}
.photo-answers-block {
  width: 350px !important;
}
.photo-answers-block a {
  cursor: pointer;
}
.student-score {
  color: red;
  font-style: italic;
}
</style>
