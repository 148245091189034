import Home from "../../portal/views/home/Home.vue";
import student from "../view/student.vue";
import examStudentImport from "../view/examStudentImport.vue";
import ipConfig from "../view/ipConfig.vue";
import examStudent from "../view/examStudent.vue";
import examInfo from "../view/examInfo.vue";
import traditionExam from "../view/traditionExam.vue";
import onlineExam from "../view/onlineExam.vue";
import onlineHomework from "../view/onlineHomework.vue";
import practiceExam from "../view/practiceExam.vue";
import offlineExam from "../view/offlineExam.vue";
import printExam from "../view/printExam.vue";
import offlineExamOrgSettings from "../view/offlineExamOrgSettings.vue";
import onlineExamOrgSettings from "../view/onlineExamOrgSettings.vue";
import onlineHomeworkOrgSettings from "../view/onlineHomeworkOrgSettings.vue";
import Tips from "../../portal/views/tips/Tips.vue";
import notice from "../view/notice.vue";
import studentSpecialSettings from "../view/studentSpecialSettings.vue";
import stageSpecialSettings from "../view/stageSpecialSettings.vue";
import examIpLimit from "../view/examIpLimit.vue";
import weixinAnswerSetting from "../view/weixinAnswerSetting.vue";
import skipFaceSetting from "../view/skipFaceSetting.vue";

export default [
  {
    path: "/examwork", //考务
    meta: { auth: false },
    component: Home,
    children: [
      {
        path: "tips", //学生管理
        component: Tips,
      },
      {
        path: "student", //学生管理
        meta: { pageName: "学生管理" },
        component: student,
      },
      {
        path: "examStudentImport", //考生导入
        meta: { pageName: "考生导入" },
        component: examStudentImport,
      },
      {
        path: "ip-config", //考点IP登记
        name: "IpConfig",
        meta: { pageName: "考点IP登记" },
        component: ipConfig,
      },
      {
        path: "examStudent", //考生管理
        meta: { pageName: "考生管理" },
        component: examStudent,
      },
      {
        path: "examInfo", //考试管理
        meta: { pageName: "考试管理" },
        component: examInfo,
      },
      {
        path: "traditionExam/:id",
        component: traditionExam,
      },
      {
        path: "onlineExam/:id",
        component: onlineExam,
      },
      {
        path: "onlineHomework/:id",
        component: onlineHomework,
      },
      {
        path: "practiceExam/:id",
        component: practiceExam,
      },
      {
        path: "printExam/:id",
        component: printExam,
      },
      {
        path: "offlineExam/:id",
        component: offlineExam,
      },
      {
        path: "offlineExamOrgSettings/:id",
        component: offlineExamOrgSettings,
      },
      {
        path: "onlineExamOrgSettings/:id",
        component: onlineExamOrgSettings,
      },
      {
        path: "onlineHomeworkOrgSettings/:id",
        component: onlineHomeworkOrgSettings,
      },
      {
        path: "studentSpecialSettings/:id",
        component: studentSpecialSettings,
      },
      {
        path: "stageSpecialSettings/:id/:examName/:examTypeName", //场次特殊设置
        component: stageSpecialSettings,
      },
      {
        path: "examIpLimit/:id/:examName/:examTypeName",
        component: examIpLimit,
      },
      {
        path: "weixinAnswerSetting/:id",
        component: weixinAnswerSetting,
      },
      {
        path: "skipFaceSetting/:id/:examName",
        component: skipFaceSetting,
      },
      {
        path: "notice",
        component: notice,
      },
    ],
  },
];
