<template>
  <div id="paperView">
    <div id="paperName">{{ paperStruct.name }}</div>
    <el-image
      ref="elImage"
      style="width: 0; height: 0"
      :src="previewSrc"
      :preview-src-list="previewList"
      @load="elImageLoad"
    >
    </el-image>
    <div id="studentInfoDiv">
      <table id="studentInfoTable" border>
        <thead>
          <tr>
            <th>考试记录ID</th>
            <th>学号</th>
            <th>姓名</th>
            <th>课程</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ examRecordData.id }}</td>
            <td>{{ examRecord.studentCode }}</td>
            <td>{{ examRecord.studentName }}</td>
            <td>{{ courseInfo.name }}({{ courseInfo.code }})</td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- 遍历大题 -->
    <div
      v-for="(questionGroup, index) in paperStruct.questionGroupList"
      :key="index"
      class="mainQuestionDiv"
    >
      <div style="font-size: 16px; font-weight: bold">
        {{ toChineseNumber(index + 1) }}、{{ questionGroup.groupName }}({{
          questionGroup.groupScore
        }}分)
      </div>
      <div>{{ questionGroup.score }}</div>
      <!-- 遍历大题下的小题 -->
      <div
        v-for="(questionWrapper, index2) in questionGroup.questionWrapperList"
        :key="index2"
      >
        <div
          v-for="(question, index3) in questionWrapper.questionList"
          :key="index3"
        >
          <div style="display: flex; flex-direction: row">
            <div v-html="restoreAudio(question.body)"></div>
          </div>
          <div
            v-for="(questionUnit, index4) in question.questionUnitList"
            :key="index4"
          >
            <div style="display: flex; flex-direction: row">
              <div>{{ questionUnit.order }}、</div>
              <div v-html="restoreAudio(questionUnit.body)"></div>
              <span>({{ questionUnit.questionScore }}分)</span>
            </div>

            <div
              v-for="(option, index5) in questionUnit.quesOptions"
              :key="index5"
            >
              <div style="display: flex; flex-direction: row">
                <div>{{ option.letter }}.</div>
                <div v-html="restoreAudio(option.body)"></div>
              </div>
            </div>

            <div>
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  color: green;
                  font-weight: bold;
                "
              >
                <div>标准答案：</div>
                <span v-html="questionUnit.correctAnswer"></span>
              </div>

              <div
                v-if="
                  questionUnit.answerType !== 'SINGLE_AUDIO' ||
                  (questionUnit.answerType === 'SINGLE_AUDIO' &&
                    (questionUnit.studentAnswer == null ||
                      questionUnit.studentAnswer == ''))
                "
                style="color: blue; font-weight: bold"
              >
                学生答案：
                <span
                  @click="answerClick"
                  v-html="questionUnit.studentAnswer"
                ></span>
                <i
                  v-show="
                    questionUnit.isObjectiveQuestion && questionUnit.isRight
                  "
                  class="el-icon-check"
                ></i>
                <i
                  v-show="
                    questionUnit.isObjectiveQuestion && !questionUnit.isRight
                  "
                  class="el-icon-close"
                ></i>
              </div>

              <div
                v-if="
                  questionUnit.answerType === 'SINGLE_AUDIO' &&
                  questionUnit.studentAnswer != null &&
                  questionUnit.studentAnswer != ''
                "
              >
                <div
                  style="
                    float: left;
                    color: blue;
                    font-weight: bold;
                    height: 54px;
                    line-height: 54px;
                    margin-right: 10px;
                  "
                >
                  学生答案：
                </div>
                <audio
                  v-if="
                    questionUnit.answerType === 'SINGLE_AUDIO' &&
                    questionUnit.studentAnswer != null &&
                    questionUnit.studentAnswer != ''
                  "
                  style="height: 54px"
                  :src="questionUnit.studentAnswer"
                  controls
                ></audio>
              </div>

              <div
                style="
                  display: flex;
                  flex-direction: row;
                  color: red;
                  font-style: italic;
                "
              >
                <div>得分：</div>
                <span>{{ questionUnit.studentScore }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      courseId: "",
      examRecordDataId: "",
      examRecordData: {},
      examRecord: {},
      courseInfo: {},
      paperStruct: {},
      examQuestionList: [],
      previewList: [],
      previewSrc: "",
    };
  },
  created() {
    this.courseId = this.$route.params.courseId;
    this.examRecordDataId = this.$route.params.examRecordDataId;
    this.getExamRecordData();
    this.getCourseInfo();
    this.getPaperData();
  },
  methods: {
    elImageLoad() {
      let img = this.$refs.elImage.$el.querySelector(".el-image__preview");
      img.click();
    },
    answerClick(e) {
      if ((e.target.className || "").includes("photo-answer")) {
        e.preventDefault();
        this.previewSrc = e.target.src;
        this.previewList = [e.target.src];
        setTimeout(() => {
          try {
            let img =
              this.$refs.elImage.$el.querySelector(".el-image__preview");
            img.click();
          } catch (err) {
            console.log(err);
          }
        }, 10);
      }
    },
    getExamRecordData() {
      this.$http
        .get("/api/ecs_oe_admin/exam/record/data/findExamRecordDataEntity", {
          params: { examRecordDataId: this.examRecordDataId },
        })
        .then((response) => {
          this.examRecordData = response.data;
          this.examRecord = response.data.examRecord;
        });
    },
    getCourseInfo() {
      this.$http
        .get("/api/ecs_core/course/" + this.courseId)
        .then((response) => {
          this.courseInfo = response.data;
        });
    },
    getPaperData() {
      this.$http
        .get(
          "/api/ecs_oe_admin/examRecordPaperStruct/getExamRecordPaperStruct",
          { params: { examRecordDataId: this.examRecordDataId } }
        )
        .then((response) => {
          this.paperStruct = response.data.defaultPaper;
          var questionGroupList = this.paperStruct.questionGroupList; //大题集合
          this.$http
            .get(
              "/api/ecs_oe_admin/examRecordQuestions/getExamRecordQuestions",
              {
                params: {
                  examRecordDataId: this.examRecordDataId,
                  withScore: true,
                },
              }
            )
            .then((response) => {
              this.examQuestionList = response.data.examQuestionEntities;
              var order = 0;
              for (var i = 0; i < questionGroupList.length; i++) {
                var questionGroup = questionGroupList[i]; //大题
                var questionWrapperList = questionGroup.questionWrapperList; //大题下小题集合
                for (var j = 0; j < questionWrapperList.length; j++) {
                  var questionWrapper = questionWrapperList[j];
                  order += questionWrapper.questionUnitWrapperList.length;
                  this.getQuestionContent(
                    questionWrapper.questionId,
                    questionWrapper,
                    order
                  );
                }
              }
            });
        });
    },
    getQuestionContent(questionId, questionWrapper, order) {
      var params = {
        examId: this.examRecordData.examRecord.examId,
        courseCode: this.courseInfo.code,
        groupCode: this.examRecordData.examRecord.paperType,
        questionId: questionId,
      };
      this.$http
        .post("/api/ecs_ques/default_question/question/detail", params)
        .then((response) => {
          var question = response.data.masterVersion;
          var questionUnitList = question.questionUnitList;
          var num = questionUnitList.length;
          for (var i = 0; i < questionUnitList.length; i++) {
            this.reOrderOptions(questionUnitList[i], order - num + 1);
            num--;
          }
          var questionList = questionWrapper.questionList;
          if (!questionList) {
            questionList = [];
            questionList.push(question);
          } else {
            questionList.push(question);
          }
          questionWrapper.questionList = questionList;
        });
    },
    reOrderOptions(question, order) {
      question.order = order; //设置序号
      var examQuestion = this.examQuestionList[order - 1];
      var questionScore = examQuestion.questionScore;
      var studentAnswer = examQuestion.studentAnswer;
      if (examQuestion.studentScore || examQuestion.studentScore === 0) {
        question.studentScore = examQuestion.studentScore + "分";
      } else {
        question.studentScore = "--分";
      }

      var correctAnswer = question.rightAnswer; //从题中获取正确答案
      var optionList = question.questionOptionList;
      //单选,多选
      if (optionList && optionList.length > 0) {
        var correctAnswerInExamQuestion =
          this.examQuestionList[order - 1].correctAnswer;
        //如果是字母，说明是旧数据
        var reg = /^[a-zA-Z]+$/; //匹配任意字母
        if (
          correctAnswerInExamQuestion &&
          reg.test(correctAnswerInExamQuestion)
        ) {
          question.studentAnswer = studentAnswer;
          question.correctAnswer = correctAnswerInExamQuestion;
          for (var i1 = 0; i1 < optionList.length; i1++) {
            var letter1 = String.fromCharCode(i1 + 65);
            optionList[i1].letter = letter1;
          }
          question.quesOptions = optionList; //选项
        } else {
          var optionPermutation =
            this.examQuestionList[order - 1].optionPermutation;
          for (var i2 = 0; i2 < optionList.length; i2++) {
            optionList[i2].optionId = i2;
          }
          //按照optionPermutation排序
          var newOptionList = [];
          for (var i3 = 0; i3 < optionPermutation.length; i3++) {
            var optionId = optionPermutation[i3];
            for (var i4 = 0; i4 < optionList.length; i4++) {
              if (optionList[i4].optionId == optionId) {
                newOptionList.push(optionList[i4]);
                break;
              }
            }
          }
          var newCorrectAnswer = []; //正确答案
          var newStudentAnswer = []; //考生作答
          for (var i5 = 0; i5 < newOptionList.length; i5++) {
            var letter = String.fromCharCode(i5 + 65);
            newOptionList[i5].letter = letter;
            if (correctAnswer) {
              //如果是单选题，直接判等
              if (
                question.questionType == "SINGLE_CHOICE" &&
                correctAnswer == newOptionList[i5].optionId.toString()
              ) {
                newCorrectAnswer.push(letter);
              } else if (
                question.questionType != "SINGLE_CHOICE" &&
                correctAnswer.indexOf(newOptionList[i5].optionId.toString()) >
                  -1
              ) {
                newCorrectAnswer.push(letter);
              }
            }
            if (studentAnswer) {
              if (
                question.questionType == "SINGLE_CHOICE" &&
                studentAnswer == newOptionList[i5].optionId.toString()
              ) {
                newStudentAnswer.push(letter);
              } else if (
                question.questionType != "SINGLE_CHOICE" &&
                studentAnswer.indexOf(newOptionList[i5].optionId.toString()) >
                  -1
              ) {
                newStudentAnswer.push(letter);
              }
            }
          }
          question.quesOptions = newOptionList; //选项
          question.correctAnswer = newCorrectAnswer;
          question.studentAnswer = newStudentAnswer;
        }
      } else {
        question.studentAnswer = studentAnswer;
        question.correctAnswer = correctAnswer;
        if (question.questionType == "TRUE_OR_FALSE") {
          if (studentAnswer == "true") {
            question.studentAnswer = "正确";
          } else if (studentAnswer == "false") {
            question.studentAnswer = "错误";
          }
          if (correctAnswer == "true") {
            question.correctAnswer = "正确";
          } else if (correctAnswer == "false") {
            question.correctAnswer = "错误";
          }
        }
      }
      question.questionScore = questionScore;
      if (question.studentAnswer) {
        question.studentAnswer = question.studentAnswer.toString();
      }
      if (question.correctAnswer) {
        question.correctAnswer = question.correctAnswer.toString();
      }
      if (
        question.questionType == "SINGLE_CHOICE" ||
        question.questionType == "MULTIPLE_CHOICE" ||
        question.questionType == "TRUE_OR_FALSE"
      ) {
        question.isObjectiveQuestion = true;
      }
      if (
        question.studentAnswer &&
        question.correctAnswer &&
        question.studentAnswer == question.correctAnswer
      ) {
        question.isRight = true;
      } else {
        question.isRight = false;
      }
      this.$forceUpdate(); //刷新视图
    },
    toChineseNumber(num) {
      return num.toLocaleString("zh-u-nu-hanidec");
    },
    restoreAudio(str) {
      return (str || "")
        .replace(/<a/g, "<audio controls ")
        .replace(/url=/g, "src=")
        .replace(/a>/g, "audio>");
    },
  },
};
</script>

<style scoped>
#paperView {
  background-color: white;
  width: 100vw;
  height: 100vh;
  overflow: auto;
}
#paperName {
  text-align: center;
  font-size: 32px;
  font-weight: bold;
}
#studentInfoDiv {
  text-align: center;
  margin: 20px 60px;
}
#studentInfoTable {
  width: 100%;
  height: 70px;
  border-collapse: collapse;
}
.mainQuestionDiv {
  font-size: 14px;
  margin: 20px 60px;
  border-bottom: 1px dashed black;
}
.el-icon-check {
  color: green;
  font-size: 16px;
  font-weight: bold;
}
.el-icon-close {
  color: red;
  font-size: 16px;
  font-weight: bold;
}
</style>
<style>
.photo-answers-block {
  width: 660px;
  height: 300px;
}
.photo-answer {
  width: 200px;
  height: 150px;
  padding: 5px;
}
</style>
