import Vue from "vue";

/* 单独备份袋设置-归集类型 */
export const groupTypeList = [
  { value: "LEARN_CENTER", label: "学习中心" },
  { value: "EXAM_SITE", label: "考点" },
];

/* 考试课程的试卷状态 */
export const coursePaperStatusList = [
  { value: 0, label: "无" },
  { value: 1, label: "未指定" },
  { value: 2, label: "已有" },
];

Vue.prototype.loadUserRole = function (user) {
  /* 当前用户角色信息 */
  this.curUserRole = {
    /* 是否为印刷总负责人 */
    isSuperLeader: false,
    /* 是否为项目经理 */
    isPM: false,
    /* 是否为印刷供应商 */
    isSupplier: false,
    /* 是否为印刷学校管理员 */
    isSchoolLeader: false,
    /* 其它 */
    isOther: false,
  };

  let roles = user.roleList;
  for (let role of roles) {
    let roleCode = role.roleCode;
    if (roleCode == "PRINT_SUPPLIER") {
      this.curUserRole.isSupplier = true;
    } else if (roleCode == "PRINT_SCHOOL_LEADER") {
      this.curUserRole.isSchoolLeader = true;
    } else if (roleCode == "PRINT_SUPER_LEADER") {
      this.curUserRole.isSuperLeader = true;
    } else if (roleCode == "PRINT_PROJECT_LEADER") {
      this.curUserRole.isPM = true;
    } else {
      this.curUserRole.isOther = true;
    }
  }
};
