import Home from "../../portal/views/home/Home.vue";
import Tips from "../../portal/views/tips/Tips.vue";
import school from "../view/school.vue";
import campus from "../view/campus.vue";
import user from "../view/user.vue";
import data_previllege from "../view/data_previllege.vue";
import specially from "../view/specially.vue";
import course from "../view/course.vue";
import app_list from "../view/app_list.vue";
import privilege_group_list from "../view/privilege_group_list.vue";
import privilege_tree from "../view/privilege_tree.vue";
import role_privilege_settings from "../view/role_privilege_settings.vue";
import school_privilege_settings from "../view/school_privilege_settings.vue";
import resource_list from "../view/resource_list.vue";
import sys_prop_list from "../view/sys_prop_list";
import client_config from "../view/clientConfig";
import school_config from "../view/school_config";
import unimportant_school_config from "../view/unimportant_school_config";
import sys_notice from "../view/sys_notice";
import sysLoginRuleList from "../view/sys_login_rule_list";
import sysCryptoConfig from "../view/sys_crypto_config";
import admin_operate from "../view/admin_operate";
import BlackListApp from "../view/black_list_app";

export default [
  {
    path: "/basic", //基础信息
    component: Home,
    children: [
      {
        path: "tips", //模块说明
        component: Tips,
      },
      {
        path: "course", //课程管理
        meta: { privilegeCodes: "index_course" },
        component: course,
      },
      {
        path: "specially", //专业管理
        meta: { privilegeCodes: "index_specially" },
        component: specially,
      },
      {
        path: "school", //学校管理
        meta: { privilegeCodes: "index_school" },
        component: school,
      },
      {
        path: "campus", //学习中心管理
        meta: { privilegeCodes: "index_campus" },
        component: campus,
      },
      {
        path: "user", //用户管理
        meta: { privilegeCodes: "index_user" },
        component: user,
      },
      {
        path: "user/data_previllege/:userId", //用户管理
        meta: { privilegeCodes: "index_user" },
        component: data_previllege,
      },
      {
        path: "admin_operate", //操作日志
        meta: { privilegeCodes: "index_admin_operate" },
        component: admin_operate,
      },
      {
        path: "app_list", //应用列表
        meta: { pageName: "应用列表" },
        component: app_list,
      },
      {
        path: "privilege_group_list", //权限组列表
        meta: { pageName: "权限组列表" },
        component: privilege_group_list,
      },
      {
        path: "privilege_tree/:privilegeGroupId", //权限树
        meta: { pageName: "权限树" },
        component: privilege_tree,
      },
      {
        path: "role_privilege_settings", //角色权限设置
        meta: { privilegeCodes: "index_role_privilege_settings" },
        component: role_privilege_settings,
      },
      {
        path: "school_privilege_settings", //学校权限设置
        meta: { privilegeCodes: "index_school_privilege_settings" },
        component: school_privilege_settings,
      },
      {
        path: "resource_list", //资源列表
        meta: { privilegeCodes: "index_resource_list" },
        component: resource_list,
      },
      {
        path: "sys_prop_list", //参数配置列表
        meta: { privilegeCodes: "sys_pop_list" },
        component: sys_prop_list,
      },
      {
        path: "client_config",
        meta: { privilegeCodes: "client_config" },
        component: client_config,
      },
      {
        path: "school_config", //学校设置
        meta: { privilegeCodes: "school_config" },
        component: school_config,
      },
      {
        path: "unimportant_school_config", //学校设置
        meta: { privilegeCodes: "unimportant_school_config" },
        component: unimportant_school_config,
      },
      {
        path: "sys_notice", //系统通知
        meta: { privilegeCodes: "sys_notice" },
        component: sys_notice,
      },
      {
        path: "sysLoginRuleList",
        meta: { privilegeCodes: "sys_login_rule_list" },
        component: sysLoginRuleList, //登录规则列表
      },
      {
        path: "sysCryptoConfig",
        meta: { privilegeCodes: "sys_crypto_config" },
        component: sysCryptoConfig, //加密方案组合配置
      },
      {
        path: "black_list_app",
        meta: { privilegeCodes: "black_list_app" },
        component: BlackListApp, // 软件黑名单配置
      },
    ],
  },
];
