<template>
  <div>
    <LinkTitlesCustom :current-paths="['评卷工作', '评卷设置', '设置列表']" />
    <section class="content">
      <div
        v-loading="fileLoading"
        class="box box-info"
        element-loading-text="导入需要较长时间，请耐心等候"
      >
        <div class="box-body">
          <el-form
            :inline="true"
            :model="formSearch"
            label-position="right"
            label-width="40px"
          >
            <el-form-item label="课程" class="pull-left">
              <el-select
                v-model="formSearch.courseCode"
                clearable
                filterable
                class="input"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in courseAllListSelect"
                  :key="item.code"
                  :label="item.courseInfo"
                  :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button
                size="small"
                type="primary"
                icon="el-icon-search"
                @click="initSetting"
                >查询</el-button
              >
              <el-button
                size="small"
                type="primary"
                icon="el-icon-arrow-left"
                @click="back"
                >返回</el-button
              >
              <el-dropdown
                style="margin-left: 10px"
                placement="bottom-start"
                @command="handleCommand"
              >
                <el-button size="small" type="primary">
                  更多 <i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="in">
                    <el-button
                      size="small"
                      type="primary"
                      icon="el-icon-upload2"
                      >导入</el-button
                    >
                  </el-dropdown-item>
                  <el-dropdown-item command="out">
                    <el-button
                      size="small"
                      type="primary"
                      icon="el-icon-download"
                      >导出</el-button
                    >
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </el-form-item>
          </el-form>
          <!-- 导入弹窗 -->
          <el-dialog title="导入窗口" size="tiny" :visible.sync="impDialog">
            <el-form>
              <el-row>
                <el-form-item>
                  <el-upload
                    ref="upload"
                    class="form_left"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    :action="uploadAction"
                    :headers="uploadHeaders"
                    :data="uploadData"
                    :before-upload="beforeUpload"
                    :on-progress="uploadProgress"
                    :on-success="uploadSuccess"
                    :on-error="uploadError"
                    :file-list="fileList"
                    :auto-upload="false"
                    :multiple="false"
                  >
                    <el-button slot="trigger" type="primary" size="small"
                      >选择文件</el-button
                    >
                    <el-button
                      size="small"
                      style="margin-left: 5px"
                      type="success"
                      @click="submitUpload"
                      >确认上传</el-button
                    >
                    <el-button
                      size="small"
                      style="margin-left: 5px"
                      type="danger"
                      @click="removeFile"
                      >清空文件</el-button
                    >
                    <el-button
                      size="small"
                      style="margin-left: 5px"
                      type="info"
                      @click="exportFile"
                      >下载模板</el-button
                    >
                    <div slot="tip" class="el-upload__tip">
                      只能上传xlsx文件
                    </div>
                  </el-upload>
                </el-form-item>
              </el-row>
            </el-form>
          </el-dialog>
          <!-- 导入错误信息列表 -->
          <el-dialog title="错误提示" :visible.sync="errDialog">
            <div
              v-for="errMessage in errMessages"
              :key="errMessage.row"
              class="text-danger"
            >
              第{{ errMessage.row }}行：{{ errMessage.excelErrorType }}
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button @click="errDialog = false">确定</el-button>
            </span>
          </el-dialog>
          <div class="block-seperator"></div>
          <!-- 页面列表 -->
          <el-table
            v-loading="loading"
            stripe
            element-loading-text="拼命加载中"
            :data="tableData"
            border
            style="width: 100%"
          >
            <el-table-column
              label="课程名称"
              width="200"
              prop="name"
            ></el-table-column>
            <el-table-column
              label="课程代码"
              min-width="100"
              prop="code"
            ></el-table-column>
            <el-table-column
              label="试卷数"
              min-width="100"
              prop="totalCount"
            ></el-table-column>
            <el-table-column
              label="评卷员数"
              min-width="100"
              prop="markerCount"
            ></el-table-column>
            <el-table-column
              label="给分间隔"
              min-width="100"
              prop="scoreStep"
            ></el-table-column>
            <el-table-column :context="_self" width="250" label="操作">
              <template slot-scope="scope">
                <div class="pull-left">
                  <el-button
                    v-if="scope.row.totalCount > 0"
                    type="primary"
                    size="mini"
                    plain
                    @click="fastSetting(scope.$index, scope.row)"
                    >快速设置</el-button
                  >
                  <el-button
                    type="primary"
                    size="mini"
                    plain
                    @click="openStepModel(scope.row)"
                    >给分间隔设置</el-button
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="page pull-right">
            <el-pagination
              background
              :current-page="currentPage"
              :page-size="pageSize"
              :page-sizes="[10, 20, 50, 100, 200, 300]"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              @current-change="handleSettingCurrentChange"
              @size-change="handleSizeChange"
            ></el-pagination>
          </div>
        </div>
        <el-dialog
          title="设置给分间隔"
          width="500px"
          :visible.sync="stepModel"
          :close-on-click-modal="false"
          @close="closeStepModel"
        >
          <el-form
            ref="stepForm"
            :key="stepModelKey"
            :inline="true"
            :model="stepForm"
            :rules="stepRules"
            label-width="90px"
          >
            <el-row>
              <el-form-item label="给分间隔" prop="step">
                <el-input-number
                  v-model="stepForm.step"
                  size="mini"
                  :precision="1"
                  :step="0.1"
                  :min="0.1"
                  :max="100"
                ></el-input-number>
              </el-form-item>
            </el-row>
            <el-row class="pull-center">
              <el-button
                type="primary"
                :loading="stepForm.loading"
                @click="subStep"
                >确定</el-button
              >
              <el-button @click="closeStepModel">取消</el-button>
            </el-row>
          </el-form>
        </el-dialog>
      </div>
    </section>
  </div>
</template>

<script>
import { MARKING_API, MARKING_LOGIC_API } from "@/constants/constants";
import { mapState } from "vuex";
import LinkTitlesCustom from "@/components/LinkTitlesCustom.vue";
export default {
  components: {
    LinkTitlesCustom,
  },
  data() {
    return {
      formSearch: {
        courseCode: "",
        courseName: "",
        specialtyLevel: "",
        specialtyName: "",
        leader: "",
      },
      courseList: [],
      tableData: [],
      oldData: [],
      currentPage: 1,
      pageSize: 10,
      total: 10,
      loading: true,
      workId: "",
      markTasks: {
        workId: "",
        userId: [],
        paperId: "",
      },
      stepModel: false,
      stepModelKey: Math.random(),
      stepForm: {
        workId: null,
        courseCode: null,
        step: null,
        loading: false,
      },
      paperId: "",
      impDialog: false,
      uploadAction: MARKING_LOGIC_API + "/markTasks/import",
      uploadHeaders: {},
      uploadData: { workId: "" },
      errMessages: [],
      errDialog: false,
      fileLoading: false,
      fileList: [],
      stepRules: {
        step: [
          { required: true, message: "请输入给分间隔", trigger: "change" },
        ],
      },
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
    courseAllListSelect() {
      let courseSelect = [];
      for (let course of this.courseList) {
        let courseInfo = course.name + "(" + course.code + ")";
        courseSelect.push({ code: course.code, courseInfo: courseInfo });
      }
      return courseSelect;
    },
  },
  created() {
    this.workId = this.$route.params.workId;
    this.uploadData.workId = this.workId;
    this.uploadHeaders = {
      key: this.user.key,
      token: this.user.token,
    };
    this.initSetting();
    this.getCourses();
  },
  methods: {
    openStepModel(row) {
      this.stepForm.step = row.scoreStep || 0.5;
      this.stepForm.workId = row.workId;
      this.stepForm.courseCode = row.code;
      this.stepModel = true;
    },
    closeStepModel() {
      this.stepModel = false;
      this.stepModelKey = Math.random();
    },
    async subStep() {
      let res = await this.$refs.stepForm.validate();
      if (!res) {
        return;
      }
      this.stepForm.loading = true;
      var url =
        MARKING_API +
        "/markCourses/score-step?workId=" +
        this.stepForm.workId +
        "&courseCode=" +
        this.stepForm.courseCode +
        "&scoreStep=" +
        this.stepForm.step;
      this.$httpWithMsg
        .put(url)
        .then(() => {
          this.$notify({
            type: "success",
            message: "设置成功！",
          });
          this.closeStepModel();
          this.initSetting();
        })
        .finally(() => (this.stepForm.loading = false));
    },
    //查询课程
    getCourses() {
      this.$http
        .get(
          MARKING_API +
            "/markResults/queryExamCourseList?workId=" +
            this.$route.params.workId
        )
        .then((response) => {
          this.courseList = response.data;
        });
    },
    fastSetting(index, row) {
      this.paperId = row.pk;
      var url =
        "/marking/mark_setting_fast/" +
        this.$route.params.workId +
        "/" +
        encodeURIComponent(this.$route.params.name) +
        "/" +
        encodeURIComponent(row.code) +
        "/" +
        encodeURIComponent(row.name);
      this.$router.push({
        path: url,
      });
    },
    filterSetting() {
      if (!this.formSearch.courseCode && !this.formSearch.courseName) {
        return;
      }
      var tempData = this.tableData.filter((element) => {
        var flag = true;
        if (this.formSearch.courseCode || this.formSearch.courseName) {
          if (this.formSearch.courseCode) {
            flag =
              flag && element.courseCode.includes(this.formSearch.courseCode);
          }
          if (this.formSearch.courseName) {
            flag =
              flag && element.courseName.includes(this.formSearch.courseName);
          }
          return flag;
        } else {
          return true;
        }
      });
      this.tableData = tempData;
      this.total = tempData.length;
    },
    handleSettingCurrentChange(val) {
      this.currentPage = val;
      this.initSetting();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.initSetting();
    },
    pagingSetting() {
      var start = (this.currentPage - 1) * this.pageSize;
      var end =
        this.currentPage * this.pageSize < this.total
          ? this.currentPage * this.pageSize
          : this.total;
      var tempData = [];
      console.log(`当前页: ${this.currentPage},开始:${start},结束:${end}`);
      for (let i = start; i < end; i++) {
        tempData.push(this.tableData[i]);
      }
      console.log(tempData);
      this.tableData = tempData;
    },
    initSetting() {
      this.loading = true;
      this.$http
        .get(
          MARKING_API +
            "/markCourses/all/" +
            (this.currentPage - 1) +
            "/" +
            this.pageSize +
            "?workId=" +
            this.workId,
          { params: this.formSearch }
        )
        .then((response) => {
          console.log(response.data);
          this.tableData = response.data.content;
          this.total = response.data.totalElements;
          this.oldData = this.tableData.slice(0);
          this.loading = false;
        });
    },
    searchSetting() {
      this.loading = true;
      this.tableData = this.oldData.slice(0);
      this.total = this.tableData.length;
      this.filterSetting();
      this.pagingSetting();
      this.loading = false;
    },
    back() {
      this.$router.push({
        path: "/marking/mark_work",
      });
    },
    //导入
    imp() {
      this.impDialog = true;
      this.initUpload();
    },
    initUpload() {
      this.fileList = [];
    },
    beforeUpload(file) {
      console.log(file);
    },
    uploadProgress() {
      console.log("uploadProgress");
    },
    uploadSuccess(response) {
      console.log("uploadSuccess");
      console.log(response);
      if (!response || response.length == 0) {
        this.$notify({
          message: "上传成功",
          type: "success",
        });
        this.fileLoading = false;
        this.impDialog = false;
        window.location.reload();
      } else {
        this.errMessages = response;
        this.errDialog = true;
        this.fileLoading = false;
        this.impDialog = false;
      }
    },
    uploadError(err) {
      var result = err.message.match(/\{.+}/);
      var errMessage = JSON.parse(result[0]).desc;
      this.$notify({
        message: errMessage,
        type: "error",
      });
      this.fileLoading = false;
    },
    //确定上传
    submitUpload() {
      if (!this.checkUpload()) {
        return false;
      }
      this.$refs.upload.submit();
      this.fileLoading = true;
    },
    checkUpload() {
      var fileList = this.$refs.upload.uploadFiles;
      if (fileList.length == 0) {
        this.$notify({
          message: "上传文件不能为空",
          type: "error",
        });
        return false;
      }
      if (fileList.length > 1) {
        this.$notify({
          message: "每次只能上传一个文件",
          type: "error",
        });
        return false;
      }
      for (let file of fileList) {
        if (!file.name.endsWith(".xlsx")) {
          this.$notify({
            message: "上传文件必须为xlsx格式",
            type: "error",
          });
          this.initUpload();
          return false;
        }
      }
      return true;
    },
    //清空文件
    removeFile() {
      this.fileList = [];
    },
    //下载模板
    exportFile() {
      var key = this.user.key;
      var token = this.user.token;
      window.location.href =
        "/api/ecs_marking/logic/markTasks/export?$key=" +
        key +
        "&$token=" +
        token;
    },
    //下载课程列表
    exportCourse() {
      var key = this.user.key;
      var token = this.user.token;
      let url =
        "/api/ecs_marking/markCourses/export?workId=" +
        this.workId +
        "&$key=" +
        key +
        "&$token=" +
        token;
      let courseCode = this.formSearch.courseCode;
      if (courseCode) {
        url = url + "&courseCode=" + courseCode;
      }
      window.location.href = url;
    },
    handleCommand(buttonType) {
      if (buttonType == "in") this.imp();
      else if (buttonType == "out") this.exportCourse();
    },
  },
};
</script>
<style lang="css" scoped>
li {
  list-style-type: none;
}

.searchFrame {
  margin-right: 10px;
  margin-bottom: 10px;
}

.page {
  margin-top: 10px;
}
.f_button {
  display: block;
  width: 57px;
  height: 20px;
  border: 1px solid #ccc;
  background: #fff;
  font-size: small;
}
</style>
