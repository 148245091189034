<template>
  <section class="content" style="margin-top: 20px">
    <div class="box box-info">
      <!-- 正文信息 -->
      <div class="box-body">
        <el-form
          :model="formSearch"
          :inline="true"
          label-position="right"
          label-width="90px"
          class="el-form el-form--inline"
          style="
            border-bottom: 1px solid rgb(221, 221, 221);
            padding-bottom: 10px;
            margin-bottom: 10px;
          "
        >
          <el-form-item label="学校">
            <el-select
              v-model="formSearch.orgId"
              placeholder="请选择"
              @change="loadExamList(formSearch.orgId)"
            >
              <el-option
                v-for="item in orgList"
                :key="item.orgId"
                :label="item.orgName"
                :value="item.orgId"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="考试">
            <el-select
              v-model="formSearch.examId"
              placeholder="请选择"
              @change="searchRecords(1)"
            >
              <el-option
                v-for="item in examList"
                :key="item.examId"
                :label="item.examName"
                :value="item.examId"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="试卷状态">
            <el-select
              v-model="formSearch.paperStatus"
              clearable
              placeholder="请选择"
            >
              <el-option label="请选择" value=""></el-option>
              <el-option
                v-for="item in coursePaperStatusList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="课程名称">
            <el-select
              v-model="formSearch.courseId"
              placeholder="请选择"
              filterable
              clearable
              remote
              :remote-method="loadCourseList"
            >
              <el-option label="请选择" value=""></el-option>
              <el-option
                v-for="item in courseList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="试卷名称">
            <el-input
              v-model="formSearch.paperName"
              style="width: 221px"
              clearable
            ></el-input>
          </el-form-item>

          <el-form-item style="padding-left: 22px">
            <el-button
              size="small"
              type="primary"
              icon="el-icon-search"
              @click="searchRecords(1)"
              >查询
            </el-button>

            <el-button
              size="small"
              icon="el-icon-refresh"
              @click="resetSearchForm"
            >
              重置
            </el-button>
          </el-form-item>
        </el-form>

        <div style="margin-bottom: 5px">
          操作：
          <el-button
            size="small"
            type="primary"
            icon="el-icon-menu"
            :disabled="!hasPermit"
            @click="openAllotAllDialog"
            >分配待指定试卷
          </el-button>

          <el-button
            size="small"
            type="primary"
            icon="el-icon-download"
            @click="openExportAllDialog"
            >整体导出
          </el-button>

          <el-button
            size="small"
            type="primary"
            icon="el-icon-download"
            :disabled="selectElements.length == 0"
            @click="openExportDialog"
            >批量导出
          </el-button>

          <el-button
            size="small"
            type="primary"
            icon="el-icon-refresh"
            :disabled="selectElements.length == 0"
            @click="refreshStatistic"
            >统计刷新
          </el-button>
        </div>

        <!-- 数据列表 -->
        <el-table
          v-loading="loading"
          :data="tableData"
          element-loading-text="数据加载中"
          style="width: 100%"
          border
          stripe
          @selection-change="handleSelection"
        >
          <el-table-column type="selection" width="40"> </el-table-column>
          <el-table-column label="课程名称" prop="courseName" sortable />
          <el-table-column
            width="140px"
            label="课程代码"
            prop="courseCode"
            sortable
          />
          <el-table-column
            width="102px"
            label="试卷类型"
            prop="paperType"
            sortable
          />
          <el-table-column width="102px" label="试卷状态" sortable>
            <template slot-scope="scope">
              <span
                style="cursor: pointer"
                @click="openAllotDialog(scope.row)"
                >{{ scope.row.paperStatusName }}</span
              >
            </template>
          </el-table-column>
          <el-table-column label="试卷名称" prop="paperName" sortable />
          <el-table-column
            width="102px"
            label="试卷P数"
            prop="paperP"
            sortable
          />
          <el-table-column
            width="102px"
            label="考生人数"
            prop="totalStudent"
            sortable
          />

          <el-table-column width="180px" label="操作" :context="_self">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="primary"
                icon="el-icon-download"
                :disabled="scope.row.paperStatus <= 1"
                plain
                @click="openExportDialog(scope.row)"
                >下载
              </el-button>

              <el-dropdown style="margin-left: 10px">
                <el-button
                  size="mini"
                  type="primary"
                  :disabled="scope.row.paperStatus == 0"
                  plain
                >
                  更多<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>

                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <el-button
                      size="mini"
                      type="primary"
                      icon="el-icon-view"
                      :disabled="scope.row.paperStatus <= 1"
                      plain
                      @click="preview(scope.row)"
                      >试卷预览
                    </el-button>
                  </el-dropdown-item>

                  <el-dropdown-item>
                    <el-button
                      size="mini"
                      type="primary"
                      icon="el-icon-menu"
                      :disabled="!hasPermit"
                      plain
                      @click="openAllotDialog(scope.row)"
                      >试卷指定
                    </el-button>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>

        <!-- 分页 -->
        <div class="page pull-right">
          <el-pagination
            :current-page="formSearch.pageNo"
            :page-size="formSearch.pageSize"
            :page-sizes="[10, 20, 50, 100, 200, 300]"
            :total="totalElements"
            layout="total, sizes, prev, pager, next, jumper"
            @current-change="handlePagerNo"
            @size-change="handlePagerSize"
          ></el-pagination>
        </div>
      </div>

      <el-dialog
        title="试卷指定"
        width="380px"
        :visible.sync="allotDialog"
        @close="closeAllotDialog"
      >
        <el-form
          ref="allotForm"
          :model="allotForm"
          :rules="rules"
          label-position="right"
          label-width="80px"
          inline-message
        >
          <el-form-item label="课程名称" prop="courseName">
            <el-input
              v-model="allotForm.courseName"
              :disabled="true"
              class="w220"
            >
              {{ allotForm.courseName }}</el-input
            >
          </el-form-item>

          <el-form-item label="试卷类型" prop="paperType">
            <el-input
              v-model="allotForm.paperType"
              :disabled="true"
              class="w220"
            >
              {{ allotForm.paperType }}</el-input
            >
          </el-form-item>

          <el-form-item label="试卷名称" prop="coursePaperId">
            <el-select
              v-model="allotForm.coursePaperId"
              placeholder="请选择"
              class="w220"
            >
              <el-option
                v-for="item in coursePaperList"
                :key="item.coursePaperId"
                :value="item.coursePaperId"
                :label="item.paperName"
              ></el-option>
            </el-select>
          </el-form-item>

          <div style="text-align: center">
            <el-button type="primary" @click="saveAllot">确 定</el-button>
            <el-button @click="closeAllotDialog">取 消</el-button>
          </div>
        </el-form>
      </el-dialog>

      <el-dialog
        title="试卷整体分配"
        width="400px"
        :visible.sync="allotAllDialog"
        @close="closeAllotAllDialog"
      >
        <el-form
          ref="allotAllForm"
          :model="allotAllForm"
          :rules="rules"
          label-position="right"
          label-width="80px"
          inline-message
        >
          <el-form-item label="学校名称" prop="orgId">
            <el-select
              v-model="allotAllForm.orgId"
              placeholder="请选择"
              class="w220"
              @change="loadAllotExamList(allotAllForm.orgId)"
            >
              <el-option
                v-for="item in orgList"
                :key="item.orgId"
                :label="item.orgName"
                :value="item.orgId"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="考试名称" prop="examId">
            <el-select
              v-model="allotAllForm.examId"
              placeholder="请选择"
              class="w220"
            >
              <el-option
                v-for="item in allotExamList"
                :key="item.examId"
                :label="item.examName"
                :value="item.examId"
              ></el-option>
            </el-select>
          </el-form-item>

          <div style="text-align: center">
            <p style="color: red">
              警告：确定后当前考试中“未指定试卷”的课程会在已有的试卷中随机选取一套试卷分配关联，是否确定执行？
            </p>
            <el-button
              type="primary"
              :disabled="allotAllForm.examId == ''"
              @click="saveAllotAll"
              >确 定</el-button
            >
            <el-button @click="closeAllotAllDialog">取 消</el-button>
          </div>
        </el-form>
      </el-dialog>

      <el-dialog
        title="导出"
        width="450px"
        :visible.sync="exportDialog"
        @close="closeExportDialog"
      >
        <el-form
          ref="exportForm"
          :model="exportForm"
          :rules="rules"
          label-position="right"
          label-width="90px"
          inline-message
        >
          <el-form-item label="导出内容" prop="types">
            <el-checkbox-group v-model="exportForm.types">
              <el-checkbox label="1">试卷</el-checkbox>
              <el-checkbox label="2">答案</el-checkbox>
              <el-checkbox label="3">试卷结构</el-checkbox>
            </el-checkbox-group>
          </el-form-item>

          <div style="text-align: center">
            <el-button
              type="primary"
              :disabled="exportForm.types.length == 0"
              @click="doExport"
              >确定</el-button
            >
            <el-button @click="closeExportDialog">取 消</el-button>
          </div>
        </el-form>
      </el-dialog>

      <el-dialog
        title="试卷整体导出"
        width="430px"
        :visible.sync="exportAllDialog"
        @close="closeExportAllDialog"
      >
        <el-form
          ref="exportAllForm"
          :model="exportAllForm"
          :rules="rules"
          label-position="right"
          label-width="90px"
          inline-message
        >
          <el-form-item label="学校名称" prop="orgId">
            <el-select
              v-model="exportAllForm.orgId"
              placeholder="请选择"
              class="w245"
              @change="loadExportExamList(exportAllForm.orgId)"
            >
              <el-option
                v-for="item in orgList"
                :key="item.orgId"
                :label="item.orgName"
                :value="item.orgId"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="考试名称" prop="examId">
            <el-select
              v-model="exportAllForm.examId"
              placeholder="请选择"
              class="w245"
              @change="loadExamCoursePaperTotal"
            >
              <el-option
                v-for="item in exportExamList"
                :key="item.examId"
                :label="item.examName"
                :value="item.examId"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="导出内容" prop="types">
            <el-checkbox-group v-model="exportAllForm.types">
              <el-checkbox label="1">试卷</el-checkbox>
              <el-checkbox label="2">答案</el-checkbox>
              <el-checkbox label="3">试卷结构</el-checkbox>
            </el-checkbox-group>
          </el-form-item>

          <el-form-item label="试卷总数" prop="allNum">
            {{ exportAllForm.allNum }}
          </el-form-item>

          <el-form-item label="已有试卷数" prop="existNum">
            {{ exportAllForm.existNum }}
          </el-form-item>

          <el-form-item label="缺少试卷数" prop="missNum">
            {{ exportAllForm.missNum }}
          </el-form-item>

          <div style="text-align: center">
            <el-button
              type="primary"
              :disabled="exportAllForm.existNum == 0"
              @click="doExportAll"
              >确定</el-button
            >
            <el-button @click="closeExportAllDialog">取 消</el-button>
          </div>
        </el-form>
      </el-dialog>
    </div>
  </section>
</template>

<script>
import { CORE_API, PRINT_API } from "@/constants/constants";
import { coursePaperStatusList } from "../constants/constants.js";
import { mapState } from "vuex";
import { checkEmptyStr, checkEmptyNumber } from "../utils/common.js";

export default {
  data() {
    return {
      formSearch: {
        orgId: "",
        examId: "",
        courseId: "",
        paperStatus: "",
        paperName: "",
        pageNo: 1,
        pageSize: 10,
      },
      curUserRole: {},
      hasPermit: false,
      totalElements: 0,
      loading: false,
      tableData: [],
      orgList: [],
      examList: [],
      courseList: [],
      coursePaperList: [],
      coursePaperStatusList: coursePaperStatusList,
      allotExamList: [],
      allotDialog: false,
      allotForm: {
        id: "",
        coursePaperId: "",
        courseName: "",
        paperType: "",
      },
      allotAllDialog: false,
      allotAllForm: {
        orgId: "",
        examId: "",
      },
      selectElements: [],
      exportExamList: [],
      exportDialog: false,
      exportForm: {
        ids: [],
        types: [],
      },
      exportAllDialog: false,
      exportAllForm: {
        orgId: "",
        examId: "",
        types: [],
        allNum: 0,
        existNum: 0,
        missNum: 0,
      },
      rules: {
        orgId: [
          { required: true, message: "学校不能为空！", trigger: "change" },
        ],
        examId: [
          { required: true, message: "考试不能为空！", trigger: "change" },
        ],
        coursePaperId: [
          { required: true, message: "试卷不能为空！", trigger: "change" },
        ],
        courseName: [
          { required: true, message: "课程不能为空！", trigger: "change" },
        ],
        paperType: [
          { required: true, message: "试卷类型不能为空！", trigger: "change" },
        ],
        types: [
          { required: true, message: "请选择导出内容！", trigger: "change" },
        ],
      },
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
  },
  created() {
    this.loadOrgList();

    this.loadUserRole(this.user);

    if (this.curUserRole.isSuperLeader || this.curUserRole.isPM) {
      this.hasPermit = true;
    } else {
      this.hasPermit = false;
    }
  },
  methods: {
    handleSelection(elements) {
      /* 处理选中记录 */
      this.selectElements = elements;
    },
    handlePagerNo(pageNo) {
      /* 处理分页 */
      this.searchRecords(pageNo);
    },
    handlePagerSize(pageSize) {
      /* 处理每页条数 */
      this.formSearch.pageSize = pageSize;
      this.searchRecords(1);
    },
    resetSearchForm() {
      this.formSearch.courseId = "";
      this.formSearch.paperStatus = "";
      this.formSearch.paperName = "";
      //this.searchRecords(1);
    },
    searchRecords(pageNo) {
      this.formSearch.pageNo = pageNo;

      /* 查询记录列表 */
      let orgId = this.formSearch.orgId;
      if (checkEmptyNumber(orgId)) {
        this.$notify({
          title: "提示",
          message: "请选择学校！",
          type: "warning",
        });
        return;
      }

      let examId = this.formSearch.examId;
      if (checkEmptyNumber(examId)) {
        this.$notify({
          title: "提示",
          message: "请选择考试！",
          type: "warning",
        });
        return;
      }

      this.loading = true;
      let url = PRINT_API + "/course/statistic/list";
      this.$http.post(url, this.formSearch).then(
        (response) => {
          this.tableData = response.data.content;
          this.totalElements = response.data.totalElements;
          this.loading = false;
        },
        (error) => {
          console.log(error);
          this.loading = false;
        }
      );
    },
    selectDefault() {
      if (this.orgList.length > 0) {
        let firstOrgId = this.orgList[0].orgId;
        this.formSearch.orgId = firstOrgId;
        this.loadExamList(firstOrgId);
      }
    },
    loadOrgList() {
      /* 查询学校列表 */
      let url = PRINT_API + "/printing/project/org/list";
      this.$http.post(url).then(
        (response) => {
          this.orgList = response.data;
          this.selectDefault();
        },
        (error) => {
          console.log(error.response);
          // ignore
        }
      );
    },
    loadExamList(orgId) {
      /* 查询考试列表 */
      this.formSearch.examId = "";
      this.examList = [];
      this.tableData = [];
      if (checkEmptyNumber(orgId)) {
        return;
      }

      let url = PRINT_API + "/printing/project/exam/list?orgId=" + orgId;
      this.$http.post(url).then((response) => {
        this.examList = response.data;
        if (this.examList.length > 0) {
          this.formSearch.examId = this.examList[0].examId;
          this.searchRecords(1);
        }
      });
    },
    loadAllotExamList(orgId) {
      /* 查询考试列表 */
      this.allotAllForm.examId = "";
      this.allotExamList = [];

      if (!checkEmptyNumber(orgId)) {
        let url = PRINT_API + "/printing/project/exam/list?orgId=" + orgId;
        this.$http.post(url).then((response) => {
          this.allotExamList = response.data;
        });
      }
    },
    loadExportExamList(orgId) {
      /* 查询考试列表 */
      this.exportAllForm.examId = "";
      this.exportExamList = [];

      if (!checkEmptyNumber(orgId)) {
        let url = PRINT_API + "/printing/project/exam/list?orgId=" + orgId;
        this.$http.post(url).then((response) => {
          this.exportExamList = response.data;
        });
      }
    },
    loadCourseList(query) {
      /* 查询课程列表 */
      this.courseList = [];
      let url = CORE_API + "/course/query?enable=true";

      let orgId = this.formSearch.orgId;
      if (!checkEmptyNumber(orgId)) {
        url += "&rootOrgId=" + orgId;
      }

      if (!checkEmptyStr(query)) {
        url += "&name=" + query;
      }

      this.$http.get(url).then((response) => {
        this.courseList = response.data;
      });
    },
    refreshStatistic() {
      let checkUrl = PRINT_API + "/course/statistic/refresh/check";
      this.$http.get(checkUrl).then((response) => {
        if (response.data.data) {
          this.$notify({
            title: "提示",
            message: "尚有课程统计任务正在刷新中，请稍后再刷新！",
            type: "warning",
          });
        } else {
          /* 刷新当前统计信息 */
          this.$confirm("刷新当前统计信息？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              if (this.selectElements.length == 0) {
                this.$notify({
                  title: "提示",
                  message: "请选择要刷新的项！",
                  type: "warning",
                });
                return;
              }

              let params = {};
              params.orgId = this.selectElements[0].orgId;
              params.examId = this.selectElements[0].examId;
              params.courses = [];
              this.selectElements.forEach((element) => {
                params.courses.push({
                  courseId: element.courseId,
                  paperType: element.paperType,
                });
              });

              let url = PRINT_API + "/course/statistic/refresh";
              this.$http.post(url, params).then(
                () => {
                  this.$notify({
                    title: "提示",
                    type: "success",
                    message: "刷新成功！",
                  });
                  this.searchRecords(this.formSearch.pageNo);
                },
                (error) => {
                  console.log(error.response);
                  this.$notify({
                    title: "错误",
                    type: "error",
                    message: error.response.data.desc,
                  });
                }
              );
            })
            .catch(() => {
              //ignore
            });
        }
      });
    },
    preview(row) {
      /* 预览试卷PDF */
      let url = row.paperPdfUrl;
      if (!url) {
        this.$notify({
          title: "提示",
          message: "当前试卷不存在！",
          type: "warning",
        });
        return;
      }
      this.$confirm("确定预览？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          window.open(url);
        })
        .catch(() => {
          /*ignore*/
        });
    },
    openAllotDialog(row) {
      /* 试卷状态为"已有"时，打开试卷指定弹窗 */
      if (!this.hasPermit) {
        console.log("no permit.");
        return;
      }
      if (row.paperStatus > 0) {
        this.allotDialog = true;
        this.allotForm.id = row.id;
        this.allotForm.coursePaperId = row.coursePaperId;
        this.allotForm.courseName = row.courseName;
        this.allotForm.paperType = row.paperType;

        let params = {
          orgId: row.orgId,
          examId: row.examId,
          courseId: row.courseId,
        };
        let url = PRINT_API + "/course/paper/list";
        this.$http.post(url, params).then(
          (response) => {
            this.coursePaperList = response.data;
          },
          (error) => {
            console.log(error);
          }
        );
      }
    },
    closeAllotDialog() {
      /* 关闭试卷指定弹窗 */
      this.allotDialog = false;
    },
    saveAllot() {
      /* 保存试卷指定 */
      this.$refs.allotForm.validate((valid) => {
        if (!valid) {
          return false;
        }

        let curLoading = this.$loading({
          lock: true,
          text: "数据保存中！",
        });

        let url =
          PRINT_API +
          "/course/paper/allot/" +
          this.allotForm.id +
          "/" +
          this.allotForm.coursePaperId;
        this.$http.post(url).then(
          () => {
            curLoading.close();
            this.$notify({
              title: "提示",
              message: "试卷指定成功！",
              type: "success",
            });
            this.allotDialog = false;
            this.searchRecords(this.formSearch.pageNo);
          },
          (error) => {
            console.log(error.response);
            curLoading.close();
            this.$notify({
              title: "错误",
              type: "error",
              message: error.response.data.desc,
            });
          }
        );
      });
    },
    openAllotAllDialog() {
      /* 打开试卷整体分配弹窗 */
      this.allotAllDialog = true;
    },
    closeAllotAllDialog() {
      /* 关闭试卷整体分配弹窗 */
      this.allotAllDialog = false;
    },
    saveAllotAll() {
      /* 保存试卷整体分配 */
      this.$refs.allotAllForm.validate((valid) => {
        if (!valid) {
          return false;
        }

        let curLoading = this.$loading({
          lock: true,
          text: "数据保存中！",
        });

        let url =
          PRINT_API +
          "/course/paper/allot/all/" +
          this.allotAllForm.orgId +
          "/" +
          this.allotAllForm.examId;

        this.$http.post(url).then(
          () => {
            curLoading.close();
            this.$notify({
              title: "提示",
              message: "试卷整体分配成功！",
              type: "success",
            });
            this.allotAllDialog = false;
            this.searchRecords(this.formSearch.pageNo);
          },
          (error) => {
            console.log(error.response);
            curLoading.close();
            this.$notify({
              title: "提示",
              type: "warning",
              message: error.response.data.desc,
            });
          }
        );
      });
    },
    openExportDialog(row) {
      /* 打开批量导出弹窗 */
      this.exportDialog = true;
      this.exportForm.ids = [];
      if (row.id) {
        this.exportForm.ids.push(row.id);
      }
    },
    closeExportDialog() {
      /* 关闭批量导出弹窗 */
      this.exportDialog = false;
    },
    doExport() {
      /* 批量导出 */
      let ids = [];
      if (this.exportForm.ids.length > 0) {
        ids = this.exportForm.ids;
      } else {
        if (this.selectElements.length == 0) {
          this.$notify({
            title: "提示",
            message: "请选择要导出的项！",
            type: "warning",
          });
          return;
        }

        this.selectElements.forEach((element) => {
          if (element.paperStatus > 1) {
            ids.push(element.id);
          }
        });
      }

      this.$refs.exportForm.validate((valid) => {
        if (!valid) {
          return false;
        }

        if (ids.length == 0) {
          this.$notify({
            title: "提示",
            message: "包含未被指定试卷的课程则不可导出！",
            type: "warning",
          });
          return;
        }

        this.closeExportDialog();
        let url =
          PRINT_API +
          "/course/paper/export/batch?ids=" +
          ids.join(",") +
          "&types=" +
          this.exportForm.types.join(",");

        //console.log(url);
        window.location.href = url;
      });
    },
    openExportAllDialog() {
      /* 打开整体导出弹窗 */
      this.exportAllDialog = true;
    },
    closeExportAllDialog() {
      /* 关闭整体导出弹窗 */
      this.exportAllDialog = false;
    },
    doExportAll() {
      /* 整体导出 */
      this.$refs.exportAllForm.validate((valid) => {
        if (!valid) {
          return false;
        }
        this.closeExportAllDialog();
        let url =
          PRINT_API +
          "/course/paper/export/all?orgId=" +
          this.exportAllForm.orgId +
          "&examId=" +
          this.exportAllForm.examId +
          "&types=" +
          this.exportAllForm.types.join(",");

        //console.log(url);
        window.location.href = url;
      });
    },
    loadExamCoursePaperTotal() {
      /* 查询考试的试卷数量情况 */
      let url =
        PRINT_API +
        "/course/paper/total/" +
        this.exportAllForm.orgId +
        "/" +
        this.exportAllForm.examId;
      this.$http.post(url).then(
        (response) => {
          let data = response.data;
          this.exportAllForm.allNum = data.allNum;
          this.exportAllForm.existNum = data.existNum;
          this.exportAllForm.missNum = data.missNum;
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
};
</script>

<style scoped>
.page {
  margin-top: 10px;
}
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
.w220 {
  width: 220px;
}
.w245 {
  width: 245px;
}
</style>
