<template>
  <section class="content">
    <div class="box box-info">
      <div
        v-loading.body="fileLoading"
        v-loading.fullscreen="fileLoading"
        class="box-body"
        element-loading-text="请稍后..."
      >
        <!-- 表单 -->
        <el-form inline :model="formSearch">
          <el-form-item v-if="isSuperAdmin" label="学校">
            <el-select
              v-model="formSearch.parentId"
              placeholder="请选择"
              :disabled="!isSuperAdmin"
              style="width: 180px"
              filterable
            >
              <el-option
                v-for="item in rootSchoolSelect"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="中心代码">
            <el-input
              v-model="formSearch.code"
              placeholder="请输入学习中心代码"
              style="width: 180px"
            />
          </el-form-item>
          <el-form-item label="中心名称">
            <el-input
              v-model="formSearch.name"
              placeholder="请输入学习中心名称"
              style="width: 180px"
            />
          </el-form-item>
          <el-form-item>
            <el-button
              size="small"
              type="primary"
              icon="el-icon-search"
              @click="handleSearchBtn"
            >
              查询
            </el-button>
            <el-button
              size="small"
              icon="el-icon-refresh"
              @click="resetEcsFormSearch"
            >
              重置
            </el-button>
            <el-button
              size="small"
              type="primary"
              icon="el-icon-plus"
              @click="insert"
            >
              新增
            </el-button>
          </el-form-item>
        </el-form>

        <div class="block-seperator"></div>

        <span>操作：</span>
        <el-button
          size="small"
          type="success"
          :disabled="noBatchSelected"
          icon="el-icon-check"
          @click="enableOrg"
        >
          启用
        </el-button>
        <el-button
          size="small"
          type="danger"
          :disabled="noBatchSelected"
          icon="el-icon-close"
          @click="disableOrg"
        >
          禁用
        </el-button>
        <el-button
          size="small"
          type="primary"
          icon="el-icon-upload2"
          @click="imp"
        >
          导入
        </el-button>
        <el-button
          size="small"
          type="primary"
          icon="el-icon-download"
          @click="exportOrg"
        >
          导出
        </el-button>

        <div style="width: 100%; margin-bottom: 10px"></div>

        <!-- 添加或修改学习中心弹出框 -->
        <el-dialog
          title="中心信息"
          width="450px"
          :visible.sync="campusDialog"
          @close="dialogBeforeClose"
        >
          <el-form
            ref="campusForm"
            :inline="true"
            inline-message
            :model="campusForm"
            :rules="rules"
            label-position="right"
            label-width="120px"
          >
            <el-row>
              <el-form-item label="中心代码" prop="code">
                <el-input
                  v-model="campusForm.code"
                  :disabled="null != campusForm.id"
                  class="pull_length"
                  auto-complete="off"
                  placeholder="中心代码"
                />
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="中心名称" prop="name">
                <el-input
                  v-model="campusForm.name"
                  class="pull_length"
                  auto-complete="off"
                  placeholder="中心名称"
                />
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="中心负责人" prop="contacts">
                <el-input
                  v-model="campusForm.contacts"
                  class="pull_length"
                  auto-complete="off"
                  placeholder="中心负责人"
                />
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="联系方式" prop="telephone">
                <el-input
                  v-model="campusForm.telephone"
                  class="pull_length"
                  auto-complete="off"
                  placeholder="联系方式"
                />
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="状态" prop="enable">
                <el-radio-group
                  v-model="campusForm.enable"
                  class="pull_right_sm"
                >
                  <el-radio label="true">启用</el-radio>
                  <el-radio label="false">禁用</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-row>
            <el-row class="d-flex justify-content-center">
              <el-button type="primary" @click="submitForm">保 存</el-button>
              <el-button @click="campusDialog = false">取 消</el-button>
            </el-row>
          </el-form>
        </el-dialog>

        <!-- 导入弹窗 -->
        <el-dialog title="导入窗口" width="520px" :visible.sync="impDialog">
          <el-form>
            <el-row>
              <el-form-item style="margin-left: 20px">
                <el-upload
                  ref="upload"
                  class="form_left"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  :action="uploadAction"
                  :headers="uploadHeaders"
                  :data="uploadData"
                  :before-upload="beforeUpload"
                  :on-progress="uploadProgress"
                  :on-success="uploadSuccess"
                  :on-error="uploadError"
                  :file-list="fileList"
                  :auto-upload="false"
                  :multiple="false"
                >
                  <el-button
                    slot="trigger"
                    icon="el-icon-search"
                    size="small"
                    type="primary"
                  >
                    选择文件
                  </el-button>
                  &nbsp;
                  <el-button
                    icon="el-icon-check"
                    size="small"
                    type="primary"
                    @click="submitUpload"
                  >
                    确认上传
                  </el-button>
                  <el-button
                    icon="el-icon-refresh"
                    size="small"
                    type="primary"
                    @click="removeFile"
                  >
                    清空文件
                  </el-button>
                  <el-button
                    icon="el-icon-download"
                    size="small"
                    type="primary"
                    @click="exportFile"
                  >
                    下载模板
                  </el-button>
                  <div slot="tip" class="el-upload__tip">只能上传xlsx文件</div>
                </el-upload>
              </el-form-item>
            </el-row>
          </el-form>
        </el-dialog>

        <!-- 导入错误信息列表 -->
        <el-dialog title="错误提示" :visible.sync="errDialog">
          <div
            v-for="errMessage in errMessages"
            :key="errMessage.lineNum"
            class="text-danger"
          >
            第{{ errMessage.lineNum }}行：{{ errMessage.msg }}
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="errDialog = false">确定</el-button>
          </span>
        </el-dialog>

        <!-- 页面列表 -->
        <el-table
          :data="tableData"
          border
          resizable
          stripe
          style="width: 100%"
          @selection-change="selectChange"
        >
          <el-table-column type="selection" width="40" />
          <el-table-column width="55" label="ID">
            <span slot-scope="scope">{{ scope.row.id }}</span>
          </el-table-column>
          <el-table-column width="200" label="中心代码">
            <span slot-scope="scope">{{ scope.row.code }}</span>
          </el-table-column>
          <el-table-column label="中心名称">
            <span slot-scope="scope">{{ scope.row.name }}</span>
          </el-table-column>
          <el-table-column width="100" label="负责人">
            <span slot-scope="scope">{{ scope.row.contacts }}</span>
          </el-table-column>
          <el-table-column width="120" label="联系方式">
            <span slot-scope="scope">{{ scope.row.telephone }}</span>
          </el-table-column>
          <el-table-column
            sortable
            width="170"
            label="更新时间"
            prop="updateTime"
          >
          </el-table-column>
          <el-table-column width="50" label="状态">
            <span slot-scope="scope">
              <span v-if="scope.row.enable">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="启用"
                  placement="left"
                >
                  <i class="el-icon-success" style="color: green"></i>
                </el-tooltip>
              </span>
              <span v-else>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="禁用"
                  placement="left"
                >
                  <i class="el-icon-error" style="color: red"></i>
                </el-tooltip>
              </span>
            </span>
          </el-table-column>
          <el-table-column :context="_self" label="操作" width="210">
            <div slot-scope="scope">
              <el-button
                size="mini"
                type="primary"
                plain
                @click="edit(scope.row)"
              >
                <i class="el-icon-edit"></i> 编辑
              </el-button>
            </div>
          </el-table-column>
        </el-table>
        <div class="page pull-right">
          <el-pagination
            v-if="paginationShow"
            :current-page="currentPage"
            :page-size="pageSize"
            :page-sizes="[10, 20, 50, 100, 200, 300]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { CORE_API } from "@/constants/constants.js";
import { mapState } from "vuex";

export default {
  name: "Campus",
  data() {
    return {
      paginationShow: false,
      rootOrgList: [],
      formSearch: {
        parentId: null,
        code: "",
        name: "",
      },
      campusForm: {
        id: null,
        name: "",
        code: "",
        domainName: "",
        contacts: "",
        telephone: "",
        enable: "true",
        remark: "",
        properties: {},
      },
      campusDialog: false,
      selectedOrgIds: [],
      loading: true,
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 10,

      impDialog: false,
      uploadAction: CORE_API + "/org/importSubOrg",
      uploadHeaders: {},
      uploadData: {},
      errMessages: [],
      errDialog: false,
      fileLoading: false,
      fileList: [],

      rules: {
        code: [{ required: true, message: "请输入代码", trigger: "blur" }],
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        contacts: [
          { required: true, message: "请输入负责人", trigger: "blur" },
        ],
        telephone: [
          { required: true, message: "请输入联系方式", trigger: "blur" },
        ],
        enable: [{ required: true, message: "状态", trigger: "change" }],
      },
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
    orgIds() {
      var orgIds = "";
      for (let orgId of this.selectedOrgIds) {
        if (!orgIds) {
          orgIds += orgId;
        } else {
          orgIds += "," + orgId;
        }
      }
      return orgIds;
    },
    noBatchSelected() {
      return this.selectedOrgIds.length === 0;
    },
    isSuperAdmin() {
      return this.user.roleList.some((role) => role.roleCode == "SUPER_ADMIN");
    },
    rootSchoolSelect() {
      let rootSchools = [];
      for (let i = 0; i < this.rootOrgList.length; i++) {
        let info = {
          name: this.rootOrgList[i].name + "（" + this.rootOrgList[i].id + "）",
          id: this.rootOrgList[i].id,
        };
        rootSchools.push(info);
      }
      return rootSchools;
    },
  },
  // beforeRouteEnter(to, from, next) {
  //   next(vm => {
  //     console.log("beforeRouteEnter: formSearch", vm.formSearch);
  //     vm.formSearch.parentId = to.$route.query.parentId || "";
  //     vm.formSearch.code = to.$route.query.code || "";
  //     vm.formSearch.name = to.$route.query.name || "";
  //     vm.currentPage = parseInt(to.$route.query.currentPage) || vm.currentPage;
  //     vm.pageSize = parseInt(to.$route.query.pageSize) || vm.pageSize;
  //   });
  // },
  // beforeRouteLeave(to, from, next) {
  //   // this.$router.push(to);
  //   // next();
  //   // this.$route.query = {
  //   //   parentId: this.formSearch.parentId,
  //   //   code: this.formSearch.code,
  //   //   name: this.formSearch.name,
  //   //   currentPage: this.currentPage,
  //   //   pageSize: this.pageSize
  //   // };
  //   // next();
  //   this.$router.push({
  //     path: "/basic/campus",
  //     query: {
  //       parentId: this.formSearch.parentId,
  //       code: this.formSearch.code,
  //       name: this.formSearch.name,
  //       currentPage: this.currentPage,
  //       pageSize: this.pageSize
  //     }
  //   });
  //   setTimeout(() => {
  //     console.log("settimeout next");
  //     next();
  //   }, 500);
  // },
  //初始化查询
  created() {
    this.formSearch.parentId = parseInt(this.$route.query.parentId);
    if (isNaN(this.formSearch.parentId)) {
      this.formSearch.parentId = null;
    }
    this.formSearch.code = this.$route.query.code || "";
    this.formSearch.name = this.$route.query.name || "";
    this.currentPage =
      parseInt(this.$route.query.currentPage) || this.currentPage;
    this.pageSize = parseInt(this.$route.query.pageSize) || this.pageSize;

    this.init();
    this.uploadHeaders = {
      key: this.user.key,
      token: this.user.token,
    };
  },
  methods: {
    resetEcsFormSearch() {
      this.formSearch = Object.assign(this.formSearch, {
        code: "",
        name: "",
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.searchForm();
    },
    getTag(status) {
      if (status == true) {
        return "success";
      } else if (status == false) {
        return "danger";
      }
      return status;
    },
    import() {
      this.imp = CORE_API + "/org/" + 0 + "/import";
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.searchForm();
    },
    handleSearchBtn() {
      this.currentPage = 1;
      this.searchForm();
    },
    //查询
    searchForm() {
      this.loading = true;
      this.fileLoading = true;
      var param = new URLSearchParams(this.formSearch);
      var url =
        CORE_API +
        "/org/subOrgPage/" +
        (this.currentPage - 1) +
        "/" +
        this.pageSize +
        "?" +
        param;
      this.$httpWithMsg
        .get(url)
        .then((response) => {
          this.tableData = response.data.list;
          this.total = response.data.total;
          this.loading = false;

          this.$router.push({
            path: "/basic/campus",
            query: {
              parentId: this.formSearch.parentId,
              code: this.formSearch.code,
              name: this.formSearch.name,
              currentPage: this.currentPage,
              pageSize: this.pageSize,
            },
          });
          this.$nextTick(function () {
            this.paginationShow = true;
          });
        })
        .finally(() => (this.fileLoading = false));
    },
    //导入
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    //启用
    enableOrg() {
      if (this.selectedOrgIds.length === 0) {
        this.$notify({
          type: "warning",
          message: "请选择要启用的机构",
        });
      } else {
        this.$confirm("是否启用这些机构？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          var url = CORE_API + "/org/enable/" + this.orgIds;
          this.$httpWithMsg.put(url).then(() => {
            this.$notify({
              type: "success",
              message: "启用成功！",
            });
            this.searchForm();
          });
        });
      }
    },
    disableOrg() {
      if (this.selectedOrgIds.length === 0) {
        this.$notify({
          type: "warning",
          message: "请选择要禁用的机构",
        });
      } else {
        this.$confirm("是否禁用这些机构？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          var url = CORE_API + "/org/disable/" + this.orgIds;
          this.$httpWithMsg.put(url, {}).then(() => {
            this.$notify({
              type: "success",
              message: "禁用成功！",
            });
            this.searchForm();
          });
        });
      }
    },
    selectChange(row) {
      this.selectedOrgIds = [];
      row.forEach((element) => {
        this.selectedOrgIds.push(element.id);
      });
      console.log(this.selectedOrgIds);
    },
    //重置
    resetForm() {
      this.$refs.campusForm.resetFields();
    },
    //提交
    submitForm() {
      this.$refs.campusForm.validate((valid) => {
        if (valid) {
          if (null != this.campusForm.id) {
            url = CORE_API + "/org/updateSubOrg";
            this.$httpWithMsg.put(url, this.campusForm).then(() => {
              this.$notify({
                type: "success",
                message: "修改成功！",
              });
              this.searchForm();
              this.resetForm();
              this.campusDialog = false;
            });
          } else {
            //新增
            var url = CORE_API + "/org/addSubOrg";
            this.$httpWithMsg.post(url, this.campusForm).then(() => {
              this.$notify({
                type: "success",
                message: "新增成功！",
              });
              this.searchForm();
              this.resetForm();
              this.campusDialog = false;
            });
          }
        } else {
          console.log("error submit!");
          return false;
        }
      });
    },
    //新增
    insert() {
      this.campusForm.id = null;
      this.campusForm.name = "";
      this.campusForm.code = "";
      this.campusForm.domainName = "";
      this.campusForm.telephone = "";
      this.campusForm.contacts = "";
      this.campusForm.enable = "true";

      this.campusDialog = true;
    },
    //修改
    edit(row) {
      this.campusForm.id = row.id;
      this.campusForm.name = row.name;
      this.campusForm.code = row.code;
      this.campusForm.domainName = row.domainName;
      this.campusForm.telephone = row.telephone;
      this.campusForm.contacts = row.contacts;
      this.campusForm.enable = row.enable ? "true" : "false";

      this.campusDialog = true;
    },
    dialogBeforeClose() {
      this.$refs.campusForm.clearValidate();
    },
    exportOrg() {
      window.open(
        CORE_API +
          "/org/sub/org/export?$key=" +
          this.user.key +
          "&$token=" +
          this.user.token +
          "&rootOrgId=" +
          this.formSearch.parentId
      );
    },
    imp() {
      this.impDialog = true;
      this.initUpload();
    },
    initUpload() {
      this.fileList = [];
    },
    beforeUpload(file) {
      console.log(file);
    },
    uploadProgress() {
      console.log("uploadProgress");
    },
    uploadSuccess(response) {
      if (!response.hasError) {
        this.$notify({
          message: "上传成功",
          type: "success",
        });
        this.fileLoading = false;
        this.impDialog = false;
        this.searchForm();
      } else {
        this.fileLoading = false;
        this.impDialog = false;
        this.errMessages = response.failRecords;
        this.errDialog = response.hasError;
      }
    },
    uploadError(response) {
      var json = JSON.parse(response.message);
      if (response.status == 500) {
        this.$notify({
          message: json.desc,
          type: "error",
        });
      }
      this.fileLoading = false;
    },
    //确定上传
    submitUpload() {
      if (!this.checkUpload()) {
        return false;
      }
      this.$refs.upload.submit();
      this.fileLoading = true;
    },
    checkUpload() {
      var fileList = this.$refs.upload.uploadFiles;
      if (fileList.length == 0) {
        this.$notify({
          message: "上传文件不能为空",
          type: "error",
        });
        return false;
      }
      if (fileList.length > 1) {
        this.$notify({
          message: "每次只能上传一个文件",
          type: "error",
        });
        return false;
      }
      for (let file of fileList) {
        if (!file.name.endsWith(".xlsx")) {
          this.$notify({
            message: "上传文件必须为xlsx格式",
            type: "error",
          });
          this.initUpload();
          return false;
        }
      }
      return true;
    },
    //清空文件
    removeFile() {
      // this.fileList = [];
      this.$refs.upload.clearFiles();
    },
    //下载模板
    exportFile() {
      window.location.href =
        CORE_API +
        "/org/importTemplate?$key=" +
        this.user.key +
        "&$token=" +
        this.user.token;
    },
    init() {
      this.$httpWithMsg
        .get(CORE_API + "/org/getRootOrgList")
        .then((response) => {
          this.rootOrgList = response.data;
          if (this.formSearch.parentId === null)
            this.formSearch.parentId = this.user.rootOrgId;
          this.searchForm();
        });
    },
  },
};
</script>

<style scoped>
.page {
  margin-top: 10px;
}

.el-upload {
  width: 80px;
}

.pull_length {
  width: 160px;
}
</style>
