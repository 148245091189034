<template>
  <div v-loading="loading" element-loading-text="拼命加载中">
    <section class="content-header">
      <span>
        <span class="pull-left">
          <small class="marktitle titlefont">
            <span>课程：{{ task.courseName }}</span>
          </small>
          <small class="marktitle titlefont">
            <span>试卷编号：{{ task.basePaperName }}</span>
          </small>
          <small class="marktitle titlefont">
            <span>试卷ID：{{ studentPaper.examRecordDataId }}</span>
          </small>
          <small class="marktitle titlefont">
            <span>客观分：{{ studentPaper.objectiveScore }}</span>
          </small>
          <small class="marktitle titlefont">
            <span>待评：{{ task.leftCount }}</span>
          </small>
          <small class="marktitle titlefont">
            <span v-if="task.totalCount == 0"> 进度：{{ 100 }}% </span>
            <span v-if="task.totalCount != 0">
              进度：{{
                (
                  ((task.totalCount - task.leftCount) * 100) /
                  task.totalCount
                ).toFixed(2)
              }}%
            </span>
          </small>
          <small class="marktitle">
            <el-button-group>
              <el-button size="small">
                <span class="titlefont">已评</span>
              </el-button>
              <el-button size="small" @click="markedClick">
                <span class="titlefont">{{ task.markedCount }}</span>
              </el-button>
            </el-button-group>
          </small>
          <small class="marktitle">
            <el-button
              class="course-tags"
              type="primary"
              size="small"
              @click="taskChange"
            >
              <span class="titlefont">
                切换任务 <i class="el-icon-caret-bottom el-icon--right"></i>
              </span>
            </el-button>
          </small>
          <el-dialog title="切换任务" :visible.sync="taskVisible" width="80%">
            <div class="scroll">
              <el-form
                :inline="true"
                :model="taskFormSearch"
                label-position="right"
                label-width="70px"
              >
                <el-form-item label="课程代码" class="pull-left">
                  <el-input
                    v-model="taskFormSearch.courseCode"
                    placeholder="课程代码"
                  ></el-input>
                </el-form-item>
                <el-form-item label="课程名称" class="pull-left">
                  <el-input
                    v-model="taskFormSearch.courseName"
                    placeholder="课程名称"
                  ></el-input>
                </el-form-item>
                <el-form-item class="pull-right">
                  <el-button
                    type="primary"
                    icon="search"
                    @click="getChangeTasks"
                    >查询</el-button
                  >
                </el-form-item>
              </el-form>
              <el-table :data="changeTasks" border style="width: 100%" script>
                <el-table-column
                  label="课程代码"
                  width="250"
                  prop="courseCode"
                />
                <el-table-column
                  label="课程名称"
                  width="200"
                  prop="courseName"
                />
                <el-table-column
                  label="任务"
                  width="200"
                  prop="markRangeName"
                />
                <el-table-column label="已评" prop="markedCount" />
                <el-table-column label="待评" prop="leftCount" />
                <el-table-column :context="_self" label="操作">
                  <template slot-scope="scope">
                    <div>
                      <el-button
                        size="small"
                        type="success"
                        @click="tasksSelect(scope.$index, scope.row)"
                        >选择</el-button
                      >
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <div class="page pull-right">
                <el-pagination
                  background
                  :current-page="taskCurrentPage"
                  :page-size="taskPageSize"
                  layout="total, prev, pager, next, jumper"
                  :total="changeTaskTotal"
                  @current-change="taskCurrentChange"
                ></el-pagination>
              </div>
            </div>
          </el-dialog>

          <el-dialog
            title="已评任务"
            :visible.sync="markedTaskVisible"
            width="80%"
          >
            <div class="scroll">
              <el-table :data="markedResults" border style="width: 100%" script>
                <el-table-column
                  label="试卷ID"
                  width="100"
                  prop="examRecordDataId"
                />
                <el-table-column label="得分" prop="score" />
                <el-table-column label="评阅时间">
                  <template slot-scope="scope">
                    <div>
                      <span style="margin-left: 10px">{{
                        dateFormatter(scope.row)
                      }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="问题卷">
                  <template slot-scope="scope">
                    <div>
                      <span style="margin-left: 10px">{{
                        getTagName(scope.row.tag)
                      }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="评卷备注">
                  <template slot-scope="scope">
                    <div>
                      <span style="margin-left: 10px">{{
                        scope.row.markRemark
                      }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column :context="_self" label="操作">
                  <template slot-scope="scope">
                    <div>
                      <el-button
                        size="small"
                        type="success"
                        @click="markTaskSelect(scope.$index, scope.row)"
                        >选择</el-button
                      >
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <div class="page pull-right">
                <el-pagination
                  background
                  :current-page="markedCurrentPage"
                  :page-size="markedPageSize"
                  :page-sizes="[10, 20, 50, 100, 200, 300]"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="markedTotal"
                  @current-change="markedCurrentChange"
                  @size-change="markedSizeChange"
                ></el-pagination>
              </div>
            </div>
          </el-dialog>
        </span>
        <span class="pull-right icon-right">
          <small class="marktitle titlefont">
            <span class="titlefont">
              <v-icon name="user" style="height: 12px" />
              {{ user.displayName }}
            </span>
          </small>
          <small class="marktitle titlefont marksign">
            <el-button
              type="success"
              size="small"
              icon="el-icon-arrow-left"
              @click="backIndex"
            >
              <!-- <v-icon name="sign-out-alt" scale="0.5"/> -->
              <span class="titlefont">返回</span>
            </el-button>
          </small>
        </span>
      </span>
    </section>

    <section class="content">
      <div class="box box-info backcolor">
        <div class="box-body">
          <!-- 文本图片阅卷主界面 -->
          <tpMain
            :paper-mark-sign="paperMarkSign"
            :sign-item="signItem"
            :sign-scores="signScores"
            :sign-option="signOption"
            :mark-sign="markSign"
            :student-paper="studentPaper"
            :exam-type="examType"
            :answer-html="answerHtml"
            @changePaperSign="changePaperSign"
            @clearScores="clearScores"
            @changeSignScores="changeSignScores"
            @showAnswerChange="showAnswerChange"
          ></tpMain>
          <!-- 文本图片给分板 -->
          <tpScoreboard
            ref="scoreboard"
            :work-id="workId"
            :objective-score="studentPaper.objectiveScore"
            :paper-mark-sign="paperMarkSign"
            :sign-item="signItem"
            :sign-scores="signScores"
            :sign-option="signOption"
            :mark-sign="markSign"
            :paper-mark="paperMark"
            :marked-result="markedResult"
            :result-items="resultItems"
            :student-paper-id="studentPaper.id"
            :score-step="studentPaper.scoreStep"
            :mark-task-id="task.id"
            :exam-type="examType"
            @submitMark="getNext"
            @changeSign="changeSign"
          ></tpScoreboard>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { mapState } from "vuex";
import TpMain from "./TpMain.vue";
import TpScoreBoard from "./TpScoreBoard.vue";
import {
  MARKING_API,
  MARKING_LOGIC_API,
  QUESTION_API,
} from "@/constants/constants";
export default {
  components: {
    tpMain: TpMain,
    tpScoreboard: TpScoreBoard,
  },
  data() {
    return {
      markSign: false,
      paperMarkSign: [],
      signScores: [],
      signItem: {},
      signOption: { score: 0, data: "", width: 800, height: 8000, loc: [] },
      tasks: [],
      task: {},
      changeTasks: [],
      changeTaskTotal: 0,
      studentPaper: {},
      markRange: {},
      resultItems: [],
      markedResults: [],
      markedResult: {},
      loading: true,
      taskCurrentPage: 1,
      taskPageSize: 10,
      taskTotal: 0,
      markedCurrentPage: 1,
      markedPageSize: 10,
      markedTotal: 0,
      pageSize: 10,
      mark_type: "正评",
      mark_task: {},
      taskVisible: false,
      markedTaskVisible: false,
      problemTaskVisible: false,
      degree: [
        { label: "是", value: "Y" },
        { label: "否", value: "N" },
      ],
      markTaskFormSearch: {
        courseCode: "",
        courseName: "",
        degree: "",
      },
      taskFormSearch: {
        courseCode: "",
        courseName: "",
        paperCode: "",
      },
      course: {},
      workId: "",
      examType: "",
      markSearch: {
        userId: "",
        workId: "",
      },
      //回评标志
      backMark: false,
      //获取试卷标志(成功获取true,没有试卷false)
      paperMark: true,
      tags: [],
      answerHtml: null,
      rangeId: null,
    };
  },
  computed: {
    allMarked() {
      if (this.tasks.length == 0) {
        return false;
      } else {
        for (let task of this.tasks) {
          if (task.leftCount > 0) {
            return false;
          }
        }
        return true;
      }
    },
    taskCur() {
      //任务及时动态切换
      if (this.tasks.length == 0) {
        return 0;
      } else {
        for (let [index, task] of this.tasks.entries()) {
          if (task.leftCount > 0 && !task.exceedLimit) {
            return index;
          }
        }
        return 0;
      }
    },
    changeTaskCur() {
      for (let [index, task] of this.tasks.entries()) {
        if (task.id == this.task.id) {
          return index;
        }
      }
      return this.taskCur;
    },
    ...mapState({ user: (state) => state.user }),
  },
  async created() {
    this.loading = true;
    await this.markInit();
    //await this.getChangeTasks();
    await this.getPaper();
    await this.getAnswerHtml();
    await this.getMarkRange();
    await this.getMarkedTask();
    this.getTags();
    this.loading = false;
  },
  methods: {
    showAnswerChange() {
      this.$refs.scoreboard.showBodyChange();
      this.$refs.scoreboard.showAnswerChange();
    },
    getTags() {
      this.$http.get(MARKING_API + "/markResults/tag").then((response) => {
        this.tags = response.data;
      });
    },
    getTagName(code) {
      for (let tag of this.tags) {
        if (tag.code === code) {
          return tag.name;
        }
      }
    },
    clearScores() {
      this.signScores.splice(0, this.signScores.length);
    },
    changePaperSign(val) {
      this.paperMarkSign = val;
    },
    changeSignScores(val) {
      this.signScores = val;
    },
    changeSign(val) {
      console.log(val);
      this.signItem = val;
    },
    taskChange() {
      this.taskVisible = true;
      this.getChangeTasks();
    },
    async tasksSelect(index, row) {
      this.taskVisible = false;
      this.loading = true;
      this.answerHtml = null;
      await this.initMarkItem();
      await this.markInit();
      this.task = row;
      await this.getPaper();
      await this.getAnswerHtml();
      await this.getMarkRange();
      await this.getMarkedTask();
      this.loading = false;
      this.markedCurrentPage = 1;
    },
    markedClick() {
      this.markedTaskVisible = true;
      this.getMarkedTask();
    },
    problemClick() {
      this.problemTaskVisible = true;
    },
    backIndex() {
      this.$router.push({ path: "/marking/mark_setting_work/marking" });
    },
    taskCurrentChange(val) {
      this.taskCurrentPage = val;
      this.getChangeTasks();
    },
    markedCurrentChange(val) {
      this.markedCurrentPage = val;
      this.getMarkedTask();
    },
    markedSizeChange(val) {
      this.markedPageSize = val;
      this.getMarkedTask();
    },
    async markTaskSelect(index, row) {
      this.markedTaskVisible = false;
      this.loading = true;
      this.backMark = true;
      this.paperMark = true;
      this.textarea = row.markRemark;
      await this.getMarkedPaper(row.studentPaperId);
      await this.getMarkedResultItems(row.id);
      await this.getAnswerHtml();
      this.loading = false;
    },
    async markInit() {
      console.log("markInit");
      var self = this;
      this.workId = this.$route.params.workId;
      var userId = this.user.userId;
      await self.$http
        .get(
          MARKING_LOGIC_API +
            "/markTasks?workId=" +
            self.workId +
            "&userId=" +
            userId
        )
        .then((response) => {
          self.tasks = response.data;
          self.taskTotal = self.tasks.length;
          //切换任务提交试卷后，继续维持该任务
          if (self.changeTaskCur != self.taskCur) {
            //切换任务评完后，继续跳转原来的任务
            if (
              self.tasks[self.changeTaskCur].leftCount == 0 ||
              self.tasks[self.changeTaskCur].exceedLimit
            ) {
              if (self.tasks[self.taskCur]) {
                self.task = self.tasks[self.taskCur];
              }
            } else if (self.tasks[self.changeTaskCur]) {
              self.task = self.tasks[self.changeTaskCur];
            }
          } else {
            if (self.tasks[self.taskCur]) {
              self.task = self.tasks[self.taskCur];
            }
          }
          console.log("tasks", self.tasks);
        });
    },
    async getChangeTasks() {
      console.log("getChangeTasks");
      var self = this;
      this.workId = this.$route.params.workId;
      var userId = this.user.userId;
      await self.$http
        .get(
          MARKING_LOGIC_API +
            "/markTasks?workId=" +
            self.workId +
            "&userId=" +
            userId
        )
        .then((response) => {
          self.changeTasks = response.data;
          self.changeTaskTotal = self.changeTasks.length;
          self.filterChangeTasks();
          self.pagingChangeTasks();
          console.log("changeTasks", self.changeTasks);
        });
    },
    filterChangeTasks() {
      var tempData = this.changeTasks.filter((element) => {
        var flag = true;
        if (this.taskFormSearch.courseCode || this.taskFormSearch.courseName) {
          if (this.taskFormSearch.courseCode) {
            flag =
              flag &&
              element.courseCode.includes(this.taskFormSearch.courseCode);
          }
          if (this.taskFormSearch.courseName) {
            flag =
              flag &&
              element.courseName.includes(this.taskFormSearch.courseName);
          }
          return flag;
        } else {
          return flag;
        }
      });
      this.changeTasks = tempData;
      this.changeTaskTotal = tempData.length;
    },
    pagingChangeTasks() {
      var start = (this.taskCurrentPage - 1) * this.pageSize;
      var end =
        this.taskCurrentPage * this.pageSize < this.changeTaskTotal
          ? this.taskCurrentPage * this.pageSize
          : this.changeTaskTotal;
      var tempData = [];
      console.log(`当前页: ${this.taskCurrentPage},开始:${start},结束:${end}`);
      for (let i = start; i < end; i++) {
        tempData.push(this.changeTasks[i]);
      }
      this.changeTasks = tempData;
    },
    async getPaper() {
      var self = this;
      await self.$http
        .get(MARKING_LOGIC_API + "/studentPapers?markTaskId=" + self.task.id)
        .then((response) => {
          if (response.data.resultCode == "0") {
            self.studentPaper = response.data.data;
            self.examType = self.studentPaper.examType;
            self.paperMark = true;
          } else {
            if (response.data.resultCode == "1") {
              self.$notify({
                message: "该任务下试卷已评完，如有剩余任务将自动切换任务",
                type: "warning",
              });
            } else if (response.data.resultCode == "2") {
              self.$notify({
                message: "您设置的阅卷任务数已完成",
                type: "warning",
              });
            }

            this.resultItems.splice(0, this.resultItems.length);
            self.studentPaper = { id: "" };
            self.paperMark = false;
            return false;
          }
        });
      return true;
    },
    async getMarkedTask() {
      console.log("getMarkedTask");
      var self = this;
      await self.$http
        .get(
          MARKING_API +
            "/markResults/" +
            this.task.id +
            "/" +
            this.markedCurrentPage +
            "/" +
            this.markedPageSize
        )
        .then((response) => {
          self.markedResults = response.data.data;
          self.markedTotal = response.data.pageInfo.totalElements;
          self.markedCurrentPage = response.data.pageInfo.page + 1;
          console.log("markedTask", self.markedResults);
        });
    },
    async getMarkedPaper(studentPaperId) {
      var self = this;
      await self.$http
        .get(MARKING_LOGIC_API + "/studentPapers/" + studentPaperId)
        .then((response) => {
          self.studentPaper = response.data;
          self.examType = self.studentPaper.examType;
        });
    },
    async getMarkedResultItems(markResultId) {
      var self = this;
      await self.$http
        .get(MARKING_API + "/markResults/" + markResultId)
        .then((response) => {
          self.resultItems = response.data.resultItems;
          self.markedResult = response.data;
          console.log(self.markedResult);
        });
    },
    async getMarkRange() {
      if (!this.paperMark) {
        return;
      }
      if (!this.backMark && this.rangeId == this.task.markRangeId) {
        for (let resultItem of this.resultItems) {
          resultItem.score = "";
        }
        return;
      }
      this.resultItems.splice(0, this.resultItems.length);
      var self = this;
      await self.$http
        .get(
          MARKING_API +
            "/markRanges/info?markRangeId=" +
            self.task.markRangeId +
            "&examRecordDataId=" +
            self.studentPaper.examRecordDataId
        )
        .then((response) => {
          self.markRange = response.data;
          self.getResultItems(self.markRange);
          console.log("markRange", self.markRange);
          console.log("resultItems", self.resultItems);
        });
    },
    getResultItems(markRange) {
      for (let markItem of markRange.markItems) {
        var resultItem = { markItem: markItem, score: "" };
        this.resultItems.push(resultItem);
      }
    },
    async getNext() {
      if (this.rangeId == null) {
        this.rangeId = this.task.markRangeId;
      }
      this.answerHtml = null;
      //试卷全部评完且回评时不继续获取试卷
      if (this.backMark && this.allMarked) {
        this.$loading().close();
        return;
      }
      this.initMarkItem();
      await this.markInit();
      await this.getPaper();
      await this.getAnswerHtml();
      // await this.getChangeTasks();
      await this.getMarkRange();
      await this.getMarkedTask();
      this.backMark = false;
      this.$loading().close();
    },
    dateFormatter(row) {
      var fulldate = new Date(row.markedOn);
      var year = fulldate.getFullYear();
      var month = fulldate.getMonth() + 1;
      var date = fulldate.getDate();
      var formatDate = [year, month, date].join("-");
      return formatDate;
    },
    initMarkItem() {
      //this.resultItems.splice(0, this.resultItems.length);
      this.markResult = {};
      this.markedResult = {};
    },
    markSignChange() {
      if (this.markSign) {
        this.$message({
          showClose: true,
          message: "轨迹模式已开启,只支持鼠标操作",
          type: "warning",
        });
      }
    },
    async getAnswerHtml() {
      if (this.studentPaper.basePaperId && this.examType == "OFFLINE") {
        var paperId = this.studentPaper.basePaperId;
        await this.$http
          .get(QUESTION_API + "/extract/getAnswerHtml/" + paperId)
          .then((response) => {
            this.answerHtml = response.data;
          });
      }
    },
  },
};
</script>
<style scoped>
.el-select {
  width: 160px;
}
.content {
  min-height: 600px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
}
small {
  font-size: 18px;
  font-weight: bold;
  margin-left: 10px;
  margin-top: 20px;
}
.content-header {
  margin-left: 15px;
  overflow: hidden;
  background-color: rgb(77, 124, 196);
  height: 55px;
}
.box-body {
  height: 100%;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  padding-left: 40px;
  padding-right: 15px;
  overflow: hidden;
}
li {
  list-style-type: none;
}
.marktitle {
  margin-right: 20px;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 700;
  color: white;
}
.titlefont {
  font-size: 14px;
  display: inline-block;
}
.box-card {
  width: 200px;
  height: 120px;
}
.block {
  display: inline-block;
}
.text {
  text-align: center;
}
.markcount {
  margin-right: 10px;
}
.reject {
  color: red;
}
.marksign {
  margin-left: 20px;
}
.label-danger {
  background-color: #d9534f;
  color: #fff;
  padding: 2px;
}
.box {
  position: relative;
  border-radius: 3px;
  margin-bottom: 0px;
  width: 100%;
}
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}
.scroll {
  overflow: auto;
  height: 450px;
}
.el-button {
  background: rgb(77, 124, 196);
  color: white;
  border-radius: 8px;
  border-color: white;
}
.course-tags {
  background: rgb(84, 191, 255);
}
.icon-right {
  margin-top: -6px;
}
</style>
<style>
.altbox {
  color: rgb(85, 191, 255);
}
</style>
