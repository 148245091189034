<template>
  <section class="content">
    <div class="box box-info">
      <!-- 正文信息 -->
      <div class="box-body">
        <el-form
          :inline="true"
          :model="formSearch"
          label-position="left"
          label-width="70px"
        >
          <el-form-item label="标题" class="pull-left">
            <el-input
              v-model="formSearch.title"
              auto-complete="off"
              width="200px"
            ></el-input>
          </el-form-item>
          <el-form-item class="d-block">
            <el-button
              size="small"
              type="primary"
              icon="el-icon-search"
              @click="resetPageAndSearchForm"
              >查询</el-button
            >
            <el-button
              size="small"
              type="primary"
              icon="el-icon-plus"
              @click="addNoticeDialog"
              >新增</el-button
            >
          </el-form-item>
        </el-form>
        <div class="block-seperator"></div>
        <span>批量操作：</span>
        <el-button
          size="small"
          type="danger"
          icon="el-icon-delete"
          @click="deleteByIds"
          >删除</el-button
        >

        <div style="width: 100%; margin-bottom: 10px"></div>
        <!-- 页面列表 -->
        <el-table
          v-loading="loading"
          element-loading-text="拼命加载中"
          :data="tableData"
          border
          style="width: 100%; text-align: center"
          @selection-change="selectChange"
        >
          <el-table-column type="selection" width="40"></el-table-column>
          <el-table-column
            prop="id"
            width="60"
            label="ID"
            sortable
          ></el-table-column>
          <el-table-column prop="title" label="标题"></el-table-column>
          <!-- <el-table-column prop="publishObject" label="发送对象"></el-table-column> -->
          <el-table-column label="发送对象" width="180">
            <template slot-scope="scope">
              <el-popover
                :title="getRuleTypeName(scope.row.ruleType)"
                trigger="hover"
                placement="top-start"
                width="200px"
              >
                <div style="max-width: 450px">
                  <el-tag
                    v-for="item in scope.row.publishObject"
                    :key="'po' + item.id"
                    style="margin-right: 5px; margin-top: 5px"
                    type="info"
                    effect="plain"
                    >{{ item.name }}</el-tag
                  >
                </div>

                <!-- <p v-html="getPublishObject(scope.row.publishObject)"></p> -->
                <div slot="reference" style="text-align: left">
                  <!-- <el-tag size="medium"> -->
                  {{ getPublishObject(scope.row.publishObject) }}
                  <!-- </el-tag> -->
                </div>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column
            width="80"
            prop="publisher"
            label="发布者"
          ></el-table-column>
          <el-table-column width="80" label="状态" sortable>
            <template slot-scope="scope">
              <div>
                <span>{{ getPublishStatus(scope.row.publishStatus) }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="publishTime"
            width="155"
            label="发送时间"
            sortable
          ></el-table-column>
          <el-table-column label="操作" width="270">
            <div slot-scope="scope">
              <el-button
                :disabled="scope.row.publishStatus == 'RECALLED'"
                size="mini"
                type="primary"
                icon="el-icon-view"
                plain
                @click="viewNoticeDialog(scope.row)"
                >详情</el-button
              >
              <el-button
                v-if="scope.row.publishStatus == 'DRAFT'"
                size="mini"
                type="primary"
                icon="el-icon-message"
                @click="sendMsg(scope.row.id)"
                >发送</el-button
              >
              <el-dropdown style="margin-left: 10px">
                <el-button type="primary" plain size="mini">
                  更多 <i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <el-button
                      :disabled="scope.row.publishStatus != 'DRAFT'"
                      size="mini"
                      type="primary"
                      icon="el-icon-edit"
                      plain
                      @click="editNoticeDialog(scope.row)"
                      >编辑</el-button
                    >
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-button
                      :disabled="scope.row.publishStatus != 'DRAFT'"
                      size="mini"
                      type="danger"
                      icon="el-icon-delete"
                      @click="deleteById(scope.row)"
                      >删除</el-button
                    >
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-button
                      v-if="
                        scope.row.publishStatus == 'PUBLISHED' ||
                        scope.row.publishStatus == 'TO_BE_PUBLISHED'
                      "
                      size="mini"
                      type="warning"
                      icon="el-icon-back"
                      @click="recallMsg(scope.row.id)"
                      >撤回</el-button
                    >
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </el-table-column>
        </el-table>
        <div class="page pull-right">
          <el-pagination
            v-if="paginationShow"
            :current-page="currentPage"
            :page-size="pageSize"
            :page-sizes="[10, 20, 50, 100, 200, 300]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          ></el-pagination>
        </div>

        <!-- 弹出窗口 -->
        <el-dialog
          v-if="editNoticeDialogVisible"
          width="700px"
          :title="
            operateType == 'add'
              ? '新增公告'
              : operateType == 'edit'
              ? '编辑公告'
              : '公告详情'
          "
          :visible.sync="editNoticeDialogVisible"
          :before-close="handleClose"
          :close-on-click-modal="false"
          @close="cancel"
        >
          <el-form
            ref="noticeForm"
            :model="noticeForm"
            inline-message
            size="large"
            :rules="rules"
            :inline="true"
            label-width="85px"
            class="editForm"
          >
            <el-form-item label="标题" prop="title">
              <el-input
                v-model="noticeForm.title"
                class="input"
                :readonly="operateType == 'view'"
                auto-complete="off"
                maxlength="50"
              ></el-input>
            </el-form-item>
            <el-form-item label="发布人" prop="publisher">
              <el-input
                v-model="noticeForm.publisher"
                class="input"
                :readonly="operateType == 'view'"
                maxlength="50"
              ></el-input>
            </el-form-item>
            <el-form-item label="发送对象" prop="ruleType">
              <el-select
                v-model="noticeForm.ruleType"
                :disabled="operateType == 'view'"
                class="input"
                clearable
                placeholder="请选择"
                @change="showSelectReceiver"
              >
                <el-option
                  v-for="item in receiverRuleTypes"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                ></el-option>
              </el-select>
              <el-button
                v-show="
                  (noticeForm.ruleType == 'TEACHER_OF_MARK_WORK' ||
                    noticeForm.ruleType == 'STUDENTS_OF_EXAM') &&
                  operateType != 'view'
                "
                style="margin-left: 5px"
                @click="showSelectReceiver"
                >选 择</el-button
              >
            </el-form-item>
            <el-row v-if="selectedExam.length != 0" :gutter="10">
              <el-col :xs="30" :sm="30" :md="30" :lg="30">
                <el-form-item label="已选">
                  <el-tag
                    v-for="tag in selectedExam"
                    :key="'res-exam' + tag.id"
                    style="margin-left: 5px; margin-top: 5px"
                    :closable="false"
                    :close-transition="false"
                    type="info"
                    @close="examTagClose(tag)"
                    >{{ tag.name }}</el-tag
                  >
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="selectedMark.length != 0" :gutter="10">
              <el-col :xs="30" :sm="30" :md="30" :lg="30">
                <el-form-item label="已选">
                  <el-tag
                    v-for="tag in selectedMark"
                    :key="'res-mark' + tag.id"
                    style="margin-left: 5px; margin-top: 5px"
                    :closable="false"
                    :close-transition="false"
                    type="info"
                    @close="markTagClose(tag)"
                    >{{ tag.name }}</el-tag
                  >
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item label="公告内容" prop="content">
              <div>
                <ckeditor
                  v-model="noticeForm.content"
                  extrabuttons="Font,FontSize,TextColor"
                  :readonly="operateType == 'view'"
                  width="650px"
                ></ckeditor>
              </div>
            </el-form-item>
          </el-form>
          <div style="text-align: center">
            <el-button
              v-show="operateType != 'view'"
              type="primary"
              :loading="noticeFormLoading"
              @click="saveNotice"
              >保 存</el-button
            >
            <el-button @click="cancel">关 闭</el-button>
          </div>
          <!-- 考试弹框 start-->
          <el-dialog
            width="600px"
            :before-close="handleClose"
            title="发送对象"
            :visible.sync="examReceiverVisible"
            append-to-body
            :close-on-click-modal="false"
            @close="examCancel"
          >
            <el-form
              inline-message
              :inline="true"
              :model="examFormSearch"
              label-position="right"
              label-width="70px"
            >
              <el-row v-if="selectedExam.length != 0" :gutter="10">
                <el-col :xs="30" :sm="30" :md="30" :lg="30">
                  <el-form-item label="已选">
                    <el-tag
                      v-for="tag in selectedExam"
                      :key="'exam' + tag.id"
                      :closable="true"
                      :close-transition="false"
                      type="info"
                      @close="examTagClose(tag)"
                      >{{ tag.name }}</el-tag
                    >
                  </el-form-item>
                </el-col>
              </el-row>
              <el-form-item label="考试名称" class="pull-left">
                <el-input
                  v-model="examFormSearch.name"
                  auto-complete="off"
                  width="200px"
                ></el-input>
              </el-form-item>
              <el-form-item class="d-block">
                <el-button
                  size="small"
                  type="primary"
                  icon="el-icon-search"
                  @click="resetExamPageAndSearchForm"
                  >查询</el-button
                >
              </el-form-item>
            </el-form>
            <div class="table-native" style="height: 400px">
              <el-table
                ref="examTable"
                v-loading="loading"
                stripe
                element-loading-text="拼命加载中"
                :data="examTableData"
                border
                @selection-change="examSelectChange"
              >
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column label="考试id" prop="id"></el-table-column>
                <el-table-column label="考试名称" prop="name"></el-table-column>
                <el-table-column width="130" label="考试类型" sortable>
                  <template slot-scope="scope">
                    <div>
                      <span>{{ getExamType(scope.row.examType) }}</span>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <div class="page pull-right">
                <el-pagination
                  v-if="examPaginationShow"
                  small
                  :current-page="examCurrentPage"
                  :page-size="examPageSize"
                  :page-sizes="[10]"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="examTotal"
                  @current-change="handleExamCurrentChange"
                  @size-change="handleExamSizeChange"
                ></el-pagination>
              </div>
            </div>
            <div style="margin-top: 20px; text-align: center">
              <el-button @click="examConfirm">确 定</el-button>
              <el-button @click="examCancel">取消</el-button>
            </div>
          </el-dialog>
          <!-- 考试弹框 end-->
          <!-- 阅卷工作弹框 start-->
          <el-dialog
            :before-close="handleClose"
            width="600px"
            title="发送对象"
            :visible.sync="markReceiverVisible"
            append-to-body
            :close-on-click-modal="false"
            @close="markCancel"
          >
            <el-form
              inline-message
              :inline="true"
              :model="markFormSearch"
              label-position="right"
              label-width="110px"
            >
              <el-row v-if="selectedMark.length != 0" :gutter="10">
                <el-col :xs="30" :sm="30" :md="30" :lg="30">
                  <el-form-item label="已选">
                    <el-tag
                      v-for="tag in selectedMark"
                      :key="'mark' + tag.id"
                      :closable="true"
                      :close-transition="false"
                      type="info"
                      @close="markTagClose(tag)"
                      >{{ tag.name }}</el-tag
                    >
                  </el-form-item>
                </el-col>
              </el-row>
              <el-form-item label="评卷工作名称" class="pull-left">
                <el-input
                  v-model="markFormSearch.workName"
                  auto-complete="off"
                  width="200px"
                ></el-input>
              </el-form-item>
              <el-form-item class="d-block">
                <el-button
                  size="small"
                  type="primary"
                  icon="el-icon-search"
                  @click="resetMarkPageAndSearchForm"
                  >查询</el-button
                >
              </el-form-item>
            </el-form>
            <div class="table-native" style="height: 400px">
              <el-table
                ref="markTable"
                v-loading="loading"
                stripe
                element-loading-text="拼命加载中"
                :data="markTableData"
                border
                @selection-change="markSelectChange"
              >
                <el-table-column type="selection" width="55"></el-table-column>
                <!-- <el-table-column label="考试id" prop="id"></el-table-column> -->
                <el-table-column
                  label="评卷工作名称"
                  prop="name"
                ></el-table-column>
                <el-table-column label="考试名称-类型" width="300">
                  <template slot-scope="scope"
                    ><div>
                      <span
                        v-for="item in scope.row.examNameAndType"
                        :key="item.index"
                        style="display: block"
                        >{{ item }}</span
                      >
                    </div></template
                  >
                </el-table-column>
              </el-table>
              <div class="page pull-right">
                <el-pagination
                  v-if="markPaginationShow"
                  small
                  :current-page="markCurrentPage"
                  :page-size="markPageSize"
                  :page-sizes="[10]"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="markTotal"
                  @current-change="handleMarkCurrentChange"
                  @size-change="handleMarkSizeChange"
                ></el-pagination>
              </div>
            </div>
            <div style="margin-top: 20px; text-align: center">
              <el-button @click="markConfirm">确 定</el-button>
              <el-button @click="markCancel">取消</el-button>
            </div>
          </el-dialog>
          <!-- 阅卷工作弹框 end-->
        </el-dialog>
      </div>
    </div>
  </section>
</template>
<script>
import {
  EXAM_WORK_API,
  EXAM_TYPE,
  PUBLISH_STATUS,
  NOTICE_RECEIVER_RULE_TYPE,
  MARKING_API,
} from "@/constants/constants.js";
import { mapState } from "vuex";
import ckeditor from "@/components/ckeditor.vue";

export default {
  components: { ckeditor },
  data() {
    return {
      noticeFormLoading: false,
      formSearch: {
        title: "",
      },
      examFormSearch: {
        enable: true,
        name: "",
      },
      markFormSearch: {
        workName: "",
      },
      selectedExam: [],
      tempSelectedExam: [],
      selectedMark: [],
      tempSelectedMark: [],
      examTableData: [],
      markTableData: [],
      operateType: "add",
      examTypeList: EXAM_TYPE,
      noticeForm: {
        id: null,
        title: "",
        content: "",
        publisher: "",
        ruleType: "", //规则类型
        publishObjectId: "", //发送对象id
        noticeStatus: "",
      },
      receiverForm: {
        receiverObjectType: null,
        receiverObject: "",
        ruleType: "", //规则类型
        publishObjectId: "", //发送对象id
      },
      receiverRuleTypes: NOTICE_RECEIVER_RULE_TYPE,
      loading: false,
      publishStatusList: PUBLISH_STATUS,
      tableData: [],
      paginationShow: false,
      examPaginationShow: false,
      markPaginationShow: false,
      editNoticeDialogVisible: false,
      examReceiverVisible: false,
      markReceiverVisible: false,
      currentPage: 1,
      pageSize: 10,
      total: 10,
      examCurrentPage: 1,
      examPageSize: 10,
      examTotal: 10,
      markCurrentPage: 1,
      markPageSize: 10,
      markTotal: 10,
      selectedNoticeIds: [],
      button: {},
      rules: {
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        content: [{ required: true, message: "请输入内容", trigger: "blur" }],
        ruleType: [
          { required: true, message: "请选择接收人", trigger: "change" },
        ],
        publisher: [
          { required: true, message: "请输入发布人", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
    noticeIds() {
      var strNoticeIds = "";
      for (let nid of this.selectedNoticeIds) {
        if (!strNoticeIds) {
          strNoticeIds += nid;
        } else {
          strNoticeIds += "," + nid;
        }
      }
      return strNoticeIds;
    },
    selectedExamIds() {
      var selectedIds = "";
      for (let exam of this.selectedExam) {
        if (!selectedIds) {
          selectedIds += exam.id;
        } else {
          selectedIds += "," + exam.id;
        }
      }
      return selectedIds;
    },
    selectedMarkIds() {
      var selectedIds = "";
      for (let mark of this.selectedMark) {
        if (!selectedIds) {
          selectedIds += mark.id;
        } else {
          selectedIds += "," + mark.id;
        }
      }
      return selectedIds;
    },
  },
  //初始化查询
  created() {
    this.searchForm();
  },

  methods: {
    closeEditNoticeDialog() {
      this.editNoticeDialogVisible = false;
    },

    selectChange(row) {
      this.selectedNoticeIds = [];
      row.forEach((element, index) => {
        console.log(index);
        this.selectedNoticeIds.push(element.id);
      });
      console.log(this.selectedNoticeIds);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.searchForm();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.searchForm();
    },
    resetPageAndSearchForm() {
      this.currentPage = 1;
      this.searchForm();
    },
    examSelectChange(row) {
      if (this.getAfterAddCount(this.selectedExam, row) > 10) {
        this.warn("一次最多只能选择10个考试！");
        return;
      }
      this.tempSelectedExam = row;
      var selectedExams = this.selectedExam;
      row.forEach((element) => {
        if (
          !selectedExams.some((se) => se.id == element.id) &&
          selectedExams.length < 10
        ) {
          selectedExams.push(element);
        }
      });
      this.selectedExam = selectedExams;
    },
    markSelectChange(row) {
      if (this.getAfterAddCount(this.selectedMark, row) > 10) {
        this.warn("一次最多只能选择10个阅卷工作！");
        return;
      }
      this.tempSelectedMark = row;
      var selectedMarks = this.selectedMark;
      row.forEach((element) => {
        if (
          !selectedMarks.some((sm) => sm.id == element.id) &&
          selectedMarks.length < 10
        ) {
          selectedMarks.push(element);
        }
      });
      this.selectedMark = selectedMarks;
    },
    getAfterAddCount(selecteds, row) {
      const selectedids = selecteds.map((x) => x.id);
      const temcount =
        row.filter((key) => selectedids.includes(key.id) === false).length +
        selectedids.length;
      return temcount;
    },
    handleExamCurrentChange(val) {
      this.examCurrentPage = val;
      this.searchExamForm();
    },
    handleExamSizeChange(val) {
      this.examPageSize = val;
      this.searchExamForm();
    },
    handleMarkCurrentChange(val) {
      this.markCurrentPage = val;
      this.searchMarkForm();
    },
    handleMarkSizeChange(val) {
      this.markPageSize = val;
      this.searchMarkForm();
    },
    resetExamPageAndSearchForm() {
      this.examCurrentPage = 1;
      this.searchExamForm();
    },
    resetMarkPageAndSearchForm() {
      this.markCurrentPage = 1;
      this.searchMarkForm();
    },
    //查询方法
    searchForm() {
      var param = new URLSearchParams(this.formSearch);
      var url =
        EXAM_WORK_API +
        "/notice/getPagedNoticeList/" +
        (this.currentPage - 1) +
        "/" +
        this.pageSize +
        "?" +
        param;
      this.loading = true;
      this.$httpWithMsg.get(url).then((response) => {
        console.log(response);
        this.tableData = response.data.list;
        this.total = response.data.total;
        this.loading = false;
        this.paginationShow = true;
      });
    },
    searchExamForm() {
      var param = new URLSearchParams(this.examFormSearch);
      var url =
        EXAM_WORK_API +
        "/exam/queryPage/" +
        (this.examCurrentPage - 1) +
        "/" +
        this.examPageSize +
        "?" +
        param;
      this.loading = true;
      this.$httpWithMsg.get(url).then((response) => {
        console.log(response);
        this.examTableData = response.data.list;
        this.examTotal = response.data.total;
        this.loading = false;
        this.examPaginationShow = true;
      });
    },
    searchMarkForm() {
      var param = new URLSearchParams(this.markFormSearch);
      var url =
        MARKING_API +
        "/markWorks/getMarkWorkPage/" +
        this.markCurrentPage +
        "/" +
        this.markPageSize +
        "?" +
        param;
      this.loading = true;
      this.$httpWithMsg.get(url).then((response) => {
        console.log(response);
        this.markTableData = response.data.list;
        this.markTotal = response.data.total;
        this.loading = false;
        this.markPaginationShow = true;
      });
    },
    getExamType(examType) {
      for (let tempExamType of this.examTypeList) {
        if (tempExamType.value == examType) {
          return tempExamType.label;
        }
      }
    },
    addNoticeDialog() {
      this.operateType = "add";

      this.noticeForm.id = null;
      this.noticeForm.title = "";
      this.noticeForm.content = "";
      this.noticeForm.publisher = "";
      this.noticeForm.ruleType = "";
      this.noticeForm.noticeStatus = "";

      this.editNoticeDialogVisible = true;

      this.selectedExam = [];
      this.selectedMark = [];
      this.noticeForm = {};
    },
    editNoticeDialog(row) {
      this.operateType = "edit";
      this.noticeForm = Object.assign({}, row);
      this.selectedExam = [];
      this.selectedMark = [];
      if (row.publishObject && row.publishObject.length > 0) {
        if (row.ruleType == "STUDENTS_OF_EXAM") {
          this.selectedExam = row.publishObject;
        } else if (row.ruleType == "TEACHER_OF_MARK_WORK") {
          this.selectedMark = row.publishObject;
        }
      }

      this.editNoticeDialogVisible = true;
    },
    viewNoticeDialog(row) {
      this.operateType = "view";
      this.noticeForm = Object.assign({}, row);
      if (row.publishObject && row.publishObject.length > 0) {
        if (row.ruleType == "STUDENTS_OF_EXAM") {
          this.selectedExam = row.publishObject;
        } else if (row.ruleType == "TEACHER_OF_MARK_WORK") {
          this.selectedMark = row.publishObject;
        }
      }

      this.editNoticeDialogVisible = true;
    },
    getPublishObject(publishObjectList) {
      if (publishObjectList == null || publishObjectList.length == 0) {
        return "";
      }
      let ruleType = publishObjectList[0].ruleType;
      if (
        ruleType == "ALL_STUDENTS_OF_ROOT_ORG" ||
        ruleType == "COMMON_USERS_OF_ROLE"
      ) {
        return publishObjectList[0].name;
      }
      let strName = "";
      for (let po of publishObjectList) {
        strName += po.name + ";";
      }
      if (strName.lastIndexOf(";") == strName.length - 1) {
        strName = strName.substring(0, strName.length - 1);
      }
      if (strName.length > 15) {
        strName = strName.substr(0, 15) + "...";
      }
      return strName;
    },
    getRuleTypeName(ruleType) {
      for (let rt of this.receiverRuleTypes) {
        if (ruleType == rt.code) {
          return rt.name;
        }
      }
    },
    getPublishStatus(publishStatus) {
      for (let status of this.publishStatusList) {
        if (status.code == publishStatus) {
          return status.name;
        }
      }
    },

    //删除单个数据
    deleteById(row) {
      this.$confirm("是否删除该公告？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        var url = EXAM_WORK_API + "/notice/" + row.id;
        this.$httpWithMsg.delete(url).then(() => {
          this.$notify({
            type: "success",
            message: "删除成功！",
          });
          this.searchForm();
        });
      });
    },
    //删除多条数据
    deleteByIds() {
      if (this.selectedNoticeIds.length === 0) {
        this.warn("请选择要删除的公告");
      } else {
        this.$confirm("是否删除这些公告？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "error",
        }).then(() => {
          var url = EXAM_WORK_API + "/notice/" + this.noticeIds;
          this.$httpWithMsg.delete(url).then(() => {
            this.success("删除成功！");
            this.searchForm();
          });
        });
      }
    },
    sendMsg(id) {
      this.$httpWithMsg
        .get(EXAM_WORK_API + "/notice/sendMsg/" + id)
        .then(() => {
          this.success("发送成功");
          this.searchForm();
        });
    },
    recallMsg(id) {
      this.$httpWithMsg
        .get(EXAM_WORK_API + "/notice/recallMsg/" + id)
        .then(() => {
          this.success("撤回成功");
          this.searchForm();
        });
    },
    saveNotice() {
      this.noticeForm.noticeStatus = "DRAFT";
      this.$refs["noticeForm"].validate((valid) => {
        if (valid) {
          if (
            this.noticeForm.ruleType == "STUDENTS_OF_EXAM" ||
            this.noticeForm.ruleType == "TEACHER_OF_MARK_WORK"
          ) {
            if (this.noticeForm.ruleType == "STUDENTS_OF_EXAM") {
              this.noticeForm.publishObjectId = this.selectedExamIds;
            } else if (this.noticeForm.ruleType == "TEACHER_OF_MARK_WORK") {
              this.noticeForm.publishObjectId = this.selectedMarkIds;
            }
            if (this.noticeForm.publishObjectId == "") {
              this.warn("请选择发送对象！");
              return;
            }
          }
          this.noticeFormLoading = true;
          //修改
          if ("edit" == this.operateType) {
            this.$httpWithMsg
              .post(EXAM_WORK_API + "/notice/updateNotice", this.noticeForm)
              .then(
                () => {
                  this.success("修改成功！");
                  this.searchForm();
                  this.noticeFormLoading = false;
                  this.editNoticeDialogVisible = false;
                },
                () => {
                  this.editNoticeDialogVisible = true;
                  this.noticeFormLoading = false;
                }
              );
          }
          //新增
          else {
            this.noticeForm.noticeStatus = "DRAFT";
            this.$httpWithMsg
              .post(EXAM_WORK_API + "/notice/addNotice", this.noticeForm)
              .then(
                () => {
                  this.success("新增成功！");
                  this.editNoticeDialogVisible = false;
                  this.noticeFormLoading = false;
                  this.searchForm();
                },
                () => {
                  this.editNoticeDialogVisible = true;
                  this.noticeFormLoading = false;
                }
              );
          }
        } else {
          console.log("error submit!");
          return false;
        }
      });
    },
    showSelectReceiver() {
      if (this.noticeForm.ruleType == "STUDENTS_OF_EXAM") {
        this.selectedMark = [];
        this.examFormSearch.name = "";
        this.resetExamPageAndSearchForm();
        this.examReceiverVisible = true;
      } else if (this.noticeForm.ruleType == "TEACHER_OF_MARK_WORK") {
        this.selectedExam = [];
        this.markFormSearch.workName = "";
        this.resetMarkPageAndSearchForm();
        this.markReceiverVisible = true;
      } else {
        this.examReceiverVisible = false;
        this.markReceiverVisible = false;
      }
    },
    examTagClose(tag) {
      for (let [index, exam] of this.selectedExam.entries()) {
        if (tag.id == exam.id) {
          this.toggleExamSelection(
            this.examTableData.find((p) => p.id == exam.id)
          );
          this.examTableData.splice(
            this.examTableData.findIndex((p) => p.id == exam.id),
            1
          );
          this.selectedExam.splice(index, 1);
        }
      }
    },
    markTagClose(tag) {
      for (let [index, mark] of this.selectedMark.entries()) {
        if (tag.id == mark.id) {
          this.toggleMarkSelection(
            this.markTableData.find((p) => p.id == mark.id)
          );
          this.markTableData.splice(
            this.markTableData.findIndex((p) => p.id == mark.id),
            1
          );
          this.selectedMark.splice(index, 1);
        }
      }
    },
    toggleExamSelection(row) {
      if (
        this.tempSelectedExam &&
        this.tempSelectedExam.length > 0 &&
        this.tempSelectedExam.some((p) => p.id == row.id)
      ) {
        this.$refs.examTable.toggleRowSelection(row);
      }
    },
    toggleMarkSelection(row) {
      if (
        this.tempSelectedMark &&
        this.tempSelectedMark.length > 0 &&
        this.tempSelectedMark.some((p) => p.id == row.id)
      ) {
        this.$refs.markTable.toggleRowSelection(row);
      }
    },
    examConfirm() {
      this.examReceiverVisible = false;
    },
    markConfirm() {
      this.markReceiverVisible = false;
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    cancel() {
      this.markReceiverVisible = false;
      this.examReceiverVisible = false;
      this.selectedExam = [];
      this.selectedMark = [];
      this.editNoticeDialogVisible = false;
    },
    examCancel() {
      this.examReceiverVisible = false;
    },
    markCancel() {
      this.markReceiverVisible = false;
    },
    warn(msg) {
      this.$notify({
        type: "warning",
        message: msg,
      });
    },
    success(msg) {
      this.$notify({
        type: "success",
        message: msg,
      });
    },
    handleClose(done) {
      done();
      // this.$confirm("确认关闭？")
      //   .then(() => {
      //     done();
      //   })
      //   .catch(() => {});
    },
  },
};
</script>
<style scoped>
.input {
  width: 400px;
}
.ckeditor-readOnly {
  width: 650px;
  height: 270px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(211, 213, 218);
  padding: 5px;
  margin-bottom: 10px;
  word-wrap: break-word;
  word-break: break-all;
}
.div-ckeditor-readOnly {
  width: 655px;
  height: 275px;
  overflow-x: auto;
  overflow-y: auto;
}
</style>
