<template>
  <section class="content">
    <LinkTitlesCustom :current-paths="['大题详情']" />
    <div class="box box-info">
      <!-- 正文信息 -->
      <div class="box-body">
        <el-form
          :inline="true"
          :model="paperDetailStruct"
          label-position="right"
          label-width="70px"
        >
          <el-row>
            <el-col :span="6">
              <el-form-item label="题型结构">
                <el-select
                  v-model="paperDetailStruct.questionType"
                  class="search_width"
                  placeholder="请选择"
                  size="small"
                  @change="getQuesNameList"
                >
                  <el-option
                    v-for="item in quesTypes"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="题目数量" class="pull-left">
                <el-input
                  v-model="count"
                  class="search_width"
                  disabled
                  size="small"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="每题分值" class="pull-left">
                <el-input-number
                  v-model="paperDetailStruct.score"
                  size="small"
                  :precision="1"
                  :min="0"
                  @change="muli(paperDetailStruct)"
                ></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">
              <el-form-item label="来源大题" class="pull-left">
                <el-select
                  v-model="paperDetailStruct.quesNames"
                  class="search_width"
                  multiple
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in quesNameList"
                    :key="item.code"
                    :label="
                      (item.name || '').length > 40
                        ? item.name.slice(0, 40) + '...'
                        : item.name
                    "
                    :value="item.code"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="题型总分" class="pull-left">
                <el-input
                  v-model="paperDetailStruct.totalScore"
                  class="search_width"
                  disabled
                  size="small"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <div class="search_down">
                <el-button size="small" type="primary" @click="saveForm"
                  >保 存</el-button
                >
                <el-button size="small" type="primary" @click="back"
                  ><i class="el-icon-arrow-left"></i> 返 回</el-button
                >
              </div>
            </el-col>
          </el-row>
        </el-form>

        <!-- 页面列表 -->
        <el-table
          :data="coursePropertyNumberDtos"
          border
          :row-class-name="tableRowClassName"
        >
          <el-table-column min-width="300" label="属性名称">
            <template slot-scope="scope">
              <div class="el_table_inner_left">
                <p
                  class="class-a"
                  :class="{ 'class-b': scope.row.propertyParentId != '0' }"
                >
                  {{ scope.row.propertyName }}
                </p>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="数量">
            <template slot-scope="scope">
              <div class="el_table_inner_left">
                <el-input
                  v-model="scope.row.number"
                  class="input_width_ss"
                  disabled
                ></el-input>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="公开（简单）">
            <template slot-scope="scope">
              <div
                class="el_table_inner_left"
                @mouseenter="showActiveIn(1, 1, scope.row)"
                @mouseleave="showActiveOff"
              >
                <el-tooltip placement="top">
                  <div slot="content">{{ message }}</div>
                  <el-input
                    v-model="scope.row.publicSimple"
                    class="input_width_ss"
                    :disabled="scope.row.disable"
                    @change="
                      sum(scope.row, scope.row.publicSimple, 'publicSimple')
                    "
                  ></el-input>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="公开（中等）">
            <template slot-scope="scope">
              <div
                class="el_table_inner_left"
                @mouseenter="showActiveIn(1, 2, scope.row)"
                @mouseleave="showActiveOff"
              >
                <el-tooltip placement="top">
                  <div slot="content">{{ message }}</div>
                  <el-input
                    v-model="scope.row.publicMedium"
                    class="input_width_ss"
                    :disabled="scope.row.disable"
                    @change="
                      sum(scope.row, scope.row.publicMedium, 'publicMedium')
                    "
                  ></el-input>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="公开（困难）">
            <template slot-scope="scope">
              <div
                class="el_table_inner_left"
                @mouseenter="showActiveIn(1, 3, scope.row)"
                @mouseleave="showActiveOff"
              >
                <el-tooltip placement="top">
                  <div slot="content">{{ message }}</div>
                  <el-input
                    v-model="scope.row.publicDifficulty"
                    class="input_width_ss"
                    :disabled="scope.row.disable"
                    @change="
                      sum(
                        scope.row,
                        scope.row.publicDifficulty,
                        'publicDifficulty'
                      )
                    "
                  ></el-input>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="非公开（简单）">
            <template slot-scope="scope">
              <div
                class="el_table_inner_left"
                @mouseenter="showActiveIn(0, 1, scope.row)"
                @mouseleave="showActiveOff"
              >
                <el-tooltip placement="top">
                  <div slot="content">{{ message }}</div>
                  <el-input
                    v-model="scope.row.noPublicSimple"
                    class="input_width_ss"
                    :disabled="scope.row.disable"
                    @change="
                      sum(scope.row, scope.row.noPublicSimple, 'noPublicSimple')
                    "
                  ></el-input>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="非公开（中等）">
            <template slot-scope="scope">
              <div
                class="el_table_inner_left"
                @mouseenter="showActiveIn(0, 2, scope.row)"
                @mouseleave="showActiveOff"
              >
                <el-tooltip placement="top">
                  <div slot="content">{{ message }}</div>
                  <el-input
                    v-model="scope.row.noPublicMedium"
                    class="input_width_ss"
                    :disabled="scope.row.disable"
                    @change="
                      sum(scope.row, scope.row.noPublicMedium, 'noPublicMedium')
                    "
                  ></el-input>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="非公开（困难）">
            <template slot-scope="scope">
              <div
                class="el_table_inner_left"
                @mouseenter="showActiveIn(0, 3, scope.row)"
                @mouseleave="showActiveOff"
              >
                <el-tooltip placement="top">
                  <div slot="content">{{ message }}</div>
                  <el-input
                    v-model="scope.row.noPublicDifficulty"
                    class="input_width_ss"
                    :disabled="scope.row.disable"
                    @change="
                      sum(
                        scope.row,
                        scope.row.noPublicDifficulty,
                        'noPublicDifficulty'
                      )
                    "
                  ></el-input>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
        </el-table>

        <div
          style="margin-top: 20px; padding-top: 20px"
          class="grid-content bg-purple-light"
        >
          <el-form label-width="80px">
            <el-row>
              <el-col :span="6">
                <el-form-item label="公开"> </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="简单总数">
                  <el-input
                    v-model="publicSimpleCount"
                    class="input_width_ss"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="中等总数">
                  <el-input
                    v-model="publicMediumCount"
                    class="input_width_ss"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="困难总数">
                  <el-input
                    v-model="publicDifficultyCount"
                    class="input_width_ss"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                <el-form-item label="非公开"> </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="简单总数">
                  <el-input
                    v-model="noPublicSimpleCount"
                    class="input_width_ss"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="中等总数">
                  <el-input
                    v-model="noPublicMediumCount"
                    class="input_width_ss"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="困难总数">
                  <el-input
                    v-model="noPublicDifficultyCount"
                    class="input_width_ss"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { QUESTION_API } from "@/constants/constants";
import { QUESTION_TYPES } from "../constants/constants";
import LinkTitlesCustom from "@/components/LinkTitlesCustom.vue";
export default {
  components: {
    LinkTitlesCustom,
  },
  data() {
    return {
      paperDetailStruct: {
        id: "",
        number: "",
        name: "", //大题名称
        questionType: "", //题型
        detailCount: 0, //小题总数
        score: 0, //每题分数
        totalScore: 0, //大题分数
        quesNames: [], //来源大题
        publicSimpleCount: 0, //公开简单总数
        publicMediumCount: 0, //公开中等总数
        publicDifficultyCount: 0, //公开困难总数
        noPublicSimpleCount: 0, //非公开简单总数
        noPublicMediumCount: 0, //非公开中等总数
        noPublicDifficultyCount: 0, //非公开困难总数
        coursePropertyNumberDtos: [], //课程属性列表
        remark: "", //备注
      },
      paperDetailStructId: "",
      quesTypes: QUESTION_TYPES,
      coursePropertyNumberDtos: [],
      properties: [],
      loading: false,
      quesNameList: [],
      blueStruct: "",
      disable: false,
      count: 0,
      publicSimpleCount: 0,
      publicMediumCount: 0,
      publicDifficultyCount: 0,
      noPublicSimpleCount: 0,
      noPublicMediumCount: 0,
      noPublicDifficultyCount: 0,
      paperStructId: "",
      message: "",
      question: {
        questionType: "",
        courseNo: "",
        coursePropertyName: "",
        publicity: "",
        firstPropertyId: "",
        secondPropertyId: "",
      },
    };
  },
  computed: {},
  watch: {
    count() {
      this.paperDetailStruct.totalScore =
        (this.paperDetailStruct.score * 1000 * this.count) / 1000;
    },
  },
  //初始化查询
  created() {
    this.paperStructId = this.$route.params.paperStructId;
    this.paperDetailStructId = this.$route.params.id;
    this.searchPaperDetail();
  },
  methods: {
    tableRowClassName(row) {
      if (row.disable) {
        return "positive-row";
      }
      return "";
    },
    //查询课程下的所有属性
    searchPaperDetail() {
      //this.loading = true;
      var bluePaperStructStorge = sessionStorage.getItem("blueStruct");
      if (typeof bluePaperStructStorge === "string") {
        var blueStruct = JSON.parse(bluePaperStructStorge);
        this.blueStruct = blueStruct;
        var paperDetailStructs = blueStruct.paperDetailStructs;
        for (let paperDetailStruct of paperDetailStructs) {
          if (paperDetailStruct.id == this.paperDetailStructId) {
            this.paperDetailStruct = paperDetailStruct;
            //新增，做初始化查询
            if (this.paperDetailStruct.coursePropertyNumberDtos.length == 0) {
              //查询课程下的所有属性
              this.$http
                .get(QUESTION_API + "/property/" + blueStruct.coursePropertyId)
                .then((response) => {
                  this.properties = response.data;
                  this.init();
                });
            } else {
              //修改
              this.coursePropertyNumberDtos =
                this.paperDetailStruct.coursePropertyNumberDtos;
              this.count = paperDetailStruct.detailCount;
              this.publicSimpleCount = paperDetailStruct.publicSimpleCount;
              this.publicMediumCount = paperDetailStruct.publicMediumCount;
              this.publicDifficultyCount =
                paperDetailStruct.publicDifficultyCount;
              this.noPublicSimpleCount = paperDetailStruct.noPublicSimpleCount;
              this.noPublicMediumCount = paperDetailStruct.noPublicMediumCount;
              this.noPublicDifficultyCount =
                paperDetailStruct.noPublicDifficultyCount;
              this.initQuesNameList();
            }
          }
        }
      }
    },
    //初始化方法
    init() {
      var i = 1;
      for (let property of this.properties) {
        var coursePropertyNumber = {
          id: i,
          propertyId: property.id,
          propertyParentId: property.parentId,
          propertyName: property.name,
          number: 0,
          publicSimple: 0,
          publicMedium: 0,
          publicDifficulty: 0,
          noPublicSimple: 0,
          noPublicMedium: 0,
          noPublicDifficulty: 0,
          disable: false,
        };
        //判断父节点
        if (property.parentId == "0") {
          //判断父节点下面有没有  子节点
          for (let propertySon of this.properties) {
            //如果有子节点，就不能输入
            if (propertySon.parentId == property.id) {
              this.disable = true;
            }
          }
          coursePropertyNumber.disable = this.disable;
        }
        this.coursePropertyNumberDtos.push(coursePropertyNumber);
        this.disable = false;
        i = i + 1;
      }
    },
    //初始化来源大题名称
    initQuesNameList() {
      var url =
        QUESTION_API +
        "/paperStruct/quesNames?quesType=" +
        this.paperDetailStruct.questionType +
        "&courseNo=" +
        this.blueStruct.courseNo;
      this.$http.get(url).then((response) => {
        this.quesNameList = response.data;
      });
    },
    //查询来源大题名称
    getQuesNameList(quesType) {
      if (quesType && quesType.length > 0) {
        var url =
          QUESTION_API +
          "/paperStruct/quesNames?quesType=" +
          quesType +
          "&courseNo=" +
          this.blueStruct.courseNo;
        this.$http.get(url).then((response) => {
          this.quesNameList = response.data;
        });
      }
      this.paperDetailStruct.quesNames = [];
      //需要记录题型，查询题目数量
    },
    //判断是否为正整数
    isPositiveInteger(s) {
      //是否为正整数
      var re = /^[0-9]+$/;
      return re.test(s);
    },
    //计算数量
    sum(row, data, rowType) {
      //计算前校验正整数
      if (!this.isPositiveInteger(data)) {
        if (rowType === "publicSimple") {
          setTimeout(function () {
            row.publicSimple = 0;
          }, 1);
        } else if (rowType === "publicMedium") {
          setTimeout(function () {
            row.publicMedium = 0;
          }, 1);
        } else if (rowType === "publicDifficulty") {
          setTimeout(function () {
            row.publicDifficulty = 0;
          }, 1);
        } else if (rowType === "noPublicSimple") {
          setTimeout(function () {
            row.noPublicSimple = 0;
          }, 1);
        } else if (rowType === "noPublicMedium") {
          setTimeout(function () {
            row.noPublicMedium = 0;
          }, 1);
        } else if (rowType === "noPublicDifficulty") {
          setTimeout(function () {
            row.noPublicDifficulty = 0;
          }, 1);
        }
      }
      var _this = this;
      setTimeout(function () {
        //每行总和计算
        console.log("row:", row);
        row.publicSimple = parseInt(row.publicSimple);
        row.publicMedium = parseInt(row.publicMedium);
        row.publicDifficulty = parseInt(row.publicDifficulty);
        row.noPublicSimple = parseInt(row.noPublicSimple);
        row.noPublicMedium = parseInt(row.noPublicMedium);
        row.noPublicDifficulty = parseInt(row.noPublicDifficulty);
        var number = 0;
        row.number =
          parseInt(row.publicSimple) +
          parseInt(row.publicMedium) +
          parseInt(row.publicDifficulty) +
          parseInt(row.noPublicSimple) +
          parseInt(row.noPublicMedium) +
          parseInt(row.noPublicDifficulty);
        //父节点总和计算
        if (row.propertyParentId != "0") {
          var publicSimple = 0;
          var publicMedium = 0;
          var publicDifficulty = 0;
          var noPublicSimple = 0;
          var noPublicMedium = 0;
          var noPublicDifficulty = 0;
          //查询到父节点this
          var coursePropertyNumber = _this.getCoursePropertyNumber(
            row.propertyParentId
          );
          //查询所有子节点
          var coursePropertyNumberSons = _this.getCoursePropertyNumberSons(
            coursePropertyNumber.propertyId
          );
          for (let coursePropertyNumberSon of coursePropertyNumberSons) {
            publicSimple =
              parseInt(coursePropertyNumberSon.publicSimple) + publicSimple;
            publicMedium =
              parseInt(coursePropertyNumberSon.publicMedium) + publicMedium;
            publicDifficulty =
              parseInt(coursePropertyNumberSon.publicDifficulty) +
              publicDifficulty;
            noPublicSimple =
              parseInt(coursePropertyNumberSon.noPublicSimple) + noPublicSimple;
            noPublicMedium =
              parseInt(coursePropertyNumberSon.noPublicMedium) + noPublicMedium;
            noPublicDifficulty =
              parseInt(coursePropertyNumberSon.noPublicDifficulty) +
              noPublicDifficulty;
          }
          coursePropertyNumber.publicSimple = publicSimple;
          coursePropertyNumber.publicMedium = publicMedium;
          coursePropertyNumber.publicDifficulty = publicDifficulty;
          coursePropertyNumber.noPublicSimple = noPublicSimple;
          coursePropertyNumber.noPublicMedium = noPublicMedium;
          coursePropertyNumber.noPublicDifficulty = noPublicDifficulty;
          coursePropertyNumber.number =
            coursePropertyNumber.publicSimple +
            coursePropertyNumber.publicMedium +
            coursePropertyNumber.publicDifficulty +
            coursePropertyNumber.noPublicSimple +
            coursePropertyNumber.noPublicMedium +
            coursePropertyNumber.noPublicDifficulty;
        }
        //计算所有公开简单数量
        var publicSimpleCount = 0;
        for (let coursePropertyNumber of _this.coursePropertyNumberDtos) {
          if (coursePropertyNumber.disable != true) {
            publicSimpleCount =
              parseInt(coursePropertyNumber.publicSimple) + publicSimpleCount;
          }
        }
        _this.publicSimpleCount = publicSimpleCount;
        //计算所有公开中等数量
        var publicMediumCount = 0;
        for (let coursePropertyNumber of _this.coursePropertyNumberDtos) {
          if (coursePropertyNumber.disable != true) {
            publicMediumCount =
              parseInt(coursePropertyNumber.publicMedium) + publicMediumCount;
          }
        }
        _this.publicMediumCount = publicMediumCount;
        //计算所有公开困难数量
        var publicDifficultyCount = 0;
        for (let coursePropertyNumber of _this.coursePropertyNumberDtos) {
          if (coursePropertyNumber.disable != true) {
            publicDifficultyCount =
              parseInt(coursePropertyNumber.publicDifficulty) +
              publicDifficultyCount;
          }
        }
        _this.publicDifficultyCount = publicDifficultyCount;
        //计算所有非公开简单数量
        var noPublicSimpleCount = 0;
        for (let coursePropertyNumber of _this.coursePropertyNumberDtos) {
          if (coursePropertyNumber.disable != true) {
            noPublicSimpleCount =
              parseInt(coursePropertyNumber.noPublicSimple) +
              noPublicSimpleCount;
          }
        }
        _this.noPublicSimpleCount = noPublicSimpleCount;
        //计算所有非公开等单数量
        var noPublicMediumCount = 0;
        for (let coursePropertyNumber of _this.coursePropertyNumberDtos) {
          if (coursePropertyNumber.disable != true) {
            noPublicMediumCount =
              parseInt(coursePropertyNumber.noPublicMedium) +
              noPublicMediumCount;
          }
        }
        _this.noPublicMediumCount = noPublicMediumCount;
        //计算所有非公开困难数量
        var noPublicDifficultyCount = 0;
        for (let coursePropertyNumber of _this.coursePropertyNumberDtos) {
          if (coursePropertyNumber.disable != true) {
            noPublicDifficultyCount =
              parseInt(coursePropertyNumber.noPublicDifficulty) +
              noPublicDifficultyCount;
          }
        }
        _this.noPublicDifficultyCount = noPublicDifficultyCount;
        //计算总数
        for (let coursePropertyNumber of _this.coursePropertyNumberDtos) {
          //父节点相加即可
          if (coursePropertyNumber.propertyParentId == "0") {
            number = parseInt(coursePropertyNumber.number) + number;
          }
        }
        _this.count = number;
      }, 5);
    },
    //根据propertyId 取到对应的 coursePropertyNumber
    getCoursePropertyNumber(propertyParentId) {
      for (let coursePropertyNumber of this.coursePropertyNumberDtos) {
        if (propertyParentId == coursePropertyNumber.propertyId) {
          return coursePropertyNumber;
        }
      }
    },
    //根据父节点，找到子节点集合
    getCoursePropertyNumberSons(propertyId) {
      var coursePropertyNumberSons = [];
      for (let coursePropertyNumber of this.coursePropertyNumberDtos) {
        if (propertyId == coursePropertyNumber.propertyParentId) {
          coursePropertyNumberSons.push(coursePropertyNumber);
        }
      }
      return coursePropertyNumberSons;
    },
    //计算总分
    muli(paperDetailStruct) {
      if (isNaN(paperDetailStruct.score) || paperDetailStruct.score < 0) {
        setTimeout(function () {
          paperDetailStruct.score = 0;
        }, 1);
        this.paperDetailStruct.totalScore = 0;
      } else {
        this.paperDetailStruct.totalScore =
          (this.paperDetailStruct.score * 1000 * this.count) / 1000;
      }
    },
    //保存
    saveForm() {
      if (!this.checkSave()) {
        return false;
      }
      this.paperDetailStruct.detailCount = this.count;
      this.paperDetailStruct.id = this.paperDetailStructId;
      this.paperDetailStruct.coursePropertyNumberDtos =
        this.coursePropertyNumberDtos;
      this.paperDetailStruct.publicSimpleCount = this.publicSimpleCount;
      this.paperDetailStruct.publicMediumCount = this.publicMediumCount;
      this.paperDetailStruct.publicDifficultyCount = this.publicDifficultyCount;
      this.paperDetailStruct.noPublicSimpleCount = this.noPublicSimpleCount;
      this.paperDetailStruct.noPublicMediumCount = this.noPublicMediumCount;
      this.paperDetailStruct.noPublicDifficultyCount =
        this.noPublicDifficultyCount;
      for (let paperDetailStruct of this.blueStruct.paperDetailStructs) {
        if (this.paperDetailStruct.id == paperDetailStruct.id) {
          paperDetailStruct = this.paperDetailStruct;
        }
      }
      //保存蓝图试卷结构
      sessionStorage.setItem("blueStruct", JSON.stringify(this.blueStruct));
      this.back();
    },
    //返回
    back() {
      this.$router.push({
        path: "/questions/insert_blue_paper_structure/" + this.paperStructId,
      });
    },
    //验证
    checkSave() {
      if (!this.paperDetailStruct.questionType) {
        this.$notify({
          type: "error",
          message: "请选择题型结构",
        });
        return false;
      }
      if (this.paperDetailStruct.quesNames.length < 1) {
        this.$notify({
          type: "error",
          message: "请选择来源大题",
        });
        return false;
      }
      if (this.paperDetailStruct.score == 0) {
        this.$notify({
          type: "error",
          message: "分数不能为0",
        });
        return false;
      }
      if (this.count < 1) {
        this.$notify({
          type: "error",
          message: "题目数量不能为0",
        });
        return false;
      }
      return true;
    },
    //鼠标悬浮事件
    showActiveIn(publicity, difficulty, row) {
      this.question.questionType = this.paperDetailStruct.questionType;
      this.question.courseNo = this.blueStruct.courseNo;
      this.question.coursePropertyName = this.blueStruct.coursePropertyName;
      this.question.quesNames = this.paperDetailStruct.quesNames.join(",");
      console.log("this.question:", this.question);
      if (publicity == 0) {
        this.question.publicity = false;
      } else {
        this.question.publicity = true;
      }
      if (difficulty == 1) {
        this.question.difficulty = "易";
      } else if (difficulty == 2) {
        this.question.difficulty = "中";
      } else {
        this.question.difficulty = "难";
      }
      if (row.propertyParentId != "0") {
        this.question.firstPropertyId = row.propertyParentId;
        this.question.secondPropertyId = row.propertyId;
      } else {
        this.question.firstPropertyId = row.propertyId;
        this.question.secondPropertyId = "";
      }
      //参数序列化
      this.$http
        .get(QUESTION_API + "/question/count", { params: this.question })
        .then((response) => {
          this.message = response.data;
          console.log("this.message:", this.message);
        });
    },
    //鼠标离开事件
    showActiveOff() {
      this.message = "";
    },
  },
};
</script>
<style scoped src="../styles/Common.css"></style>
<style scoped>
.bg-purple-light {
  background: #e5e9d0;
}
.class-a {
  white-space: nowrap;
  margin-left: 5px;
  text-align: left;
}
.class-b {
  margin-left: 30px;
}
.el-table .positive-row {
  background: #e2f0e4;
}
</style>
