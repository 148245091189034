<template>
  <section class="content">
    <div
      v-loading.body="fileLoading"
      v-loading.fullscreen="loading"
      class="box-body"
      element-loading-text="请稍后..."
    >
      <el-form ref="primaryForm" :inline="true" :model="formSearch">
        <el-row>
          <el-form-item label="违纪类型名称">
            <el-input
              v-model="formSearch.name"
              class="input_width_lg"
              placeholder="请输入违纪类型名称"
            />
          </el-form-item>
          <el-form-item label="违纪类型代码">
            <el-input
              v-model="formSearch.code"
              class="input_width_lg"
              placeholder="请输入违纪类型代码"
            />
          </el-form-item>

          <el-form-item>
            <el-button
              size="small"
              type="primary"
              icon="el-icon-search"
              @click="handleSearchBtn"
              >查询</el-button
            >
            <el-button
              size="small"
              icon="el-icon-refresh"
              @click="resetPrimaryForm"
              >重置</el-button
            >
            <el-button
              size="small"
              type="primary"
              icon="el-icon-plus"
              @click="insert"
              >新增</el-button
            >
          </el-form-item>
        </el-row>
      </el-form>

      <div class="block-seperator"></div>

      <span>操作：</span>
      <el-button
        size="small"
        type="danger"
        :disabled="noBatchSelected"
        icon="el-icon-delete"
        @click="deleteIds"
        >删除</el-button
      >
      <el-button
        size="small"
        type="primary"
        icon="el-icon-upload2"
        @click="impIllegallyType"
        >导入</el-button
      >
      <el-button
        size="small"
        type="primary"
        icon="el-icon-download"
        @click="exportIllegallyType"
        >导出</el-button
      >

      <div style="width: 100%; margin-bottom: 10px"></div>

      <!-- 新增或修改弹出框 -->
      <el-dialog
        title="违纪类型"
        width="450px"
        :visible.sync="illegallyTypeDialog"
        @close="dialogBeforeClose"
      >
        <el-form
          ref="illegallyTypeForm"
          :inline="true"
          inline-message
          :model="illegallyTypeForm"
          :rules="rules"
          label-position="right"
          label-width="90px"
        >
          <el-form-item label="代码" prop="code">
            <el-input
              v-model="illegallyTypeForm.code"
              :disabled="null != illegallyTypeForm.id"
              class="pull_length"
              auto-complete="off"
              placeholder="违纪类型代码"
              maxlength="100"
            />
          </el-form-item>
          <el-form-item label="名称" prop="name">
            <el-input
              v-model="illegallyTypeForm.name"
              class="pull_length"
              auto-complete="off"
              placeholder="违纪类型名称"
              maxlength="100"
            />
          </el-form-item>
          <el-row class="pull_center">
            <el-button type="primary" @click="submitForm('illegallyTypeForm')"
              >保 存</el-button
            >
            <el-button @click="close">取 消</el-button>
          </el-row>
        </el-form>
      </el-dialog>

      <!-- 页面列表 -->
      <el-table
        :data="tableData"
        border
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          :selectable="showCheckBox"
          width="40"
        ></el-table-column>
        <el-table-column prop="id" label="ID" width="60"></el-table-column>
        <el-table-column prop="code" label="违纪类型代码"></el-table-column>
        <el-table-column prop="name" label="违纪类型名称"></el-table-column>
        <el-table-column
          prop="dataCategory"
          label="数据分类"
          width="100"
        ></el-table-column>
        <el-table-column label="数据分类" width="100">
          <span slot-scope="scope">
            <span v-if="scope.row.dataCategory == 'SYSTEM'">
              <el-tooltip
                class="item"
                effect="dark"
                content="系统数据不可编辑"
                placement="right"
              >
                <el-button>系统</el-button>
              </el-tooltip>
            </span>
            <span v-else>
              <el-tooltip
                class="item"
                effect="dark"
                content="自定义数据可编辑"
                placement="right"
              >
                <el-button>自定义</el-button>
              </el-tooltip>
            </span>
          </span>
        </el-table-column>
        <el-table-column
          sortable
          prop="updateTime"
          label="更新时间"
          width="170"
        ></el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <div>
              <el-button
                v-show="scope.row.dataCategory != 'SYSTEM'"
                size="mini"
                type="primary"
                icon="el-icon-edit"
                plain
                @click="edit(scope.row)"
                >编辑</el-button
              >
              <el-button
                v-show="scope.row.dataCategory != 'SYSTEM'"
                size="mini"
                type="danger"
                icon="el-icon-delete"
                @click="deleteId(scope.row)"
                >删除</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="page pull-right">
        <el-pagination
          :current-page="currentPage"
          :page-size="pageSize"
          :page-sizes="[10, 20, 50, 100, 200, 300]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        />
      </div>

      <!-- 导入弹窗 -->
      <el-dialog title="导入窗口" width="520px" :visible.sync="impDialog">
        <el-form>
          <el-row>
            <el-form-item style="margin-left: 20px">
              <el-upload
                ref="upload"
                class="form_left"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                :action="uploadAction"
                :headers="uploadHeaders"
                :data="uploadData"
                :before-upload="beforeUpload"
                :on-progress="uploadProgress"
                :on-success="uploadSuccess"
                :on-error="uploadError"
                :file-list="fileList"
                :auto-upload="false"
                :multiple="false"
              >
                <el-button
                  slot="trigger"
                  size="small"
                  type="primary"
                  icon="el-icon-search"
                  >选择文件</el-button
                >&nbsp;
                <el-button
                  size="small"
                  type="primary"
                  icon="el-icon-check"
                  @click="submitUpload"
                  >确认上传</el-button
                >
                <el-button
                  size="small"
                  type="primary"
                  icon="el-icon-refresh"
                  @click="removeFile"
                  >清空文件</el-button
                >
                <el-button
                  size="small"
                  type="primary"
                  icon="el-icon-download"
                  @click="exportFile"
                  >下载模板</el-button
                >
                <div slot="tip" class="el-upload__tip">只能上传xlsx文件</div>
              </el-upload>
            </el-form-item>
          </el-row>
        </el-form>
      </el-dialog>

      <!-- 导入错误信息列表 -->
      <el-dialog title="错误提示" :visible.sync="errDialog">
        <div
          v-for="errMessage in errMessages"
          :key="errMessage.lineNum"
          class="text-danger"
        >
          第{{ errMessage.lineNum }}行：{{ errMessage.msg }}
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="errDialog = false">确定</el-button>
        </span>
      </el-dialog>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "IllegallyType",
  data() {
    return {
      formSearch: {
        name: "",
        code: "",
      },
      illegallyTypeForm: {
        id: null,
        name: "",
        code: "",
        sortNo: 0,
      },
      illegallyTypeId: "",
      illegallyTypeDialog: false,
      loading: false,
      multipleSelection: [],
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 10,
      impDialog: false,
      uploadAction: "/api/ecs_oe_admin/illegallyType/import",
      uploadHeaders: {},
      uploadData: {},
      errMessages: [],
      errDialog: false,
      fileLoading: false,
      fileList: [],
      rules: {
        name: [{ required: true, message: "名称不允许为空", trigger: "blur" }],
        code: [
          {
            required: true,
            message: "代码不允许为空",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    noBatchSelected() {
      return this.multipleSelection.length === 0;
    },
  },
  //初始化查询
  created() {
    this.searchForm();
    this.uploadHeaders = {
      key: this.user.key,
      token: this.user.token,
    };
  },
  methods: {
    dialogBeforeClose() {
      this.$refs.illegallyTypeForm.clearValidate();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.searchForm();
    },
    resetPrimaryForm() {
      this.formSearch = {
        name: "",
        code: "",
      };
      this.$refs.primaryForm.resetFields();
    },
    handleSearchBtn() {
      this.currentPage = 1;
      this.searchForm();
    },
    //查询
    searchForm() {
      let searchLock = true;
      setTimeout(() => {
        if (searchLock) {
          this.loading = true;
        }
      }, 500);
      var param = new URLSearchParams(this.formSearch);
      var url =
        "/api/ecs_oe_admin/illegallyType/illegallyTypePage/" +
        (this.currentPage - 1) +
        "/" +
        this.pageSize +
        "?" +
        param;
      this.$httpWithMsg
        .get(url)
        .then((response) => {
          console.log("response :", response);
          this.tableData = response.data.list;
          this.total = response.data.total;
        })
        .finally(() => {
          searchLock = false;
          this.loading = false;
        });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.searchForm();
    },
    //全选
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //保存
    submitForm(formData) {
      this.$refs[formData].validate((valid) => {
        if (valid) {
          var url = "/api/ecs_oe_admin/illegallyType";
          //修改
          if (null != this.illegallyTypeForm.id) {
            this.$httpWithMsg.put(url, this.illegallyTypeForm).then(
              () => {
                this.$notify({
                  type: "success",
                  message: "修改成功！",
                });
                this.searchForm();
                this.illegallyTypeDialog = false;
              },
              () => {
                this.searchForm();
                this.illegallyTypeDialog = false;
              }
            );
          }
          //新增
          else {
            this.$httpWithMsg.post(url, this.illegallyTypeForm).then(
              () => {
                this.$notify({
                  type: "success",
                  message: "新增成功！",
                });
                this.illegallyTypeDialog = false;
                this.searchForm();
              },
              () => {
                this.illegallyTypeDialog = false;
                return this.searchForm();
              }
            );
          }
        } else {
          console.log("error submit!");
          return false;
        }
      });
    },

    //重置
    resetForm(formData) {
      this.$refs[formData].resetFields();
    },
    close() {
      this.illegallyTypeDialog = false;
    },
    //新增
    insert() {
      this.illegallyTypeForm.id = null;
      this.illegallyTypeForm.name = "";
      this.illegallyTypeForm.code = "";
      this.illegallyTypeForm.sortNo = 0;

      this.illegallyTypeDialog = true;
    },
    //修改
    edit(row) {
      this.illegallyTypeForm = Object.assign({}, row);

      this.illegallyTypeDialog = true;
    },
    //删除
    deleteId(row) {
      this.$confirm("是否删除该条违纪类型信息？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      }).then(() => {
        var url = "/api/ecs_oe_admin/illegallyType/" + row.id;
        this.$httpWithMsg.delete(url).then(() => {
          this.$notify({
            type: "success",
            message: "删除成功！",
          });
          this.searchForm();
        });
      });
    },
    //删除多条信息
    deleteIds() {
      if (this.multipleSelection.length === 0) {
        this.$notify({
          type: "warning",
          message: "请选择要删除的违纪类型",
        });
      } else {
        this.$confirm("是否删除这些违纪类型信息？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "error",
        }).then(() => {
          var ids = [];
          for (var i = 0; i < this.multipleSelection.length; i++) {
            ids.push(this.multipleSelection[i].id);
          }
          var url = "/api/ecs_oe_admin/illegallyType/" + ids;
          this.$httpWithMsg.delete(url).then(() => {
            this.$notify({
              type: "success",
              message: "删除成功！",
            });
            this.searchForm();
          });
        });
      }
    },

    exportIllegallyType() {
      this.$confirm("确定执行导出?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let param = new URLSearchParams(this.formSearch);
        window.open(
          "/api/ecs_oe_admin/illegallyType/export?$key=" +
            this.user.key +
            "&$token=" +
            this.user.token +
            "&" +
            param
        );
      });
    },
    //导入
    impIllegallyType() {
      this.impDialog = true;
      this.initUpload();
    },
    initUpload() {
      this.fileList = [];
    },
    beforeUpload(file) {
      console.log(file);
    },
    uploadProgress() {
      console.log("uploadProgress");
    },
    uploadSuccess(response) {
      if (!response.hasError) {
        this.$notify({
          message: "上传成功",
          type: "success",
        });
        this.fileLoading = false;
        this.impDialog = false;
        this.searchForm();
      } else {
        this.fileLoading = false;
        this.impDialog = false;
        this.errMessages = response.failRecords;
        this.errDialog = true;
      }
    },
    uploadError(response) {
      var json = JSON.parse(response.message);
      if (response.status == 500) {
        this.$notify({
          message: json.desc,
          type: "error",
        });
      }
      this.fileLoading = false;
    },
    //确定上传
    submitUpload() {
      if (!this.checkUpload()) {
        return false;
      }
      this.$refs.upload.submit();
      this.fileLoading = true;
    },
    checkUpload() {
      var fileList = this.$refs.upload.uploadFiles;
      if (fileList.length == 0) {
        this.$notify({
          message: "上传文件不能为空",
          type: "error",
        });
        return false;
      }
      if (fileList.length > 1) {
        this.$notify({
          message: "每次只能上传一个文件",
          type: "error",
        });
        return false;
      }
      for (let file of fileList) {
        if (!file.name.endsWith(".xlsx")) {
          this.$notify({
            message: "上传文件必须为xlsx格式",
            type: "error",
          });
          this.initUpload();
          return false;
        }
      }
      return true;
    },
    //清空文件
    removeFile() {
      // this.fileList = [];
      this.$refs.upload.clearFiles();
    },
    //下载模板
    exportFile() {
      window.location.href =
        "/api/ecs_oe_admin/illegallyType/importTemplate?$key=" +
        this.user.key +
        "&$token=" +
        this.user.token;
    },
    showCheckBox(row) {
      if (row.dataCategory == "SYSTEM") {
        return false;
      }
      return true;
    },
  },
};
</script>

<style scoped>
.page {
  margin-top: 10px;
}

.input_width_lg {
  width: 180px;
}
.pull_center {
  margin-left: 120px;
}
.pull_length {
  width: 200px;
}
</style>
