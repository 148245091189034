<template>
  <section style="margin-top: 0px">
    <el-form
      :model="searchForm"
      :inline="true"
      style="border-bottom: 1px solid rgb(221, 221, 221); margin-bottom: 10px"
    >
      <el-form-item label="学校">
        <el-select
          v-model="searchForm.rootOrgId"
          placeholder="请选择"
          filterable
          clearable
          size="small"
          class="w180"
        >
          <el-option
            v-for="item in rootSchoolSelect"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>

      <el-form-item label="规则类型">
        <el-select
          v-model="searchForm.type"
          size="small"
          class="w180"
          placeholder="请选择"
          clearable
          @clear="clearTypeValue"
        >
          <el-option
            v-for="item in loginRuleTypes"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button
          size="small"
          type="primary"
          icon="el-icon-search"
          @click="doSearch(1)"
          >查询
        </el-button>

        <el-button size="small" icon="el-icon-refresh" @click="resetSearchForm">
          重置
        </el-button>
      </el-form-item>
    </el-form>

    <div style="margin-bottom: 5px">
      操作：
      <el-button
        size="small"
        type="primary"
        icon="el-icon-plus"
        @click="loginRuleDialogOpen(null)"
        >新增
      </el-button>

      <el-button
        v-show="false"
        size="small"
        type="primary"
        icon="el-icon-refresh"
        @click="doRefreshRule"
        >刷新
      </el-button>

      <div class="page pull-right">
        <el-form :inline="true">
          <span
            v-for="(item, index) in topLoginRuleList"
            :key="index"
            style="padding: 0px 15px"
          >
            <el-form-item :label="'全局开放' + getTypeTitle(item.type)">
              <el-switch
                v-model="switchModels[index].allow"
                @change="updateTopRule(item.type, index)"
              ></el-switch>
            </el-form-item>
          </span>
        </el-form>
      </div>
    </div>

    <el-table
      v-loading="loading"
      :data="tableData"
      element-loading-text="数据加载中"
      style="width: 100%"
      border
      stripe
    >
      <el-table-column label="学校ID" prop="rootOrgId" width="110px" sortable />
      <el-table-column label="学校名称" prop="rootOrgName" sortable />

      <el-table-column label="规则类型" width="150px">
        <template slot-scope="scope">
          <span>{{ getTypeTitle(scope.row.type) }} </span></template
        >
      </el-table-column>

      <el-table-column label="是否开放" width="150px">
        <template slot-scope="scope">
          <span>{{ scope.row.allow ? "是" : "否" }}</span>
        </template>
      </el-table-column>

      <el-table-column
        label="创建时间"
        prop="creationTime"
        width="160px"
        sortable
      />
      <el-table-column
        label="更新时间"
        prop="updateTime"
        width="160px"
        sortable
      />

      <el-table-column label="操作" width="180px">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit"
            plain
            @click="loginRuleDialogOpen(scope.row)"
            >修改
          </el-button>

          <el-button
            size="mini"
            type="danger"
            icon="el-icon-delete"
            plain
            @click="doDeleteRule(scope.row)"
            >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="page pull-right">
      <el-pagination
        :current-page="searchForm.pageNo"
        :page-size="searchForm.pageSize"
        :page-sizes="[10, 20, 50, 100, 200, 300]"
        :total="totalElements"
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="handlePagerNo"
        @size-change="handlePagerSize"
      ></el-pagination>
    </div>

    <el-dialog
      :title="isCreate ? '规则新增' : '规则修改'"
      width="390px"
      :visible.sync="loginRuleDialog"
      @close="loginRuleDialogClose"
    >
      <el-form
        ref="loginRuleForm"
        :model="loginRuleForm"
        :rules="formRules"
        label-position="right"
        label-width="110px"
        inline-message
      >
        <el-form-item label="学校" prop="rootOrgId">
          <el-select
            v-model="loginRuleForm.rootOrgId"
            :disabled="!isCreate"
            placeholder="请选择"
            filterable
            clearable
            size="small"
            class="w180"
          >
            <el-option
              v-for="item in rootSchoolSelect"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="规则类型" prop="type">
          <el-select
            v-model="loginRuleForm.type"
            :disabled="!isCreate"
            size="small"
            class="w180"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in loginRuleTypes"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="是否开放" prop="allow">
          <el-radio-group v-model="loginRuleForm.allow" class="w180">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>

        <div style="text-align: center">
          <el-button type="primary" @click="doSaveRule">确 定</el-button>
          <el-button @click="loginRuleDialogClose">取 消</el-button>
        </div>
      </el-form>
    </el-dialog>
  </section>
</template>

<script>
import { mapState } from "vuex";
import { CORE_API } from "@/constants/constants.js";

export default {
  data() {
    return {
      searchForm: {
        rootOrgId: null,
        type: null,
        allow: null,
        pageNo: 1,
        pageSize: 10,
      },
      loading: false,
      tableData: [],
      totalElements: 0,
      rootOrgList: [],
      loginRuleTypes: [
        { label: "WAP登录", value: "STUDENT_LOGIN" },
        { label: "考生端登录", value: "STUDENT_CLIENT_LOGIN" },
        { label: "极验验证码登录", value: "GEETEST_LOGIN" },
      ],
      isCreate: true,
      loginRuleDialog: false,
      loginRuleForm: {
        rootOrgId: null,
        type: null,
        allow: false,
      },
      formRules: {
        rootOrgId: [
          { required: true, message: "请选择学校！", trigger: "change" },
        ],
        type: [
          { required: true, message: "请选择规则类型！", trigger: "change" },
        ],
        allow: [
          {
            required: true,
            message: "请选择是否开放！",
            trigger: "change",
          },
        ],
      },
      topLoginRuleList: [],
      switchModels: [],
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
    isSuperAdmin() {
      return this.user.roleList.some((role) => role.roleCode == "SUPER_ADMIN");
    },
    rootSchoolSelect() {
      let rootSchools = [];
      for (let i = 0; i < this.rootOrgList.length; i++) {
        let info = {
          name: this.rootOrgList[i].name + "（" + this.rootOrgList[i].id + "）",
          id: this.rootOrgList[i].id,
        };
        rootSchools.push(info);
      }
      return rootSchools;
    },
  },
  created() {
    this.getRootOrgList();
    this.getTopLoginRuleList();
    this.doSearch(1);
  },
  methods: {
    getRootOrgList() {
      if (this.isSuperAdmin) {
        this.$httpWithMsg
          .get(CORE_API + "/org/getRootOrgList")
          .then((response) => {
            this.rootOrgList = response.data;
          });
      }
    },
    getTopLoginRuleList() {
      this.$httpWithMsg
        .post(CORE_API + "/sys/xlogin/rule/top/list")
        .then((response) => {
          this.topLoginRuleList = response.data;

          for (let n = 0; n < this.topLoginRuleList.length; n++) {
            let value = this.topLoginRuleList[n].allow;
            this.switchModels.push({ allow: value });
          }
        });
    },
    doSearch(pageNo) {
      this.searchForm.pageNo = pageNo;

      this.loading = true;
      let url = CORE_API + "/sys/xlogin/rule/list";
      this.$http.post(url, this.searchForm).then(
        (response) => {
          this.tableData = response.data.content;
          this.totalElements = response.data.totalElements;
          this.loading = false;
        },
        (error) => {
          console.log(error);
          this.loading = false;
        }
      );
    },
    loginRuleDialogOpen(row) {
      if (row) {
        this.isCreate = false;
        this.loginRuleForm.rootOrgId = row.rootOrgId;
        this.loginRuleForm.type = row.type;
        this.loginRuleForm.allow = row.allow;
      } else {
        this.isCreate = true;
        this.loginRuleForm.rootOrgId = null;
        this.loginRuleForm.type = null;
        this.loginRuleForm.allow = false;
      }
      this.loginRuleDialog = true;
    },
    loginRuleDialogClose() {
      this.loginRuleDialog = false;
    },
    doSaveRule() {
      this.$refs.loginRuleForm.validate((valid) => {
        if (!valid) {
          return false;
        }

        let url = CORE_API + "/sys/xlogin/rule/save";
        this.$http.post(url, this.loginRuleForm).then(() => {
          this.$notify({
            type: "success",
            message: "保存成功",
          });
          this.doSearch();
          this.loginRuleDialogClose();
        });
      });
    },
    updateTopRule(type, index) {
      let allow = this.switchModels[index].allow;
      let params = { rootOrgId: -1, type: type, allow: allow };
      let url = CORE_API + "/sys/xlogin/rule/save";
      this.$http.post(url, params).then(() => {
        this.$notify({
          type: "success",
          message: "更新成功",
        });
      });
    },
    doDeleteRule(row) {
      this.$confirm("确认删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let url = CORE_API + "/sys/xlogin/rule/delete?ruleId=" + row.id;
        this.$http.post(url).then(() => {
          this.$notify({
            type: "success",
            message: "删除成功",
          });
          this.doSearch();
        });
      });
    },
    doRefreshRule() {
      this.$confirm("确认刷新？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let url = CORE_API + "/sys/xlogin/rule/refresh";
        this.$http.post(url).then(() => {
          this.$notify({
            type: "success",
            message: "刷新成功",
          });
        });
      });
    },
    handlePagerNo(pageNo) {
      this.doSearch(pageNo);
    },
    handlePagerSize(pageSize) {
      this.searchForm.pageSize = pageSize;
      this.doSearch(1);
    },
    clearTypeValue() {
      this.searchForm.type = null;
    },
    getTypeTitle(val) {
      for (let type of this.loginRuleTypes) {
        if (type.value == val) {
          return type.label;
        }
      }
      return "";
    },
    resetSearchForm() {
      this.searchForm.rootOrgId = null;
      this.searchForm.type = null;
      this.searchForm.allow = null;
      this.doSearch(1);
    },
  },
};
</script>

<style scoped>
.page {
  margin-top: 10px;
}
.pull-right {
  float: right;
}
.w180 {
  width: 180px;
}
</style>
