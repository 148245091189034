<template>
  <div class="extract-paper-template">
    <el-form :inline="true" :model="searchParams">
      <el-form-item label="课程：">
        <el-select
          v-model="searchParams.courseId"
          clearable
          placeholder="请选择课程"
          size="small"
          filterable
          :remote-method="getAllCourses"
          remote
          @focus="(e) => getAllCourses(e.target.value)"
        >
          <el-option
            v-for="item in allCourseList"
            :key="item.id"
            :label="`${item.name}(${item.code})`"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="模板名称："
        label-width="100px"
        label-position="right"
      >
        <el-input v-model="searchParams.name"></el-input>
      </el-form-item>
      <el-form-item label="状态：" label-width="100px" label-position="right">
        <el-select
          v-model="searchParams.enable"
          clearable
          placeholder="请选择状态"
          size="small"
          style="width: 120px"
        >
          <el-option label="启用" :value="true"></el-option>
          <el-option label="禁用" :value="false"></el-option>
        </el-select>
      </el-form-item>
      <el-button type="primary" @click="search(1)">查询</el-button>
      <el-button type="primary" @click="toAddPaperSelect"
        ><i class="el-icon-plus"></i>随机抽卷模板</el-button
      >
    </el-form>

    <el-table :data="tableData" style="margin-top: 20px">
      <el-table-column
        v-for="(column, index) in columns"
        :key="index"
        :prop="column.prop"
        :label="column.label"
        :width="column.width"
        :min-width="column.minWidth"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column label="操作" :width="160">
        <template slot-scope="scope">
          <el-button type="primary" plain @click="editRow(scope.row)"
            >编辑</el-button
          >
          <el-button
            v-if="scope.row.enable"
            type="danger"
            plain
            @click="changeStatus(scope.row)"
            >禁用</el-button
          >
          <el-button
            v-else
            type="success"
            plain
            @click="changeStatus(scope.row)"
            >启用</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="page pull-right">
      <el-pagination
        :current-page="pageNumber"
        :page-size="pageSize"
        :page-sizes="[10, 20, 50, 100, 200, 300]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { QUESTION_API } from "@/constants/constants";
export default {
  data() {
    return {
      allCourseList: [],
      searchParams: {
        courseId: "",
        name: "",
        enable: "",
      },
      pageNumber: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      columns: [
        // { label: "ID", prop: "id", width: 178 },
        { label: "模板名称", prop: "name" },
        { label: "课程代码", prop: "courseCode" },
        { label: "课程名称", prop: "courseName" },
        { label: "组卷类型", prop: "paperStructTypeStr", width: 100 },
        { label: "结构名称", prop: "paperStructName" },
        { label: "状态", prop: "enableStr", width: 60 },
        { label: "更新时间", prop: "updateDate", minWidth: 140 },
        { label: "操作人", prop: "updateByName" },
      ],
    };
  },
  created() {
    this.getAllCourses("");
    this.search();
  },
  methods: {
    changeStatus(row) {
      let str = row.enable ? "禁用" : "启用";
      this.$confirm(`确认${str}？`, "提示", {
        type: "warning",
      }).then(() => {
        this.loading = true;
        this.$http
          .post("/api/ecs_ques/randompaper/toggle", null, {
            params: {
              id: row.id,
              enable: !row.enable,
            },
          })
          .then(() => {
            this.$notify({
              message: str + "成功",
              type: "success",
            });
            this.search();
          });
      });
    },
    editRow(row) {
      this.$router.push(
        `/questions/add_paper_select/${row.id}?courseNo=${row.courseCode}&courseName=${row.courseName}&courseId=${row.courseId}`
      );
    },
    getAllCourses(query) {
      if (query) {
        query = query.trim();
      }
      this.$http
        .get("/api/ecs_core/course/query", {
          params: {
            enable: true,
            name: query,
          },
        })
        .then((response) => {
          this.allCourseList = response.data;
        });
    },
    handleCurrentChange(val) {
      this.search(val);
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.search(1);
    },
    search(pageNumber) {
      this.pageNumber = pageNumber;
      this.$http
        .post(QUESTION_API + "/randompaper/page", null, {
          params: {
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
            ...this.searchParams,
          },
        })
        .then((res) => {
          this.tableData = res.data.content || [];
          this.total = res.data.totalElements;
        });
    },
    toAddPaperSelect() {
      var courseId = this.searchParams.courseId;
      if (!courseId) {
        this.$notify({
          title: "警告",
          message: "请选择课程",
          type: "warning",
        });
        return false;
      }
      let course = this.allCourseList.find((item) => item.id == courseId);
      let courseName = course.name;
      let courseNo = course.code;
      this.$router.push({
        path: "/questions/add_paper_select",
        query: { courseNo, courseName, courseId },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.extract-paper-template {
  background-color: #fff;
  border-radius: 6px;
  padding: 15px;
}
</style>
